import React, { Fragment } from 'react';
import BaseComponent from "../../components/component/base";
import PageCompanyTabs from "./tabs";

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            name: "",
            taxNumber: "",
            identNumber: "",
            provinceName: "",
            postalCode: "",
            cityName: "",
            streetAddress: "",
            streetNumber: "",
            apartmentNumber: "",
            courtNumber: "",
            courtName: "",
            capitalValue: "",
            type: "",
            representativeUuid: "",
            history: [],
            users: [],
            files: [],
            signatureStatuses: [],
            fullData: "",
            signatureUuid: '',
        };
    }

    componentDidMount() {
        if (this.props.match.params.uuid) {
            this.loadData(this.props.match.params.uuid);
        } else {
            this.setState({ isLoading: false })
        }
    }

    loadSignatureStatuses(signatureUuid, callback) {
        this.props.api.post(
            `/signature/describe`,
            {
                'signature': {
                    'uuid': signatureUuid
                }
            },
            (data) => {
                this.setState({
                    'signatureUuid': signatureUuid || '',
                    'signatureStatuses': data.signatureStatuses || [],
                }, () => {
                    callback();
                })
            },
            (e) => {
                let ident = e?.ident || `${uuid}-error-status`;
                let status = e?.data?.error?.ident || null;
                let message = '';
                try {
                    status = JSON.parse(e?.data?.error?.ident);
                    message = status.error_message;
                } catch (ee) {
                    // ...
                }
                this.setState({
                    'signatureUuid': '',
                    'signatureStatuses': [{
                        uuid: ident,
                        created: '',
                        ident: 'problem',
                        party: 'Scrive',
                        comment: message
                    }]
                }, () => {
                    callback();
                })
            }
        );
    }

    loadData(uuid) {
        this.setState({ isLoading: true }, () => {
            this.props.api.post(
                `/company/describe`,
                {
                    company: {
                        uuid: uuid
                    }
                },
                (data) => {
                    this.setState({
                        signatureUuid: data.signature?.uuid
                    }, () => {
                        if (this.state.signatureUuid) {
                            this.loadSignatureStatuses(this.state.signatureUuid, () => {
                                this.setData(data);
                            });
                        } else {
                            this.setData(data);
                        }
                    })

                },
                (errorObject) => {
                    this.props.showToast({
                        errorObject: errorObject,
                        title: this.props.t('common.toast.error'),
                        color: "danger"
                    });
                }
            );
        });
    }

    setData(data) {
        this.setState({
            ...data.address,
            ...data.company,
            files: data.files,
            representativeUuid: data.representative?.uuid,
            signatureUuid: data.signature?.uuid,
            signatureStatuses: data.signatureStatuses,
            history: data.history.map(itm => ({ ...itm, userUuid: itm.user.uuid })),
            users: data.users,
            isLoading: false
        }, () => this.buildFullData());
    }

    buildFullData() {
        let fullData = [];
        if (this.props.isRegion(['POLAND'])) {
            fullData.push(`${this.state.name}`);
            fullData.push(`z siedzibą w`);
            fullData.push(this.state.cityName);
            fullData.push(`${this.state.postalCode},`);
            fullData.push(this.state.streetAddress);
            fullData.push(this.state.streetNumber);
            fullData.push(this.state.apartmentNumber);
            if (this.state.courtName) {
                fullData.push(`wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego przez ${this.state.courtName}`);
            }
            if (this.state.courtName) {
                fullData.push(`pod numerem KRS: ${this.state.courtName};`);
            }
            if (this.state.taxNumber) {
                fullData.push(`NIP: ${this.state.taxNumber};`);
            }
            if (this.state.identNumber) {
                fullData.push(`REGON: ${this.state.identNumber};`);
            }
            if (this.state.capitalValue) {
                fullData.push(`kapitał zakładowy: ${this.state.capitalValue};`);
            }
        }
        this.setState({
            fullData: fullData.filter(Boolean).join(' ')
        })
    }

    saveData() {
        this.setState({ isLoading: true });
        this.props.api.post(
            this.state.uuid ? `/company/update` : `/company/create`,
            {
                "company": {
                    "uuid": this.state.uuid,
                    "name": this.state.name,
                    "taxNumber": this.state.taxNumber,
                    "identNumber": this.state.identNumber,
                    "courtNumber": this.state.courtNumber,
                    "courtName": this.state.courtName,
                    "capitalValue": this.state.capitalValue,
                    "type": this.state.type,
                },
                "representative": {
                    "uuid": this.state.representativeUuid
                },
                "address": {
                    "provinceName": this.state.provinceName,
                    "postalCode": this.state.postalCode,
                    "cityName": this.state.cityName,
                    "streetAddress": this.state.streetAddress,
                    "streetNumber": this.state.streetNumber,
                    "apartmentNumber": this.state.apartmentNumber
                },
                "history": this.state.history.map(itm => ({ ...itm, user: { ...itm.user, uuid: itm.userUuid } })),
                "files": this.state.files
            },
            (data) => {
                this.setState({ isLoading: false });
                this.props.showToast({
                    title: this.props.t('common.toast.dataSaved'),
                    color: "info"
                });
                this.setData(data);
            },
            (errorObject) => {
                this.setState({ isLoading: false });
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        );
    }

    render() {

        return (
            <Fragment>
                <PageCompanyTabs
                    {...this.props}
                    upperState={this.state}
                    setUpperState={(data, callback) =>
                        this.setState(data, () => {
                            if (callback) {
                                callback();
                            }
                        })}
                    saveData={(data) => this.saveData(data)}
                />
            </Fragment>
        );
    }
}

export default Content
