import React, { Component } from 'react';
import { Table } from "react-bootstrap";

class Comments extends Component {

    render() {

        const { t } = this.props;
        const { comments } = this.props.upperState;

        return (
            <Table size="xs" className="mb-0 comments border-left border-right border-bottom" responsive>
                <thead>
                    <tr>
                        <th scope="col" className="pl-20">
                            {t('page.asset.element.commentsTable.content')}
                        </th>
                        <th scope="col" style={{ maxWidth: 200 }} className="pr-20 text-right">
                            {t('page.asset.element.commentsTable.created')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        comments.filter(itm => !itm.trash).length ?
                            comments.map((status) => {
                                return (
                                    <tr key={status.uuid}>
                                        <td className="align-middle pl-20" dangerouslySetInnerHTML={{ __html: status.content }} />
                                        <td className="align-middle pr-20 text-right">{status.created}</td>
                                    </tr>
                                );
                            })
                            :
                            (
                                <tr>
                                    <td colSpan="2" className="pl-20">
                                        {t('page.asset.element.commentsTable.empty')}
                                    </td>
                                </tr>
                            )
                    }
                </tbody>
            </Table>
        );
    }
}
export default Comments;