import React, { Fragment } from 'react';
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import LowerComponent from "../../components/component/lower-elements";
import Icon from '../../components/icon';
import TextEditor from '../../components/text-editor';
import { articleCategories } from '../../constants';

class PageArticleEditor extends LowerComponent {

    render() {

        const {
            isLoading,
            html,
            category,
            title,
            footer,
            schema
        } = this.props.upperState;


        return (
            <Fragment>
                <Form method="post" onSubmit={(e) => { e.preventDefault(); this.props.saveData(this.props.upperState) }}>

                    <Row className="mb-30">
                        <Col sm={12} md={12} lg={12}>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="required">
                                                <Form.Label>{this.props.t(`page.article.editor.title`)}</Form.Label>
                                                <Form.Control
                                                    required={true}
                                                    type="text"
                                                    placeholder={this.props.t(`page.article.editor.placeholder.title`)}
                                                    value={title || ""}
                                                    onChange={(e) => { this.onChangeSetUpperState('title', e) }} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="">
                                                <Form.Label>{this.props.t(`page.article.editor.footer`)}</Form.Label>
                                                <Form.Control
                                                    required={false}
                                                    type="text"
                                                    placeholder={this.props.t(`page.article.editor.placeholder.footer`)}
                                                    value={footer || ""}
                                                    onChange={(e) => { this.onChangeSetUpperState('footer', e) }} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="">
                                                <Form.Label>{this.props.t(`page.article.editor.schema`)}</Form.Label>
                                                <Form.Control
                                                    required={false}
                                                    type="text"
                                                    placeholder={this.props.t(`page.article.editor.placeholder.schema`)}
                                                    value={schema || ""}
                                                    onChange={(e) => { this.onChangeSetUpperState('schema', e) }} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="required">
                                                <Form.Label>{this.props.t(`page.article.editor.category`)}</Form.Label>
                                                <Form.Control
                                                    required={true}
                                                    disabled={isLoading}
                                                    as="select"
                                                    custom
                                                    value={category || ""}
                                                    onChange={(e) => { this.onChangeSetUpperState('category', e) }}
                                                >
                                                    <option value="">{this.props.t(`page.article.editor.placeholder.category`)}</option>
                                                    {
                                                        articleCategories.map(rt => (
                                                            <option key={`rt-${rt}`} value={rt}>
                                                                {this.props.t(`common.articleCategory.${rt}`)}
                                                            </option>
                                                        ))
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mb-30">
                        <Col sm={12}>
                            <Form.Group>
                                <TextEditor
                                    required={false}
                                    disabled={isLoading}
                                    rows={20}
                                    value={html || ""}
                                    config={{
                                        allowedContent: true,
                                        removeFormatAttributes: '',
                                    }}
                                    onChange={
                                        (html) => {
                                            this.props.setUpperState(
                                                { html: html }
                                            )
                                        }
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-30">
                        <Col sm={12}>
                            <div className="pt-30">
                                <Button
                                    variant="success"
                                    size="lg"
                                    disabled={isLoading}
                                    type="submit"
                                >
                                    <Icon name="save" />
                                    <span className="pl-10">
                                        {this.props.t('page.article.editor.options.saveData')}
                                    </span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        );
    }
}

export default PageArticleEditor
