import './style.scss';
import React, {Fragment, Profiler} from 'react';
import _ from 'lodash';
import classnames from 'classnames/dedupe';
import { FormGroup, Table, Form, Tabs, Tab, Spinner, Row, Col, Modal, Button } from 'react-bootstrap';
import BaseComponent from '../../components/component/base';
import Icon from '../../components/icon';
import TextEditor from '../../components/text-editor';
import getProto from '../../proto';
import DebounceInput from 'react-debounce-input';
import { HelpCircle } from "react-feather";
import ReactTooltip from "react-tooltip";
import { Tree } from './tree';
import Select from "react-select";

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            activeTab1: 'basic',
            tree: [],
            variables: {},
            selectedObj: null,
            selectedLvl: null,
            loading: false,
            downloading: false,
            saving: false,
            modal: false,
            lastChange: null,
            selectedPath: [],
            definedVariables: {},
            productTypes: [],
            productsPurpose: 'roof',
            productTemplates: [],
            selectedTemplate: null,
        };
        this.treeUpdateStatus = this.treeUpdateStatus.bind(this);
        this.treeUpdateStatusInterval = undefined;
    }

    componentDidMount() {
        this.fetchConfig(() => {
            this.getAll();
        });
        this.treeUpdateStatusInterval = setInterval(() => {
            this.treeUpdateStatus()
        }, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.treeUpdateStatusInterval);
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild();
    }

    getAll() {
        this.setState({ loading: true, downloading: true }, () => {
            this.getTheTree(() => {
                this.getProductTemplates(() => {
                    this.setState({ loading: false, downloading: false });
                })
            });
        });
    }

    fetchConfig(callback) {
        this.props.api.post(
            `/project/data`,
            {
                "file": {
                    "names": ["product_types"]
                }
            }, (data) => {
                this.setState({ productTypes: (data.product_types || []) }, callback);
            }
        );
    }

    getProductTemplates(callback) {
        this.props.api.get(
            `/product-template/short-list`,
            (data) => {
                this.setState({
                    productTemplates: data,
                    selectedObj: null
                }, () => { callback() })
            }
        );
    }

    getTheTree(callback) {
        this.props.api.get(
            `/roof-product/tree-structure/${this.state.productsPurpose}`,
            (data) => {
                this.setState({
                    tree: data.tree,
                    definedVariables: data.variables,
                    lastChange: 'structure',
                    selectedObj: null
                }, () => { callback() })
            }
        );
    }

    setTheTree(newState) {
        this.setState(
            newState,
            () => {
                let newItem = document.getElementsByClassName(`highlight`);
                if (newItem && newItem.length) {
                    // this.maybeScrollPageToElement(newItem[0]);
                }
            }
        )
    }

    maybeScrollPageToBottom() {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        });
    }

    treeUpdateStatus() {
        if (this.state.saving) {
            this.props.showToast({
                title: this.props.t('common.toast.dataBeingSaved'),
                color: "info",
                duration: 2500,
            });
        }
    }

    setTreeInOrder(tree) {

        const g = [...tree].map((lvl0, lvl0i) => {
            return {
                ...lvl0,
                position: lvl0i,
                children: [...lvl0.children].map((lvl1, lvl1i) => {
                    return {
                        ...lvl1,
                        position: lvl1i,
                        children: [...(lvl1.children || [])].map((lvl2, lvl2i) => {
                            return {
                                ...lvl2,
                                position: lvl2i,
                            }
                        })
                    }
                })
            }
        });

        return g;
    }

    maybeSave(e) {
        if (e) {
            e.preventDefault();
        }
        let selectedObj = { ...this.state.selectedObj || {} };
        let selectedLvl = this.state.selectedLvl || 1;
        let selectedPath = [...this.state.selectedPath];
        this.setState({
            downloading: true,
            saving: true,
        }, () => {
            this.props.api.post(
                `/roof-product/tree-update`,
                {
                    tree: this.setTreeInOrder(this.state.tree),
                    purpose: this.state.productsPurpose
                },
                (data) => {
                    this.state.tree.forEach(obj => {
                        if (obj.uuid === selectedObj.uuid) {
                            selectedObj = { ...obj }
                        }
                    });
                    this.selectItem(selectedObj, selectedLvl, () => {
                        this.setState(
                            {
                                tree: data.tree,
                                downloading: false,
                                saving: false,
                                lastChange: "structure",
                                selectedPath: selectedPath,
                            },
                            () => {
                                this.props.showToast({
                                    title: this.props.t('common.toast.dataSaved'),
                                    color: "success"
                                });
                            })
                    });
                },
                (errorObject) => {
                    this.setState(
                        {
                            downloading: false,
                            saving: false
                        },
                        () => {
                            this.props.showToast({
                                errorObject: errorObject,
                                title: this.props.t('common.toast.error'),
                                color: 'danger'
                            });
                        }
                    );
                }
            );

            this.selectItem(null, null);
        });
    }

    onInvalid(e) {
        if (e) {
            e.preventDefault();
        }
        let errorObject = {
            "ident": "",
            "data": {
                "error": {
                    "ident": ""
                }
            }
        };
        if (
            e.target
            && e.target?.previousElementSibling
            && e.target.previousElementSibling.tagName === 'LABEL'
        ) {
            errorObject.ident = this.props.t(`common.tooltip.invalid`);
            errorObject.data.error.ident = e.target.previousElementSibling.innerText;
        }
        this.props.showToast({
            title: this.props.t('common.toast.checkInputData'),
            color: 'danger',
            errorObject: errorObject.ident ? errorObject : null
        });
    }

    export() {
        let self = this;
        self.setState({ loading: true });
        self.props.showToast({
            title: self.props.t('common.toast.exportStarter'),
            color: "info"
        });
        self.props.api.post(
            `/roof-product/tree-export`,
            {
                purpose: this.state.productsPurpose
            },
            (data) => {
                self.setState({ loading: false });
                if (data.file && data.file.link) {
                    window.open(data.file.link);
                }
            },
            (errorObject) => {
                this.setState({ loading: false });
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        );
    }

    selectItem(obj, lvl, callback) {
        let selectedPath = [obj?.parent];
        let lastParent = obj?.parent;

        for (let p = 0; p < 3; p++) {
            if (!lastParent) {
                break;
            }
            this.loopThrough((itm) => {
                if (itm.uuid === lastParent) {
                    selectedPath.push(lastParent);
                    lastParent = itm.parent;
                }
                return itm;
            });
        }

        selectedPath = selectedPath.filter(Boolean);

        if (obj?.template_uuid) {
            this.props.api.get(
                `/product-template/template/${obj.template_uuid}`,
                (data) => {
                    obj.template_uuid = data.uuid ?? '';
                    this.setState({
                        selectedTemplate: data,
                        selectedObj: {
                            ...obj,
                            template_uuid: data.uuid ?? ''
                        }
                    })
                }
            )
        } else {
            this.setState({
                selectedTemplate: null
            })
        }


        this.setState({
            activeTab1: 'basic',
            lastChange: 'item',
            selectedObj: obj,
            selectedLvl: lvl,
            selectedPath: selectedPath,
        }, () => {
            this.props.api.dispatchEvent('UPDATE_EDITOR', { editorId: "product-description-editor", content: obj?.descriptionIfIncluded || '' });
            this.dndIsSlowSoSelectElementManually(obj?.uuid);
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    dndIsSlowSoSelectElementManually(elementUuid) {
        let items = document.getElementsByClassName(`tree-item`);
        let item = document.getElementsByClassName(`tree-item-${elementUuid}`);
        for (let i = 0; i < items.length; i++) {
            items[i].classList.remove('current');
        }
        if (item && item.length) {
            item[0].classList.add('current');
        }
    }

    dndIsSlowSoToggleSubItemsManually(elementUuid) {
        let items = document.getElementsByClassName(`tree-group-with-parent-${elementUuid}`);
        for (let i = 0; i < items.length; i++) {
            items[i].classList.toggle('hidden');
        }
    }

    loopThrough(fn) {
        let preparedTree = this.state.tree;
        preparedTree = preparedTree.map(rootItm => {
            return this.diveIn(rootItm, fn, 0, null);
        });
        return preparedTree;
    }

    diveIn(obj, fn, level, parent) {
        obj = fn(obj, level, parent);
        if (obj.children && obj.children.length) {
            obj.children.map(child => {
                return this.diveIn(child, fn, level++, obj);
            });
        }
        return obj;
    }

    addItem(parentUuid, level, newItem) {
        let label = "";
        switch (level) {
            case 0:
                label = this.props.t('page.products.defaultLabelCategory');
                break

            case 1:
                label = this.props.t('page.products.defaultLabelGroup');
                break;

            case 2:
                label = this.props.t('page.products.defaultLabelProduct');
                break;
        }
        newItem = newItem || getProto('product', { label: label, className: 'highlight' });
        if (parentUuid) {
            let newTree = this.loopThrough((obj) => {
                if (obj.uuid === parentUuid) {
                    obj.children = obj.children.concat([newItem])
                        .sort((a, b) => (a.position > b.position) ? 1 : (a.position < b.position) ? -1 : 0);
                }
                return obj;
            });
            this.setTheTree({
                lastChange: "structure",
                tree: newTree,
            });
        } else {
            this.setTheTree({
                lastChange: "structure",
                tree: this.state.tree.concat([newItem])
                    .sort((a, b) => (a.position > b.position) ? 1 : (a.position < b.position) ? -1 : 0),
            });
        }
    }

    copyItem(item) {
        let newItem = {
            ...item,
            uuid: getProto('uuid'),
            label: `${item.label} (copy)`,
            className: 'highlight'
        };
        if (newItem.children) {
            newItem.children = newItem.children.map(lvl0Real => {
                let lvl0 = { ...lvl0Real };
                lvl0.uuid = getProto('uuid');
                lvl0.parent = newItem.uuid;
                lvl0.label = `${lvl0.label} (copy)`;
                lvl0.className = 'highlight';
                if (lvl0.children) {
                    lvl0.children = lvl0.children.map(lvl1Real => {
                        let lvl1 = { ...lvl1Real };
                        lvl1.uuid = getProto('uuid');
                        lvl1.parent = lvl0.uuid;
                        lvl1.label = `${lvl1.label} (copy)`;
                        lvl1.className = 'highlight';
                        if (lvl1.children) {
                            lvl1.children = lvl1.children.map(lvl2Real => {
                                let lvl2 = { ...lvl2Real };
                                lvl2.uuid = getProto('uuid');
                                lvl2.parent = lvl1.uuid;
                                lvl2.label = `${lvl2.label} (copy)`;
                                lvl2.className = 'highlight';
                                if (lvl2.children) {
                                    lvl2.children = lvl2.children.map(lvl3Real => {
                                        let lvl3 = { ...lvl3Real };
                                        lvl3.uuid = getProto('uuid');
                                        lvl3.parent = lvl2.uuid;
                                        lvl3.label = `${lvl3.label} (copy)`;
                                        lvl3.className = 'highlight';
                                        return lvl3;
                                    })
                                }
                                return lvl2;
                            })
                        }
                        return lvl1;
                    })
                }
                return lvl0;
            })
        }
        this.addItem(
            newItem.parent,
            undefined,
            newItem
        );
    }

    editItem(value, field) {
        const {
            selectedObj,
            selectedTemplate
        } = this.state;

        let newSelectedObj = { ...selectedObj, template_uuid: selectedTemplate?.uuid };

        let preparedTree = this.loopThrough((obj) => {
            if (obj.uuid === selectedObj?.uuid) {
                obj[field] = value;
                obj['template_uuid'] = selectedTemplate?.uuid;
                newSelectedObj = { ...obj, template_uuid: selectedTemplate?.uuid };
            }
            return obj;
        })
        this.setState({ tree: preparedTree, selectedObj: newSelectedObj, lastChange: "item" });
    }

    removeItem(shouldBeInTrash, uuid) {
        let preparedTree = this.loopThrough((obj) => {
            if (obj.uuid === uuid) {
                obj.trash = shouldBeInTrash;
                return obj;
            }
            return obj;
        });
        this.setTheTree({ tree: preparedTree, lastChange: "structure" });
    }

    getLabel(obj, level, dragProps) {

        const {
            selectedObj
        } = this.state;

        if (obj.createdSecondsAgo < 10) {
            obj.className = 'highlight';
        }

        const objLabel = (
            <>
                {
                    obj.trash
                        ?
                        (
                            <del className="text-danger mr-5">{obj.label}</del>
                        )
                        :
                        (
                            <span className={classnames("mr-5", "tree-item", `tree-item-${obj.uuid}`, obj.className, selectedObj && obj.uuid === selectedObj.uuid ? "current" : "")}>{obj.label}</span>
                        )
                }
            </>
        );

        const copyButton = (
            <>
                <button
                    type="button"
                    data-tip={this.props.t('common.tooltip.copy')}
                    className="btn btn-custom-round text-secondary ml-5"
                    onClick={(e) => { this.copyItem(obj) }}
                >
                    <Icon name="copy" />
                </button>

            </>
        );

        const editButton = (
            <>
                <button
                    type="button"
                    className="btn btn-link"
                    data-tip={this.props.t('common.tooltip.edit')}
                    style={{ textTransform: 'none' }}
                    onClick={(e) => {
                        this.selectItem(obj, level)
                    }}
                >
                    {objLabel}
                </button>
            </>
        )

        const addButton = (
            level > 2
                ?
                <></>
                :
                <>
                    <button
                        type="button"
                        className="btn btn-custom-round text-info ml-5"
                        data-tip={this.props.t('common.tooltip.add')}
                        onClick={(e) => { this.addItem(obj.uuid, level) }}
                    >
                        <Icon name="plus-square" />
                    </button>

                </>
        );

        const showSubItemsButton = (
            level > 2
                ?
                <></>
                :
                <>
                    <button
                        type="button"
                        className="btn btn-custom-round ml-5"
                        data-tip={this.props.t('common.tooltip.show')}
                        onClick={(e) => { this.dndIsSlowSoToggleSubItemsManually(obj.uuid) }}
                    >
                        <Icon name="chevrons-down" />
                    </button>

                </>
        );

        const removeButton = (
            <>
                <button
                    type="button"
                    className="btn btn-custom-round ml-5 text-danger"
                    data-tip={this.props.t('common.tooltip.remove')}
                    onClick={
                        (e) => {
                            this.confirmRemove(
                                () => {
                                    this.removeItem(true, obj.uuid)
                                }
                            )
                        }
                    }
                >
                    <Icon name="minus-square" />
                </button>
            </>
        )

        const moveButton = (
            <span
                style={{ cursor: "move" }}
                {...dragProps}
            >
                <Icon name="menu" />
            </span>
        )

        return (
            <>
                {moveButton}
                {showSubItemsButton}
                {editButton}
                {addButton}
                {copyButton}
                {removeButton}
            </>
        );
    }

    render() {
        const {
            selectedObj,
            selectedTemplate,
            selectedLvl,
            tree,
            activeTab1,
            loading,
            downloading,
            lastChange,
            selectedPath,
            definedVariables,
        } = this.state;

        const selectedTemplateData = this.state.productTemplates.find(
            (template) => template.uuid === selectedObj?.template_uuid
        );

        const objEditor = !_.isEmpty(selectedObj) && (

            <div className={classnames('p-30', _.isEmpty(selectedObj) ? 'd-none' : 'col-sm-12 col-md-8')}>
                <div className="sticky-top" style={{top: 100}}>
                    <Form method='post'
                          onSubmit={(e) => {
                              this.maybeSave(e)
                          }}
                          onInvalid={(e) => {
                              this.onInvalid(e)
                          }}>
                        <Tabs defaultActiveKey={`basic`} activeKey={activeTab1}
                              onSelect={(k) => this.setState({activeTab1: k})} className="nicebar">
                            <Tab eventKey="basic" title={this.props.t('page.products.basicData.title')}>
                                <div style={{maxHeight: `calc(100vh - 260px)`, overflowY: 'auto', overflowX: 'hidden'}}>

                                    {(selectedLvl === 1 || selectedLvl === 2) && (
                                        <FormGroup>
                                            <Form.Label>
                                                {this.props.t('page.products.basicData.name')}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={this.props.t('page.products.basicData.placeholder.name')}
                                                value={selectedObj?.label || ""}
                                                onChange={(e) => {
                                                    this.editItem(this.val(e), 'label')
                                                }}
                                            />
                                        </FormGroup>
                                    )}

                                    {(selectedLvl === 3) && (
                                        <FormGroup>
                                            <Form.Label>
                                                {this.props.t('page.products.basicData.template')}
                                            </Form.Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                isSearchable={true}
                                                placeholder={'...'}
                                                value={
                                                    selectedTemplateData
                                                        ? { value: selectedObj.template_uuid, label: selectedTemplateData.name}
                                                        : null
                                                }
                                                options={this.state.productTemplates.map(template => ({
                                                    value: template.uuid,
                                                    label: template.name
                                                }))}
                                                loadingMessage={() => this.props.t('common.select.loading')}
                                                filterOption={(option, inputValue) => option.label.toLowerCase().includes(inputValue.toLowerCase())}
                                                onChange={(newValue) =>
                                                    this.props.api.get(
                                                        `/product-template/template/${newValue.value}`,
                                                        (data) => {
                                                            this.setState({
                                                                selectedTemplate: data,
                                                                selectedObj: {
                                                                    ...selectedObj,
                                                                    template_uuid: newValue.value ?? ''
                                                                }
                                                            }, () => { this. editItem(this.val(newValue), 'template_uuid')})
                                                        }
                                                    )
                                                }
                                            />
                                        </FormGroup>
                                    )}

                                    {(selectedLvl === 1) && (
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Form.Label>
                                                        {this.props.t('page.products.basicData.category')}
                                                    </Form.Label>
                                                    <Form.Control as="select" value={selectedObj?.category || ""}
                                                                  onChange={(e) => {
                                                                      this.editItem(this.val(e), 'category')
                                                                  }}>
                                                        <option
                                                            value="">{this.props.t('page.products.basicData.empty')}</option>
                                                        <option
                                                            value="active">{this.props.t('page.products.basicData.active')}</option>
                                                        <option
                                                            value="passive">{this.props.t('page.products.basicData.passive')}</option>
                                                        {
                                                            selectedObj?.category === 'service' && (
                                                                <option
                                                                    value="service">{this.props.t('page.products.basicData.service')}</option>
                                                            )
                                                        }
                                                        <option
                                                            value="additional">{this.props.t('page.products.basicData.additional')}</option>
                                                        <option
                                                            value="other">{this.props.t('page.products.basicData.other')}</option>
                                                    </Form.Control>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}

                                    {(selectedLvl === 1) && (
                                        <Row>
                                            <Col md={12}>
                                                <Form.Check
                                                    type="switch"
                                                    id="required"
                                                    label={this.props.t('page.products.basicData.required')}
                                                    checked={selectedObj?.required || false}
                                                    onChange={(e) => {
                                                        this.editItem(!selectedObj?.required, 'required')
                                                    }}
                                                    className="mb-10"
                                                />
                                            </Col>
                                        </Row>
                                    )}

                                    {(selectedLvl === 1) && (
                                        <Row>
                                            <Col md={12}>
                                                <Form.Check
                                                    type="switch"
                                                    id="allowCopy"
                                                    label={this.props.t('page.products.basicData.allowCopy')}
                                                    checked={selectedObj?.allowCopy || false}
                                                    onChange={(e) => {
                                                        this.editItem(!selectedObj?.allowCopy, 'allowCopy')
                                                    }}
                                                    className="mb-10"
                                                />
                                            </Col>
                                        </Row>
                                    )}

                                    <Row>
                                        <Col md={12}>
                                            <Form.Check
                                                type="switch"
                                                id="hidden"
                                                label={this.props.t('page.products.basicData.hidden')}
                                                checked={selectedObj?.hidden || false}
                                                onChange={(e) => {
                                                    this.editItem(!selectedObj?.hidden, 'hidden')
                                                }}
                                                className="mb-10"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Check
                                                type="switch"
                                                id="visibleForWholesaler"
                                                label={this.props.t('page.products.basicData.visibleForWholesaler')}
                                                checked={selectedObj?.visibleForWholesaler || false}
                                                onChange={(e) => {
                                                    this.editItem(!selectedObj?.visibleForWholesaler, 'visibleForWholesaler')
                                                }}
                                                className="mb-10"
                                            />
                                        </Col>
                                    </Row>

                                    {
                                        selectedLvl === 2
                                            ?
                                            (
                                                <>
                                                    {
                                                        (this.props.hasPermission(['VIEW_PRODUCTS_SET_VAT_ZERO']).any) && (
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id="vatZero"
                                                                        label={this.props.t('page.products.basicData.vatZero')}
                                                                        checked={selectedObj?.vatZero || false}
                                                                        onChange={(e) => {
                                                                            this.editItem(!selectedObj?.vatZero, 'vatZero')
                                                                        }}
                                                                        className="mb-10"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }
                                                    <Row>
                                                        <Col md={12}>
                                                            <Form.Check
                                                                type="switch"
                                                                id="allowCustom"
                                                                label={this.props.t('page.products.basicData.allowCustom')}
                                                                checked={selectedObj?.allowCustom || false}
                                                                onChange={(e) => {
                                                                    this.editItem(!selectedObj?.allowCustom, 'allowCustom')
                                                                }}
                                                                className="mb-10"
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Form.Check
                                                                    type="switch"
                                                                    id="pvPowerSetter"
                                                                    label={this.props.t('page.products.basicData.pvPowerSetter')}
                                                                    checked={selectedObj?.pvPowerSetter || false}
                                                                    onChange={(e) => {
                                                                        this.editItem(!selectedObj?.pvPowerSetter, 'pvPowerSetter')
                                                                    }}
                                                                    className="mb-10"
                                                                />
                                                            </FormGroup>
                                                            {
                                                                selectedObj?.pvPowerSetter && (
                                                                    <Row>
                                                                        <Col sm={12} md={6} xl={3}>
                                                                            <FormGroup>
                                                                                <Form.Label>
                                                                                    {this.props.t('page.products.basicData.pvPower')}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder={this.props.t('page.products.basicData.placeholder.pvPower')}
                                                                                    value={selectedObj?.pvPower || ""}
                                                                                    onChange={(e) => {
                                                                                        this.editItem(this.val(e), 'pvPower')
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col sm={12} md={6} xl={3}>
                                                                            <FormGroup>
                                                                                <Form.Label>
                                                                                    {this.props.t('page.products.basicData.pvWidth')}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder={this.props.t('page.products.basicData.placeholder.pvWidth')}
                                                                                    value={selectedObj?.pvWidth || ""}
                                                                                    onChange={(e) => {
                                                                                        this.editItem(this.val(e), 'pvWidth')
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col sm={12} md={6} xl={3}>
                                                                            <FormGroup>
                                                                                <Form.Label>
                                                                                    {this.props.t('page.products.basicData.pvHeight')}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder={this.props.t('page.products.basicData.placeholder.pvHeight')}
                                                                                    value={selectedObj?.pvHeight || ""}
                                                                                    onChange={(e) => {
                                                                                        this.editItem(this.val(e), 'pvHeight')
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col sm={12} md={6} xl={3}>
                                                                            <FormGroup>
                                                                                <Form.Label>
                                                                                    {this.props.t('page.products.basicData.pvThick')}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder={this.props.t('page.products.basicData.placeholder.pvThick')}
                                                                                    value={selectedObj?.pvThick || ""}
                                                                                    onChange={(e) => {
                                                                                        this.editItem(this.val(e), 'pvThick')
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.unit')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.unit')}
                                                                    value={selectedObj?.unit || ""} onChange={(e) => {
                                                                    this.editItem(this.val(e), 'unit')
                                                                }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.variable')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.variable')}
                                                                    value={selectedObj?.variable || ""}
                                                                    onChange={(e) => {
                                                                        this.editItem(this.val(e), 'variable')
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.amountAuto')}
                                                                    <Button variant="link" className="px-5"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.setState({modal: true})
                                                                            }}>
                                                                        <HelpCircle size={12}/>
                                                                    </Button>
                                                                </Form.Label>
                                                                <DebounceInput
                                                                    className={
                                                                        selectedObj.amountAuto
                                                                        &&
                                                                        this.props.utils.calculate(
                                                                            {...definedVariables},
                                                                            selectedObj.amountAuto,
                                                                            true
                                                                        ) === this.props.utils.calculationGoneWrongValue
                                                                            ? 'form-control is-invalid' : 'form-control is-valid'
                                                                    }
                                                                    element="textarea"
                                                                    minLength={0}
                                                                    debounceTimeout={500}
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.amountAuto')}
                                                                    value={selectedObj?.amountAuto || ""}
                                                                    disabled={this.props.isLoading}
                                                                    onChange={(e) => {
                                                                        this.editItem(this.val(e), 'amountAuto')
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                            :
                                            <></>
                                    }

                                    {
                                        selectedLvl === 3
                                            ?
                                            (
                                                <>

                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.bcId')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.bcId')}
                                                                    value={selectedTemplate?.bcId || ""}
                                                                    onChange={(e) => {
                                                                        this.editItem(this.val(e), 'bcId')
                                                                    }}
                                                                    disabled={true}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.type')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    value={selectedTemplate?.type || ""}
                                                                    onChange={(e) => {
                                                                        this.editItem(this.val(e), 'type')
                                                                    }}
                                                                    disabled={true}
                                                                >
                                                                    <option
                                                                        value="">{this.props.t('page.products.basicData.productType.empty')}</option>
                                                                    {
                                                                        (this.state.productTypes || []).map(productType => {
                                                                            return (
                                                                                <option key={productType}
                                                                                        value={productType}>{productType}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Control>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.unit')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.unit')}
                                                                    value={selectedTemplate?.unit || ""}
                                                                    onChange={(e) => {
                                                                        this.editItem(this.val(e), 'unit')
                                                                    }}
                                                                    disabled={true}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.costPerUnit')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.costPerUnit')}
                                                                    value={selectedTemplate?.costPerUnit || ""}
                                                                    onChange={(e) => {
                                                                        this.editItem(this.val(e), 'costPerUnit')
                                                                    }}
                                                                    disabled={true}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.minMargin')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    min="0"
                                                                    max="99.9999"
                                                                    step="0.01"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.minMargin')}
                                                                    value={selectedObj?.minMargin || ""}
                                                                    onChange={(e) => {
                                                                        this.editItem(this.val(e), 'minMargin')
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.defaultMargin')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    min="0"
                                                                    max="99.9999"
                                                                    step="0.01"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.defaultMargin')}
                                                                    value={selectedObj?.defaultMargin || ""}
                                                                    onChange={(e) => {
                                                                        this.editItem(this.val(e), 'defaultMargin')
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.maxMargin')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    min="0"
                                                                    max="99.9999"
                                                                    step="0.01"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.maxMargin')}
                                                                    value={selectedObj?.maxMargin || ""}
                                                                    onChange={(e) => {
                                                                        this.editItem(this.val(e), 'maxMargin')
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.amountAuto')}
                                                                    <Button variant="link" className="px-5"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.setState({modal: true})
                                                                            }}>
                                                                        <HelpCircle size={12}/>
                                                                    </Button>
                                                                </Form.Label>
                                                                <DebounceInput
                                                                    className={
                                                                        selectedObj.amountAuto
                                                                        &&
                                                                        this.props.utils.calculate(
                                                                            {...definedVariables},
                                                                            selectedObj.amountAuto,
                                                                            true
                                                                        ) === this.props.utils.calculationGoneWrongValue
                                                                            ? 'form-control is-invalid' : 'form-control is-valid'
                                                                    }
                                                                    element="textarea"
                                                                    minLength={0}
                                                                    debounceTimeout={500}
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.amountAuto')}
                                                                    value={selectedObj?.amountAuto || ""}
                                                                    disabled={this.props.isLoading}
                                                                    onChange={(e) => {
                                                                        this.editItem(this.val(e), 'amountAuto')
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                            :
                                            <></>
                                    }
                                </div>
                            </Tab>

                            <Tab eventKey="description" title={this.props.t('page.products.description.title')}
                                 disabled={selectedLvl !== 2}>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group>
                                            <TextEditor
                                                required={false}
                                                rows={3}
                                                editorId="product-description-editor"
                                                value={selectedObj?.descriptionIfIncluded || ""}
                                                onChange={
                                                    (html) => {
                                                        this.editItem(html, 'descriptionIfIncluded')
                                                    }
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Tab>

                        </Tabs>
                        <div className="border-top mt-20 pt-20">
                            <button
                                type="submit"
                                className="btn btn-uniform btn-success"
                                disabled={loading}
                            >
                                <Icon name="save"/>
                                <span className="pl-10">
                                    {this.props.t('page.products.saveData')}
                                </span>
                            </button>

                            <button
                                type="button"
                                className="btn btn-uniform btn-default float-right"
                                disabled={loading}
                                onClick={(e) => {
                                    this.selectItem(null, null)
                                }}
                            >
                                <Icon name="x"/>
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        )

        return (
            <Fragment>

                <ReactTooltip/>


                <Modal
                    show={this.state.modal}
                    onHide={() => { this.setState({ modal: false }) }}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.products.basicData.amountAutoHint.title')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mb-10">
                            {this.props.t('page.products.basicData.amountAutoHint.line0')}
                        </p>
                        <p className="mb-10">
                            <samp>
                                {this.props.t('page.products.basicData.amountAutoHint.line1')}
                            </samp>
                        </p>
                        <p className="mb-10">
                            {this.props.t('page.products.basicData.amountAutoHint.line2')}
                        </p>
                        <ul className="mb-15 pre code" style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', padding: 10, overflowX: 'scroll', listStyle: 'none' }}>
                            {
                                Object.keys({ ...definedVariables }).map(defvar =>
                                    <li className='pr-20' key={`hint-${defvar}`}>{this.props.t(`page.products.basicData.amountAutoHint.${defvar}`, defvar)}</li>
                                )
                            }
                        </ul>
                        <p className="mb-10">
                            {this.props.t('page.products.basicData.amountAutoHint.line3')}
                        </p>
                        <p className="mb-10">
                            {this.props.t('page.products.basicData.amountAutoHint.line4')}
                        </p>
                        <p className="mb-10">
                            {this.props.t('page.products.basicData.amountAutoHint.line5')}
                        </p>
                        <p className="mb-10">
                            <samp>
                                {this.props.t('page.products.basicData.amountAutoHint.line6')}
                            </samp>
                        </p>
                        <p className="mb-10">
                            {this.props.t('page.products.basicData.amountAutoHint.line7')}
                        </p>
                        <p className="mb-10">
                            <samp>
                                {this.props.t('page.products.basicData.amountAutoHint.line8')}
                            </samp>
                        </p>
                        <p className="mb-0">
                            {this.props.t('page.products.basicData.amountAutoHint.line9')}
                        </p>
                    </Modal.Body>
                </Modal>

                <div className="row border py-10">
                    <div className="col-sm-12 justify-content-between">
                        <div className="row justify-content-between">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center">
                                    <div className="input-group-prepend">
                                        <button
                                            type="button"
                                            disabled={loading}
                                            className="btn btn-uniform text-success mr-10"
                                            onClick={(e) => {
                                                this.maybeSave(e)
                                            }}
                                        >
                                            <Icon name="save" />
                                            <span className="pl-10">
                                                {this.props.t('common.operation.save')}
                                            </span>
                                        </button>
                                    </div>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <button
                                                type="button"
                                                disabled={loading}
                                                className="btn btn-uniform text-info mr-10"
                                                onClick={(e) => {
                                                    this.addItem(null, 0);
                                                    this.maybeScrollPageToBottom()
                                                }}
                                            >
                                                <Icon name="plus-square"/>
                                                <span className="pl-10">
                                                    {this.props.t('page.products.newCategory')}
                                                </span>
                                            </button>
                                        </div>
                                        <div className="input-group-prepend">
                                            <button
                                                type="button"
                                                disabled={loading}
                                                className="btn btn-uniform text-secondary mr-10"
                                                onClick={(e) => {
                                                    this.export()
                                                }}
                                            >
                                                <Icon name="download"/>
                                                <span className="pl-10">
                                                    {this.props.t('common.operation.export')}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row border-left border-bottom border-right">

                    <div className={classnames('col-sm-12 overflow-auto p-0', _.isEmpty(selectedObj) ? 'col-md-12' : 'col-md-4 border-right')}>
                        {
                            downloading
                                ?
                                (
                                    <div className="text-center p-30">
                                        <Spinner animation="border"/>
                                    </div>
                                )
                                :
                                (
                                    <Tree
                                        tree={tree}
                                        lastChange={lastChange}
                                        setTheTree={(data) => this.setTheTree(data)}
                                        getLabel={(obj, level, dragProps) => this.getLabel(obj, level, dragProps)}
                                        loopThrough={(fn) => this.loopThrough(fn)}
                                        selectedPath={selectedPath}
                                    />
                                )
                        }
                    </div>

                    {objEditor}

                </div>
            </Fragment >
        );
    }
}

export default Content
