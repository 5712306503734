import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';

class AuthLogoutPage extends Component {

    componentDidMount() {
        this.logoutRoutine();
    }

    logoutRoutine() {
        const currentToken = this.props.api.getToken();
        this.props.api.post(
            `/auth/logout`,
            {},
            () => { this.props.api.setToken(null); this.props.api.deRegisterTokens(); this.props.reload('/auth/login/') },
            () => { this.props.api.setToken(null); this.props.api.deRegisterTokens(); this.props.reload('/auth/login/') },
        );
    }

    render() {
        return (
            <div className="p-50 text-center">
                <Spinner animation="border" />
            </div>
        );
    }
}

export default AuthLogoutPage;
