import React, { Fragment } from 'react';
import { Row, Col, Modal, Form, Button, Tabs, Tab, InputGroup } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import BaseComponent from '../../components/component/base';
import Icon from '../../components/icon';
import SelectSync from '../../components/select/sync';
import { Info } from 'react-feather';

class ModalFillForm extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            selectedFormUuid: "",
            selectedFormSchema: null,
            fuseOptions: [],
            riktaRattOptions: [],
            schema: [],
            fileIdent: "",
            formArticles: [],
        }
        this.renderSubSection = this.renderSubSection.bind(this);
    }

    componentDidMount() {
        Promise.all([
            this.props.api.fetch({ method: 'GET', rawUrl: "/assets/data/fuse.json" }),
            this.props.api.fetch({ method: 'GET', rawUrl: "/assets/data/rikta-ratt.json" }).then((data) => data.sort((a, b) => a.hasAdditionalData > b.hasAdditionalData ? -1 : 1)),
            this.props.fetchFormArticles && this.props.api.fetch({ method: 'POST', url: "/article/list", data: { ...this.props.fetchFormArticles } }).then((obj) => obj.data.articles || []).then((articles) => articles.filter(article => article.schema)),
        ]).then(allResponses => {
            this.setState({
                fuseOptions: allResponses[0] || [],
                riktaRattOptions: allResponses[1] || [],
                formArticles: this.props.fetchFormArticles ? allResponses[2] : this.props.formArticles,
            });
        });
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild();
    }

    fetchSchema(schema, callback) {
        fetch(`/assets/data/schema/${schema}`)
            .then((res) => res.json())
            .then((data) => this.setState({ schema: data, fileIdent: data.fileIdent }, callback));
    }

    setForm(selectedFormUuid) {
        let { upperState } = this.props;
        const { formArticles } = this.state;
        if (selectedFormUuid) {
            let selectedFormData = formArticles.find(itm => itm.uuid === selectedFormUuid);
            if (selectedFormData.schema) {
                this.fetchSchema(selectedFormData.schema, () => {
                    let selectedFormSchema = this.mapFields(this.state.schema, (field) => {
                        switch (field.value) {
                            case "fullName":
                                field.value = [upperState.firstName, upperState.lastName].join(' ')
                                break;

                            case "offeredPvPower":
                                field.value = upperState.offeredPvPower || "";
                                break;

                            case "fuse":
                                field.value = upperState.fuse && upperState.fuse.length ? this.state.fuseOptions.find(itm => itm.value === upperState.fuse[0].ident)?.label || "" : "";
                                break;

                            default:
                                field.value = field.valueWhenSelected === field.value ? field.value : upperState[field.value] || "";
                                break;
                        }
                        return field;
                    });
                    this.setState({
                        selectedFormUuid: selectedFormUuid,
                        selectedFormSchema: selectedFormSchema
                    })
                })
            } else {
                this.setState({
                    selectedFormUuid: selectedFormUuid,
                    selectedFormSchema: {}
                })
            }
        } else {
            this.setState({
                selectedFormUuid: "",
                selectedFormSchema: null
            })
        }
    }

    mapFields(selectedFormSchema, fieldCallback, subSectionCallback) {
        if (typeof fieldCallback !== "function") {
            fieldCallback = (e) => { return e };
        }
        if (typeof subSectionCallback !== "function") {
            subSectionCallback = (e) => { return e };
        }
        selectedFormSchema.sections = selectedFormSchema.sections.map(mainSection => {
            let newMainSection = { ...mainSection };
            newMainSection.sections = mainSection.sections.map(subSection => {
                let newSubSection = { ...subSection };
                newSubSection = subSectionCallback(newSubSection);
                newSubSection.fields = subSection.fields.map(field => {
                    let newField = { ...field };
                    newField = fieldCallback(newField);
                    return newField;
                })
                return newSubSection;
            })
            return newMainSection;
        });
        return selectedFormSchema;
    }

    setValue(fieldName, fieldValue) {
        let selectedFormSchema = { ...this.state.selectedFormSchema };

        selectedFormSchema = this.mapFields(selectedFormSchema, (field) => {
            if (field.name === fieldName) {
                field.value = fieldValue;
            }
            return field;
        })

        if (fieldName.includes("inverterFromRiktaRatt")) {
            let inverterParameters = this.state.riktaRattOptions.find(itm => itm.value === fieldValue);
            let inverterIndex = parseInt(fieldName.match(/\d+$/)[0]);
            selectedFormSchema = this.mapFields(selectedFormSchema, (field) => {
                if (field.name === `inverterRatedPower${inverterIndex}`) {
                    field.value = inverterParameters.power ? inverterParameters.power : "";
                }
                if (field.name === `inverterPowerFactor${inverterIndex}`) {
                    field.value = inverterParameters.powerFactor ? inverterParameters.powerFactor : "";
                }
                if (field.name === `inverterMaxShortCurrent${inverterIndex}`) {
                    field.value = inverterParameters.maxShortCurrent ? inverterParameters.maxShortCurrent : "";
                }
                if (field.name === `inverterAmount${inverterIndex}`) {
                    field.value = '1';
                }
                return field;
            })
        }

        this.setState({ selectedFormSchema: selectedFormSchema });
    }

    processGridOwnerName(e) {
        if (e) {
            e.preventDefault();
        }
        const { lat, lng } = this.props.upperState.mapCenter;
        this.props.api.post(`/search/natomraden`, { lat, lng },
            (data) => {
                this.setValue("gridOwnerName", data.gridOwnerName)
            },
            (errorObject) => {
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        )
    }

    renderSubSection(subSection) {
        let rows = [];
        let cols = [];

        subSection.fields.forEach((field, findex) => {
            let fieldName = `${field.name}`;

            if (!field.labeled) {
                field.labeled = (
                    <>
                        {field.label}
                        {field.tooltip && (
                            <i data-tip={field.tooltip}>
                                <Info size={14} />
                            </i>
                        )
                        }
                        :
                    </>
                );
            }

            cols.push(
                <Col key={`${fieldName}-${findex}-col`} sm={12} md={subSection.fields.length > 1 ? 6 : 12} lg={6}>
                    {
                        field.name === "gridOwnerName" ? (
                            <Form.Group>
                                <Form.Label>
                                    {field.labeled}
                                </Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        disabled={this.props.isLoading || this.props.closed || field.disabled}
                                        type="text"
                                        id={`${findex}-${fieldName}-field`}
                                        value={field.value || ""}
                                        onChange={e => this.setValue(fieldName, e.target.value)}
                                    />
                                    <InputGroup.Append>
                                        <Button
                                            variant="outline-secondary"
                                            disabled={this.props.isLoading || this.props.closed || field.disabled}
                                            onClick={(e) => { this.processGridOwnerName(e) }}>
                                            <Icon name="refresh-cw" height={12} />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>

                        ) : field.type === "text" ? (
                            <Form.Group>
                                <Form.Label>
                                    {field.labeled}
                                </Form.Label>
                                <Form.Control
                                    disabled={this.props.isLoading || this.props.closed || field.disabled}
                                    type="text"
                                    id={`${findex}-${fieldName}-field`}
                                    value={field.value || ""}
                                    onChange={e => this.setValue(fieldName, e.target.value)}
                                />
                            </Form.Group>
                        ) : field.type === "number" ? (
                            <Form.Group>
                                <Form.Label>
                                    {field.labeled}
                                </Form.Label>
                                <Form.Control
                                    disabled={this.props.isLoading || this.props.closed || field.disabled}
                                    type="number"
                                    id={`${findex}-${fieldName}-field`}
                                    value={field.value || ""}
                                    onChange={e => this.setValue(fieldName, e.target.value)}
                                />
                            </Form.Group>
                        ) : field.type === "checkbox" ? (
                            <Form.Group>
                                <Form.Check
                                    disabled={this.props.isLoading || this.props.closed || field.disabled}
                                    type="switch"
                                    id={`${findex}-${fieldName}-field`}
                                    checked={field.value === field.valueWhenSelected}
                                    onChange={e => this.setValue(fieldName, field.value === field.valueWhenSelected ? "" : field.valueWhenSelected)}
                                    label={field.labeled} />
                            </Form.Group>
                        ) : field.type === "select" ? (
                            <Form.Group>
                                <Form.Label>
                                    {field.labeled}
                                </Form.Label>
                                <SelectSync
                                    t={this.props.t}
                                    api={this.props.api}
                                    showToast={this.props.showToast}
                                    placeholder={this.props.t(`common.placeholder.any`)}
                                    disabled={this.props.isLoading || this.props.closed || field.disabled}
                                    cache={true}
                                    preload={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    isMulti={field.multiple || false}
                                    options={Array.isArray(field.values) ? field.values : Array.isArray(this.state[field.values]) ? this.state[field.values] : Array.isArray(this.props[field.values]) ? this.props[field.values] : []}
                                    value={field.value}
                                    onChange={e => this.setValue(fieldName, e?.value)}
                                />
                            </Form.Group>
                        ) : field.type === "textarea" ? (
                            <Form.Group>
                                <Form.Label>
                                    {field.labeled}
                                </Form.Label>
                                <Form.Text
                                    disabled={this.props.isLoading || this.props.closed || field.disabled}
                                    as="textarea"
                                    rows={5}
                                    id={`${findex}-${fieldName}-field`}
                                    value={field.value || ""}
                                    onChange={e => this.setValue(fieldName, e.target.value)}
                                    className="form-control"
                                    maxLength={field.maxLength || 10000}
                                />
                            </Form.Group>
                        ) : field.type === "file" ? (
                            <Form.Group>
                                <Form.Label>
                                    {field.labeled}
                                </Form.Label>
                                <Form.Control
                                    disabled={this.props.isLoading || this.props.closed || field.disabled}
                                    type="file"
                                    multiple={field.multiple}
                                    accept={field.accept}
                                    id={`${findex}-${fieldName}-field`}
                                    value={field.value || ""}
                                    onChange={e => {
                                        const files = e.target.files || [];
                                        for (let f = 0; f < files.length; f++) {
                                            if ((files[f].size / 1024) > 1024) {
                                                return this.props.showToast({
                                                    title: this.props.t('common.toast.maxmb'),
                                                    color: "danger"
                                                });
                                            }
                                        }
                                        this.setValue(fieldName, e.target.value)

                                    }}
                                />
                            </Form.Group>
                        ) : (
                            <></>
                        )
                    }
                </Col>
            )
        })
        rows.push(
            <Row className="p-1" key={`${subSection.label}-row`}>
                {cols}
            </Row>
        )
        return rows;
    }

    submitForm(e) {
        if (e) {
            e.preventDefault();
        }
        let tokens = {
            totalNumberOfInverters: 0,
            totalPowerOfInverters: 0
        };

        this.mapFields(this.state.selectedFormSchema, (field) => {
            let fieldName = field.name;
            let val = String(field.value).replace("✔️", "");
            tokens[fieldName] = val;
            return field;
        });

        Object.keys(tokens).forEach(fieldName => {
            if (fieldName.includes("inverterFromRiktaRatt")) {
                let inverterIndex = parseInt(fieldName.match(/\d+$/)[0]);
                let inverterAmount = this.parseFloatFixed(tokens[`inverterAmount${inverterIndex}`]);
                let inverterPower = this.parseFloatFixed(tokens[`inverterRatedPower${inverterIndex}`]);
                tokens.totalNumberOfInverters += inverterAmount;
                tokens.totalPowerOfInverters += inverterAmount * inverterPower;
            }
        });

        this.props.onSubmit(this.state.selectedFormUuid, this.state.fileIdent, tokens);
    }

    render() {
        return (
            <Fragment>

                <Modal
                    show={this.props.show}
                    onHide={() => { this.props.onHide() }}
                    backdrop="static"
                    keyboard={false}
                    size={this.state.selectedFormUuid ? "xl" : "sm"}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.calculation.files.modal.fillFormParams.title')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form method="post" onSubmit={(e) => { this.submitForm(e) }}>
                            <ReactTooltip />
                            <Row>
                                <Col sm={12}>
                                    <Form.Group>
                                        <Form.Label>{this.props.t(`page.calculation.files.modal.fillFormParams.form`)}</Form.Label>
                                        <Form.Control
                                            required={false}
                                            disabled={this.props.isLoading || this.props.closed}
                                            as="select"
                                            custom
                                            value={this.state.selectedFormUuid || ""}
                                            onChange={(e) => { this.setForm(e.target.value) }}
                                        >
                                            <option value="">...</option>
                                            {
                                                this.state.formArticles.map(form => (
                                                    <option key={form.uuid} value={form.uuid}>{form.title}</option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                                this.state.selectedFormSchema && (
                                    <Tabs
                                        className="m-0 align-content-between nicebar"
                                        id="modal-fill-form"
                                    >
                                        {
                                            this.state.selectedFormSchema.sections.map(mainSection => (
                                                <Tab key={mainSection.label} title={mainSection.label} eventKey={mainSection.label}>
                                                    {
                                                        mainSection.sections.map(subSection => (
                                                            <div key={`${mainSection.label}-${subSection.label}-div`}>
                                                                <Row>
                                                                    <Col sm={12}>
                                                                        <Form.Label>
                                                                            <strong>
                                                                                {subSection.label}
                                                                            </strong>
                                                                        </Form.Label>
                                                                    </Col>
                                                                </Row>
                                                                {this.renderSubSection(subSection)}
                                                            </div>
                                                        ))
                                                    }
                                                </Tab>
                                            ))
                                        }
                                    </Tabs>
                                )
                            }
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="p-20 border-top">
                        <Button
                            variant="success"
                            disabled={this.props.isLoading || this.props.closed || !this.state.selectedFormSchema}
                            onClick={(e) => { this.submitForm(e) }}>
                            {this.props.t('page.calculation.files.modal.fillFormParams.generate')}
                        </Button>
                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}
export default ModalFillForm;