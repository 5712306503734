import React, { Fragment } from 'react';
import Datatable from "../../components/datatable";
import { Row, Col, Card } from "react-bootstrap";
import Icon from '../../components/icon';
import BaseComponent from '../../components/component/base';

class Content extends BaseComponent {

    render() {
        return (
            <Fragment>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Header className="px-10 py-10 background-white">
                                <button
                                    type="button"
                                    className="btn btn-uniform text-success"
                                    disabled={false}
                                    onClick={(e) => { this.props.openLink('/article/') }}
                                >
                                    <Icon name="plus-square" />
                                    <span className="pl-10">
                                        {this.props.t('page.articles.newArticle')}
                                    </span>
                                </button>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Datatable
                                    id={'article-table'}
                                    endpoint={`article/table`}
                                    withData={{}}
                                    columns={[
                                        {
                                            Header: this.props.t('page.articles.table.category'),
                                            accessor: "category_1",
                                            width: 150
                                        },
                                        {
                                            Header: this.props.t('page.articles.table.title'),
                                            accessor: "title_2"
                                        },
                                        {
                                            Header: (<>&nbsp;</>),
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                            width: 150
                                        }
                                    ]}
                                    sort={[
                                        {
                                            id: "title_2",
                                            desc: false
                                        }
                                    ]}
                                    badges={[
                                        {
                                            "field": "category_1",
                                            "badgify": true,
                                            "divClass": "text-left"
                                        }
                                    ]}
                                    withCheckbox={false}
                                    onChecked={(data) => { }}
                                    actions={[
                                        {
                                            action: "edit",
                                            callback: (data) => {
                                                this.props.openLink(`/article/${data.uuid_0}/`)
                                            }
                                        },
                                        {
                                            action: 'delete',
                                            callback: (data) => {
                                                this.confirmRemove(() => {
                                                    this.props.api.post(
                                                        `/article/trash`,
                                                        {
                                                            "article": {
                                                                "uuids": [data.uuid_0]
                                                            }
                                                        },
                                                        () => {
                                                            this.props.api.dispatchEvent('RELOAD_TABLE');
                                                        }
                                                    )
                                                })
                                            }
                                        }
                                    ]}
                                    {...this.props}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }

}

export default Content
