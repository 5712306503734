import React, { Fragment } from 'react';
import { Table } from "react-bootstrap";
import LowerElementsComponent from '../../components/component/lower-elements';

class OfferSummary extends LowerElementsComponent {

    render() {

        const {
            offerElements,
            costTotal,
            costTotalGross,
            discountTotal,
            marginTotal
        } = this.props

        return (
            <Fragment>
                <Table size="xs" className="mb-0" responsive>
                    <thead>
                        <tr>
                            <th scope="col" style={{ minWidth: 150 }} className="pl-20">
                                {this.props.t('page.calculation.offer.summary.table.offer')}
                            </th>
                            <th scope="col" style={{ minWidth: 150 }} className="pl-10">
                                {this.props.t('page.calculation.offer.summary.table.product')}
                            </th>
                            {
                                this.props.hasPermission(['VIEW_OFFER_MARGIN']).any &&
                                (
                                    <th scope="col" style={{ width: 80 }} className="pr-10 text-right">
                                        {this.props.t('page.calculation.offer.summary.table.margin')}
                                    </th>
                                )
                            }
                            {
                                this.props.hasPermission(['VIEW_OFFER_DISCOUNT']).any && (
                                    <th scope="col" style={{ width: 80 }} className="pr-10 text-right">
                                        {this.props.t('page.calculation.offer.summary.table.discount')}
                                    </th>
                                )
                            }
                            <th scope="col" style={{ width: 80 }} className="pr-10 text-right">
                                {this.props.t('page.calculation.offer.summary.table.value')}
                            </th>
                            <th scope="col" style={{ width: 80 }} className="pr-20 text-right">
                                {this.props.t('page.calculation.offer.summary.table.gross')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            offerElements.map((mainSection) => {
                                let selectedSubSection = null;
                                if (mainSection.value && mainSection.children) {
                                    selectedSubSection = mainSection.children.find(element => element.uuid === mainSection.value);
                                }
                                if (!selectedSubSection) {
                                    return null;
                                }
                                return (
                                    <tr key={mainSection.uuid}>
                                        <td className="align-middle pl-20">{mainSection.label}</td>
                                        <td className="align-middle">{selectedSubSection.label}</td>
                                        {
                                            this.props.hasPermission(['VIEW_OFFER_MARGIN']).any &&
                                            (
                                                <td className="align-middle pr-10 text-right">{this.parseFloatFixed(selectedSubSection.marginTotal).toFixed(2)}</td>
                                            )
                                        }
                                        {
                                            this.props.hasPermission(['VIEW_OFFER_DISCOUNT']).any && (
                                                <td className="align-middle pr-10 text-right">{this.parseFloatFixed(selectedSubSection.discountTotal).toFixed(2)}</td>
                                            )
                                        }
                                        <td className="align-middle pr-10 text-right">{this.parseFloatFixed(selectedSubSection.costTotal).toFixed(2)}</td>
                                        <td className="align-middle pr-20 text-right">{this.parseFloatFixed(selectedSubSection.costTotalGross).toFixed(2)}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2" className="align-middle pl-20 text-right" >
                                <strong>
                                    {this.props.t('page.calculation.offer.summary.table.sum')}
                                </strong>
                            </td>
                            {
                                this.props.hasPermission(['VIEW_OFFER_MARGIN']).any &&
                                (
                                    <td className="align-middle pr-10 text-right" style={{ width: 120 }}>
                                        <strong>{this.parseFloatFixed(marginTotal).toFixed(2)}</strong>
                                    </td>
                                )
                            }
                            {
                                this.props.hasPermission(['VIEW_OFFER_DISCOUNT']).any && (
                                    <td className="align-middle pr-10 text-right" style={{ width: 120 }}>
                                        <strong>{this.parseFloatFixed(discountTotal).toFixed(2)}</strong>
                                    </td>
                                )
                            }
                            <td className="align-middle text-right" style={{ width: 80 }}>
                                <strong>{this.parseFloatFixed(costTotal).toFixed(2)}</strong>
                            </td>
                            <td className="align-middle pr-20 text-right" style={{ width: 80 }}>
                                <strong>{this.parseFloatFixed(costTotalGross).toFixed(2)}</strong>
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </Fragment>

        );
    }
}
export default OfferSummary;