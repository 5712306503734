// https://transform.tools/
import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            height={60.890751}
            viewBox="0 0 16.110678 16.110678"
            width={60.890751}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                transform="translate(-18.021 -334.125)"
                fillOpacity={1}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={4}
                strokeDasharray="none"
                strokeDashoffset={0}
                strokeOpacity={1}
            >
                <circle
                    cx={26.076485}
                    cy={342.17984}
                    r={8.0553389}
                    opacity={1}
                    fill="#ffc107"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M20.609 336.275a8.055 8.055 0 00-2.588 5.905 8.055 8.055 0 008.056 8.055 8.055 8.055 0 007.004-4.088 8.055 8.055 0 01-.46.405 8.055 8.055 0 01-.655.468 8.055 8.055 0 01-.7.401 8.055 8.055 0 01-.734.329 8.055 8.055 0 01-.764.254 8.055 8.055 0 01-.786.176 8.055 8.055 0 01-.8.097 8.055 8.055 0 01-.569.02 8.055 8.055 0 01-.804-.04 8.055 8.055 0 01-.796-.12 8.055 8.055 0 01-.78-.2 8.055 8.055 0 01-.757-.275 8.055 8.055 0 01-.725-.35 8.055 8.055 0 01-.686-.422 8.055 8.055 0 01-.64-.487 8.055 8.055 0 01-.59-.549 8.055 8.055 0 01-.532-.605 8.055 8.055 0 01-.468-.655 8.055 8.055 0 01-.4-.698 8.055 8.055 0 01-.33-.735 8.055 8.055 0 01-.253-.764 8.055 8.055 0 01-.177-.786 8.055 8.055 0 01-.097-.8 8.055 8.055 0 01-.02-.569 8.055 8.055 0 01.04-.804 8.055 8.055 0 01.12-.796 8.055 8.055 0 01.2-.78 8.055 8.055 0 01.276-.757 8.055 8.055 0 01.35-.725 8.055 8.055 0 01.065-.105z"
                    opacity={1}
                    fill="#ff9800"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <ellipse
                    cx={28.74918}
                    cy={338.57098}
                    rx={0.70726985}
                    ry={0.69707364}
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
                <ellipse
                    cx={23.40379}
                    cy={338.57098}
                    rx={0.70726985}
                    ry={0.69707364}
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
                <path
                    d="M29.588 344.818s-6.414 2.139-8.486-2.004"
                    opacity={1}
                    fill="none"
                    stroke="#1a1a1a"
                    strokeWidth={1.0583334}
                />
            </g>
        </svg>
    )
}

export default SvgComponent
