class Utils {

    constructor() {
        this.calculationGoneWrongValue = -999_999_999_999;
    }

    canUseDOM() {
        return !!(
            typeof window !== 'undefined' &&
            window.document &&
            window.document.createElement
        );
    }

    getUUID() {
        return (
            Number(String(Math.random()).slice(2)) +
            Date.now() +
            Math.round(window.performance.now())
        ).toString(36);
    }

    isUUID(uuid) {
        let s = "" + uuid;
        s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
        if (s === null) {
            return false;
        }
        return true;
    }

    fileSizeToHumanReadable(size) {
        const i = Math.floor(Math.log(size) / Math.log(1024));
        return ((size / Math.pow(1024, i)).toFixed(2) * 1) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    calculate(vars, funcBody, debug) {
        // usage: utils.calculate({"foo":10, "bar":100}, "foo+bar")
        let ctx = { ...vars };
        let newBody = [];
        for (let k in ctx) {
            let i = "let " + k + " = ctx['" + k + "'];";
            newBody.push(i);
        }
        let res = "return function(){ return Math.round((" + funcBody + ") * 1000 + Number.EPSILON) / 1000 }";
        newBody.push(res);
        try {
            // eslint-disable-next-line no-new-func
            let F = new Function("ctx", newBody.join(''));
            return F(ctx)();
        } catch (e) {
            if (debug) {
                console.log(e.name, 'in', funcBody);
            }
        }
        return this.calculationGoneWrongValue;
    }

    isValidEmail(email) {
        const rx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return rx.test(email);
    }

    isValidPlPesel(value) {
        let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
        let sum = 0;
        let controlNumber = parseInt(value.substring(10, 11));

        for (let i = 0; i < weight.length; i++) {
            sum += (parseInt(value.substring(i, i + 1)) * weight[i]);
        }
        sum = sum % 10;
        return (10 - sum) % 10 === controlNumber;
    }

    isValidSvSsn(value) {
        const rx = /^(19|20)?(\d{6}([-+]|\s)\d{4}|(?!19|20)\d{10})$/;
        return rx.test(value);
    }

    isValidSvAnlaggningsId(value) {
        value = value.replace(/\s+/g, '').replace(/\-/g, '')
        return value.startsWith("735999") && value.length === 18;
    }

    isValid(required, value, validator) {
        value = String(value);
        if (!required && !value) {
            return true;
        }
        if (validator === 'pl-pesel') {
            return this.isValidPlPesel(value);
        }
        if (validator === 'sv-ssn') {
            return this.isValidSvSsn(value);
        }
        if (validator === 'sv-anlaggnings-id') {
            return this.isValidSvAnlaggningsId(value);
        }
        if (validator === 'email') {
            return this.isValidEmail(value);
        }
        return true;
    }

    minimumPasswordComplexity() {
        return 3;
    }

    stringToColor(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xFF;
            color += ('00' + value.toString(16)).substr(-2);
        }
        return color;
    }

    contrastColor(hex, tresh) {
        if ((/^#([0-9A-F]{3}){1,2}$/i).test(hex) === false) {
            return "#000000";
        }
        let hexToR = (h) => { return parseInt((cutHex(h)).substring(0, 2), 16) }
        let hexToG = (h) => { return parseInt((cutHex(h)).substring(2, 4), 16) }
        let hexToB = (h) => { return parseInt((cutHex(h)).substring(4, 6), 16) }
        let cutHex = (h) => { return (h.charAt(0) == "#") ? h.substring(1, 7) : h }
        let threshold = tresh || 140; /* about half of 256. Lower threshold equals more dark text on dark background  */
        let hRed = hexToR(hex);
        let hGreen = hexToG(hex);
        let hBlue = hexToB(hex);
        let cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
        return (cBrightness > threshold) ? "#000000" : "#ffffff";
    }

    shadeColor(color, percent) {
        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);
        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);
        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;
        var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
        var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
        var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));
        return "#" + RR + GG + BB;
    }

    hex2rgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ] : null;
    }

    rgb2hex(rgb) {
        return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
    }

    interpolateHexColor(color1, color2, steps) {
        var factorStep = steps > 1 ? 1 / (steps - 1) : .5;
        var output = [];
        color1 = this.hex2rgb(color1);
        color2 = this.hex2rgb(color2);
        for (var step = 0; step < steps; step++) {
            var localColor = color1.slice();
            var factor = factorStep * step;
            for (var i = 0; i < 3; i++) {
                localColor[i] = Math.round(localColor[i] + factor * (color2[i] - color1[i]));
            }
            output.push(this.rgb2hex(localColor));
        }
        return output;
    }

    degreesToRadians(degrees) {
        return degrees * (Math.PI / 180);
    }

    radiansToDegrees(radians) {
        return radians * (180 / Math.PI);
    }

    percentFromDegrees(degrees) {
        degrees = parseFloat(degrees);
        let rad = (degrees / 360) * 2 * Math.PI;
        let percent = Math.tan(rad);
        percent = Math.round(percent * 10000)
        return percent / 100;
    }

    degreesFromPercent(percent) {
        percent = parseFloat(percent);
        let degrees = 36000 * Math.atan(percent / 100) / (2 * Math.PI);
        degrees = Math.round(degrees)
        return degrees / 100;
    }

    normalizeDegrees(degrees) {
        degrees = degrees % 360;
        degrees = (degrees + 360) % 360;
        if (degrees > 180) {
            degrees -= 360;
        }
        return degrees;
    }

    distanceBetween(lat1, lon1, lat2, lon2) {
        var R = 6371; // km
        var dLat = this.degreesToRadians(lat2 - lat1);
        var dLon = this.degreesToRadians(lon2 - lon1);
        var lat1 = this.degreesToRadians(lat1);
        var lat2 = this.degreesToRadians(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    }

    passwordComplexity(password, minPasswordLength) {
        let num = {};
        num.Excess = 0;
        num.Upper = 0;
        num.Numbers = 0;
        num.Symbols = 0;

        let bonus = {};
        bonus.Excess = 3;
        bonus.Upper = 4;
        bonus.Numbers = 5;
        bonus.Symbols = 5;
        bonus.Combo = 0;
        bonus.FlatLower = 0;
        bonus.FlatNumber = 0;

        let totalScore = 0;
        let baseScore = 50;

        minPasswordLength = minPasswordLength || 6;

        if (password.length >= minPasswordLength) {
            for (let i = 0; i < password.length; i++) {
                if (password[i].match(/[A-Z]/g)) { num.Upper++; }
                if (password[i].match(/[0-9]/g)) { num.Numbers++; }
                if (password[i].match(/(.*[!,@,#,$,%,^,&,*,?,_,~])/)) { num.Symbols++; }
            }

            num.Excess = password.length - minPasswordLength;

            if (num.Upper && num.Numbers && num.Symbols) {
                bonus.Combo = 25;
            }

            else if ((num.Upper && num.Numbers) || (num.Upper && num.Symbols) || (num.Numbers && num.Symbols)) {
                bonus.Combo = 15;
            }

            if (password.match(/^[\sa-z]+$/)) {
                bonus.FlatLower = -15;
            }

            if (password.match(/^[\s0-9]+$/)) {
                bonus.FlatNumber = -35;
            }

            totalScore = baseScore;
            totalScore += (num.Excess * bonus.Excess);
            totalScore += (num.Upper * bonus.Upper);
            totalScore += (num.Numbers * bonus.Numbers);
            totalScore += (num.Symbols * bonus.Symbols);
            totalScore += bonus.Combo;
            totalScore += bonus.FlatLower;
            totalScore += bonus.FlatNumber;

        }
        else {
            baseScore = 0;
        }

        if (password.length < minPasswordLength) {
            return 0;
        }
        else if (totalScore < 50) {
            return 1;
        }
        else if (totalScore >= 50 && totalScore < 75) {
            return 2;
        }
        else if (totalScore >= 75 && totalScore < 100) {
            return 3;
        }
        else if (totalScore >= 100) {
            return 4;
        }
    }

    arrayValues(assocArray) {
        let flatArray = [];
        Object.keys(assocArray).forEach((prop) => {
            flatArray.push(assocArray[prop]);
        });
        return flatArray;
    }

    provincesOf(country) {
        // https://en.wikipedia.org/wiki/Category:Administrative_divisions_in_Europe
        switch (country.toLowerCase()) {
            case 'poland':
                return [
                    "Dolnośląskie",
                    "Kujawsko - pomorskie",
                    "Lubelskie",
                    "Lubuskie",
                    "Łódzkie",
                    "Małopolskie",
                    "Mazowieckie",
                    "Opolskie",
                    "Podkarpackie",
                    "Podlaskie",
                    "Pomorskie",
                    "Śląskie",
                    "Świętokrzyskie",
                    "Warmińsko - mazurskie",
                    "Wielkopolskie",
                    "Zachodniopomorskie",
                ];

            case 'sweden':
                return [
                    'Stockholm',
                    'Västerbotten',
                    'Norrbotten',
                    'Uppsala',
                    'Södermanland',
                    'Östergötland',
                    'Jönköping',
                    'Kronoberg',
                    'Kalmar',
                    'Gotland',
                    'Blekinge',
                    'Skåne',
                    'Halland',
                    'Västra Götaland',
                    'Värmland',
                    'Örebro',
                    'Västmanland',
                    'Dalarna',
                    'Gävleborg',
                    'Västernorrland',
                    'Jämtland'
                ];

            case 'germany':
                return [
                    'Baden - Württemberg',
                    'Bayern',
                    'Berlin',
                    'Brandenburg',
                    'Bremen',
                    'Hamburg',
                    'Hessen',
                    'Niedersachsen',
                    'Mecklenburg - Vorpommern',
                    'Nordrhein - Westfalen',
                    'Rheinland - Pfalz',
                    'Saarland',
                    'Sachsen',
                    'Sachsen - Anhalt',
                    'Schleswig - Holstein',
                    'Thüringen'
                ];

            case 'italy':
                return [
                    'Nord-Ovest',
                    'Nord-Est',
                    'Centro',
                    'Sud',
                    'Isole or Insulare'
                ];

            case 'usa':
                return [
                    'Alabama',
                    'Alaska',
                    'American Samoa',
                    'Arizona',
                    'Arkansas',
                    'California',
                    'Colorado',
                    'Connecticut',
                    'Delaware',
                    'District Of Columbia',
                    'Federated States Of Micronesia',
                    'Florida',
                    'Georgia',
                    'Guam',
                    'Hawaii',
                    'Idaho',
                    'Illinois',
                    'Indiana',
                    'Iowa',
                    'Kansas',
                    'Kentucky',
                    'Louisiana',
                    'Maine',
                    'Marshall Islands',
                    'Maryland',
                    'Massachusetts',
                    'Michigan',
                    'Minnesota',
                    'Mississippi',
                    'Missouri',
                    'Montana',
                    'Nebraska',
                    'Nevada',
                    'New Hampshire',
                    'New Jersey',
                    'New Mexico',
                    'New York',
                    'North Carolina',
                    'North Dakota',
                    'Northern Mariana Islands',
                    'Ohio',
                    'Oklahoma',
                    'Oregon',
                    'Palau',
                    'Pennsylvania',
                    'Puerto Rico',
                    'Rhode Island',
                    'South Carolina',
                    'South Dakota',
                    'Tennessee',
                    'Texas',
                    'Utah',
                    'Vermont',
                    'Virgin Islands',
                    'Virginia',
                    'Washington',
                    'West Virginia',
                    'Wisconsin',
                    'Wyoming'
                ];

            default:
                return [];
        }
    };

    chartSnap(chartRef) {
        const oldHeight = chartRef.current.chartInstance.canvas.parentNode.style.height;
        const oldWidth = chartRef.current.chartInstance.canvas.parentNode.style.width;
        chartRef.current.chartInstance.canvas.parentNode.style.height = '1920px';
        chartRef.current.chartInstance.canvas.parentNode.style.width = '1920px';
        let content = chartRef.current.chartInstance.toBase64Image();
        chartRef.current.chartInstance.canvas.parentNode.style.height = oldHeight;
        chartRef.current.chartInstance.canvas.parentNode.style.width = oldWidth;
        return content;
    };

    getInitials(...strings) {
        strings.push('S R');
        let string = strings.filter(Boolean).join(' ');
        let names = string.split(' ');
        let initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
            initials += names[1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    getTextFromHtml(html) {
        var div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText;
    };

    getApproxTextWidth(str, fontSize = 10) {
        if (!str) {
            return 0;
        }
        // https://bl.ocks.org/tophtucker/62f93a4658387bb61e4510c37e2e97cf
        const widths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.1556640625, 0.27875001430511476, 0.4, 0.7200000286102295, 0.5599999904632569, 0.9600000381469727, 0.7200000286102295, 0.24000000953674316, 0.4, 0.4, 0.48000001907348633, 0.6400000095367432, 0.27875001430511476, 0.4, 0.27875001430511476, 0.4, 0.5599999904632569, 0.55625, 0.5599999904632569, 0.5599999904632569, 0.6400000095367432, 0.5599999904632569, 0.5599999904632569, 0.5599999904632569, 0.5599999904632569, 0.5599999904632569, 0.27875001430511476, 0.27875001430511476, 0.6400000095367432, 0.6400000095367432, 0.6400000095367432, 0.5599999904632569, 1.1199999809265138, 0.8800000190734864, 0.7200000286102295, 0.8, 0.7224999904632569, 0.7200000286102295, 0.6400000095367432, 0.8, 0.7224999904632569, 0.27875001430511476, 0.5, 0.8, 0.6400000095367432, 0.8800000190734864, 0.7224999904632569, 0.8, 0.7200000286102295, 0.8, 0.8, 0.7200000286102295, 0.6400000095367432, 0.7224999904632569, 0.8, 1.1199999809265138, 0.8, 0.8, 0.7200000286102295, 0.3200000047683716, 0.48000001907348633, 0.3200000047683716, 0.48000001907348633, 0.7200000286102295, 0.33375000953674316, 0.5599999904632569, 0.6400000095367432, 0.5599999904632569, 0.5599999904632569, 0.5599999904632569, 0.48000001907348633, 0.5599999904632569, 0.5599999904632569, 0.24000000953674316, 0.3200000047683716, 0.5599999904632569, 0.24000000953674316, 0.8800000190734864, 0.5599999904632569, 0.5599999904632569, 0.6400000095367432, 0.5599999904632569, 0.4, 0.5599999904632569, 0.4, 0.5599999904632569, 0.6400000095367432, 0.8800000190734864, 0.6400000095367432, 0.6400000095367432, 0.6400000095367432, 0.4, 0.26000001430511477, 0.48000001907348633, 0.6400000095367432]
        const avg = 0.581559626428704
        return str
            .split('')
            .map(c => c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg)
            .reduce((cur, acc) => acc + cur) * fontSize
    };

    getInnerText(obj) {
        var buf = '';
        var self = this;
        if (obj) {
            var type = typeof (obj);
            if (type === 'string' || type === 'number') {
                buf += obj;
            } else if (type === 'object') {
                var children = null;
                if (Array.isArray(obj)) {
                    children = obj;
                } else {
                    var props = obj.props;
                    if (props) {
                        children = props.children;
                    }
                }
                if (children) {
                    if (Array.isArray(children)) {
                        children.forEach(function (o) {
                            buf += self.getInnerText(o);
                        });
                    } else {
                        buf += self.getInnerText(children);
                    }
                }
            }
        }
        return buf;
    };

    getLastHashElement() {
        const particles = window.location.hash.replace('#', '').split('/').filter(Boolean);
        const uuid = particles[particles.length - 1];
        if (this.isUUID(uuid)) {
            return uuid;
        }
        return null;
    }

    getClosestLabelText(elem) {
        if (
            elem?.previousElementSibling
            && elem.previousElementSibling.tagName === 'LABEL'
        ) {
            return elem.previousElementSibling.innerText;
        }
        return null;
    }

}

export default Utils