import { Component } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import Icon from '../../../components/icon';

class ExternalMap extends Component {

    openLantmateriet(e, lat, lng) {
        if (e) {
            e.preventDefault();
        }
        this.props.api.post(
            `/search/lat-lng`,
            { lat, lng },
            (data) => {
                let href = `https://minkarta.lantmateriet.se/?e=${data.sweref99.easting}&n=${data.sweref99.northing}&z=15&background=2&boundaries=false`;
                let hwnd = window.open(href, '_blank');
                if (hwnd) {
                    hwnd.focus();
                }
            }
        )
    }

    render() {

        const lat = this.props.defaultCenter.lat.toFixed(6);
        const lng = this.props.defaultCenter.lng.toFixed(6);
        const add = this.props.fullAddress;

        return (
            <DropdownButton
                as={ButtonGroup}
                title={(<><Icon name="external-link" className="ml" /><span className="pl-10">{this.props.t('page.calculation.pvDesigner.select')}</span></>)}
                variant="link"
                menuAlign={{ lg: 'right' }}
                alignRight={true}
            >
                <Dropdown.Item as="a" target="_blank" rel="noopener noreferrer" href={`https://kartor.eniro.se/?c=${lat},${lng}&z=20&l=aerial&som=0`}>Eniro karta</Dropdown.Item>
                <Dropdown.Item as="a" target="_blank" rel="noopener noreferrer" href={`https://www.hitta.se/kartan!~${lat},${lng},20z/tr!i=zGx1c5cH/tileLayer!l=1/realestate!a=1`}>Hitta karta</Dropdown.Item>
                <Dropdown.Item as="a" target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/@?api=1&map_action=map&center=${lat},${lng}&zoom=20&basemap=satellite`}>Google maps</Dropdown.Item>
                <Dropdown.Item as="button" onClick={(e) => this.openLantmateriet(e, lat, lng)}>Lantmäteriet</Dropdown.Item>
                <Dropdown.Item as="a" target="_blank" rel="noopener noreferrer" href={`http://www.energimyndigheten.se/fornybart/solelportalen/vad-kostar-det/solelkalkyl/`}>Energimyndigheten</Dropdown.Item>
            </DropdownButton>
        )
    }
}

export default ExternalMap;