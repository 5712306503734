import React from 'react';
import BaseComponent from '../../components/component/base';
import { Row, Col, Spinner } from "react-bootstrap";
import Board from '../../components/kanban/board';
import ModalTicket from './modal-ticket';
import ModalCreateTicket from './modal-create-ticket';

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            itemCount: 0,
            quoteMap: {},
            columnKeys: [],
            ticketuuid: '',
            showModalTicket: false,
            showModalCreateTicket: false,
        }
        this.onCreateClick = this.onCreateClick.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
        this.fetchItems = this.fetchItems.bind(this);
        this.onReorder = this.onReorder.bind(this);
    }

    componentDidMount() {
        window.addEventListener("QUOTE_ITEM_CLICK", this.onItemClick, false);
        window.addEventListener("CREATE_TICKET_CLICK", this.onCreateClick, false);
        window.addEventListener("FETCH_TICKETS_CLICK", this.fetchItems, false);
        window.addEventListener("REORDER", this.onReorder, false);
        this.fetchItems();
        this.parsePathAndOpenModal();
    }

    componentWillUnmount() {
        window.removeEventListener('QUOTE_ITEM_CLICK', this.onItemClick, false);
        window.removeEventListener('CREATE_TICKET_CLICK', this.onCreateClick, false);
        window.removeEventListener("FETCH_TICKETS_CLICK", this.fetchItems, false);
        window.removeEventListener("REORDER", this.onReorder, false);
    }

    parsePathAndOpenModal() {
        const particles = this.props.location.hash.replace('#', '').split('/').filter(Boolean);
        if (particles.length < 2) {
            return;
        }
        const path = particles[0];
        const uuid = particles[1];
        switch (path) {
            case 'ticket':
                this.fetchItem(uuid)
                break;

            default:
                break;
        }
    }

    onReorder(e) {
        let positions = {};
        e.detail.payload[e.detail.destination.droppableId].forEach((ticket) => {
            positions[ticket.id] = ticket.index;
        });
        this.props.api.postSilent(
            `/ticket/transition`,
            {
                ticket: {
                    'uuid': e.detail.draggableId,
                    'status': e.detail.destination?.droppableId,
                },
                tickets: {
                    'positions': positions,
                }
            },
            (ticketObj) => {
                // this.afterFetchSuccess(ticketObj);
            },
            (errorObject) => {
                this.afterFetchError(errorObject);
            }
        );
    }

    onCreateClick(e) {
        const data = typeof e.detail === 'string' ? JSON.parse(e.detail) : {};
        this.setState({
            showModalCreateTicket: true,
        });
    }

    onItemClick(e) {
        const data = typeof e.detail === 'string' ? JSON.parse(e.detail) : {};
        this.setState({
            showModalTicket: true,
            ticketuuid: data.id || '',
        });
    }

    fetchItems(callback) {
        this.setState({ isLoading: true }, () => {
            this.props.api.post(
                `/ticket/kanban`,
                {},
                (ticketsObject) => {
                    this.afterFetchSuccess(ticketsObject), callback;
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        });
    }

    fetchItem(uuid) {
        this.setState({
            showModalTicket: true,
            ticketuuid: uuid,
        }, () => {
            this.props.openLink(`/tickets/#/ticket/${uuid}`, '_hash');
        });
    }

    afterFetchSuccess(ticketsObject, callback) {
        let itemCount = ticketsObject.count;
        let quoteMap = ticketsObject.tickets;
        // sampleQuoteMap = generateQuoteMap(itemCount);
        this.setState({
            isLoading: false,
            itemCount,
            quoteMap,
        }, callback)
    }

    afterFetchError(errorObject) {
        this.setState({ isLoading: false }, () => {
            this.props.showToast({
                errorObject: errorObject,
                title: this.props.t('common.toast.error'),
                color: 'danger'
            });
        })
    }

    render() {
        const {
            isLoading,
            itemCount,
            quoteMap,
        } = this.state;

        const loadingView = (
            <Row>
                <Col sm={12} className="text-center p-20">
                    <Spinner animation="border" />
                </Col>
            </Row>
        )

        const boardView = (
            <Board initialState={{ itemCount, quoteMap }} setColumnKeys={(columnKeys) => {
                setTimeout(() => {
                    this.setState({ columnKeys })
                }, 500);
            }} />
        )

        const modalCreateTicket = this.state.showModalCreateTicket && (
            <ModalCreateTicket
                {...this.props}
                showModal={this.state.showModalCreateTicket}
                onModalHide={() => {
                    this.setState({
                        showModalCreateTicket: false
                    }, () => {
                        this.props.openLink('/tickets/', '_hash')
                    })
                }}
                afterCreateTicket={(ticketuuid) => {
                    this.setState({
                        ticketuuid,
                        showModalCreateTicket: false,
                        showModalTicket: true
                    }, () => {
                        this.props.openLink(`/tickets/#/ticket/${ticketuuid}`, '_hash');
                    });
                }}
            />
        );

        const modalTicket = this.state.showModalTicket && (
            <ModalTicket
                {...this.props}
                showModal={this.state.showModalTicket}
                columnKeys={this.state.columnKeys}
                onModalHide={() => {
                    this.setState({
                        showModalTicket: false
                    }, () => {
                        this.props.openLink('/tickets/')
                    })
                }}
                ticketuuid={this.state.ticketuuid}
                afterFetchTicket={() => {
                    this.props.openLink(`/tickets/#/ticket/${this.state.ticketuuid}`, '_hash');
                }}
            />
        );

        return (
            <div>
                <Row>
                    <Col sm={12}>
                        {isLoading ? loadingView : boardView}
                        {modalTicket}
                        {modalCreateTicket}
                    </Col>
                </Row>
            </div>
        );
    }

}

export default Content
