import AuthLogin from './AuthLogin';
import AuthReset from './AuthReset';
import Articles from './Articles';
import Article from './Article';
import Sales from './Sales';
import Products from './Products';
import RoofProducts from './RoofProducts';
import Lead from './Offer/lead';
import Calculation from './Offer/calculation';
import Companies from './Companies';
import Company from './Company';
import Users from './Users';
import User from './User';
import Roles from './Roles';
import Calculations from './Offers';
import Leads from './Offers';
import Knowledge from './Knowledge';
import Assets from './Assets';
import Asset from './Asset';
import Purchases from './Purchases';
import Purchase from './Purchase';
import Profile from './Profile';
import AuthLogoutPage from './AuthLogout';
import Scheduler from './Scheduler';
import Teams from './Teams';
import Team from './Team';
import Mapper from './Mapper';
import Tasks from './Tasks';
import Task from './Task';
import Operations from './Operations';
import Areas from './Areas';
import Tickets from './Tickets';
import AdminOperations from './AdminOperations';
import AdminSales from './AdminSales';
import ReferralDetails from './Referral/details';
import ReferralManager from './Referral/manager';
import TechProducts from "./TechProducts";

const allRoutesDefinitions = [
    {
        'url': '/auth/login/',
        'match': '/auth/login/',
        'authOnly': false,
        'component': AuthLogin,
        'includeForPermissions': [],
        'excludeForPermissions': [],
        'category': ['auth'],
        'props': {},
        'inSidebar': {
            'visible': false,
            'label': null,
            'name': null,
            'icon': null
        }
    },
    {
        'url': '/auth/reset/',
        'match': '/auth/reset/',
        'authOnly': false,
        'component': AuthReset,
        'includeForPermissions': [],
        'excludeForPermissions': [],
        'category': ['auth'],
        'props': {},
        'inSidebar': {
            'visible': false,
            'label': null,
            'name': null,
            'icon': null
        }
    },
    {
        'url': '/sales/',
        'match': '/sales/',
        'authOnly': true,
        'component': Sales,
        'includeForPermissions': [],
        'excludeForPermissions': ['VIEW_DASHBOARD_SALES_DISABLED'],
        'category': ['sales'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.sales.inSidebar.label',
            'name': 'page.sales.inSidebar.name',
            'icon': 'bookmark'
        }
    },
    {
        'url': '/lead/',
        'match': '/lead/:uuid?/',
        'authOnly': true,
        'component': Lead,
        'includeForPermissions': ['VIEW_OFFER_LEAD'],
        'excludeForPermissions': [],
        'category': ['sales'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.lead.inSidebar.label',
            'name': 'page.lead.inSidebar.name',
            'icon': 'target'
        }
    },
    {
        'url': '/leads/',
        'match': '/leads/',
        'authOnly': true,
        'component': Leads,
        'includeForPermissions': ['VIEW_OFFER_LEADS'],
        'excludeForPermissions': [],
        'category': ['sales'],
        'props': { 'typePlural': 'leads', 'typeSingular': 'lead' },
        'inSidebar': {
            'visible': true,
            'label': 'page.leads.inSidebar.label',
            'name': 'page.leads.inSidebar.name',
            'icon': 'shopping-bag'
        }
    },
    {
        'url': '/referral-details/',
        'match': '/referral-details/',
        'authOnly': true,
        'component': ReferralDetails,
        'includeForPermissions': ['VIEW_REFERRAL_PAYOUT_DETAILS'],
        'excludeForPermissions': [],
        'category': ['sales'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.referralDetails.inSidebar.label',
            'name': 'page.referralDetails.inSidebar.name',
            'icon': 'credit-card'
        }
    },
    {
        'url': '/referral-supervisor/',
        'match': '/referral-supervisor/',
        'authOnly': true,
        'component': ReferralManager,
        'includeForPermissions': ['VIEW_REFERRAL_SUPERVISOR_DETAILS'],
        'excludeForPermissions': [],
        'category': ['sales'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.referralManager.inSidebar.label',
            'name': 'page.referralManager.inSidebar.name',
            'icon': 'user'
        }
    },
    {
        'url': '/calculation/',
        'match': '/calculation/:uuid?/',
        'authOnly': true,
        'component': Calculation,
        'includeForPermissions': ['VIEW_OFFER_CALCULATION'],
        'excludeForPermissions': [],
        'category': ['sales'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.calculation.inSidebar.label',
            'name': 'page.calculation.inSidebar.name',
            'icon': 'dollar-sign'
        }
    },
    {
        'url': '/calculations/',
        'match': '/calculations/',
        'authOnly': true,
        'component': Calculations,
        'includeForPermissions': ['VIEW_OFFER_CALCULATIONS'],
        'excludeForPermissions': [],
        'category': ['sales'],
        'props': { 'typePlural': 'calculations', 'typeSingular': 'calculation' },
        'inSidebar': {
            'visible': true,
            'label': 'page.calculations.inSidebar.label',
            'name': 'page.calculations.inSidebar.name',
            'icon': 'shopping-bag'
        }
    },
    {
        'url': '/users/',
        'match': '/users/',
        'authOnly': true,
        'component': Users,
        'includeForPermissions': ['VIEW_USERS'],
        'excludeForPermissions': [],
        'category': ['admin'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.users.inSidebar.label',
            'name': 'page.users.inSidebar.name',
            'icon': 'users'
        }
    },
    {
        'url': '/user/:uuid?/',
        'match': '/user/:uuid?/',
        'authOnly': true,
        'component': User,
        'includeForPermissions': ['VIEW_USER'],
        'excludeForPermissions': [],
        'category': ['admin'],
        'props': {},
        'inSidebar': {
            'visible': false,
            'label': 'page.user.inSidebar.label',
            'name': 'page.user.inSidebar.name',
            'icon': 'alert'
        }
    },
    {
        'url': '/companies/',
        'match': '/companies/',
        'authOnly': true,
        'component': Companies,
        'includeForPermissions': ['VIEW_COMPANIES'],
        'excludeForPermissions': [],
        'category': ['admin'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.companies.inSidebar.label',
            'name': 'page.companies.inSidebar.name',
            'icon': 'briefcase'
        }
    },
    {
        'url': '/company/:uuid?/',
        'match': '/company/:uuid?/',
        'authOnly': true,
        'component': Company,
        'includeForPermissions': ['VIEW_COMPANY'],
        'excludeForPermissions': [],
        'category': ['admin'],
        'props': {},
        'inSidebar': {
            'visible': false,
            'label': 'page.company.inSidebar.label',
            'name': 'page.company.inSidebar.name',
            'icon': 'company'
        }
    },
    {
        'url': '/teams/',
        'match': '/teams/',
        'authOnly': true,
        'component': Teams,
        'includeForPermissions': ['VIEW_TEAMS'],
        'excludeForPermissions': [],
        'category': ['admin'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.teams.inSidebar.label',
            'name': 'page.teams.inSidebar.name',
            'icon': 'command'
        }
    },
    {
        'url': '/team/:uuid?/',
        'match': '/team/:uuid?/',
        'authOnly': true,
        'component': Team,
        'includeForPermissions': ['VIEW_TEAM'],
        'excludeForPermissions': [],
        'category': ['admin'],
        'props': {},
        'inSidebar': {
            'visible': false,
            'label': 'page.team.inSidebar.label',
            'name': 'page.team.inSidebar.name',
            'icon': 'command'
        }
    },
    {
        'url': '/areas/',
        'match': '/areas/',
        'authOnly': true,
        'component': Areas,
        'includeForPermissions': ['VIEW_AREAS'],
        'excludeForPermissions': [],
        'category': ['admin'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.areas.inSidebar.label',
            'name': 'page.areas.inSidebar.name',
            'icon': 'map'
        }
    },
    {
        'url': '/products/',
        'match': '/products/',
        'authOnly': true,
        'component': Products,
        'includeForPermissions': ['VIEW_PRODUCTS'],
        'excludeForPermissions': [],
        'category': ['assets'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.products.inSidebar.label',
            'name': 'page.products.inSidebar.name',
            'icon': 'box'
        }
    },
    {
        'url': '/tech-products/',
        'match': '/tech-products/',
        'authOnly': true,
        'component': TechProducts,
        'includeForPermissions': ['VIEW_PRODUCTS'],
        'excludeForPermissions': [],
        'category': ['assets'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.products.inSidebar.label',
            'name': 'page.products.inSidebar.tech_name',
            'icon': 'box'
        }
    },
    {
        'url': '/roof-products/',
        'match': '/roof-products/',
        'authOnly': true,
        'component': RoofProducts,
        'includeForPermissions': ['VIEW_PRODUCTS'],
        'excludeForPermissions': [],
        'category': ['assets'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.products.inSidebar.label',
            'name': 'page.products.inSidebar.roof_name',
            'icon': 'GitPullRequest'
        }
    },
    {
        'url': '/knowledge/',
        'match': '/knowledge/',
        'authOnly': true,
        'component': Knowledge,
        'includeForPermissions': ['VIEW_KNOWLEDGE'],
        'excludeForPermissions': [],
        'category': ['assets'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.knowledge.inSidebar.label',
            'name': 'page.knowledge.inSidebar.name',
            'icon': 'book'
        }
    },
    {
        'url': '/purchases/',
        'match': '/purchases/',
        'authOnly': true,
        'component': Purchases,
        'includeForPermissions': ['VIEW_PURCHASES'],
        'excludeForPermissions': [],
        'category': ['assets'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.purchases.inSidebar.label',
            'name': 'page.purchases.inSidebar.name',
            'icon': 'shopping-cart'
        }
    },
    {
        'url': '/purchase/:uuid?/',
        'match': '/purchase/:uuid?/',
        'authOnly': true,
        'component': Purchase,
        'includeForPermissions': ['VIEW_PURCHASE'],
        'excludeForPermissions': [],
        'category': ['assets'],
        'props': {},
        'inSidebar': {
            'visible': false,
            'label': 'page.purchase.inSidebar.label',
            'name': 'page.purchase.inSidebar.name',
            'icon': 'shopping-cart'
        }
    },
    {
        'url': '/assets/',
        'match': '/assets/',
        'authOnly': true,
        'component': Assets,
        'includeForPermissions': ['VIEW_ASSETS'],
        'excludeForPermissions': [],
        'category': ['assets'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.assets.inSidebar.label',
            'name': 'page.assets.inSidebar.name',
            'icon': 'shopping-bag'
        }
    },
    {
        'url': '/asset/:uuid?/',
        'match': '/asset/:uuid?/',
        'authOnly': true,
        'component': Asset,
        'includeForPermissions': ['VIEW_ASSET'],
        'excludeForPermissions': [],
        'category': ['assets'],
        'props': {},
        'inSidebar': {
            'visible': false,
            'label': 'page.asset.inSidebar.label',
            'name': 'page.asset.inSidebar.name',
            'icon': 'shopping-bag'
        }
    },
    {
        'url': '/articles/',
        'match': '/articles/',
        'authOnly': true,
        'component': Articles,
        'includeForPermissions': ['VIEW_ARTICLES'],
        'excludeForPermissions': [],
        'category': ['admin'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.articles.inSidebar.label',
            'name': 'page.articles.inSidebar.name',
            'icon': 'file-text'
        }
    },
    {
        'url': '/article/:uuid?/',
        'match': '/article/:uuid?/',
        'authOnly': true,
        'component': Article,
        'includeForPermissions': ['VIEW_ARTICLE'],
        'excludeForPermissions': [],
        'category': ['admin'],
        'props': {},
        'inSidebar': {
            'visible': false,
            'label': 'page.article.inSidebar.label',
            'name': 'page.article.inSidebar.name',
            'icon': 'shopping-bag'
        }
    },
    {
        'url': '/roles/',
        'match': '/roles/',
        'authOnly': true,
        'component': Roles,
        'includeForPermissions': ['VIEW_ROLES'],
        'excludeForPermissions': [],
        'category': ['admin'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.roles.inSidebar.label',
            'name': 'page.roles.inSidebar.name',
            'icon': 'roles'
        }
    },
    {
        'url': '/admin-sales/',
        'match': '/admin-sales/',
        'authOnly': true,
        'component': AdminSales,
        'includeForPermissions': ['VIEW_ADMIN_SALES'],
        'excludeForPermissions': [],
        'category': ['admin'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.admin.sales.inSidebar.label',
            'name': 'page.admin.sales.inSidebar.name',
            'icon': 'dollar-sign'
        }
    },
    {
        'url': '/admin-operations/',
        'match': '/admin-operations/',
        'authOnly': true,
        'component': AdminOperations,
        'includeForPermissions': ['VIEW_ADMIN_OPERATIONS'],
        'excludeForPermissions': [],
        'category': ['admin'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.admin.operations.inSidebar.label',
            'name': 'page.admin.operations.inSidebar.name',
            'icon': 'sun'
        }
    },
    {
        'url': '/operations/',
        'match': '/operations/',
        'authOnly': true,
        'component': Operations,
        'includeForPermissions': ['VIEW_OPERATIONS', 'VIEW_DASHBOARD_OPERATIONS'],
        'excludeForPermissions': [],
        'category': ['operations'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.operations.inSidebar.label',
            'name': 'page.operations.inSidebar.name',
            'icon': 'layout'
        }
    },
    {
        'url': '/tasks/',
        'match': '/tasks/',
        'authOnly': true,
        'component': Tasks,
        'includeForPermissions': ['VIEW_TASKS', 'VIEW_DASHBOARD_TASKS'],
        'excludeForPermissions': [],
        'category': ['operations'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.tasks.inSidebar.label',
            'name': 'page.tasks.inSidebar.name',
            'icon': 'check-circle'
        }
    },
    {
        'url': '/task/:uuid/',
        'match': '/task/:uuid/',
        'authOnly': true,
        'component': Task,
        'includeForPermissions': ['VIEW_TASKS'],
        'excludeForPermissions': [],
        'category': ['operations'],
        'props': {},
        'inSidebar': {
            'visible': false,
            'label': 'page.tasks.inSidebar.label',
            'name': 'page.tasks.inSidebar.name',
            'icon': 'check-circle'
        }
    },
    {
        'url': '/scheduler/',
        'match': '/scheduler/',
        'authOnly': true,
        'component': Scheduler,
        'includeForPermissions': ['VIEW_PROJECT_SCHEDULER'],
        'excludeForPermissions': [],
        'category': ['operations'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.scheduler.inSidebar.label',
            'name': 'page.scheduler.inSidebar.name',
            'icon': 'calendar'
        }
    },
    {
        'url': '/mapper/',
        'match': '/mapper/',
        'authOnly': true,
        'component': Mapper,
        'includeForPermissions': ['VIEW_PROJECT_MAPPER'],
        'excludeForPermissions': [],
        'category': ['operations'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.mapper.inSidebar.label',
            'name': 'page.mapper.inSidebar.name',
            'icon': 'map'
        }
    },
    {
        'url': '/tickets/',
        'match': '/tickets/:uuid?/',
        'authOnly': true,
        'component': Tickets,
        'includeForPermissions': ['VIEW_CUSTOMER'],
        'excludeForPermissions': [],
        'category': ['customer'],
        'props': {
            customerContext: true
        },
        'inSidebar': {
            'visible': true,
            'label': 'page.tickets.inSidebar.label',
            'name': 'page.tickets.inSidebar.name',
            'icon': 'pocket'
        }
    },
    {
        'url': '/customers/',
        'match': '/customers/:uuid?/',
        'authOnly': true,
        'component': Users,
        'includeForPermissions': ['VIEW_CUSTOMER'],
        'excludeForPermissions': [],
        'category': ['customer'],
        'props': {
            customerContext: true
        },
        'inSidebar': {
            'visible': true,
            'label': 'page.customer.inSidebar.label',
            'name': 'page.customer.inSidebar.name',
            'icon': 'users'
        }
    },
    {
        'url': '/customer/',
        'match': '/customer/:uuid?/',
        'authOnly': true,
        'component': Users,
        'includeForPermissions': ['VIEW_CUSTOMER'],
        'excludeForPermissions': [],
        'category': ['customer'],
        'props': {
            customerContext: true
        },
        'inSidebar': {
            'visible': false,
            'label': 'page.customer.inSidebar.label',
            'name': 'page.customer.inSidebar.name',
            'icon': 'users'
        }
    },
    {
        'url': '/profile/',
        'match': '/profile/',
        'authOnly': true,
        'component': Profile,
        'includeForPermissions': [],
        'excludeForPermissions': [],
        'category': ['profile'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.profile.inSidebar.label',
            'name': 'page.profile.inSidebar.name',
            'icon': 'smile'
        }
    },
    {
        'url': '/auth/logout/',
        'match': '/auth/logout/',
        'authOnly': true,
        'component': AuthLogoutPage,
        'includeForPermissions': [],
        'excludeForPermissions': [],
        'category': ['profile'],
        'props': {},
        'inSidebar': {
            'visible': true,
            'label': 'page.logout.inSidebar.label',
            'name': 'page.logout.inSidebar.name',
            'icon': 'log-out'
        }
    }
];

export default allRoutesDefinitions;
