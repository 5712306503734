import React, { Component } from 'react';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import Engine from './engine';
import { Badge } from 'react-bootstrap'

class Lead extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deadlineAtRisk: false,
            areaHasSales: true,
            isRealPostalCode: true,
            offerIdent: '',
            roofPitch: 0,
        }
    }

    render() {

        let longTitle = [
            this.state.offerIdent,
            this.props.t('page.lead.longTitle')
        ].filter(Boolean).join(' - ');

        let hidden = [
            'files',
            'users',
            'comments',
            'financing',
            'independence',
            'input',
            'offer',
            'comparison',
            'balance',
            'roi',
            'generateCalculation',
            'signature',
            'designer',
            'status',
        ];

        if (!this.props.hasPermission(['VIEW_OFFER_USERS']).any) {
            hidden.push('users');
        }

        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={
                        {
                            '#': this.props.t('page.lead.shortTitle'),
                        }
                    }
                    meta={{ title: longTitle }}
                    t={this.props.t}
                >
                    <h1>
                        {longTitle}
                    </h1>

                    {
                        this.props.hasPermission(['VIEW_INFO_LEAD_HAS_LOWER_COMISSION']).any && (
                            <h4 className="mt-20 mb-0">
                                <Badge variant="danger">
                                    <span className='text-truncate' style={{ display: 'inline-block', overflow: 'hidden', overflowX: 'hidden', maxWidth: '90vw' }}>
                                        {this.props.t('alert.leadHasLowerComissionThanDeal')}
                                    </span>
                                </Badge>
                            </h4>
                        )
                    }

                    {
                        !this.state.areaHasSales && this.props.isRegion(['SWEDEN']) &&
                        (
                            <h4 className="mt-20 mb-0">
                                <Badge variant="danger">
                                    <span className='text-truncate' style={{ display: 'inline-block', overflow: 'hidden', overflowX: 'hidden', maxWidth: '90vw' }}>
                                        {this.props.t('alert.areaHasNoSales')}
                                    </span>
                                </Badge>
                            </h4>
                        )
                    }

                    {
                        !this.state.isRealPostalCode && this.props.isRegion(['SWEDEN']) && (
                            <h4 className="mt-20 mb-0">
                                <Badge variant="warning">
                                    <span className='text-truncate' style={{ display: 'inline-block', overflow: 'hidden', overflowX: 'hidden', maxWidth: '90vw' }}>
                                        {this.props.t('alert.wrongPostalCode')}
                                    </span>
                                </Badge>
                            </h4>
                        )
                    }

                    {
                        this.state.roofPitch > 47 && this.props.isRegion(['SWEDEN']) && (
                            <h3 className="mt-20 mb-0">
                                <Badge variant="warning">
                                    <span className='text-truncate' style={{ display: 'inline-block', overflow: 'hidden', overflowX: 'hidden', maxWidth: '90vw' }}>
                                        {this.props.t('alert.roofPitchOver47Deg')}
                                        {` (${roofPitch}°)`}
                                    </span>
                                </Badge>
                            </h3>
                        )
                    }
                </PageTitle>
                <Engine
                    hidden={hidden}
                    type={`lead`}
                    setDeadlineAtRisk={(status) => { this.setState({ deadlineAtRisk: status }) }}
                    setAreaHasSalesAndIsRealPostalCode={(areaHasSales, isRealPostalCode) => { this.setState({ areaHasSales, isRealPostalCode }) }}
                    setOfferIdent={(status) => { this.setState({ offerIdent: status }) }}
                    setUpperState={(newState, callback) => { this.setState(newState, callback) }}
                    {...this.props}
                />
            </PageWrap>
        );
    }
}

export default Lead;
