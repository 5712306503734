import './style.scss';

import React, { Component } from 'react';
import Icon from '../icon';
import RoutesList from '../../pages';
import classnames from 'classnames/dedupe';
import { Row, Col } from 'react-bootstrap';
import MainMenu from './main-menu';

class PageSidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: 'sales',
            sidebarTwoColumns: true,
            niceHeight: '85vh',
            yayHide: false,
            mobileMenuShow: false,
        }
        this.state.mobileMenuShow = document.getElementById('rui-mobile-scroll') ? true : false;
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.handleSidebarAction = this.handleSidebarAction.bind(this);
        this.toggleMobileSidebar = this.toggleMobileSidebar.bind(this);
        this.routes = RoutesList.filter((elem) => elem.inSidebar.visible === true);
        this._routes = RoutesList;
    }

    componentDidMount() {
        window.addEventListener("SIDEBAR_ACTION", this.handleSidebarAction, false);
        window.addEventListener("TOGGLE_MOBILE_SIDEBAR", this.toggleMobileSidebar, false);
        window.addEventListener('resize', this.handleWindowResize);
        this.setState({ selectedCategory: this.getActiveCategory(), niceHeight: window.innerHeight - 20 })
    }

    handleWindowResize() {
        this.setState({ niceHeight: window.innerHeight - 20 });
    }

    componentWillUnmount() {
        window.removeEventListener('SIDEBAR_ACTION', this.handleSidebarAction);
        window.removeEventListener("TOGGLE_MOBILE_SIDEBAR", this.toggleMobileSidebar);
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleSidebarAction(e) {
        const yayHide = document.body.classList.contains('yay-hide-to-small');
        this.setState({
            sidebarTwoColumns: yayHide ? false : true,
            yayHide,
        })
    }

    toggleMobileSidebar(e) {
        this.setState({ mobileMenuShow: e.detail }, () => {
            if (!this.state.mobileMenuShow) {
                return;
            }
            setTimeout(() => {
                const container = document.getElementById('rui-mobile-scroll');
                const activeLink = document.querySelectorAll('a.active');
                if (activeLink.length > 0) {
                    const scrollTopOffset = (activeLink.length > 1 ? activeLink[1].offsetTop : activeLink[0].offsetTop) - 120;
                    container?.scrollTo({ top: scrollTopOffset, behavior: "smooth" });
                }
            }, 50);
        });
    }

    categoryHasElements(categoryName) {
        const result = this.routes.map((route) => {
            if (!this.props.hasPermission(route.includeForPermissions).any) {
                return null;
            }
            if (route.excludeForPermissions.length && this.props.hasPermission(route.excludeForPermissions).any) {
                return;
            }
            if (!route.category.includes(categoryName)) {
                return null;
            }
            return route;
        }).filter(Boolean);
        return result.length > 0;
    }

    getActiveCategory() {
        let category = 'sales'; // default
        let pathExploded = this.props.location.pathname.split('/');
        this._routes.forEach((route) => {
            let isActive = route.url.includes('/' + pathExploded[3]);
            if (isActive) {
                category = route.category[0];
            }
        });
        return category;
    }

    render() {

        const categories = [
            {
                value: 'sales',
                icon: <Icon name="dollar-sign" />,
                iconName: "dollar-sign",
                label: this.props.t('category.sales'),
                visible: this.categoryHasElements('sales'),
            },
            {
                value: 'operations',
                icon: <Icon name="sun" />,
                iconName: "sun",
                label: this.props.t('category.operations'),
                visible: this.categoryHasElements('operations'),
            },
            {
                value: 'customer',
                icon: <Icon name="users" />,
                iconName: "users",
                label: this.props.t('category.customer'),
                visible: this.categoryHasElements('customer'),
            },
            {
                value: 'assets',
                icon: <Icon name="box" />,
                iconName: "box",
                label: this.props.t('category.assets'),
                visible: this.categoryHasElements('assets'),
            },
            {
                value: 'admin',
                icon: <Icon name="lock" />,
                iconName: "lock",
                label: this.props.t('category.admin'),
                visible: this.categoryHasElements('admin'),
            },
            {
                value: 'profile',
                icon: <Icon name="smile" />,
                iconName: "smile",
                label: this.props.t('category.profile'),
                visible: this.categoryHasElements('profile'),
            },
        ].filter(itm => itm.visible === true)

        const sidebarTwoColumns = this.state.sidebarTwoColumns && categories.length > 0;
        const yayHide = this.state.yayHide;

        return (
            <>
                <div className='yaybar yay-hide-to-small yay-gestures rui-yaybar yay-shrink'>
                    <Row className="m-0">
                        {
                            sidebarTwoColumns && (
                                <Col xs={3} className="yay-appbar p-0">
                                    <div className="py-15 text-center">
                                        {
                                            categories.map(category => (
                                                <button
                                                    key={`side-category-${category.value}`}
                                                    onClick={() => this.setState({ selectedCategory: category.value })}
                                                    disabled={!category.visible}
                                                    className={classnames("btn-link fs-9", this.state.selectedCategory === category.value ? 'active' : '')}
                                                >
                                                    {category.icon}
                                                    <br />{category.label}
                                                </button>
                                            ))
                                        }
                                    </div>
                                </Col>
                            )
                        }
                        <Col xs={sidebarTwoColumns ? 9 : 12} className={classnames("p-0", sidebarTwoColumns ? "" : "position-fixed overflow-x-hidden")} style={yayHide ? { maxWidth: "none", width: "auto" } : {}}>
                            <div className="yay-wrap-menu nicebar">
                                <div className="yaybar-wrap">
                                    <MainMenu {...this.props} yayHide={this.state.yayHide} inCategories={true} selectedCategory={this.state.selectedCategory} categories={categories} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                {this.state.mobileMenuShow && (
                    <div className='rui-navbar-show'>
                        <div className="yaybar yay-gestures rui-yaybar yay-overlay" style={{ display: 'block', margin: "-5px 0 5px -5px" }}>
                            <div className="yay-wrap-menu nicebar" style={{ background: '#fff', height: this.state.niceHeight, maxHeight: '100vh', maxWidth: "100%", overflowX: 'hidden' }}>
                                <div className="yaybar-wrap" id="rui-mobile-scroll" style={{ maxHeight: this.state.niceHeight, maxWidth: "100%", overflowX: 'hidden' }}>
                                    <MainMenu {...this.props} inCategories={false} isMobileView={true} selectedCategory={this.state.selectedCategory} categories={categories} />
                                </div>
                            </div>
                        </div>
                        <div
                            className="rui-navbar-bg"
                            onClick={() => { this.props.api.dispatchEvent('HIDE_MOBILE_SIDEBAR'); }}
                            onKeyUp={() => { }}
                            role="button"
                            tabIndex={0}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default PageSidebar
