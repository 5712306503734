import React, { Component, Fragment } from 'react';
import Datatable from "../../components/datatable";
import { Row, Col, Card } from "react-bootstrap";
import Icon from '../../components/icon';

class Content extends Component {

    render() {
        return (
            <Fragment>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body className="p-0">
                                <Datatable
                                    id={'knowledge-table'}
                                    endpoint={`knowledge/table`}
                                    withData={{}}
                                    columns={[
                                        {
                                            Header: this.props.t('page.knowledge.table.category'),
                                            accessor: "category_2",
                                            width: 100
                                        },
                                        {
                                            Header: this.props.t('page.knowledge.table.name'),
                                            accessor: "name_3"
                                        },
                                        {
                                            Header: (<>&nbsp;</>),
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                            width: 50
                                        }
                                    ]}
                                    sort={[
                                        {
                                            id: "name_3",
                                            desc: false
                                        }
                                    ]}
                                    badges={[
                                        {
                                            "field": "category_2",
                                            "badgify": true,
                                            "divClass": "text-left"
                                        },
                                        {
                                            "field": "version_4",
                                            "badgify": true,
                                            "divClass": "text-left"
                                        }
                                    ]}
                                    withCheckbox={false}
                                    onChecked={(data) => { }}
                                    transform={[
                                        {
                                            'field': 'name_3',
                                            'fn': (row) => {
                                                return (
                                                    <a
                                                        href={row.link_5}
                                                        target="_blank"
                                                        rel="nofollow noopener"
                                                        className="btn btn-link btn-uniform"

                                                    >
                                                        <Icon name="external-link" />
                                                        <span className="pl-10">
                                                            {row.name_3}
                                                        </span>
                                                    </a>
                                                )
                                            }
                                        }
                                    ]}
                                    actions={[]}
                                    {...this.props}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }

}

export default Content
