import { DateTime } from "luxon";
import React, { Fragment } from 'react';
import { Alert, Button, Col, Form, InputGroup, ListGroup, Modal, ProgressBar, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import BaseComponent from '../../components/component/base';
import Discussion from '../../components/discussion';
import Files from '../../components/files';
import Icon from '../../components/icon';
import SelectAsync from '../../components/select/async';
import TextEditor from '../../components/text-editor';
import Checklist from './checklist';

class ModalTask extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            reloadParent: false,
            activeTab1: props.activeTab || "details",
            showChecklistModal: false,
            taskuuid: props.data.taskuuid,
            projectuuid: props.data.projectuuid,
            tasklabel: '',
            taskdescription: '',
            taskstatus: "todo",
            contractor: props.data.contractorlabel
                ? { label: props.data.contractorlabel, value: props.data.contractoruuid, uuid: props.data.contractoruuid }
                : null,
            tasksince: props.data.since,
            tasktill: '',
            taskconfirmed: false,
            taskconfirmedby: '',
            taskconfirmedts: '',
            taskprogress: "0",
            taskchecklist: [],
            filesroot: null,
            projectchecklist: [],
            projectchecklistitemuuid: null,
            optionsets: [],
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true }, () => {
            this.fetchConfig(() => {
                this.fetchTask();
            });
        });
    }

    fetchConfig(callback) {
        this.props.api.post(
            `/project/data`,
            {
                "file": {
                    "names": ["optionsets"]
                }
            },
            (data) => {
                this.setState({ optionsets: data.optionsets.task.default }, callback);
            },
            (errorObject) => {
                this.afterFetchError(errorObject);
            }
        );
    }

    fetchTask() {
        const {
            taskuuid
        } = this.state;
        if (!taskuuid) {
            this.setState({
                __modified: false,
                __validated: false,
                isLoading: false,
            });
            return;
        }
        this.setState({ isLoading: true }, () => {
            this.props.api.post(
                `/task/describe`,
                {
                    task: {
                        uuid: taskuuid
                    }
                },
                (dataObject) => {
                    this.afterFetchSuccess(dataObject);
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        })
    }

    afterFetchSuccess(dataObject) {
        this.setState({
            __modified: false,
            __validated: false,
            isLoading: false,
            taskuuid: dataObject.task.uuid,
            tasklabel: dataObject.task.label,
            taskdescription: dataObject.task.description,
            tasksince: dataObject.task.since,
            tasktill: dataObject.task.till,
            taskconfirmed: dataObject.task.confirmed ? true : false,
            taskconfirmedts: dataObject.task.confirmed,
            taskconfirmedby: dataObject.confirming?.label || '',
            taskprogress: String(dataObject.task.progress),
            taskchecklist: dataObject.task.checklist || [],
            taskstatus: dataObject.task.status,
            filesroot: dataObject.root,
            contractor: dataObject.contractor,
            projectuuid: dataObject.project.uuid,
        }, () => {
            this.setTab(this.state.activeTab1);
            ReactTooltip.rebuild();
        })
    }

    afterFetchError(errorObject) {
        this.setState({ isLoading: false }, () => {
            this.props.showToast({
                errorObject: errorObject,
                title: this.props.t('common.toast.error'),
                color: 'danger'
            });
        })
    }

    saveTask() {
        const {
            taskuuid,
            tasklabel,
            taskdescription,
            taskstatus,
            contractor,
            tasksince,
            tasktill,
            taskconfirmed,
            taskprogress,
            projectuuid,
            taskchecklist,
            filesroot,
            projectchecklistitemuuid,
        } = this.state;

        let errors = [];

        if (!tasktill || !tasksince) {
            errors.push(this.props.t('page.scheduler.modal.taskDetails.error.emptyDates'))
        }

        const tastksincedate = DateTime.fromISO(tasksince);
        const tastktilldate = DateTime.fromISO(tasktill);

        if (tastksincedate > tastktilldate) {
            errors.push(this.props.t('page.scheduler.modal.taskDetails.error.wrongDates'))
        }

        if (!contractor?.uuid) {
            errors.push(this.props.t('page.scheduler.modal.taskDetails.error.emptyContractor'))
        }

        if (!projectuuid) {
            errors.push(this.props.t('page.scheduler.modal.taskDetails.error.emptyProject'))
        }

        if (!filesroot || !filesroot.uuid) {
            errors.push(this.props.t('page.scheduler.modal.taskDetails.error.emptyFilesRoot'))
        }

        if (errors.length) {
            this.props.showToast({
                title: errors.join('; '),
                color: 'danger'
            });
            return;
        }

        this.setState({ isLoading: true, reloadParent: true }, () => {
            this.props.api.post(
                taskuuid ? `/task/update` : `/task/create`,
                {
                    task: {
                        uuid: taskuuid,
                        label: tasklabel,
                        description: taskdescription,
                        since: tasksince,
                        till: tasktill,
                        confirmed: taskconfirmed,
                        progress: taskprogress,
                        status: taskstatus,
                        checklist: taskchecklist,
                    },
                    contractor: {
                        uuid: contractor?.uuid
                    },
                    project: {
                        uuid: projectuuid,
                        checklist: {
                            uuid: projectchecklistitemuuid
                        }
                    },
                    root: {
                        uuid: filesroot?.uuid
                    }
                },
                (dataObject) => {
                    this.afterFetchSuccess({ ...dataObject, reloadParent: true });
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        })
    }

    removeTask() {
        const callback = () => {
            this.setState({ isLoading: true, reloadParent: true }, () => {
                this.props.api.post(
                    `/task/trash`,
                    {
                        task: {
                            uuids: [this.state.taskuuid]
                        },
                    },
                    (dataObject) => {
                        this.props.onAfterRemove(dataObject);
                    },
                    (errorObject) => {
                        this.afterFetchError(errorObject);
                    }
                );
            })
        };
        this.confirm({
            title: this.props.t('page.scheduler.modal.taskDetails.modal.confirmRemove.title'),
            text: this.props.t('page.scheduler.modal.taskDetails.modal.confirmRemove.text'),
            icon: 'danger',
            showCancelButton: true,
            confirmButtonText: this.props.t('page.scheduler.modal.taskDetails.modal.confirmRemove.confirm'),
            confirmButtonColor: '#fdbf21',
            cancelButtonColor: '#aaa',
            cancelButtonText: this.props.t('page.scheduler.modal.taskDetails.modal.confirmRemove.cancel')
        }, callback);
    }

    setTab(tabName, callback) {
        const { taskuuid } = this.state;
        if (taskuuid) {
            this.props.openLink(`/scheduler/#/task/${taskuuid}/${tabName}/`, '_hash');
        }
        this.setState({ activeTab1: tabName }, callback)
    }

    prepareDirectories(files) {
        const dirs = files.filter(itm => itm.file.extension === 'dir');
        return dirs.map(itm => {
            let newItm = { ...itm };
            let searchObj = { ...newItm };
            let iteration = 0;
            let breadcrumbs = [];
            while (searchObj && searchObj.parent) {
                searchObj = files.find(itm => itm.file.uuid === searchObj.parent.uuid);
                if (searchObj) {
                    const searchObjCopy = { ...searchObj };
                    breadcrumbs.push(searchObjCopy.file.filename);
                }
                if (iteration++ > 100) {
                    console.error('max iterations limit reached');
                    break;
                }
            }
            breadcrumbs.push(newItm.file.filename);
            return { ...newItm, uuid: newItm.file.uuid, value: newItm.file.uuid, label: breadcrumbs.join(' / ') }
        });
    }

    prepareChecklistModal() {
        const { projectuuid } = this.state;
        this.setState({
            showChecklistModal: true
        }, () => {
            this.props.api.post(
                `/project/describe-partial/checklist`,
                {
                    project: {
                        uuid: projectuuid
                    }
                },
                (dataObject) => {
                    this.setState({
                        projectchecklist: dataObject.checklist.filter(itm => itm.parentuuid),
                    })
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        })
    }

    setTaskLabel(title) {
        this.setState({
            __modified: true,
            __validated: false,
            tasklabel: title,
            // projectchecklistitemuuid: item.uuid, // disabled as decided 09.2022
            showChecklistModal: false,
        });
    }

    confirmHide() {
        if (this.state.__modified) {
            this.confirm(
                {
                    title: this.props.t(`common.confirm.quitNotSaving.title`),
                    text: this.props.t(`common.confirm.quitNotSaving.text`),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ffc107',
                    confirmButtonText: this.props.t(`common.confirm.quitNotSaving.confirmButtonText`),
                    cancelButtonText: this.props.t(`common.confirm.quitNotSaving.cancelButtonText`)
                },
                () => {
                    this.props.onModalHide(this.state.reloadParent);
                }
            )
        } else {
            this.props.onModalHide(this.state.reloadParent);
        }
    }

    saveTaskRoutine(e) {
        e.preventDefault();
        e.stopPropagation();
        this.validateForms(
            () => {
                this.saveTask();
            },
            (invalidTab) => {
                this.setTab(invalidTab, () => {
                    this.props.showToast({
                        title: this.props.t('common.toast.checkRequiredFields'),
                        color: 'danger'
                    });
                });
            }
        );
    }

    render() {

        const {
            __modified,
            __validated,
            isLoading,
            activeTab1,
            taskuuid,
            tasklabel,
            taskdescription,
            contractor,
            tasksince,
            tasktill,
            taskconfirmed,
            taskconfirmedby,
            taskconfirmedts,
            taskprogress,
            taskstatus,
            taskchecklist,
            projectuuid,
            filesroot,
            showChecklistModal,
        } = this.state;

        const forcedReadOnly = !this.props.hasPermission(['VIEW_PROJECT_SCHEDULER_EDIT']).any;

        const loadingView = (
            <Row>
                <Col sm={12} className="text-center p-20">
                    <Spinner animation="border" />
                </Col>
            </Row>
        )

        const tabHeader = __modified && (
            <></>
        )

        const tabFooter = (
            <div className="py-20 border-top">
                <Row>
                    <Col sm={6}>
                        <Button variant="success" disabled={isLoading} type="submit" onClick={(e) => { this.saveTaskRoutine(e) }}>
                            {this.props.t('common.button.save')}
                        </Button>
                    </Col>
                    {
                        taskuuid && (
                            <>
                                <Col sm={6} className="text-sm-right">
                                    <Button variant="link" className="btn-link-danger" disabled={isLoading || forcedReadOnly} onClick={() => { this.removeTask() }}>
                                        {this.props.t('common.button.remove')}
                                    </Button>
                                </Col>
                            </>
                        )
                    }
                </Row>

            </div>
        )

        const checklistModal = (
            <Modal
                show={showChecklistModal}
                onHide={() => { this.setState({ showChecklistModal: false }) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
                autoFocus={false}
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.t('page.scheduler.modal.taskDetails.modal.checklistModal.title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {(this.state.optionsets || []).map((item, index) =>
                            <ListGroup.Item disabled={isLoading || forcedReadOnly} action key={`propotask-${item}-${index}`} onClick={() => { this.setTaskLabel(item) }}>
                                <span className="media media-filled">
                                    <span className="media-body">
                                        <span className="media-title">
                                            {item}
                                        </span>
                                    </span>
                                </span>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </Modal.Body>
            </Modal>
        )

        const tabDetails = (
            <Tab eventKey="details" title={this.props.t('page.scheduler.modal.taskDetails.tabs.details.title')}>
                <Form validated={__validated} method="post" onSubmit={(e) => { this.saveTaskRoutine(e); }} onClick={(e) => { if (e.target?.tagName !== 'INPUT') { this.setState({ showColorPicker: false }) } }} data-tab='details'>
                    {tabHeader}
                    <Row>
                        <Col sm={12} md={8}>
                            <Form.Group className='required'>
                                <Form.Label>
                                    {this.props.t('page.scheduler.modal.taskDetails.tabs.details.label')}
                                    {` `}
                                    <Icon name="info" data-tip={this.props.t('page.scheduler.modal.taskDetails.tabs.details.tip.label')} />
                                </Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        disabled={isLoading || forcedReadOnly}
                                        required
                                        type="text"
                                        value={tasklabel || ''}
                                        placeholder={this.props.t('page.scheduler.modal.taskDetails.tabs.details.placeholder.label')}
                                        onChange={(e) => { this.onChangeSetState('tasklabel', e) }}
                                    />
                                    <InputGroup.Append>
                                        <Button variant="outline-secondary" onClick={(e) => { this.prepareChecklistModal() }}>
                                            <Icon name="book-open" />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className='required'>
                                <Form.Label>{this.props.t('page.scheduler.modal.taskDetails.tabs.details.root')}</Form.Label>
                                <SelectAsync
                                    t={this.props.t}
                                    api={this.props.api}
                                    isClearable={true}
                                    cache={true}
                                    preload={true}
                                    required={true}
                                    disabled={isLoading || forcedReadOnly}
                                    endpoint={`/file/list`}
                                    value={filesroot}
                                    placeholder={this.props.t('common.placeholder.any')}
                                    params={{ include: filesroot ? [filesroot.uuid] : [], anchors: { project: { uuid: projectuuid } } }}
                                    transformData={(data, callback) => {
                                        let newData = this.prepareDirectories(data.files);
                                        callback(newData)
                                    }}
                                    onChange={(data) => {
                                        this.setState({ filesroot: data, __modified: true, __validated: false });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{this.props.t('page.scheduler.modal.taskDetails.tabs.details.description')}</Form.Label>
                                <TextEditor
                                    required={false}
                                    disabled={isLoading || forcedReadOnly}
                                    value={taskdescription || ''}
                                    placeholder={this.props.t('common.placeholder.any')}
                                    simpleToolbar={true}
                                    onChange={
                                        (html) => {
                                            this.setState({ taskdescription: html, __modified: taskdescription !== html })
                                        }
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={4}>
                            <Form.Group>
                                <Form.Label>{this.props.t('page.scheduler.modal.taskDetails.tabs.details.contractor')}</Form.Label>
                                <SelectAsync
                                    t={this.props.t}
                                    api={this.props.api}
                                    isClearable={true}
                                    cache={true}
                                    preload={true}
                                    disabled={isLoading || forcedReadOnly}
                                    endpoint={`/contractor/list`}
                                    value={contractor}
                                    placeholder={this.props.t('common.placeholder.any')}
                                    params={{ include: contractor ? [contractor.uuid] : [], filter: { projectuuid: projectuuid } }}
                                    transformData={(data, callback) => {
                                        let newData = data.contractors.map(itm => { return { ...itm, value: itm.uuid } });
                                        callback(newData)
                                    }}
                                    onChange={(data) => {
                                        this.setState({ contractor: data, __modified: true, __validated: false });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Check
                                    disabled={isLoading || forcedReadOnly}
                                    type="switch"
                                    id="confirmed"
                                    label={
                                        <>
                                            {this.props.t('page.scheduler.modal.taskDetails.tabs.details.confirmed')}
                                            {
                                                taskconfirmed && (
                                                    <>
                                                        {` `}
                                                        <Icon name="info" data-tip={`${taskconfirmedby} @ ${taskconfirmedts}`} />
                                                    </>
                                                )
                                            }
                                        </>
                                    }
                                    checked={taskconfirmed || false}
                                    onChange={(e) => { this.setState({ taskconfirmed: !taskconfirmed, __modified: true }) }}
                                    className="mb-10"
                                />
                            </Form.Group>
                            <Form.Group className='required'>
                                <Form.Label>{this.props.t('page.scheduler.modal.taskDetails.tabs.details.since')}</Form.Label>
                                <Form.Control
                                    disabled={isLoading || forcedReadOnly}
                                    required
                                    type="date"
                                    value={tasksince || ''}
                                    placeholder={this.props.t('common.placeholder.any')}
                                    onChange={(e) => { this.onChangeSetState('tasksince', e) }}
                                />
                            </Form.Group>
                            <Form.Group className='required'>
                                <Form.Label>{this.props.t('page.scheduler.modal.taskDetails.tabs.details.till')}</Form.Label>
                                <Form.Control
                                    disabled={isLoading || forcedReadOnly}
                                    required
                                    type="date"
                                    value={tasktill || ''}
                                    placeholder={this.props.t('common.placeholder.any')}
                                    onChange={(e) => { this.onChangeSetState('tasktill', e) }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{this.props.t('page.scheduler.modal.taskDetails.tabs.details.status')}</Form.Label>
                                <Form.Control
                                    as="select"
                                    custom
                                    value={taskstatus || ''}
                                    placeholder={this.props.t('common.placeholder.any')}
                                    disabled={isLoading || forcedReadOnly}
                                    onChange={(e) => { this.onChangeSetState('taskstatus', e) }}
                                >
                                    <option value="todo">{this.props.t('common.kanban.todo')}</option>
                                    <option value="doing">{this.props.t('common.kanban.doing')}</option>
                                    <option value="done">{this.props.t('common.kanban.done')}</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='required'>
                                <Form.Label>{this.props.t('page.scheduler.modal.taskDetails.tabs.details.progress')}</Form.Label>
                                <Form.Control
                                    disabled={isLoading || forcedReadOnly}
                                    required
                                    type="number"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={taskprogress || ''}
                                    placeholder={this.props.t('common.placeholder.any')}
                                    onChange={(e) => { this.onChangeSetState('taskprogress', e) }}
                                />
                                <ProgressBar now={taskprogress} />
                            </Form.Group>
                        </Col>
                    </Row>
                    {tabFooter}
                </Form>
            </Tab>
        )

        const tabChecklist = (
            <Tab
                eventKey="checklist"
                title={
                    <>
                        {this.props.t('page.scheduler.modal.taskDetails.tabs.checklist.title')}
                        <Icon name="info" style={{ marginLeft: 5 }} data-tip={this.props.t('page.scheduler.modal.taskDetails.tabs.checklist.tip')} />
                    </>
                }
            >
                {tabHeader}
                <Checklist
                    t={this.props.t}
                    api={this.props.api}
                    utils={this.props.utils}
                    getUser={this.props.getUser}
                    showToast={this.props.showToast}
                    getRegionIdent={this.props.getRegionIdent}
                    checklist={taskchecklist}
                    onChange={(newChecklist, callback) => {
                        this.setState({
                            taskchecklist: newChecklist,
                            __modified: true
                        }, () => {
                            callback(newChecklist)
                        })
                    }}
                    setMasterStatus={(setNew, newStatus, callback) => {
                        this.setState({
                            taskstatus: setNew ? newStatus : this.state.taskstatus,
                            __modified: setNew,
                        }, () => {
                            callback(newStatus)
                        })
                    }}
                    stageOfChecklist={this.props.t('page.scheduler.modal.taskDetails.tabs.checklist.stageOfChecklist')}
                    taskOfChecklist={this.props.t('page.scheduler.modal.taskDetails.tabs.checklist.taskOfChecklist')}
                    canEdit={!isLoading & !forcedReadOnly}
                    canEditDate={!isLoading & !forcedReadOnly}
                    canEditNotes={!isLoading & !forcedReadOnly}
                />
                {tabFooter}
            </Tab>
        )

        const tabDiscussion = (
            <Tab eventKey="discussion" title={this.props.t('page.scheduler.modal.projectDetails.tabs.discussion.title')}>
                <Discussion
                    t={this.props.t}
                    api={this.props.api}
                    utils={this.props.utils}
                    getUser={this.props.getUser}
                    location={this.props.location}
                    showToast={this.props.showToast}
                    hasPermission={this.props.hasPermission}
                    anchors={{ task: { uuid: taskuuid }, project: { uuid: projectuuid } }}
                    subpage='scheduler'
                    buildLink={this.props.buildLink}
                />
            </Tab>
        )

        const tabFiles = (
            <Tab eventKey="files" title={this.props.t('page.scheduler.modal.taskDetails.tabs.files.title')}>
                <Files
                    t={this.props.t}
                    api={this.props.api}
                    utils={this.props.utils}
                    getUser={this.props.getUser}
                    showToast={this.props.showToast}
                    hasPermission={this.props.hasPermission}
                    anchors={{ task: { uuid: taskuuid }, project: { uuid: projectuuid } }}
                    anchorsFetch={{ project: { uuid: projectuuid } }}
                    activeDirObj={filesroot ? { file: filesroot, readonly: true } : null}
                    ident={`task`}
                    buildLink={this.props.buildLink}
                    openLink={this.props.openLink}
                    isRegion={this.props.isRegion}
                    fetchFormArticles={{ "article": { "category": "operations" } }}
                />
            </Tab>
        )

        const formView = (
            <Tabs
                activeKey={activeTab1}
                onSelect={(k) => this.setTab(k)}
                className="m-0 align-content-between nicebar"
            >
                {projectuuid && tabDetails}
                {projectuuid && tabChecklist}
                {taskuuid && projectuuid && tabDiscussion}
                {taskuuid && projectuuid && tabFiles}
            </Tabs>
        )

        return (
            <Fragment>
                <Modal
                    show={this.props.showModal}
                    onHide={() => { this.confirmHide() }}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                __modified ? (
                                    <Icon name="save" className="bg-warning p-3 mr-5 mt-n1" style={{ width: 20, height: 20, borderRadius: '50%' }} />
                                ) : (
                                    <Icon name="save" className="bg-success text-white p-3 mr-5 mt-n1" style={{ width: 20, height: 20, borderRadius: '50%' }} />
                                )
                            }
                            {
                                [
                                    tasklabel,
                                    this.props.modalTitle
                                ].filter(Boolean).join(' - ')
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ReactTooltip className="tooltip-multiline" multiline={true} />
                        {isLoading ? loadingView : formView}
                    </Modal.Body>
                </Modal>
                {checklistModal}
            </Fragment>
        )
    }

}

export default ModalTask
