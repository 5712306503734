import React from 'react';
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import ChartComponent from '../../components/component/chart';
import { preferredCooperationTypes } from '../../constants';

class ModalMissingData extends ChartComponent {

    constructor(props) {
        super(props);
        this.fields = ["preferredCooperationType"];
        this.state = {
            modal: false,
            isLoading: false,
            updateFields: [],
            preferredCooperationType: "",
        };
    }

    componentDidMount() {
        this.updateFields();
    }

    componentDidUpdate(prevProps) {
        if (this.props.userUpdated !== prevProps.userUpdated) {
            this.updateFields();
        }
    }

    updateFields() {
        if (!this.props.user.user || !this.props.user.role || !this.props.user.role.ident.startsWith('AFF_')) {
            return;
        }
        if (!this.props.hasPermission(['VIEW_MISSING_DATA_MODAL']).any) {
            return;
        }
        let updateFields = this.fields.filter(updateField => {
            if (!this.props.user.user[updateField]) {
                return true;
            }
            return false;
        });
        this.setState({ modal: updateFields.length ? true : false, updateFields: updateFields });
    }

    onSubmit(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ isLoading: true });
        this.props.api.post(
            `/user/update-partial-self`,
            {
                "fields": this.state.updateFields,
                "user": {
                    "preferredCooperationType": this.state.preferredCooperationType
                }
            },
            (data) => {
                this.setState({ isLoading: false });
                this.props.showToast({
                    title: this.props.t('common.toast.dataSaved'),
                    color: "info"
                });
                this.props.reloadUserData();
            },
            (errorObject) => {
                this.setState({ isLoading: false });
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        );
    }

    render() {

        const {
            modal,
            isLoading,
            preferredCooperationType,
            updateFields,
        } = this.state;

        return (
            <Form method="post" onSubmit={(e) => { this.onSubmit(e) }}>
                <Modal
                    show={modal}
                    onHide={() => { this.setState({ modal: false }) }}
                    style={{ maxWidth: "100%" }}
                    backdrop="static"
                    keyboard={false}
                    size="md"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.t('page.sales.modal.missingData.title')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={12} className="pb-10">
                                <strong>
                                    {this.props.t('page.sales.modal.missingData.description')}
                                </strong>
                            </Col>
                            {
                                updateFields.includes('preferredCooperationType') && (
                                    <Col sm={12}>
                                        <Form.Group className="required">
                                            <Form.Label>
                                                {this.props.t('page.user.admin.preferredCooperationType')}
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                required={true}
                                                value={preferredCooperationType}
                                                onChange={(e) => { this.setState({ 'preferredCooperationType': e.target.value }) }}
                                                disabled={isLoading}
                                            >
                                                <option value="">{this.props.t(`common.value.empty`)}</option>
                                                {preferredCooperationTypes.map(type => {
                                                    return <option key={type} value={type}>{this.props.t(`common.preferredCooperationType.${type}`)}</option>
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                )
                            }
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="px-25">
                        <Button variant="success" type="submit" disabled={isLoading} onClick={(e) => { this.onSubmit(e) }}>
                            {this.props.t('page.sales.modal.missingData.save')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form>
        );
    }
}

export default ModalMissingData
