import React, { Fragment } from 'react';
import { Row, Col } from "react-bootstrap";
import BaseComponent from '../../components/component/base';
import ModalMissingData from './modal-missing-data';
import WidgetOffers from './widget-offers';
import WidgetUploads from './widget-uploads';
import ModalAnnounceGoodJob from '../../components/modal-announce/goodjob';

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            showAnnounce: true,
            recentOffers: [],
            recentFiles: [],
        };
    }

    componentDidMount() {
        this.prepareData();
    }

    prepareData() {
        this.props.api.get(
            `/stats/sales`,
            (data) => {
                this.setState({
                    recentOffers: data.recent.offer,
                    recentFiles: data.recent.file,
                })
            }
        );
    }

    render() {
        const limitOffersAndUploads = Math.min(this.state.recentOffers.length || 1, this.state.recentFiles.length || 1, 5);
        return (
            <Fragment>
                <h2>{this.props.t('page.sales.welcome')} 🌅</h2>

                <ModalMissingData
                    {...this.props}
                />

                <ModalAnnounceGoodJob
                    {...this.props}
                    api={this.props.api}
                    what="goodjob"
                    size="sm"
                />

                {/*
                    <ModalAnnounceSundesk
                        api={this.props.api}
                        what="sundesk"
                        size="sm"
                        till="2023-02-13"
                        times={3}
                    />
                */}



                <Row>
                    <Col sm={12} lg={6} className="mb-30">
                        <WidgetOffers {...this.props} items={this.state.recentOffers} limit={limitOffersAndUploads} />
                    </Col>
                    <Col sm={12} lg={6} className="mb-30">
                        <WidgetUploads {...this.props} items={this.state.recentFiles} limit={limitOffersAndUploads} />
                    </Col>
                </Row>

            </Fragment>
        );
    }
}

export default Content
