import React, { Fragment } from 'react';
import { Row, Col, Form, Card } from "react-bootstrap";
import LowerComponent from '../../components/component/lower';
import SelectAsync from '../../components/select/async';

class Users extends LowerComponent {

    render() {

        const {
            isLoading,
            closed
        } = this.props;

        return (
            <Fragment>

                <h2>
                    {this.props.t('page.calculation.users.title')}
                    <div className="float-md-right clear-section-container">

                        {
                            this.props.hasPermission(['VIEW_OFFER_CLEAR_SECTION_BUTTON']).any && (
                                <button
                                    type="button"
                                    className="btn text-danger px-0"
                                    disabled={isLoading || closed}
                                    onClick={(e) => { this.props.clearSection(e, ['users']) }}
                                >
                                    {this.props.t(`common.button.clear`)}
                                </button>
                            )
                        }

                    </div>
                </h2>

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col sm={12} md={6} lg={6}>
                                        <Form.Group className="required">
                                            <Form.Label>{this.props.t('page.calculation.users.seller')}</Form.Label>
                                            {
                                                isLoading ?
                                                    (
                                                        <Form.Control
                                                            disabled={true}
                                                            type="text"
                                                            placeholder="..."
                                                        />
                                                    ) :
                                                    (
                                                        <SelectAsync
                                                            {...this.props}
                                                            isClearable={false}
                                                            cache={true}
                                                            preload={true}
                                                            disabled={isLoading || closed || !this.props.hasPermission(['OFFER_CHANGE_OWNER']).any}
                                                            endpoint={`/user/list`}
                                                            value={this.props.sellerUuid}
                                                            params={{ include: [this.props.sellerUuid] }}
                                                            transformData={(data, callback) => {
                                                                let newData = data.users.map(itm => { return { ...itm, value: itm.uuid } });
                                                                callback(newData)
                                                            }}
                                                            onChange={(data) => {
                                                                this.props.setUpperState({ sellerUuid: data?.uuid || null })
                                                            }}
                                                        />
                                                    )
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6} lg={6}>
                                        <Form.Group>
                                            <Form.Label>{this.props.t('page.calculation.users.supervisor')}</Form.Label>
                                            {
                                                isLoading ?
                                                    (
                                                        <Form.Control
                                                            disabled={true}
                                                            type="text"
                                                            placeholder="..."
                                                        />
                                                    ) :
                                                    (
                                                        <SelectAsync
                                                            {...this.props}
                                                            isClearable={true}
                                                            cache={true}
                                                            preload={true}
                                                            disabled={isLoading || closed}
                                                            endpoint={`/user/list`}
                                                            value={this.props.supervisorUuid}
                                                            params={{ include: [this.props.supervisorUuid] }}
                                                            transformData={(data, callback) => {
                                                                let newData = data.users.map(itm => { return { ...itm, value: itm.uuid } });
                                                                callback(newData)
                                                            }}
                                                            onChange={(data) => {
                                                                this.props.setUpperState({ supervisorUuid: data?.uuid || null })
                                                            }}
                                                        />
                                                    )
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment >
        );
    }
}
export default Users;