import React, { Fragment } from 'react';
import { Row, Col, Form, Card } from "react-bootstrap";
import LowerComponent from '../../components/component/lower';

class AddonsLight extends LowerComponent {

    constructor(props) {
        super(props);
        this.state = {
            fuseOptions: []
        }
    }

    componentDidMount() {
        fetch("/assets/data/fuse.json")
            .then((res) => res.json())
            .then((data) => this.setState({ fuseOptions: data }));
    }

    render() {

        const {
            isLoading,
            closed
        } = this.props;

        const investmentType = [
            "newBuilding",
            "buildingRenovation"
        ];

        const constructionType = [
            "roof",
            "carport",
            "facade",
            "battery",
            "evcharger"
        ];

        return (
            <Fragment>
                <h2>
                    {this.props.t('page.calculation.addons.title')}
                    <div className="float-md-right clear-section-container">

                        {
                            this.props.hasPermission(['VIEW_OFFER_CLEAR_SECTION_BUTTON']).any && (
                                <button
                                    type="button"
                                    className="btn text-danger px-0"
                                    disabled={isLoading || closed}
                                    onClick={(e) => { this.props.clearSection(e, ['addons']) }}
                                >
                                    {this.props.t(`common.button.clear`)}
                                </button>
                            )
                        }

                    </div>
                </h2>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                {
                                    this.props.hasPermission(['VIEW_OFFER_DISCLAIMER']).any && (
                                        <>
                                            <Row>
                                                <Col sm={12}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {this.props.t('page.calculation.addons.disclaimer')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            required={false}
                                                            disabled={isLoading || closed}
                                                            as="textarea"
                                                            style={{ height: 100 }}
                                                            rows="2"
                                                            value={this.props.disclaimer || ""}
                                                            onChange={(e) => { this.props.setUpperState({ disclaimer: this.val(e) }) }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <hr className="mb-30" />
                                        </>
                                    )
                                }

                                <Row>
                                    <Col sm={12}>
                                        <Form.Label>
                                            {this.props.t('page.calculation.addons.constructionType.title')}
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        constructionType.map(ident => {
                                            let checked = this.props.constructionType.find(itm => itm.ident === ident) ? true : false;
                                            return (
                                                <Col key={ident} sm={6} md={3} lg={2}>
                                                    <Form.Group>
                                                        <Form.Check
                                                            disabled={isLoading || closed}
                                                            type="switch"
                                                            id={ident}
                                                            checked={checked}
                                                            onChange={event => {
                                                                if (checked) {
                                                                    // ...
                                                                } else {
                                                                    this.props.setUpperState({ "constructionType": [{ "ident": ident }] })
                                                                }
                                                            }}
                                                            label={this.props.t(`page.calculation.addons.constructionType.list.${ident}`)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <Form.Label>
                                            {this.props.t('page.calculation.addons.investmentType.title')}
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        investmentType.map(ident => {
                                            let checked = this.props.investmentType.find(itm => itm.ident === ident) ? true : false;
                                            return (
                                                <Col key={ident} sm={6} md={4} lg={4}>
                                                    <Form.Group>
                                                        <Form.Check
                                                            name="investmentType[]"
                                                            disabled={isLoading || closed}
                                                            data-invalidmsg={this.props.t('page.calculation.addons.investmentType.title')}
                                                            required={this.props.getConfig('useInvestmentTypeRequired') && this.props.investmentType.length === 0 ? true : false}
                                                            type="switch"
                                                            id={ident}
                                                            checked={checked}
                                                            onChange={event => {
                                                                if (checked) {
                                                                    this.props.setUpperState({ "investmentType": this.props.investmentType.filter(itm => itm.ident !== ident) })
                                                                } else {
                                                                    this.props.setUpperState({ "investmentType": [{ "ident": ident }] })
                                                                }
                                                            }}
                                                            label={this.props.t(`page.calculation.addons.investmentType.list.${ident}`)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}
export default AddonsLight;