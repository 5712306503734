import React from 'react';
import BaseComponent from '../../components/component/base';
import { IsLoading } from '../../components/spinner';

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.setState({ isLoading: true }, () => {
            this.props.api.post(
                `/admin/describe-sales`,
                {},
                (dataObject) => {
                    this.afterFetchDataSuccess(dataObject);
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        })
    }

    afterFetchDataSuccess(dataObject, callback) {
        if (typeof callback !== "function") {
            callback = () => { }
        }
        this.setState({
            isLoading: false
        }, callback);
    }

    afterFetchError(errorObject) {
        this.setState({ isLoading: false }, () => {
            this.props.showToast({
                errorObject: errorObject,
                title: this.props.t('common.toast.error'),
                color: 'danger'
            });
        })
    }

    render() {
        const {
            isLoading
        } = this.state;

        const loadingView = (
            <IsLoading />
        );

        const dataView = (
            <></>
        );

        return isLoading ? loadingView : dataView;
    }
}

export default Content
