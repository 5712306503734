import './style.scss';

import React, { Component } from 'react';
import { Row, Col, Card, Form, Button, } from "react-bootstrap";
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import Icon from '../../components/icon';

class ReferralDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            secondName: props.user.user.secondName,
            ...props.user.address
        }
    }

    saveData(e) {
        if (e) {
            e.preventDefault()
        }
        this.setState({ isLoading: true }, () => {
            this.props.api.post(
                `/user/update-partial-self-address`,
                {
                    "user": {
                        "secondName": this.state.secondName,
                    },
                    "address": {
                        "taxNumber": this.state.taxNumber,
                        "postalCode": this.state.postalCode,
                        "cityName": this.state.cityName,
                        "streetAddress": this.state.streetAddress,
                        "streetNumber": this.state.streetNumber,
                        "apartmentNumber": this.state.apartmentNumber,
                        "bankAccountNumber": this.state.bankAccountNumber,
                        "bankName": this.state.bankName,
                        "taxOfficeName": this.state.taxOfficeName,
                    }
                },
                () => {
                    this.setState({ isLoading: false }, () => {
                        this.props.showToast({
                            title: this.props.t('common.toast.dataSavedRefresh'),
                            color: "info"
                        });
                    });

                },
                (errorObject) => {
                    this.setState({ isLoading: false }, () => {
                        this.props.showToast({
                            errorObject: errorObject,
                            title: this.props.t('common.toast.error'),
                            color: "danger"
                        });
                    });
                }
            );
        });
    }

    render() {

        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={
                        {
                            '#': this.props.t('page.referralDetails.shortTitle'),
                        }
                    }
                    meta={{ title: this.props.t(`page.referralDetails.longTitle`) }}
                    t={this.props.t}
                >
                    <h1>
                        {this.props.t('page.referralDetails.longTitle')}
                    </h1>
                </PageTitle>
                <PageContent>
                    <Form method="post" onSubmit={(e) => { }}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    {[
                                        'secondName', 'taxNumber', 'postalCode', 'cityName',
                                        'streetAddress', 'streetNumber', 'apartmentNumber',
                                        'bankAccountNumber', 'bankName', 'taxOfficeName',
                                    ].map(itm => (
                                        <Col sm={12} md={4} key={itm}>
                                            <Form.Group>
                                                <Form.Label>
                                                    {this.props.t(`page.referralDetails.${itm}`)}
                                                </Form.Label>
                                                <Form.Control
                                                    onChange={(e) => {
                                                        this.setState({ [itm]: e.target.value })
                                                    }}
                                                    value={this.state[itm] || ''}
                                                    type="text"
                                                    autoComplete="off"
                                                    readOnly={this.state.isLoading}
                                                />
                                            </Form.Group>
                                        </Col>
                                    ))}
                                </Row>
                            </Card.Body>
                        </Card>
                        <Row className="mb-30">
                            <Col sm={12}>
                                <div className="pt-30">
                                    <Button
                                        variant="success"
                                        size="lg"
                                        disabled={this.state.isLoading}
                                        onClick={(e) => { this.saveData(e) }}
                                    >
                                        <Icon name="save" />
                                        <span className="pl-10">
                                            {this.props.t('page.referralDetails.saveData')}
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </PageContent>
            </PageWrap>
        );
    }
}

export default ReferralDetails;
