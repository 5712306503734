import BaseComponent from "../../component/base";
import { Col, Row, ListGroup, Form, Button } from "react-bootstrap";
import Icon from "../../icon";

class Dimensions extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            selectedElementDimensions: props.selectedElementDimensions,
            useLocalState: false
        }
    }

    onChange(e, i) {
        let value = this.valFloat(e);
        let newDimensions = this.props.selectedElementDimensions.map((itm) => {
            let newItm = { ...itm };
            if (itm.index === i) {
                newItm.length = value;
            }
            return newItm;
        })
        this.setState({
            selectedElementDimensions: newDimensions,
            useLocalState: true
        });
    }

    updateCoords() {
        const {
            selectedElement,
            maps
        } = this.props;

        let coords = selectedElement.getPath().getArray().map((latLng) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
        });

        let pathLength = selectedElement.getPath().getLength();

        this.state.selectedElementDimensions.forEach((itm) => {
            let i = itm.index;
            let startIndex = i;
            let endIndex = i < pathLength - 1 ? i + 1 : 0;
            let newDistance = itm.length;
            let start = new maps.LatLng(coords[startIndex]);
            let end = new maps.LatLng(coords[endIndex]);
            let distance = maps.geometry.spherical.computeDistanceBetween(start, end);
            let delta = Math.abs((distance - newDistance) / 2);
            let headingStart = maps.geometry.spherical.computeHeading(start, end);
            let headingEnd = maps.geometry.spherical.computeHeading(end, start);

            if (newDistance > distance) {
                [headingStart, headingEnd] = [headingEnd, headingStart];
            }

            let newStart = maps.geometry.spherical.computeOffset(start, delta, headingStart);
            let newEnd = maps.geometry.spherical.computeOffset(end, delta, headingEnd);

            coords = coords.map((latLng, index) => {
                if (index === startIndex) {
                    return { lat: newStart.lat(), lng: newStart.lng() }
                }
                if (index === endIndex) {
                    return { lat: newEnd.lat(), lng: newEnd.lng() }
                }
                return latLng;
            });
        });

        this.props.updateSelectedElementPath(coords);
        this.setState({ useLocalState: false });
    }

    render() {

        const {
            selectedElement,
        } = this.props;

        const selectedElementDimensions =
            this.state.useLocalState ?
                this.state.selectedElementDimensions :
                this.props.selectedElementDimensions;

        return (
            <ListGroup>
                {
                    selectedElementDimensions.map(itm => {
                        return (
                            <ListGroup.Item key={`length-${itm.index}`} className="px-15 py-5">
                                <Form.Group as={Row} className="mb-0">
                                    <Form.Label column sm={12} md={6}>
                                        {`L${itm.index}`}&nbsp;(m):
                                    </Form.Label>
                                    <Col sm={12} md={6}>
                                        <Form.Control
                                            type="number"
                                            value={itm.length}
                                            disabled={true}
                                            step="1"
                                            onChange={(e) => { this.onChange(e, itm.index) }}
                                        />
                                    </Col>
                                </Form.Group>
                            </ListGroup.Item>
                        )
                    })
                }
                {
                    selectedElement && Object.keys(selectedElement.sizes).length > 0 && (
                        <ListGroup.Item className="px-15 py-5">
                            <Form.Group as={Row} className="mb-0 text-right">
                                <Col sm={12}>
                                    <Button
                                        variant="link"
                                        disabled={this.props.isLoading || this.props.isDisabled}
                                        style={{ padding: 0, marginLeft: 5 }}
                                        onClick={
                                            (evt) => {
                                                this.props.editSelectedElement()
                                            }
                                        }>
                                        {this.props.t('common.button.edit')}
                                    </Button>
                                </Col>
                            </Form.Group>
                        </ListGroup.Item>
                    )
                }
                {
                    /*
                        <ListGroup.Item>
                            <Button
                                variant="link"
                                onClick={(e) => this.updateCoords()}
                                className="float-right pr-0"
                            >
                                Apply
                            </Button>
                        </ListGroup.Item>
                    */
                }
            </ListGroup >
        )
    }
}

export default Dimensions;