import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon';
import Widget from '../../components/widget';

class WidgetOffers extends Component {
    render() {

        const items = this.props.items.slice(0, this.props.limit);

        return (
            <Widget title={this.props.t('page.sales.recentCalculations')} className="rui-widget-actions" showDropdown={false}>
                <div className="rui-widget-content">
                    {
                        items.length ? (
                            <ul className="list-group list-group-flush rui-widget-list rui-widget-task-list">
                                {
                                    items.map(item => (
                                        <li className="list-group-item" key={`dash-offer-${item.uuid}`}>
                                            <Link className="rui-task rui-task-success" to={this.props.buildLink(`/${item.type}/${item.uuid}/`)}>
                                                <div className="rui-task-icon">
                                                    <Icon name="dollar-sign" />
                                                </div>
                                                <div className="rui-task-content">
                                                    <span className="rui-task-title">
                                                        {item.firstName} {item.lastName} - {item.ident}
                                                    </span>
                                                    <small className="rui-task-subtitle">
                                                        {item.created}
                                                    </small>
                                                </div>
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        ) : (
                            <div className="text-center">
                                <Icon name="coffee" style={{ width: 48, height: 48, color: "#e6ecf0" }} />
                            </div>
                        )
                    }
                </div>
            </Widget>
        );
    }
}

export default WidgetOffers;
