import './style.scss';

import React, { Component } from 'react';
import PageWrap from '../../components/page-wrap';
import Content from './content';
import PageTitle from '../../components/page-title';

class Areas extends Component {
    render() {
        return (
            <PageWrap>
                <PageTitle
                    metaOnly={{ title: this.props.t('page.areas.longTitle') }}
                    t={this.props.t}
                ></PageTitle>
                <div className='rui-full-page-content'>
                    <Content {...this.props} />
                </div>
            </PageWrap>
        );
    }
}

export default Areas;
