import React from 'react';
import { Row, Col, Form, Tabs, Tab, Spinner, Button } from "react-bootstrap";
import BaseComponent from '../../components/component/base';
import SimpleMap from '../../components/maps/simple';
import SelectAsync from '../../components/select/async';
import Icon from '../../components/icon';
import { mapCenter } from '../../constants';

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            markers: [],
            polygons: [],
            areas: [],
            areauuid: '',
            areaident: '',
            areacountrycode: '',
            arealoaded: false,
            areapostalcodes: '',
            areasm: null,
            arearsm: null,
            areagov: null,
            areapartner: null,
            areahassales: false,
            areauseasfallbackarea: false,
            areatrash: false,
        };
    }

    componentDidMount() {
        this.fetchGeoFencing();
    }

    componentWillUnmount() {

    }

    fetchGeoFencing() {
        this.setState({
            isLoading: true,
            areauuid: '',
            areaident: '',
            areacountrycode: '',
            arealoaded: false,
            areapostalcodes: '',
            areasm: null,
            arearsm: null,
            areagov: null,
            areapartner: null,
            areahassales: false,
            areauseasfallbackarea: false,
            areatrash: false,
        }, () => {
            this.props.api.post(
                `/area/geo-fencing`,
                {},
                (data) => {
                    let areas = [...data.areas];
                    let polygons = data.areas.filter(itm => itm.useAsFallbackArea === 0) || [];
                    polygons = polygons.map(poly => {
                        return { ...poly, onClick: () => this.selectArea(poly.uuid) };
                    })
                    this.setState({
                        isLoading: false,
                        polygons,
                        areas,
                    })
                },
                (errorObject) => {
                    this.props.showToast({
                        errorObject: errorObject,
                        title: this.props.t('common.toast.error'),
                        color: 'danger'
                    });
                }
            );
        });
    }

    fetchArea(areauuid) {
        this.setState({
            arealoaded: false,
            areauuid: areauuid,
        }, () => {
            this.props.api.post(
                `/area/describe`,
                {
                    "area": {
                        "uuid": areauuid
                    }
                },
                (dataObject) => {
                    this.loadData(dataObject);
                },
                (errorObject) => {
                    this.setState({
                        arealoaded: true
                    }, () => {
                        this.props.showToast({
                            errorObject: errorObject,
                            title: this.props.t('common.toast.error'),
                            color: 'danger'
                        });
                    });
                }
            );
        });
    }

    loadData(dataObject) {
        this.setState({
            arealoaded: true,
            areatrash: false,
            areaident: dataObject.area.ident,
            areapostalcodes: dataObject.area.postalCodes.join("\r\n"),
            areahassales: dataObject.area.hasSales,
            areauseasfallbackarea: dataObject.area.useAsFallbackArea,
            areacountrycode: dataObject.area.countryCode,
            areasm: dataObject.sm,
            arearsm: dataObject.rsm,
            areagov: dataObject.gov,
            areapartner: dataObject.partner,
        })
    }

    saveData() {
        this.setState({
            arealoaded: false,
        }, () => {
            this.props.api.post(
                `/area/update`,
                {
                    "area": {
                        "uuid": this.state.areauuid,
                        "ident": this.state.areaident,
                        "countryCode": this.state.areacountrycode,
                        "postalCodes": this.state.areapostalcodes.split(/\r?\n/).filter(Boolean),
                        "hasSales": this.state.areahassales,
                        "useAsFallbackArea": this.state.areauseasfallbackarea,
                        "trash": this.state.areatrash,
                    },
                    "sm": {
                        "uuid": this.state.areasm?.uuid,
                    },
                    "rsm": {
                        "uuid": this.state.arearsm?.uuid,
                    },
                    "gov": {
                        "uuid": this.state.areagov?.uuid,
                    },
                    "partner": {
                        "uuid": this.state.areapartner?.uuid,
                    },
                },
                (dataObject) => {
                    if (this.state.areatrash) {
                        this.fetchGeoFencing();
                    } else {
                        this.loadData(dataObject);
                    }
                },
                (errorObject) => {
                    this.props.showToast({
                        errorObject: errorObject,
                        title: this.props.t('common.toast.error'),
                        color: 'danger'
                    });
                }
            );
        });
    }

    selectArea(areauuid) {
        areauuid && this.fetchArea(areauuid)
    }

    render() {
        const {
            isLoading,
            arealoaded
        } = this.state;

        const viewAreaLoaded = arealoaded && (
            <>
                <Form method="post" onSubmit={(e) => { e.preventDefault(); this.saveData() }}>
                    <Tabs
                        className="m-0 nicebar border-top pt-15"
                        id="tooltabs"
                    >
                        <Tab eventKey="basic" title={this.props.t('page.areas.basic')}>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group className='required'>
                                        <Form.Label>{this.props.t('page.areas.areaident')}</Form.Label>
                                        <Form.Control
                                            disabled={isLoading}
                                            required
                                            type="text"
                                            placeholder={this.props.t('common.placeholder.any')}
                                            value={this.state.areaident}
                                            onChange={(e) => { this.setState({ areaident: this.val(e) }) }}
                                        />
                                    </Form.Group>
                                    <Form.Group className='required'>
                                        <Form.Label>{this.props.t('page.areas.areacountrycode')}</Form.Label>
                                        <Form.Control
                                            disabled={isLoading}
                                            required
                                            type="text"
                                            placeholder={this.props.t('common.placeholder.any')}
                                            value={this.state.areacountrycode}
                                            onChange={(e) => { this.setState({ areacountrycode: this.val(e) }) }}
                                        />
                                    </Form.Group>
                                    <Form.Group className="required">
                                        <Form.Label>
                                            {this.props.t('page.areas.areapostalcodes')}
                                        </Form.Label>
                                        <Form.Control
                                            required={true}
                                            disabled={this.props.isLoading}
                                            as="textarea"
                                            style={{ padding: 5 }}
                                            rows="30"
                                            value={this.state.areapostalcodes}
                                            onChange={(e) => { this.setState({ areapostalcodes: this.val(e) }) }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="users" title={this.props.t('page.areas.users')}>
                            <Row>
                                <Col sm={12}>
                                    {

                                        ['areasm', 'arearsm', 'areagov', 'areapartner'].map(person => (
                                            <Form.Group key={person}>
                                                <Form.Label className="text-capitalize">{this.props.t(`page.areas.${person}`)}</Form.Label>
                                                <SelectAsync
                                                    api={this.props.api}
                                                    t={this.props.t}
                                                    isClearable={true}
                                                    cache={true}
                                                    preload={true}
                                                    disabled={isLoading}
                                                    endpoint={`/user/list`}
                                                    value={this.state[person] || null}
                                                    placeholder={this.props.t('common.placeholder.any')}
                                                    params={{ include: this.state[person] ? [this.state[person].uuid] : [] }}
                                                    transformData={(data, callback) => {
                                                        let newData = data.users.map(itm => { return { ...itm, value: itm.uuid } });
                                                        callback(newData)
                                                    }}
                                                    onChange={(data) => {
                                                        this.setState({ [person]: data });
                                                    }}
                                                />
                                            </Form.Group>
                                        ))
                                    }
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="addons" title={this.props.t('page.areas.addons')}>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group>
                                        <Form.Check
                                            custom
                                            key={`areahassales`}
                                            id={`areahassales`}
                                            disabled={isLoading}
                                            checked={this.state.areahassales}
                                            label={this.props.t('page.areas.areahassales')}
                                            onChange={event => {
                                                this.setState({ areahassales: !this.state.areahassales })
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={12}>
                                    <Form.Group>
                                        <Form.Check
                                            custom
                                            key={`areauseasfallbackarea`}
                                            id={`areauseasfallbackarea`}
                                            disabled={isLoading}
                                            checked={this.state.areauseasfallbackarea}
                                            label={this.props.t('page.areas.areauseasfallbackarea')}
                                            onChange={event => {
                                                this.setState({ areauseasfallbackarea: !this.state.areauseasfallbackarea })
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={12}>
                                    <Form.Group className="border border-danger px-5 py-10">
                                        <Form.Check
                                            custom
                                            key={`areatrash`}
                                            id={`areatrash`}
                                            disabled={isLoading}
                                            checked={this.state.areatrash}
                                            label={this.props.t('page.areas.areatrash')}
                                            onChange={event => {
                                                this.setState({ areatrash: !this.state.areatrash })
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                    <Row className="mb-15">
                        <Col sm={12}>
                            <div className="pt-15">
                                <Button
                                    variant="success"
                                    size="lg"
                                    disabled={isLoading}
                                    type="submit"
                                >
                                    <Icon name="save" />
                                    <span className="pl-10">
                                        {this.props.t('common.button.save')}
                                    </span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </>
        )

        const viewSidebar = (
            <div className="side-with-shadow" style={{ position: 'absolute', background: '#ffffff', zIndex: 5, padding: 15, top: 10, left: 10, width: 550 }}>
                <Row>
                    {
                        isLoading ? (
                            <Col sm={12} className="text-center p-20">
                                <Spinner animation="border" />
                            </Col>
                        ) : (
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>
                                        {this.props.t('page.areas.area')}
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        custom
                                        value={this.state.areauuid}
                                        onChange={(e) => { this.selectArea(this.val(e)) }}
                                    >
                                        <option value=''>{this.props.t('common.placeholder.any')}</option>
                                        {
                                            this.state.areas.map(area => (
                                                <option key={`area-${area.uuid}`} value={area.uuid}>
                                                    {area.ident}
                                                </option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        )
                    }
                </Row>
                {viewAreaLoaded}
            </div>
        );

        const viewMap = (
            <SimpleMap
                defaultZoom={5}
                fullAddress={``}
                defaultCenter={mapCenter[this.props.getRegionIdent()]}
                markers={this.state.markers}
                polygons={this.state.polygons.map(polygon => {
                    let newPol = { ...polygon };
                    if (newPol.uuid === this.state.areauuid) {
                        newPol.props = {
                            fillColor: '#007bff',
                            strokeColor: "#007bff",
                            zIndex: 11,
                        };
                        newPol.markerprops = {
                            icon: {
                                fillColor: '#007bff',
                                zIndex: 12,
                            }
                        };
                    }
                    return newPol;
                })}
                height={`calc(100vh - 70px)`}
            />
        );

        return (
            <div style={{ position: 'relative', overflow: 'hidden', background: `#c6c4c7` }}>
                {viewSidebar}
                {viewMap}
            </div>
        )
    }
}

export default Content
