import React, { Fragment } from 'react';
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import LowerComponent from "../../components/component/lower-elements";
import Icon from '../../components/icon';
import AsyncSelect from '../../components/select/async';
import SelectSync from '../../components/select/sync';
import { assetTypes, contractTypes, headquarters, grants, assetStatuses } from '../../constants';
import Comment from "./comment";
import Comments from "./comments";
import File from "./file";
import FilesTable from './files';
import Statuses from './statuses';

class PageAsset extends LowerComponent {

    render() {

        let assetTypesPrepared = [];
        assetTypes.forEach(rt => {
            let keyValue = rt.split('_');
            let key = keyValue[0];
            let value = keyValue[1];
            if (typeof assetTypesPrepared[key] === 'undefined') {
                assetTypesPrepared[key] = {
                    'label': this.props.t(`common.assetType.${key}`),
                    'options': []
                };
            }
            assetTypesPrepared[key]['options'].push({
                label: this.props.t(`common.assetType.${rt}`),
                value: rt
            });
        });
        assetTypesPrepared = this.props.utils.arrayValues(assetTypesPrepared);

        return (
            <Fragment>
                <Form method="post" onSubmit={(e) => { e.preventDefault(); this.props.saveData(this.props.upperState) }}>
                    {
                        this.props.upperState.fields.map(
                            section => {
                                let sectionIdent = section.ident;
                                let gridSize = section.fields.length === 1 ? 12 : section.fields.length % 2 === 0 ? 6 : 4;
                                let cardClass = "";
                                let cardBodyClass = "";
                                if (['commentsSection',
                                    'commentSection',
                                    'filesSection',
                                    'fileSection',
                                    'statusesSection'
                                ].includes(sectionIdent)) {
                                    gridSize = 12;
                                    cardClass = "border-0";
                                    cardBodyClass = "p-0";
                                }
                                let fields = section.fields.map(
                                    field => {
                                        if (this.props.upperState.isReadOnly) {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <Form.Group>
                                                        <Form.Label>{this.props.t(`page.asset.element.${field}`)}</Form.Label>
                                                        <Form.Control
                                                            readOnly={true}
                                                            value={this.props.upperState[field] || ""}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                        if (field === "assetType") {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <Form.Group className="required">
                                                        <Form.Label>{this.props.t(`page.asset.element.${field}`)}</Form.Label>
                                                        <SelectSync
                                                            t={this.props.t}
                                                            api={this.props.api}
                                                            showToast={this.props.showToast}
                                                            placeholder={this.props.t(`page.asset.element.placeholder.${field}`)}
                                                            disabled={this.props.upperState.isLoading}
                                                            cache={true}
                                                            preload={true}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={assetTypesPrepared}
                                                            value={this.props.upperState[field] || ""}
                                                            onChange={(data) => {
                                                                this.props.setUpperState({
                                                                    assetType: data?.value || "",
                                                                });
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                        if (field === "nameOfOwningCompany") {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <Form.Group className="required">
                                                        <Form.Label>{this.props.t(`page.asset.element.${field}`)}</Form.Label>
                                                        <Form.Control
                                                            required={true}
                                                            disabled={this.props.upperState.isLoading}
                                                            as="select"
                                                            custom
                                                            value={this.props.upperState[field] || ""}
                                                            onChange={(e) => { this.onChangeSetUpperState(field, e) }}
                                                        >
                                                            <option value="">...</option>
                                                            {
                                                                headquarters.map(rt => (
                                                                    <option key={`rt-${rt}`} value={rt}>
                                                                        {rt}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                        if (field === "grantName") {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <Form.Group>
                                                        <Form.Label>{this.props.t(`page.asset.element.${field}`)}</Form.Label>
                                                        <Form.Control
                                                            required={false}
                                                            disabled={this.props.upperState.isLoading}
                                                            as="select"
                                                            custom
                                                            value={this.props.upperState[field] || ""}
                                                            onChange={(e) => { this.onChangeSetUpperState(field, e) }}
                                                        >
                                                            <option value="">...</option>
                                                            {
                                                                grants.map(rt => (
                                                                    <option key={`rt-${rt}`} value={rt}>
                                                                        {rt}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                        if (field === "contractType") {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <Form.Group className="required">
                                                        <Form.Label>{this.props.t(`page.asset.element.${field}`)}</Form.Label>
                                                        <Form.Control
                                                            required={true}
                                                            disabled={this.props.upperState.isLoading}
                                                            as="select"
                                                            custom
                                                            value={this.props.upperState[field] || ""}
                                                            onChange={(e) => { this.onChangeSetUpperState(field, e) }}
                                                        >
                                                            <option value="">...</option>
                                                            {
                                                                contractTypes.map(rt => (
                                                                    <option key={`rt-${rt}`} value={rt}>
                                                                        {this.props.t(`common.contractType.${rt}`)}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                        if (field === "product") {
                                            if (this.props.upperState.assetType !== 'product_roofConstruction') {
                                                return (<div key={field} />);
                                            }
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <Form.Group>
                                                        <Form.Label>{this.props.t(`page.asset.element.${field}`)}</Form.Label>
                                                        <AsyncSelect
                                                            t={this.props.t}
                                                            api={this.props.api}
                                                            showToast={this.props.showToast}
                                                            endpoint={`/search/product`}
                                                            placeholder={this.props.t(`page.asset.element.placeholder.${field}`)}
                                                            disabled={this.props.upperState.isLoading}
                                                            cache={true}
                                                            preload={true}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            value={this.props.upperState[field] || ""}
                                                            onChange={(data) => {
                                                                this.props.setUpperState({
                                                                    product: data?.value || "",
                                                                    name: data?.label || ""
                                                                });
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                        if (field.includes('countryCode')) {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <Form.Group>
                                                        <Form.Label>{this.props.t(`page.asset.element.${field}`)}</Form.Label>
                                                        <AsyncSelect
                                                            t={this.props.t}
                                                            api={this.props.api}
                                                            showToast={this.props.showToast}
                                                            endpoint={`/search/country`}
                                                            placeholder={this.props.t(`page.asset.element.placeholder.${field}`)}
                                                            disabled={this.props.upperState.isLoading}
                                                            cache={true}
                                                            preload={true}
                                                            isSearchable={true}
                                                            value={this.props.upperState[field] || ""}
                                                            onChange={(data) => { this.onChangeSetUpperState(field, data.value, true) }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                        if (field.includes('currencyCode')) {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <Form.Group className="required">
                                                        <Form.Label>{this.props.t(`page.asset.element.${field}`)}</Form.Label>
                                                        <AsyncSelect
                                                            t={this.props.t}
                                                            api={this.props.api}
                                                            showToast={this.props.showToast}
                                                            endpoint={`/search/currency`}
                                                            placeholder={this.props.t(`page.asset.element.placeholder.${field}`)}
                                                            disabled={this.props.upperState.isLoading}
                                                            cache={true}
                                                            preload={true}
                                                            isSearchable={true}
                                                            value={this.props.upperState[field] || ""}
                                                            onChange={(data) => { this.onChangeSetUpperState(field, data.value, true) }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                        if (field.includes('Value') || field.includes('Fee')) {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <Form.Group className={['totalValue'].includes(field) ? "required" : ""}>
                                                        <Form.Label>{this.props.t(`page.asset.element.${field}`)}</Form.Label>
                                                        <Form.Control
                                                            required={['totalValue'].includes(field)}
                                                            disabled={this.props.upperState.isLoading}
                                                            placeholder={this.props.t(`page.asset.element.placeholder.${field}`)}
                                                            value={this.props.upperState[field] || ""}
                                                            onChange={(e) => { this.onChangeSetUpperState(field, e) }}
                                                            type="number"
                                                            step="0.01"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                        if (field === "gap") {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <div className="rui-gap-1" />
                                                </Col>
                                            )
                                        }
                                        if (field === "comments") {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <Comments
                                                        {...this.props}
                                                    />
                                                </Col>
                                            );
                                        }
                                        if (field === "comment") {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <Form.Group key={field}>
                                                        <Comment
                                                            {...this.props}
                                                            onChange={(e) => { this.onChangeSetUpperState('comment', e, true) }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            );
                                        }
                                        if (field === "files") {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <FilesTable
                                                        t={this.props.t}
                                                        files={this.props.upperState.files}
                                                        withOptions={true}
                                                        confirmRemove={(callback) => { this.confirmRemove(callback) }}
                                                        setUpperState={(newState, callback) => { this.setUpperState(newState, callback) }}
                                                        handleElementTrash={(elementsKey, uuid) => { this.handleElementTrash(elementsKey, uuid, this.props.upperState) }}
                                                        handleElementChange={(elementsKey, uuid, field, value) => { this.handleElementChange(elementsKey, uuid, field, value, this.props.upperState) }}
                                                    />
                                                </Col>
                                            );
                                        }
                                        if (field === "file") {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <File
                                                        {...this.props}
                                                        addFile={(data) => {
                                                            this.handleElementAdd(
                                                                'files',
                                                                { ...data },
                                                                this.props.upperState
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                            );
                                        }
                                        if (field === "statuses") {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <Statuses
                                                        {...this.props}
                                                    />
                                                </Col>
                                            );
                                        }
                                        if (field === "status") {
                                            return (
                                                <Col md={gridSize} key={field}>
                                                    <Form.Group>
                                                        <Form.Label>{this.props.t(`page.asset.element.${field}`)}</Form.Label>
                                                        <Form.Control
                                                            disabled={this.props.upperState.isLoading}
                                                            as="select"
                                                            custom
                                                            value={this.props.upperState[field] || ""}
                                                            onChange={(e) => { this.onChangeSetUpperState(field, e) }}
                                                        >
                                                            <option value="">...</option>
                                                            {
                                                                assetStatuses.map(rt => (
                                                                    <option key={`rt-${rt}`} value={rt}>
                                                                        {this.props.t(`common.assetStatus.${rt}`)}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            );
                                        }
                                        return (
                                            <Col md={gridSize} key={field}>
                                                <Form.Group className={['emailAddressOfSupervisor'].includes(field) ? 'required' : ''}>
                                                    <Form.Label>{this.props.t(`page.asset.element.${field}`)}</Form.Label>
                                                    <Form.Control
                                                        readOnly={field.includes('ident')}
                                                        required={['emailAddressOfSupervisor'].includes(field)}
                                                        disabled={this.props.upperState.isLoading}
                                                        type={
                                                            field.includes('emailAddress') ? "email" :
                                                                field.includes('mobilePhone') ? "phone" :
                                                                    field.includes('Date') ? "date" :
                                                                        "text"
                                                        }
                                                        placeholder={this.props.t(`page.asset.element.placeholder.${field}`)}
                                                        value={this.props.upperState[field] || ""}
                                                        onChange={(e) => { this.onChangeSetUpperState(field, e) }} />
                                                </Form.Group>
                                            </Col>
                                        )
                                    }
                                );

                                if (['statusesSection', 'statusSection'].includes(section.ident)) {
                                    if (!this.props.upperState.uuid) {
                                        return;
                                    }
                                    if (!this.props.hasPermission('VIEW_ASSET_BUTTON_STATUS').any) {
                                        return;
                                    }
                                }

                                return (
                                    <Fragment key={section.ident}>
                                        <h2>
                                            {this.props.t(`page.asset.element.${section.ident}`)}
                                        </h2>
                                        <Row>
                                            <Col sm={12}>
                                                <Card className={cardClass}>
                                                    <Card.Body className={cardBodyClass}>
                                                        <Row>
                                                            {fields}
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <div className="rui-gap-1" />
                                    </Fragment>
                                );
                            }
                        )
                    }

                    {
                        (!this.props.upperState.isReadOnly) &&
                        (
                            <>
                                <Row className="mb-30">
                                    <Col sm={12}>
                                        <div className="pt-30">
                                            <Button
                                                variant="success"
                                                size="lg"
                                                disabled={this.props.upperState.isLoading}
                                                type="submit"
                                            >
                                                <Icon name="save" />
                                                <span className="pl-10">
                                                    {this.props.t('page.asset.element.options.saveData')}
                                                </span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                </Form>
            </Fragment>
        );
    }
}

export default PageAsset
