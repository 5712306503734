import { Component } from "react";
import { Col, Row, ListGroup, Form } from "react-bootstrap";
import MapType from "./mapType";
import ExternalMap from "./externalMap";

class Summary extends Component {

    render() {
        return (
            <ListGroup>
                <ListGroup.Item className="px-15 py-5">
                    <Form.Group as={Row} className="mb-0">
                        <Form.Label column sm={12} md={6}>
                            {this.props.t('page.calculation.pvDesigner.externalMap')}
                            &nbsp;
                        </Form.Label>
                        <Col sm={12} md={6} className="p-0">
                            <div className="float-right">
                                <ExternalMap {...this.props} />
                            </div>
                        </Col>
                    </Form.Group>
                </ListGroup.Item>
                <ListGroup.Item className="px-15 py-5">
                    <div className="float-left">
                        {this.props.t('page.calculation.pvDesigner.latitude')}
                        &nbsp;
                    </div>
                    <div className="float-right">
                        {this.props.defaultCenter.lat.toFixed(6)}
                    </div>
                </ListGroup.Item>
                <ListGroup.Item className="px-15 py-5">
                    <div className="float-left">
                        {this.props.t('page.calculation.pvDesigner.longitude')}
                        &nbsp;
                    </div>
                    <div className="float-right">
                        {this.props.defaultCenter.lng.toFixed(6)}
                    </div>
                </ListGroup.Item>
            </ListGroup>
        )
    }
}

export default Summary;