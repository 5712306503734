
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import BaseComponent from "../../components/component/base";

class FinancingBasic extends BaseComponent {

    render() {
        return (
            <>

                {
                    this.props.showSwitch && (
                        <Col md={12}>
                            <Form.Check
                                disabled={this.props.isLoading || this.props.closed}
                                type="switch"
                                id="financingPresent"
                                label={this.props.t('page.calculation.financing.financingPresent')}
                                checked={this.props.financingPresent || false}
                                onChange={(e) => {
                                    this.props.setUpperState({
                                        financingPresent: !this.props.financingPresent
                                    })
                                }}
                                className="mt-0 mb-20"
                            />
                        </Col>
                    )
                }

                <Col sm={12} md={6} lg={6} className="align-self-end">
                    <Form.Group className="optional">
                        <Form.Label>
                            {this.props.t('page.calculation.financing.estAnnualProductionFromSingleKwP2KwH')}
                        </Form.Label>
                        <Form.Control
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            required={false}
                            min={100}
                            max={10000}
                            disabled={this.props.isLoading || this.props.closed}
                            readOnly={false}
                            placeholder="..."
                            type="number"
                            step="0.01"
                            value={this.props.estAnnualProductionFromSingleKwP2KwH || "0"}
                            onChange={
                                (evt) => {
                                    this.props.setUpperState(
                                        {
                                            estAnnualProductionFromSingleKwP2KwH: this.valFloat(evt),
                                            valueOfAverageInstallment: 0
                                        }
                                    )
                                }
                            }
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} md={6} lg={6} className="align-self-end">
                    <Form.Group className="optional">
                        <Form.Label>
                            {this.props.t('page.calculation.financing.estAnnualAssumedEnergyProductionKwH')}
                        </Form.Label>
                        <Form.Control
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            required={false}
                            min={0}
                            max={9999999}
                            disabled={this.props.isLoading || this.props.closed}
                            readOnly={true}
                            placeholder="..."
                            type="number"
                            step="0.01"
                            value={this.props.estAnnualAssumedEnergyProductionKwH || "0"}
                            onChange={
                                (evt) => {
                                    this.props.setUpperState(
                                        {
                                            estAnnualAssumedEnergyProductionKwH: this.valFloat(evt),
                                            valueOfAverageInstallment: 0
                                        }
                                    )
                                }
                            }
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} md={6} lg={6} className="align-self-end">
                    <Form.Group className="optional">
                        <Form.Label>
                            {this.props.t('page.calculation.financing.estAvgEnergyPrice')}
                        </Form.Label>
                        <Form.Control
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            required={false}
                            min={0}
                            max={10}
                            disabled={this.props.isLoading || this.props.closed}
                            readOnly={false}
                            placeholder="..."
                            type="number"
                            step="0.01"
                            value={this.props.estAvgEnergyPrice || "0"}
                            onChange={
                                (evt) => {
                                    this.props.setUpperState(
                                        {
                                            estAvgEnergyPrice: this.valFloat(evt),
                                            valueOfAverageInstallment: 0
                                        }
                                    )
                                }
                            }
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} md={6} lg={6} className="align-self-end">
                    <Form.Group className="optional">
                        <Form.Label>
                            {this.props.t('page.calculation.financing.estAnnualEnergyValue')}
                        </Form.Label>
                        <Form.Control
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            required={false}
                            min={0}
                            max={9999999}
                            disabled={this.props.isLoading || this.props.closed}
                            readOnly={true}
                            placeholder="..."
                            type="number"
                            step="0.01"
                            value={this.props.estAnnualEnergyValue || "0"}
                            onChange={
                                (evt) => {
                                    this.props.setUpperState(
                                        {
                                            estAnnualEnergyValue: this.valFloat(evt),
                                            valueOfAverageInstallment: 0
                                        }
                                    )
                                }
                            }
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} md={6} lg={6} className="align-self-end">
                    <Form.Group className="optional">
                        <Form.Label>
                            {this.props.t('page.calculation.financing.estMonthlyEnergyValue')}
                        </Form.Label>
                        <Form.Control
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            required={false}
                            min={0}
                            max={9999999}
                            disabled={this.props.isLoading || this.props.closed}
                            readOnly={true}
                            placeholder="..."
                            type="number"
                            step="0.01"
                            value={this.props.estMonthlyEnergyValue || "0"}
                            onChange={
                                (evt) => {
                                    this.props.setUpperState(
                                        {
                                            estMonthlyEnergyValue: this.valFloat(evt),
                                            valueOfAverageInstallment: 0
                                        }
                                    )
                                }
                            }
                        />
                    </Form.Group>
                </Col>

            </>
        );

    }
}

export default FinancingBasic;