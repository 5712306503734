import './style.scss';

import _ from 'lodash';
import React, { Component } from 'react';
import classnames from 'classnames/dedupe';
import { Link } from 'react-router-dom';
import Dropdown from '../bs-dropdown';
import Icon from '../icon';

import logo from '../../assets/images/logo.svg';
import seFlag from '../../assets/images/flags/sweden.svg';
import plFlag from '../../assets/images/flags/republic-of-poland.svg';
import ukFlag from '../../assets/images/flags/united-kingdom.svg';
import deFlag from '../../assets/images/flags/germany.svg';
import usFlag from '../../assets/images/flags/united-states-of-america.svg';
import { Button, Col, Row } from 'react-bootstrap';
import Avatar from './avatar';

class PageNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileMenuShow: false,
            showIconNotSaved: false,
            showIconYesSaved: false,
            langCode: props.lang || 'en',
        };
        this.renderSubmenus = this.renderSubmenus.bind(this);
        this.renderRightMenuItems = this.renderRightMenuItems.bind(this);
        this.handleNavbarAction = this.handleNavbarAction.bind(this);
        this.handleHideMobileSidebar = this.handleHideMobileSidebar.bind(this);
        this.starterLink = this.props.buildLink(this.props.getStarterLink());
    }

    componentDidMount() {
        window.addEventListener("NAVBAR_ACTION", this.handleNavbarAction, false);
        window.addEventListener("HIDE_MOBILE_SIDEBAR", this.handleHideMobileSidebar, false);
        this.createAvatar();
    }

    componentWillUnmount() {
        window.removeEventListener('NAVBAR_ACTION', this.handleNavbarAction);
        window.removeEventListener('HIDE_MOBILE_SIDEBAR', this.handleHideMobileSidebar);
    }

    toggleSidebar() {
        document.body.classList.toggle('yay-hide');
        this.props.api.dispatchEvent('SIDEBAR_ACTION');
        const yayHide = document.body.classList.contains('yay-hide-to-small');
        if (yayHide) {
            this.toggleMobileSidebar();
        }
    }

    toggleMobileSidebar() {
        this.setState({ mobileMenuShow: !this.state.mobileMenuShow }, () => {
            this.props.api.dispatchEvent('TOGGLE_MOBILE_SIDEBAR', this.state.mobileMenuShow);
        });
    }

    handleHideMobileSidebar(e) {
        this.setState({ mobileMenuShow: false }, () => {
            this.props.api.dispatchEvent('TOGGLE_MOBILE_SIDEBAR', this.state.mobileMenuShow);
        });
    }

    handleNavbarAction(e) {
        let newState = { ...this.state };
        let icon = e.detail.icon;
        if (icon === 'not-saved') {
            newState = {
                ...newState,
                showIconNotSaved: true,
                showIconYesSaved: false
            }
        }
        else if (icon === 'saved') {
            newState = {
                ...newState,
                showIconNotSaved: false,
                showIconYesSaved: true
            }
        }
        else {
            newState = {
                ...newState,
                showIconNotSaved: false,
                showIconYesSaved: false
            }
        }
        if (e.detail.hideMobileMenu) {
            newState = {
                ...newState,
                mobileMenuShow: false,
            }
        }
        if (!_.isEqual(this.state, newState)) {
            this.setState(newState);
        }
    }

    renderSubmenus(nav, isMobile, level = 1) {
        return Object.keys(nav).map((url) => {
            const data = nav[url];
            const isActive = window.location.pathname === `${url}`;
            const LinkContent = data.name ? (
                <>
                    {data.icon ? (
                        <>
                            <Icon name={data.icon} />
                            <span>{data.name}</span>
                            <span className={data.sub ? 'rui-dropdown-circle' : 'rui-nav-circle'} />
                        </>
                    ) : (
                        data.name
                    )}
                </>
            ) : '';

            return (
                <React.Fragment key={`${url}-${data.name}`}>
                    {data.sub ? (
                        <Dropdown as="li" className={classnames(isActive ? 'active' : '')} direction={level === 1 ? 'up' : 'right'} openOnHover={!isMobile} showTriangle>
                            <Dropdown.Toggle as="button" className="dropdown-item">
                                {LinkContent}
                            </Dropdown.Toggle>
                            <Dropdown.Menu as="ul" className="nav dropdown-menu">
                                {this.renderSubmenus(data.sub, isMobile, level + 1)}
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <li className={classnames('nav-item', isActive ? 'active' : '')}>
                            <Link
                                to={data.sub ? '#' : url}
                                className="nav-link"
                            >
                                {LinkContent}
                            </Link>
                        </li>
                    )}
                </React.Fragment>
            );
        });
    }

    renderRightMenuItems(isMobile) {

        const countriesDropdown = [
            {
                name: this.props.t('language.sv.short'),
                img: seFlag,
                lang: 'sv',
                label: 'Svenska (SV)'
            },
            {
                name: this.props.t('language.pl.short'),
                img: plFlag,
                lang: 'pl',
                label: 'Polski (PL)'
            },
            {
                name: this.props.t('language.en.short'),
                img: ukFlag,
                lang: 'en',
                label: 'English (EN)'
            },
            {
                name: this.props.t('language.de.short'),
                img: deFlag,
                lang: 'de',
                label: 'Deutsch (DE)'
            },
            {
                name: this.props.t('language.us.short'),
                img: usFlag,
                lang: 'us',
                label: 'English (US)'
            }
        ];

        let currentLangFlag = countriesDropdown.filter(itm => itm.lang === this.state.langCode);
        if (currentLangFlag?.length) {
            currentLangFlag = currentLangFlag[0].img
        } else {
            currentLangFlag = null;
        }

        return (
            <>
                <Dropdown as={isMobile ? "div" : "li"} direction="up" openOnHover={!isMobile} showTriangle id='lang-changer'>
                    <Dropdown.Toggle
                        id="lang-toggle"
                        as="button"
                        className={
                            classnames(
                                'dropdown-item',
                                isMobile ? 'nav-link' : '',
                            )
                        }
                    >
                        <span className="btn btn-custom-universal">
                            <Icon name="globe" />
                            <span className="ml-5">
                                LANG: {this.state.langCode.toUpperCase()}
                            </span>
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        as="ul"
                        className="nav dropdown-menu rui-navbar-dropdown-universal"
                        popperConfig={{
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [-14, 22],
                                    },
                                },
                            ],
                        }}
                    >
                        {
                            countriesDropdown.map((data) => {
                                const newLangLink = window.location.pathname.replace(`/${this.state.langCode}/`, `/${data.lang}/`) + window.location.hash;
                                return (
                                    <li key={data.name}>
                                        <a
                                            className={
                                                classnames(
                                                    "btn btn-link rui-navbar-universal text-left",
                                                    this.state.langCode === data.lang ? 'active' : ''
                                                )
                                            }
                                            href={newLangLink}
                                        >
                                            <span className="rui-navbar-universal-img">
                                                <img src={data.img} alt={data.name} style={{ width: 16, height: 16 }} />
                                            </span>
                                            <span style={{ whiteSpace: 'nowrap' }}>
                                                {data.label}
                                            </span>
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    }

    createAvatar() {
        this.avatar = <Avatar user={this.props.user} utils={this.props.utils} />
    }

    loginPopUp(link) {
        const w = 414;
        const h = 571;
        const left = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
        const top = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
        const popupWindow = window.open(link, '_blank', `width=${w},height=${h},left=${left},top=${top},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`);
        let checkAddressChange = setInterval(() => {
            try {
                if (popupWindow && popupWindow.location.href) {
                    if (!popupWindow.location.href.includes('/auth/')) {
                        popupWindow.close();
                        clearInterval(checkAddressChange);
                        window.location.reload();
                    }
                }
            } catch (e) {
                console.error('An error occurred:', e);
            }
            if (!popupWindow || popupWindow.closed) {
                clearInterval(checkAddressChange);
                window.location.reload();
            }
        }, 500);
    }

    render() {

        const theAvatarToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
                href=""
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}>
                {this.avatar}
            </a>
        ));

        const filteredAccounts = this.props.api.getRegisteredTokens().map((data) => (
            <Dropdown.Item key={data.token?.uuid} as='li' className='px-0'>
                <Button
                    variant='btn btn-fake-link text-left p-0'
                    style={{ textTransform: 'none', fontWeight: data.token?.current ? 'bold' : 'normal', fontSize: 'inherit' }}
                    onClick={(e) => {
                        e.preventDefault();
                        this.props.setToken(data.token?.uuid);
                        setTimeout(() => {
                            window.location.href = window.location.pathname.replace(`/${this.props.getRegionIdent().toLowerCase()}/`, `/${data.region.ident}/`) + window.location.hash;
                        }, 100);
                    }}
                >
                    <span>
                        {`${data.user.emailAddress}`}
                    </span>
                </Button>
            </Dropdown.Item>
        ))

        const theAvatarDropdown = (
            <Dropdown showTriangle>
                <Dropdown.Toggle as={theAvatarToggle} id="dropdown-custom-components" />
                <Dropdown.Menu
                    as="ul"
                    className="nav dropdown-menu rui-navbar-dropdown-universal"
                    popperConfig={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [22, 22],
                                },
                            },
                        ],
                    }}>

                    {filteredAccounts}

                    <Dropdown.Item as='li' className='border-top mt-10 pt-15 px-0' style={{ minWidth: 230, fontSize: 11 }}>
                        <Row className='mx-0 text-center w-100'>
                            <Col md={12} className='px-0 mb-5'>
                                <Link
                                    to={this.props.buildLink('/auth/login')}
                                    className="border py-4 px-8 d-inline-block w-100"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.loginPopUp(this.props.buildLink('/auth/login'));
                                    }}
                                >
                                    <span>
                                        {this.props.t('page.login.inNavbar.name')}
                                    </span>
                                </Link>
                            </Col>
                            <Col md={12} className='px-0 mb-5'>
                                <Link
                                    to={this.props.buildLink('/auth/logout/')}
                                    className="text-danger border py-4 px-8 d-inline-block w-100"
                                >
                                    <span>
                                        {this.props.t('page.logout.inNavbar.name')}
                                    </span>
                                </Link>
                            </Col>
                        </Row>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );

        return (
            <>

                { /* Nav Menu */}

                <nav className='rui-navbar rui-navbar-top rui-navbar-sticky rui-navbar-expand-lg navbar-milky'>
                    <div className="rui-navbar-brand">
                        <Link to={this.starterLink} className="rui-navbar-logo">
                            <img src={logo} alt="" width={60} />
                        </Link>
                    </div>
                    <div className={`container-fluid`}>
                        <div className="rui-navbar-content" style={{ height: 70 }}>
                            <ul className="nav">
                                {this.renderRightMenuItems()}
                                <li className='nav-item rui-yaybar-toggle-parent' style={{ padding: 0, marginLeft: -5, marginRight: 25 }}>
                                    <button className="yay-toggle rui-yaybar-toggle" type="button" onClick={() => this.toggleSidebar()}>
                                        <span />
                                    </button>
                                </li>
                                <li className={classnames("notify-save-icon nav-item", this.state.showIconYesSaved ? '' : 'd-none')}>
                                    <Icon name="save" className="bg-success text-white p-3" style={{ width: 24, height: 24, borderRadius: '50%' }} />
                                </li>
                                <li className={classnames("notify-save-icon nav-item", this.state.showIconNotSaved ? '' : 'd-none')}>
                                    <Icon name="save" className="bg-warning p-3" style={{ width: 24, height: 24, borderRadius: '50%' }} />
                                </li>
                            </ul>
                            <ul className='nav rui-navbar-right'>
                                <li className='nav-item' style={{ flexGrow: '1', flexDirection: 'column', flexWrap: 'wrap', textAlign: 'right', fontSize: 12 }}>
                                    <span>{`${this.props.user?.user?.firstName} ${this.props.user?.user?.lastName}`}</span>
                                    <small>{`${this.props.user?.role?.summary}`}</small>
                                </li>
                                <li className='nav-item nav-profile-img' style={{ flexGrow: '1', flexDirection: 'column', flexWrap: 'wrap', textAlign: 'center' }}>
                                    {theAvatarDropdown}
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                { /* Mobile Menu */}

                <nav className={'rui-navbar rui-navbar-mobile rui-navbar-expand-lg'} style={{ maxWidth: '100vw' }}>
                    <div className="rui-navbar-head" style={{ maxWidth: '100vw' }}>
                        <button className="rui-yaybar-toggle rui-yaybar-toggle-inverse yay-toggle" type="button" aria-label="Toggle side navigation" onClick={() => this.toggleMobileSidebar()}>
                            <span />
                        </button>
                        {this.renderRightMenuItems(true)}
                        <ul className='nav rui-navbar-right' style={{ marginLeft: 'auto' }}>
                            <li className='nav-item nav-profile-img' style={{ flexGrow: '1', flexDirection: 'column', flexWrap: 'wrap', textAlign: 'center' }}>
                                {theAvatarDropdown}
                            </li>
                        </ul>
                    </div>
                </nav>

            </>
        );
    }
}

export default PageNavbar;
