import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            height={60.890751}
            viewBox="0 0 16.110678 16.110678"
            width={60.890751}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                transform="translate(-72.211 -316.238)"
                fillOpacity={1}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={4}
                strokeDasharray="none"
                strokeDashoffset={0}
                strokeOpacity={1}
            >
                <circle
                    cx={80.266289}
                    cy={324.29294}
                    r={8.0553389}
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M74.798 318.388a8.055 8.055 0 00-2.587 5.905 8.055 8.055 0 008.055 8.055 8.055 8.055 0 007.005-4.088 8.055 8.055 0 01-.46.405 8.055 8.055 0 01-.656.468 8.055 8.055 0 01-.698.401 8.055 8.055 0 01-.735.329 8.055 8.055 0 01-.764.254 8.055 8.055 0 01-.786.176 8.055 8.055 0 01-.8.098 8.055 8.055 0 01-.569.02 8.055 8.055 0 01-.804-.04 8.055 8.055 0 01-.796-.121 8.055 8.055 0 01-.78-.2 8.055 8.055 0 01-.757-.275 8.055 8.055 0 01-.725-.35 8.055 8.055 0 01-.686-.421 8.055 8.055 0 01-.641-.488 8.055 8.055 0 01-.59-.549 8.055 8.055 0 01-.531-.605 8.055 8.055 0 01-.468-.654 8.055 8.055 0 01-.4-.7 8.055 8.055 0 01-.33-.734 8.055 8.055 0 01-.254-.764 8.055 8.055 0 01-.176-.786 8.055 8.055 0 01-.097-.8 8.055 8.055 0 01-.02-.569 8.055 8.055 0 01.04-.804 8.055 8.055 0 01.12-.796 8.055 8.055 0 01.2-.78 8.055 8.055 0 01.276-.757 8.055 8.055 0 01.35-.725 8.055 8.055 0 01.064-.105z"
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M76.068 320.576h2.976"
                    opacity={1}
                    fill="none"
                    stroke="#fff"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M79.044 320.576h-.732"
                    opacity={1}
                    fill="none"
                    stroke="#1a1a1a"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M82.422 320.576H85.4"
                    opacity={1}
                    fill="none"
                    stroke="#fff"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M85.399 320.576h-.732M76.592 327.247s.378-2.488 4.269-2.488M85.129 327.247s-.377-2.488-4.268-2.488"
                    opacity={1}
                    fill="none"
                    stroke="#1a1a1a"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M79.753 326.992s.648-.744 1.938 0"
                    opacity={1}
                    fill="none"
                    stroke="#fe1d25"
                    strokeWidth={1.0583334}
                />
                <circle
                    cx={26.076485}
                    cy={342.17984}
                    r={8.0553389}
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M20.609 336.275a8.055 8.055 0 00-2.588 5.905 8.055 8.055 0 008.056 8.055 8.055 8.055 0 007.004-4.088 8.055 8.055 0 01-.46.405 8.055 8.055 0 01-.655.468 8.055 8.055 0 01-.7.401 8.055 8.055 0 01-.734.329 8.055 8.055 0 01-.764.254 8.055 8.055 0 01-.786.176 8.055 8.055 0 01-.8.097 8.055 8.055 0 01-.569.02 8.055 8.055 0 01-.804-.04 8.055 8.055 0 01-.796-.12 8.055 8.055 0 01-.78-.2 8.055 8.055 0 01-.757-.275 8.055 8.055 0 01-.725-.35 8.055 8.055 0 01-.686-.422 8.055 8.055 0 01-.64-.487 8.055 8.055 0 01-.59-.549 8.055 8.055 0 01-.532-.605 8.055 8.055 0 01-.468-.655 8.055 8.055 0 01-.4-.698 8.055 8.055 0 01-.33-.735 8.055 8.055 0 01-.253-.764 8.055 8.055 0 01-.177-.786 8.055 8.055 0 01-.097-.8 8.055 8.055 0 01-.02-.569 8.055 8.055 0 01.04-.804 8.055 8.055 0 01.12-.796 8.055 8.055 0 01.2-.78 8.055 8.055 0 01.276-.757 8.055 8.055 0 01.35-.725 8.055 8.055 0 01.065-.105z"
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <ellipse
                    cx={28.74918}
                    cy={338.57098}
                    rx={0.70726985}
                    ry={0.69707364}
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
                <ellipse
                    cx={23.40379}
                    cy={338.57098}
                    rx={0.70726985}
                    ry={0.69707364}
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
                <circle
                    cx={8.1896076}
                    cy={342.17984}
                    r={8.0553389}
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M2.722 336.275a8.055 8.055 0 00-2.588 5.905 8.055 8.055 0 008.056 8.055 8.055 8.055 0 007.004-4.088 8.055 8.055 0 01-.46.405 8.055 8.055 0 01-.655.468 8.055 8.055 0 01-.699.401 8.055 8.055 0 01-.735.329 8.055 8.055 0 01-.764.254 8.055 8.055 0 01-.786.176 8.055 8.055 0 01-.799.097 8.055 8.055 0 01-.57.02 8.055 8.055 0 01-.804-.04 8.055 8.055 0 01-.796-.12 8.055 8.055 0 01-.78-.2 8.055 8.055 0 01-.757-.276 8.055 8.055 0 01-.725-.35 8.055 8.055 0 01-.686-.42 8.055 8.055 0 01-.64-.488 8.055 8.055 0 01-.59-.549 8.055 8.055 0 01-.532-.605 8.055 8.055 0 01-.468-.655 8.055 8.055 0 01-.4-.698 8.055 8.055 0 01-.33-.735 8.055 8.055 0 01-.253-.764 8.055 8.055 0 01-.176-.786 8.055 8.055 0 01-.098-.8 8.055 8.055 0 01-.02-.569 8.055 8.055 0 01.04-.804 8.055 8.055 0 01.12-.796 8.055 8.055 0 01.2-.78 8.055 8.055 0 01.276-.757 8.055 8.055 0 01.35-.725 8.055 8.055 0 01.065-.105z"
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M29.588 344.818s-6.414 2.139-8.486-2.004"
                    opacity={1}
                    fill="none"
                    stroke="#1a1a1a"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M11.416 343.271c.53 2.106-.81 4.333-2.336 2.77-1.526-1.562 1.818-5.606 2.336-2.77z"
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M3.808 338.212h2.977"
                    opacity={1}
                    fill="none"
                    stroke="#fff"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M6.785 338.212h-.732"
                    opacity={1}
                    fill="none"
                    stroke="#1a1a1a"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M10.163 338.212h2.977"
                    opacity={1}
                    fill="none"
                    stroke="#fff"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M13.14 338.212h-.733"
                    opacity={1}
                    fill="none"
                    stroke="#1a1a1a"
                    strokeWidth={1.0583334}
                />
                <circle
                    cx={44.492531}
                    cy={342.17984}
                    r={8.0553389}
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M39.025 336.275a8.055 8.055 0 00-2.588 5.905 8.055 8.055 0 008.056 8.055 8.055 8.055 0 007.004-4.088 8.055 8.055 0 01-.46.405 8.055 8.055 0 01-.655.468 8.055 8.055 0 01-.699.401 8.055 8.055 0 01-.735.329 8.055 8.055 0 01-.764.254 8.055 8.055 0 01-.786.176 8.055 8.055 0 01-.8.097 8.055 8.055 0 01-.569.02 8.055 8.055 0 01-.804-.04 8.055 8.055 0 01-.796-.12 8.055 8.055 0 01-.78-.2 8.055 8.055 0 01-.757-.275 8.055 8.055 0 01-.725-.35 8.055 8.055 0 01-.686-.422 8.055 8.055 0 01-.64-.487 8.055 8.055 0 01-.59-.549 8.055 8.055 0 01-.532-.605 8.055 8.055 0 01-.468-.655 8.055 8.055 0 01-.4-.698 8.055 8.055 0 01-.33-.735 8.055 8.055 0 01-.253-.764 8.055 8.055 0 01-.177-.786 8.055 8.055 0 01-.097-.8 8.055 8.055 0 01-.02-.569 8.055 8.055 0 01.04-.804 8.055 8.055 0 01.12-.796 8.055 8.055 0 01.2-.78 8.055 8.055 0 01.276-.757 8.055 8.055 0 01.35-.725 8.055 8.055 0 01.065-.105z"
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <rect
                    height={3.2072346}
                    rx={1.6036173}
                    ry={1.6036173}
                    width={4.1092691}
                    x={39.455666}
                    y={337.32428}
                    opacity={1}
                    fill="#fff"
                    stroke="none"
                    strokeWidth={1.0583334}
                />
                <ellipse
                    cx={43.452389}
                    cy={338.92789}
                    rx={0.70726985}
                    ry={0.69707364}
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
                <rect
                    height={3.2072346}
                    rx={1.6036173}
                    ry={1.6036173}
                    width={4.1092691}
                    x={45.836727}
                    y={337.32428}
                    opacity={1}
                    fill="#fff"
                    stroke="none"
                    strokeWidth={1.0583334}
                />
                <ellipse
                    cx={49.950382}
                    cy={338.92789}
                    rx={0.70726985}
                    ry={0.69707364}
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
                <path
                    d="M183.271 235.475v1.224a3.387 3.387 0 01-3.393 3.393h-9.151V254.363a6.944 6.944 0 006.959 6.96c2.261 0 4.17-1.14 5.439-2.802.894-1.17 1.518-2.564 1.518-4.158v-14.947c0-1.498-.572-2.808-1.372-3.941z"
                    transform="matrix(.26458 0 0 .26458 0 280.067)"
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={4}
                />
                <path
                    d="M170.727 240.092h9.152a3.393 3.393 0 002.4-.992 3.387 3.387 0 00.992-2.4v-1.225c-1.257-1.782-3.229-3.018-5.585-3.018a6.944 6.944 0 00-6.96 6.959z"
                    transform="matrix(.26458 0 0 .26458 0 280.067)"
                    opacity={1}
                    fill="#ff8080"
                    stroke="none"
                    strokeWidth={4}
                />
                <path
                    d="M173.854 236.926c-.89 0-1.606.717-1.606 1.607v3.053h9.344v-3.053c0-.89-.717-1.607-1.608-1.607h-1.459c-.89 0-1.605.717-1.605 1.607 0-.89-.717-1.607-1.607-1.607z"
                    transform="matrix(.26458 0 0 .26458 0 280.067)"
                    opacity={1}
                    fill="#fff"
                    stroke="none"
                    strokeWidth={4}
                />
                <path
                    d="M45.212 341.094s-1.158.52-1.134 2.008"
                    opacity={1}
                    fill="none"
                    stroke="#fe1d25"
                    strokeWidth={1.0583334}
                />
                <circle
                    cx={62.37941}
                    cy={342.17984}
                    r={8.0553389}
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M56.912 336.275a8.055 8.055 0 00-2.588 5.905 8.055 8.055 0 008.055 8.055 8.055 8.055 0 007.005-4.088 8.055 8.055 0 01-.46.405 8.055 8.055 0 01-.655.468 8.055 8.055 0 01-.7.401 8.055 8.055 0 01-.734.329 8.055 8.055 0 01-.764.254 8.055 8.055 0 01-.786.176 8.055 8.055 0 01-.8.097 8.055 8.055 0 01-.569.02 8.055 8.055 0 01-.804-.04 8.055 8.055 0 01-.796-.12 8.055 8.055 0 01-.78-.2 8.055 8.055 0 01-.757-.275 8.055 8.055 0 01-.725-.35 8.055 8.055 0 01-.686-.422 8.055 8.055 0 01-.641-.487 8.055 8.055 0 01-.59-.549 8.055 8.055 0 01-.53-.605 8.055 8.055 0 01-.47-.654 8.055 8.055 0 01-.4-.7 8.055 8.055 0 01-.329-.734 8.055 8.055 0 01-.253-.764 8.055 8.055 0 01-.177-.786 8.055 8.055 0 01-.097-.8 8.055 8.055 0 01-.02-.569 8.055 8.055 0 01.04-.804 8.055 8.055 0 01.12-.796 8.055 8.055 0 01.2-.78 8.055 8.055 0 01.276-.757 8.055 8.055 0 01.35-.725 8.055 8.055 0 01.065-.105z"
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M62.635 342.209a3.814 1.558 0 00-3.814 1.558 3.814 1.558 0 00.017.134 1.427 1.723 0 00-.122.693 1.427 1.723 0 001.427 1.722 1.427 1.723 0 001.204-.801 1.427 1.723 0 001.206.801 1.427 1.723 0 001.287-.983 1.427 1.723 0 001.288.983 1.427 1.723 0 001.427-1.722 1.427 1.723 0 00-.122-.694 3.814 1.558 0 00.017-.133 3.814 1.558 0 00-3.815-1.558z"
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={1.22579861}
                />
                <path
                    d="M218.805 220.965a7.157 7.054 0 00-.172 1.514 7.157 7.054 0 007.156 7.052 7.157 7.054 0 007.158-7.052 7.157 7.054 0 00-.177-1.514z"
                    transform="matrix(.26458 0 0 .26458 0 280.067)"
                    opacity={1}
                    fill="#fff"
                    stroke="none"
                    strokeWidth={3.06789517}
                />
                <path
                    d="M227.742 220.965a2.673 2.635 0 00-.08.629 2.673 2.635 0 002.672 2.635 2.673 2.635 0 002.674-2.635 2.673 2.635 0 00-.08-.63z"
                    transform="matrix(.26458 0 0 .26458 0 280.067)"
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={1.14582837}
                />
                <path
                    d="M64.034 338.53a1.894 1.866 0 00-.045.4 1.894 1.866 0 001.893 1.867 1.894 1.866 0 001.894-1.866 1.894 1.866 0 00-.047-.4z"
                    opacity={1}
                    fill="#fff"
                    stroke="none"
                    strokeWidth={0.81171393}
                />
                <path
                    d="M66.399 338.53a.707.697 0 00-.021.167.707.697 0 00.707.697.707.697 0 00.707-.697.707.697 0 00-.021-.167z"
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
                <circle
                    cx={80.019196}
                    cy={342.17984}
                    r={8.0553389}
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M74.551 336.275a8.055 8.055 0 00-2.587 5.905 8.055 8.055 0 008.055 8.055 8.055 8.055 0 007.005-4.088 8.055 8.055 0 01-.46.405 8.055 8.055 0 01-.656.468 8.055 8.055 0 01-.698.401 8.055 8.055 0 01-.735.329 8.055 8.055 0 01-.764.254 8.055 8.055 0 01-.786.176 8.055 8.055 0 01-.8.097 8.055 8.055 0 01-.569.02 8.055 8.055 0 01-.804-.04 8.055 8.055 0 01-.796-.12 8.055 8.055 0 01-.78-.2 8.055 8.055 0 01-.757-.275 8.055 8.055 0 01-.725-.35 8.055 8.055 0 01-.686-.422 8.055 8.055 0 01-.641-.487 8.055 8.055 0 01-.59-.549 8.055 8.055 0 01-.531-.605 8.055 8.055 0 01-.468-.654 8.055 8.055 0 01-.4-.7 8.055 8.055 0 01-.33-.734 8.055 8.055 0 01-.254-.764 8.055 8.055 0 01-.176-.786 8.055 8.055 0 01-.097-.8 8.055 8.055 0 01-.02-.569 8.055 8.055 0 01.04-.804 8.055 8.055 0 01.12-.796 8.055 8.055 0 01.2-.78 8.055 8.055 0 01.275-.757 8.055 8.055 0 01.35-.725 8.055 8.055 0 01.065-.105z"
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M81.851 349.82h3.709v-2.039l-3.675 1.136z"
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M82.52 342.302c-1.407 0-2.54 1.53-2.54 3.43v2.977c0 .185.014.365.035.542h5.01c.02-.177.033-.357.033-.542v-2.978c0-1.9-1.132-3.429-2.538-3.429z"
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={1.22996891}
                />
                <rect
                    height={3.2072346}
                    rx={1.6036173}
                    ry={1.6036173}
                    width={4.1092691}
                    x={76.33886}
                    y={337.32428}
                    opacity={1}
                    fill="#fff"
                    stroke="none"
                    strokeWidth={1.0583334}
                />
                <ellipse
                    cx={80.335587}
                    cy={338.92789}
                    rx={0.70726985}
                    ry={0.69707364}
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
                <rect
                    height={3.2072346}
                    rx={1.6036173}
                    ry={1.6036173}
                    width={4.1092691}
                    x={82.719925}
                    y={337.32428}
                    opacity={1}
                    fill="#fff"
                    stroke="none"
                    strokeWidth={1.0583334}
                />
                <ellipse
                    cx={86.83358}
                    cy={338.92789}
                    rx={0.70726985}
                    ry={0.69707364}
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
                <path
                    d="M82.524 346.67a1.735 2.405 0 00-.123.006 1.735 2.405 0 00-.172.029 1.735 2.405 0 00-.17.053 1.735 2.405 0 00-.164.075 1.735 2.405 0 00-.158.099 1.735 2.405 0 00-.15.119 1.735 2.405 0 00-.141.14 1.735 2.405 0 00-.13.159 1.735 2.405 0 00-.119.175 1.735 2.405 0 00-.105.193 1.735 2.405 0 00-.09.204 1.735 2.405 0 00-.076.216 1.735 2.405 0 00-.06.226 1.735 2.405 0 00-.032.18 3.96 5.491 0 00.01.006H84.2a3.96 5.491 0 00.013-.007 1.735 2.405 0 00-.018-.11 1.735 2.405 0 00-.054-.229 1.735 2.405 0 00-.07-.22 1.735 2.405 0 00-.088-.208 1.735 2.405 0 00-.1-.195 1.735 2.405 0 00-.114-.18 1.735 2.405 0 00-.128-.165 1.735 2.405 0 00-.138-.145 1.735 2.405 0 00-.147-.126 1.735 2.405 0 00-.156-.105 1.735 2.405 0 00-.163-.082 1.735 2.405 0 00-.169-.06 1.735 2.405 0 00-.171-.035 1.735 2.405 0 00-.173-.013z"
                    opacity={1}
                    fill="#c24040"
                    stroke="none"
                    strokeWidth={0.01304576}
                />
                <path
                    d="M84.213 348.537a1.735 1.5 0 00-.017-.068 1.735 1.5 0 00-.055-.143 1.735 1.5 0 00-.07-.136 1.735 1.5 0 00-.087-.13 1.735 1.5 0 00-.101-.122 1.735 1.5 0 00-.115-.113 1.735 1.5 0 00-.127-.102 1.735 1.5 0 00-.138-.09 1.735 1.5 0 00-.147-.079 1.735 1.5 0 00-.156-.065 1.735 1.5 0 00-.163-.052 1.735 1.5 0 00-.168-.037 1.735 1.5 0 00-.172-.022 1.735 1.5 0 00-.173-.008 1.735 1.5 0 00-.123.004 1.735 1.5 0 00-.172.018 1.735 1.5 0 00-.17.033 1.735 1.5 0 00-.164.047 1.735 1.5 0 00-.158.061 1.735 1.5 0 00-.15.075 1.735 1.5 0 00-.141.087 1.735 1.5 0 00-.13.099 1.735 1.5 0 00-.119.11 1.735 1.5 0 00-.105.12 1.735 1.5 0 00-.09.127 1.735 1.5 0 00-.076.135 1.735 1.5 0 00-.06.14 1.735 1.5 0 00-.032.112 3.96 3.423 0 003.38 0z"
                    opacity={1}
                    fill="#f55"
                    stroke="none"
                    strokeWidth={0.01029941}
                />
                <circle
                    cx={26.076485}
                    cy={360.06674}
                    r={8.0553389}
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M20.609 354.161a8.055 8.055 0 00-2.588 5.906 8.055 8.055 0 008.056 8.055 8.055 8.055 0 007.004-4.088 8.055 8.055 0 01-.46.405 8.055 8.055 0 01-.655.468 8.055 8.055 0 01-.7.401 8.055 8.055 0 01-.734.329 8.055 8.055 0 01-.764.254 8.055 8.055 0 01-.786.176 8.055 8.055 0 01-.8.097 8.055 8.055 0 01-.569.02 8.055 8.055 0 01-.804-.04 8.055 8.055 0 01-.796-.12 8.055 8.055 0 01-.78-.2 8.055 8.055 0 01-.757-.276 8.055 8.055 0 01-.725-.35 8.055 8.055 0 01-.686-.42 8.055 8.055 0 01-.64-.488 8.055 8.055 0 01-.59-.549 8.055 8.055 0 01-.532-.605 8.055 8.055 0 01-.468-.655 8.055 8.055 0 01-.4-.698 8.055 8.055 0 01-.33-.735 8.055 8.055 0 01-.253-.764 8.055 8.055 0 01-.177-.786 8.055 8.055 0 01-.097-.8 8.055 8.055 0 01-.02-.569 8.055 8.055 0 01.04-.804 8.055 8.055 0 01.12-.796 8.055 8.055 0 01.2-.78 8.055 8.055 0 01.276-.757 8.055 8.055 0 01.35-.725 8.055 8.055 0 01.065-.106z"
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <circle
                    cx={8.1896076}
                    cy={360.06674}
                    r={8.0553389}
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M2.722 354.161a8.055 8.055 0 00-2.588 5.906 8.055 8.055 0 008.056 8.055 8.055 8.055 0 007.004-4.088 8.055 8.055 0 01-.46.405 8.055 8.055 0 01-.655.468 8.055 8.055 0 01-.699.401 8.055 8.055 0 01-.735.329 8.055 8.055 0 01-.764.254 8.055 8.055 0 01-.786.176 8.055 8.055 0 01-.799.097 8.055 8.055 0 01-.57.02 8.055 8.055 0 01-.804-.04 8.055 8.055 0 01-.796-.12 8.055 8.055 0 01-.78-.2 8.055 8.055 0 01-.757-.276 8.055 8.055 0 01-.725-.35 8.055 8.055 0 01-.686-.42 8.055 8.055 0 01-.64-.488 8.055 8.055 0 01-.59-.549 8.055 8.055 0 01-.532-.605 8.055 8.055 0 01-.468-.655 8.055 8.055 0 01-.4-.698 8.055 8.055 0 01-.33-.735 8.055 8.055 0 01-.253-.764 8.055 8.055 0 01-.176-.786 8.055 8.055 0 01-.098-.8 8.055 8.055 0 01-.02-.569 8.055 8.055 0 01.04-.804 8.055 8.055 0 01.12-.796 8.055 8.055 0 01.2-.78 8.055 8.055 0 01.276-.757 8.055 8.055 0 01.35-.725 8.055 8.055 0 01.065-.106z"
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <circle
                    cx={44.492531}
                    cy={360.06674}
                    r={8.0553389}
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M39.025 354.161a8.055 8.055 0 00-2.588 5.906 8.055 8.055 0 008.056 8.055 8.055 8.055 0 007.004-4.088 8.055 8.055 0 01-.46.405 8.055 8.055 0 01-.655.468 8.055 8.055 0 01-.699.401 8.055 8.055 0 01-.735.329 8.055 8.055 0 01-.764.254 8.055 8.055 0 01-.786.176 8.055 8.055 0 01-.8.097 8.055 8.055 0 01-.569.02 8.055 8.055 0 01-.804-.04 8.055 8.055 0 01-.796-.12 8.055 8.055 0 01-.78-.2 8.055 8.055 0 01-.757-.276 8.055 8.055 0 01-.725-.35 8.055 8.055 0 01-.686-.42 8.055 8.055 0 01-.64-.488 8.055 8.055 0 01-.59-.549 8.055 8.055 0 01-.532-.605 8.055 8.055 0 01-.468-.655 8.055 8.055 0 01-.4-.698 8.055 8.055 0 01-.33-.735 8.055 8.055 0 01-.253-.764 8.055 8.055 0 01-.177-.786 8.055 8.055 0 01-.097-.8 8.055 8.055 0 01-.02-.569 8.055 8.055 0 01.04-.804 8.055 8.055 0 01.12-.796 8.055 8.055 0 01.2-.78 8.055 8.055 0 01.276-.757 8.055 8.055 0 01.35-.725 8.055 8.055 0 01.065-.106z"
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <circle
                    cx={62.37941}
                    cy={360.06674}
                    r={8.0553389}
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M56.912 354.161a8.055 8.055 0 00-2.588 5.906 8.055 8.055 0 008.055 8.055 8.055 8.055 0 007.005-4.088 8.055 8.055 0 01-.46.405 8.055 8.055 0 01-.655.468 8.055 8.055 0 01-.7.401 8.055 8.055 0 01-.734.329 8.055 8.055 0 01-.764.254 8.055 8.055 0 01-.786.176 8.055 8.055 0 01-.8.097 8.055 8.055 0 01-.569.02 8.055 8.055 0 01-.804-.04 8.055 8.055 0 01-.796-.12 8.055 8.055 0 01-.78-.2 8.055 8.055 0 01-.757-.276 8.055 8.055 0 01-.725-.35 8.055 8.055 0 01-.686-.42 8.055 8.055 0 01-.641-.488 8.055 8.055 0 01-.59-.549 8.055 8.055 0 01-.53-.605 8.055 8.055 0 01-.47-.655 8.055 8.055 0 01-.4-.698 8.055 8.055 0 01-.329-.735 8.055 8.055 0 01-.253-.764 8.055 8.055 0 01-.177-.786 8.055 8.055 0 01-.097-.8 8.055 8.055 0 01-.02-.569 8.055 8.055 0 01.04-.804 8.055 8.055 0 01.12-.796 8.055 8.055 0 01.2-.78 8.055 8.055 0 01.276-.757 8.055 8.055 0 01.35-.725 8.055 8.055 0 01.065-.106z"
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <circle
                    cx={80.019196}
                    cy={360.06674}
                    r={8.0553389}
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M74.551 354.161a8.055 8.055 0 00-2.587 5.906 8.055 8.055 0 008.055 8.055 8.055 8.055 0 007.005-4.088 8.055 8.055 0 01-.46.405 8.055 8.055 0 01-.656.468 8.055 8.055 0 01-.698.401 8.055 8.055 0 01-.735.329 8.055 8.055 0 01-.764.254 8.055 8.055 0 01-.786.176 8.055 8.055 0 01-.8.097 8.055 8.055 0 01-.569.02 8.055 8.055 0 01-.804-.04 8.055 8.055 0 01-.796-.12 8.055 8.055 0 01-.78-.2 8.055 8.055 0 01-.757-.276 8.055 8.055 0 01-.725-.35 8.055 8.055 0 01-.686-.42 8.055 8.055 0 01-.641-.488 8.055 8.055 0 01-.59-.549 8.055 8.055 0 01-.531-.605 8.055 8.055 0 01-.468-.655 8.055 8.055 0 01-.4-.698 8.055 8.055 0 01-.33-.735 8.055 8.055 0 01-.254-.764 8.055 8.055 0 01-.176-.786 8.055 8.055 0 01-.097-.8 8.055 8.055 0 01-.02-.569 8.055 8.055 0 01.04-.804 8.055 8.055 0 01.12-.796 8.055 8.055 0 01.2-.78 8.055 8.055 0 01.275-.757 8.055 8.055 0 01.35-.725 8.055 8.055 0 01.065-.106z"
                    opacity={1}
                    fill="#fe1d25"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M5.127 357.436a1.894 1.866 0 00-.045.4 1.894 1.866 0 001.893 1.866 1.894 1.866 0 001.894-1.866 1.894 1.866 0 00-.047-.4z"
                    opacity={1}
                    fill="#fff"
                    stroke="none"
                    strokeWidth={0.81171393}
                />
                <path
                    d="M7.492 357.436a.707.697 0 00-.021.166.707.697 0 00.707.697.707.697 0 00.707-.697.707.697 0 00-.021-.166z"
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
                <path
                    d="M11.27 357.436a1.894 1.866 0 00-.046.4 1.894 1.866 0 001.893 1.866 1.894 1.866 0 001.894-1.866 1.894 1.866 0 00-.047-.4z"
                    opacity={1}
                    fill="#fff"
                    stroke="none"
                    strokeWidth={0.81171393}
                />
                <path
                    d="M13.634 357.436a.707.697 0 00-.021.166.707.697 0 00.707.697.707.697 0 00.707-.697.707.697 0 00-.02-.166z"
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
                <path
                    d="M10.123 361.445s1.938-.768 2.272-.2c.334.568-1.604 1.136-.97 2.138.636 1.002-.166.969-.834.702l-.669-.268"
                    opacity={1}
                    fill="none"
                    stroke="#1a1a1a"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M90.242 331.621v-39.664H79.211v33.895a30.445 30.445 0 0011.031 5.77zM116.313 327.047v-35.09h-11.03v40.06a30.445 30.445 0 0011.03-4.97z"
                    transform="matrix(.26458 0 0 .26458 0 280.067)"
                    opacity={1}
                    fill="#0085a6"
                    stroke="none"
                    strokeWidth={0.03990013}
                />
                <path
                    d="M20.67 357.314h3.674M27.384 357.314h3.675"
                    opacity={1}
                    fill="none"
                    stroke="#1a1a1a"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M27.115 364.335s-1.288-.484-2.292.06"
                    opacity={1}
                    fill="none"
                    stroke="#fe1d25"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M22.735 362.581s.785-.518 1.553-.016c.769.5 1.77-.635 2.54-.368.768.267 1.185.952 2.304.217M40.57 355.967l2.599 2.599M43.169 355.967l-2.599 2.599M46.24 355.967l2.598 2.599M48.838 355.967l-2.598 2.599"
                    opacity={1}
                    fill="none"
                    stroke="#1a1a1a"
                    strokeWidth={1.0583334}
                />
                <circle
                    cx={44.700829}
                    cy={362.92581}
                    r={1.6704345}
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M57.5 359.886s1.697 1.093 3.59 0M63.547 359.886s1.698 1.093 3.591 0"
                    opacity={1}
                    fill="none"
                    stroke="#1a1a1a"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M62.548 355.894h1.512l-1.512 1.512h1.512M65.038 353.836h1.512l-1.212 2.448h1.546M67.777 352.7h1.512l-1.211 2.448h1.545"
                    opacity={1}
                    fill="none"
                    stroke="#0085a6"
                    strokeWidth={1.0583334}
                />
                <circle
                    cx={62.938179}
                    cy={364.10699}
                    r={0.63099992}
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3997812}
                />
                <path
                    d="M77.135 358.976a1.894 1.866 0 00-.045.4 1.894 1.866 0 001.893 1.867 1.894 1.866 0 001.894-1.866 1.894 1.866 0 00-.047-.4z"
                    opacity={1}
                    fill="#fff"
                    stroke="none"
                    strokeWidth={0.81171393}
                />
                <path
                    d="M79.5 358.976a.707.697 0 00-.021.167.707.697 0 00.707.697.707.697 0 00.707-.697.707.697 0 00-.02-.167z"
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
                <path
                    d="M83.278 358.976a1.894 1.866 0 00-.046.4 1.894 1.866 0 001.894 1.867 1.894 1.866 0 001.893-1.866 1.894 1.866 0 00-.047-.4z"
                    opacity={1}
                    fill="#fff"
                    stroke="none"
                    strokeWidth={0.81171393}
                />
                <path
                    d="M85.642 358.976a.707.697 0 00-.02.167.707.697 0 00.706.697.707.697 0 00.708-.697.707.697 0 00-.022-.167z"
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
                <path
                    d="M83.355 364.051s-1.838-1.704-3.542 0"
                    opacity={1}
                    fill="none"
                    stroke="#1a1a1a"
                    strokeWidth={1.0583334}
                />
                <path
                    d="M77.249 357.996h4.63M81.88 357.996h4.63"
                    opacity={1}
                    fill="none"
                    stroke="#fe1d25"
                    strokeWidth={1.0583334}
                />
            </g>
        </svg>
    )
}

export default SvgComponent
