import React, { Fragment } from 'react';
import Datatable from "../../components/datatable";
import BaseComponent from '../../components/component/base';
import Icon from '../../components/icon';
import { Row, Col, Card, Form } from "react-bootstrap";

class Content extends BaseComponent {

    render() {
        return (
            <Fragment>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Header className="px-10 py-10 background-white">
                                <button
                                    type="button"
                                    className="btn btn-uniform text-success"
                                    disabled={false}
                                    onClick={(e) => { this.props.openLink('/team/') }}
                                >
                                    <Icon name="plus-square" />
                                    <span className="pl-10">
                                        {this.props.t('page.teams.new')}
                                    </span>
                                </button>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Datatable
                                    id={`team-table`}
                                    endpoint={`team/table`}
                                    columns={[
                                        {
                                            Header: this.props.t('page.teams.table.name'),
                                            accessor: "label_1"
                                        },
                                        {
                                            Header: this.props.t('page.teams.table.companyName'),
                                            accessor: "name_3"
                                        },
                                        {
                                            Header: this.props.t(`page.teams.table.category`),
                                            accessor: "category_2"
                                        },
                                        {
                                            Header: (<>&nbsp;</>),
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                            width: 100
                                        }
                                    ]}
                                    sort={[
                                        {
                                            id: "label_1",
                                            desc: false
                                        }
                                    ]}
                                    withCheckbox={false}
                                    onChecked={(data) => { }}
                                    badges={[]}
                                    transform={[{
                                        field: "label_1",
                                        fn: (data) => {
                                            return <span>
                                                <span style={{ color: data.color_4 }}>&#9724;</span>
                                                {` `}
                                                {data.label_1}
                                            </span>
                                        }
                                    }]}
                                    actions={[
                                        {
                                            action: 'edit',
                                            callback: (data) => {
                                                this.props.openLink(`/team/${data.uuid_0}`)
                                            }
                                        },
                                        {
                                            action: 'delete',
                                            callback: (data) => {
                                                this.confirmRemove(() => {
                                                    this.props.api.post(
                                                        `/team/trash`,
                                                        {
                                                            "team": {
                                                                "uuids": [data.uuid_0]
                                                            }
                                                        },
                                                        () => {
                                                            this.props.api.dispatchEvent('RELOAD_TABLE');
                                                        }
                                                    )
                                                })
                                            }
                                        }
                                    ]}
                                    {...this.props}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }

}

export default Content
