import './style.scss';
import React, { Component } from 'react';
import confetti from 'canvas-confetti';
import anime0 from '../../assets/images/gif/unicorn/0.png';
import anime1 from '../../assets/images/gif/unicorn/1.png';

class Unicorn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            frame: 0,
            alive: false
        }
        this.animeRef = React.createRef();
        this.animeFrameInterval = undefined;
        this.animeAliveInterval = undefined;
        this.animeAliveIntervalPaused = true;
        this.animeStarted = props.born;
        this.animeDuration = 7.5;
        this.animeFrames = [
            anime0,
            anime1
        ];
    }

    componentDidMount() {
        this.animeFrameInterval = setInterval((self) => {
            if (self.animeAliveIntervalPaused) {
                return;
            }
            let newFrameIndex = self.state.frame + 1;
            if (newFrameIndex === self.animeFrames.length) {
                newFrameIndex = 0;
            }
            self.setState({ frame: newFrameIndex });
        }, 500, this);

        this.animeAliveInterval = setInterval((self) => {
            if (self.animeAliveIntervalPaused) {
                return;
            }
            let alive = false;
            let now = (new Date()).getTime() / 1000;
            if (now - this.animeStarted < this.animeDuration) {
                alive = true;
            }
            this.setState({ alive });
        }, 50, this);

    }

    componentWillUnmount() {
        clearInterval(this.animeFrameInterval);
        clearInterval(this.animeAliveInterval);
    }

    componentDidUpdate(prevProps) {
        if (this.props.born !== prevProps.born) {
            this.animeAliveIntervalPaused = true;
            this.animeStarted = this.props.born;
            this.setState({ alive: true }, () => {
                console.log('✨✔');
                this.hurray();
                this.animeAliveIntervalPaused = false;
            });
        }
    }

    hurray() {
        confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
            zIndex: 9999
        });
    }

    render() {
        return this.state.alive && (
            <div className='unicorn-vernissage' role="dialog" aria-modal="true" ref={this.animeRef}>
                <img src={this.animeFrames[this.state.frame]} alt="hurray!!!" />
            </div>
        )
    }
}

export default Unicorn;