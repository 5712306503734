import React, { Component, Fragment } from 'react';
import { Row, Col } from "react-bootstrap";
import Discussion from '../../components/discussion';

class Comments extends Component {

    render() {

        const { isLoading, closed, uuid } = this.props;

        return (
            <Fragment>

                <h2>
                    {this.props.t('page.calculation.comments.title')}
                </h2>

                <Row>
                    <Col sm={12}>
                        <Discussion
                            t={this.props.t}
                            api={this.props.api}
                            utils={this.props.utils}
                            getUser={this.props.getUser}
                            location={this.props.location}
                            showToast={this.props.showToast}
                            hasPermission={this.props.hasPermission}
                            anchors={{ offer: { uuid: uuid } }}
                            filter={(comment) => comment}
                            mainpage={this.props.type} // lead / calculation
                            divStyle={{ maxHeight: 'unset' }}
                            buildLink={this.props.buildLink}
                            readOnly={isLoading || closed}
                        />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}
export default Comments;