class API {

    constructor() {
        this.config = {
            debug: 1,
            url: undefined,
            env: undefined,
            xhrAbort: null,
            data: [],
            timeOffset: 0,
            progressFactor: 1,
            headers: [],
        }
        this.token = {
            name: "Authorization",
            type: "Bearer",
            value: null,
            key: 'bp_token',
            keys: 'bp_tokens',
            validity: 14, // days
        };
        this.secureStorage = {
            setItem(name, value, days) {
                var expires = "";
                if (days) {
                    var date = new Date();
                    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                    expires = "; expires=" + date.toUTCString();
                }
                document.cookie = name + "=" + (value || "") + expires + "; path=/; Secure; SameSite=Strict";
            },

            getItem(name) {
                var nameEQ = name + "=";
                var ca = document.cookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
                }
                return null;
            }
        };
    }

    isDef(v) {
        return typeof v !== "undefined";
    }

    dispatchEvent(key, value) {
        var eventName = key;
        window.dispatchEvent(new CustomEvent(eventName, { 'detail': value }), true);
    }

    triggerDownload(dataurl, filename) {
        const link = document.createElement("a");
        link.href = dataurl;
        link.download = filename;
        link.target = "_blank"
        link.click();
    }

    getConfig(key) {
        return this.config[key] || null;
    }

    setConfig(key, value) {
        this.config[key] = value;
        return this.config[key] || null;
    }

    setStored(storage, key, value) {
        let dismissed = this.getStored(storage, null, {});
        dismissed = { ...dismissed, [key]: value };
        window.localStorage.setItem(storage, JSON.stringify(dismissed));
        return dismissed;
    }

    getStored(storage, key, defaultValue) {
        const data = JSON.parse(window.localStorage.getItem(storage)) || null;
        if (key && data) {
            return data[key] || defaultValue;
        }
        return data || defaultValue;
    }

    setSecureStored(key, value, days) {
        return this.secureStorage.setItem(key, JSON.stringify(value), days);
    }

    getSecureStored(key) {
        if ([this.token.key, this.token.keys].includes(key)) {
            const plainValue = JSON.parse(window.localStorage.getItem(key)) || null;
            if (plainValue) {
                this.setSecureStored(key, plainValue, this.token.validity);
                window.localStorage.removeItem(key);
                return plainValue;
            }
        }
        return JSON.parse(this.secureStorage.getItem(key)) || null;
    }

    setToken(value) {
        var self = this;
        self.token.value = value;
        self.setSecureStored(self.token.key, self.token.value, self.token.validity);
    }

    getToken() {
        var self = this;
        self.token.value = self.getSecureStored(self.token.key);
        return self.token.value;
    }

    getRegisteredTokens() {
        var self = this;
        return (self.getSecureStored(self.token.keys) || []).map(itm => {
            var newItm = { ...itm };
            newItm.token['current'] = newItm.token.uuid === self.getSecureStored(self.token.key);
            return newItm;
        });
    }

    deRegisterTokens() {
        var self = this;
        self.setSecureStored(self.token.keys, null, self.token.validity);
    }

    registerToken(tokenData) {
        var self = this;
        let currentFound = false;
        let currentTokens = (self.getSecureStored(self.token.keys) || []).map((storedToken) => {
            let newToken = { ...storedToken };
            if (newToken.user?.emailAddress === tokenData.user?.emailAddress) {
                newToken = { ...tokenData };
                currentFound = true;
            }
            return newToken;
        });
        if (currentFound === false) {
            currentTokens.push(tokenData);
        }
        self.setSecureStored(self.token.keys, currentTokens.filter(Boolean), self.token.validity);
    }

    hashCode(str, prefix) {
        var hash = 0, i = 0, len = str.length;
        while (i < len) {
            hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
        }
        hash = prefix + hash;
        return hash;
    }

    syncTime(ts) {
        var self = this;
        var resolve = function (data) {
            var dateStr = data.time.date;
            var serverTimeMillisGMT = Date.parse(new Date(Date.parse(dateStr)).toUTCString());
            var localMillisUTC = Date.parse(new Date().toUTCString());
            self.config.timeOffset = serverTimeMillisGMT - localMillisUTC;
        }
        resolve({ time: { date: ts } });
    }

    utcToLocal(date) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();
        newDate.setHours(hours - offset);
        return newDate;
    }

    getTime(asTimestamp) {
        var date = new Date();
        date.setTime(date.getTime() + this.config.timeOffset);
        var local = this.utcToLocal(date);
        if (asTimestamp) {
            return parseInt((local.getTime() / 1000).toFixed(0));
        }
        return local;
    }

    setCookie(sName, sValue, options) {
        var sCookie = encodeURIComponent(sName) + '=' + encodeURIComponent(sValue);
        if (options && options instanceof Date) {
            options = {
                expires: options
            };
        }
        if (options && typeof options == 'object') {
            if (options.expires) {
                sCookie += '; expires=' + options.expires.toGMTString();
            }
            if (options.path) {
                sCookie += '; path=' + options.path.toString();
            }
            if (options.domain) {
                sCookie += '; domain=' + options.domain.toString();
            }
            if (options.secure) {
                sCookie += '; secure';
            }
        }
        document.cookie = sCookie;
    }

    getCookie(sName) {
        var oCrumbles = document.cookie.split(';');
        for (var i = 0; i < oCrumbles.length; i++) {
            var oPair = oCrumbles[i].split('=');
            var sKey = decodeURIComponent(oPair[0].trim());
            var sValue = oPair.length > 1 ? oPair[1] : '';
            if (sKey === sName) {
                return decodeURIComponent(sValue);
            }
        }
        return '';
    }

    removeCookie(sName, options) {
        if (!options) {
            options = {};
        }
        options.expires = new Date();
        this.setCookie(sName, '', options);
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }

    isGuid(stringToTest) {
        if (stringToTest[0] === "{") {
            stringToTest = stringToTest.substring(1, stringToTest.length - 1);
        }
        var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return regexGuid.test(stringToTest);
    }

    abort() {
        this.xhrAbort = Date.now();
    }

    endpoint(endpoint) {
        return this.config.url + endpoint.replace(/^\/+/g, '');
    }

    get(endpoint, resolveFn, rejectFn, progressFn) {
        return this.request(endpoint, {}, resolveFn, rejectFn, progressFn, 'GET');
    }

    post(endpoint, data, resolveFn, rejectFn, progressFn) {
        return this.request(endpoint, data, resolveFn, rejectFn, progressFn, 'POST');
    }

    async fetch(params) {
        const self = this;
        const url = params.rawUrl || self.endpoint(params.url);
        let headers = { "Content-Type": "application/json" };

        if (self.token.value) {
            headers[self.token.name] = self.token.type + ' ' + self.token.value;
        }

        if (self.config.headers) {
            for (var h = 0; h < self.config.headers.length; h++) {
                headers[self.config.headers[h].name] = self.config.headers[h].value;
            }
        }

        const res = await fetch(url, {
            method: params.method || "POST",
            headers: headers,
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: params.data ? JSON.stringify(params.data) : null, // body data type must match "Content-Type" header
        });
        return await res.json();
    }

    postSilent(endpoint, data, resolveFn, rejectFn, progressFn) {
        return this.request(endpoint, data, resolveFn, rejectFn, progressFn, 'POST', true);
    }

    request(endpoint, data, resolveFn, rejectFn, progressFn, method, silent) {
        var self = this;
        var xhrCreated = Date.now();
        var xhr = new XMLHttpRequest();
        var url = "";

        var isAborted = function () {
            var test = this.xhrAbort > xhrCreated;
            return test;
        }.bind(this);

        if (typeof data == "undefined") {
            data = {};
        }

        if (typeof method == "undefined") {
            method = 'POST';
        }

        var rejected = false;

        var progress = function (data) {
            if (silent) {
                return;
            }
            if (typeof progressFn === "function") {
                if (!isAborted()) {
                    progressFn(data);
                }
            } else {
                self.dispatchEvent('API_PROGRESS', parseFloat(data) * self.config.progressFactor);
            }
        }

        var resolve = function (data) {
            progress(100);
            if (typeof resolveFn === "function") {
                if (!isAborted()) {
                    resolveFn(data)
                }
            }
        }

        var reject = function (data) {
            if (rejected) {
                return;
            } else {
                rejected = true;
            }
            progress(100);
            if (self.isDef(data) && self.config.debug) {
                console.log("api.js", data ? JSON.stringify(data) : null);
            }
            if (typeof rejectFn === "function") {
                if (!isAborted()) {
                    rejectFn(data);
                }
            }
            self.dispatchEvent('API_STATUS', data);
        }

        progress(0);

        url = self.endpoint(endpoint);

        xhr.open(method, url, true);

        if (typeof data.files !== "undefined" && data.files.length && data.files[0] instanceof Blob) {
            let formData = new FormData();
            for (var f = 0; f < data.files.length; f++) {
                formData.append('files[]', data.files[f], data.files[f].name);
            }
            delete data.files;
            formData.append('_input', JSON.stringify(data));
            data = formData;
        } else {
            xhr.setRequestHeader('Content-Type', 'application/json');
            data = JSON.stringify(data);
        }

        if (self.token.value) {
            xhr.setRequestHeader(self.token.name, self.token.type + ' ' + self.token.value);
        }

        if (self.config.headers) {
            for (var h = 0; h < self.config.headers.length; h++) {
                xhr.setRequestHeader(self.config.headers[h].name, self.config.headers[h].value);
            }
        }

        xhr.setRequestHeader('Accept', 'application/json');

        xhr.onprogress = function (event) {
            if (event.lengthComputable) {
                var complete = (event.loaded / event.total * 100 | 0);
                progress(complete);
            }
        }

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 2) {
                const maintenance = JSON.parse(xhr.getResponseHeader("x-maintenance"));
                self.dispatchEvent('API_MAINTENANCE', maintenance);
            }
            if (xhr.readyState === 4) {
                var obj = { meta: null, data: null };
                try {
                    obj = JSON.parse(xhr.response);
                } catch (jerror) {
                    return reject({
                        status: xhr.status,
                        ident: "xhr.onreadystatechange.JSONparse",
                        data: {
                            error: {
                                ident: jerror.message,
                            }
                        },
                        captureObject: {
                            xhrStatus: xhr.status,
                            jerror: jerror?.message,
                            response: xhr.response,
                        },
                    });
                }
                if (obj && obj.meta?.success) {
                    return resolve(obj.data);
                } else {
                    return reject({
                        ident: obj.meta?.ident,
                        status: xhr.status,
                        data: obj.data,
                    });
                }
            }
        };

        xhr.onerror = function () {
            return reject({
                ident: "xhr.onerror",
                status: xhr.status,
                data: {
                    error: {
                        ident: xhr.statusText
                    }
                }
            });
        };

        xhr.send(data);

    }

}

export default API;