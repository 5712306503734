import "./style.scss";
import { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Location from "./element/location";
import Slope from "./element/slope";
import Dimensions from "./element/dimensions";
import Parameters from "./element/parameters";
import Toolbox from "./element/toolbox";
import CustomSE from "./element/custom-se";
import Icon from "../icon";

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab1: 'roof'
        };
    }

    render() {
        return (
            <>
                <Tabs
                    className="m-0 nicebar"
                    id="tooltabs"
                    defaultActiveKey={`roof`}
                    activeKey={this.state.activeTab1}
                    onSelect={
                        (k) => {
                            this.setState(
                                { activeTab1: k },
                                () => {
                                    this.props.setSidebarVisible(this.state.activeTab1 === 'hide')
                                })
                        }
                    }
                >
                    <Tab eventKey="roof" title={this.props.t('page.calculation.pvDesigner.roofSideTab')}>
                        {
                            this.props.selectedElement?.elementCategory === "slope" ? (
                                <>
                                    <Slope {...this.props} />
                                    <div className="mb-20" />
                                    <Dimensions {...this.props} />
                                </>
                            ) : (
                                <>
                                    <p style={{ border: "2px dotted #ccc", fontSize: 20, padding: 20, textAlign: "center", color: "#ccc" }}>
                                        {this.props.t('page.calculation.pvDesigner.roofSidePleaseSelect')}
                                    </p>
                                </>
                            )
                        }
                    </Tab>
                    <Tab eventKey="location" title={this.props.t('page.calculation.pvDesigner.locationTab')}>
                        <Location {...this.props} />
                    </Tab>
                    <Tab eventKey="parameters" title={this.props.t('page.calculation.pvDesigner.parametersTab')}>
                        <Parameters {...this.props} />
                        {
                            this.props.isRegion(['SWEDEN']) && (
                                <>
                                    <div className="mb-20" />
                                    <CustomSE {...this.props} />
                                </>
                            )
                        }
                        <div className="mb-20" />
                        <Toolbox {...this.props} />
                    </Tab>
                    <Tab eventKey="hide" title={<Icon name='eye-off' />} />
                </Tabs>
            </>
        )
    }
}

export default Sidebar;