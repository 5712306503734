import React, { Component } from 'react';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import Content from './content';

class NotFoundPage extends Component {
    render() {
        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={
                        {
                            '/': this.props.t('common.breadcrumbs.home'),
                        }
                    }
                    meta={{ title: "" }}
                    t={this.props.t}
                >
                    <h1>404</h1>
                </PageTitle>
                <PageContent>
                    <Content {...this.props} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default NotFoundPage;
