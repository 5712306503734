import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col } from 'react-bootstrap';

export class Dnd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            droppableId: this.props.droppableId,
            items: this.props.items || []
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        nextState.items = nextProps.items || [];
        return true;
    }

    getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        padding: this.props.itemPadding || `5px 5px 5px 25px`,
        margin: this.props.direction === "horizontal" ? `0` : `0 0 0 0`,
        border: `1px solid #fff`,
        backgroundColor: isDragging ? '#007bff' : 'white',
        borderColor: isDragging ? '#007bff' : '#fff',
        color: isDragging ? '#000' : '#333',
        transition: `background-color 0.5s ease-out`,
        ...draggableStyle
    });

    getListStyle = isDraggingOver => ({
        backgroundColor: isDraggingOver ? '#e3e3e3' : 'white',
        transition: `background-color 0.3s ease-out`,
    });

    onDragEnd(result) {
        const { source, destination } = result;
        if (!source || !destination) {
            return;
        }

        let newItems = this.state.items;
        for (let i = 0; i < newItems.length; i++) {
            if (i === source.index) {
                newItems[i].position = destination.index;
            } else if (i < source.index) {
                newItems[i].position = i + 1;
            } else if (i > source.index) {
                newItems[i].position = i - 1;
            }
        }

        let fixedPosition = newItems
            .sort((a, b) => (a.position > b.position) ? 1 : (a.position < b.position) ? -1 : 0)
            .map(
                ({ ...itm }, index) => ({
                    ...itm,
                    position: index
                })
            );

        this.props.onUpdate(fixedPosition);
    }

    render() {
        return (
            <DragDropContext onDragEnd={(e) => { this.onDragEnd(e) }}>
                <Droppable droppableId={this.state.droppableId} direction={this.props.direction || "vertical"}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={this.getListStyle(snapshot.isDraggingOver)}
                            className={this.props.containerClass || ""}
                        >
                            {this.props.firstItem}
                            {
                                this.state.items.filter(item => item.trash === false).sort((a, b) => (a.position > b.position) ? 1 : (a.position < b.position) ? -1 : 0).map((item, index) => (
                                    <Draggable
                                        key={item.uuid}
                                        draggableId={item.uuid}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                className={this.props.itemClass || ""}
                                                style={this.getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>
                                                {
                                                    this.props.renderItem(item, index, provided.dragHandleProps)
                                                }
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                            }
                            {this.props.lastItem}
                            {provided.placeholder}
                            {
                                this.state.items.length === 0 && !this.props.lastItem && !this.props.firstItem && this.props.placeholder
                                    ?
                                    (
                                        <Row>
                                            <Col sm={12}>
                                                <p
                                                    className="lead text-muted"
                                                    style={{
                                                        border: `1px dashed #e3e3d3`,
                                                        textAlign: `center`,
                                                        padding: `8px`,
                                                        lineHeight: `48px`,
                                                        margin: `0 8px`
                                                    }}
                                                >
                                                    {this.props.placeholder}
                                                </p>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    <></>
                            }
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }
}

export default Dnd