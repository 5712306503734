import React, { Fragment } from 'react';
import { Row, Col, Form, ListGroup, Alert, Button } from "react-bootstrap";
import { AlertTriangle } from 'react-feather';
import BaseComponent from '../../components/component/base';
import Discussion from '../../components/discussion';
import DropzoneWrapper from '../../components/dropzone';
import Icon from '../../components/icon';
import { IsLoading } from '../../components/spinner';
import { acceptFileType, kanbanTerminal } from '../../constants';
import confetti from 'canvas-confetti';

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            ident: 'task',
            isLoading: true,

            taskuuid: '',
            tasklabel: '',
            taskdescription: '',
            tasksince: '',
            tasktill: '',
            taskprogress: '',
            taskpreviousprogress: '',
            taskchecklist: [],
            taskstatus: '',
            taskconfirmed: false,
            taskdone: false,

            filesrootuuid: '',
            currentfilesrootuuid: '',
            previousfilesrootuuids: [],

            contractor: '',

            projectuuid: '',
            projectlabel: '',
            projectdescription: '',

            pmlabel: '',
            pmmobilephone: '',

            addresslabel: '',

            files: [],
            anchors: {},
            anchorsFetchFiles: {},
        };

        this.delayedRequestTimeout = undefined;
        this.fetchTask = this.fetchTask.bind(this);
    }

    componentDidMount() {
        this.fetchTask();
        window.addEventListener("FETCH_TASK", this.fetchTask, false);
    }

    componentWillUnmount() {
        window.removeEventListener('FETCH_TASK', this.fetchTask, false);
    }

    hurray() {
        confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
            zIndex: 9999
        });
    }

    vibrate() {
        if (navigator && typeof navigator.vibrate === 'function') {
            navigator.vibrate(50);
        }
    }

    fetchTask() {
        const taskuuid = this.props.match.params.uuid;
        this.setState({ isLoading: true }, () => {
            this.props.api.post(
                `/task/describe`,
                {
                    task: {
                        uuid: taskuuid
                    }
                },
                (dataObject) => {
                    this.afterFetchTaskSuccess(dataObject, () => {
                        this.fetchFiles(this.state.filesrootuuid);
                    });
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        })
    }

    fetchFiles(currentfilesrootuuid) {
        let anchorsFetchFiles = { ...this.state.anchorsFetchFiles };
        let previousfilesrootuuids = [...this.state.previousfilesrootuuids];
        const filesrootuuid = this.state.filesrootuuid;
        if (currentfilesrootuuid) {
            if (currentfilesrootuuid !== filesrootuuid) {
                if (previousfilesrootuuids.includes(currentfilesrootuuid)) {
                    previousfilesrootuuids.splice(previousfilesrootuuids.indexOf(currentfilesrootuuid));
                } else {
                    previousfilesrootuuids.push(currentfilesrootuuid);
                }
            }
        }
        let NEWcurrentfilesrootuuid = previousfilesrootuuids[previousfilesrootuuids.length - 1] || filesrootuuid;
        anchorsFetchFiles.file.uuid = NEWcurrentfilesrootuuid;
        this.props.api.post(
            `/file/list-partial-task`,
            {
                anchors: anchorsFetchFiles
            },
            (dataObject) => {
                this.setState({
                    files: dataObject.files,
                    currentfilesrootuuid: NEWcurrentfilesrootuuid,
                    previousfilesrootuuids: previousfilesrootuuids,
                    anchorsFetchFiles: anchorsFetchFiles,
                });
            },
            (errorObject) => {
                this.afterFetchError(errorObject);
            }
        );
    }

    afterFetchTaskSuccess(dataObject, callback) {
        if (typeof callback !== "function") {
            callback = () => { }
        }
        this.setState({
            isLoading: false,
            taskuuid: dataObject.task.uuid,
            tasklabel: dataObject.task.label,
            taskdescription: dataObject.task.description,
            tasksince: dataObject.task.since,
            tasktill: dataObject.task.till,
            taskconfirmed: dataObject.task.confirmed ? true : false,
            taskprogress: String(dataObject.task.progress),
            taskpreviousprogress: String(dataObject.task.progress),
            taskchecklist: dataObject.task.checklist || [],
            taskstatus: dataObject.task.status,
            taskdone: kanbanTerminal.includes(dataObject.task.status?.toLowerCase()),

            filesrootuuid: dataObject.root?.uuid,
            currentfilesrootuuid: dataObject.root?.uuid,
            previousfilesrootuuids: [dataObject.root?.uuid],
            contractor: dataObject.contractor,

            projectuuid: dataObject.project.uuid,
            projectlabel: dataObject.project.label,
            projectdescription: dataObject.project.description,

            pmlabel: dataObject.pm?.label,
            pmmobilephone: dataObject.pm?.mobilePhone,

            addresslabel: dataObject.address?.label,

            anchors: { task: { uuid: dataObject.task.uuid }, project: { uuid: dataObject.project.uuid } },
            anchorsFetchFiles: { project: { uuid: dataObject.project.uuid }, file: { uuid: dataObject.root?.uuid } },
        }, () => {
            this.props.setMeta({
                title: dataObject.task.label,
                shortTitle: dataObject.project.label
            });
            callback();
        })
    }

    afterFetchError(errorObject) {
        this.setState({ isLoading: false }, () => {
            this.props.showToast({
                errorObject: errorObject,
                title: this.props.t('common.toast.error'),
                color: 'danger'
            });
        })
    }

    checkChecklistItem(itemuuid, state) {
        const { taskuuid } = this.state;
        this.vibrate();
        this.props.api.post(
            `/task/update-partial-checklist`,
            {
                task: {
                    uuid: taskuuid
                },
                checklist: {
                    item: {
                        uuid: itemuuid,
                        done: state,
                    }
                }
            },
            (dataObject) => {
                this.afterFetchTaskSuccess(dataObject);
            },
            (errorObject) => {
                this.afterFetchError(errorObject);
            }
        );
    }

    setTaskConfirmed(e, confirmed) {
        if (e) {
            e.preventDefault();
        }
        const { taskuuid } = this.state;
        this.props.api.post(
            `/task/update-partial-confirmed`,
            {
                task: {
                    uuid: taskuuid,
                    confirmed: confirmed
                }
            },
            (dataObject) => {
                this.afterFetchTaskSuccess(dataObject);
            },
            (errorObject) => {
                this.afterFetchError(errorObject);
            }
        );
    }

    setTaskProgress(e, progress) {
        if (e) {
            e.preventDefault();
        }
        const { taskuuid } = this.state;
        progress = parseInt(progress, 10);

        const callback = () => {
            this.props.api.post(
                `/task/update-partial-progress`,
                {
                    task: {
                        uuid: taskuuid,
                        progress: progress,
                        status: progress === 0 ? 'todo' : progress === 100 ? 'done' : 'doing'
                    }
                },
                (dataObject) => {
                    this.afterFetchTaskSuccess(dataObject, () => {
                        if (progress === 100) {
                            this.hurray();
                        }
                    });
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        }

        if (progress === 100) {
            this.confirm(
                {
                    title: this.props.t('page.task.content.modal.confirmDone.title'),
                    text: this.props.t('page.task.content.modal.confirmDone.text'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.props.t('common.button.confirm'),
                    confirmButtonColor: '#fdbf21',
                    cancelButtonColor: '#aaa',
                    cancelButtonText: this.props.t('common.button.cancel')
                },
                () => callback(),
                () => {
                    this.setState({ taskprogress: this.state.taskpreviousprogress })
                }
            );
        } else {
            callback();
        }
    }

    setTaskProgressDelayed(newState) {
        this.setState(newState, () => {
            if (this.delayedRequestTimeout) {
                clearTimeout(this.delayedRequestTimeout);
            }
            this.delayedRequestTimeout = setTimeout(() => {
                this.setTaskProgress(null, this.state.taskprogress);
            }, 500);
        })
    }

    render() {
        const {
            ident,
            isLoading,

            taskuuid,
            tasklabel,
            taskdescription,
            taskchecklist,
            taskconfirmed,
            taskprogress,
            taskdone,
            tasksince,
            tasktill,

            projectuuid,
            projectlabel,
            projectdescription,

            pmlabel,
            pmmobilephone,

            addresslabel,

            filesrootuuid,
            currentfilesrootuuid,
            previousfilesrootuuids,

            files,
            anchors,
        } = this.state;

        const loadingView = (
            <IsLoading />
        )

        const maybeConfirmView = taskconfirmed ? (
            <Alert variant='success' className="mb-25">
                {this.props.t('page.task.content.confirmed')}
            </Alert>
        ) : (
            <Alert variant='warning' className="mb-25">
                {this.props.t('page.task.content.doYouConfirm')}
                <br />
                <Button variant="outline-success" className="mt-10" onClick={(e) => this.setTaskConfirmed(e, true)}>{this.props.t('page.task.content.yesIConfirm')}</Button>
            </Alert>
        );

        const maybeDoneView = taskdone && (
            <Alert variant='success' className="mb-25">
                {this.props.t('page.task.content.done')}
            </Alert>
        )

        const projectDetailsView = (
            <>
                <h4 className='mt-25'>{projectlabel}</h4>

                <div className="border px-15 pt-15 single-task-description">
                    <p>
                        <Icon name="user" className="mr-5 mb-5" />
                        <span className='mr-5'>
                            {pmlabel || '---'}
                        </span>
                    </p>
                    <p>
                        <Icon name="phone" className="mr-5 mb-5" />
                        <a href={`tel:${pmmobilephone}`}>
                            {pmmobilephone || '---'}
                        </a>
                    </p>
                    <p>
                        <Icon name="map" className="mr-5 mb-5" />
                        <a target="_blank" rel="noopener noreferrer" href={"https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=" + encodeURI(addresslabel)}>
                            {addresslabel || '---'}
                        </a>
                    </p>
                </div>
            </>
        )

        const taskDetailsView = (
            <>
                <h4 className="mt-25">{tasklabel}</h4>

                <div className="border px-15 pt-15 single-task-description">
                    <p>
                        <Icon name="calendar" className="mr-5 mb-5" />
                        {tasksince || '---'}
                        {` - `}
                        {tasktill || '---'}
                    </p>
                    <div dangerouslySetInnerHTML={{ __html: taskdescription || '' }} />
                </div>
            </>
        )

        const taskProgressView = (
            <>
                <h4 className="mt-25">{this.props.t('page.task.content.header.progress')}</h4>

                <div className="border p-15 text-center">
                    <Form.Control
                        disabled={this.props.isLoading}
                        type="range"
                        min={0}
                        max={100}
                        value={taskprogress}
                        style={{ accentColor: parseInt(taskprogress, 10) === 100 ? 'green' : 'auto' }}
                        onChange={(e) => { this.setTaskProgressDelayed({ taskprogress: e.target.value }) }}
                    />
                    <small>{taskprogress || '0'}%</small>
                </div>
            </>
        )

        const dropzoneContent = (
            <h4 className="mt-25" key="dz-ico">
                {this.props.t('page.task.content.header.files')}
                <Icon name="upload-cloud" style={{ color: "#007bff", marginLeft: 15 }} />
            </h4>
        )

        const dropzoneView = filesrootuuid && (
            <>
                <DropzoneWrapper
                    disabled={isLoading}
                    inputContent={dropzoneContent}
                    api={this.props.api}
                    bearerToken={this.props.api.getToken()}
                    params={{ file: { ident: ident }, anchors: anchors, parent: { uuid: currentfilesrootuuid } }}
                    accept={acceptFileType.join(', ')}
                    className="dropzone single-task-dropzone"
                    progressEventName="API_PROGRESS"
                    onUploaded={(file) => { this.fetchFiles(null) }}
                />
            </>
        )

        const filesListView = (
            <>
                <ListGroup className="mb-10">
                    {
                        previousfilesrootuuids.length > 1 && (
                            <ListGroup.Item>
                                <Row className="justify-content-center align-items-center">
                                    <Col sm={12}>
                                        <button style={{ width: '100%', textAlign: 'left' }} className="btn-fake-link" onClick={() => { this.fetchFiles(previousfilesrootuuids[previousfilesrootuuids.length - 1]); }}>
                                            <Icon name="arrowLeft" />
                                        </button>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        )
                    }
                    {
                        files.map(fileObj => (
                            <ListGroup.Item key={fileObj.file.uuid}>
                                <Row className="justify-content-center align-items-center">
                                    <Col sm={12}>
                                        {fileObj.file.extension === 'dir' ? (
                                            <button className="btn-fake-link" onClick={() => { this.fetchFiles(fileObj.file?.uuid); }}>
                                                <Icon name="folder" />
                                                <span className="pl-5">
                                                    {fileObj.file.filename}
                                                </span>
                                            </button>
                                        ) : (
                                            <button className="btn-fake-link" onClick={() => { this.props.api.triggerDownload(fileObj.file?.link, fileObj.file?.filename); }}>
                                                <Icon name="file" />
                                                <span className="pl-5">
                                                    {fileObj.file.filename}
                                                </span>
                                            </button>
                                        )}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>
            </>
        )

        const checklistView = (
            <>
                {
                    taskchecklist.filter(itm => itm.parentuuid === null && !itm.trash).map(mainSection => {
                        const subSections = taskchecklist.filter(itm => itm.parentuuid === mainSection.uuid && !itm.trash);
                        return (
                            <div key={mainSection.uuid}>
                                <h4 className="mt-25">{mainSection.title}</h4>
                                <ListGroup className="mb-10">
                                    {
                                        subSections.map(subSection => (
                                            <ListGroup.Item key={subSection.uuid}>
                                                <Row className="justify-content-center align-items-center">
                                                    <Col sm={12} style={{ textDecoration: subSection.done ? 'line-through' : 'none' }}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            inline
                                                            label={subSection.title}
                                                            checked={subSection.done || false}
                                                            onChange={(e) => this.checkChecklistItem(subSection.uuid, !subSection.done)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        ))
                                    }
                                </ListGroup>
                            </div>
                        )
                    })
                }
            </>
        )

        const discussionView = (
            <>
                <h4 className="mt-25">{this.props.t('page.task.content.header.discussion')}</h4>

                <Discussion
                    t={this.props.t}
                    api={this.props.api}
                    utils={this.props.utils}
                    getUser={this.props.getUser}
                    location={this.props.location}
                    showToast={this.props.showToast}
                    hasPermission={this.props.hasPermission}
                    anchors={{ task: { uuid: taskuuid }, project: { uuid: projectuuid } }}
                    mainpage='task'
                    buildLink={this.props.buildLink}
                    divStyle={{}}
                    stopAutoScroll={true}
                />
            </>
        )

        const taskView = (
            <>
                <Fragment>
                    {maybeConfirmView}

                    {maybeDoneView}

                    <Row style={{ marginTop: -25 }}>
                        <Col md={6} lg={4}>
                            <Row>
                                <Col xs={12}>
                                    {projectDetailsView}

                                    {taskDetailsView}

                                    {taskProgressView}

                                    {checklistView}
                                </Col>
                            </Row>
                        </Col>

                        <Col md={6} lg={8}>
                            <Row>
                                <Col lg={6}>
                                    {discussionView}
                                </Col>
                                <Col lg={6}>
                                    {dropzoneView}

                                    {filesListView}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Fragment>
            </>
        )

        return isLoading ? loadingView : taskView;
    }
}

export default Content
