import './style.scss';

import React, { Component } from 'react';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import Content from './content';
import { Button } from 'react-bootstrap';
import Icon from '../../components/icon';

class Tickets extends Component {
    render() {
        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={
                        {
                            '#': this.props.t('page.tickets.shortTitle'),
                        }
                    }
                    meta={{ title: this.props.t(`page.tickets.longTitle`) }}
                    t={this.props.t}
                >
                    <h1>
                        {this.props.t('page.tickets.longTitle')}
                        <Button variant='link' onClick={() => { this.props.api.dispatchEvent('FETCH_TICKETS_CLICK', {}) }}>
                            <Icon name="refresh-cw" />
                        </Button>
                        <Button variant='link' onClick={() => { this.props.api.dispatchEvent('CREATE_TICKET_CLICK', {}) }}>
                            <Icon name="plus-square" />
                        </Button>
                    </h1>
                </PageTitle>
                <Content {...this.props} />
            </PageWrap>
        )
    }
}

export default Tickets;
