import './style.scss'
import React from 'react';
import { Row, Col, Spinner, Form, ListGroup, Button, Modal } from 'react-bootstrap';
import BaseComponent from '../../components/component/base';
import IconEmpty from '../../assets/images/icon-empty.svg';
import IconPdf from '../../assets/images/icon-pdf.svg';
import IconDoc from '../../assets/images/icon-doc.svg';
import IconRar from '../../assets/images/icon-rar.svg';
import IconXls from '../../assets/images/icon-xls.svg';
import IconZip from '../../assets/images/icon-zip.svg';
import IconJpg from '../../assets/images/icon-jpg.svg';
import IconDir from '../../assets/images/icon-dir.svg';
import IconPng from '../../assets/images/icon-png.svg';
import Icon from '../../components/icon';
import DropzoneWrapper from '../dropzone';
import { acceptFileType, chmodEnum } from '../../constants';
import ModalFillForm from '../../pages/Offer/modal-fill-form';

class Files extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSaving: false,
            files: [],
            images: [],
            activeFileObj: null,
            selectedFilesObj: {},
            totalSize: 0,
            user: props.getUser(),
            anchors: props.anchors,
            anchorsFetch: props.anchorsFetch || props.anchors,
            ident: props.ident,
            showParentDirModal: false,
            showFilenameModal: false,
            showFillFormModal: false,
            showForClientModal: false,
            newFilename: '',
            activeDirObj: props.activeDirObj || null,
            useFakeRoot: props.activeDirObj ? true : false,
            rootName: props.rootName || 'root',
        }
        this.openFileEvent = this.openFileEvent.bind(this);
        this.onInit = this.onInit.bind(this);
    }

    componentDidMount() {
        window.addEventListener("OPEN_FILE", this.openFileEvent, false);
        window.addEventListener("REFETCH_FILES", this.onInit, false);
        this.onInit();
    }

    componentWillUnmount() {
        window.removeEventListener('OPEN_FILE', this.openFileEvent, false);
        window.removeEventListener("REFETCH_FILES", this.onInit, false);
    }

    onInit() {
        this.fetchFiles(() => {
            let uuidfromhash = this.props.utils.getLastHashElement();
            this.openFileByUuid(uuidfromhash);
        });
    }

    openFileByUuid(lookup) {
        let activeFileObj = null;
        this.state.files.forEach(itm => {
            if (itm.file.uuid === lookup || itm.file.filename === lookup) {
                activeFileObj = { ...itm }
            }
        });
        if (activeFileObj) {
            let activeFileObjParent = null;
            this.state.files.forEach(itm => {
                if (itm.file.uuid === activeFileObj.parent?.uuid) {
                    activeFileObjParent = { ...itm }
                }
            });
            if (activeFileObj.file?.extension === 'dir') {
                this.onFileDoubleClick(null, activeFileObj);
            } else {
                if (activeFileObjParent) {
                    this.onFileDoubleClick(null, activeFileObjParent, () => {
                        setTimeout(() => {
                            this.onFileClick(null, activeFileObj);
                        }, 800);
                    });
                } else {
                    this.onFileClick(null, activeFileObj);
                }
            }
        }
    }

    openFileEvent(e) {
        this.openFileByUuid(e.detail.search);
    }

    fetchFiles(callback) {
        this.setState({ isLoading: true }, () => {
            this.props.api.post(
                `/file/list`,
                {
                    "anchors": this.state.anchorsFetch
                },
                (data) => {
                    const images = [...data.files]
                        .filter(itm => ['jpg', 'jpeg', 'png'].includes(itm.file.extension))
                        .map((itm) => { return { label: itm.file.filename, value: itm.file.link } });

                    let newActiveFileObj = null;
                    if (this.state.activeFileObj) {
                        [...data.files].forEach(newFileObj => {
                            if (newFileObj.file?.uuid === this.state.activeFileObj.file?.uuid) {
                                newActiveFileObj = { ...newFileObj };
                            }
                            return newFileObj;
                        });
                    }

                    this.setState({
                        isLoading: false,
                        isSaving: false,
                        files: data.files,
                        images: images,
                        activeFileObj: newActiveFileObj,
                    }, callback)
                },
                (errorObject) => {
                    this.props.showToast({
                        errorObject: errorObject,
                        title: this.props.t('common.toast.error'),
                        color: 'danger'
                    });
                }
            );
        })
    }

    trashFile(fileObj) {
        this.confirmRemove(() => {
            this.setState({ isSaving: true }, () => {
                this.props.api.post(
                    `/file/trash`,
                    {
                        "file": {
                            "uuids": [fileObj?.file?.uuid],
                        },
                        "anchors": this.state.anchors
                    },
                    (data) => {
                        this.fetchFiles();
                    },
                    (errorObject) => {
                        this.props.showToast({
                            errorObject: errorObject,
                            title: this.props.t('common.toast.error'),
                            color: 'danger'
                        });
                    }
                );
            })
        });
    }

    createDir(dirObj) {
        this.setState({ isSaving: true }, () => {
            this.props.api.post(
                `/file/create-dir`,
                {
                    "parent": {
                        "uuid": dirObj?.file?.uuid,
                    },
                    "anchors": this.state.anchors
                },
                (data) => {
                    this.fetchFiles();
                },
                (errorObject) => {
                    this.props.showToast({
                        errorObject: errorObject,
                        title: this.props.t('common.toast.error'),
                        color: 'danger'
                    });
                }
            );
        })
    }

    setFileParent(fileObj, parentObj) {
        const { files } = this.state;
        const newFiles = files.map(tmpFileObj => {
            let newFileObj = { ...tmpFileObj };
            if (newFileObj.file?.uuid === fileObj.file?.uuid) {
                newFileObj.parent = parentObj.uuid ? { ...parentObj } : null;
            }
            return newFileObj;
        });
        this.setState({ files: newFiles, showParentDirModal: false }, () => this.updateFilePartial(fileObj.file?.uuid, fileObj.file?.filename, parentObj?.uuid));
    }

    setFileName(fileObj, newFilename) {
        const { files } = this.state;
        const newFiles = files.map(tmpFileObj => {
            let newFileObj = { ...tmpFileObj };
            if (newFileObj.file?.uuid === fileObj.file?.uuid) {
                newFileObj.file.filename = newFilename;
            }
            return newFileObj;
        });
        this.setState({ files: newFiles, showFilenameModal: false }, () => this.updateFilePartial(fileObj.file?.uuid, newFilename, fileObj.parent?.uuid));
    }


    setFileForClient(fileObj, forClient) {
        this.updateFileChmod(fileObj.file?.uuid, forClient)
    }

    updateFileChmod(fileUuid, chmodIsForClient) {
        this.setState({ isSaving: true }, () => {
            this.props.api.post(
                `/file/update-chmod`,
                {
                    "file": {
                        "uuid": fileUuid
                    },
                    "chmod": {
                        "forClient": chmodIsForClient,
                    }
                },
                (data) => {
                    this.fetchFiles();
                },
                (errorObject) => {
                    this.props.showToast({
                        errorObject: errorObject,
                        title: this.props.t('common.toast.error'),
                        color: 'danger'
                    });
                }
            );
        })
    }

    updateFilePartial(fileUuid, fileFilename, parentUuid) {
        this.setState({ isSaving: true }, () => {
            this.props.api.post(
                `/file/update-partial`,
                {
                    "file": {
                        "uuid": fileUuid,
                        "filename": fileFilename,
                    },
                    "parent": {
                        "uuid": parentUuid,
                    },
                    "anchors": this.state.anchors
                },
                (data) => {
                    this.fetchFiles();
                },
                (errorObject) => {
                    this.props.showToast({
                        errorObject: errorObject,
                        title: this.props.t('common.toast.error'),
                        color: 'danger'
                    });
                }
            );
        })
    }

    getIcon(extension) {
        switch (String(extension).toLowerCase()) {
            case 'pdf':
                return IconPdf
            case 'doc':
            case 'docx':
                return IconDoc;
            case 'rar':
                return IconRar;
            case 'xls':
            case 'xlsx':
                return IconXls;
            case 'zip':
                return IconZip;
            case 'jpg':
            case 'jpeg':
                return IconJpg;
            case 'dir':
                return IconDir;
            case 'png':
                return IconPng;
            default:
                return IconEmpty
        }
    }

    countSubFiles(fileuuid) {
        return this.state.files.filter(fileObj => fileObj.parent && fileObj.parent.uuid === fileuuid).length;
    }

    getFile(fileObj) {
        const { isLoading, isSaving, activeFileObj, selectedFilesObj } = this.state;
        const isActive = fileObj.file.uuid === activeFileObj?.file?.uuid || selectedFilesObj[fileObj.file.uuid];
        return (
            <ListGroup.Item active={isActive} disabled={isLoading || isSaving} action key={`file-${fileObj.file.uuid}`} href={`#${fileObj.file.uuid}`} onClick={(e) => this.onFileClick(e, fileObj)} onDoubleClick={(e) => this.onFileDoubleClick(e, fileObj)} style={{ userSelect: 'none' }}>
                <span className="media media-filled">
                    <span className="media-img">
                        <img src={this.getIcon(fileObj.file.extension)} className="icon-file" alt="" />
                    </span>
                    <span className="media-body">
                        <span className="media-title">
                            {
                                this.isReadOnly(fileObj.file.chmod) && (
                                    <>&#128274;&nbsp;</>
                                )
                            }
                            {
                                this.isForClient(fileObj.file.chmod) && (
                                    <>🌞&nbsp;</>
                                )
                            }
                            {fileObj.file.filename}
                        </span>
                        <small className="media-subtitle">
                            {fileObj.file.extension === 'dir' ? this.countSubFiles(fileObj.file.uuid) : this.props.utils.fileSizeToHumanReadable(fileObj.file.size)} / {fileObj.file.created}
                        </small>
                    </span>
                </span>
            </ListGroup.Item>
        )
    }

    traverseFiles(fileObj) {
        let nestedFiles = [];

        this.state.files.forEach((f) => {
            if (f.parent?.uuid === fileObj.file.uuid) {
                const newFileObj = {
                    file: { ...f.file },
                    parent: f.parent,
                    user: f.user,
                };
                if (f.file.extension === "dir") {
                    newFileObj.files = this.traverseFiles(f);
                }
                nestedFiles.push(newFileObj);
            }
        });

        return nestedFiles;
    };

    calculateTotalSize(filesObj) {
        let totalSize = 0;
        Object.values(filesObj).forEach((f) => {
            if (f.file?.extension !== "dir") {
                totalSize += f.file.size || 0;
            }
        });
        return totalSize;
    };

    onFileClick(e, fileObj) {
        if (e) {
            e.preventDefault();
        }

        let particles = window.location.hash.replace("#", "").split("/");
        for (let i = 0; i < particles.length; i++) {
            if (particles[i] === "files") {
                particles[i + 1] = fileObj?.file?.uuid;
            }
        }

        if (e?.ctrlKey) {
            let newSelectedFilesObj = { ...this.state.selectedFilesObj };
            if (newSelectedFilesObj[fileObj?.file?.uuid]) {
                delete newSelectedFilesObj[fileObj?.file?.uuid];
            } else {
                newSelectedFilesObj[fileObj?.file?.uuid] = fileObj;
                if (newSelectedFilesObj[fileObj?.file?.uuid].file?.extension === "dir") {
                    newSelectedFilesObj[fileObj?.file?.uuid]["files"] = this.traverseFiles(fileObj); // call traverseFiles here
                }
            }
            const totalSize = this.calculateTotalSize(newSelectedFilesObj);
            this.setState({ activeFileObj: null, selectedFilesObj: newSelectedFilesObj, totalSize: totalSize, });
        } else {
            this.setState({ activeFileObj: fileObj, selectedFilesObj: {} }, () => {
                this.props.openLink("#" + particles.join("/"), "_hash");
            });
        }
    }

    onFileDoubleClick(e, fileObj, callback) {
        if (e) {
            e.preventDefault();
        }
        const file = fileObj.file;
        if (file.extension === 'dir') {
            this.setState({ activeDirObj: fileObj, activeFileObj: null, selectedFilesObj: {} }, callback);
        } else {
            this.props.api.triggerDownload(fileObj.file?.link, fileObj.file?.filename);
        }
    }

    getDirs(files, folders, parentFolderUuid, parentFolderFilename, parents) {
        files
            .filter(tempFile => !tempFile.file.trash)
            .filter(tempFile => (tempFile.parent?.uuid || null) === parentFolderUuid)
            .forEach(tempFile => {
                if (tempFile.file.extension === 'dir') {
                    let newFilename = parentFolderFilename + ' / ' + tempFile.file.filename;
                    let newParents = [...parents, tempFile.file.uuid];
                    folders.push({ uuid: tempFile.file.uuid, path: newFilename, filename: tempFile.file.filename, parents: newParents });
                    folders.concat(this.getDirs(files, folders, tempFile.file.uuid, newFilename, newParents));
                };
            });
        return folders;
    }

    getBreadcrumbs() {
        const { files, activeDirObj, useFakeRoot, isLoading, isSaving } = this.state;
        const breadcrumbs = [];
        let bcIndex = 0;
        breadcrumbs.push(<span key="ops">
            <button style={{ userSelect: 'none' }} disabled={isLoading || isSaving || useFakeRoot} key={`bc-${bcIndex++}`} className='btn btn-link btn-no-upper px-3 py-1 m-3 border border-info rounded' onClick={() => this.createDir(activeDirObj, 'dir')}>+ dir</button>
            {
                this.props.isRegion(['SWEDEN']) && (
                    <button style={{ userSelect: 'none' }} disabled={isLoading || isSaving || useFakeRoot} key={`bc-${bcIndex++}`} className='btn btn-link btn-no-upper px-3 py-1 m-3 border border-info rounded' onClick={() => this.setState({ showFillFormModal: true })}>+ fill form</button>
                )
            }
        </span>);
        if (activeDirObj) {
            breadcrumbs.push(<button style={{ userSelect: 'none' }} disabled={isLoading || isSaving} key={`bc-${bcIndex++}`} className='btn btn-link btn-no-upper px-5' onClick={() => this.setState({ activeDirObj: activeDirObj, activeFileObj: null })} >{activeDirObj.file.filename}</button>);
            let searchObj = { ...activeDirObj };
            let iteration = 0;
            while (searchObj && searchObj.parent) {
                searchObj = files.find(itm => itm.file.uuid === searchObj.parent.uuid);
                if (searchObj) {
                    const searchObjCopy = { ...searchObj };
                    breadcrumbs.push(<button style={{ userSelect: 'none' }} disabled={isLoading || isSaving} key={`bc-${bcIndex++}`} className='btn btn-link btn-no-upper px-5' onClick={() => this.setState({ activeDirObj: searchObjCopy, activeFileObj: null })}>{searchObjCopy.file.filename}</button>);
                }
                if (iteration++ > 100) {
                    console.error('max iterations limit reached');
                    break;
                }
            }
        }
        breadcrumbs.push(<button style={{ userSelect: 'none' }} disabled={isLoading || isSaving || useFakeRoot} key={`bc-${bcIndex++}`} className='btn btn-link btn-no-upper px-5' onClick={() => this.setState({ activeDirObj: null, activeFileObj: null })}>{this.state.rootName}</button>);
        return breadcrumbs.reverse().reduce((prev, curr) => [prev, <span key={`bc-${bcIndex++}`} style={{ userSelect: 'none' }}> / </span>, curr])
    }

    fillForm(articleUuid, fileIdent, formTokens) {
        this.setState({ isLoading: true, activeFileObj: null }, () => {
            this.props.api.post(
                `/file/fill-form`,
                {
                    "article": {
                        "uuid": articleUuid
                    },
                    "file": {
                        "ident": fileIdent
                    },
                    "parent": {
                        "uuid": this.state.activeDirObj?.file?.uuid,
                    },
                    "anchors": this.state.anchors,
                    "tokens": formTokens
                },
                (successFileCreateData) => {
                    this.setState({ isLoading: false, showFillFormModal: false, activeFileObj: null }, () => {
                        this.fetchFiles(() => {
                            this.onFileClick(null, { file: successFileCreateData.file })
                        });
                    });
                    this.props.showToast({
                        title: this.props.t('common.toast.fileCreated'),
                        color: "info"
                    });
                },
                (errorFileCreateData) => {
                    this.setState({ isLoading: false, activeFileObj: null, }, () => {
                        this.fetchFiles();
                    });
                    this.props.showToast({
                        errorObject: errorFileCreateData,
                        title: this.props.t('common.toast.error'),
                        color: "danger"
                    });
                }
            );
        })
    }

    zipAndDownloadActiveObjs() {
        const {
            activeFileObj,
            selectedFilesObj
        } = this.state;
        if (activeFileObj) {
            const key = activeFileObj.file.uuid;
            return this.getZip({ [key]: activeFileObj });
        }

        if (Object.keys(selectedFilesObj).length > 0) {
            return this.getZip(selectedFilesObj);
        }
    }

    getZip(selectedFilesObj) {
        this.setState({ isLoading: true, activeFileObj: null }, () => {
            this.props.api.post(
                `/file/prepare-zip`,
                {
                    "files": selectedFilesObj,
                    "parent": {
                        "uuid": this.state.activeDirObj?.file?.uuid,
                    },
                    "anchors": this.state.anchors
                },
                (successFileCreateData) => {
                    this.setState({ isLoading: false, activeFileObj: null }, () => {
                        this.fetchFiles(() => {
                            this.onFileDoubleClick(null, { file: successFileCreateData.file })
                        });
                    });
                    this.props.showToast({
                        title: this.props.t('common.toast.fileCreated'),
                        color: "info"
                    });
                },
                (errorFileCreateData) => {
                    this.setState({ isLoading: false, activeFileObj: null, }, () => {
                        this.fetchFiles();
                    });
                    this.props.showToast({
                        errorObject: errorFileCreateData,
                        title: this.props.t('common.toast.error'),
                        color: "danger"
                    });
                }
            );
        })
    }

    isReadOnly(fileChmod) {
        if (!fileChmod) {
            return false;
        }
        // Normalize the chmod string to ensure it's four characters long,
        // padding with zeros if necessary, then compare the last three characters
        fileChmod = (fileChmod || '').padStart(4, '0');
        return fileChmod.substring(1) === chmodEnum.READONLY.substring(1);
    }

    isForClient(fileChmod) {
        if (!fileChmod) {
            return false;
        }
        // Normalize the chmod string to ensure it's four characters long,
        // padding with zeros if necessary, then compare the first character
        fileChmod = (fileChmod || '').padStart(4, '0');
        return fileChmod.substring(0, 1) === chmodEnum.FOR_CLIENT.substring(0, 1);
    }

    render() {
        const {
            files,
            isLoading,
            isSaving,
            activeFileObj,
            selectedFilesObj,
            showParentDirModal,
            showFilenameModal,
            showFillFormModal,
            newFilename,
            activeDirObj,
            ident,
            anchors,
            totalSize
        } = this.state;

        const filesClean = files
            .filter(fileObj => !fileObj.file?.trash)
            .filter(fileObj => activeDirObj?.file?.uuid === fileObj.parent?.uuid)
            .sort((fileObjA, fileObjB) => {
                const fa = fileObjA.file?.extension === 'dir' ? 1 : 0;
                const fb = fileObjB.file?.extension === 'dir' ? 1 : 0;
                return fb - fa;
            })
            .sort((fileObjA, fileObjB) => {
                const fa = this.isReadOnly(fileObjA.file?.chmod);
                const fb = this.isReadOnly(fileObjB.file?.chmod);
                return fb - fa;
            });
        const dirsClean = files.filter(fileObj => !fileObj.file?.trash && fileObj.file.extension === 'dir').sort((fileObjA, fileObjB) => fileObjA.file.extension === 'dir');
        const dirs = this.getDirs(dirsClean, [{ uuid: null, path: this.state.rootName, "name": this.state.rootName, parents: [] }], null, this.state.rootName, []).filter(Boolean);

        const loadingItem = isLoading && (
            <>
                <ListGroup.Item style={{ padding: 0, borderBottom: 0 }}>
                    <div className="text-center py-40" style={{ position: 'absolute', zIndex: 999, background: 'rgba(255,255,255,0.6)', width: '100%', height: '100vh' }}>
                        <Spinner animation="border" />
                    </div>
                </ListGroup.Item>
            </>
        )

        const parentDirModal = (
            <Modal
                show={showParentDirModal}
                onHide={() => { this.setState({ showParentDirModal: false }) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.t('component.files.modal.parentDir.title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {loadingItem}
                        {dirs.filter(parentDir => !parentDir.parents.includes(activeFileObj?.file?.uuid)).map(parentDir =>
                            <ListGroup.Item disabled={isLoading || isSaving} action key={`dir-${parentDir.uuid}`} onClick={() => this.setFileParent(activeFileObj, parentDir)}>
                                <span className="media media-filled">
                                    <span className="media-body">
                                        <span className="media-title">
                                            {parentDir.path}
                                        </span>
                                    </span>
                                </span>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </Modal.Body>
            </Modal>
        )

        const filenameModal = (
            <Modal
                show={showFilenameModal}
                onHide={() => { this.setState({ showFilenameModal: false }) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.t('component.files.modal.filename.title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{this.props.t('component.files.modal.filename.label')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={newFilename || ''}
                            disabled={isLoading || isSaving}
                            onChange={(e) => { this.onChangeSetState('newFilename', e) }}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="p-20 border-top">
                    <Button variant="success" type="button" onClick={(e) => { this.setFileName(activeFileObj, newFilename) }}>
                        {this.props.t('common.button.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )

        const fillFormModal = (
            <ModalFillForm
                t={this.props.t}
                api={this.props.api}
                isLoading={isLoading || closed}
                isRegion={this.props.isRegion}
                show={showFillFormModal}
                showToast={this.props.showToast}
                fetchFormArticles={this.props.fetchFormArticles}
                onHide={() => { this.setState({ showFillFormModal: false }) }}
                onSubmit={(articleUuid, fileIdent, tokens) => { this.fillForm(articleUuid, fileIdent, tokens) }}
                upperState={{ ...this.props.fillFormWithTokens } || {}}
                images={this.state.images}
            />
        )

        const dropzoneContent = (
            <div className="icon" key="dz-ico">
                <Icon name="upload-cloud" style={{ width: 64, height: 64, color: "#e6ecf0" }} />
            </div>
        )

        const fileUpload = this.props.hasPermission(['VIEW_PROJECT_FILES_EDIT']).any && (
            <ListGroup.Item disabled={isLoading || isSaving} style={{ padding: 0 }}>
                <DropzoneWrapper
                    disabled={isLoading || isSaving}
                    inputContent={dropzoneContent}
                    api={this.props.api}
                    bearerToken={this.props.api.getToken()}
                    params={{ file: { ident: ident }, anchors: anchors, parent: { uuid: activeDirObj?.file?.uuid } }}
                    accept={acceptFileType.join(', ')}
                    className="dropzone component-files-dropzone"
                    progressEventName="API_PROGRESS"
                    onUploaded={(file) => { this.fetchFiles() }}
                />
            </ListGroup.Item>
        )

        const filesView = (
            <>
                <Row style={{ marginTop: -15 }}>
                    <Col sm={12}>
                        <button style={{ userSelect: 'none' }} disabled={isLoading || isSaving} className='btn btn-link btn-no-upper px-3 py-2 m-3 border border-info rounded' onClick={() => this.onInit()}>
                            <Icon name="refresh-cw" style={{ width: 14, height: 14 }} />
                        </button>
                        <span> / </span>
                        {this.getBreadcrumbs()}
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <ListGroup defaultActiveKey={`#${activeFileObj?.file?.uuid}`}>
                            {loadingItem}
                            {filesClean.map(file => this.getFile(file))}
                            {fileUpload}
                        </ListGroup>

                    </Col>
                    <Col sm={4}>
                        {
                            activeFileObj ? (
                                <ListGroup variant="flush" className="sticky-top">
                                    <ListGroup.Item disabled={isLoading || isSaving} className="px-0">
                                        <button disabled={isLoading || isSaving || !this.props.hasPermission(['VIEW_PROJECT_FILES_EDIT']).any || this.isReadOnly(activeFileObj?.file?.chmod)} className="force-select btn-inline text-left" onClick={() => this.setState({ showFilenameModal: true, newFilename: activeFileObj.file?.filename })}>
                                            {activeFileObj.file.filename || `...`}
                                        </button>
                                    </ListGroup.Item>
                                    <ListGroup.Item disabled={isLoading || isSaving} className="px-0">
                                        <span>
                                            {this.isForClient(activeFileObj?.file?.chmod) ? '🌞' : '🌥️'}
                                        </span>
                                        {` `}
                                        <button disabled={isLoading || isSaving || !this.props.hasPermission(['VIEW_PROJECT_FILES_EDIT']).any} className="force-select btn-inline text-left" onClick={() => this.setFileForClient(activeFileObj, !this.isForClient(activeFileObj?.file?.chmod))}>
                                            {this.isForClient(activeFileObj?.file?.chmod) ? this.props.t(`component.files.forClient`) : this.props.t(`component.files.notForClient`)}
                                        </button>
                                    </ListGroup.Item>
                                    <ListGroup.Item disabled={isLoading || isSaving} className="px-0">
                                        <span>
                                            {this.props.t(`component.files.path`)}
                                        </span>
                                        {` `}
                                        <button disabled={isLoading || isSaving || !this.props.hasPermission(['VIEW_PROJECT_FILES_EDIT']).any || this.isReadOnly(activeFileObj?.file?.chmod)} className="force-select btn-inline text-left" onClick={() => this.setState({ showParentDirModal: true })}>
                                            {activeFileObj.parent?.filename || this.state.rootName}
                                        </button>
                                    </ListGroup.Item>
                                    {
                                        activeFileObj.user && (
                                            <>
                                                <ListGroup.Item disabled={isLoading || isSaving} className="px-0">
                                                    {this.props.t(`component.files.creator`)}
                                                    {` `}
                                                    {`${activeFileObj.user?.firstName} ${activeFileObj.user?.lastName}`}
                                                </ListGroup.Item>
                                            </>
                                        )
                                    }
                                    <ListGroup.Item disabled={isLoading || isSaving} className="px-0">
                                        {this.props.t(`component.files.created`)}
                                        {` `}
                                        {activeFileObj.file.created}
                                    </ListGroup.Item>
                                    {
                                        ['jpg', 'jpeg', 'png'].includes(activeFileObj.file.extension) && (
                                            <ListGroup.Item disabled={isLoading || isSaving} className="py-5 px-0">
                                                <img src={activeFileObj.file.link} alt={activeFileObj.file.filename || `...`} className='img-fluid' />
                                            </ListGroup.Item>
                                        )
                                    }
                                    {
                                        activeFileObj.file.extension === 'dir' ? (
                                            <></>
                                        ) : (
                                            <ListGroup.Item disabled={isLoading || isSaving} className="px-0 text-right">
                                                <button className="btn-inline text-success" onClick={() => this.onFileDoubleClick(null, activeFileObj)}>
                                                    {this.props.t(`common.button.download`)}
                                                </button>
                                            </ListGroup.Item>
                                        )
                                    }
                                    {
                                        (this.props.hasPermission(['VIEW_PROJECT_FILES_EDIT']).any && !this.isReadOnly(activeFileObj?.file?.chmod)) && (
                                            <ListGroup.Item disabled={isLoading || isSaving} className="px-0 text-right">
                                                <button className="btn-inline text-danger" onClick={() => this.trashFile(activeFileObj)}>
                                                    {this.props.t(`common.button.remove`)}
                                                </button>
                                            </ListGroup.Item>
                                        )
                                    }
                                </ListGroup>
                            ) : Object.keys(selectedFilesObj).length > 0 ? (
                                <ListGroup variant="flush" className="sticky-top">
                                    <ListGroup.Item>
                                        {this.props.t(`component.files.selected`)}
                                        {` `}
                                        {totalSize > 0 && this.props.utils.fileSizeToHumanReadable(totalSize)}{` `}({Object.keys(selectedFilesObj).length})
                                    </ListGroup.Item>
                                    <ListGroup.Item disabled={isLoading || isSaving} className="px-0 text-right">
                                        <button className="btn-inline text-success" onClick={() => this.zipAndDownloadActiveObjs()}>
                                            {this.props.t(`common.button.download`)}
                                        </button>
                                    </ListGroup.Item>
                                </ListGroup>
                            ) : (
                                <></>
                            )
                        }
                    </Col>
                </Row>
            </>
        )

        return (
            <div className='component-files'>
                {parentDirModal}
                {filenameModal}
                {fillFormModal}
                {filesView}
            </div>
        );
    }

}

export default Files;