import React, { Fragment } from 'react';
import BaseComponent from '../../components/component/base';
import { Row, Col, Modal, Form, Button, Alert } from "react-bootstrap";

class ModalImportDynamicsCrm extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            found: false,
            rawCrmId: "",
        }
    }

    check(e) {
        if (e) {
            e.preventDefault();
        }
        const urlParams = new URLSearchParams(this.state.rawCrmId);
        const msId = urlParams.get('id') || this.state.rawCrmId;
        if (!msId) {
            this.props.showToast({
                title: this.props.t('common.toast.checkInputData'),
                color: "danger"
            });
            return;
        }
        this.setState({ isLoading: true, found: null }, () => {
            this.props.api.post(
                `/import/offer-from-dynamics-crm/check`,
                {
                    dynamicsCrm: {
                        opportunityId: msId
                    }
                },
                (data) => {
                    this.setState({ isLoading: false, found: data });
                },
                (errorObject) => {
                    this.setState({ isLoading: false, found: null });
                    this.props.showToast({
                        errorObject: errorObject,
                        title: this.props.t('common.toast.dealNotFound'),
                        color: "danger"
                    });
                }
            );
        });

    }

    import() {
        const {
            found
        } = this.state;

        if (!found) {
            return;
        }

        const firstLastName = found.opportunity.opportunity.contact?.name?.split(' ');
        const firstName = firstLastName.shift();
        const lastName = firstLastName.join(' ');

        this.props.setUpperState(
            {
                modalImportFromDynamicsCrm: false,
                msId: found.opportunity.opportunity.id,

                firstName: firstName,
                lastName: lastName,
                emailAddress: found.opportunity.opportunity.contact?.email,
                mobilePhone: found.opportunity.opportunity.contact?.phoneNumber,
                postalCode: found.opportunity.opportunity.contact?.postalCode,
                cityName: found.opportunity.opportunity.contact?.city,
                streetAddress: found.opportunity.opportunity.contact?.addressLine1,
                streetNumber: found.opportunity.opportunity.contact?.addressLine2,
                apartmentNumber: found.opportunity.opportunity.contact?.addressLine3,
                provinceName: (found.opportunity.opportunity.contact?.region || '').toLowerCase(),

                marginTypesDisabled: [
                    100000082, // Wattfox
                    100000027, // Hittasolceller
                    100000025, // HelpHero
                    100000026, // HemSol
                ].includes(found.opportunity.opportunity.source) ? ['min'] : []
            },
            () => {
                this.props.refreshAddress()
            }
        );
    }

    render() {

        const {
            isLoading,
            found,
            rawCrmId
        } = this.state;

        return (
            <Fragment>

                <Modal
                    show={this.props.show}
                    onHide={() => { this.props.onHide() }}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Form method="post" onSubmit={(e) => { this.check(e) }}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.props.t('component.modalImportDynamicsCrm.title')}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group className="required">
                                        <Form.Label>
                                            {this.props.t('component.modalImportDynamicsCrm.rawCrmId')}
                                        </Form.Label>
                                        <Form.Control
                                            required={true}
                                            disabled={isLoading || closed}
                                            type="text"
                                            value={rawCrmId || ""}
                                            placeholder={this.props.t('component.modalImportDynamicsCrm.rawCrmIdPlaceholder')}
                                            onChange={
                                                (evt) => {
                                                    this.setState(
                                                        { rawCrmId: this.val(evt) }
                                                    )
                                                }
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                {
                                    found && (
                                        <Col sm={12}>
                                            <Form.Group>
                                                <Form.Label>
                                                    {this.props.t('component.modalImportDynamicsCrm.dealFoundTitle')}
                                                </Form.Label>
                                                <Alert variant="info">
                                                    {found.opportunity.opportunity.subject}
                                                </Alert>
                                            </Form.Group>
                                        </Col>
                                    )
                                }
                                {
                                    found && found.offer && (
                                        <Col sm={12}>
                                            <Form.Group>
                                                <Form.Label>
                                                    {this.props.t('component.modalImportDynamicsCrm.offerFoundTitle')}
                                                </Form.Label>
                                                <Alert variant="danger">
                                                    <a href={this.props.buildLink(`/calculation/${found.offer.uuid}/`)} target="_blank" rel="nofollow noopener">{found.offer.ident}</a>
                                                </Alert>
                                            </Form.Group>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Modal.Body>
                        <Modal.Footer className="p-20 border-top">
                            <Button variant="info" disabled={isLoading || closed} onClick={() => { this.check() }}>
                                {this.props.t('component.modalImportDynamicsCrm.check')}
                            </Button>
                            {
                                found && (found.offer === null) && (
                                    <Button variant="success" disabled={isLoading || closed} onClick={() => { this.import() }} className="pull-right">
                                        {this.props.t('component.modalImportDynamicsCrm.import')}
                                    </Button>
                                )
                            }
                        </Modal.Footer>
                    </Form>
                </Modal>

            </Fragment>
        )
    }


}

export default ModalImportDynamicsCrm
