import "./style.scss";
import React, { Component } from "react";
import AsyncSelect from 'react-select/async';

class SelectAsync extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.value || "",
            options: []
        };
    }

    loadOptions(input, resolve) {
        let transformData = (data, callback) => callback(data);
        if (typeof this.props.transformData === "function") {
            transformData = this.props.transformData;
        }
        let theSearch = input || this.props.value;
        if (typeof theSearch !== "string") {
            theSearch = "";
        }
        this.props.api.post(
            this.props.endpoint,
            Object.assign({}, { search: theSearch }, this.props.params || {}),
            (data) => {
                transformData(
                    data,
                    (preparedData) => {
                        preparedData = [...this.props.appendData || [], ...preparedData, ...this.props.prependData || []];
                        this.setState(
                            {
                                options: preparedData
                            },
                            () => {
                                resolve(preparedData);
                            }
                        )
                    }
                )
            },
            () => { },
            () => { }
        );
    }

    render() {

        const {
            t,
            value,
            placeholder,
            disabled,
            isSearchable,
            isClearable,
            isMulti,
            cache,
            preload,
            onChange
        } = this.props;

        const {
            options,
            inputValue
        } = this.state;

        let resolvedValue = null;
        let stringValue = String(value || '').toLowerCase();

        if (options) {
            if (typeof value === "object") {
                resolvedValue = value;
            } else {
                resolvedValue = options.find(itm => String(itm.value || '').toLowerCase() === stringValue)
            }
        }

        return (
            <>
                <AsyncSelect
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder={placeholder || "..."}
                    noOptionsMessage={() => inputValue ? t('common.select.emptyResults') : t('common.select.emptyPhrase')}
                    loadingMessage={() => t('common.select.loading')}
                    isDisabled={disabled}
                    isSearchable={isSearchable === false ? false : true}
                    isClearable={isClearable || false}
                    isMulti={isMulti || false}
                    cacheOptions={cache || false}
                    defaultOptions={preload || false}
                    value={resolvedValue}
                    onInputChange={(val) => { this.setState({ inputValue: val }); return val; }}
                    loadOptions={(input, resolve) => this.loadOptions(input, resolve)}
                    onChange={(value) => { onChange(value); }}
                />
            </>
        );
    }
}

export default SelectAsync;