import React, { Component } from 'react';
import FaceDissapointed from '../../assets/images/face-dissapointed.js';
import FaceContent from '../../assets/images/face-content.js';
import FaceHappy from '../../assets/images/face-happy.js';
import FaceSuperHappy from '../../assets/images/face-super-happy.js';

class MarginSmile extends Component {

    render() {
        let theIcon = null;
        let theVal = parseFloat(this.props.val) || 0;
        let size = this.props.size || 16;
        if (theVal === 0) {
            theIcon = null;
        } else if (theVal < 20) {
            theIcon = <FaceDissapointed style={{ height: size, width: size }} />
        } else if (theVal < 30) {
            theIcon = <FaceContent style={{ height: size, width: size }} />
        } else {
            theIcon = <FaceSuperHappy style={{ height: size, width: size }} />
        }
        return <span style={this.props.style || {}}>{theIcon}</span>
    }
}

export default MarginSmile;
