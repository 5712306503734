import './style.scss';

import React, { Component } from 'react';
import PageWrap from '../../components/page-wrap';
import Content from './content';
import { Button } from 'react-bootstrap';
import IconFilters from '../../components/icon-filters';
import { mapperMarker } from '../../constants';

class Mapper extends Component {
    render() {
        return (
            <PageWrap>
                <div className='rui-full-page-content'>
                    <Button
                        variant='link'
                        onClick={() => { this.props.api.dispatchEvent('ADJUST_MAPPER', {}) }}
                        style={{ position: 'absolute', zIndex: 99, background: '#fff' }}
                        className='border-right border-bottom'
                    >
                        <IconFilters />
                    </Button>

                    <div className="mapper-legend">
                        <p style={{ marginTop: 0 }}>
                            <small>
                                {this.props.t('page.mapper.legend.content')}
                            </small>
                        </p>
                        <p>
                            <small>
                                <img src={mapperMarker.projectDone} alt='projectDone' style={{ maxHeight: 16 }} /> - {this.props.t('common.map.projectDone')}
                            </small>
                        </p>
                        <p>
                            <small>
                                <img src={mapperMarker.bipvDone} alt='bipvDone' style={{ maxHeight: 16 }} /> - {this.props.t('common.map.bipvDone')}
                            </small>
                        </p>
                        <p>
                            <small>
                                <img src={mapperMarker.bipvOngoing} alt='bipvOngoing' style={{ maxHeight: 16 }} /> - {this.props.t('common.map.bipvOngoing')}
                            </small>
                        </p>
                        <p>
                            <small>
                                <img src={mapperMarker.pending} alt='pending' style={{ maxHeight: 16 }} /> - {this.props.t('common.map.pending')}
                            </small>
                        </p>
                        <p>
                            <small>
                                <img src={mapperMarker.cancelled} alt='cancelled' style={{ maxHeight: 16 }} /> - {this.props.t('common.map.cancelled')}
                            </small>
                        </p>
                    </div>

                    <Content {...this.props} />
                </div>
            </PageWrap>
        );
    }
}

export default Mapper;
