import React, { Component } from 'react';
import classnames from 'classnames/dedupe';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import AppVersion from '../app-version';

class PageContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeScrollSpy: null,
        };
        this.setActiveScrollspy = this.setActiveScrollspy.bind(this);
        this.getNavigationItem = this.getNavigationItem.bind(this);
        this.onSectionObserved = this.onSectionObserved.bind(this);
        this.onHashChange = this.onHashChange.bind(this);

    }

    componentDidMount() {
        window.addEventListener("hashchange", this.onHashChange, false);
        window.addEventListener("SECTION_OBSERVED", this.onSectionObserved, false);
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", this.onHashChange, false);
        window.removeEventListener("SECTION_OBSERVED", this.onSectionObserved, false);
    }

    onLinkClick(e) {
        e.preventDefault();
        if (e.currentTarget.hash) {
            const hash = e.currentTarget.hash.split('#').pop();
            window.location.hash = hash;
        }
    }

    setActiveScrollspy(item) {
        if (item && item.id) {
            this.setState({ activeScrollSpy: `${item.id}` });
            window.location.hash = item.id;
        }
    }

    onHashChange() {
        const id = window.location.hash.split('#').pop();
        this.setActiveScrollspy({ id: id });
    }

    onSectionObserved(e) {
        this.setActiveScrollspy({ id: e.detail.id });
    }

    getNavigationItem(data) {
        const { activeScrollSpy } = this.state;

        if (data.submit) {
            return (
                <li key={`${data.type}-${data.title}`}>
                    <button
                        disabled={data.disabled || false}
                        type="submit"
                        style={{ textTransform: 'none', fontSize: 'inherit' }}
                        className="btn btn-link nav-link"
                    >
                        {data.title}
                    </button>
                </li>
            );
        }

        return (
            <li key={`${data.to}-${data.title}`}>
                <Link
                    disabled={data.disabled || false}
                    className={classnames('nav-link', activeScrollSpy && activeScrollSpy === data.to ? 'active' : '')}
                    to={`#${data.to}`}
                    onClick={this.onLinkClick}
                >
                    {data.title}
                </Link>
            </li>
        );
    }

    render() {
        const {
            sideNav,
            hideFooter
        } = this.props;

        let divProps = {
            ...this.props,
        };
        delete divProps['hideFooter'];

        if (divProps.sideNav) {
            delete divProps.sideNav;
        }

        // page content.
        let content = (
            <Container fluid>
                <div {...divProps} />
            </Container>
        );

        // add side navigation.
        if (sideNav && sideNav.length) {
            content = (
                <Row className="align-items-start mr-0">
                    <Col xl={10}>
                        {content}
                    </Col>
                    <Col xl={2} className="d-none d-xl-block rui-scrollbar rui-page-sidebar-sticky">
                        <nav className="rui-page-sidebar">
                            <ul className="nav">
                                {sideNav.map((data) => (
                                    this.getNavigationItem(data)
                                ))}
                            </ul>
                        </nav>
                    </Col>
                </Row>
            );
        }

        return (
            <>
                <div className={this.props.className || "rui-page-content"}>
                    {content}
                </div>
                {
                    !hideFooter && (
                        <footer className="rui-footer">
                            <Container style={{ maxWidth: '100%' }}>
                                <AppVersion />
                            </Container>
                        </footer>
                    )
                }
            </>
        );
    }
}

export default PageContent;
