import './style.scss';

import React, { Component } from 'react';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import Content from './content';
import { Button } from 'react-bootstrap';
import Icon from '../../components/icon';

class Task extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.t('page.task.longTitle'),
            shortTitle: this.props.t('page.task.shortTitle'),
        }
    }

    render() {
        const { title, shortTitle } = this.state;
        const tasksLink = this.props.buildLink('/tasks/');
        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={
                        {
                            [tasksLink]: this.props.t('page.tasks.longTitle'),
                            '#/': shortTitle,
                        }
                    }
                    meta={{ title: title }}
                    t={this.props.t}
                >
                    <h1>
                        {title}
                        <Button variant='link' onClick={() => { this.props.api.dispatchEvent('FETCH_TASK', {}) }}>
                            <Icon name="refresh-cw" />
                        </Button>
                    </h1>
                </PageTitle>
                <PageContent>
                    <Content {...this.props} setMeta={(data) => { this.setState(data) }} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default Task;
