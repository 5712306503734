window.decimalSeparator = (1.1).toLocaleString().substring(1, 2);

document.body.addEventListener('mousedown', function (event) {
    document.body.classList.add('using-mouse');
    if (event.button == 1 || event.buttons == 4) {
        if (event && event.target) {
            if (event.target.classList.contains('capture-middle-click')) {
                event.preventDefault();
                event.target.dispatchEvent(new Event('auxclick'));
            }
            if (event.target.parentElement) {
                if (event.target.parentElement.classList.contains('capture-middle-click')) {
                    event.preventDefault();
                    event.target.parentElement.dispatchEvent(new Event('auxclick'));
                }
            }
        }
    }
});

document.body.addEventListener('keydown', function (event) {
    if (event.key === 'Tab') {
        document.body.classList.remove('using-mouse');
    }
    if (event.ctrlKey && event.key.toLocaleLowerCase() !== 'control') {
        window.dispatchEvent(new CustomEvent('CTRL_KEY_DOWN', { 'detail': event }), true);
    }
});

document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number" ||
        document.activeElement.classList.contains("noscroll")) {
        document.activeElement.blur();
    }
});

(() => {
    window.localStorage.removeItem('bp_tokens');
})();