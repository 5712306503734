import './style.scss';
import React, { Component } from 'react';
import { CKEditor } from 'ckeditor4-react';

class TextEditor extends Component {

    constructor(props) {
        super(props);
        this.inited = false;
        this.editor = null;
        this.value = props.value;
        this.editorId = props.editorId;
        this.updateByEvent = this.updateByEvent.bind(this);
    }

    componentDidMount() {
        this.maybeSetData(this.props.value);
        window.addEventListener("UPDATE_EDITOR", this.updateByEvent, false);
    }

    componentWillUnmount() {
        window.removeEventListener("UPDATE_EDITOR", this.updateByEvent, false);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.value !== this.props.value) {
            this.maybeSetData(nextProps.value);
        }
        return true;
    }

    maybeSetData(value) {
        this.value = value;
        if (this.editor && !this.inited) {
            this.editor.setData(this.value);
            this.inited = true;
        }
    }

    updateByEvent(e) {
        if (e.detail.editorId !== this.editorId) {
            return;
        }
        if (!this.editor) {
            return;
        }
        this.editor.setData(e.detail.content);
    }

    render() {
        return (
            <CKEditor
                readOnly={this.props.disabled}
                type="classic"
                debug={false}
                editorUrl={process.env.PUBLIC_URL + `/assets/lib/ckeditor/ckeditor.js`}
                onChange={(ev) => {
                    this.props.onChange(ev.editor.getData());
                }}
                onInstanceReady={(ev) => {
                    this.editor = ev.editor;
                    this.maybeSetData(this.props.value);
                }}
                onPluginsLoaded={(ev) => {
                    ev.editor.dataProcessor.dataFilter.addRules({
                        elements: {
                            script: function (element) {
                                return false;
                            }
                        }
                    });
                }}
                onBeforeLoad={(CKEDITOR, getEditorInstance) => {
                    CKEDITOR.on('dialogDefinition', ev => {
                        const dialogName = ev.data.name;
                        const dialogDefinition = ev.data.definition;
                        if (dialogName === 'link') {
                            // Remove the 'Target' and 'Advanced' tabs from the 'Link' dialog.
                            dialogDefinition.removeContents('target');
                            dialogDefinition.removeContents('advanced');
                            // Get a reference to the 'Link Info' tab.
                            var infoTab = dialogDefinition.getContents('info');
                            if (infoTab) {
                                infoTab.remove('browse');
                                infoTab.remove('protocol');
                                infoTab.get('linkType').style = 'display: none';
                            }
                        }
                        if (dialogName == 'image') {
                            dialogDefinition.removeContents('Link');
                            dialogDefinition.removeContents('advanced');
                            var infoTab = dialogDefinition.getContents('info');
                            if (infoTab) {
                                infoTab.remove('txtBorder');
                                infoTab.remove('txtHSpace');
                                infoTab.remove('txtVSpace');
                                infoTab.remove('cmbAlign');
                            }
                        }
                    });
                }}
                config={{
                    ...this.props.config || {},
                    filebrowserBrowseUrl: '',
                    filebrowserImageBrowseUrl: '',
                    extraPlugins: [
                        'autogrow', 'autocomplete', 'mentions'
                    ],
                    removePlugins: [
                        'exportpdf', 'find', 'flash', 'font',
                        'forms', 'iframe', 'image', 'newpage', 'removeformat', 'scayt',
                        'smiley', 'specialchar', 'stylescombo', 'templates', 'wsc', 'elementspath'
                    ],
                    protectedSource: [
                        /\{\{[\s\S]*?\}\}/g,
                        /\{\%[\s\S]*?%\}/g,
                        /\{\#[\s\S]*?#\}/g,
                    ],
                    mentions: [{
                        feed: (options, callback) => {
                            if (!this.props.mentions || !this.props.api) {
                                callback([]);
                                return;
                            }
                            if (options.query?.toLowerCase() === 'all') {
                                callback([{ id: 0, uuid: null, emailAddress: '@all', 'name': 'ALL' }])
                            } else {
                                this.props.api.postSilent(
                                    `/user/list-partial-mentions`,
                                    {
                                        "search": options.query
                                    },
                                    (data) => {
                                        const users = data.users.map(user => { return { ...user, name: user.label } });
                                        callback(users);
                                    },
                                    () => {
                                        callback([])
                                    }
                                );
                            }
                        },
                        outputTemplate: `<a class="mention" href="mailto:{emailAddress}">{name}</a>`,
                        throttle: 200,
                        minChars: 3
                    }],
                    toolbar: this.props.simpleToolbar ?
                        [
                            ['Bold', 'Italic', 'Underline', 'Strike'],
                            ['NumberedList', 'BulletedList'],
                            ['TextColor', 'BGColor'],
                            ['Link', 'Unlink'],
                        ] : [
                            ['Format'],
                            ['Bold', 'Italic', 'Underline', 'Strike', '-', 'Subscript', 'Superscript'],
                            ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', 'Blockquote'],
                            ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                            ['BidiLtr', 'BidiRtl'],
                            ['Link', 'Unlink'],
                            ['Table'],
                            ['Source']
                        ],
                }}
            />
        );
    }
}

export default TextEditor;
