import PageAssetContent from "../Asset/content";

class Content extends PageAssetContent {

    constructor(props) {
        super(props);
        this.state.view = 'purchase';
        this.state.category = 'purchase';
        this.state.fields = [
            {
                "ident": "basicDataSection",
                "fields": [
                    "assetType",
                    "product",
                    "name",
                ]
            },
            {
                "ident": "ownerDataSection",
                "fields": [
                    "nameOfOwningCompany",
                    "countryCodeOfUser",
                    "firstNameOfUser",
                    "lastNameOfUser",
                    "emailAddressOfUser",
                    "mobilePhoneOfUser",
                    "positionOfUser",
                    "emailAddressOfSupervisor"
                ]
            },
            {
                "ident": "contractDataSection",
                "fields": [
                    "contractType",
                    "currencyCode",
                    "totalValue",
                    "entryFee",
                    "monthlyFee",
                    "redemptionValue",
                    "purchaseDate",
                    "expirationDate",
                    "grantName",
                ]
            },
            {
                "ident": "commentsSection",
                "fields": [
                    "comments"
                ]
            },
            {
                "ident": "commentSection",
                "fields": [
                    "comment"
                ]
            },
            {
                "ident": "filesSection",
                "fields": [
                    "files",
                ]
            },
            {
                "ident": "fileSection",
                "fields": [
                    "file",
                ]
            },
            {
                "ident": "statusesSection",
                "fields": [
                    "statuses",
                ]
            },
            {
                "ident": "statusSection",
                "fields": [
                    "status",
                    "statusComment"
                ]
            }
        ]
    }

}

export default Content
