// @flow
import React from 'react';
import styled from '@emotion/styled';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import QuoteItem from './quote-item';
import { grid } from './constants';
import colors from './colors';

export const getBackgroundColor = (
    isDraggingOver,
    isDraggingFrom,
) => {
    if (isDraggingOver) {
        return colors.Y50;
    }
    if (isDraggingFrom) {
        return colors.N50;
    }
    return colors.N30;
};

const Wrapper = styled.div`
  background-color: ${(props) =>
        getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')};
  padding: ${grid}px;
  border: ${grid}px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 250px;
`;

const scrollContainerHeight = 250;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: ${scrollContainerHeight}px;

  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  padding-bottom: ${grid}px;
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${scrollContainerHeight}px;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div``;
/* stylelint-enable */

const InnerQuoteList = React.memo(function InnerQuoteList(
    props,
) {
    return props.quotes.map((quote, index) => (
        <Draggable key={quote.id} draggableId={quote.id} index={index}>
            {(
                dragProvided,
                dragSnapshot,
            ) => (
                <QuoteItem
                    key={quote.id}
                    quote={quote}
                    isDragging={dragSnapshot.isDragging}
                    isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                    provided={dragProvided}
                />
            )}
        </Draggable>
    ));
});

function InnerList(props) {
    const { quotes, dropProvided } = props;
    const title = props.title ? <h1>{props.title}</h1> : null;

    return (
        <Container>
            {title}
            <DropZone ref={dropProvided.innerRef}>
                <InnerQuoteList quotes={quotes} />
                {dropProvided.placeholder}
            </DropZone>
        </Container>
    );
}

export default function QuoteList(props) {
    const {
        ignoreContainerClipping,
        internalScroll,
        scrollContainerStyle,
        isDropDisabled,
        isCombineEnabled,
        listId = 'LIST',
        listType,
        style,
        quotes,
        title,
        useClone,
    } = props;

    return (
        <Droppable
            droppableId={listId}
            type={listType}
            ignoreContainerClipping={ignoreContainerClipping}
            isDropDisabled={isDropDisabled}
            isCombineEnabled={isCombineEnabled}
            renderClone={
                useClone
                    ? (provided, snapshot, descriptor) => (
                        <QuoteItem
                            quote={quotes[descriptor.source.index]}
                            provided={provided}
                            isDragging={snapshot.isDragging}
                            isClone
                        />
                    )
                    : null
            }
        >
            {(
                dropProvided,
                dropSnapshot,
            ) => (
                <Wrapper
                    style={style}
                    isDraggingOver={dropSnapshot.isDraggingOver}
                    isDropDisabled={isDropDisabled}
                    isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
                    {...dropProvided.droppableProps}
                >
                    {internalScroll ? (
                        <ScrollContainer style={scrollContainerStyle}>
                            <InnerList
                                quotes={quotes}
                                title={title}
                                dropProvided={dropProvided}
                            />
                        </ScrollContainer>
                    ) : (
                        <InnerList
                            quotes={quotes}
                            title={title}
                            dropProvided={dropProvided}
                        />
                    )}
                </Wrapper>
            )}
        </Droppable>
    );
}