import './style.scss';
import classnames from 'classnames/dedupe';
import React, { Component } from 'react';
import { Toast } from 'react-bootstrap';
import TimeAgo from 'react-timeago';
import Icon from '../icon';

class PageToasts extends Component {

    render() {
        const {
            toasts,
        } = this.props;

        return (
            <div className="rui-toast-container">
                {
                    Object.keys(toasts).map((uid) => {
                        const toast = toasts[uid];
                        return (
                            <Toast
                                key={uid}
                                className={classnames('rui-toast', 'with-nice-shadow', `toast-${toast.color}`)}
                            >
                                <div className="toast-header">
                                    {
                                        toast.title &&
                                        (
                                            <h5 className="mr-auto mnb-2">{toast.title}</h5>
                                        )
                                    }
                                    {
                                        toast.time &&
                                        (
                                            <small className="toast-date">
                                                <TimeAgo date={toast.time} />
                                            </small>
                                        )
                                    }
                                    {
                                        toast.closeButton &&
                                        (
                                            <button
                                                type="button"
                                                className="btn  toast-close close px-10 py-5"
                                                aria-label="Close"
                                                onClick={() => {
                                                    this.props.removeToast(toast.ts);
                                                }}
                                            >
                                                <Icon name="x" />
                                            </button>
                                        )
                                    }
                                </div>
                                {
                                    toast.content &&
                                    (
                                        <Toast.Body>
                                            {toast.content}
                                        </Toast.Body>
                                    )
                                }
                            </Toast>
                        );
                    })
                }
            </div>
        );
    }
}

export default PageToasts
