import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

class Content extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Fragment>
                <p className="display-4 mb-30">{this.props.t('page.404.title')}</p>
                <Link to="/" className="btn btn-brand">{this.props.t('page.404.backHome')}</Link>
            </Fragment>
        );
    }
}

export default Content
