import React, { Fragment } from 'react';
import { Table } from "react-bootstrap";
import LowerElementsComponent from '../../components/component/lower-elements';

class OfferSupplier extends LowerElementsComponent {

    render() {

        const {
            activeCostTotal,
            passiveCostTotal,
            serviceCostTotal,
            kickoffCostTotal
        } = this.props

        return (
            <Fragment>
                <Table size="xs" className="mb-0" responsive>
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: 80 }} className="pl-20">
                                {this.props.t('page.calculation.offer.supplier.table.label')}
                            </th>
                            <th scope="col" style={{ width: 80 }} className="pr-20 text-right">
                                {this.props.t('page.calculation.offer.supplier.table.value')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="align-middle pl-20">{this.props.t('page.calculation.offer.supplier.table.active')}</td>
                            <td className="align-middle pr-20 text-right">{this.parseFloatFixed(activeCostTotal).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td className="align-middle pl-20">{this.props.t('page.calculation.offer.supplier.table.passive')}</td>
                            <td className="align-middle pr-20 text-right">{this.parseFloatFixed(passiveCostTotal).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td className="align-middle pl-20">{this.props.t('page.calculation.offer.supplier.table.service')}</td>
                            <td className="align-middle pr-20 text-right">{this.parseFloatFixed(serviceCostTotal).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td className="align-middle pl-20 strong">{this.props.t('page.calculation.offer.supplier.table.kickoff')}</td>
                            <td className="align-middle pr-20 text-right strong">{this.parseFloatFixed(kickoffCostTotal).toFixed(2)}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2" className="align-middle p-0">
                                <p className="alert alert-success m-0 text-center" style={{ whiteSpace: "pre-wrap" }}>
                                    {this.props.t('page.calculation.offer.supplier.table.disclaimer')}
                                </p>
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </Fragment>

        );
    }
}
export default OfferSupplier;