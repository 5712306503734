import React, { Fragment } from 'react';
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import LowerElementsComponent from '../../components/component/lower-elements';
import Icon from '../../components/icon';

class Content extends LowerElementsComponent {

    render() {

        const {
            upperState
        } = this.props;

        let lastPositionPermission = 0;
        let lastPositionRole = 0;

        return (
            <Fragment>
                <Row className="mt-10 mb-30 rui-page-sidebar-sticky" style={{ background: '#fff', zIndex: 99 }}>
                    <Col sm={12} md={12} lg={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group className="required">
                                            <Form.Label>{this.props.t(`page.roles.table.role`)}</Form.Label>
                                            <Form.Control
                                                required={true}
                                                as="select"
                                                custom
                                                value={upperState.roleUuid}
                                                onChange={(e) => { this.onChangeSetUpperState('roleUuid', e) }}
                                            >
                                                {
                                                    upperState.roles.map(role => {
                                                        const prevPosition = lastPositionRole;
                                                        const currentPosition = role.position;
                                                        lastPositionRole = currentPosition;
                                                        return (
                                                            <Fragment key={`role-${role.uuid}`}>
                                                                {Math.abs(prevPosition - currentPosition) > 5 && (
                                                                    <option disabled></option>
                                                                )}
                                                                <option value={role.uuid}>
                                                                    {`#${role.position} - ${role.summary} (${role.ident})`}
                                                                </option>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card className="border-top-0">
                            <Card.Body className="p-0">
                                <Table size="xs" className="mb-0" responsive striped>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="pl-25" >
                                                {this.props.t('page.roles.table.permissions')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="pl-10 align-middle">
                                                <Row className="m-0">
                                                    {upperState.permissions.map(
                                                        (permission) => {
                                                            const prevPosition = lastPositionPermission;
                                                            const currentPosition = permission.position;
                                                            const role = upperState.roles.find((item) => item.uuid === upperState.roleUuid);
                                                            lastPositionPermission = currentPosition;
                                                            return (
                                                                <Col sm={12} key={`${upperState.roleUuid}-${permission.uuid}`}>
                                                                    {Math.abs(prevPosition - currentPosition) > 10 && (
                                                                        <hr />
                                                                    )}
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id={`${upperState.roleUuid}-${permission.uuid}`}
                                                                        label={`#${permission.position} - ${permission.summary}`}
                                                                        checked={role?.permissions?.includes(permission.uuid)}
                                                                        onChange={(e) => {
                                                                            this.handleElementChange(
                                                                                'roles',
                                                                                upperState.roleUuid,
                                                                                'permissions',
                                                                                role.permissions.includes(permission.uuid)
                                                                                    ?
                                                                                    role.permissions.filter(uuid => uuid !== permission.uuid)
                                                                                    :
                                                                                    role.permissions.concat([permission.uuid]),
                                                                                upperState
                                                                            )
                                                                        }}
                                                                        className="mb-10"
                                                                    />
                                                                </Col>
                                                            )
                                                        }
                                                    )}
                                                </Row>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-30">
                    <Col sm={12}>
                        <div className="pt-30">
                            <Button
                                variant="success"
                                size="lg"
                                disabled={upperState.isLoading}
                                onClick={(e) => { this.props.saveData() }}
                            >
                                <Icon name="save" />
                                <span className="pl-10">
                                    {this.props.t('page.user.options.saveData')}
                                </span>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Fragment>
        );
    }

}

export default Content
