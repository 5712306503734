import React, { Fragment } from 'react';
import Datatable from "../../components/datatable";
import BaseComponent from '../../components/component/base';
import Icon from '../../components/icon';
import { Row, Col, Card, Form, Modal } from "react-bootstrap";
import UserView from '../User/content';

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            modalCustomer: false,
            customeruuid: null,
            customername: '',
            onlyMyArea: this.props.hasPermission(['VIEW_MY_AREA_FILTER_BUTTON']).any
        };
    }

    render() {

        const match = {
            'params': {
                'uuid': this.state.customeruuid
            }
        };

        return (
            <Fragment>
                <Modal
                    show={this.state.modalCustomer}
                    onHide={() => { this.setState({ modalCustomer: false, customeruuid: null, customername: '' }) }}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    centered
                    autoFocus={false}
                    enforceFocus={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {`${this.state.customername}`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UserView {...this.props} match={match} customer={true} />
                    </Modal.Body>
                </Modal>

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Header className="px-10 py-10 background-white">
                                <button
                                    type="button"
                                    className="btn btn-uniform text-success"
                                    disabled={false}
                                    onClick={(e) => { this.setState({ modalCustomer: true, customeruuid: null, customername: this.props.t('common.button.add') }) }}
                                >
                                    <Icon name="plus-square" />
                                    <span className="pl-10">
                                        {this.props.t('page.users.newCustomer')}
                                    </span>
                                </button>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Datatable
                                    id={`customers-table`}
                                    endpoint={`user/table`}
                                    monitorProp={this.state.onlyMyArea}
                                    withData={{
                                        "onlyMyArea": this.state.onlyMyArea,
                                        "customer": true,
                                    }}
                                    columns={[
                                        {
                                            Header: this.props.t('page.users.table.created'),
                                            accessor: "created_7"
                                        },
                                        {
                                            Header: this.props.t('page.users.table.active'),
                                            accessor: "active_8",
                                            width: 100
                                        },
                                        {
                                            Header: this.props.t('page.users.table.emailAddress'),
                                            accessor: "emailAddress_3"
                                        },
                                        {
                                            Header: this.props.t('page.users.table.firstName'),
                                            accessor: "firstName_1"
                                        },
                                        {
                                            Header: this.props.t('page.users.table.lastName'),
                                            accessor: "lastName_2"
                                        },
                                        {
                                            Header: (<>&nbsp;</>),
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                            width: 100
                                        }
                                    ]}
                                    sort={[
                                        {
                                            id: "created_7",
                                            desc: true
                                        }
                                    ]}
                                    badges={[
                                        {
                                            'field': 'active_8',
                                            'variants':
                                            {
                                                '1': 'success',
                                                '0': 'warning'
                                            }
                                        },
                                        {
                                            "field": "summary_5",
                                            "badgify": true
                                        }
                                    ]}
                                    transform={[]}
                                    withCheckbox={false}
                                    onChecked={(data) => { }}
                                    actions={[
                                        {
                                            action: 'edit',
                                            callback: (data) => {
                                                this.setState({ modalCustomer: true, customeruuid: data.uuid_0, customername: data.emailAddress_3 || 'New account' })
                                            }
                                        }
                                    ]}
                                    {...this.props}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment >
        );
    }

}

export default Content
