import { Button, Form, Row, Col, ListGroup } from "react-bootstrap";
import BaseComponent from "../../component/base";
import Icon from "../../icon";

class InputData extends BaseComponent {

    render() {
        return (
            <>
                <ListGroup>
                    <ListGroup.Item className="px-15 py-5">
                        <div className="float-left">
                            {this.props.t('page.calculation.pvDesigner.offeredPvPower')}
                            &nbsp;
                        </div>
                        <div className="float-right">
                            {this.props.offeredPvPower.toFixed(2)}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-15 py-5">
                        <div className="float-left">
                            {this.props.t('page.calculation.pvDesigner.realAnnualAssumedEnergyProductionKwH')}
                            &nbsp;
                        </div>
                        <div className="float-right">
                            {(this.props.realAnnualAssumedEnergyProductionKwH || 0).toFixed(2)}
                            <Button
                                variant="link"
                                disabled={this.props.isLoading || this.props.isDisabled}
                                style={{ padding: 0, marginLeft: 5 }}
                                onClick={
                                    (evt) => {
                                        this.props.reCalcFinancing()
                                    }
                                }>
                                <Icon name="refresh-cw" height={12} />
                            </Button>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-15 py-5">
                        <div className="float-left">
                            {this.props.t('page.calculation.pvDesigner.roofArea')}
                            &nbsp;
                        </div>
                        <div className="float-right">
                            {this.props.roofArea.toFixed(2)}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-15 py-5">
                        <div className="float-left">
                            {this.props.t('page.calculation.pvDesigner.pvAmount')}
                            &nbsp;
                        </div>
                        <div className="float-right">
                            {this.props.pvAmount}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-15 py-5">
                        <div className="float-left">
                            {this.props.t('page.calculation.pvDesigner.pvArea')}
                            &nbsp;
                        </div>
                        <div className="float-right">
                            {this.props.pvArea.toFixed(2)}
                        </div>
                    </ListGroup.Item>
                    {
                        (this.props.isRegion(['SWEDEN', 'GERMANY'])) && (
                            <ListGroup.Item className="px-15 py-5">
                                <div className="float-left">
                                    {this.props.t('page.calculation.pvDesigner.bipvArea')}
                                    &nbsp;
                                </div>
                                <div className="float-right">
                                    {this.props.bipvArea.toFixed(2)}
                                </div>
                            </ListGroup.Item>
                        )
                    }
                    <ListGroup.Item className="px-15 py-5">
                        <div className="float-left">
                            {this.props.t('page.calculation.pvDesigner.passiveArea')}
                            &nbsp;
                        </div>
                        <div className="float-right">
                            {this.props.passiveArea.toFixed(2)}
                        </div>
                    </ListGroup.Item>
                    {
                        (this.props.isRegion(['SWEDEN', 'GERMANY'])) && (
                            <ListGroup.Item className="px-15 py-5">
                                <div className="float-left">
                                    {this.props.t('page.calculation.pvDesigner.passiveAreaExtra')}
                                    &nbsp;
                                </div>
                                <div className="float-right">
                                    {this.props.passiveAreaExtra.toFixed(2)}
                                </div>
                            </ListGroup.Item>
                        )
                    }
                    <ListGroup.Item className="px-15 py-5">
                        <div className="float-left">
                            {this.props.t('page.calculation.pvDesigner.roofType')}
                            &nbsp;
                        </div>
                        <div className="float-right" style={{ minWidth: 60 }}>
                            <Form.Control
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                required={true}
                                disabled={this.props.isLoading}
                                as="select"
                                custom
                                value={this.props.roofType}
                                onChange={
                                    (evt) => {
                                        this.props.setUpperState(
                                            { roofType: this.val(evt) }
                                        )
                                    }
                                }
                            >
                                <option value=""></option>
                                {
                                    this.props.roofTypes.map(rt => (
                                        <option key={`rt-${rt}`} value={rt}>
                                            {rt}
                                        </option>
                                    ))
                                }
                            </Form.Control>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </>
        )
    }
}

export default InputData;