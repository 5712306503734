import React, { Component } from 'react';
import Widget from '../../components/widget';
import IconEmpty from '../../assets/images/icon-empty.svg';
import Icon from '../../components/icon';

class WidgetUploads extends Component {
    render() {

        const items = this.props.items.slice(0, this.props.limit);

        return (
            <Widget title={this.props.t('page.sales.recentFiles')} className="rui-widget-actions" showDropdown={false}>
                <div className="rui-widget-content">
                    {
                        items.length ? (
                            <ul className="list-group list-group-flush rui-widget-list">
                                {
                                    items.map(item => (
                                        <li className="list-group-item" key={`dash-upload-${item.uuid}`}>
                                            <div className="media media-filled">
                                                <a href={item.link} target="_blank" className="media-link">
                                                    <span className="media-img bg-transparent">
                                                        <img src={IconEmpty} className="icon-file" alt="" />
                                                    </span>
                                                    <span className="media-body">
                                                        <span className="media-title">
                                                            {item.filename}
                                                        </span>
                                                        <small className="media-subtitle">
                                                            {item.created}
                                                        </small>
                                                    </span>
                                                </a>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        ) : (
                            <div className="text-center">
                                <Icon name="coffee" style={{ width: 48, height: 48, color: "#e6ecf0" }} />
                            </div>
                        )
                    }
                </div>
            </Widget>
        );
    }
}

export default WidgetUploads;