import React, { Fragment } from 'react';
import BaseComponent from "../../components/component/base";
import Basic from "./basic";

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,

            uuid: "",
            active: false,
            notify: false,
            mentions: false,
            otpRequired: false,
            referralCode: "",

            emailAddress: "",
            firstName: "",
            secondName: "",
            lastName: "",
            mobilePhone: "",

            newPassword: "",
            newPasswordRepeat: "",
            newPasswordComplexity: 0,

            preferredCooperationModel: "",
            preferredCooperationType: "",
            preferredCooperationDescription: "",

            consentAgreement: null,
            consentSalesLeaderContest: null,
            consentAffIsEmployed: null,
            consentAffIsContractor: null,
            consentAffIsStudent: null,
            consentAffIsUnemployed: null,
            consentAffIsRetired: null,

            roleUuid: "",
            companyUuid: "",
            supervisorUuid: "",
            referralManagerUuid: "",
            areas: [],
            projects: [],

            roles: [],
            companies: [],

            configuration: [],
            avatar: null,
            orgChart: null,

            taxNumber: "",
            taxOfficeName: "",
            postalCode: "",
            cityName: "",
            streetAddress: "",
            streetNumber: "",
            apartmentNumber: "",
            bankName: "",
            bankAccountNumber: "",
        };
    }

    componentDidMount() {
        this.maybeLoadRolesAndCompanies();
        if (this.props.match.params.uuid) {
            this.loadData(this.props.match.params.uuid);
        }
    }

    maybeLoadRolesAndCompanies() {
        if (this.props.hasPermission(['ADMIN', 'ROLES']).any) {
            this.loadRoles();
        }
        if (this.props.hasPermission(['ADMIN', 'MY_REGION_COMPANIES']).any) {
            this.loadCompanies();
        }
    }

    loadData(uuid) {
        this.setState({ isLoading: true });
        this.props.api.post(
            `/user/describe`,
            {
                "user": {
                    "uuid": uuid
                }
            },
            (data) => {
                let addressPieces = {};
                if (data.address) {
                    addressPieces = {
                        taxNumber: data.address.taxNumber,
                        taxOfficeName: data.address.taxOfficeName,
                        postalCode: data.address.postalCode,
                        cityName: data.address.cityName,
                        streetAddress: data.address.streetAddress,
                        streetNumber: data.address.streetNumber,
                        apartmentNumber: data.address.apartmentNumber,
                        bankName: data.address.bankName,
                        bankAccountNumber: data.address.bankAccountNumber,
                    };
                }
                this.setData({
                    ...data.user,
                    ...addressPieces,
                    configuration: data.user.configuration || {},
                    areas: data.areas.map(itm => { return { ...itm, label: itm.ident, value: itm.uuid } }),
                    projects: data.projects.map(itm => { return { uuid: itm.project.uuid, label: itm.project.label + ' ' + itm.address.addressName, value: itm.project.uuid } }),
                    roleUuid: data.role ? data.role.uuid : "",
                    companyUuid: data.company ? data.company.uuid : "",
                    supervisorUuid: data.supervisor ? data.supervisor.uuid : "",
                    referralManagerUuid: data.referralManager ? data.referralManager.uuid : "",
                    avatar: data.files ? data.files.find(itm => itm.ident === 'avatar') : null
                });
            }
        );
    }

    loadRoles() {
        this.setState({ isLoading: true });
        this.props.api.post(
            `/role/list`,
            {},
            (data) => {
                this.setData(
                    {
                        roles: data.roles
                    }
                )
            }
        );
    }

    loadCompanies() {
        this.setState({ isLoading: true });
        this.props.api.post(
            `/company/list`,
            {},
            (data) => {
                this.setData(
                    {
                        companies: data.companies
                    }
                )
            }
        );
    }

    setData(data) {
        this.setState({
            ...data,
            isLoading: false
        });
    }

    saveData() {
        if (this.state.newPassword) {
            if (this.state.newPassword !== this.state.newPasswordRepeat) {
                this.props.showToast({
                    title: this.props.t('common.toast.mismatchPassword'),
                    color: "danger"
                });
                return;
            }
            if (this.state.newPasswordComplexity < this.props.utils.minimumPasswordComplexity()) {
                this.props.showToast({
                    title: this.props.t('common.toast.tooWeakPassword'),
                    color: "danger"
                });
                return;
            }
        }
        this.setState({ isLoading: true });
        this.props.api.post(
            this.state.uuid ? `/user/update` : `/user/create`,
            {
                "user": {
                    "uuid": this.state.uuid,
                    "firstName": this.state.firstName,
                    "secondName": this.state.secondName,
                    "lastName": this.state.lastName,
                    "emailAddress": this.state.emailAddress,
                    "mobilePhone": this.state.mobilePhone,
                    "active": this.state.active,
                    "notify": this.state.notify,
                    "mentions": this.state.mentions,
                    "otpRequired": this.state.otpRequired,
                    "withPassword": this.state.newPassword !== "",
                    "password": this.state.newPassword,
                    "preferredCooperationModel": this.state.preferredCooperationModel,
                    "preferredCooperationType": this.state.preferredCooperationType,
                    "preferredCooperationDescription": this.state.preferredCooperationDescription,
                    "withConsents": true,
                    "consentAgreement": this.state.consentAgreement,
                    "consentSalesLeaderContest": this.state.consentSalesLeaderContest,
                    "consentAffIsEmployed": this.state.consentAffIsEmployed,
                    "consentAffIsContractor": this.state.consentAffIsContractor,
                    "consentAffIsStudent": this.state.consentAffIsStudent,
                    "consentAffIsUnemployed": this.state.consentAffIsUnemployed,
                    "consentAffIsRetired": this.state.consentAffIsRetired,
                    "configuration": this.state.configuration,
                    "orgChart": this.state.orgChart?.value || '',
                    "referralCode": this.state.referralCode,
                },
                "address": {
                    "taxNumber": this.state.taxNumber,
                    "taxOfficeName": this.state.taxOfficeName,
                    "postalCode": this.state.postalCode,
                    "cityName": this.state.cityName,
                    "streetAddress": this.state.streetAddress,
                    "streetNumber": this.state.streetNumber,
                    "apartmentNumber": this.state.apartmentNumber,
                    "bankName": this.state.bankName,
                    "bankAccountNumber": this.state.bankAccountNumber,
                },
                "company": {
                    "uuid": this.state.companyUuid
                },
                "supervisor": {
                    "uuid": this.state.supervisorUuid
                },
                "role": {
                    "uuid": this.state.roleUuid
                },
                "referralManager": {
                    "uuid": this.state.referralManagerUuid,
                },
                "areas": this.state.areas,
                "projects": this.state.projects,
            },
            (data) => {
                this.props.showToast({
                    title: this.props.t('common.toast.dataSaved'),
                    color: "info"
                });
                this.setData({
                    ...data.user,
                    roleUuid: data.role ? data.role.uuid : "",
                    companyUuid: data.company ? data.company.uuid : "",
                    supervisorUuid: data.supervisor ? data.supervisor.uuid : "",
                    notify: false,
                });
            },
            (errorObject) => {
                this.setState({ isLoading: false });
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        );
    }

    render() {

        return (
            <Fragment>
                <Basic
                    {...this.props}
                    showAdminTab={true}
                    customer={this.props.customer}
                    upperState={this.state}
                    setUpperState={(data, callback) => this.setState(data, callback)}
                    saveData={(data) => this.saveData(data)}
                />
            </Fragment>
        );
    }
}

export default Content
