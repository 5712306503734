import React, { Fragment } from 'react';
import { Row, Col, Card, Tabs, Tab, ListGroup, Button } from "react-bootstrap";
import Icon from '../../components/icon';
import LowerComponent from '../../components/component/lower';
import ModalSignature from '../../components/signature/modal';
import Timeline from '../../components/timeline';

class Signature extends LowerComponent {

    constructor(props) {
        super(props);
        this.state = {
            modalSignatureParams: false
        }
    }

    getDocumentMainFile(e, signatureUuid) {
        e.preventDefault();
        this.props.setUpperState({ isLoading: true }, () => {
            this.props.api.post(
                `/signature/download`,
                {
                    "signature": {
                        "uuid": signatureUuid,
                    }
                },
                (successData) => {
                    this.props.setUpperState({ isLoading: false }, () => {
                        if (successData.file && successData.file.link) {
                            window.open(successData.file.link);
                        }
                    });
                },
                (errorData) => {
                    this.props.setUpperState({ isLoading: false }, () => {
                        this.props.showToast({
                            errorObject: errorData,
                            title: this.props.t('common.toast.error'),
                            color: "danger"
                        });
                    });
                }
            );
        });
    }

    render() {

        const {
            isLoading,
            closed,
            files,
            uuid,
            firstName,
            lastName,
            emailAddress,
            signatures,
            signatureUuid,
            signatureStatuses
        } = this.props;

        const timelineItems = signatureStatuses.map((signatureStatus, signatureStatusIndex) => {
            return (
                <Timeline.Item
                    key={signatureStatus.uuid}
                    icon={<Icon name="check-circle" />}
                    date={signatureStatus.created}
                    swap={signatureStatusIndex % 2 === 0}
                >
                    <h3 className="py-0">{this.props.t(`common.signatureStatus.${signatureStatus.ident}`)}</h3>
                    <p>{this.props.t('page.calculation.signature.party') + ' ' + signatureStatus.party}</p>
                    <p dangerouslySetInnerHTML={{ __html: signatureStatus.comment }} />
                </Timeline.Item>
            );
        });

        const placeholder = (
            <div className="dropzone py-50 px-20 text-center">
                {this.props.t('page.calculation.signature.await')}
                {` `}
                <Icon name="clock" />
            </div>
        )

        const metrics = (signature) => [
            {
                "label": this.props.t(`page.calculation.signature.metric.documentMainFileName`),
                "value": (
                    <button className='btn-link p-0 text-right' onClick={(e) => { this.getDocumentMainFile(e, signature.uuid) }}>{signature.documentMainFileName || "---"}</button>
                )
            },
            { "label": this.props.t(`page.calculation.signature.metric.documentId`), "value": signature.documentId },
            { "label": this.props.t(`page.calculation.signature.metric.documentStatus`), "value": signature.documentStatus },
            { "label": this.props.t(`page.calculation.signature.metric.created`), "value": signature.created },
            { "label": this.props.t(`page.calculation.signature.metric.agreementType`), "value": this.props.t(`common.agreementType.${signature.agreementType || 'contract'}`) },
            { "label": this.props.t(`page.calculation.signature.metric.comment`), "value": signature.comment || "---" },
        ];

        return (
            <Fragment>

                <h2>
                    {this.props.t('page.calculation.signature.title')}
                </h2>

                <ModalSignature
                    show={this.state.modalSignatureParams}
                    showParties={true}
                    onHide={() => { this.setState({ modalSignatureParams: false }) }}
                    header={`${firstName} ${lastName} - ${emailAddress}`}
                    files={[...files].reverse()}
                    t={this.props.t}
                    api={this.props.api}
                    isLoading={this.props.isLoading || this.props.closed}
                    setUpperState={(newState, callback) => { this.setUpperState(newState, callback) }}
                    isRegion={this.props.isRegion}
                    hasPermission={this.props.hasPermission}
                    getUserConfig={this.props.getUserConfig}
                    showToast={this.props.showToast}
                    offerUuid={uuid}
                    endpoint={`/offer`}
                    isSalesProcess={true}
                    onSigned={(newState, callback) => {
                        this.setState(
                            {
                                modalSignatureParams: false,
                            },
                            () => {
                                this.setUpperState(newState, callback);
                                this.props.showToast({
                                    title: this.props.t('common.toast.signatureSent'),
                                    color: "info"
                                });
                                setTimeout(() => {
                                    this.props.api.dispatchEvent('REFETCH_FILES');
                                }, 1000);
                            });
                    }}
                />

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Header className="px-10 py-10 background-white">
                                {
                                    (this.props.hasPermission(['VIEW_OFFER_SIGNATURE_BUTTON']).any) && (
                                        <button
                                            type="button"
                                            className="btn btn-uniform text-success"
                                            onClick={(e) => { this.setState({ modalSignatureParams: true }) }}
                                            disabled={isLoading || closed}
                                        >
                                            <Icon name="pen-tool" />
                                            <span className="pl-10">
                                                {this.props.t('page.calculation.signature.send')}
                                            </span>
                                        </button>
                                    )
                                }
                            </Card.Header>
                            <Card.Body>
                                {
                                    signatures.length > 0 ? (
                                        <>
                                            <Tabs
                                                className="align-content-between nicebar border-bottom"
                                                id="signatures"
                                                activeKey={signatureUuid}
                                                onSelect={(k) => this.props.setSignature(k)}
                                            >
                                                {
                                                    signatures.map(signature => (
                                                        <Tab key={signature.uuid} title={`${signature.comment || this.props.t(`common.agreementType.${signature.agreementType || 'contract'}`)} (${signature.created})`} tabClassName="mt-10" eventKey={`${signature.uuid}`}>
                                                            <Row>
                                                                <Col sm={12} className="mt-30">
                                                                    {
                                                                        signatureStatuses.length ? (
                                                                            <Timeline lg="left">
                                                                                {timelineItems}
                                                                            </Timeline>

                                                                        ) : placeholder
                                                                    }
                                                                </Col>
                                                                <Col sm={12} className="mt-30">
                                                                    <ListGroup>
                                                                        {
                                                                            metrics(signature).map(metric => (
                                                                                <ListGroup.Item key={`metric-${metric.label}`} className="d-flex justify-content-between align-items-center">
                                                                                    <div>
                                                                                        {metric.label}
                                                                                    </div>
                                                                                    <div>
                                                                                        {metric.value}
                                                                                    </div>
                                                                                </ListGroup.Item>
                                                                            ))
                                                                        }
                                                                    </ListGroup>
                                                                </Col>
                                                            </Row>
                                                        </Tab>
                                                    ))
                                                }
                                            </Tabs>
                                        </>
                                    ) : placeholder
                                }

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment >
        );
    }
}
export default Signature;