import BaseComponent from "../../component/base";
import { Col, Row, ListGroup, Form, Button } from "react-bootstrap";
import PvPanelsAction from "./pvPanelsAction";
import { orientation } from "../../../constants";
import Icon from "../../icon";

class Slope extends BaseComponent {

    onChange(e, element) {
        let val = this.valFloat(e);
        if (element === "slopeDeg") {
            this.props.updateSelectedElement({ slopeDeg: val, slopePercent: this.props.utils.percentFromDegrees(val) });
        } else if (element === "slopePercent") {
            this.props.updateSelectedElement({ slopePercent: val, slopeDeg: this.props.utils.degreesFromPercent(val) });
        } else {
            this.props.updateSelectedElement({ [element]: val });
        }
    }

    render() {

        const selectedElementIsPassiveExtra = this.props.selectedElement.calculateAs === 'passiveAreaExtra';

        return (
            <>
                <ListGroup>
                    <ListGroup.Item className="px-15 py-5">
                        <Form.Group as={Row} className="mb-0">
                            <Form.Label column sm={12} md={6}>
                                {this.props.t('page.calculation.pvDesigner.roofPartOrientation')}
                                &nbsp;
                            </Form.Label>
                            <Col sm={12} md={6}>
                                <Form.Control
                                    disabled={this.props.isLoading || this.props.isDisabled}
                                    as="select"
                                    value={this.props.selectedElement.orientation || '0'}
                                    onChange={(e) => { this.onChange(e, 'orientation') }}
                                >
                                    {
                                        Object.keys(orientation).map(o => {
                                            return (
                                                <option key={o} value={orientation[o]}>
                                                    {this.props.t(`page.calculation.input.roofDirection.${o}`)}
                                                </option>
                                            )
                                        })
                                    }
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-15 py-5">
                        <Form.Group as={Row} className="mb-0">
                            <Form.Label column sm={12} md={6}>
                                {this.props.t('page.calculation.pvDesigner.roofPartSlopeDeg')}
                                &nbsp;
                            </Form.Label>
                            <Col sm={12} md={6}>
                                <Form.Control
                                    disabled={this.props.isLoading || this.props.isDisabled}
                                    type="number"
                                    step="0.01"
                                    value={this.props.selectedElement.slopeDeg}
                                    onChange={(e) => { this.onChange(e, 'slopeDeg') }}
                                />
                            </Col>
                        </Form.Group>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-15 py-5">
                        <Form.Group as={Row} className="mb-0">
                            <Form.Label column sm={12} md={6}>
                                {this.props.t('page.calculation.pvDesigner.roofPartSlopePrc')}
                                &nbsp;
                            </Form.Label>
                            <Col sm={12} md={6}>
                                <Form.Control
                                    disabled={this.props.isLoading || this.props.isDisabled}
                                    type="number"
                                    step="0.01"
                                    value={this.props.selectedElement.slopePercent}
                                    onChange={(e) => { this.onChange(e, 'slopePercent') }}
                                />
                            </Col>
                        </Form.Group>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-15 py-5">
                        <Form.Group as={Row} className="mb-0">
                            <Form.Label column sm={12} md={6}>
                                {this.props.t('page.calculation.pvDesigner.fakeSlopeArea')}
                                &nbsp;
                            </Form.Label>
                            <Col sm={12} md={6}>
                                <Form.Control
                                    disabled={this.props.isLoading || this.props.isDisabled}
                                    type="text"
                                    step="0.01"
                                    value={this.props.selectedElement.fakeSlopeArea}
                                    onChange={(e) => { this.onChange(e, 'fakeSlopeArea') }}
                                />
                            </Col>
                        </Form.Group>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-15 py-5">
                        <Form.Group as={Row} className="mb-0 mt-5">
                            <Col>
                                <Form.Check
                                    disabled={this.props.isLoading || this.props.isDisabled}
                                    type="switch"
                                    id="selectedElementIsPassiveExtra"
                                    label={this.props.t('page.calculation.pvDesigner.roofPartAsTraditional')}
                                    checked={selectedElementIsPassiveExtra}
                                    onChange={(e) => {
                                        this.props.updateSelectedElement(
                                            { calculateAs: selectedElementIsPassiveExtra ? null : 'passiveAreaExtra' },
                                            () => {
                                                this.props.removePolygonPanels()
                                            }
                                        );
                                    }}
                                    className="mb-5"
                                />
                            </Col>
                        </Form.Group>
                    </ListGroup.Item>
                    {
                        !selectedElementIsPassiveExtra && (
                            <ListGroup.Item className="px-15 py-5">
                                <Form.Group as={Row} className="mb-0">
                                    <Form.Label column sm={12} md={8}>
                                        {this.props.t('page.calculation.pvDesigner.roofPartAddRemovePv')}
                                        {` `}
                                        ({this.props.pvPower} W):
                                        &nbsp;
                                    </Form.Label>
                                    <Col sm={12} md={4} className="p-0">
                                        <div className="float-right">
                                            <PvPanelsAction {...this.props} />
                                        </div>
                                    </Col>
                                </Form.Group>
                            </ListGroup.Item>
                        )
                    }
                </ListGroup>

                <div className="mb-20" />

                <ListGroup>
                    <ListGroup.Item className="px-15 py-5">
                        <Form.Group as={Row} className="mb-0">
                            <Form.Label column sm={12} md={6}>
                                {this.props.t('page.calculation.pvDesigner.realAnnualAssumedEnergyProductionKwH')}
                                &nbsp;
                            </Form.Label>
                            <Col sm={12} md={6}>
                                <div className="float-right">
                                    {(this.props.selectedElement.annualProduction || 0).toFixed(2)}
                                    <Button
                                        variant="link"
                                        disabled={this.props.isLoading || this.props.isDisabled}
                                        style={{ padding: 0, marginLeft: 5 }}
                                        onClick={
                                            (evt) => {
                                                this.props.reCalcFinancing()
                                            }
                                        }>
                                        <Icon name="refresh-cw" height={12} />
                                    </Button>
                                </div>
                            </Col>
                        </Form.Group>
                    </ListGroup.Item>
                </ListGroup>

                {/* 

                <div className="mb-20" />

                <ListGroup>
                    <ListGroup.Item className="px-15 py-5">
                        <Form.Group as={Row} className="mb-0">
                            <Form.Label column sm={12} xl={6} style={{whiteSpace:'no-wrap'}}>
                                Orientation (deg):
                                &nbsp;
                                </Form.Label>
                            <Col sm={12} xl={6}>
                                <Form.Control
                                disabled={this.props.isLoading || this.props.isDisabled}
                                    type="number"
                                    step="0.01"
                                    value={this.props.selectedElement.rotation}
                                    onChange={(e) => { this.onChange(e, 'rotation') }}
                                />
                                <Form.Control
                                disabled={this.props.isLoading || this.props.isDisabled}
                                    type="range"
                                    min={-180}
                                    max={180}
                                    value={this.props.selectedElement.rotation}
                                    onChange={(e) => { this.onChange(e, 'rotation') }}
                                />
                            </Col>
                        </Form.Group>
                    </ListGroup.Item>
                </ListGroup>
                */}
            </>
        )
    }
}

export default Slope;