import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap";
import logo from '../../assets/images/logo.svg';

class ModalAnnounceSundesk extends Component {

    constructor(props) {
        super(props);
        const modalOutdated = props.till ? new Date().valueOf() > new Date(props.till).valueOf() : false;
        const modalIdent = `announce_${props.what || 'blank'}`;
        const showedTimes = props.api.getStored('bp_dismiss', modalIdent, 0);
        const requiredTimes = props.times;
        this.state = {
            modalOutdated,
            modalIdent,
            showedTimes,
            requiredTimes,
            show: showedTimes < requiredTimes, // modalOutdated
            size: props.size,
            what: props.what,
        }
    }

    dismiss() {
        this.props.api.postSilent(`/system/log/event_bp_dismiss`, this.state);
        this.props.api.setStored('bp_dismiss', this.state.modalIdent, this.state.showedTimes + 1);
        this.setState({ show: false });
    }

    render() {

        return null;
        
        const content = {
            sundesk: (
                <div className='py-25 text-center'>
                    <h1 className="display-4 mb-15 p-25">
                        <img src={logo} alt="Sundesk" className="img-fluid" style={{ maxHeight: 100 }} />
                    </h1>
                    <p className='lead mb-25'>SunRoof is growing super-fast,<br />and so is the system!</p>
                    <p className='lead mb-25'>From now on, our Business&nbsp;Panel&nbsp;will&nbsp;gain<br />a&nbsp;new brand name, logo, and address.</p>
                    <p className='lead mb-25'><strong>Welcome: SUNDESK!</strong></p>
                    <Button variant="outline-dark" type="button" onClick={() => { this.dismiss() }}>
                        Awesome, let's roll!
                    </Button>
                </div>
            ),
        };

        return (
            <Modal
                centered
                show={this.state.show}
                onHide={() => { this.dismiss() }}
                style={{ maxWidth: "100vw", outline: 0 }}
                backdrop="static"
                keyboard={false}
                size={this.state.size || 'md'}
            >
                <Modal.Body>
                    {content[this.state.what]}
                </Modal.Body>
            </Modal>
        );
    }
}

export default ModalAnnounceSundesk
