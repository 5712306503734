import React, { Component } from "react";
import { Badge } from "react-bootstrap";

export function stringToColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

export function contrastColor(hex, tresh) {
    if ((/^#([0-9A-F]{3}){1,2}$/i).test(hex) === false) {
        return "#000000";
    }
    let hexToR = (h) => { return parseInt((cutHex(h)).substring(0, 2), 16) }
    let hexToG = (h) => { return parseInt((cutHex(h)).substring(2, 4), 16) }
    let hexToB = (h) => { return parseInt((cutHex(h)).substring(4, 6), 16) }
    let cutHex = (h) => { return (h.charAt(0) == "#") ? h.substring(1, 7) : h }
    let threshold = tresh || 140; /* about half of 256. Lower threshold equals more dark text on dark background  */
    let hRed = hexToR(hex);
    let hGreen = hexToG(hex);
    let hBlue = hexToB(hex);
    let cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
    return (cBrightness > threshold) ? "#000000" : "#ffffff";
}

export function adjustColor(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export function getBadgeColor(text) {
    let theText = text || "E.M.P.T.Y.";
    let bgColor = stringToColor(theText);
    let bgLightColor = adjustColor(bgColor, 160);

    // fix colors for particular texts
    switch (theText) {
        case '#sales':
            bgColor = '#ffeb3b';
            bgLightColor = '#fffbd7';
            break;

        case '#project':
            bgColor = '#9C27B0';
            bgLightColor = '#eeebff';
            break;

        case '#blank':
            bgColor = '#fafafa';
            bgLightColor = bgColor;
            break;

        case '#service':
            bgColor = '#32e90c';
            bgLightColor = '#dcffd4';
            break;

        default:
            break;
    }

    let textColor = contrastColor(bgColor);
    let textLightColor = contrastColor(bgLightColor);
    return [textColor, bgColor, bgLightColor, textLightColor];
}

class AutoBadge extends Component {

    render() {
        const [textColor, bgColor] = getBadgeColor(this.props.text);

        let localProps = {
            variant: this.props.variant || null,
            pill: this.props.pill || true
        }

        if (!localProps.variant) {
            localProps.style = {
                backgroundColor: bgColor,
                color: textColor
            }
        }

        return (
            <div className={this.props.divClassName || `text-center`} style={this.props.divStyle}>
                <Badge {...localProps}>
                    {this.props.text}
                </Badge>
            </div>
        )
    }

}

export default AutoBadge;