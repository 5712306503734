import React, { Component } from 'react';
import { Table, Form } from "react-bootstrap";
import Icon from '../../components/icon';

class Files extends Component {

    render() {

        return (
            <Table size="xs" className="mb-0" responsive>
                <thead>
                    <tr>
                        <th scope="col" className={`pl-${this.props.paddingLeft || 20}`}>
                            {this.props.t('page.company.component.files.table.filename')}
                        </th>
                        <th scope="col" style={{ width: 150 }}>
                            {this.props.t('page.company.component.files.table.created')}
                        </th>
                        <th scope="col" style={{ width: 80 }} className="text-center">
                            {this.props.t('page.company.component.files.table.options')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.files.filter(itm => !itm.trash).length ?
                            this.props.files.map((file) => {
                                if (file.trash) {
                                    return null;
                                }
                                return (
                                    <tr key={file.uuid} className={`${file.className}`}>
                                        <td className={`pl-${this.props.paddingLeft || 20} align-middle`}>
                                            <a target="_blank" rel="rel noopener noreferrer" href={file.link}>
                                                {file.filename}
                                            </a>
                                        </td>
                                        <td className="align-middle">
                                            {file.created}
                                        </td>
                                        <td className="align-middle text-center">
                                            <button
                                                type="button"
                                                className="btn"
                                                data-tip={this.props.t('common.tooltip.remove')}
                                                onClick={
                                                    (e) => {
                                                        this.props.confirmRemove(
                                                            () => {
                                                                this.props.handleElementTrash(
                                                                    'files',
                                                                    file.uuid,
                                                                    this.props
                                                                );
                                                            }
                                                        )
                                                    }
                                                }
                                            >
                                                <Icon name="x" />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })
                            :
                            (
                                <tr>
                                    <td className={`pl-${this.props.paddingLeft || 20}`}>
                                        {this.props.t('page.company.component.files.table.empty')}
                                    </td>
                                </tr>
                            )
                    }
                </tbody>
            </Table>
        );
    }
}

export default Files;