import { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { appEnv, appEnvCurrent } from '../../constants';

class BaseComponent extends Component {

    debug(...args) {
        if ([appEnv.local].includes(appEnvCurrent)) {
            console.log(...args)
        }
    }

    onChange(key, e) {
        this.setState({ [key]: this.val(e) });
    }

    onChangeSetState(key, e, explicit, callback) {
        this.setState({ [key]: explicit ? e : this.val(e), '__modified': true, '__validated': false }, callback);
    }

    val(evt) {
        return evt === null ? null : evt.target ? evt.target.value : evt.value;
    }

    valFloat(evt) {
        let val = this.val(evt);
        return this.parseFloatFixed(val);
    }

    valFloatPositive(evt) {
        let val = this.val(evt);
        return String(this.parseFloatFixed(val)).replace('-', '');
    }

    round(value, decimals) {
        decimals = decimals || 0;
        value = this.parseFloatFixed(value);
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    parseFloatFixed(value) {
        if (typeof value === 'string') {
            value = value.replaceAll(/\s/g, '');
        }
        if (isNaN(parseFloat(value))) {
            return 0;
        }
        return parseFloat(value);
    }

    parseIntFixed(value, radix) {
        if (isNaN(parseInt(value, radix))) {
            return 0;
        }
        return parseInt(value, radix);
    }

    resolveValue(propsOptions, propsValue) {
        let value = null;
        let type = typeof propsValue;
        if (propsOptions && type !== undefined) {
            if (["string", "boolean", "number"].includes(type)) {
                let filtered = propsOptions.filter(itm => itm.value === propsValue);
                if (filtered) {
                    value = filtered[0];
                }
            } else {
                value = propsValue;
            }
        }
        return value;
    }

    confirm(params, callback, callbackNegative) {
        const MySwal = withReactContent(Swal)
        callback = typeof callback === 'function' ? callback : () => { };
        callbackNegative = typeof callbackNegative === 'function' ? callbackNegative : () => { };
        MySwal.fire(
            Object.assign(
                {},
                {
                    title: params.title,
                    text: params.text,
                    icon: params.icon,
                    showCancelButton: params.showCancelButton,
                    confirmButtonText: params.confirmButtonText,
                    confirmButtonColor: params.confirmButtonColor || '#d33',
                    cancelButtonColor: params.cancelButtonColor || '#aaa',
                    cancelButtonText: params.cancelButtonText
                },
                params
            )
        ).then((result) => {
            if (result.value) {
                callback();
            } else {
                callbackNegative();
            }
        })
    }

    confirmRemove(callback, settings) {
        this.confirm(
            {
                title: this.props.t(`common.confirm.${settings ? settings.word : 'remove'}.title`),
                text: this.props.t(`common.confirm.${settings ? settings.word : 'remove'}.text`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.props.t(`common.confirm.${settings ? settings.word : 'remove'}.confirmButtonText`),
                cancelButtonText: this.props.t(`common.confirm.${settings ? settings.word : 'remove'}.cancelButtonText`)
            },
            () => {
                callback();
            }
        )
    }

    spliceNoMutate(arr, indexToRemove) {
        return arr.slice(0, indexToRemove).concat(arr.slice(indexToRemove + 1));
    }

    getInvalidForms() {
        const forms = document.getElementsByTagName('form');
        let invForms = [];
        let invElements = [];
        for (let i = 0; i < forms.length; i++) {
            const form = forms[i];
            const elements = Array.from(form.elements).filter(tag => ["select", "textarea", "input"].includes(tag.tagName.toLowerCase()));
            let isFormInvalid = false;
            for (let j = 0; j < elements.length; j++) {
                if (elements[j].checkValidity() === false) {
                    invElements.push(elements[j])
                    isFormInvalid = true;
                }
            }
            if (isFormInvalid) {
                invForms.push(forms[i]);
            }
        }
        return {
            forms: invForms,
            inputs: invElements,
        };
    }

    validateForms(callbackPositive, callbackNegative) {
        this.setState({ __validated: false }, () => {
            const invalidFormsAndElements = this.getInvalidForms();
            const invalidInputs = invalidFormsAndElements.inputs;
            const invalidForms = invalidFormsAndElements.forms;

            let invalidTab = null;
            let invalidTab2 = null;

            for (let i = 0; i < invalidForms.length; i++) {
                if (invalidForms[i]?.dataset?.tab) {
                    invalidTab = invalidForms[i]?.dataset?.tab
                }
            }

            for (let i = 0; i < invalidInputs.length; i++) {
                if (invalidInputs[i]?.dataset?.tab2) {
                    invalidTab2 = invalidInputs[i]?.dataset?.tab2
                }
            }

            if (invalidForms.length > 0) {
                return this.setState({ __validated: true }, () => {
                    callbackNegative(invalidTab, invalidForms, invalidInputs, invalidTab2);
                });
            } else if (invalidInputs.length > 0) {
                callbackNegative(invalidTab, invalidForms, invalidInputs, invalidTab2);
            } else {
                return this.setState({ __validated: true }, () => {
                    callbackPositive();
                });
            }
        })
    }

    getConf(cfg, key, defaultValue) {
        if (cfg && cfg[key]) {
            return cfg[key] || defaultValue
        }
        return defaultValue
    }
}

export default BaseComponent;