import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            height={60.890751}
            viewBox="0 0 16.110678 16.110678"
            width={60.890751}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                transform="translate(-35.908 -280.464)"
                fillOpacity={1}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={4}
                strokeDasharray="none"
                strokeDashoffset={0}
                strokeOpacity={1}
            >
                <circle
                    cx={43.963356}
                    cy={288.51913}
                    r={8.0553389}
                    opacity={1}
                    fill="#8bc34a"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <path
                    d="M38.496 282.614a8.055 8.055 0 00-2.588 5.905 8.055 8.055 0 008.055 8.055 8.055 8.055 0 007.005-4.087 8.055 8.055 0 01-.46.404 8.055 8.055 0 01-.656.469 8.055 8.055 0 01-.698.4 8.055 8.055 0 01-.735.33 8.055 8.055 0 01-.764.253 8.055 8.055 0 01-.786.177 8.055 8.055 0 01-.8.097 8.055 8.055 0 01-.569.02 8.055 8.055 0 01-.804-.04 8.055 8.055 0 01-.796-.12 8.055 8.055 0 01-.78-.2 8.055 8.055 0 01-.757-.276 8.055 8.055 0 01-.725-.35 8.055 8.055 0 01-.686-.421 8.055 8.055 0 01-.641-.487 8.055 8.055 0 01-.59-.55 8.055 8.055 0 01-.53-.604 8.055 8.055 0 01-.47-.655 8.055 8.055 0 01-.4-.699 8.055 8.055 0 01-.329-.735 8.055 8.055 0 01-.254-.764 8.055 8.055 0 01-.176-.785 8.055 8.055 0 01-.097-.8 8.055 8.055 0 01-.02-.57 8.055 8.055 0 01.04-.804 8.055 8.055 0 01.12-.796 8.055 8.055 0 01.2-.78 8.055 8.055 0 01.276-.757 8.055 8.055 0 01.35-.725 8.055 8.055 0 01.065-.105z"
                    opacity={1}
                    fill="#00c100"
                    stroke="none"
                    strokeWidth={0.01055691}
                />
                <circle
                    cx={38.717545}
                    cy={289.37396}
                    r={2.3521585}
                    opacity={1}
                    fill="#e91e63"
                    stroke="none"
                    strokeWidth={0.02315548}
                />
                <circle
                    cx={49.207874}
                    cy={289.37396}
                    r={2.3521585}
                    opacity={1}
                    fill="#e91e63"
                    stroke="none"
                    strokeWidth={0.02315548}
                />
                <path
                    d="M39.69 289.784s.367 4.16 4.159 4.16M48.008 289.784s-.367 4.16-4.16 4.16"
                    opacity={1}
                    fill="none"
                    stroke="#1a1a1a"
                    strokeWidth={1.0583334}
                />
                <ellipse
                    cx={46.922508}
                    cy={285.29636}
                    rx={1.8936776}
                    ry={1.8663778}
                    opacity={1}
                    fill="#fff"
                    stroke="none"
                    strokeWidth={0.81171393}
                />
                <ellipse
                    cx={41.142803}
                    cy={283.82639}
                    rx={1.8936776}
                    ry={1.8663778}
                    opacity={1}
                    fill="#fff"
                    stroke="none"
                    strokeWidth={0.81171393}
                />
                <ellipse
                    cx={40.474628}
                    cy={282.75729}
                    rx={0.70726985}
                    ry={0.69707364}
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
                <ellipse
                    cx={48.024994}
                    cy={285.96454}
                    rx={0.70726985}
                    ry={0.69707364}
                    opacity={1}
                    fill="#1a1a1a"
                    stroke="none"
                    strokeWidth={0.3031671}
                />
            </g>
        </svg>
    )
}

export default SvgComponent
