import React, { Fragment } from 'react';
import { Row, Col, Card, Tabs, Tab, Form, Button, Nav, InputGroup } from "react-bootstrap";
import LowerComponent from "../../components/component/lower-elements";
import Icon from '../../components/icon';
import ActionSalesLeader from './action-sales-leader';
import FilesTable from './files-table';
import FilesUpload from './files-upload';
import Signature from './signature';
import { companyTypes, preferredCooperationModels } from '../../constants';

class PageCompanyTabs extends LowerComponent {

    searchCompany() {
        this.props.api.post(
            `/gate/company-search`,
            {
                "company": {
                    "taxNumber": this.props.upperState.taxNumber
                }
            },
            (data) => {
                data = data[0];
                this.props.setUpperState({
                    taxNumber: data.taxNumber,
                    identNumber: data.identNumber,
                    name: data.name,
                    postalCode: data.zipCode,
                    cityName: data.city,
                    streetAddress: data.street,
                    streetNumber: data.propertyNumber,
                    apartmentNumber: data.apartmentNumber,
                    provinceName: data.province?.toLowerCase(),
                    loading: false,
                    autoFilled: true,
                    alert: ''
                });
                if (data.canDeepSearch) {
                    this.searchDeepCompany();
                }
            },
            (errorObject) => {
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        )
    }

    searchDeepCompany() {
        this.props.api.post(
            `/gate/company-deep-search`,
            {
                "company": {
                    "taxNumber": this.props.upperState.taxNumber
                }
            },
            (data) => {
                this.props.setUpperState({
                    courtName: data.financial.length ? data.financial[0].sad : "",
                    courtNumber: data.court.numery?.krs,
                    capitalValue: data.basic.wysokosc_kapitalu_zakladowego?._wartosc?.kwota + data.basic.wysokosc_kapitalu_zakladowego?._wartosc?.waluta
                });
            },
            (errorObject) => {
                this.props.showToast({
                    errorObject: { ...errorObject, data: { error: {} } },
                    title: this.props.t('common.toast.courtDataNotFound'),
                    color: "warning"
                });
            }
        )
    }

    render() {

        const showSpecialActions = false;

        return (
            <Fragment>
                <Form method="post" onSubmit={(e) => { e.preventDefault(); this.props.saveData(this.props.upperState) }}>
                    <Card>
                        <Card.Body>
                            <Tabs defaultActiveKey="metric" className="border-bottom nicebar">
                                <Tab eventKey="metric" title={this.props.t('page.company.component.basicData.title')}>
                                    <Row className="pt-20">

                                        <Col>

                                            <Form.Row>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{this.props.t('page.company.component.basicData.name')}</Form.Label>
                                                        <Form.Control
                                                            disabled={this.props.upperState.isLoading}
                                                            required
                                                            type="text"
                                                            placeholder={this.props.t('page.company.component.basicData.placeholder.name')}
                                                            value={this.props.upperState.name || ""}
                                                            onChange={(e) => { this.onChangeSetUpperState('name', e) }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    {
                                                        (this.props.isRegion(['POLAND'])) ? (
                                                            <Form.Group>
                                                                <Form.Label>{this.props.t('page.company.component.basicData.type')}</Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    value={this.props.upperState.type || ""}
                                                                    onChange={(e) => { this.onChangeSetUpperState('type', e) }}
                                                                >
                                                                    <option value="">{this.props.t(`common.value.empty`)}</option>
                                                                    {companyTypes.map(model => {
                                                                        return <option key={model} value={model}>{model}</option>
                                                                    })}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        ) : (
                                                            <Form.Group>
                                                                <Form.Label>{this.props.t('page.company.component.basicData.type')}</Form.Label>
                                                                <Form.Control
                                                                    disabled={this.props.upperState.isLoading}
                                                                    required
                                                                    type="text"
                                                                    placeholder={this.props.t('page.company.component.basicData.placeholder.type')}
                                                                    value={this.props.upperState.type || ""}
                                                                    onChange={(e) => { this.onChangeSetUpperState('type', e) }}
                                                                />
                                                            </Form.Group>
                                                        )
                                                    }
                                                </Col>
                                            </Form.Row>

                                            <hr />

                                            <Form.Row>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{this.props.t('page.company.component.basicData.taxNumber')}</Form.Label>
                                                        <InputGroup>
                                                            <Form.Control
                                                                disabled={this.props.upperState.isLoading}
                                                                required
                                                                type="text"
                                                                placeholder={this.props.t('page.company.component.basicData.placeholder.taxNumber')}
                                                                value={this.props.upperState.taxNumber || ""}
                                                                onChange={(e) => { this.onChangeSetUpperState('taxNumber', e) }}
                                                            />
                                                            <InputGroup.Append>
                                                                <Button variant="outline-secondary" onClick={(e) => { this.searchCompany() }}>
                                                                    <Icon name="search" />
                                                                </Button>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                {
                                                    (this.props.isRegion(['POLAND'])) && (
                                                        <>
                                                            <Col md={4}>
                                                                <Form.Group>
                                                                    <Form.Label>{this.props.t('page.company.component.basicData.identNumber')}</Form.Label>
                                                                    <Form.Control
                                                                        disabled={this.props.upperState.isLoading}
                                                                        required
                                                                        type="text"
                                                                        placeholder={this.props.t('page.company.component.basicData.placeholder.identNumber')}
                                                                        value={this.props.upperState.identNumber || ""}
                                                                        onChange={(e) => { this.onChangeSetUpperState('identNumber', e) }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={4}>
                                                                <Form.Group>
                                                                    <Form.Label>{this.props.t('page.company.component.basicData.courtNumber')}</Form.Label>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            disabled={this.props.upperState.isLoading}
                                                                            required
                                                                            type="text"
                                                                            placeholder={this.props.t('page.company.component.basicData.placeholder.courtNumber')}
                                                                            value={this.props.upperState.courtNumber || ""}
                                                                            onChange={(e) => { this.onChangeSetUpperState('courtNumber', e) }}
                                                                        />
                                                                    </InputGroup>
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                    )
                                                }
                                            </Form.Row>

                                            <hr />

                                            <Form.Row>
                                                <Col md={4}>
                                                    <Form.Group controlId="provinceName">
                                                        <Form.Label>{this.props.t('page.company.component.basicData.provinceName')}</Form.Label>
                                                        <Form.Control
                                                            disabled={this.props.upperState.isLoading}
                                                            required as="select"
                                                            value={this.props.upperState.provinceName || ""}
                                                            onChange={(e) => { this.onChangeSetUpperState('provinceName', e) }}
                                                        >
                                                            <option value="" disabled>{this.props.t('page.company.component.basicData.placeholder.provinceName')}</option>
                                                            {
                                                                this.props.utils.provincesOf(this.props.region).map(
                                                                    province => {
                                                                        return <option key={province} value={province.toLowerCase()}>{province}</option>
                                                                    }
                                                                )
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{this.props.t('page.company.component.basicData.postalCode')}</Form.Label>
                                                        <Form.Control
                                                            disabled={this.props.upperState.isLoading}
                                                            required
                                                            type="text"
                                                            placeholder={this.props.t('page.company.component.basicData.placeholder.postalCode')}
                                                            value={this.props.upperState.postalCode || ""}
                                                            onChange={(e) => { this.onChangeSetUpperState('postalCode', e) }} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{this.props.t('page.company.component.basicData.cityName')}</Form.Label>
                                                        <Form.Control
                                                            disabled={this.props.upperState.isLoading}
                                                            required
                                                            type="text"
                                                            placeholder={this.props.t('page.company.component.basicData.placeholder.cityName')}
                                                            value={this.props.upperState.cityName || ""}
                                                            onChange={(e) => { this.onChangeSetUpperState('cityName', e) }} />
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>{this.props.t('page.company.component.basicData.streetAddress')}</Form.Label>
                                                        <Form.Control
                                                            disabled={this.props.upperState.isLoading}
                                                            required
                                                            type="text"
                                                            placeholder={this.props.t('page.company.component.basicData.placeholder.streetAddress')}
                                                            value={this.props.upperState.streetAddress || ""}
                                                            onChange={(e) => { this.onChangeSetUpperState('streetAddress', e) }} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Group>
                                                        <Form.Label>{this.props.t('page.company.component.basicData.streetNumber')}</Form.Label>
                                                        <Form.Control
                                                            disabled={this.props.upperState.isLoading}
                                                            required
                                                            type="text"
                                                            placeholder={this.props.t('page.company.component.basicData.placeholder.streetNumber')}
                                                            value={this.props.upperState.streetNumber || ""}
                                                            onChange={(e) => { this.onChangeSetUpperState('streetNumber', e) }} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{this.props.t('page.company.component.basicData.apartmentNumber')}</Form.Label>
                                                        <Form.Control
                                                            disabled={this.props.upperState.isLoading}
                                                            type="text"
                                                            placeholder={this.props.t('page.company.component.basicData.placeholder.apartmentNumber')}
                                                            value={this.props.upperState.apartmentNumber || ""}
                                                            onChange={(e) => { this.onChangeSetUpperState('apartmentNumber', e) }} />
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>
                                                {
                                                    (this.props.isRegion(['POLAND'])) ? (
                                                        <>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{this.props.t('page.company.component.basicData.courtName')}</Form.Label>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            disabled={this.props.upperState.isLoading}
                                                                            required
                                                                            type="text"
                                                                            placeholder={this.props.t('page.company.component.basicData.placeholder.courtName')}
                                                                            value={this.props.upperState.courtName || ""}
                                                                            onChange={(e) => { this.onChangeSetUpperState('courtName', e) }}
                                                                        />
                                                                    </InputGroup>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>{this.props.t('page.company.component.basicData.capitalValue')}</Form.Label>
                                                                    <Form.Control
                                                                        disabled={this.props.upperState.isLoading}
                                                                        required
                                                                        type="text"
                                                                        placeholder={this.props.t('page.company.component.basicData.placeholder.capitalValue')}
                                                                        value={this.props.upperState.capitalValue || ""}
                                                                        onChange={(e) => { this.onChangeSetUpperState('capitalValue', e) }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={3}>
                                                                <Form.Label>{this.props.t('page.company.component.basicData.representative')}</Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    value={this.props.upperState.representativeUuid || ""}
                                                                    onChange={(e) => { this.onChangeSetUpperState('representativeUuid', e) }}
                                                                    disabled={this.props.upperState.loading || !this.props.upperState.users.length}>
                                                                    <option value="" disabled>{this.props.t('page.company.component.basicData.placeholder.representative')}</option>
                                                                    {
                                                                        this.props.upperState.users.map(
                                                                            user => {
                                                                                return <option key={user.uuid} value={user.uuid}>{`${user.firstName} ${user.lastName}`}</option>
                                                                            }
                                                                        )
                                                                    }
                                                                </Form.Control>
                                                            </Col>
                                                        </>
                                                    ) : (
                                                        <Col md={6}>
                                                            <Form.Label>{this.props.t('page.company.component.basicData.representative')}</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                value={this.props.upperState.representativeUuid || ""}
                                                                onChange={(e) => { this.onChangeSetUpperState('representativeUuid', e) }}
                                                                disabled={this.props.upperState.loading || !this.props.upperState.users.length}>
                                                                <option value="" disabled>{this.props.t('page.company.component.basicData.placeholder.representative')}</option>
                                                                {
                                                                    this.props.upperState.users.map(
                                                                        user => {
                                                                            return <option key={user.uuid} value={user.uuid}>{`${user.firstName} ${user.lastName}`}</option>
                                                                        }
                                                                    )
                                                                }
                                                            </Form.Control>
                                                        </Col>
                                                    )
                                                }
                                            </Form.Row>

                                        </Col>
                                    </Row>
                                </Tab>

                                <Tab eventKey="files" title={this.props.t('page.company.component.files.title')} disabled={!this.props.upperState.uuid}>
                                    <Row className="pt-20">
                                        <Col className="px-5">
                                            <FilesTable
                                                t={this.props.t}
                                                api={this.props.api}
                                                uuid={this.props.upperState.uuid}
                                                files={this.props.upperState.files}
                                                isRegion={this.props.isRegion}
                                                isLoading={this.props.isLoading}
                                                hasPermission={this.props.hasPermission}
                                                getUserConfig={this.props.getUserConfig}
                                                showToast={this.props.showToast}
                                                confirmRemove={(callback) => { this.confirmRemove(callback) }}
                                                setUpperState={(newState, callback) => { this.setUpperState(newState, callback) }}
                                                handleElementAdd={(elementsKey, uuid, where) => { this.handleElementTrash(elementsKey, uuid, where) }}
                                                handleElementTrash={(elementsKey, uuid, where) => { this.handleElementTrash(elementsKey, uuid, where) }}
                                            />

                                        </Col>
                                    </Row>

                                    <Row className="pt-20">
                                        <Col className="px-5">
                                            <FilesUpload
                                                t={this.props.t}
                                                api={this.props.api}
                                                uuid={this.props.upperState.uuid}
                                                files={this.props.upperState.files}
                                                isRegion={this.props.isRegion}
                                                isLoading={this.props.isLoading}
                                                hasPermission={this.props.hasPermission}
                                                getUserConfig={this.props.getUserConfig}
                                                showToast={this.props.showToast}
                                                confirmRemove={(callback) => { this.confirmRemove(callback) }}
                                                upperState={this.props.upperState}
                                                setUpperState={(newState, callback) => { this.setUpperState(newState, callback) }}
                                                handleElementAdd={(elementsKey, uuid, where) => { this.handleElementTrash(elementsKey, uuid, where) }}
                                                handleElementTrash={(elementsKey, uuid, where) => { this.handleElementTrash(elementsKey, uuid, where) }}
                                            />
                                        </Col>
                                    </Row>
                                </Tab>

                                <Tab eventKey="signature" title={this.props.t('page.company.component.signature.title')} disabled={!this.props.upperState.uuid}>
                                    <Row className="pt-20">
                                        <Col className="px-5">
                                            <Signature
                                                t={this.props.t}
                                                api={this.props.api}
                                                uuid={this.props.upperState.uuid}
                                                files={this.props.upperState.files}
                                                signatureStatuses={this.props.upperState.signatureStatuses}
                                                representativeUuid={this.props.upperState.representativeUuid}
                                                users={this.props.upperState.users}
                                                isRegion={this.props.isRegion}
                                                isLoading={this.props.isLoading}
                                                hasPermission={this.props.hasPermission}
                                                getUserConfig={this.props.getUserConfig}
                                                showToast={this.props.showToast}
                                                confirmRemove={(callback) => { this.confirmRemove(callback) }}
                                                setUpperState={(newState, callback) => { this.setUpperState(newState, callback) }}
                                                handleElementAdd={(elementsKey, uuid, where) => { this.handleElementTrash(elementsKey, uuid, where) }}
                                                handleElementTrash={(elementsKey, uuid, where) => { this.handleElementTrash(elementsKey, uuid, where) }}
                                                isSalesProcess={false}
                                            />
                                        </Col>
                                    </Row>
                                </Tab>

                                {
                                    showSpecialActions && (
                                        <Tab eventKey="actions" title={this.props.t('page.company.component.actions.title')} disabled={!this.props.upperState.uuid}>
                                            <Row className="pt-20">
                                                <Col>
                                                    <Tab.Container id="actions" defaultActiveKey="salesLeader">
                                                        <Row>
                                                            <Col xs={12} sm={12} md={3}>
                                                                <Nav className="flex-column mb-30">
                                                                    <Nav.Item>
                                                                        <Nav.Link eventKey="salesLeader" className="pl-0">
                                                                            <Icon name="user" />
                                                                            <span>{this.props.t('page.company.component.actions.salesLeader.title')}</span>
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                </Nav>
                                                            </Col>
                                                            <Col xs={12} sm={12} md={9}>
                                                                <Tab.Content>
                                                                    <Tab.Pane eventKey="salesLeader" className="pt-10">
                                                                        <h3>{this.props.t('page.company.component.actions.salesLeader.name')}</h3>
                                                                        <ActionSalesLeader
                                                                            {...this.props}
                                                                            history={this.props.upperState.history}
                                                                            setUpperState={(data) => { this.props.setUpperState(data) }}
                                                                        />
                                                                    </Tab.Pane>
                                                                </Tab.Content>
                                                            </Col>
                                                        </Row>
                                                    </Tab.Container>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    )
                                }

                            </Tabs>
                        </Card.Body>
                    </Card>
                    <Row className="mb-30">
                        <Col sm={12}>
                            <div className="pt-30">
                                <Button
                                    variant="success"
                                    size="lg"

                                    disabled={this.props.upperState.isLoading}
                                    onClick={(e) => { this.props.saveData() }}
                                >
                                    <Icon name="save" />
                                    <span className="pl-10">
                                        {this.props.t('page.company.component.options.saveData')}
                                    </span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form >
            </Fragment >
        );
    }
}

export default PageCompanyTabs
