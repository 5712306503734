import React, { Fragment } from 'react';
import { Row, Col, Form, Card, Modal, Carousel, Button } from "react-bootstrap";
import { AlertTriangle } from "react-feather";
import LowerElementsComponent from '../../components/component/lower-elements';
import Icon from '../../components/icon';
import Designer from '../../components/maps/designer';

import img0 from '../../assets/images/roof/mono.jpg';
import img1 from '../../assets/images/roof/gable.jpg';
import img2 from '../../assets/images/roof/multi.jpg';
import { appEnv, appEnvCurrent } from '../../constants';

class Input extends LowerElementsComponent {

    constructor(props) {
        super(props);
        this.state = {
            modalPvDesigner: false,
            modalHelp: false
        }
        this.images = [
            { lines: 2, title: props.t('page.calculation.input.modalHelp.mono'), src: img0 },
            { lines: 2, title: props.t('page.calculation.input.modalHelp.gable'), src: img1 },
            { lines: 3, title: props.t('page.calculation.input.modalHelp.multi'), src: img2 }
        ]
    }

    render() {

        const {
            closed,
            isLoading,
            uuid,
            roofArea,
            roofTypes,
            roofType,
            pvArea,
            pvAmount,
            ridgeLength,
            eavesLength,
            vergeLength,
            valleyLength,
            pvPower,
            pvPowers,
            assumedPvPower,
            offeredPvPower,
            offeredPvPowerError,
            neededPvPower,
            passiveAreaExtra,
            passiveAreaAbsolute,
            passiveAreaAbsoluteError,
            pvDesign,

            distInverterWlz,
            distGeneratorInverter,
            pvHorizontal,
            pvVertical,
        } = this.props;

        const disableBecausePvDesign = pvDesign && pvDesign.length > 0;

        return (
            <Fragment>

                <h2>
                    {this.props.t('page.calculation.input.title')}

                    <div className="float-md-right clear-section-container">

                        {
                            this.props.hasPermission(['VIEW_OFFER_CLEAR_SECTION_BUTTON']).any && (
                                <button
                                    type="button"
                                    className="btn text-danger px-0"
                                    disabled={isLoading || closed}
                                    onClick={(e) => { this.props.clearSection(e, ['input']) }}
                                >
                                    {this.props.t(`common.button.clear`)}
                                </button>
                            )
                        }

                    </div>
                </h2>

                <Modal
                    show={this.state.modalPvDesigner}
                    onHide={() => { this.setState({ modalPvDesigner: false }) }}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.calculation.input.modalPvDesigner.title')}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="modal-pv-designer p-0" id="tooltabs-tabpane-/tab-designer">
                        <Designer
                            enableDesigner={true}
                            defaultZoom={20}
                            fullAddress={this.props.fullAddress}
                            defaultCenter={this.props.mapCenter}
                            showToast={this.props.showToast}
                            markers={this.props.mapMarkers}
                            layers={['sketch']}
                            t={this.props.t}
                            api={this.props.api}
                            utils={this.props.utils}
                            setUpperState={this.props.setUpperState}
                            pvWidth={this.props.pvWidth}
                            pvHeight={this.props.pvHeight}
                            pvThick={this.props.pvThick}
                            pvPower={this.props.pvPower}
                            bipvArea={this.props.bipvArea}
                            passiveArea={this.props.passiveArea}
                            passiveAreaExtra={this.props.passiveAreaExtra}
                            pvPowers={this.props.pvPowers}
                            pvDesign={this.props.pvDesign}
                            roofType={this.props.roofType}
                            roofTypes={this.props.roofTypes}
                            roofPitch={this.props.roofPitch}
                            roofOrientation={this.props.roofOrientation}
                            realAnnualAssumedEnergyProductionKwH={this.props.realAnnualAssumedEnergyProductionKwH}
                            reCalcFinancing={this.props.reCalcFinancing}
                            isLoading={this.props.isLoading}
                            isDisabled={this.props.closed}
                            isRegion={this.props.isRegion}
                            reFetchTokens={this.props.reFetchTokens}
                            tokenRot={this.props.tokenRot}
                            tokenGronTeknik={this.props.tokenGronTeknik}
                            hipLength={this.props.hipLength}
                            pvHorizontal={this.props.pvHorizontal}
                            pvVertical={this.props.pvVertical}
                        />
                    </Modal.Body>

                </Modal>

                <Modal
                    show={this.state.modalHelp}
                    onHide={() => { this.setState({ modalHelp: false }) }}
                    backdrop="static"
                    keyboard={false}
                    size="md"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.calculation.input.modalHelp.title')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-offer">
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <p className="mb-10">
                                    {this.props.t('page.calculation.input.modalHelp.line0')}
                                </p>
                                <p className="mb-10">
                                    {this.props.t('page.calculation.input.modalHelp.line1')}
                                </p>
                                <ul className="mb-15">
                                    <li>{this.props.t('page.calculation.input.modalHelp.li0')}</li>
                                    <li>{this.props.t('page.calculation.input.modalHelp.li1')}</li>
                                </ul>
                                <p className="mb-10">
                                    {this.props.t('page.calculation.input.modalHelp.line3')}
                                </p>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <Carousel>
                                    {
                                        this.images.map((img, index) => {
                                            return (
                                                <Carousel.Item key={index}>
                                                    <div>
                                                        <p className="fs-22 mb-5 strong">{img.title}</p>
                                                        <span className="fs-16 mr-15" style={{ borderBottom: '10px solid #f3ce01', whiteSpace: 'nowrap' }}>
                                                            {this.props.t('page.calculation.input.modalHelp.eaves')}
                                                        </span>
                                                        <span className="fs-16 mr-15" style={{ borderBottom: '10px solid #f34901', whiteSpace: 'nowrap' }}>
                                                            {this.props.t('page.calculation.input.modalHelp.mainRidge')}
                                                        </span>
                                                        {img.lines === 3 && (
                                                            <>
                                                                <span className="fs-16" style={{ borderBottom: '10px solid #ff8f01', whiteSpace: 'nowrap' }}>
                                                                    {this.props.t('page.calculation.input.modalHelp.cornerRidge')}
                                                                </span>
                                                            </>
                                                        )}
                                                        <img
                                                            className="d-block w-100 mt-20"
                                                            src={img.src}
                                                            alt=""
                                                        />
                                                    </div>

                                                </Carousel.Item>
                                            )
                                        })
                                    }
                                </Carousel>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>

                <Row>
                    <Col sm={12}>
                        <Card className="offer-inputs">
                            <Card.Header className="px-20 py-10 background-white">

                                {
                                    this.props.hasPermission(['VIEW_OFFER_PV_DESIGN_BUTTON']).any && (
                                        <button
                                            type="button"
                                            className="btn btn-uniform text-success"
                                            onClick={(e) => { this.setState({ modalPvDesigner: true }) }}
                                            disabled={isLoading || closed}
                                        >
                                            <Icon name="star" />
                                            <span className="pl-10">
                                                {this.props.t('page.calculation.input.pvDesigner')}
                                            </span>
                                        </button>
                                    )
                                }

                                <button
                                    type="button"
                                    className="btn btn-uniform text-info"
                                    onClick={(e) => { this.setState({ modalHelp: true }) }}
                                    disabled={isLoading || closed}
                                >
                                    <Icon name="help-circle" />
                                    <span className="pl-10">
                                        {this.props.t('page.calculation.input.help')}
                                    </span>
                                </button>
                            </Card.Header>
                            <Card.Body>
                                {
                                    (uuid || isLoading) || !this.props.hasPermission(['VIEW_PV_DESIGN_BUTTON_REQUEST']).any ?
                                        (
                                            <></>
                                        )
                                        :
                                        (
                                            <Row>
                                                <Col md={12}>
                                                    <Form.Check
                                                        disabled={isLoading || closed}
                                                        type="switch"
                                                        id="pvDesignRequest"
                                                        label={this.props.t('page.calculation.input.pvDesignRequest')}
                                                        checked={this.props.pvDesignRequest || false}
                                                        onChange={(e) => { this.setUpperState({ pvDesignRequest: !this.props.pvDesignRequest }) }}
                                                        className="mt-0 mb-20"
                                                    />
                                                </Col>
                                            </Row>
                                        )
                                }
                                {
                                    this.props.isRegion(['POLAND', 'USA']) ? (
                                        <Row>
                                            <Col md={12}>
                                                <Form.Check
                                                    disabled={isLoading || disableBecausePvDesign || closed}
                                                    type="switch"
                                                    id="calculateAssumedPvPower"
                                                    label={this.props.t('page.calculation.input.calculateAssumedPvPower')}
                                                    checked={this.props.calculateAssumedPvPower || false}
                                                    onChange={(e) => { this.setUpperState({ calculateAssumedPvPower: !this.props.calculateAssumedPvPower }) }}
                                                    className="mt-0 mb-20"
                                                />
                                            </Col>
                                        </Row>
                                    ) : (
                                        <></>
                                    )
                                }
                                <Row>
                                    {
                                        this.props.isRegion(['POLAND', 'GERMANY', 'USA']) ? (
                                            <>
                                                <Col sm={12} md={6} lg={6} xl={4}>
                                                    <Form.Group className="required">
                                                        <Form.Label>
                                                            {this.props.t('page.calculation.input.roofArea')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                            required={true}
                                                            disabled={isLoading || disableBecausePvDesign || closed}
                                                            type="number"
                                                            step="0.01"
                                                            value={roofArea || ""}
                                                            placeholder={`...`}
                                                            onChange={
                                                                (evt) => {
                                                                    this.props.setUpperState(
                                                                        { roofArea: this.valFloat(evt) }
                                                                    )
                                                                }
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} md={6} lg={6} xl={4}>
                                                    <Form.Group className="required">
                                                        <Form.Label>
                                                            {
                                                                roofType === "multi"
                                                                    ?
                                                                    this.props.t('page.calculation.input.ridgeAndEdgeLength')
                                                                    :
                                                                    this.props.t('page.calculation.input.ridgeLength')
                                                            }
                                                        </Form.Label>
                                                        <Form.Control
                                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                            required={true}
                                                            disabled={isLoading || disableBecausePvDesign || closed}
                                                            type="number"
                                                            step="0.01"
                                                            value={ridgeLength || ""}
                                                            placeholder={`...`}
                                                            onChange={
                                                                (evt) => {
                                                                    this.props.setUpperState(
                                                                        {
                                                                            ridgeLength: this.valFloat(evt)
                                                                        }
                                                                    )
                                                                }
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} md={6} lg={6} xl={4}>
                                                    <Form.Group className="required">
                                                        <Form.Label>
                                                            {this.props.t('page.calculation.input.eavesLength')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                            required={true}
                                                            disabled={isLoading || disableBecausePvDesign || closed}
                                                            type="number"
                                                            step="0.01"
                                                            value={eavesLength || ""}
                                                            placeholder={`...`}
                                                            onChange={
                                                                (evt) => {
                                                                    this.props.setUpperState(
                                                                        {
                                                                            eavesLength: this.valFloat(evt)
                                                                        }
                                                                    )
                                                                }
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                        ) : (
                                            <>
                                                <Col sm={12} md={6} lg={6} xl={12}>
                                                    <Form.Group className="required">
                                                        <Form.Label>
                                                            {this.props.t('page.calculation.input.roofArea')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                            required={true}
                                                            disabled={isLoading || disableBecausePvDesign || closed}
                                                            type="number"
                                                            step="0.01"
                                                            value={roofArea || ""}
                                                            placeholder={`...`}
                                                            onChange={
                                                                (evt) => {
                                                                    this.props.setUpperState(
                                                                        { roofArea: this.valFloat(evt) }
                                                                    )
                                                                }
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                        )
                                    }
                                    <Col sm={12} md={6} lg={6} xl={4}>
                                        <Form.Group>
                                            <Form.Label>
                                                {this.props.t('page.calculation.input.assumedPvPower')}
                                            </Form.Label>
                                            <Form.Control
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={false}
                                                disabled={isLoading || disableBecausePvDesign || closed}
                                                readOnly={this.props.calculateAssumedPvPower}
                                                placeholder="..."
                                                type="number"
                                                step="0.01"
                                                value={assumedPvPower || ""}
                                                onChange={
                                                    (evt) => {
                                                        this.props.setUpperState(
                                                            { assumedPvPower: this.valFloat(evt) }
                                                        )
                                                    }
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} xl={4}>
                                        <Form.Group className="required">
                                            <Form.Label>
                                                {this.props.t('page.calculation.input.offeredPvPower')}
                                                {` `}
                                                {
                                                    offeredPvPowerError
                                                        ?
                                                        (
                                                            <i className="text-danger">
                                                                <AlertTriangle size={12} />
                                                            </i>
                                                        )
                                                        :
                                                        (
                                                            <></>
                                                        )
                                                }
                                            </Form.Label>
                                            <Form.Control
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={false}
                                                disabled={isLoading || disableBecausePvDesign || closed}
                                                readOnly={true}
                                                type="number"
                                                step="0.01"
                                                value={offeredPvPower}
                                                onChange={
                                                    (evt) => {
                                                        this.props.setUpperState(
                                                            { offeredPvPower: this.valFloat(evt) }
                                                        )
                                                    }
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} xl={4}>
                                        <Form.Group className="required">
                                            <Form.Label>
                                                {this.props.t('page.calculation.input.neededPvPower')}
                                            </Form.Label>
                                            <Form.Control
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={true}
                                                disabled={isLoading || disableBecausePvDesign || closed}
                                                readOnly={true}
                                                type="number"
                                                step="0.01"
                                                value={neededPvPower}
                                                onChange={
                                                    (evt) => {
                                                        this.props.setUpperState(
                                                            { neededPvPower: this.valFloat(evt) }
                                                        )
                                                    }
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} xl={4}>
                                        <Form.Group className="required">
                                            <Form.Label>
                                                {this.props.t('page.calculation.input.roofTypeLabel')}
                                            </Form.Label>
                                            <Form.Control
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={true}
                                                disabled={isLoading || disableBecausePvDesign || closed}
                                                as="select"
                                                custom
                                                value={roofType}
                                                onChange={
                                                    (evt) => {
                                                        this.props.setUpperState(
                                                            { roofType: this.val(evt) }
                                                        )
                                                    }
                                                }
                                            >
                                                {
                                                    roofTypes.map(rt => (
                                                        <option key={`rt-${rt}`} value={rt}>
                                                            {rt}
                                                        </option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} xl={4}>
                                        <Form.Group>
                                            <Form.Label>
                                                {this.props.t('page.calculation.input.pvPower')}
                                            </Form.Label>
                                            <Form.Control
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={false}
                                                disabled={isLoading || disableBecausePvDesign || closed}
                                                as="select"
                                                custom
                                                value={pvPower ? pvPower.toString() : ''}
                                                data-value={pvPower ? pvPower.toString() : ''}
                                                onChange={
                                                    (evt) => {
                                                        this.props.setUpperState(
                                                            { pvPower: this.parseFloatFixed(this.val(evt)) }
                                                        )
                                                    }
                                                }
                                            >
                                                {
                                                    pvPowers.map(pwr => (
                                                        <option key={`pwr-${pwr}`} value={pwr.toString()}>
                                                            {pwr}
                                                        </option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} xl={4}>
                                        <Form.Group>
                                            <Form.Label>
                                                {this.props.t('page.calculation.input.pvAmount')}
                                            </Form.Label>
                                            <Form.Control
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={false}
                                                disabled={isLoading || disableBecausePvDesign || closed}
                                                readOnly={true}
                                                type="number"
                                                step="0.01"
                                                value={pvAmount}
                                                onChange={
                                                    (evt) => {
                                                        this.props.setUpperState(
                                                            {
                                                                pvAmount: this.valFloat(evt)
                                                            }
                                                        )
                                                    }
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} xl={4}>
                                        <Form.Group className="required">
                                            <Form.Label>
                                                {this.props.t('page.calculation.input.pvArea')}
                                            </Form.Label>
                                            <Form.Control
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={true}
                                                disabled={isLoading || disableBecausePvDesign || closed}
                                                readOnly={true}
                                                type="number"
                                                step="0.01"
                                                value={pvArea}
                                                onChange={
                                                    (evt) => {
                                                        this.props.setUpperState(
                                                            {
                                                                pvArea: this.valFloat(evt)
                                                            }
                                                        )
                                                    }
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} xl={4}>
                                        <Form.Group>
                                            <Form.Label>
                                                {this.props.t('page.calculation.input.passiveAreaExtra')}
                                            </Form.Label>
                                            <Form.Control
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={false}
                                                disabled={isLoading || disableBecausePvDesign || closed}
                                                type="number"
                                                step="0.01"
                                                value={passiveAreaExtra || ""}
                                                placeholder={`...`}
                                                onChange={
                                                    (evt) => {
                                                        this.props.setUpperState(
                                                            { passiveAreaExtra: this.valFloat(evt) }
                                                        )
                                                    }
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} xl={4}>
                                        <Form.Group className={this.props.isRegion(['POLAND']) ? "" : "required"}>
                                            <Form.Label>
                                                {this.props.t('page.calculation.input.passiveAreaAbsolute')}
                                                {` `}
                                                {
                                                    passiveAreaAbsoluteError
                                                        ?
                                                        (
                                                            <i className="text-danger">
                                                                <AlertTriangle size={12} />
                                                            </i>
                                                        )
                                                        :
                                                        (
                                                            <></>
                                                        )
                                                }
                                                {
                                                    this.props.isRegion(['POLAND']) && (
                                                        <Button
                                                            variant="link"
                                                            disabled={this.props.isLoading || this.props.isDisabled}
                                                            style={{ padding: 0, marginLeft: 5 }}
                                                            onClick={
                                                                (evt) => {
                                                                    this.props.setUpperState(
                                                                        { reCalcPassiveAreaAbsolute: true }
                                                                    )
                                                                }
                                                            }>
                                                            <Icon name="refresh-cw" height={12} />
                                                        </Button>
                                                    )
                                                }
                                            </Form.Label>
                                            <Form.Control
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                disabled={isLoading || disableBecausePvDesign || closed}
                                                required={!this.props.isRegion(['POLAND'])}
                                                readOnly={!this.props.isRegion(['POLAND'])}
                                                type="number"
                                                step="0.01"
                                                value={passiveAreaAbsolute || ""}
                                                placeholder={`...`}
                                                onChange={
                                                    (evt) => {
                                                        this.props.setUpperState(
                                                            { passiveAreaAbsolute: this.valFloat(evt) }
                                                        )
                                                    }
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {
                                    this.props.isRegion(['POLAND']) && (
                                        <Row>
                                            <Col sm={12} md={6} lg={6} xl={4}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        {this.props.t('page.calculation.input.distInverterWlz')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        required={false}
                                                        disabled={isLoading || disableBecausePvDesign || closed}
                                                        type="number"
                                                        step="0.01"
                                                        min="0"
                                                        value={distInverterWlz || ""}
                                                        placeholder={`...`}
                                                        onChange={
                                                            (evt) => {
                                                                this.props.setUpperState(
                                                                    { distInverterWlz: this.valFloat(evt) }
                                                                )
                                                            }
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} md={6} lg={6} xl={4}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        {this.props.t('page.calculation.input.distGeneratorInverter')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        required={false}
                                                        disabled={isLoading || disableBecausePvDesign || closed}
                                                        type="number"
                                                        step="0.01"
                                                        min="0"
                                                        value={distGeneratorInverter || ""}
                                                        placeholder={`...`}
                                                        onChange={
                                                            (evt) => {
                                                                this.props.setUpperState(
                                                                    { distGeneratorInverter: this.valFloat(evt) }
                                                                )
                                                            }
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} md={6} lg={6} xl={4}>
                                                <Form.Group className="required">
                                                    <Form.Label>
                                                        {this.props.t('page.calculation.input.pvOrientation')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        required={true}
                                                        disabled={isLoading || disableBecausePvDesign || closed}
                                                        as="select"
                                                        custom
                                                        value={pvHorizontal ? 'pvHorizontal' : pvVertical ? 'pvVertical' : ''}
                                                        onChange={
                                                            (evt) => {
                                                                this.props.setUpperState(
                                                                    {
                                                                        pvHorizontal: this.val(evt) === 'pvHorizontal',
                                                                        pvVertical: this.val(evt) === 'pvVertical',
                                                                    }
                                                                )
                                                            }
                                                        }
                                                    >
                                                        {
                                                            ['', 'pvVertical', 'pvHorizontal'].map(value => (
                                                                <option key={`pvOrientation-${value}`} value={value}>
                                                                    {this.props.t(`page.calculation.input.${value || 'placeholder'}`)}
                                                                </option>
                                                            ))
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}
export default Input;