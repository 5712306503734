import React, { Fragment } from 'react';
import { Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Link } from 'react-router-dom';
import BaseComponent from '../../components/component/base';
import Icon from '../../components/icon';
import { IsLoading } from '../../components/spinner';
import Widget from '../../components/widget';
import { kanbanTerminal } from '../../constants';

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            itemsall: [],
            itemsopen: [],
            itemsdone: [],
            itemscurrent: [],
            isLoading: true,
            filterStatus: 'open',
            filterSearch: '',
        };
        this.fetchTasks = this.fetchTasks.bind(this);
    }

    componentDidMount() {
        this.fetchTasks();
        window.addEventListener("FETCH_TASKS", this.fetchTasks, false);
    }

    componentWillUnmount() {
        window.removeEventListener('FETCH_TASKS', this.fetchTasks, false);
    }

    fetchTasks() {
        this.setState({ isLoading: true }, () => {
            this.props.api.get(
                `/task/my-list`,
                (data) => {
                    const itemsall = data.tasks.map(task => { return { ...task, searchable: JSON.stringify(task).toLocaleLowerCase() } });
                    const itemsopen = itemsall.filter(itm => itm.task.status !== 'done');
                    const itemsdone = itemsall.filter(itm => kanbanTerminal.includes(itm.task.status?.toLowerCase()));
                    const itemscurrent = [...itemsopen];
                    this.setState({
                        itemsall,
                        itemsopen,
                        itemsdone,
                        itemscurrent,
                        isLoading: false
                    }, () => {
                        this.setFilterStatusAndSearch(this.state.filterStatus);
                    });
                }
            );
        });
    }

    setFilterStatusAndSearch(name) {
        let { filterSearch } = this.state;
        let itemscurrent = [];
        if (name === 'open') {
            itemscurrent = [...this.state.itemsopen];
        } else {
            itemscurrent = [...this.state.itemsdone];
        }
        if (filterSearch) {
            filterSearch = String(filterSearch).toLocaleLowerCase();
            itemscurrent = itemscurrent.filter(item => item.searchable.search(filterSearch) > -1);
        }
        this.setState({ itemscurrent, filterStatus: name, isLoading: true }, () => {
            setTimeout(() => {
                this.setState({ isLoading: false });
            }, 250);
        })
    }

    applyFilters(e) {
        if (e) {
            e.preventDefault();
        }
        this.setFilterStatusAndSearch(this.state.filterStatus);
    }

    render() {
        const { itemscurrent, itemsopen, itemsdone, filterStatus, filterSearch, isLoading } = this.state;
        const isEmpty = itemscurrent.length === 0;

        const loadingView = (
            <IsLoading />
        )

        const emptyView = (
            <li className="list-group-item text-center">
                <Icon name="check-circle" style={{ width: 48, height: 48, color: "#e6ecf0" }} />
            </li>
        )

        const listView = (
            <>
                <div className="rui-project-task-info bg-light border-left border-top border-right px-30 py-15 m-0" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => this.setFilterStatusAndSearch('open')} variant="custom" className={`rui-project-task-info-link border-0 ${filterStatus === 'open' ? 'btn-info' : 'btn-outline-info'}`}>
                        <span className="rui-project-task-info-icon" style={{ color: filterStatus === 'open' ? '#fff' : '#333' }}>
                            <Icon name="alert-circle" />
                            {` `}
                            {this.props.t('page.tasks.content.open')}
                            {` `}
                            {itemsopen.length}
                        </span>
                    </Button>
                    <Button onClick={() => this.setFilterStatusAndSearch('done')} variant="custom" className={`rui-project-task-info-link border-0 ${kanbanTerminal.includes(filterStatus?.toLowerCase()) ? 'btn-success' : 'btn-outline-success'}`}>
                        <span className="rui-project-task-info-icon" style={{ color: filterStatus === 'done' ? '#fff' : '#333' }}>
                            <Icon name="check-circle" />
                            {` `}
                            {this.props.t('page.tasks.content.closed')}
                            {` `}
                            {itemsdone.length}
                        </span>
                    </Button>
                </div>
                <div className="rui-project-task-search bg-light border-left border-top border-right p-15 m-0">
                    <Form method="post" onSubmit={(e) => { this.applyFilters(e) }}>
                        <InputGroup>
                            <InputGroup.Prepend className="pr-5">
                                <Button variant="custom" onClick={(e) => { this.applyFilters(e) }}>
                                    <Icon name="search" />
                                </Button>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                placeholder={this.props.t('common.placeholder.search')}
                                required={true}
                                value={filterSearch}
                                onChange={e => { this.setState({ filterSearch: e.target.value }, () => this.setFilterStatusAndSearch(filterStatus)) }}
                            />
                        </InputGroup>
                    </Form>
                </div>
                {isLoading && loadingView}
                <Widget className="rui-widget-actions" showDropdown={false}>
                    <div className="rui-widget-content">
                        <ul className="list-group list-group-flush rui-widget-list rui-widget-task-list">
                            {isEmpty && emptyView}
                            {
                                itemscurrent.map(item => {
                                    const task = item.task;
                                    const color = kanbanTerminal.includes(task.status?.toLowerCase()) ? 'success' : 'info';
                                    return (
                                        <li className="list-group-item" key={`task-${task.uuid}`}>
                                            <Link className={`rui-task rui-task-${color}`} to={this.props.buildLink(`/task/${task.uuid}/#/`)}>
                                                <div className="rui-task-icon">
                                                    {
                                                        kanbanTerminal.includes(task.status?.toLowerCase()) ? (
                                                            <Icon name="check-circle" />
                                                        ) : (
                                                            <span>{task.progress}%</span>
                                                        )
                                                    }
                                                </div>
                                                <div className="rui-task-content">
                                                    <span className="rui-task-title">
                                                        {task.confirmed ? `` : `❓ `}
                                                        <strong>
                                                            {task.label}
                                                        </strong>
                                                    </span>
                                                    <span className="rui-task-title">
                                                        {item.project.label}
                                                    </span>
                                                    <small className="rui-task-subtitle">
                                                        {task.since} - {task.till}
                                                    </small>
                                                </div>
                                                <div className="rui-task-icon">
                                                    {item.badge.comments > 0 && (
                                                        <>
                                                            <Icon name="message-circle" />
                                                            {` `}
                                                            {item.badge.comments}
                                                        </>
                                                    )}
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </Widget>
            </>
        )

        return listView;
    }
}

export default Content
