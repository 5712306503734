import React from 'react';
import { DateTime } from "luxon";
import ReactTooltip from "react-tooltip";
import Icon from '../../components/icon';
import { ListGroup, Row, Col, Form, Modal, Button, Alert } from 'react-bootstrap';
import BaseComponent from '../../components/component/base';
import getProto from '../../proto';
import Unicorn from '../../components/unicorn';
import { today } from '../../constants';

class Checklist extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            checklist: props.checklist || [],
            checklistDefaults: {},
            checklistPrepared: [],

            withTasks: props.withTasks || false,
            withStatuses: props.withStatuses || false,
            withHistory: props.withHistory || false,
            editable: false,
            canEdit: props.canEdit || false,
            canEditDate: props.canEditDate || false,
            canEditNotes: props.canEditNotes || false,
            noteDateRequired: props.noteDateRequired || false,
            upperBlockers: props.upperBlockers || {},

            showChecklistItemStatusModal: false,
            showChecklistItemDateModal: false,
            showItemHistoryModal: false,

            selectedItem: null,
            selectedItemHistory: [],

            showUnicorn: false,
            unicornBorn: 0,
        }
    }

    componentDidMount() {
        this.fetchConfig();
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild();
    }

    fetchConfig(callback) {
        if (this.props.checklistDefaults) {
            this.setState({ checklistDefaults: this.props.checklistDefaults }, callback);
        } else {
            this.props.api.post(
                `/project/data`,
                {
                    "file": {
                        "names": ["checklist"]
                    }
                },
                (data) => {
                    this.setState({ checklistDefaults: data.checklist }, callback);
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        }
    }

    afterFetchError(errorObject) {
        this.setState({ isLoading: false }, () => {
            this.props.showToast({
                errorObject: errorObject,
                title: this.props.t('common.toast.error'),
                color: 'danger'
            });
        })
    }

    getTaskName(taskuuid, taskObj, tasks) {
        if (!taskuuid && !taskObj) {
            return '';
        }
        const theTask = taskObj || tasks.filter(Boolean).find(taskObj => taskObj.task?.uuid === taskuuid);
        return theTask ? `${theTask.task.label} (${theTask.task.status})` : ``;
    }

    getNewHistoryItem(details) {
        return {
            ...details,
            'who': this.props.getUser().user?.firstName + ' ' + this.props.getUser().user?.lastName,
            'when': DateTime.local().toFormat('yyyy-LL-dd HH:mm:ss'),
            'whouuid': this.props.getUser().user?.uuid,
            'uuid': this.props.utils.getUUID(),
        }
    }

    setItem(itemuuid, fragment, e, explicit, saveHistory, callback, transactionId, forceShowHurray) {
        if (typeof callback !== 'function') {
            callback = () => { }
        }

        transactionId = transactionId || this.props.utils.getUUID();
        let checkAllDoneForParentUuid = null;
        let foundItem = null;
        const newValue = explicit ? e : this.val(e);

        const newChecklist = [...this.props.checklist].map(itm => {
            let newItm = { ...itm };
            if (newItm.uuid === itemuuid) {
                newItm[fragment] = newValue;
                if (fragment == 'done') {
                    newItm['date'] = today;
                    newItm['status'] = 'done';
                }
                if (saveHistory) {
                    checkAllDoneForParentUuid = newItm.parentuuid;
                    if (!Array.isArray(newItm.history)) {
                        newItm.history = [];
                    }
                    newItm.history.push(this.getNewHistoryItem({ changed: fragment, tid: transactionId, [fragment]: newValue }));
                    if (fragment == 'done') {
                        newItm.history.push(this.getNewHistoryItem({ changed: 'date', tid: transactionId, 'date': today }));
                    }

                }
                foundItem = { ...newItm };
            }
            return newItm;
        }) || [];

        let showHurray = false;
        let useFlatStatus = this.props.checklist.find(itm => itm.parentuuid === null && itm.title === '_') ? true : false;

        if (checkAllDoneForParentUuid) {
            let allChildren = newChecklist.filter(itm => itm.parentuuid === checkAllDoneForParentUuid);
            let doneChildren = newChecklist.filter(itm => itm.parentuuid === checkAllDoneForParentUuid && itm.done);
            if (allChildren.length === doneChildren.length) {
                if (fragment === 'done') {
                    showHurray = true;
                }
            }
        }

        this.props.setMasterStatus(useFlatStatus && fragment === 'done' && foundItem.done, foundItem?.title, () => {
            this.props.onChange(newChecklist, () => {
                if (showHurray || forceShowHurray) {
                    this.setState({ showUnicorn: true }, () => {
                        this.setState({ unicornBorn: (new Date()).getTime() / 1000 }, callback)
                    });
                } else {
                    callback();
                }
            });
        });
    }

    removeItem(itemuuid) {
        if (!this.state.canEdit) {
            return;
        }
        let newChecklist = [...this.props.checklist].filter(itm => itm.uuid !== itemuuid && itm.parentuuid !== itemuuid);
        this.props.onChange(newChecklist, () => { });
    }

    addItem(parentuuid) {
        if (!this.state.canEdit) {
            return;
        }
        let newChecklist = [...this.props.checklist, getProto('toDo', { parentuuid: parentuuid })];
        this.props.onChange(newChecklist, () => { });
    }

    itemChangeStatusNotesModal(e, itemuuid, itemtitle, itemstatus, parentitemuuid, parentitemtitle, dateonly) {
        if (e) {
            e.preventDefault();
        }
        if (!this.state.canEditDate && !this.state.canEditNotes) {
            return;
        }
        this.setState({
            showChecklistItemDateModal: false,
            showChecklistItemStatusModal: true,
            selectedItem: {
                uuid: itemuuid,
                title: itemtitle,
                status: itemstatus,
                newStatus: itemstatus,
                newDate: today,
                parentUuid: parentitemuuid,
                parentTitle: parentitemtitle,
            }
        })
    }

    itemChangeStatusDateModal(e, itemuuid, itemtitle, itemstatus, parentitemuuid, parentitemtitle, dateonly) {
        if (e) {
            e.preventDefault();
        }
        if (!this.state.canEditDate && !this.state.canEditNotes) {
            return;
        }
        this.setState({
            showChecklistItemDateModal: true,
            showChecklistItemStatusModal: false,
            selectedItem: {
                uuid: itemuuid,
                title: itemtitle,
                status: itemstatus,
                newStatus: '',
                newDate: today,
                parentUuid: parentitemuuid,
                parentTitle: parentitemtitle,
            }
        })
    }

    itemHistoryModal(e, itemhistory) {
        if (e) {
            e.preventDefault();
        }
        if (!this.state.canEditDate && !this.state.canEditNotes) {
            return;
        }
        this.setState({
            showItemHistoryModal: true,
            selectedItemHistory: itemhistory,
        })
    }

    changeSelectedItemStatus() {
        const {
            selectedItem,
            canEditNotes,
        } = this.state;
        if (!selectedItem || !canEditNotes) {
            return;
        }
        const { uuid, newStatus, newDate } = selectedItem;
        this.setState({
            selectedItem: null,
            showChecklistItemStatusModal: false,
            showChecklistItemDateModal: false,
        }, () => {
            const tID = this.props.utils.getUUID();
            this.setItem(uuid, 'status', newStatus, true, true, () => {
                if (newDate) {
                    this.setItem(uuid, 'date', newDate, true, true, undefined, tID);
                }
            }, tID);

        })
    }

    changeSelectedItemDate() {
        const {
            selectedItem,
            canEditDate,
        } = this.state;
        if (!selectedItem || !canEditDate) {
            return;
        }
        const { uuid, newDate } = selectedItem;
        this.setState({
            selectedItem: null,
            showChecklistItemStatusModal: false,
            showChecklistItemDateModal: false,
        }, () => {
            const tID = this.props.utils.getUUID();
            this.setItem(uuid, 'date', newDate, true, true, undefined, tID);

        })
    }

    getRelatedFilesAndDirs(mainTitle, subTitle, subUuid) {
        const { checklist, checklistDefaults } = this.state;
        let hasRelatedFiles = [];
        let hasRelatedDirs = [];
        let mainSections = Object.keys(checklistDefaults);

        mainSections.forEach(mainSectionTitle => {
            let mainSection = checklistDefaults[mainSectionTitle];
            let subSections = Object.keys(mainSection);
            subSections.forEach(subSectionTitle => {
                let subSection = checklistDefaults[mainSectionTitle][subSectionTitle];
                let subSectionUuid = subSection._uuid;

                if (mainTitle === mainSectionTitle && subTitle === subSectionTitle) {
                    hasRelatedFiles = (subSection.related?.files || []);
                    hasRelatedDirs = (subSection.related?.dirs || []);
                }

                if (subSectionUuid === subUuid) {
                    hasRelatedFiles = subSection.link ? [subSection.link] : [];
                }
            });
        });

        return (
            <span>
                {hasRelatedFiles.map(relatedItem => (
                    <button
                        key={`${mainTitle}-${subTitle}-${relatedItem}`}
                        className='btn-fake-link btn-icon-link px-5 mx-5'
                        data-tip={relatedItem}
                        style={{
                            fontSize: 12,
                            backgroundColor: '#ffe9a2',
                            color: '#333',
                            borderRadius: 5,
                            fontWeight: '600'
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            if (relatedItem.startsWith('http')) {
                                window.open(relatedItem, '_blank');
                            } else {
                                this.props.api.dispatchEvent('SET_TAB', { tab: 'files' });
                                setTimeout(() => {
                                    this.props.api.dispatchEvent('OPEN_FILE', { search: relatedItem });
                                }, 800);
                            }
                        }}
                    >
                        <Icon name="file" size={12} />
                    </button>
                ))}
                {hasRelatedDirs.map(relatedItem => (
                    <button
                        key={`${mainTitle}-${subTitle}-${relatedItem}`}
                        className='btn-fake-link btn-icon-link px-5 mr-5'
                        data-tip={relatedItem}
                        style={{
                            fontSize: 12,
                            backgroundColor: '#ffe9a2',
                            color: '#333',
                            borderRadius: 5,
                            fontWeight: '600'
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.api.dispatchEvent('SET_TAB', { tab: 'files' });
                            setTimeout(() => {
                                this.props.api.dispatchEvent('OPEN_FILE', { search: relatedItem });
                            }, 800);
                        }}
                    >
                        <Icon name="folder" size={12} />
                    </button>
                ))}
            </span>
        );
    }

    prepareChecklist(checklist) {
        const defaultMainSections = Object.keys(this.state.checklistDefaults);
        const colors = this.props.utils.interpolateHexColor("#5b9bd5", "#70ad47", defaultMainSections.length);
        let colorIndex = 0;
        let blockerFound = false;
        let blockerNoted = false;
        let newChecklist = [...checklist]
            .filter(itm => itm.parentuuid === null && !itm.trash)
            .sort((a, b) => a.position - b.position)
            .map((itm) => {
                let newItm = {
                    ...itm
                };
                if (!newItm.taskuuid) {
                    newItm.taskuuid = null;
                }
                if (!newItm.position) {
                    newItm.position = 0;
                }
                if (!newItm.parentuuid) {
                    newItm.color = colors[colorIndex++];
                }
                newItm.taskname = this.getTaskName(newItm.taskuuid, null, this.props.tasks);
                newItm.subSections = [...checklist]
                    .filter(subItm => subItm.parentuuid === itm.uuid && !subItm.trash)
                    .sort((a, b) => a.position - b.position)
                    .map(subItm => { return { ...subItm, parentTitle: itm.title } });
                return newItm;
            })
            .map(itm => {
                const subColors = this.props.utils.interpolateHexColor("#5b9bd5", "#70ad47", itm.subSections.length);
                let newItm = {
                    ...itm,
                    subSections: itm.subSections
                        .map((subSection, subIndex) => {
                            let newSubSection = {
                                ...subSection,
                                hasBlockers: false,
                                blockedBy: [],
                                tip: '',
                                dateOnly: false,
                                color: subColors[subIndex],
                            };
                            defaultMainSections.forEach(defaultMainSectionTitle => {
                                let defaultMainSection = this.state.checklistDefaults[defaultMainSectionTitle];
                                let defaultSubSections = Object.keys(defaultMainSection);
                                defaultSubSections.forEach(defaultSubSectionTitle => {
                                    let subSectionParams = this.state.checklistDefaults[defaultMainSectionTitle][defaultSubSectionTitle];
                                    let isFirstBlocker = !blockerFound;
                                    if (
                                        newSubSection.parentTitle === defaultMainSectionTitle &&
                                        newSubSection.title === defaultSubSectionTitle
                                    ) {
                                        newSubSection.defaultParams = { ...subSectionParams };
                                        if (subSectionParams.blocker && !newSubSection.done) {
                                            blockerFound = newSubSection;
                                        }
                                        if (isFirstBlocker) {
                                            // ...
                                        } else {
                                            newSubSection.hasBlockers = true;
                                            if (!blockerNoted) {
                                                blockerNoted = true;
                                                newSubSection.blockedBy.push(blockerFound?.title);
                                            }
                                        }

                                        newSubSection.dateOnly = newSubSection.defaultParams.dateOnly;
                                        newSubSection.tip = newSubSection.defaultParams.tip;

                                        (newSubSection.defaultParams.blockedBy || []).forEach(blockerKey => {
                                            const hasBlocker = this.props.upperBlockers[blockerKey];
                                            if (hasBlocker) {
                                                newSubSection.hasBlockers = hasBlocker;
                                                newSubSection.blockedBy.push(this.props.t(`common.blocker.${blockerKey}`));
                                            }
                                        });

                                    }
                                });
                            });
                            return newSubSection;
                        })
                };
                return newItm;
            });
        return newChecklist;
    }

    render() {

        const {
            editable,
            canEdit,
            canEditDate,
            canEditNotes,
            showChecklistItemStatusModal,
            showChecklistItemDateModal,
            showItemHistoryModal,
            selectedItem,
            selectedItemHistory,
            showUnicorn,
            unicornBorn,
        } = this.state;

        const checklistPrepared = this.prepareChecklist(this.props.checklist);
        const checklistIsEmpty = checklistPrepared.length === 0;
        const useFlatStatus = checklistPrepared.find(itm => itm.parentuuid === null && itm.title === '_') ? true : false;

        const checklistEmptyView = checklistIsEmpty && (
            <div className="text-center p-25" style={{ border: `1px solid #dee2e6` }}>
                <Icon name="check-circle" style={{ width: 64, height: 64, color: "#e6ecf0" }} />
            </div>
        )

        const checklistEditView = (
            <>
                {
                    checklistPrepared.map(mainSection => {
                        return (
                            <ListGroup key={mainSection.uuid} className="mb-10">
                                <ListGroup.Item style={{ borderLeft: `5px solid ${mainSection.color}` }}>
                                    <Row>
                                        <Col sm={11}>
                                            <small>{this.props.stageOfChecklist || this.props.t('component.checklist.stageOfChecklist')}</small>
                                            <br />
                                            <Form.Control
                                                value={mainSection.title}
                                                disabled={useFlatStatus}
                                                onChange={(e) => { this.setItem(mainSection.uuid, 'title', e) }}
                                            />
                                        </Col>
                                        <Col sm={1} className="text-right">
                                            <small>&nbsp;</small>
                                            <br />
                                            <button
                                                type="button"
                                                className={"btn pr-0 pl-0"}
                                                onClick={(e) => { this.removeItem(mainSection.uuid) }}
                                                disabled={useFlatStatus}
                                            >
                                                {this.props.t('common.button.remove')}
                                            </button>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                                {
                                    mainSection.subSections.map(subSection => (
                                        <ListGroup.Item key={subSection.uuid} style={{ borderLeftWidth: `5px` }}>
                                            <Row className="justify-content-center align-items-center">
                                                <Col sm={11} md={11}>
                                                    <small>{this.props.taskOfChecklist || this.props.t('component.checklist.taskOfChecklist')}</small>
                                                    <br />
                                                    <Form.Control
                                                        value={subSection.title}
                                                        onChange={(e) => { this.setItem(subSection.uuid, 'title', e) }}
                                                    />
                                                </Col>
                                                <Col sm={1} md={1} className="text-center">
                                                    <small>&nbsp;</small>
                                                    <br />
                                                    <button
                                                        type="button"
                                                        className={"btn pr-0 pl-0"}
                                                        onClick={(e) => { this.removeItem(subSection.uuid) }}
                                                    >
                                                        {this.props.t('common.button.remove')}
                                                    </button>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    ))
                                }
                                <ListGroup.Item style={{ borderLeftWidth: `5px` }}>
                                    <Row>
                                        <Col sm={6}>
                                            <button
                                                type="button"
                                                className={"btn pr-0 pl-0"}
                                                onClick={(e) => { this.addItem(mainSection.uuid) }}
                                            >
                                                <Icon name="plus" className="mr-5" />
                                                {this.props.t('common.button.add')}
                                            </button>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        )
                    })
                }
                <Row>
                    <Col sm={6}>
                        <button
                            type="button"
                            className={"btn pr-0 pl-0 my-10"}
                            onClick={(e) => {
                                e.preventDefault();
                                this.addItem(null);
                            }}
                        >
                            <Icon name="plus" className="mr-5" />
                            {this.props.t('common.button.add')}
                        </button>
                    </Col>
                    <Col sm={6} className="text-right">
                        <button
                            type="button"
                            className={"btn pr-0 pl-0 my-10"}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({ editable: false })
                            }}
                        >
                            {this.props.t('common.button.close')}
                            <Icon name="x" className="ml-5" />
                        </button>
                    </Col>
                </Row>
            </>
        );

        const checklistView = (
            <>
                {
                    checklistPrepared.map(mainSection => {
                        return (
                            <ListGroup key={mainSection.uuid} className="mb-10">
                                {
                                    mainSection.title !== '_' && (
                                        <ListGroup.Item style={{ borderLeft: `5px solid ${mainSection.color}` }}>
                                            <small>{this.props.stageOfChecklist || this.props.t('component.checklist.stageOfChecklist')}</small>
                                            <br />
                                            <strong>{mainSection.title}</strong>
                                        </ListGroup.Item>
                                    )
                                }
                                {
                                    (mainSection.subSections || []).map((subSection, subSectionIndex) => {
                                        let disabledCheckbox = !canEdit || subSection.disabled;
                                        let disabledNotes = !canEditNotes || subSection.disabled;
                                        let disabledHistory = (subSection.history || []).length === 0;
                                        let dateOnly = subSection.dateOnly;
                                        let subSectionTrueIndex = subSection.index ? `${subSectionTrueIndex}` : ``;
                                        return (
                                            <React.Fragment key={subSection.uuid}>
                                                <ListGroup.Item style={{ borderLeft: `5px solid ${subSection.color}` }}>
                                                    <Row className="justify-content-center align-items-center">
                                                        <Col sm={12} md={5} style={{ borderLeft: subSection.custom ? `10px solid #f3f4f7` : `` }}>
                                                            <small>{this.props.t('component.checklist.milestone')}</small>
                                                            <br />
                                                            <div className="d-flex justify-content-left align-items-center">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    inline
                                                                    label={subSection.title}
                                                                    checked={subSection.done || false}
                                                                    disabled={disabledCheckbox}
                                                                    onChange={(e) => this.setItem(subSection.uuid, 'done', !subSection.done, true, true, null, null, !subSection.done)}
                                                                    style={{ textDecoration: subSection.done ? 'line-through' : 'none' }}
                                                                />
                                                                {
                                                                    subSection.tip && (
                                                                        <Icon name="info" data-tip={subSection.tip} />
                                                                    )
                                                                }
                                                                {
                                                                    this.getRelatedFilesAndDirs(mainSection.title, subSection.title, subSection._uuid)
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col sm={12} md={3}>
                                                            <small>{this.props.t('component.checklist.date')}</small>
                                                            <br />
                                                            <button
                                                                disabled={disabledNotes}
                                                                className='btn-fake-link'
                                                                onClick={(e) => this.itemChangeStatusDateModal(e, subSection.uuid, subSection.title, subSection.status, mainSection.uuid, mainSection.title, dateOnly)}
                                                            >
                                                                {subSection.date && (<>{subSection.date}</>)}
                                                                {!subSection.date && (<>{this.props.t('common.placeholder.any')}</>)}
                                                            </button>
                                                        </Col>
                                                        <Col sm={12} md={3}>
                                                            <small>{this.props.t('component.checklist.status')}</small>
                                                            <br />
                                                            <button
                                                                disabled={disabledNotes}
                                                                className='btn-fake-link'
                                                                onClick={(e) => this.itemChangeStatusNotesModal(e, subSection.uuid, subSection.title, subSection.status, mainSection.uuid, mainSection.title, dateOnly)}
                                                                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '100%' }}
                                                            >
                                                                {subSection.status && (<>{subSection.status}</>)}
                                                                {!subSection.status && (<>{this.props.t('common.placeholder.any')}</>)}
                                                            </button>
                                                        </Col>
                                                        {
                                                            /*
                                                                <Col sm={12} md={2}>
                                                                    <small>{this.props.t('component.checklist.taskOfSubcontractor')}</small>
                                                                    <br />{subSection.taskname || this.props.t('common.placeholder.any')}
                                                                </Col>
                                                            */
                                                        }
                                                        <Col sm={12} md={1}>
                                                            <small>{this.props.t('component.checklist.log')}</small>
                                                            <br />
                                                            <button
                                                                className='btn-fake-link btn-icon-link'
                                                                disabled={disabledHistory}
                                                                onClick={(e) => this.itemHistoryModal(e, subSection.history || [])}
                                                            >
                                                                &#128269;
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                                {
                                                    ((subSection.blockedBy || []).length > 0) && (
                                                        <Alert className='py-1 px-5 my-5' variant='warning'>
                                                            {
                                                                this.props.t(
                                                                    `common.blocker.fill`,
                                                                    { what: ' → "' + subSection.blockedBy.join(' + ') + '"' }
                                                                )
                                                            }
                                                        </Alert>
                                                    )
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </ListGroup>
                        )
                    })
                }
                <Row>
                    <Col sm={12}>
                        {
                            !canEdit || useFlatStatus ? (<></>) :
                                checklistIsEmpty ? (
                                    <button
                                        type="button"
                                        className={"btn pr-0 pl-0 my-10"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ editable: true }, () => this.addItem(null))
                                        }}
                                    >
                                        <Icon name="plus" className="mr-5" />
                                        {this.props.t('common.button.add')}
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className={"btn pr-0 pl-0 my-10"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ editable: true })
                                        }}
                                    >
                                        <Icon name="edit2" className="mr-5" />
                                        {this.props.t('common.button.edit')}
                                    </button>
                                )
                        }

                    </Col>
                </Row>
            </>
        );

        const checklistItemStatusModal = showChecklistItemStatusModal && selectedItem && (
            <Modal
                show={showChecklistItemStatusModal}
                onHide={() => { this.setState({ showChecklistItemStatusModal: false, selectedItem: null }) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedItem.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>
                                    {this.props.t('component.checklist.status')}:
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    custom
                                    value={selectedItem.newStatus || ''}
                                    placeholder={this.props.t('common.placeholder.any')}
                                    onChange={(e) => { this.setState({ selectedItem: { ...selectedItem, newStatus: this.val(e), newDate: today } }) }}
                                >
                                    <option value="" disabled></option>
                                    <option>{this.props.t('common.milestoneStatus.na')}</option>
                                    <option>{this.props.t('common.milestoneStatus.todo')}</option>
                                    <option>{this.props.t('common.milestoneStatus.doing')}</option>
                                    <option>{this.props.t('common.milestoneStatus.done')}</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="p-20 border-top">
                    <Button disabled={this.state.isLoading} variant="info" onClick={(e) => { e.preventDefault(); this.changeSelectedItemStatus() }}>
                        {this.props.t('common.button.set')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )

        const checklistItemDateModal = showChecklistItemDateModal && selectedItem && (
            <Modal
                show={showChecklistItemDateModal}
                onHide={() => { this.setState({ showChecklistItemDateModal: false, selectedItem: null }) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedItem.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            <Form.Group className='required'>
                                <Form.Label>
                                    {this.props.t('component.checklist.date')}:
                                </Form.Label>
                                <Form.Control
                                    required={true}
                                    type="date"
                                    value={selectedItem.newDate || ''}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                    onChange={(e) => { this.setState({ selectedItem: { ...selectedItem, newDate: this.val(e) } }) }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="p-20 border-top">
                    <Button variant="info" onClick={(e) => { e.preventDefault(); this.changeSelectedItemDate() }}>
                        {this.props.t('common.button.set')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )

        const itemHistoryModal = showItemHistoryModal && selectedItemHistory && (
            <Modal
                show={showItemHistoryModal}
                onHide={() => { this.setState({ showItemHistoryModal: false, selectedItemHistory: [] }) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.t('component.checklist.log')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {selectedItemHistory.map((histItem, histItemIndex) =>
                            <ListGroup.Item key={`${histItem.who}-${histItem.when}-${histItem.uuid || histItemIndex}`}>
                                <span className="media-title">
                                    <small>{`${histItem.who} @ ${histItem.when}`}</small>
                                    {
                                        histItem.changed && (
                                            <>
                                                <br />{`${histItem.changed} → ${JSON.stringify(histItem[histItem.changed])}`}
                                            </>
                                        )
                                    }
                                </span>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </Modal.Body>
            </Modal>
        )

        const unicorn = showUnicorn && (
            <Unicorn born={unicornBorn} />
        )

        return (
            <>
                <ReactTooltip />
                {unicorn}
                {checklistItemStatusModal}
                {checklistItemDateModal}
                {itemHistoryModal}
                {checklistEmptyView}
                {editable ? checklistEditView : checklistView}
            </>
        )
    }

}

export default Checklist