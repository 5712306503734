import React from 'react';
import BaseComponent from './base';

class ChartComponent extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {}
        this.chartRef = React.createRef();
        this.chartOptions = {
            tooltips: {
                mode: 'nearest',
                intersect: false,
                backgroundColor: '#393f49',
                titleMarginBottom: 8,
                titleFontSize: 14,
                titleFontColor: '#f8f9fa',
                titleFontFamily: "'Open Sans', sans-serif",
                bodyFontSize: 11,
                bodyFontColor: '#d7d9e0',
                bodyFontFamily: "'Open Sans', sans-serif",
                footerMarginTop: 10,
                footerFontSize: 11,
                footerFontColor: '#f8f9fa',
                footerFontFamily: "'Open Sans', sans-serif",
                xPadding: 10,
                yPadding: 10,
                caretPadding: 5,
                cornerRadius: 4,
            },
            legend: {
                labels: {
                    padding: 30,
                    fontSize: 14,
                    fontColor: '#6c757d',
                    fontFamily: "'Open Sans', sans-serif",
                    boxWidth: 14,
                },
            },
            scales: {
                xAxes: [{
                    stacked: false,
                    gridLines: {
                        lineWidth: 0,
                        color: 'transparent',
                        zeroLineWidth: 0,
                        zeroLineColor: 'transparent',
                    },
                    ticks: {
                        min: 0,
                        fontSize: 12,
                        fontColor: '#bcbec0',
                        fontFamily: "'Open Sans', sans-serif",
                    },
                }],
                yAxes: [{
                    stacked: false,
                    gridLines: {
                        lineWidth: 1,
                        color: '#e0e0e0',
                        zeroLineWidth: 1,
                        zeroLineColor: '#e0e0e0',
                    },
                    ticks: {
                        min: 0,
                        fontSize: 12,
                        fontColor: '#bcbec0',
                        fontFamily: "'Open Sans', sans-serif",
                    },
                }],
            },
        }
    }

    snapshot() {
        const oldHeight = this.chartRef.current.chartInstance.canvas.parentNode.style.height;
        const oldWidth = this.chartRef.current.chartInstance.canvas.parentNode.style.width;
        this.chartRef.current.chartInstance.canvas.parentNode.style.height = '1920px';
        this.chartRef.current.chartInstance.canvas.parentNode.style.width = '1920px';
        let content = this.chartRef.current.chartInstance.toBase64Image();
        this.chartRef.current.chartInstance.canvas.parentNode.style.height = oldHeight;
        this.chartRef.current.chartInstance.canvas.parentNode.style.width = oldWidth;
        return content;
    }

}
export default ChartComponent;