import React, { Fragment } from 'react';
import BaseComponent from '../../components/component/base';
import { Col, Form, ListGroup, Modal, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import Discussion from '../../components/discussion';
import TimeAgo from 'react-timeago';
import AutoBadge from '../../components/auto-badge';

class ModalTicket extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            activeTab1: props.activeTab,
            activeTab1Seen: [props.activeTab].filter(Boolean),
            ticketuuid: props.ticketuuid || '',
            ticketident: props.ticketident || '',
            ticketlabel: '',
            tickethashtags: '',
            ticketprogress: '',
            ticketdescription: '',
            ticketstatus: '',
            ticketsince: '',
            tickettill: '',
            ticketcreated: '',
            ticketconfirmed: '',
            creatoruuid: '',
            creatorinitials: '',
            creatorimageUrl: '',
            creatorlabel: '',
            creatormobilePhone: '',
            projectuuid: '',
            projectvalue: '',
            projectlabel: '',
            projectdescription: '',
            kanbanmapping: {},
        };
        this.setTabEvent = this.setTabEvent.bind(this);
    }

    componentDidMount() {
        window.addEventListener("SET_TAB", this.setTabEvent, false);
        if (this.props.ticketuuid) {
            this.fetchTicket(this.props.afterFetchTicket);
        } else {
            this.afterFetchSuccess({})
        }
    }

    setTab1(tabName, callback) {
        this.setState({ activeTab1: tabName, activeTab1Seen: [...this.state.activeTab1Seen, tabName] }, callback);
    }

    setTabEvent(e) {
        this.setTab(e.detail.tab, () => { });
    }

    fetchTicket(callback) {
        const {
            ticketuuid
        } = this.state;
        this.setState({ isLoading: true }, () => {
            this.props.api.post(
                `/ticket/describe`,
                {
                    ticket: {
                        uuid: ticketuuid
                    }
                },
                (dataObject) => {
                    this.afterFetchSuccess(dataObject, callback);
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        });
    }

    afterFetchSuccess(dataObject, callback) {
        this.setState({
            isLoading: false,
            "ticketuuid": dataObject.ticket?.uuid || '',
            "ticketident": dataObject.ticket?.ident || '',
            "ticketlabel": dataObject.ticket?.label || '',
            "tickethashtags": dataObject.ticket?.hashtags || '',
            "ticketprogress": dataObject.ticket?.progress || '',
            "ticketdescription": dataObject.ticket?.description || '',
            "ticketstatus": dataObject.ticket?.status || '',
            "ticketsince": dataObject.ticket?.since || '',
            "tickettill": dataObject.ticket?.till || '',
            "ticketcreated": dataObject.ticket?.created || '',
            "ticketconfirmed": dataObject.ticket?.confirmed || '',
            "creatoruuid": dataObject.creator?.uuid || '',
            "creatorinitials": dataObject.creator?.initials || '',
            "creatorimageUrl": dataObject.creator?.imageUrl || '',
            "creatorlabel": dataObject.creator?.label || '',
            "creatormobilePhone": dataObject.creator?.mobilePhone || '',
            "projectuuid": dataObject.project?.uuid || '',
            "projectvalue": dataObject.project?.value || '',
            "projectlabel": dataObject.project?.label || '',
            "projectdescription": dataObject.project?.description || '',
            "kanbanmapping": dataObject.kanban?.mapping || {},
        }, callback);
    }

    afterFetchError(errorObject) {
        this.setState({ isLoading: false }, () => {
            this.props.showToast({
                errorObject: errorObject,
                title: this.props.t('common.toast.error'),
                color: 'danger'
            });
        })
    }

    render() {

        const {
            isLoading,
            activeTab1,
            activeTab1Seen,
            ticketuuid,
            ticketident,
            ticketlabel,
            tickethashtags,
            ticketprogress,
            ticketdescription,
            ticketstatus,
            ticketsince,
            tickettill,
            ticketcreated,
            ticketconfirmed,
            creatoruuid,
            creatorinitials,
            creatorimageUrl,
            creatorlabel,
            creatormobilePhone,
            projectuuid,
            projectvalue,
            projectlabel,
            projectdescription,
            kanbanmapping
        } = this.state;

        const projectsummary = [
            { key: null, val: ticketlabel || '-' },
            { key: this.props.t('page.tickets.ticket.summary.ticketstatus'), val: ticketstatus || '---' },
            { key: this.props.t('page.tickets.ticket.summary.ticketcreated'), val: ticketcreated ? <TimeAgo date={ticketcreated} /> : '---' },
            { key: this.props.t('page.tickets.ticket.summary.creatorlabel'), val: creatorlabel || '---' },
            { key: this.props.t('page.tickets.ticket.summary.projectlabel'), val: projectlabel || '---' },
        ];

        const loadingView = (
            <Row>
                <Col sm={12} className="text-center p-20">
                    <Spinner animation="border" />
                </Col>
            </Row>
        )

        const tabDiscussion = (
            <Tab eventKey="discussion" title={this.props.t('page.tickets.ticket.tabs.discussion.title')}>
                <Discussion
                    t={this.props.t}
                    api={this.props.api}
                    utils={this.props.utils}
                    getUser={this.props.getUser}
                    location={this.props.location}
                    showToast={this.props.showToast}
                    hasPermission={this.props.hasPermission}
                    anchors={{ ticket: { uuid: ticketuuid } }}
                    subpage={`tickets`}
                    buildLink={this.props.buildLink}
                    readOnly={false}
                    disableEdit={true}
                />
            </Tab>
        )

        const formView = (
            <Row>
                <Col sm={12} md={8}>
                    <Tabs
                        activeKey={activeTab1}
                        onSelect={(k) => this.setTab1(k, () => { this.props.onSetTab('activeTab1', k) })}
                        className="m-0 align-content-between nicebar"
                    >
                        {tabDiscussion}
                    </Tabs>
                </Col>
                <Col sm={12} md={4}>
                    <h2 className='py-6'>{ticketident}🔅</h2>
                    <ListGroup style={{ fontSize: 12 }}>
                        {projectsummary.filter(itm => itm.val !== '---').map((itm, itmIdx) => (
                            <ListGroup.Item key={`summary-${itmIdx}`}>
                                {itm.key}
                                {` `}
                                <b>{itm.val}</b>
                            </ListGroup.Item>
                        ))}
                        <ListGroup.Item>
                            <Form.Control
                                as="select"
                                custom
                                value={ticketstatus}
                                onChange={(e) => {
                                    this.props.api.postSilent(
                                        `/ticket/transition`,
                                        {
                                            ticket: {
                                                'uuid': ticketuuid,
                                                'status': kanbanmapping[this.val(e)],
                                            }
                                        },
                                        (dataObject) => {
                                            this.afterFetchSuccess(dataObject);
                                        },
                                        (errorObject) => {
                                            this.afterFetchError(errorObject);
                                        }
                                    );
                                }}
                            >
                                {
                                    Object.keys(kanbanmapping).map(itm => (
                                        <option key={`ticketstatus-${itm}`} value={itm}>
                                            {kanbanmapping[itm]}
                                        </option>
                                    ))
                                }
                            </Form.Control>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        )

        const modalMain = (
            <Modal
                show={this.props.showModal}
                onHide={() => { this.props.onModalHide() }}
                backdrop="static"
                keyboard={false}
                size="md"
                centered
                autoFocus={false}
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ display: 'flex', alignItems: 'center' }}>
                        {tickethashtags && <AutoBadge text={tickethashtags} divClassName={'d-inline mr-5'} inline={true} />}
                        <span>{`${ticketident} - ${ticketlabel}`}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? loadingView : formView}
                </Modal.Body>
            </Modal>
        )

        return (
            <Fragment>
                {modalMain}
            </Fragment>
        )
    }

}

export default ModalTicket
