import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
import Dnd from '../../components/dnd';

export class Tree extends Component {

    shouldComponentUpdate(nextProps) {
        if (nextProps.lastChange === "item") {
            return false;
        }
        return true;
    }

    render() {

        const {
            tree,
            setTheTree,
            getLabel,
            loopThrough,
            selectedPath,
        } = this.props;

        return (
            <ListGroup variant="flush">
                <Dnd
                    items={tree}
                    onUpdate={(newTree) => {
                        setTheTree({ tree: newTree });
                    }}
                    droppableId="categories"
                    renderItem={(category, categoryIndex, categoryDragProps) => {
                        return (
                            <ListGroup.Item key={category.uuid} className="p-0 m-0" style={{ whiteSpace: "nowrap" }}>
                                {getLabel(category, 1, categoryDragProps)}
                                <ListGroup className={`tree-group-with-parent-${category.uuid} ${(selectedPath || []).includes(category.uuid) ? '' : 'hidden'}`}>
                                    <Dnd
                                        items={category.children}
                                        onUpdate={(items) => {
                                            let newTree = loopThrough((obj) => {
                                                if (obj.uuid === category.uuid) {
                                                    obj.children = obj.children.concat([items]);
                                                }
                                                return obj;
                                            });
                                            setTheTree({ tree: newTree });
                                        }}
                                        droppableId={category.uuid}
                                        renderItem={(subCategory, subCategoryIndex, subCategoryDragProps) => {
                                            return (
                                                <ListGroup.Item key={subCategory.uuid} className="p-0 m-0" style={{ whiteSpace: "nowrap" }}>
                                                    {getLabel(subCategory, 2, subCategoryDragProps)}
                                                    <ListGroup className={`tree-group-with-parent-${subCategory.uuid} ${(selectedPath || []).includes(subCategory.uuid) ? '' : 'hidden'}`}>
                                                        <Dnd
                                                            items={subCategory.children}
                                                            onUpdate={(items) => {
                                                                let newTree = loopThrough((obj) => {
                                                                    if (obj.uuid === subCategory.uuid) {
                                                                        obj.children = obj.children.concat([items]);
                                                                    }
                                                                    return obj;
                                                                });
                                                                setTheTree({ tree: newTree });
                                                            }}
                                                            droppableId={subCategory.uuid}
                                                            renderItem={(product, index, productDragProps) => {
                                                                return (
                                                                    <ListGroup.Item key={product.uuid} className="p-0 m-0" style={{ whiteSpace: "nowrap" }}>
                                                                        {getLabel(product, 3, productDragProps)}
                                                                    </ListGroup.Item>
                                                                )
                                                            }}
                                                        />
                                                    </ListGroup>
                                                </ListGroup.Item>
                                            )
                                        }}
                                    />
                                </ListGroup>
                            </ListGroup.Item>
                        );
                    }}
                />
            </ListGroup>
        )
    }

}