import React, { Fragment } from 'react';
import Datatable from "../../components/datatable";
import BaseComponent from '../../components/component/base';
import Icon from '../../components/icon';
import { Row, Col, Card, Form } from "react-bootstrap";

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            onlyMyArea: this.props.hasPermission(['VIEW_MY_AREA_FILTER_BUTTON']).any
        };
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Header className="px-10 py-10 background-white">
                                {
                                    this.props.hasPermission(['VIEW_USER']).any && (
                                        <button
                                            type="button"
                                            className="btn btn-uniform text-success"
                                            disabled={false}
                                            onClick={(e) => { this.props.openLink('/user/') }}
                                        >
                                            <Icon name="plus-square" />
                                            <span className="pl-10">
                                                {this.props.t('page.users.newUser')}
                                            </span>
                                        </button>
                                    )
                                }
                                {
                                    this.props.hasPermission(['VIEW_ROLES']).any && (
                                        <button
                                            type="button"
                                            className="btn btn-uniform text-info"
                                            disabled={false}
                                            onClick={(e) => { this.props.openLink('/roles/') }}
                                        >
                                            <Icon name="edit" />
                                            <span className="pl-10">
                                                {this.props.t('page.users.editRoles')}
                                            </span>
                                        </button>
                                    )
                                }
                                {
                                    this.props.hasPermission(['VIEW_MY_AREA_FILTER_BUTTON']).any && (
                                        <div className="float-md-right clear-section-container">
                                            <Form.Check
                                                type="switch"
                                                id="onlyMyArea"
                                                label={this.props.t('page.users.onlyMyArea')}
                                                checked={this.state.onlyMyArea || false}
                                                onChange={(e) => { this.setState({ onlyMyArea: !this.state.onlyMyArea }) }}
                                            />
                                        </div>
                                    )
                                }
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Datatable
                                    id={`user-table`}
                                    endpoint={`user/table`}
                                    monitorProp={this.state.onlyMyArea}
                                    withData={{
                                        "onlyMyArea": this.state.onlyMyArea
                                    }}
                                    columns={[
                                        {
                                            Header: this.props.t('page.users.table.created'),
                                            accessor: "created_7"
                                        },
                                        {
                                            Header: this.props.t('page.users.table.active'),
                                            accessor: "active_8",
                                            width: 100
                                        },
                                        {
                                            Header: this.props.t('page.users.table.emailAddress'),
                                            accessor: "emailAddress_3"
                                        },
                                        {
                                            Header: this.props.t('page.users.table.firstName'),
                                            accessor: "firstName_1"
                                        },
                                        {
                                            Header: this.props.t('page.users.table.lastName'),
                                            accessor: "lastName_2"
                                        },
                                        {
                                            Header: this.props.t('page.users.table.companyName'),
                                            accessor: "name_6"
                                        },
                                        {
                                            Header: this.props.t(`page.users.table.area`),
                                            accessor: "sclr_10",
                                            width: 100
                                        },
                                        {
                                            Header: this.props.t('page.users.table.roleIdent'),
                                            accessor: "summary_5"
                                        },
                                        {
                                            Header: (<>&nbsp;</>),
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                            width: 100
                                        }
                                    ]}
                                    sort={[
                                        {
                                            id: "created_7",
                                            desc: true
                                        }
                                    ]}
                                    badges={[
                                        {
                                            'field': 'active_8',
                                            'variants':
                                            {
                                                '1': 'success',
                                                '0': 'warning'
                                            }
                                        },
                                        {
                                            "field": "summary_5",
                                            "badgify": true
                                        }
                                    ]}
                                    transform={[
                                        {
                                            'field': 'name_6',
                                            'fn': (row) => {
                                                return (
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={this.props.buildLink(`/company/${row.uuid_9}/`)}
                                                    >
                                                        {row.name_6}
                                                    </a>
                                                )
                                            }
                                        }
                                    ]}
                                    withCheckbox={false}
                                    onChecked={(data) => { }}
                                    actions={
                                        this.props.hasPermission(['VIEW_USER']).any
                                            ?
                                            [
                                                {
                                                    action: 'edit',
                                                    callback: (data) => {
                                                        this.props.openLink(`/user/${data.uuid_0}/`)
                                                    }
                                                },
                                                {
                                                    action: 'delete',
                                                    callback: (data) => {
                                                        this.confirmRemove(() => {
                                                            this.props.api.post(
                                                                `/user/trash`,
                                                                {
                                                                    "user": {
                                                                        "uuids": [data.uuid_0]
                                                                    }
                                                                },
                                                                () => {
                                                                    this.props.api.dispatchEvent('RELOAD_TABLE');
                                                                }
                                                            )
                                                        })
                                                    }
                                                }
                                            ]
                                            :
                                            []
                                    }
                                    {...this.props}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }

}

export default Content
