import React, { Fragment } from 'react';
import { Row, Col, Form, Card } from "react-bootstrap";
import LowerComponent from '../../components/component/lower';
import CustomFields from './custom-fields';

class Addons extends LowerComponent {

    constructor(props) {
        super(props);
        this.state = {
            fuseOptions: []
        }
    }

    componentDidMount() {
        fetch("/assets/data/fuse.json")
            .then((res) => res.json())
            .then((data) => this.setState({ fuseOptions: data }));
    }

    render() {

        const {
            isLoading,
            closed
        } = this.props;

        const fields = [
            {
                "visible": true,
                "label": this.props.t('page.calculation.addons.plannedConstructionFrom'),
                "value": this.props.plannedConstructionFrom || "",
                "inputType": "date",
                "stateKey": "plannedConstructionFrom",
                "validator": null,
                "required": false,
            },
            {
                "visible": true,
                "label": this.props.t('page.calculation.addons.plannedConstructionUntil'),
                "value": this.props.plannedConstructionUntil || "",
                "inputType": "date",
                "stateKey": "plannedConstructionUntil",
                "validator": null,
                "required": false,
            },
            {
                "visible": this.props.getConfig('useReferenceNumber'),
                "label": this.props.getConfig('useReferenceNumberLabel') || this.props.t('page.calculation.addons.referenceNumber'),
                "value": this.props.referenceNumber || "",
                "inputType": "text",
                "stateKey": "referenceNumber",
                "validator": this.props.getConfig('useReferenceNumberValidator'),
                "required": this.props.getConfig('useReferenceNumberRequired'),
            },
            {
                "visible": this.props.getConfig('useEnergyCompanyId'),
                "label": this.props.getConfig('useEnergyCompanyIdLabel') || this.props.t('page.calculation.addons.energyCompanyId'),
                "value": this.props.energyCompanyId || "",
                "inputType": "text",
                "stateKey": "energyCompanyId",
                "validator": this.props.getConfig('useEnergyCompanyIdValidator'),
                "required": this.props.getConfig('useEnergyCompanyIdRequired'),
            },
            {
                "visible": this.props.getConfig('useUsableArea'),
                "label": this.props.getConfig('useUsableAreaLabel') || this.props.t('page.calculation.addons.usableArea'),
                "value": this.props.usableArea || "",
                "inputType": "number",
                "stateKey": "usableArea",
                "validator": this.props.getConfig('useUsableAreaValidator'),
                "required": this.props.getConfig('useUsableAreaRequired'),
            },
            {
                "visible": this.props.isRegion(['SWEDEN', 'POLAND']),
                "label": this.props.t('page.calculation.financing.estAvgEnergyPrice'),
                "value": this.props.estAvgEnergyPrice || "0",
                "inputType": "number",
                "stateKey": "estAvgEnergyPrice",
                "validator": null,
                "required": false,
                "options": { min: 0, step: 0.01 }
            },
            {
                "visible": this.props.isRegion(['POLAND']),
                "label": this.props.t('page.calculation.financing.estAnnualProductionFromSingleKwP2KwH'),
                "value": this.props.estAnnualProductionFromSingleKwP2KwH || "0",
                "inputType": "number",
                "stateKey": "estAnnualProductionFromSingleKwP2KwH",
                "validator": null,
                "required": false,
                "options": { min: 0, step: 0.01 }
            },
            {
                "visible": this.props.getConfig('useFuse'),
                "label": this.props.getConfig('useFuseLabel') || this.props.t('page.calculation.addons.fuse'),
                "value": this.props.fuse && this.props.fuse.length ? this.props.fuse[0].ident : "",
                "values": this.state.fuseOptions,
                "inputType": "select",
                "stateKey": "fuse",
                "validator": this.props.getConfig('useFuseValidator'),
                "required": this.props.getConfig('useFuseRequired'),
            },
            {
                "visible": this.props.isRegion(['GERMANY']),
                "label": this.props.t('page.calculation.addons.roofExactType.title'),
                "value": this.props.roofExactType,
                "values": this.props.roofExactTypes.map(type => { return { value: type, label: this.props.t(`page.calculation.addons.roofExactType.list.${type}`) } }),
                "inputType": "select",
                "stateKey": "roofExactType",
                "validator": null,
                "required": true,
                "useRawValue": true
            },
        ].filter(itm => itm.visible);

        const consumption = [
            "ec",
            "heating",
            "smartHome",
            "ac"
        ];

        const connectivity = [
            "lan",
            "wifi",
            "mobile"
        ];

        const investmentType = [
            "newBuilding",
            "buildingRenovation"
        ];

        const constructionType = [
            "roof",
            "carport",
            "facade",
            "custom"
        ];

        return (
            <Fragment>
                <h2>
                    {this.props.t('page.calculation.addons.title')}
                    <div className="float-md-right clear-section-container">

                        {
                            this.props.hasPermission(['VIEW_OFFER_CLEAR_SECTION_BUTTON']).any && (
                                <button
                                    type="button"
                                    className="btn text-danger px-0"
                                    disabled={isLoading || closed}
                                    onClick={(e) => { this.props.clearSection(e, ['addons']) }}
                                >
                                    {this.props.t(`common.button.clear`)}
                                </button>
                            )
                        }

                    </div>
                </h2>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <CustomFields
                                    isLoading={isLoading || closed}
                                    fields={fields}
                                    t={this.props.t}
                                    validator={(required, value, validator) => this.props.utils.isValid(required, value, validator)}
                                    setUpperState={this.props.setUpperState}
                                />

                                {
                                    fields.length > 0 && (
                                        <hr className="mb-30" />
                                    )
                                }

                                {
                                    this.props.hasPermission(['VIEW_OFFER_DISCLAIMER']).any && (
                                        <>
                                            <Row>
                                                <Col sm={12}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {this.props.t('page.calculation.addons.disclaimer')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            required={false}
                                                            disabled={isLoading || closed}
                                                            as="textarea"
                                                            style={{ height: 100 }}
                                                            rows="2"
                                                            value={this.props.disclaimer || ""}
                                                            onChange={(e) => { this.props.setUpperState({ disclaimer: this.val(e) }) }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <hr className="mb-30" />
                                        </>
                                    )
                                }

                                <Row>
                                    <Col sm={12}>
                                        <Form.Label>
                                            {this.props.t('page.calculation.addons.constructionType.title')}
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        constructionType.map(ident => {
                                            let checked = this.props.constructionType.find(itm => itm.ident === ident) ? true : false;
                                            return (
                                                <Col key={ident} sm={6} md={4} lg={3}>
                                                    <Form.Group>
                                                        <Form.Check
                                                            disabled={isLoading || closed}
                                                            type="switch"
                                                            id={ident}
                                                            checked={checked}
                                                            onChange={event => {
                                                                if (checked) {
                                                                    // ...
                                                                } else {
                                                                    this.props.setUpperState({ "constructionType": [{ "ident": ident }] })
                                                                }
                                                            }}
                                                            label={this.props.t(`page.calculation.addons.constructionType.list.${ident}`)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <Form.Label className={this.props.getConfig('useInvestmentTypeRequired') ? "required" : ""}>
                                            {this.props.t('page.calculation.addons.investmentType.title')}
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        investmentType.map(ident => {
                                            let checked = this.props.investmentType.find(itm => itm.ident === ident) ? true : false;
                                            return (
                                                <Col key={ident} sm={6} md={4} lg={3}>
                                                    <Form.Group>
                                                        <Form.Check
                                                            name="investmentType[]"
                                                            disabled={isLoading || closed}
                                                            data-invalidmsg={this.props.t('page.calculation.addons.investmentType.title')}
                                                            required={this.props.getConfig('useInvestmentTypeRequired') && this.props.investmentType.length === 0 ? true : false}
                                                            type="switch"
                                                            id={ident}
                                                            checked={checked}
                                                            onChange={event => {
                                                                if (checked) {
                                                                    this.props.setUpperState({ "investmentType": this.props.investmentType.filter(itm => itm.ident !== ident) })
                                                                } else {
                                                                    this.props.setUpperState({ "investmentType": [{ "ident": ident }] })
                                                                }
                                                            }}
                                                            label={this.props.t(`page.calculation.addons.investmentType.list.${ident}`)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Label>
                                            {this.props.t('page.calculation.addons.consumption.title')}
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        consumption.map(ident => {
                                            let checked = this.props.consumption.find(itm => itm.ident === ident) ? true : false;
                                            return (
                                                <Col key={ident} sm={6} md={4} lg={3}>
                                                    <Form.Group>
                                                        <Form.Check
                                                            disabled={isLoading || closed}
                                                            type="switch"
                                                            id={ident}
                                                            checked={checked}
                                                            onChange={event => {
                                                                if (checked) {
                                                                    this.props.setUpperState({ "consumption": this.props.consumption.filter(itm => itm.ident !== ident) })
                                                                } else {
                                                                    this.props.setUpperState({ "consumption": this.props.consumption.concat([{ "ident": ident }]) })
                                                                }
                                                            }}
                                                            label={this.props.t(`page.calculation.addons.consumption.list.${ident}`)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Label>
                                            {this.props.t('page.calculation.addons.connectivity.title')}
                                        </Form.Label>
                                    </Col>
                                </Row>

                                <Row>
                                    {
                                        connectivity.map(ident => {
                                            let checked = this.props.connectivity.find(itm => itm.ident === ident) ? true : false;
                                            return (
                                                <Col key={ident} sm={6} md={4} lg={3}>
                                                    <Form.Group>
                                                        <Form.Check
                                                            disabled={isLoading || closed}
                                                            type="switch"
                                                            id={ident}
                                                            checked={checked}
                                                            onChange={event => {
                                                                if (checked) {
                                                                    this.props.setUpperState({ "connectivity": this.props.connectivity.filter(itm => itm.ident !== ident) })
                                                                } else {
                                                                    this.props.setUpperState({ "connectivity": this.props.connectivity.concat([{ "ident": ident }]) })
                                                                }
                                                            }}
                                                            label={this.props.t(`page.calculation.addons.connectivity.list.${ident}`)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}
export default Addons;