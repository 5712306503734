import { Component } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";

class MapType extends Component {

    render() {
        return (
            <DropdownButton
                as={ButtonGroup}
                title={this.props.mapTypeId}
                variant="link"
                menuAlign={{ lg: 'right' }}
                alignRight={true}
            >
                <Dropdown.Item as="button" onClick={(e) => this.props.setMapTypeId(e, 'roadmap')}>Roadmap</Dropdown.Item>
                <Dropdown.Item as="button" onClick={(e) => this.props.setMapTypeId(e, 'satellite')}>Satellite</Dropdown.Item>
                {
                    this.props.layers.map(layerId => {
                        return (
                            <Dropdown.Item
                                as="button"
                                key={layerId}
                                onClick={(e) => this.props.setMapTypeId(e, layerId)}
                                style={{ textTransform: 'capitalize' }}
                            >
                                {layerId}
                            </Dropdown.Item>
                        )
                    })
                }
            </DropdownButton>
        )
    }
}

export default MapType;