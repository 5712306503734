import './goodjob.scss'
import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap";
import { DateTime } from "luxon";
import Unicorn from '../unicorn';

class ModalAnnounceGoodJob extends Component {

    constructor(props) {
        super(props);
        const modalIdent = `announce_goojob`;
        const showedTimes = props.api.getStored('bp_dismiss', modalIdent, 0);
        this.today = DateTime.local();
        this.todayIso = this.today.toISODate();
        this.state = {
            modalIdent,
            showedTimes,
            show: false,
            size: props.size,
            showUnicorn: false,
            unicornBorn: 0,
            forUser: null,
        }
    }

    componentDidMount() {
        this.fetchConfig();
    }

    fetchConfig() {

        const loggedUserEmail = (this.props.user?.user?.emailAddress || '').toLowerCase();
        const calendar = {
            "2023-10-31": [
                { email: "xx.xx", "name": "XX!" },
            ]
        }
        let show = false;
        let usr = null;

        if (calendar[this.todayIso]) {
            calendar[this.todayIso].forEach(forUser => {
                if (loggedUserEmail.includes(forUser.email)) {
                    usr = { ...forUser };
                    show = true;
                }
            })
        }

        if (show) {
            console.log('🥇' + this.todayIso);
            this.setState({ show: true, forUser: usr }, () => {
                this.setState({ showUnicorn: true }, () => {
                    this.setState({ unicornBorn: (new Date()).getTime() / 1000 }, () => {
                        setTimeout(() => {
                            if (this) {
                                this.setState({ show: false });
                            }
                        }, 6000);
                    })
                });
            });
        }
    }

    dismiss() {
        this.props.api.postSilent(`/system/log/event_bp_dismiss`, this.state);
        this.props.api.setStored('bp_dismiss', this.state.modalIdent, this.state.showedTimes + 1);
        this.setState({ show: false });
    }

    render() {
        return (
            <>
                <Modal
                    centered
                    show={this.state.show}
                    onHide={() => { this.dismiss() }}
                    style={{ maxWidth: "100vw", outline: 0 }}
                    keyboard={true}
                    size={this.state.size || 'md'}
                >
                    <Modal.Body>
                        <div id="goodjob">
                            <div className="box">
                                <strong>{this.state.forUser?.name}</strong>
                                <div className="flip">
                                    <div className='two'><div>GOOD JOB!!🙌🤩</div></div>
                                    <div className='one'><div>CONGRATULATIONS!!!👏🥇</div></div>
                                    <div className='three'><div>WELL DONE!!💪🥷</div></div>
                                </div>
                                Achievement Unlocked
                            </div>
                        </div>
                        {this.state.showUnicorn && (
                            <Unicorn born={this.state.unicornBorn} />
                        )}
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default ModalAnnounceGoodJob
