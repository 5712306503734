import React, { Component } from 'react';
import Icon from '../icon';

class IconFilters extends Component {

    constructor(props) {
        super(props);
        this.state = { filterIsActive: false }
        this.intervalFilters = setInterval(() => { this.checkIfFilterIsActive() }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalFilters);
    }

    checkIfFilterIsActive() {
        this.setState({
            filterIsActive: !(JSON.parse(window.localStorage.getItem("bp_scheduler_filter")) || {})?.default
        })
    }

    render() {
        return (
            <span className={this.state.filterIsActive ? 'bg-warning text-dark rounded-circle' : ''}>
                <Icon name="settings" />
            </span>
        )
    }
}

export default IconFilters;