import React, { Fragment } from 'react';
import BaseComponent from '../../components/component/base';
import { Col, Form, Modal, Row, Button } from 'react-bootstrap';
import SelectAsync from '../../components/select/async';
import TextEditor from '../../components/text-editor';

class ModalCreateTicket extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedproject: null,
            selecteduser: null,
            selectedtopic: null,
            ticketlabel: null,
            commentcontent: null,
        };
    }

    saveData() {
        this.setState({ isLoading: true }, () => {
            const {
                selectedproject,
                selecteduser,
                selectedtopic,
                ticketlabel,
                commentcontent,
            } = this.state;
            this.props.api.post(
                `/ticket/create`,
                {
                    "ticket": {
                        "label": [selectedtopic?.value, ticketlabel].filter(Boolean).join(': '),
                        "hashtags": selectedtopic?.hashtags,
                        "progress": 0,
                        "status": 'todo',
                    },
                    "comment": {
                        "content": commentcontent
                    },
                    "project": {
                        "uuid": selectedproject?.value
                    },
                    "user": {
                        "uuid": selecteduser?.value
                    }
                },
                (dataObject) => {
                    this.props.afterCreateTicket(dataObject.ticket.uuid);
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        });
    }

    afterFetchError(errorObject) {
        this.setState({ isLoading: false }, () => {
            this.props.showToast({
                errorObject: errorObject,
                title: this.props.t('common.toast.error'),
                color: 'danger'
            });
        })
    }

    render() {

        const modalMain = (
            <Modal
                show={this.props.showModal}
                onHide={() => { this.props.onModalHide() }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
                autoFocus={false}
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    {this.props.t('page.tickets.ticket.create.title')}
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col sm={this.state.selectedtopic?.custom ? 6 : 12}>
                                <Form.Group className='required'>
                                    <Form.Label>
                                        {this.props.t('page.tickets.ticket.create.subject')}
                                    </Form.Label>
                                    <SelectAsync
                                        t={this.props.t}
                                        api={this.props.api}
                                        isClearable={true}
                                        isMulti={false}
                                        cache={false}
                                        preload={true}
                                        disabled={this.state.isLoading}
                                        endpoint={`/project/data`}
                                        value={this.state.selectedtopic}
                                        params={{
                                            "file": {
                                                "names": ["ticket_subject"]
                                            }
                                        }}
                                        transformData={(data, callback) => {
                                            let newData = [];
                                            data.ticket_subject.message.forEach(mainItem => {
                                                mainItem.subItems.forEach(subItem => {
                                                    newData.push({ ...subItem, label: subItem.value });
                                                })
                                            })
                                            callback(newData);
                                        }}
                                        onChange={(data) => {
                                            this.setState({ selectedtopic: data })
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            {
                                this.state.selectedtopic?.custom && (
                                    <Col sm={6}>
                                        <Form.Group className='required'>
                                            <Form.Label>
                                                {this.props.t('page.tickets.ticket.create.label')}
                                            </Form.Label>
                                            <Form.Control
                                                disabled={this.state.isLoading}
                                                required={false}
                                                type="text"
                                                value={this.state.ticketlabel || ''}
                                                placeholder={this.props.t('common.placeholder.any')}
                                                onChange={(e) => { this.onChangeSetState('ticketlabel', e) }}
                                            />
                                        </Form.Group>
                                    </Col>
                                )
                            }
                        </Row>
                        <Row>
                            <Col sm={this.state.selectedproject ? 6 : 12}>
                                <Form.Group className='required'>
                                    <Form.Label>
                                        {this.props.t('page.tickets.ticket.create.project')}
                                    </Form.Label>
                                    <SelectAsync
                                        t={this.props.t}
                                        api={this.props.api}
                                        isClearable={true}
                                        isMulti={false}
                                        cache={false}
                                        preload={true}
                                        disabled={this.state.isLoading}
                                        endpoint={`/search/project`}
                                        value={this.state.selectedproject || null}
                                        params={{
                                            filter: []
                                        }}
                                        transformData={(data, callback) => {
                                            let newData = data.projects.map(itm => { return { ...itm, value: itm.uuid, label: `${itm.label} (${itm.addressName})` } });
                                            callback(newData)
                                        }}
                                        onChange={(data) => {
                                            this.setState({ selectedproject: data, selecteduser: null });
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            {
                                this.state.selectedproject && (
                                    <Col sm={6}>
                                        <Form.Group className='required'>
                                            <Form.Label>
                                                {this.props.t('page.tickets.ticket.create.customer')}
                                            </Form.Label>
                                            <SelectAsync
                                                t={this.props.t}
                                                api={this.props.api}
                                                isClearable={true}
                                                isMulti={false}
                                                cache={false}
                                                preload={true}
                                                disabled={this.state.isLoading}
                                                endpoint={`/user/list-partial-tickets`}
                                                value={this.state.selecteduser}
                                                params={{
                                                    include: [this.state.selecteduser].filter(Boolean),
                                                    anchors: { project: { uuid: this.state.selectedproject?.value } }
                                                }}
                                                transformData={(data, callback) => {
                                                    let newData = data.users.map(itm => { return { ...itm, value: itm.uuid } });
                                                    callback(newData)
                                                }}
                                                onChange={(data) => {
                                                    this.setState({ selecteduser: data || null })
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                )
                            }
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>
                                        {this.props.t('page.tickets.ticket.create.comment')}
                                    </Form.Label>
                                    <TextEditor
                                        api={this.props.api}
                                        mentions={false}
                                        editorId="custom-create-ticket-editor"
                                        value={this.state.commentcontent || ''}
                                        simpleToolbar={true}
                                        config={{ autoGrow_maxHeight: 150 }}
                                        onChange={
                                            (html) => {
                                                this.setState({ commentcontent: html || null });
                                            }
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="p-20 border-top">
                    <Button
                        variant="success"
                        type="button"
                        onClick={(e) => { this.saveData(); }}
                        disabled={this.state.isLoading || !this.state.selectedproject || !this.state.selecteduser}
                    >
                        {this.props.t('page.tickets.ticket.create.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )

        return (
            <Fragment>
                {modalMain}
            </Fragment>
        )
    }

}

export default ModalCreateTicket
