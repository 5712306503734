import LowerComponent from './lower';
import _ from 'lodash';

class LowerElementsComponent extends LowerComponent {

    handleElementAdd = (elementsKey, element, where, onBeginning) => {
        where = where || this.props;
        if (onBeginning) {
            this.setUpperState(
                { [elementsKey]: [element].concat(where[elementsKey]) }
            );
        } else {
            this.setUpperState(
                { [elementsKey]: where[elementsKey].concat([element]) }
            );
        }
    };

    updateNestedElements(elements, uuid, field, value) {
        let found = false;
        let newElements = elements.map((element) => {
            let updatedElement = { ...element };
            if (found) {
                return element;
            }
            if (uuid !== updatedElement.uuid) {
                if (updatedElement.children) {
                    updatedElement.children = this.updateNestedElements(updatedElement.children, uuid, field, value)
                }
                return updatedElement;
            }
            _.set(updatedElement, field, value);
            found = true;
            return updatedElement;
        });
        return newElements;
    }

    updateNestedElements2(elements, uuid, fieldsValues) {
        let found = false;
        let newElements = elements.map((element) => {
            let updatedElement = { ...element };
            if (found) {
                return element;
            }
            if (uuid !== updatedElement.uuid) {
                if (updatedElement.children) {
                    updatedElement.children = this.updateNestedElements2(updatedElement.children, uuid, fieldsValues)
                }
                return updatedElement;
            }
            return { ...updatedElement, ...fieldsValues };
        });
        return newElements;
    }

    handleElementChange(elementsKey, uuid, field, value, where, callback) {
        where = where || this.props;
        let newElements = this.updateNestedElements(where[elementsKey], uuid, field, value);
        this.setUpperState(
            { [elementsKey]: newElements },
            callback
        );
    }

    handleElementChange2(elementsKey, uuid, fieldsValues, where, callback) {
        where = where || this.props;
        let newElements = [...this.updateNestedElements2(where[elementsKey], uuid, fieldsValues)];
        this.setUpperState(
            { [elementsKey]: newElements },
            callback
        );
    }

    handleElementTrash(elementsKey, uuid, where) {
        where = where || this.props;
        let newElements = where[elementsKey].map((element) => {
            if (uuid !== element.uuid && (uuid !== element.parent?.uuid)) {
                return element;
            }
            return { ...element, trash: true };
        });
        this.setUpperState(
            { [elementsKey]: newElements }
        );
    }

    handleElementTrash2(elementsKey, uuid, where) {
        where = where || this.props;
        return where[elementsKey].map((element) => {
            let updatedElement = { ...element };
            if (updatedElement.children) {
                updatedElement.children = [...this.handleElementTrash2('children', uuid, updatedElement)]
            }
            if (uuid === updatedElement.uuid) {
                return null;
            }
            return updatedElement;
        }).filter(Boolean);
    }

}

export default LowerElementsComponent;