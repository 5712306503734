import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import RoutesList from './pages';
import NotFoundPage from './pages/404';

class Router extends Component {

    render() {
        const {
            location,
            buildLink,
            lang,
            region,
            user,
            hasPermission,
            getStarterLink,
        } = this.props;

        return (
            <Switch
                location={location}
            >
                {
                    user
                        ?
                        (
                            <Redirect from="/" exact to={buildLink(getStarterLink())} />
                        )
                        :
                        (
                            <Redirect from="/" exact to={buildLink('/auth/login/')} />
                        )
                }
                {
                    RoutesList.map(
                        (route) => {
                            const RouteComponent = route.component;
                            const restrictRoute = (route.authOnly && !user);
                            if (!hasPermission(route.includeForPermissions).any) {
                                return;
                            }
                            if (route.excludeForPermissions.length && hasPermission(route.excludeForPermissions).any) {
                                return;
                            }
                            return restrictRoute ?
                                (
                                    <Redirect
                                        key={route.url}
                                        from={`/${lang}/${region}${route.url}`}
                                        to={buildLink('/auth/login/')}
                                    />
                                )
                                :
                                (
                                    <Route
                                        key={route.url}
                                        path={`/:lang/:region${route.match}`}
                                        render={
                                            (props) => (
                                                <RouteComponent {...this.props} {...route.props} {...props} />
                                            )
                                        }
                                    />
                                )
                        })
                }
                {
                    user
                        ?
                        (
                            <Redirect from={`/${lang}/${region}/`} exact to={buildLink(getStarterLink())} />
                        )
                        :
                        (
                            <Redirect from={`/${lang}/${region}/`} exact to={buildLink('/auth/login/')} />
                        )
                }
                <Route
                    render={
                        () => (
                            <NotFoundPage
                                {...this.props}
                            />
                        )
                    }
                />
            </Switch>
        );
    }

}

export default Router;
