import React, { Fragment } from 'react';
import { Row, Col, Modal, Form, Button, Tabs, Tab } from "react-bootstrap";
import Icon from '../icon';
import ReactTooltip from "react-tooltip";
import classnames from 'classnames/dedupe';
import confetti from 'canvas-confetti';
import BaseComponent from '../component/base';
import { signingParties, agreementTypes } from '../../constants';

class ModalSignature extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            modalSignatureParams: false,
            signatureFileCount: 2,
            signatureFileMax: 10,
            signatureFileMin: 1,
            signatureFile0: "",
            signatureFile1: "",
            signatureFile2: "",
            signatureFile3: "",
            signatureFile4: "",
            signatureFile5: "",
            signatureFile6: "",
            signatureFile7: "",
            signatureFile8: "",
            signatureFile9: "",
            invitationMessage: "",
            confirmationMessage: "",
            acceptingConstructor: "",
            signingConstructor: "",
            agreementType: "annex",
            parties: "client",
            agreementComment: "",
            userIdentNumber: ""
        }
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild();
    }

    signatureFileCount(e, delta) {
        let {
            signatureFileCount,
            signatureFileMax,
            signatureFileMin
        } = this.state;

        e.preventDefault();

        if (signatureFileCount + delta >= signatureFileMin && signatureFileCount + delta < signatureFileMax) {
            this.setState({ signatureFileCount: signatureFileCount + delta });
        }
    }

    sendToSignature() {
        if (!this.canSign()) {
            return;
        }
        this.props.setUpperState({ isLoading: true }, () => {
            this.props.api.post(
                `/signature/create${this.props.endpoint}`,
                {
                    "company": {
                        "uuid": this.props.companyUuid,
                    },
                    "offer": {
                        "uuid": this.props.offerUuid,
                    },
                    "project": {
                        "uuid": this.props.projectUuid,
                    },
                    "signature": {
                        "invitationMessage": this.state.invitationMessage,
                        "confirmationMessage": this.state.confirmationMessage,
                        "acceptingConstructor": this.state.acceptingConstructor,
                        "signingConstructor": this.state.signingConstructor,
                        "agreementType": this.state.agreementType,
                        "comment": this.state.agreementComment,
                        "parties": this.state.parties
                    },
                    "user": {
                        "identNumber": this.state.userIdentNumber
                    },
                    "file": {
                        "uuids": [
                            this.state.signatureFile0,
                            this.state.signatureFile1,
                            this.state.signatureFile2,
                            this.state.signatureFile3,
                            this.state.signatureFile4,
                            this.state.signatureFile5,
                            this.state.signatureFile6,
                            this.state.signatureFile7,
                            this.state.signatureFile8,
                            this.state.signatureFile9
                        ].slice(0, this.state.signatureFileCount)
                    }
                },
                (successData) => {
                    this.hideModals(() => {
                        this.hurray();
                        this.props.onSigned({
                            isLoading: false,
                            signatureStatuses: successData.signatureStatuses
                        });
                    });
                },
                (errorData) => {
                    this.props.setUpperState({ isLoading: false });
                    this.props.showToast({
                        errorObject: errorData,
                        title: this.props.t('common.toast.error'),
                        color: "danger"
                    });
                }
            );
        });
    }

    canSign() {
        return !this.props.isLoading
            && this.state.signatureFile0
            && this.state.invitationMessage
            && this.state.confirmationMessage
            && this.state.agreementType
            && (this.state.agreementType !== 'documentation' || this.state.agreementComment)
    }

    hideModals(callback) {
        if (typeof callback !== 'function') {
            callback = () => { }
        }
        this.setState({
            modalSignatureParams: false
        }, () => callback());
    }

    hurray() {
        confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
            zIndex: 9999
        });
    }

    render() {

        const {
            agreementType,
            invitationMessage,
            confirmationMessage,
            userIdentNumber,
            parties,
            agreementComment,
        } = this.state;

        return (
            <Fragment>

                <Modal
                    show={this.props.show}
                    onHide={this.props.onHide}
                    backdrop="static"
                    keyboard={false}
                    scrollable={true}
                    size="md"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('component.modalSignature.title')} - {this.props.header}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs
                            className="m-0 align-content-between nicebar"
                            id="modal-signature"
                        >
                            {
                                this.props.showParties && (
                                    <Tab title={this.props.t('component.modalSignature.tab.parties')} eventKey={`parties`}>
                                        <Row>
                                            {
                                                (this.props.isRegion(['SWEDEN']) && this.props.isSalesProcess) && (
                                                    <Col md={12}>
                                                        <Form.Group className="required">
                                                            <Form.Label>{this.props.t('component.modalSignature.userIdentNumber')}</Form.Label>
                                                            <Form.Control
                                                                required={true}
                                                                disabled={this.props.isLoading}
                                                                value={userIdentNumber}
                                                                onChange={(e) => { this.setState({ userIdentNumber: this.val(e) }) }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                )
                                            }
                                            <Col md={12}>
                                                <Form.Group className="required">
                                                    <Form.Label>{this.props.t('component.modalSignature.parties')}</Form.Label>
                                                    <Form.Control
                                                        required={true}
                                                        as="select"
                                                        custom
                                                        disabled={this.props.isLoading}
                                                        value={parties}
                                                        onChange={(e) => { this.setState({ parties: this.val(e) }) }}
                                                    >
                                                        {
                                                            signingParties.map((itm) => {
                                                                return (
                                                                    <option key={itm} value={itm}>
                                                                        {this.props.t(`component.modalSignature.party.${itm}`)}
                                                                    </option>
                                                                )
                                                            })
                                                        }

                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Tab>
                                )
                            }

                            <Tab title={this.props.t('component.modalSignature.tab.messages')} eventKey={`messages`}>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group className="required">
                                            <Form.Label>{this.props.t('component.modalSignature.invitationMessage')}</Form.Label>
                                            <Form.Control
                                                required={true}
                                                disabled={this.props.isLoading}
                                                as="textarea"
                                                style={{ height: 100 }}
                                                rows="2"
                                                value={invitationMessage}
                                                onChange={(e) => { this.setState({ invitationMessage: this.val(e) }) }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="required">
                                            <Form.Label>{this.props.t('component.modalSignature.confirmationMessage')}</Form.Label>
                                            <Form.Control
                                                required={true}
                                                disabled={this.props.isLoading}
                                                as="textarea"
                                                style={{ height: 100 }}
                                                rows="2"
                                                value={confirmationMessage}
                                                onChange={(e) => { this.setState({ confirmationMessage: this.val(e) }) }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Tab>

                            <Tab title={this.props.t('component.modalSignature.tab.files')} eventKey={`files`}>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group className="required">
                                            <Form.Label>{this.props.t('component.modalSignature.agreementType')}</Form.Label>
                                            <Form.Control
                                                required={true}
                                                as="select"
                                                custom
                                                disabled={this.props.isLoading}
                                                value={agreementType}
                                                onChange={(e) => { this.setState({ agreementType: this.val(e) }) }}
                                            >
                                                <option value="">...</option>
                                                {
                                                    agreementTypes
                                                        .filter(itm => {
                                                            if (this.props.isSalesProcess && itm === 'ata') {
                                                                return false;
                                                            }
                                                            if (!this.props.isSalesProcess && itm === 'alp') {
                                                                return false;
                                                            }
                                                            if (!this.props.isRegion(['SWEDEN']) && ['ata', 'alp'].includes(itm)) {
                                                                return false;
                                                            }
                                                            return true;
                                                        }).map((itm) => {
                                                            return (
                                                                <option key={itm} value={itm}>
                                                                    {this.props.t(`common.agreementType.${itm}`)}
                                                                </option>
                                                            )
                                                        })
                                                }

                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    {
                                        agreementType === 'documentation' && (
                                            <Col md={12}>
                                                <Form.Group className="required">
                                                    <Form.Label>{this.props.t('component.modalSignature.agreementComment')}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        required={true}
                                                        disabled={this.props.isLoading}
                                                        value={agreementComment}
                                                        onChange={(e) => { this.setState({ agreementComment: this.val(e) }) }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )
                                    }
                                    {
                                        [...Array(this.state.signatureFileCount).keys()].map((fileIndex) => {
                                            return (
                                                <Col key={`signatureFile-${fileIndex}`} md={12}>
                                                    <Form.Group className="required">
                                                        <Form.Label>{this.props.t(`component.modalSignature.signatureFile${fileIndex}`)}</Form.Label>
                                                        <Form.Control
                                                            required={true}
                                                            disabled={this.props.isLoading}
                                                            as="select"
                                                            custom
                                                            value={this.state[`signatureFile${fileIndex}`]}
                                                            onChange={(e) => { this.setState({ [`signatureFile${fileIndex}`]: e.target.value }) }}
                                                        >
                                                            <option value="">...</option>
                                                            {
                                                                this.props.files
                                                                    .filter(itm => !itm.trash)
                                                                    .sort((a, b) => (a.final > b.final) ? -1 : ((b.final > a.final) ? 1 : 0))
                                                                    .map((fileObj) => {
                                                                        const file = fileObj.file ? { ...fileObj.file } : { ...fileObj };
                                                                        if (file.ident.indexOf("budget") > -1) {
                                                                            return null;
                                                                        }
                                                                        return (
                                                                            <option key={`signatureFile-${fileIndex}-${file.uuid}`} value={file.uuid}>
                                                                                {`${file.filename} ${file.final ? '🔵' : ''}`}
                                                                            </option>
                                                                        )
                                                                    })
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            )
                                        })
                                    }
                                    <Col md={12}>
                                        {this.props.t('component.modalSignature.addRemove')}
                                        <button
                                            type="button"
                                            className={classnames("btn pr-0 pl-0")}
                                            onClick={(e) => { this.signatureFileCount(e, 1) }}
                                        >
                                            <Icon name="plus" />
                                        </button>
                                        <button
                                            type="button"
                                            className={classnames("btn pr-0 pl-0 ml-10")}
                                            onClick={(e) => { this.signatureFileCount(e, -1) }}
                                        >
                                            <Icon name="minus" />
                                        </button>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Modal.Body>
                    <Modal.Footer className="p-20 border-top">
                        <Button variant="success" disabled={!this.canSign()} onClick={() => { this.sendToSignature() }}>
                            {this.props.t('component.modalSignature.send')}
                        </Button>
                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}
export default ModalSignature;