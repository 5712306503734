import React, { Component, Fragment } from 'react';
import { Row, Col, Modal, Table, Alert } from "react-bootstrap";

class ModalBcWarnings extends Component {

    render() {
        return (
            <Fragment>
                <Modal
                    show={this.props.showModal}
                    onHide={() => { this.props.onModalHide() }}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.modalTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={12}>
                                <Alert variant="danger" className="mb-15">
                                    {this.props.t('alert.bcImportHasWarnings')}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Table striped bordered hover responsive size="sm" className="sticky-thead">
                                    <thead>
                                        <tr>
                                            <th>{this.props.t('common.text.name')}</th>
                                            <th>{this.props.t('common.text.error')}</th>
                                            <th>{this.props.t('common.text.data')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.bcWarnings.map((itm, idx) => (
                                            <tr key={`bc-warning-${idx}`}>
                                                <td>
                                                    <pre>
                                                        {JSON.stringify(itm.name, null, 2)}
                                                    </pre>
                                                </td>
                                                <td>
                                                    <pre>
                                                        {JSON.stringify(itm.error, null, 2)}
                                                    </pre>
                                                </td>
                                                <td>
                                                    <pre>
                                                        {JSON.stringify(itm.data, null, 2)}
                                                    </pre>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Fragment>
        )
    }

}

export default ModalBcWarnings
