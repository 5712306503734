import React, { Fragment } from 'react';
import LowerElementsComponent from '../../components/component/lower-elements';
import Designer from '../../components/maps/designer';

class InputDesigner extends LowerElementsComponent {


    render() {
        return (
            <Fragment>
                <Designer
                    enableDesigner={true}
                    defaultZoom={20}
                    fullAddress={this.props.fullAddress}
                    defaultCenter={this.props.mapCenter}
                    showToast={this.props.showToast}
                    markers={this.props.mapMarkers}
                    layers={['sketch']}
                    t={this.props.t}
                    api={this.props.api}
                    utils={this.props.utils}
                    setUpperState={this.props.setUpperState}
                    pvWidth={this.props.pvWidth}
                    pvHeight={this.props.pvHeight}
                    pvThick={this.props.pvThick}
                    pvPower={this.props.pvPower}
                    bipvArea={this.props.bipvArea}
                    passiveArea={this.props.passiveArea}
                    passiveAreaExtra={this.props.passiveAreaExtra}
                    pvPowers={this.props.pvPowers}
                    pvDesign={this.props.pvDesign}
                    roofType={this.props.roofType}
                    roofTypes={this.props.roofTypes}
                    roofPitch={this.props.roofPitch}
                    roofOrientation={this.props.roofOrientation}
                    realAnnualAssumedEnergyProductionKwH={this.props.realAnnualAssumedEnergyProductionKwH}
                    reCalcFinancing={this.props.reCalcFinancing}
                    isLoading={this.props.isLoading}
                    isDisabled={this.props.closed}
                    isRegion={this.props.isRegion}
                    reFetchTokens={this.props.reFetchTokens}
                    tokenRot={this.props.tokenRot}
                    tokenGronTeknik={this.props.tokenGronTeknik}
                    hipLength={this.props.hipLength}
                    pvHorizontal={this.props.pvHorizontal}
                    pvVertical={this.props.pvVertical}
                />
            </Fragment>
        );
    }
}
export default InputDesigner;