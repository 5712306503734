import './style.scss';
import React from 'react';
import { Spinner } from 'react-bootstrap'

class IsLoading extends React.PureComponent {

    render() {
        let marginLeft = 0;
        let yayBar = document.getElementsByClassName("yaybar");
        if (yayBar.length && this.props.withMarginLeft) {
            marginLeft = (parseInt(yayBar[0].offsetWidth) || 0) / 2;
        }
        return (
            <div className="is-loading" style={this.props.style || {}}>
                <div className="spinner-holder" style={{ left: `calc(50% + ${marginLeft}px)` }}>
                    <Spinner animation="border" />
                </div>
            </div>
        )
    }
}

export { Spinner, IsLoading }