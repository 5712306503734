import './style.scss';

import React, { Component } from 'react';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import Content from './content';
import { Button } from 'react-bootstrap';
import Icon from '../../components/icon';

class Tasks extends Component {
    render() {
        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={
                        {
                            '#': this.props.t('page.tasks.shortTitle'),
                        }
                    }
                    meta={{ title: this.props.t('page.tasks.longTitle') }}
                    t={this.props.t}
                >
                    <h1>
                        {this.props.t('page.tasks.longTitle')}
                        <Button variant='link' onClick={() => { this.props.api.dispatchEvent('FETCH_TASKS', {}) }}>
                            <Icon name="refresh-cw" />
                        </Button>
                    </h1>
                </PageTitle>
                <PageContent>
                    <Content {...this.props} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default Tasks;
