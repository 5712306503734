import './style.scss';

import React, { Component } from 'react';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import Content from './content';

class Asset extends Component {
    render() {
        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={
                        {
                            '#': this.props.t('page.asset.shortTitle'),
                        }
                    }
                    meta={{ title: this.props.t('page.asset.longTitle') }}
                    t={this.props.t}
                >
                    <h1>
                        {this.props.t('page.asset.longTitle')}
                    </h1>
                </PageTitle>
                <PageContent>
                    <Content
                        {...this.props}
                        fields={[
                            {
                                "ident": "basicDataSection",
                                "fields": [
                                    "assetType",
                                    "ident",
                                    "serialNumber",
                                    "name",
                                ]
                            },
                            {
                                "ident": "ownerDataSection",
                                "fields": [
                                    "nameOfOwningCompany",
                                    "countryCodeOfUser",
                                    "firstNameOfUser",
                                    "lastNameOfUser",
                                    "emailAddressOfUser",
                                    "mobilePhoneOfUser",
                                    "positionOfUser",
                                    "emailAddressOfSupervisor",
                                ]
                            },
                            {
                                "ident": "contractDataSection",
                                "fields": [
                                    "contractType",
                                    "currencyCode",
                                    "totalValue",
                                    "entryFee",
                                    "monthlyFee",
                                    "redemptionValue",
                                    "purchaseDate",
                                    "expirationDate",
                                    "grantName"
                                ]
                            },
                            {
                                "ident": "detailsDataSection",
                                "fields": [
                                    "pinOfDevice",
                                    "pukOfDevice"
                                ]
                            },
                        ]} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default Asset;
