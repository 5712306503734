import './style.scss';

import React, { Component } from 'react';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import Content from './content';

class Companies extends Component {
    render() {
        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={
                        {
                            '#': this.props.t('page.companies.shortTitle'),
                        }
                    }
                    meta={{ title: this.props.t('page.companies.longTitle') }}
                    t={this.props.t}
                >
                    <h1>
                        {this.props.t('page.companies.longTitle')}
                    </h1>
                </PageTitle>
                <PageContent>
                    <Content {...this.props} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default Companies;
