import './style.scss';

import React from 'react';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import Content from './content';
import BaseComponent from '../../components/component/base';
import { Spinner } from 'react-bootstrap';

class Roles extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            roles: [],
            permissions: [],
            roleUuid: ""
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({ isLoading: true });
        this.props.api.post(
            `/role/list`,
            {},
            (data) => {
                const roles = data.roles.sort((a, b) => a.position > b.position);
                const permissions = data.permissions.sort((a, b) => a.position > b.position);
                this.setState({
                    roles,
                    permissions,
                    roleUuid: data.roles[0].uuid,
                    isLoading: false
                });
            }
        );
    }

    saveData() {
        this.setState({ isLoading: true });
        this.props.api.post(
            `/role/bulk-update`,
            {
                roles: this.state.roles
            },
            (data) => {
                const roles = data.roles.sort((a, b) => a.position > b.position);
                const permissions = data.permissions.sort((a, b) => a.position > b.position);
                this.setState({
                    roles,
                    permissions,
                    isLoading: false
                });
            }
        );
    }

    render() {
        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={
                        {
                            '#': this.props.t('page.roles.shortTitle'),
                        }
                    }
                    meta={{ title: this.props.t(`page.roles.longTitle`) }}
                    t={this.props.t}
                >
                    <h1>
                        {this.props.t('page.roles.longTitle')}
                    </h1>
                </PageTitle>
                <PageContent>
                    {
                        this.state.isLoading ?
                            (
                                <div className="text-center p-30">
                                    <Spinner animation="border" />
                                </div>
                            )
                            :
                            (
                                <Content
                                    upperState={this.state}
                                    setUpperState={(data) => this.setState(data)}
                                    saveData={() => this.saveData()}
                                    {...this.props}
                                />
                            )
                    }

                </PageContent>
            </PageWrap>
        );
    }
}

export default Roles;
