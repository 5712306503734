import BaseComponent from "../../components/component/base";
import FinancingPL from "./financing-pl";
import FinancingDE from "./financing-de";
import FinancingSE from "./financing-se";

class Financing extends BaseComponent {

    render() {

        return (
            <>
                <h2>
                    {this.props.t('page.calculation.financing.title')}
                </h2>

                {
                    this.props.isRegion(['GERMANY']) ? (
                        <FinancingDE {...this.props} />
                    ) : this.props.isRegion(['SWEDEN']) ? (
                        <FinancingSE {...this.props} />
                    ) : (
                        <></>
                    )
                }
            </>
        )
    }
}

export default Financing;