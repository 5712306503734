import React, { Component, Fragment } from 'react';
import { Row, Col, Modal, Form, Button, Tabs, Tab } from "react-bootstrap";
import SelectAsync from '../../components/select/async';
import confetti from 'canvas-confetti';
import { IsLoading } from '../../components/spinner';
import { appEnv, appEnvCurrent } from '../../constants';

class ModalConvertToProject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            budgetFile: '',
            offerFile: '',
            alpFile: '',
            agreementFile: '',
            annexFile: '',
            additionalFiles: [],
            clientInfoPermit: false,
            closeOffer: false,
            createJob: true,
            createProject: true,
            jobTemplate: null,
            jobNumberingSeries: null,

            clientAddressProvinceName: '',
            clientAddressPostalCode: '',
            clientAddressCityName: '',
            clientAddressStreetAddress: '',
            clientAddressStreetNumber: '',
            clientAddressApartmentNumber: '',

            fullName: '',
            mobilePhone: '',
            emailAddress: '',

            provinceName: '',
            postalCode: '',
            cityName: '',
            streetAddress: '',
            streetNumber: '',
            apartmentNumber: '',

            projectSince: '',
            contractSigned: '',
        }
    }

    componentDidMount() {
        const additionalFiles = this.filterFiles('addon');
        this.setState({
            additionalFiles: additionalFiles,
            fullName: this.props.fullName || '',
            mobilePhone: this.props.mobilePhone || '',
            emailAddress: this.props.emailAddress || '',
            provinceName: this.props.provinceName || '',
            postalCode: this.props.postalCode || '',
            cityName: this.props.cityName || '',
            streetAddress: this.props.streetAddress || '',
            streetNumber: this.props.streetNumber || '',
            apartmentNumber: this.props.apartmentNumber || '',
        });
    }

    componentDidUpdate(prevProps) {
        let newState = {};
        let setState = false;
        ['fullName', 'mobilePhone', 'emailAddress', 'provinceName', 'postalCode',
            'cityName', 'streetAddress', 'streetNumber', 'apartmentNumber']
            .forEach(itm => {
                if (this.props[itm] !== prevProps[itm]) {
                    newState = { ...newState, [itm]: this.props[itm] };
                    setState = true;
                }
            });
        if (setState) {
            this.setState(newState);
        }
    }

    checkOneFile(uuid, checkedValue) {
        let newFiles = this.state.additionalFiles;
        if (checkedValue) {
            newFiles = newFiles.concat([{ uuid: uuid }])
        } else {
            newFiles = newFiles.filter((item) => { return item.uuid !== uuid })
        }
        this.setState({ additionalFiles: newFiles });
    }

    filterFiles(type, props) {
        return (props || this.props).files
            .filter(itm => !itm.trash && itm.ident.indexOf(type) > -1)
            .sort((a, b) => (a.final > b.final) ? -1 : ((b.final > a.final) ? 1 : 0))
    }

    hurray() {
        confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
            zIndex: 9999
        });
    }

    onSubmit(e) {
        if (e) {
            e.preventDefault();
        }
        const {
            budgetFile,
            offerFile,
            alpFile,
            agreementFile,
            annexFile,
            additionalFiles,
            jobTemplate,
            jobNumberingSeries,
            closeOffer,
            createJob,
            createProject,

            fullName,
            mobilePhone,
            emailAddress,

            clientAddressProvinceName,
            clientAddressPostalCode,
            clientAddressCityName,
            clientAddressStreetAddress,
            clientAddressStreetNumber,
            clientAddressApartmentNumber,

            provinceName,
            postalCode,
            cityName,
            streetAddress,
            streetNumber,
            apartmentNumber,

            clientInfoPermit,
            projectSince,
            contractSigned,
        } = this.state;

        const {
            plannedConstructionFrom,
            plannedConstructionUntil
        } = this.props;

        this.props.closeProject({
            files: {
                final: {
                    budget: {
                        uuid: budgetFile,
                    },
                    offer: {
                        uuid: offerFile,
                    },
                    alp: {
                        uuid: alpFile,
                    },
                    agreement: {
                        uuid: agreementFile,
                    },
                    annex: {
                        uuid: annexFile,
                    },
                    addons: {
                        uuids: additionalFiles.map(f => { return f.uuid }),
                    },
                }
            },
            offer: {
                plannedConstructionFrom,
                plannedConstructionUntil
            },
            project: {
                clientInfoPermit: clientInfoPermit,
                since: projectSince,
                contractSigned: contractSigned,
            },
            address: {
                client: {
                    fullName,
                    mobilePhone,
                    emailAddress,
                    clientAddressProvinceName,
                    clientAddressPostalCode,
                    clientAddressCityName,
                    clientAddressStreetAddress,
                    clientAddressStreetNumber,
                    clientAddressApartmentNumber,
                },
                offer: {
                    fullName,
                    mobilePhone,
                    emailAddress,
                    provinceName,
                    postalCode,
                    cityName,
                    streetAddress,
                    streetNumber,
                    apartmentNumber,
                }
            },
            options: {
                jobTemplate: jobTemplate?.value,
                jobNumberingSeries: jobNumberingSeries?.value,
                closeOffer: closeOffer,
                createJob: createJob,
                createProject: createProject
            }
        }, () => {
            this.hurray();
            this.props.onHide();
        });
    }

    cannotGenerateProject() {
        return (
            this.props.isLoading ||
            !this.state.fullName ||
            !this.state.mobilePhone ||

            !this.state.clientAddressPostalCode ||
            !this.state.clientAddressCityName ||
            !this.state.clientAddressStreetAddress ||
            !this.state.clientAddressStreetNumber ||

            !this.state.postalCode ||
            !this.state.cityName ||
            !this.state.streetAddress ||
            !this.state.streetNumber ||

            !this.props.plannedConstructionFrom ||
            !this.state.budgetFile ||
            !this.state.offerFile ||
            !this.state.contractSigned ||
            (this.props.isRegion(['POLAND']) && !this.state.agreementFile) ||
            (this.props.isRegion(['SWEDEN']) && !this.state.alpFile) ||
            (this.props.isRegion(['SWEDEN']) && !this.state.clientInfoPermit) ||
            (this.props.isRegion(['SWEDEN']) && !this.state.projectSince)
        );
    }

    render() {

        const loadingView = this.props.isLoading && (
            <IsLoading
                style={{ position: 'absolute' }}
            />
        )

        const markAsRequired = !(this.props.isRegion(['GERMANY'])) && this.props.hasPermission(['ADMIN']).none;

        return (
            <Fragment>
                <Modal
                    show={this.props.show}
                    onHide={() => { this.props.onHide() }}
                    backdrop="static"
                    keyboard={false}
                    size="md"
                    centered
                >
                    {loadingView}
                    <Form method='post' onSubmit={(e) => { this.onSubmit(e) }} onInvalid={(e) => { this.props.onInvalid(e) }}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.props.t('component.modalConvert.title')}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Tabs
                                className="m-0 align-content-between nicebar"
                                id="modal-convert-to-project"
                            >
                                <Tab title={this.props.t('component.modalConvert.tab.address')} eventKey={`address`}>

                                    <Form.Row>
                                        <Form.Group as={Col} sm={3} className="required">
                                            <Form.Label>{this.props.t('component.modalConvert.fullName')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={this.props.isLoading}
                                                required={true}
                                                value={this.state.fullName || ""}
                                                onChange={(e) => { this.setState({ fullName: e.target.value }) }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={3} className="required">
                                            <Form.Label>
                                                {this.props.t('component.modalConvert.mobilePhone')}
                                            </Form.Label>
                                            <Form.Control
                                                required={true}
                                                type="text"
                                                disabled={this.props.isLoading}
                                                value={this.state.mobilePhone || ""}
                                                onChange={(e) => { this.setState({ mobilePhone: e.target.value }) }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={6} className="required">
                                            <Form.Label>
                                                {this.props.t('component.modalConvert.emailAddress')}
                                            </Form.Label>
                                            <Form.Control
                                                required={true}
                                                type="text"
                                                disabled={this.props.isLoading}
                                                value={this.state.emailAddress || ""}
                                                onChange={(e) => { this.setState({ emailAddress: e.target.value }) }}
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                    <hr />

                                    <Form.Row>
                                        <Form.Group as={Col} md={3}>
                                            <Form.Label>{this.props.t('component.modalConvert.provinceName')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={this.props.isLoading}
                                                value={this.state.clientAddressProvinceName || ""}
                                                onChange={(e) => { this.setState({ clientAddressProvinceName: e.target.value }) }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={3} className="required">
                                            <Form.Label>
                                                {this.props.t('component.modalConvert.postalCode')}
                                            </Form.Label>
                                            <Form.Control
                                                required={true}
                                                type="text"
                                                disabled={this.props.isLoading}
                                                value={this.state.clientAddressPostalCode || ""}
                                                onChange={(e) => { this.setState({ clientAddressPostalCode: e.target.value }) }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} className="required">
                                            <Form.Label>
                                                {this.props.t('component.modalConvert.cityName')}
                                            </Form.Label>
                                            <Form.Control
                                                required={true}
                                                type="text"
                                                disabled={this.props.isLoading}
                                                value={this.state.clientAddressCityName || ""}
                                                onChange={(e) => { this.setState({ clientAddressCityName: e.target.value }) }}
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} md={6} className="required">
                                            <Form.Label>
                                                {this.props.t('component.modalConvert.streetAddress')}
                                            </Form.Label>
                                            <Form.Control
                                                required={true}
                                                type="text"
                                                disabled={this.props.isLoading}
                                                value={this.state.clientAddressStreetAddress || ""}
                                                onChange={(e) => { this.setState({ clientAddressStreetAddress: e.target.value }) }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={3} className="required">
                                            <Form.Label>
                                                {this.props.t('component.modalConvert.streetNumber')}
                                            </Form.Label>
                                            <Form.Control
                                                required={true}
                                                type="text"
                                                disabled={this.props.isLoading}
                                                value={this.state.clientAddressStreetNumber || ""}
                                                onChange={(e) => { this.setState({ clientAddressStreetNumber: e.target.value }) }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={3}>
                                            <Form.Label>
                                                {this.props.t('component.modalConvert.apartmentNumber')}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={this.props.isLoading}
                                                value={this.state.clientAddressApartmentNumber || ""}
                                                onChange={(e) => { this.setState({ clientAddressApartmentNumber: e.target.value }) }}
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                    {
                                        (this.props.isRegion(['SWEDEN'])) && (
                                            <>
                                                <hr />
                                                <Form.Group className="required">
                                                    <Form.Check
                                                        custom
                                                        required={true}
                                                        key={`clientInfoPermit`}
                                                        id={`clientInfoPermit`}
                                                        label={<>{this.props.t('common.info.clientInfoPermit')}<span className='text-danger'>*</span></>}
                                                        disabled={this.props.isLoading}
                                                        checked={this.state.clientInfoPermit || false}
                                                        onChange={(e) => { this.setState({ clientInfoPermit: !this.state.clientInfoPermit }) }}
                                                    />
                                                </Form.Group>
                                            </>
                                        )
                                    }
                                </Tab>

                                <Tab title={this.props.t('component.modalConvert.tab.locationAddress')} eventKey={`locationAddress`}>
                                    <Form.Row>
                                        <Form.Group as={Col} md={3}>
                                            <Form.Label>{this.props.t('component.modalConvert.provinceName')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={this.props.isLoading}
                                                value={this.state.provinceName || ""}
                                                onChange={(e) => { this.setState({ provinceName: e.target.value }) }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={3} className="required">
                                            <Form.Label>
                                                {this.props.t('component.modalConvert.postalCode')}
                                            </Form.Label>
                                            <Form.Control
                                                required={true}
                                                type="text"
                                                disabled={this.props.isLoading}
                                                value={this.state.postalCode || ""}
                                                onChange={(e) => { this.setState({ postalCode: e.target.value }) }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} className="required">
                                            <Form.Label>
                                                {this.props.t('component.modalConvert.cityName')}
                                            </Form.Label>
                                            <Form.Control
                                                required={true}
                                                type="text"
                                                disabled={this.props.isLoading}
                                                value={this.state.cityName || ""}
                                                onChange={(e) => { this.setState({ cityName: e.target.value }) }}
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} md={6} className="required">
                                            <Form.Label>
                                                {this.props.t('component.modalConvert.streetAddress')}
                                            </Form.Label>
                                            <Form.Control
                                                required={true}
                                                type="text"
                                                disabled={this.props.isLoading}
                                                value={this.state.streetAddress || ""}
                                                onChange={(e) => { this.setState({ streetAddress: e.target.value }) }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={3} className="required">
                                            <Form.Label>
                                                {this.props.t('component.modalConvert.streetNumber')}
                                            </Form.Label>
                                            <Form.Control
                                                required={true}
                                                type="text"
                                                disabled={this.props.isLoading}
                                                value={this.state.streetNumber || ""}
                                                onChange={(e) => { this.setState({ streetNumber: e.target.value }) }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={3}>
                                            <Form.Label>
                                                {this.props.t('component.modalConvert.apartmentNumber')}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={this.props.isLoading}
                                                value={this.state.apartmentNumber || ""}
                                                onChange={(e) => { this.setState({ apartmentNumber: e.target.value }) }}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </Tab>

                                <Tab title={this.props.t('component.modalConvert.tab.details')} eventKey={`details`}>

                                    {
                                        (this.props.isRegion(['SWEDEN'])) && (
                                            <Row>
                                                <Col sm={12} md={12}>
                                                    <Form.Group className="required">
                                                        <Form.Label>{this.props.t('page.calculation.addons.projectSince')}</Form.Label>
                                                        <Form.Control
                                                            required={true}
                                                            disabled={this.props.isLoading}
                                                            type="date"
                                                            value={this.state.projectSince || ""}
                                                            onChange={(e) => { this.setState({ [`projectSince`]: e.target.value }) }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        )
                                    }

                                    <Row>
                                        <Col sm={12} md={12}>
                                            <Form.Group className="required">
                                                <Form.Label>{this.props.t('page.calculation.addons.contractSigned')}</Form.Label>
                                                <Form.Control
                                                    required={true}
                                                    disabled={this.props.isLoading}
                                                    type="date"
                                                    value={this.state.contractSigned || ""}
                                                    onChange={(e) => { this.setState({ [`contractSigned`]: e.target.value }) }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="required">
                                                <Form.Label>{this.props.t('page.calculation.addons.plannedConstructionFrom')}</Form.Label>
                                                <Form.Control
                                                    required={true}
                                                    disabled={this.props.isLoading}
                                                    type="date"
                                                    value={this.props.plannedConstructionFrom || ""}
                                                    onChange={(e) => { this.props.setUpperStateNoReCalc({ [`plannedConstructionFrom`]: e.target.value }) }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className="">
                                                <Form.Label>{this.props.t('page.calculation.addons.plannedConstructionUntil')}</Form.Label>
                                                <Form.Control
                                                    required={false}
                                                    disabled={this.props.isLoading}
                                                    type="date"
                                                    value={this.props.plannedConstructionUntil || ""}
                                                    onChange={(e) => { this.props.setUpperStateNoReCalc({ [`plannedConstructionUntil`]: e.target.value }) }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12} md={6}>
                                            <Form.Group className={this.state.createJob ? "required" : ""}>
                                                <Form.Label>{this.props.t(`component.modalConvert.jobTemplate`)}</Form.Label>
                                                <SelectAsync
                                                    t={this.props.t}
                                                    api={this.props.api}
                                                    isClearable={true}
                                                    isSearchable={false}
                                                    cache={true}
                                                    preload={true}
                                                    disabled={this.props.isLoading}
                                                    endpoint={`/gate/job-templates`}
                                                    value={this.state.jobTemplate}
                                                    onChange={(data) => {
                                                        this.setState({ jobTemplate: data });
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group className={this.state.createJob ? "required" : ""}>
                                                <Form.Label>{this.props.t(`component.modalConvert.jobNumberingSeries`)}</Form.Label>
                                                <SelectAsync
                                                    t={this.props.t}
                                                    api={this.props.api}
                                                    isClearable={true}
                                                    isSearchable={false}
                                                    cache={true}
                                                    preload={true}
                                                    disabled={this.props.isLoading}
                                                    endpoint={`/gate/job-numbering-series`}
                                                    value={this.state.jobNumberingSeries}
                                                    onChange={(data) => {
                                                        this.setState({ jobNumberingSeries: data });
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab>

                                <Tab title={this.props.t('component.modalConvert.tab.files')} eventKey={`files`}>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group className="required">
                                                <Form.Label>{this.props.t(`component.modalConvert.budgetFile`)}</Form.Label>
                                                <Form.Control
                                                    required={true}
                                                    disabled={this.props.isLoading}
                                                    as="select"
                                                    custom
                                                    value={this.state[`budgetFile`]}
                                                    onChange={(e) => { this.setState({ [`budgetFile`]: e.target.value }) }}
                                                >
                                                    <option value="">...</option>
                                                    {
                                                        this.filterFiles('budget').concat(this.filterFiles('addon')).map((file) => {
                                                            return (
                                                                <option key={`budgetFile-${file.uuid}`} value={file.uuid}>
                                                                    {`${file.filename} ${file.final ? '🔵' : ''}`}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <Form.Group className='required'>
                                                <Form.Label>{this.props.t(`component.modalConvert.offerFile`)}</Form.Label>
                                                <Form.Control
                                                    disabled={this.props.isLoading}
                                                    as="select"
                                                    custom
                                                    value={this.state[`offerFile`]}
                                                    onChange={(e) => { this.setState({ [`offerFile`]: e.target.value }) }}
                                                >
                                                    <option value="">...</option>
                                                    {
                                                        this.filterFiles('offer')
                                                            .concat(this.filterFiles('annex'))
                                                            .concat(this.filterFiles('signed'))
                                                            .concat(this.filterFiles('sealed'))
                                                            .concat(this.filterFiles('addon')).map((file) => {
                                                                return (
                                                                    <option key={`offerFile-${file.uuid}`} value={file.uuid}>
                                                                        {`${file.filename} ${file.final ? '🔵' : ''}`}
                                                                    </option>
                                                                )
                                                            })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <Form.Group className={this.props.isRegion(['POLAND']) ? "required" : ''}>
                                                <Form.Label>{this.props.t(`component.modalConvert.agreementFile`)}</Form.Label>
                                                <Form.Control
                                                    required={this.props.isRegion(['POLAND'])}
                                                    disabled={this.props.isLoading}
                                                    as="select"
                                                    custom
                                                    value={this.state[`agreementFile`]}
                                                    onChange={(e) => { this.setState({ [`agreementFile`]: e.target.value }) }}
                                                >
                                                    <option value="">...</option>
                                                    {
                                                        this.filterFiles('agreement')
                                                            .concat(this.filterFiles('annex'))
                                                            .concat(this.filterFiles('signed'))
                                                            .concat(this.filterFiles('sealed'))
                                                            .concat(this.filterFiles('addon'))
                                                            .map((file) => {
                                                                return (
                                                                    <option key={`agreementFile-${file.uuid}`} value={file.uuid}>
                                                                        {`${file.filename} ${file.final ? '🔵' : ''}`}
                                                                    </option>
                                                                )
                                                            })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <Form.Group>
                                                <Form.Label>{this.props.t(`component.modalConvert.annexFile`)}</Form.Label>
                                                <Form.Control
                                                    disabled={this.props.isLoading}
                                                    as="select"
                                                    custom
                                                    value={this.state[`annexFile`]}
                                                    onChange={(e) => { this.setState({ [`annexFile`]: e.target.value }) }}
                                                >
                                                    <option value="">...</option>
                                                    {
                                                        this.filterFiles('agreement')
                                                            .concat(this.filterFiles('annex'))
                                                            .concat(this.filterFiles('signed'))
                                                            .concat(this.filterFiles('sealed'))
                                                            .concat(this.filterFiles('addon'))
                                                            .map((file) => {
                                                                return (
                                                                    <option key={`agreementFile-${file.uuid}`} value={file.uuid}>
                                                                        {`${file.filename} ${file.final ? '🔵' : ''}`}
                                                                    </option>
                                                                )
                                                            })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>

                                        {
                                            (this.props.isRegion(['SWEDEN'])) && (
                                                <Col sm={12} md={6}>
                                                    <Form.Group className='required'>
                                                        <Form.Label>{this.props.t(`component.modalConvert.alpFile`)}</Form.Label>
                                                        <Form.Control
                                                            disabled={this.props.isLoading}
                                                            as="select"
                                                            custom
                                                            value={this.state[`alpFile`]}
                                                            onChange={(e) => { this.setState({ [`alpFile`]: e.target.value }) }}
                                                        >
                                                            <option value="">...</option>
                                                            {
                                                                this.filterFiles('alp-blankett')
                                                                    .concat(this.filterFiles('addon'))
                                                                    .map((file) => {
                                                                        return (
                                                                            <option key={`alpFile-${file.uuid}`} value={file.uuid}>
                                                                                {`${file.filename} ${file.final ? '🔵' : ''}`}
                                                                            </option>
                                                                        )
                                                                    })
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }

                                        <Col sm={12}>
                                            <Form.Group>
                                                <Form.Label>{this.props.t(`component.modalConvert.additionalFiles`)}</Form.Label>
                                                {
                                                    this.filterFiles('addon').length ? this.filterFiles('addon').map((file) => {
                                                        return (
                                                            <Form.Check
                                                                custom
                                                                key={`additionalFile-${file.uuid}`}
                                                                id={`additionalFile-${file.uuid}`}
                                                                checked={this.state.additionalFiles.some(e => e.uuid === file.uuid)}
                                                                label={`${file.filename} ${file.final ? '🔵' : ''}`}
                                                                onChange={event => {
                                                                    this.checkOneFile(file.uuid, event.target.checked);
                                                                }}
                                                            />
                                                        )
                                                    }) : (
                                                        <p>---</p>
                                                    )
                                                }
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab>

                                <Tab title={this.props.t('component.modalConvert.tab.options')} eventKey={`options`}>
                                    <Row>
                                        <Col sm={4}>
                                            <Form.Group>
                                                <Form.Check
                                                    custom
                                                    key={`closeOffer`}
                                                    id={`closeOffer`}
                                                    checked={this.state.closeOffer}
                                                    disabled={markAsRequired}
                                                    label={this.props.t(`component.modalConvert.closeOffer`)}
                                                    onChange={event => {
                                                        this.setState({ closeOffer: !this.state.closeOffer })
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Group>
                                                <Form.Check
                                                    custom
                                                    key={`createJob`}
                                                    id={`createJob`}
                                                    checked={this.state.createJob}
                                                    disabled={markAsRequired}
                                                    label={this.props.t(`component.modalConvert.createJob`)}
                                                    onChange={event => {
                                                        this.setState({ createJob: !this.state.createJob })
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Group>
                                                <Form.Check
                                                    custom
                                                    key={`createProject`}
                                                    id={`createProject`}
                                                    checked={this.state.createProject}
                                                    disabled={markAsRequired}
                                                    label={this.props.t(`component.modalConvert.createProject`)}
                                                    onChange={event => {
                                                        this.setState({ createProject: !this.state.createProject })
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Modal.Body>

                        <Modal.Footer className="p-20 border-top">
                            <Button
                                variant="success"
                                disabled={this.props.isLoading || this.cannotGenerateProject()}
                                type="submit"
                            >
                                {this.props.t('page.calculation.files.modal.fillFormParams.generate')}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        )
    }

}

export default ModalConvertToProject
