import './style.scss';
import React, { Component } from 'react'
import Dropzone from 'react-dropzone-uploader'
import { appEnv, appEnvCurrent, s3Bucket } from '../../constants';

export class DropzoneWrapper extends Component {

    constructor(props) {
        super(props);
        this.processedFiles = [];
        this.totalProgress = [];
        this.getUploadParams = this.getUploadParams.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async getUploadParams({ file, meta }) {
        return this.getUploadParams__future({ file, meta })
    }

    async getUploadParams__future({ file, meta }) {
        const presigned = await this.props.api.fetch({
            method: 'POST',
            url: '/file/presigned-upload',
            data: {
                ...this.props.params,
                _file: {
                     'bucket': s3Bucket.BUCKET_SUNROOF_BP, // !! REAL
                    // 'bucket': s3Bucket.BUCKET_SUNDESK_TEST_PUBLIC, // !! TEST
                    'lastMod': file.lastModified,
                    'lastModDate': file.lastModifiedDate,
                    'name': file.name,
                    'size': file.size,
                    'type': file.type,
                }
            }
        });

        let formData = new FormData();
        const { formAttributes, formInputs } = presigned.data.follow;
        Object.keys(formInputs).forEach(key => {
            formData.append(key, formInputs[key]);
        })
        // IMPORTANCE: https://stackoverflow.com/a/15235866
        // AWS ignores all fields in the request after the file field, so all other fields must appear before the file.
        formData.append('file', file);

        const data = {
            url: formAttributes.action,
            body: formData,
            meta: { file: presigned.data.file },
        }
        return data;
    }

    async getUploadParams__legacy({ file, meta }) {
        let formData = new FormData();
        let endpoint = this.props.api.endpoint('/file/upload');
        formData.append('files[]', file, file.name);
        formData.append('_input', JSON.stringify(this.props.params));
        return { url: endpoint, body: formData, headers: { "Authorization": `Bearer ${this.props.bearerToken}` } }
    }

    updateProgress(currentProgress) {
        if (this.props.progressEventName) {
            window.dispatchEvent(new CustomEvent(this.props.progressEventName, { 'detail': currentProgress }), true);
        }
    }

    handleChangeStatus({ meta, file }, status, data) {
        if (typeof this.props.onChangeStatus === "function") {
            this.props.onChangeStatus(status, meta, file, data);
        }
        if (status === "preparing") {
            this.updateProgress(0);
        }
        if (status === "uploading") {
            data.forEach((dataObj, dataIndex) => {
                if (dataObj.xhr && dataObj.xhr.upload) {
                    dataObj.xhr.upload.addEventListener("progress",
                        (event) => {
                            if (event.lengthComputable) {
                                this.updateProgress(event.loaded / event.total * 100 | 0);
                            }
                        },
                        false
                    );
                }
            });
        }
        if (status === "error_upload") {
            if (typeof this.props.onError === "function") {
                this.props.onError();
            }
            return;
        }
        if (status === "done") {
            if (meta.file) {
                if (this.processedFiles.includes(meta.file.uuid)) {
                    return;
                }
                this.processedFiles.push(meta.file.uuid);
                if (typeof this.props.onUploaded === "function") {
                    return this.props.onUploaded(meta.file);
                }
            }
            data.forEach((uploaded, index) => {
                if (!uploaded.xhr || !uploaded.xhr.response) {
                    return;
                }
                let obj = JSON.parse(uploaded.xhr?.response || "{}");
                obj.data.files?.forEach(file => {
                    if (this.processedFiles.includes(file.uuid)) {
                        return;
                    }
                    this.processedFiles.push(file.uuid);
                    if (typeof this.props.onUploaded === "function") {
                        this.props.onUploaded(file);
                    }
                });
            })
        }

    }

    handleSubmit(files, allFiles) {
        allFiles.forEach(f => f.remove())
    }

    render() {
        return (
            <div className={this.props.className || 'dropzone'}>
                <Dropzone
                    inputContent={this.props.inputContent}
                    inputWithFilesContent={this.props.inputContent}
                    getUploadParams={this.getUploadParams}
                    onChangeStatus={this.handleChangeStatus}
                    onSubmit={null}
                    accept={this.props.accept || "image/*,audio/*,video/*"}
                    PreviewComponent={null}
                    disabled={files => files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) || this.props.disabled}
                />
            </div>
        )
    }
}

export default DropzoneWrapper