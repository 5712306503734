import React, { Fragment } from 'react';
import { Row, Col, Card, Modal, Form, Button } from "react-bootstrap";
import DropzoneWrapper from '../../components/dropzone';
import Icon from '../../components/icon';
import LowerElementsComponent from '../../components/component/lower-elements';
import { acceptFileType } from '../../constants';

class FilesUpload extends LowerElementsComponent {

    constructor(props) {
        super(props);
        this.state = {
            modalAgreementParams: false,
            agreementArticleUuid: "",
            agreementArticles: [],
            companyData: props.companyData
        }
    }

    componentDidMount() {
        this.getAgreementArticles();
    }

    getAgreementArticles() {
        if (!this.props.hasPermission(['DB_MY_REGION_ARTICLES']).any) {
            return;
        }
        this.props.api.post(
            `/article/list`,
            {
                "article": {
                    "category": "partners"
                }
            },
            (data) => {
                this.setState({ agreementArticles: data.articles })
            },
            (errorUpdateValidityData) => {
                this.props.showToast({
                    errorObject: errorUpdateValidityData,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        );
    }

    hideModals() {
        this.setState({ modalAgreementParams: false })
    }

    checkIfFileCanBeGeneratedAndThen(ident, callback) {

        if (this.props.isRegion(['POLAND'])) {
            ident = ident + '-pl';
        }

        if (this.props.isRegion(['SWEDEN'])) {
            ident = ident + '-se';
        }

        if (this.props.isRegion(['GERMANY'])) {
            ident = ident + '-de';
        }

        if (this.props.isRegion(['USA'])) {
            ident = ident + '-us';
        }

        if (!this.props.uuid) {
            this.props.showToast({
                title: this.props.t('common.toast.saveOfferFirst'),
                color: "warning"
            });
            return;
        }

        callback(ident);
        this.setUpperState({ isLoading: true });
    }

    addFile(data) {
        this.handleElementAdd(
            'files',
            { ...data, className: 'highlight' },
            this.props
        );
    }

    generateFile(ident, withData) {
        this.props.api.post(
            `/file/create/company`,
            {
                ...withData,
                "company": {
                    "uuid": this.props.uuid,
                    "fullData": this.props.upperState.fullData,
                },
                "file": {
                    "ident": ident
                }
            },
            (successFileCreateData) => {
                this.hideModals();
                this.setUpperState({ isLoading: false });
                this.addFile(successFileCreateData.file);
                this.props.showToast({
                    title: this.props.t('common.toast.fileCreated'),
                    color: "info"
                });
            },
            (errorFileCreateData) => {
                this.setUpperState({ isLoading: false });
                this.props.showToast({
                    errorObject: errorFileCreateData,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        );
    }

    render() {
        const {
            isLoading,
            uuid
        } = this.props;

        return (
            <Fragment>

                <Modal
                    show={this.state.modalAgreementParams}
                    onHide={() => { this.setState({ modalAgreementParams: false }) }}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.company.component.files.modal.agreementParams.title')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Group className="required">
                                    <Form.Label>{this.props.t(`page.company.component.files.modal.agreementParams.article`)}</Form.Label>
                                    <Form.Control
                                        required={true}
                                        disabled={isLoading}
                                        as="select"
                                        custom
                                        value={this.state.agreementArticleUuid}
                                        onChange={(e) => { this.setState({ agreementArticleUuid: e.target.value }) }}
                                    >
                                        <option value="">...</option>
                                        {
                                            this.state.agreementArticles.map(articleCategory => (
                                                <option key={articleCategory.uuid} value={articleCategory.uuid}>
                                                    {articleCategory.title}
                                                </option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="required">
                                    <Form.Label>{this.props.t('page.company.component.files.modal.agreementParams.fullData')}</Form.Label>
                                    <Form.Control
                                        required={true}
                                        disabled={this.props.isLoading}
                                        as="textarea"
                                        style={{ height: 100 }}
                                        rows="2"
                                        value={this.props.upperState.fullData}
                                        onChange={(e) => { this.props.setUpperState({ fullData: this.val(e) }, true) }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="p-20 border-top">
                        <Button variant="success" disabled={isLoading || !this.state.agreementArticleUuid} onClick={() => { this.checkIfFileCanBeGeneratedAndThen('agreement', (ident) => this.generateFile(ident, { article: { uuid: this.state.agreementArticleUuid } })) }}>
                            {this.props.t('page.company.component.files.modal.agreementParams.generate')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Header className="px-10 py-10 background-white">
                                {
                                    (this.props.hasPermission(['VIEW_COMPANY_AGREEMENT_BUTTON']).any) && (
                                        <button
                                            type="button"
                                            className="btn btn-uniform text-default"
                                            onClick={(e) => { this.setState({ modalAgreementParams: true }) }}
                                            disabled={isLoading}
                                        >
                                            <Icon name="plus-square" />
                                            <span className="pl-10">
                                                {this.props.t('page.company.component.files.generateAgreement')}
                                            </span>
                                        </button>
                                    )
                                }
                            </Card.Header>
                            <Card.Body className="border-top-1 overflow-auto">
                                <DropzoneWrapper
                                    inputContent={!uuid ? this.props.t('common.toast.saveCompanyFirst') : this.props.t('page.company.component.files.dropzone')}
                                    disabled={isLoading || !uuid}
                                    api={this.props.api}
                                    bearerToken={this.props.api.getToken()}
                                    params={{ "company": { "uuid": this.props.uuid }, "file": { "ident": "addon" } }}
                                    accept={acceptFileType.join(', ')}
                                    className="dropzone py-50 px-20 text-center"
                                    progressEventName="API_PROGRESS"
                                    onUploaded={(file) => { this.addFile(file); }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}
export default FilesUpload;