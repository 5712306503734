import React, { Component } from 'react';
import { Table } from "react-bootstrap";

class Statuses extends Component {

    render() {

        const { t } = this.props;
        const { statuses } = this.props.upperState;

        return (
            <Table size="xs" className="mb-0 statuses border-left border-right border-bottom" responsive>
                <thead>
                    <tr>
                        <th scope="col" className="pl-20">
                            {t('page.asset.element.statusesTable.status')}
                        </th>
                        <th scope="col" className="pl-20">
                            {t('page.asset.element.statusesTable.user')}
                        </th>
                        <th scope="col" className="pl-20">
                            {t('page.asset.element.statusesTable.comment')}
                        </th>
                        <th scope="col" style={{ maxWidth: 200 }} className="pr-20 text-right">
                            {t('page.asset.element.statusesTable.created')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        statuses.filter(itm => !itm.trash).length ?
                            statuses.map((status) => {
                                return (
                                    <tr key={status.uuid}>
                                        <td className="align-middle pl-20">
                                            {
                                                this.props.t(`common.assetStatus.${status.ident}`)
                                            }
                                        </td>
                                        <td className="align-middle pl-20">{`${status.user.firstName} ${status.user.lastName}`}</td>
                                        <td className="align-middle pl-20" dangerouslySetInnerHTML={{ __html: status.comment || '---' }} />
                                        <td className="align-middle pr-20 text-right">{status.created}</td>
                                    </tr>
                                );
                            })
                            :
                            (
                                <tr>
                                    <td colSpan="2" className="pl-20">
                                        {t('page.asset.element.statusesTable.empty')}
                                    </td>
                                </tr>
                            )
                    }
                </tbody>
            </Table>
        );
    }
}
export default Statuses;