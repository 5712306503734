import React, { Fragment } from 'react';
import { Row, Col, Card, Tabs, Tab, Form, Button, Nav } from "react-bootstrap";
import LowerComponent from "../../components/component/lower";
import DropzoneWrapper from '../../components/dropzone';
import Icon from '../../components/icon';
import SelectAsync from '../../components/select/async';
import { preferredCooperationModels, preferredCooperationTypes, consentsPoland } from '../../constants'

class PageUser extends LowerComponent {

    render() {

        const showAdminTab = this.props.hasPermission(['ADMIN', 'ROLES', 'MY_REGION_COMPANIES']).any
            && this.props.showAdminTab
            && this.props.upperState.uuid;

        const adminTab = (
            <Tab eventKey="admin" title={this.props.t('page.user.admin.title')}>
                <Tab.Container id="adminPrimary" defaultActiveKey="adminPrimary">
                    <Row>
                        <Col xs={12} sm={12} md={2}>
                            <Nav className="flex-column mb-30">
                                <Nav.Item>
                                    <Nav.Link eventKey="adminPrimary" className="pl-0">
                                        <Icon name="user" />
                                        <span>{this.props.t('page.user.basicData.adminTab.primary')}</span>
                                    </Nav.Link>
                                </Nav.Item>
                                {
                                    !this.props.customer && (
                                        <Nav.Item>
                                            <Nav.Link eventKey="adminAddress" className="pl-0">
                                                <Icon name="check-square" />
                                                <span>{this.props.t('page.user.basicData.adminTab.address')}</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    )
                                }
                                {
                                    this.props.isRegion(['POLAND']) && !this.props.customer && (
                                        <Nav.Item>
                                            <Nav.Link eventKey="adminSecondary" className="pl-0">
                                                <Icon name="bookmark" />
                                                <span>{this.props.t('page.user.basicData.adminTab.secondary')}</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    )
                                }
                            </Nav>
                        </Col>
                        <Col xs={12} sm={12} md={10}>
                            <Tab.Content>
                                <Tab.Pane eventKey="adminPrimary">
                                    {
                                        this.props.hasPermission(['ADMIN']).any && (
                                            <Row className="pt-20">
                                                <Col sm={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Check
                                                            type="switch"
                                                            id="active"
                                                            label={this.props.t('page.user.admin.active')}
                                                            checked={this.props.upperState.active || false}
                                                            onChange={(e) => { this.setUpperState({ active: !this.props.upperState.active, notify: false }) }}
                                                            className="mb-10"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                {this.props.upperState.active && (
                                                    <Col sm={12} md={6}>
                                                        <Form.Group>
                                                            <Form.Check
                                                                type="switch"
                                                                id="notify"
                                                                label={this.props.t('page.user.admin.notify')}
                                                                checked={this.props.upperState.notify || false}
                                                                onChange={(e) => { this.setUpperState({ notify: !this.props.upperState.notify }) }}
                                                                className="mb-10"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                )}
                                            </Row>
                                        )
                                    }
                                    <Row>
                                        {
                                            this.props.hasPermission(['ADMIN', 'ROLES']).any && (
                                                <Col sm={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {this.props.t('page.user.admin.role')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            value={this.props.upperState.roleUuid || ""}
                                                            onChange={(e) => { this.onChangeSetUpperState('roleUuid', e) }}
                                                        >
                                                            <option value="">{this.props.t(`common.value.empty`)}</option>
                                                            {this.props.upperState.roles.map(role => {
                                                                return <option key={role.uuid} value={role.uuid}>{role.summary}</option>
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                        {
                                            this.props.hasPermission(['ADMIN', 'MY_REGION_COMPANIES']).any && (
                                                <>
                                                    <Col sm={12} md={6}>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                {this.props.t('page.user.admin.company')}
                                                            </Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                value={this.props.upperState.companyUuid || ""}
                                                                onChange={(e) => { this.onChangeSetUpperState('companyUuid', e) }}
                                                            >
                                                                <option value="">{this.props.t(`common.value.empty`)}</option>
                                                                {this.props.upperState.companies.map(company => {
                                                                    return <option key={company.uuid} value={company.uuid}>{company.name}</option>
                                                                })}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                            )
                                        }
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <Form.Group>
                                                <Form.Label>
                                                    {this.props.t('page.user.admin.area')}
                                                </Form.Label>
                                                <SelectAsync
                                                    {...this.props}
                                                    isClearable={false}
                                                    isMulti={true}
                                                    preload={true}
                                                    disabled={false}
                                                    endpoint={`/area/list`}
                                                    value={this.props.upperState.areas || []}
                                                    params={{ include: this.props.upperState.areas }}
                                                    transformData={(data, callback) => {
                                                        let newData = data.areas.map(itm => { return { ...itm, value: itm.uuid } });
                                                        callback(newData)
                                                    }}
                                                    onChange={(data) => {
                                                        this.onChangeSetUpperState('areas', data, true);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        {
                                            this.props.hasPermission(['ADMIN', 'ROLES']).any && (
                                                <Col sm={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {this.props.t('page.user.admin.supervisor')}
                                                        </Form.Label>
                                                        <SelectAsync
                                                            {...this.props}
                                                            isClearable={true}
                                                            cache={true}
                                                            preload={true}
                                                            disabled={false}
                                                            endpoint={`/user/list`}
                                                            value={this.props.upperState.supervisorUuid || ""}
                                                            params={{ include: [this.props.upperState.supervisorUuid] }}
                                                            transformData={(data, callback) => {
                                                                let newData = data.users.map(itm => { return { ...itm, value: itm.uuid } });
                                                                callback(newData)
                                                            }}
                                                            onChange={(data) => {
                                                                this.props.setUpperState({ supervisorUuid: data?.uuid || null })
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <Form.Group>
                                                <Form.Label>
                                                    {this.props.t('page.user.basicData.orgChart')}
                                                </Form.Label>
                                                <SelectAsync
                                                    t={this.props.t}
                                                    api={this.props.api}
                                                    isClearable={true}
                                                    isMulti={false}
                                                    cache={true}
                                                    preload={true}
                                                    disabled={false}
                                                    endpoint={`/user/list-org-chart`}
                                                    value={this.props.upperState.orgChart}
                                                    transformData={(data, callback) => {
                                                        let newData = [...data]
                                                        callback(newData)
                                                    }}
                                                    onChange={(data) => {
                                                        this.props.setUpperState({ orgChart: data });
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        {
                                            this.props.hasPermission(['ADMIN', 'ROLES']).any && (
                                                <Col sm={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {this.props.t('page.user.admin.referralManager')}
                                                        </Form.Label>
                                                        <SelectAsync
                                                            {...this.props}
                                                            isClearable={true}
                                                            cache={true}
                                                            preload={true}
                                                            disabled={false}
                                                            endpoint={`/user/list`}
                                                            value={this.props.upperState.referralManagerUuid || ""}
                                                            params={{ include: [this.props.upperState.referralManagerUuid] }}
                                                            transformData={(data, callback) => {
                                                                let newData = data.users.map(itm => { return { ...itm, value: itm.uuid } });
                                                                callback(newData)
                                                            }}
                                                            onChange={(data) => {
                                                                this.props.setUpperState({ referralManagerUuid: data?.uuid || null })
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Tab.Pane>
                                {
                                    this.props.hasPermission(['ADMIN', 'ROLES']).any && (
                                        <Tab.Pane eventKey="adminAddress">
                                            <Row className='mt-10'>
                                                {[
                                                    'taxNumber', 'postalCode', 'cityName',
                                                    'streetAddress', 'streetNumber', 'apartmentNumber',
                                                    'bankAccountNumber', 'bankName', 'taxOfficeName',
                                                ].map(itm => (
                                                    <Col sm={12} md={4} key={itm}>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                {this.props.t(`page.referralDetails.${itm}`)}
                                                            </Form.Label>
                                                            <Form.Control
                                                                onChange={(e) => { this.onChangeSetUpperState(itm, e) }}
                                                                value={this.props.upperState[itm] || ''}
                                                                type="text"
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Tab.Pane>
                                    )
                                }
                                {
                                    !this.props.customer && (
                                        <Tab.Pane eventKey="adminSecondary">

                                            <>
                                                <Row>
                                                    <Col sm={12}>
                                                        <h3 className='mt-10'>{this.props.t('page.user.basicData.adminTab.secondary')}</h3>
                                                        <hr />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} md={6}>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                {this.props.t('page.user.admin.preferredCooperationModel')}
                                                            </Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                value={this.props.upperState.preferredCooperationModel || ""}
                                                                onChange={(e) => { this.onChangeSetUpperState('preferredCooperationModel', e) }}
                                                            >
                                                                <option value="">{this.props.t(`common.value.empty`)}</option>
                                                                {preferredCooperationModels.map(model => {
                                                                    return <option key={model} value={model}>{this.props.t(`common.preferredCooperationModel.${model}`)}</option>
                                                                })}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={12} md={6}>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                {this.props.t('page.user.admin.preferredCooperationType')}
                                                            </Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                value={this.props.upperState.preferredCooperationType || ""}
                                                                onChange={(e) => { this.onChangeSetUpperState('preferredCooperationType', e) }}
                                                            >
                                                                <option value="">{this.props.t(`common.value.empty`)}</option>
                                                                {preferredCooperationTypes.map(type => {
                                                                    return <option key={type} value={type}>{this.props.t(`common.preferredCooperationType.${type}`)}</option>
                                                                })}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={12} md={12}>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                {this.props.t('page.user.admin.preferredCooperationDescription')}
                                                            </Form.Label>
                                                            <Form.Control
                                                                onChange={(e) => { this.onChangeSetUpperState('preferredCooperationDescription', e) }}
                                                                value={this.props.upperState.preferredCooperationDescription || ""}
                                                                type="text"
                                                                placeholder={this.props.t('page.user.basicData.placeholder.preferredCooperationDescription')}
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={12}>
                                                        <hr />
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    {
                                                        consentsPoland.map(consent => {
                                                            return (
                                                                <Col sm={12} md={6} key={consent}>
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            {this.props.t(`page.user.admin.${consent}`)}
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            as="select"
                                                                            value={this.props.upperState[consent] === null ? "" : this.props.upperState[consent] || ""}
                                                                            onChange={(e) => { this.onChangeSetUpperState(consent, e) }}
                                                                        >
                                                                            <option value={""}>{this.props.t(`common.value.empty`)}</option>
                                                                            <option value={true}>{this.props.t(`common.value.yes`)}</option>
                                                                            <option value={false}>{this.props.t(`common.value.no`)}</option>
                                                                        </Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </>
                                        </Tab.Pane>
                                    )}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Tab>
        )

        const theAvatar = this.props.upperState.avatar ? (
            <img src={this.props.upperState.avatar.link} alt="" key="dz-inputContent" />
        ) : (
            <div className="img" key="dz-inputContent">
                {this.props.utils.getInitials(this.props.upperState.firstName, this.props.upperState.lastName)}
            </div>
        );

        return (
            <Fragment>
                <Form method="post" onSubmit={(e) => { e.preventDefault(); this.props.saveData(this.state) }}>
                    <Card>
                        <Card.Body>
                            <Tabs defaultActiveKey={"metric"} className="border-bottom nicebar">
                                <Tab eventKey="metric" title={this.props.t('page.user.basicData.title')}>
                                    <Row className="pt-20">
                                        <Col sm={12} md={2}>
                                            <Row>
                                                <Col sm={12}>
                                                    <Form.Group>
                                                        <div className="rui-profile-img">
                                                            {
                                                                this.props.showAvatarUpload ? (
                                                                    <>
                                                                        <DropzoneWrapper
                                                                            inputContent={theAvatar}
                                                                            disabled={false}
                                                                            api={this.props.api}
                                                                            bearerToken={this.props.api.getToken()}
                                                                            params={{ "file": { "ident": "avatar" } }}
                                                                            accept="image/png, image/jpeg"
                                                                            className="dropzone-full"
                                                                            progressEventName="API_PROGRESS"
                                                                            onUploaded={(file) => { this.setUpperState({ avatar: file }); }}
                                                                            onError={() => {
                                                                                this.props.showToast({
                                                                                    title: this.props.t('common.toast.error'),
                                                                                    color: 'danger'
                                                                                });
                                                                            }}
                                                                        />
                                                                        <small>{this.props.t('page.user.basicData.avatarDisclaimer')}</small>
                                                                    </>
                                                                ) : theAvatar
                                                            }
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={12} md={10}>
                                            <Row>
                                                <Col sm={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {this.props.t('page.user.basicData.emailAddress')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            onChange={(e) => { this.onChangeSetUpperState('emailAddress', e) }}
                                                            value={this.props.upperState.emailAddress || ""}
                                                            type="text"
                                                            placeholder={this.props.t('page.user.basicData.placeholder.emailAddress')}
                                                            autoComplete="off"
                                                            readOnly={this.props.upperState.uuid ? true : false}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {this.props.t('page.user.basicData.firstName')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            onChange={(e) => { this.onChangeSetUpperState('firstName', e) }}
                                                            value={this.props.upperState.firstName || ""}
                                                            placeholder={this.props.t('page.user.basicData.placeholder.firstName')}
                                                            autoComplete="off"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {this.props.t('page.user.basicData.mobilePhone')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            onChange={(e) => { this.onChangeSetUpperState('mobilePhone', e) }}
                                                            value={this.props.upperState.mobilePhone || ""}
                                                            type="text"
                                                            placeholder={this.props.t('page.user.basicData.placeholder.mobilePhone')}
                                                            autoComplete="off"
                                                        />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {this.props.t('page.user.basicData.lastName')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            onChange={(e) => { this.onChangeSetUpperState('lastName', e) }}
                                                            value={this.props.upperState.lastName || ""}
                                                            placeholder={this.props.t('page.user.basicData.placeholder.lastName')}
                                                            autoComplete="off"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {this.props.t('page.user.basicData.secondName')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            onChange={(e) => { this.onChangeSetUpperState('secondName', e) }}
                                                            value={this.props.upperState.secondName || ""}
                                                            placeholder={this.props.t('page.user.basicData.placeholder.secondName')}
                                                            autoComplete="off"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {this.props.t('page.user.basicData.referralCode')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            onChange={(e) => { this.onChangeSetUpperState('referralCode', e) }}
                                                            value={this.props.upperState.referralCode || ""}
                                                            type="text"
                                                            placeholder={this.props.t('page.user.basicData.placeholder.referralCode')}
                                                            autoComplete="off"
                                                            readOnly={!showAdminTab}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                {
                                                    this.props.customer && (
                                                        <Col sm={12} md={6}>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    {this.props.t('page.user.admin.project')}
                                                                </Form.Label>
                                                                <SelectAsync
                                                                    {...this.props}
                                                                    isClearable={false}
                                                                    isMulti={true}
                                                                    preload={true}
                                                                    disabled={false}
                                                                    endpoint={`/project/list`}
                                                                    value={this.props.upperState.projects || []}
                                                                    params={{ include: this.props.upperState.projects, page: 0, pageSize: 10 }}
                                                                    transformData={(data, callback) => {
                                                                        let newData = data.projects.map(itm => { return { uuid: itm.project.uuid, label: itm.project.label + ' ' + itm.address.addressName, value: itm.project.uuid } });
                                                                        callback(newData)
                                                                    }}
                                                                    onChange={(data) => {
                                                                        this.onChangeSetUpperState('projects', data, true);
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    )
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                </Tab>
                                {
                                    showAdminTab && adminTab
                                }
                                <Tab eventKey="settings" title={this.props.t('page.user.settings.title')}>
                                    <Row className="pt-20">
                                        {
                                            !this.props.customer && (
                                                <Col sm={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Check
                                                            type="switch"
                                                            id="mentions"
                                                            label={this.props.t('page.user.settings.mentions')}
                                                            checked={this.props.upperState.mentions || false}
                                                            onChange={(e) => { this.setUpperState({ mentions: !this.props.upperState.mentions }) }}
                                                            className="mb-10"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                        <Col sm={12} md={6}>
                                            <Form.Group>
                                                <Form.Check
                                                    type="switch"
                                                    id="otpRequired"
                                                    label={this.props.t('page.user.settings.otpRequired')}
                                                    checked={this.props.upperState.otpRequired || false}
                                                    onChange={(e) => { this.setUpperState({ otpRequired: !this.props.upperState.otpRequired }) }}
                                                    className="mb-10"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab>
                                {
                                    !this.props.customer && (
                                        <Tab eventKey="preferences" title={this.props.t('page.user.preferences.title')}>
                                            <Row className="pt-20">
                                                <Col sm={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Check
                                                            type="switch"
                                                            id="offerUseTabs"
                                                            label={this.props.t('page.user.preferences.configuration.offerUseTabs')}
                                                            disabled={this.props.hasPermission(['VIEW_OFFER_TABS']).any}
                                                            checked={this.props.upperState.configuration?.offerUseTabs || this.props.hasPermission(['VIEW_OFFER_TABS']).any || false}
                                                            onChange={(e) => {
                                                                this.setUpperState({
                                                                    configuration: {
                                                                        ...(this.props.upperState.configuration || {}),
                                                                        offerUseTabs: !(this.props.upperState.configuration?.offerUseTabs || false)
                                                                    }
                                                                });
                                                            }}
                                                            className="mb-10"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    )
                                }
                                <Tab eventKey="password" title={this.props.t('page.user.password.title')}>
                                    {
                                        this.props.showOldPassword && (
                                            <Row className="pt-20">
                                                <Col sm={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {this.props.t('page.user.password.oldPassword')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            onChange={(e) => {
                                                                this.setUpperState({
                                                                    oldPassword: e.target.value,
                                                                    oldPasswordComplexity: this.props.utils.passwordComplexity(e.target.value)
                                                                })
                                                            }}
                                                            value={this.props.upperState.oldPassword || ""}
                                                            type="password"
                                                            placeholder={this.props.t('page.user.password.placeholder.oldPassword')}
                                                            autoComplete="old-password"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <Form.Group>
                                                <Form.Label>
                                                    {this.props.t('page.user.password.newPassword')}
                                                </Form.Label>
                                                <Form.Control
                                                    onChange={(e) => {
                                                        this.setUpperState({
                                                            newPassword: e.target.value,
                                                            newPasswordComplexity: this.props.utils.passwordComplexity(e.target.value)
                                                        })
                                                    }}
                                                    value={this.props.upperState.newPassword || ""}
                                                    type="password"
                                                    placeholder={this.props.t('page.user.password.placeholder.newPassword')}
                                                    autoComplete="new-password"
                                                />
                                                <Form.Text>
                                                    {
                                                        this.props.upperState.newPassword && this.props.upperState.newPasswordComplexity < this.props.utils.minimumPasswordComplexity()
                                                            ?
                                                            this.props.t('common.toast.tooWeakPassword')
                                                            :
                                                            ``
                                                    }
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group>
                                                <Form.Label>
                                                    {this.props.t('page.user.password.newPasswordRepeat')}
                                                </Form.Label>
                                                <Form.Control
                                                    onChange={(e) => { this.onChangeSetUpperState('newPasswordRepeat', e) }}
                                                    value={this.props.upperState.newPasswordRepeat || ""}
                                                    type="password"
                                                    placeholder={this.props.t('page.user.password.placeholder.newPasswordRepeat')}
                                                    autoComplete="new-password"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                    <Row className="mb-30">
                        <Col sm={12}>
                            <div className="pt-30">
                                <Button
                                    variant="success"
                                    size="lg"
                                    disabled={this.props.upperState.isLoading}
                                    onClick={(e) => { this.props.saveData(this.state) }}
                                >
                                    <Icon name="save" />
                                    <span className="pl-10">
                                        {this.props.t('page.user.options.saveData')}
                                    </span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        );
    }
}

export default PageUser
