import React from 'react';
import DropzoneWrapper from '../../components/dropzone';
import LowerElementsComponent from '../../components/component/lower-elements';
import { acceptFileType } from '../../constants';

class File extends LowerElementsComponent {

    render() {

        const { t, addFile } = this.props;
        const { isLoading, uuid } = this.props.upperState;

        return (
            <DropzoneWrapper
                inputContent={t('page.calculation.files.dropzone')}
                disabled={isLoading}
                api={this.props.api}
                bearerToken={this.props.api.getToken()}
                params={{ "asset": { "uuid": uuid }, "file": { "ident": "addon" } }}
                accept={acceptFileType.join(', ')}
                className="dropzone py-50 px-20 text-center border"
                progressEventName="API_PROGRESS"
                onUploaded={(file) => { addFile(file); }}
            />
        );
    }
}
export default File;