import BaseComponent from "../../component/base";
import { Col, Row, ListGroup, Form, Button } from "react-bootstrap";
import Icon from "../../icon";

class CustomSE extends BaseComponent {

    render() {
        return (
            <>
                <ListGroup>
                    <ListGroup.Item className="px-15 py-5">
                        <Form.Group as={Row} className="mb-0">
                            <Form.Label column sm={12} md={6}>
                                {this.props.t('page.calculation.pvDesigner.rot')}
                                &nbsp;
                            </Form.Label>
                            <Col sm={12} md={6}>
                                <div className="float-right">
                                    <span className="px-5">
                                        {this.props.tokenRot || "0"}
                                    </span>
                                    <Button
                                        variant="link"
                                        disabled={this.props.isLoading}
                                        style={{ padding: 0 }}
                                        onClick={
                                            (evt) => {
                                                this.props.reFetchTokens()
                                            }
                                        }>
                                        <Icon name="refresh-cw" height={12} />
                                    </Button>
                                </div>
                            </Col>
                        </Form.Group>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-15 py-5">
                        <Form.Group as={Row} className="mb-0">
                            <Form.Label column sm={12} md={6}>
                                {this.props.t('page.calculation.pvDesigner.gronTeknik')}
                                &nbsp;
                            </Form.Label>
                            <Col sm={12} md={6}>
                                <div className="float-right">
                                    <span className="px-5">
                                        {this.props.tokenGronTeknik || "0"}
                                    </span>
                                    <Button
                                        variant="link"
                                        disabled={this.props.isLoading}
                                        style={{ padding: 0 }}
                                        onClick={
                                            (evt) => {
                                                this.props.reFetchTokens()
                                            }
                                        }>
                                        <Icon name="refresh-cw" height={12} />
                                    </Button>
                                </div>
                            </Col>
                        </Form.Group>
                    </ListGroup.Item>
                </ListGroup>
            </>
        )
    }
}

export default CustomSE;