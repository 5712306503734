import React, { Fragment } from 'react';
import BaseComponent from "../../components/component/base";
import PageTeamTabs from './tabs';

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.defaultColor = "#ffffff";
        this.state = {
            isLoading: true,
            label: "",
            category: "",
            color: this.defaultColor,
            company: null,
            users: []
        };
    }

    componentDidMount() {
        if (this.props.match.params.uuid) {
            this.loadData(this.props.match.params.uuid);
        } else {
            this.setState({ isLoading: false })
        }
    }

    loadData(uuid) {
        this.setState({ isLoading: true });
        this.props.api.post(
            `/team/describe`,
            {
                team: {
                    uuid: uuid
                }
            },
            (data) => {
                this.setData(data);
            },
            (errorObject) => {
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        );
    }

    setData(data) {
        this.setState({
            ...data.team,
            color: data.team.color || this.defaultColor,
            users: data.users,
            company: data.company,
            isLoading: false
        }, () => {
            this.props.openLink(`/team/${data.team.uuid}`);
        });
    }

    saveData() {
        this.setState({ isLoading: true });
        this.props.api.post(
            this.state.uuid ? `/team/update` : `/team/create`,
            {
                "team": {
                    "uuid": this.state.uuid,
                    "label": this.state.label,
                    "category": this.state.category,
                    "color": this.state.color || this.defaultColor,
                },
                "user": {
                    "uuids": this.state.users.map(user => user.uuid)
                },
                "company": {
                    "uuid": this.state.company?.uuid
                }
            },
            (data) => {
                this.setState({ isLoading: false });
                this.props.showToast({
                    title: this.props.t('common.toast.dataSaved'),
                    color: "info"
                });
                this.setData(data);
            },
            (errorObject) => {
                this.setState({ isLoading: false });
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        );
    }

    render() {
        return (
            <Fragment>
                <PageTeamTabs
                    {...this.props}
                    upperState={this.state}
                    setUpperState={(data) => this.setState(data)}
                    saveData={(data) => this.saveData(data)}
                />
            </Fragment>
        );
    }
}

export default Content
