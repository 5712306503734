import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import logo from '../../assets/images/logo.svg';
import BaseComponent from '../../components/component/base';
import { Row, Col, Form, Alert, Button } from "react-bootstrap";
import AppVersion from '../../components/app-version';

class Content extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            emailAddress: '',
            authCode: '',
            newPassword: '',
            newPasswordRepeat: '',
            authError: '',
            loading: false
        };
    }

    onSubmit(e) {
        e.preventDefault();
        if (this.state.step === 0) {
            this.preReset({
                user: {
                    emailAddress: this.state.emailAddress
                }
            });
        }
        if (this.state.step === 1) {
            this.reset({
                user: {
                    emailAddress: this.state.emailAddress,
                    password: this.state.newPassword
                },
                token: {
                    otp: this.state.authCode
                }
            });
        }
    }

    preReset(data) {
        this.setState({ loading: true });
        this.props.api.post(
            `/auth/pre-reset`,
            data,
            () => {
                this.setState({ step: 1, loading: false, authError: '' });
            },
            (errorObject) => {
                let authErrorString;
                switch (errorObject.data.error.ident) {
                    case 'error_user_not_found':
                        authErrorString = this.props.t('page.auth.userNotFound');
                        break;
                    default:
                        authErrorString = errorObject.data.error.ident;
                        break;
                }
                this.setState({
                    loading: false,
                    authError: authErrorString
                })
            }
        )
    }

    reset(data) {

        const {
            newPassword,
            newPasswordRepeat
        } = this.state;

        if (newPassword !== newPasswordRepeat) {
            this.setState({
                loading: false,
                authError: passwordMismatch
            });
            return;
        }

        this.setState({ loading: true });
        this.props.api.post(
            `/auth/reset`,
            data,
            (successData) => {
                this.props.setToken(successData.token.uuid, false);
                this.props.reload(this.props.getStarterLink());
            },
            (errorObject) => {
                let authErrorString;
                switch (errorObject.data.error.ident) {
                    case 'error_unauthorized':
                        authErrorString = this.props.t('page.auth.wrongCode');
                        break;
                    case 'error_token_expired':
                        authErrorString = this.props.t('page.auth.codeExpired');
                        break;
                    default:
                        authErrorString = errorObject.data.error.ident;
                        break;
                }
                this.setState({
                    loading: false,
                    authError: authErrorString
                })
            }
        )
    }

    render() {

        let steps = [];
        steps.push(
            <>
                <Col sm={12}>
                    <Form.Control
                        type="email"
                        required
                        placeholder={this.props.t('page.auth.email')}
                        value={this.state.emailAddress}
                        onChange={event =>
                            this.setState({ emailAddress: event.target.value })
                        }
                    />
                </Col>
                <Col sm={12}>
                    <Button
                        className="text-center"
                        variant="brand"
                        block
                        disabled={this.state.loading}
                        type="submit"
                    >
                        {this.props.t('page.auth.sendCode')}
                        {
                            this.state.loading &&
                            (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )
                        }
                    </Button>
                </Col>
            </>
        );
        steps.push(
            <>
                <Col sm={12}>
                    <Alert variant="success">
                        {this.props.t('page.auth.authCodeSent')}
                    </Alert>
                </Col>
                <Col sm={12}>
                    <Form.Control
                        disabled={true}
                        type="email"
                        autoComplete="email"
                        placeholder={this.props.t('page.auth.email')}
                        value={this.state.emailAddress}
                        onChange={event =>
                            this.setState({ emailAddress: event.target.value })
                        }
                    />
                </Col>
                <Col sm={12}>
                    <Form.Control
                        type="text"
                        autoComplete="one-time-code"
                        placeholder={this.props.t('page.auth.authCode')}
                        value={this.state.authCode}
                        onChange={event =>
                            this.setState({ authCode: event.target.value })
                        }
                    />
                </Col>
                <Col sm={12}>
                    <Form.Control
                        type="password"
                        autoComplete="new-password"
                        placeholder={this.props.t('page.auth.setPassword')}
                        value={this.state.newPassword}
                        onChange={event =>
                            this.setState({ newPassword: event.target.value })
                        }
                    />
                </Col>
                <Col sm={12}>
                    <Form.Control
                        type="password"
                        autoComplete="new-password"
                        placeholder={this.props.t('page.auth.setPasswordRepeat')}
                        value={this.state.newPasswordRepeat}
                        onChange={event =>
                            this.setState({ newPasswordRepeat: event.target.value })
                        }
                    />
                </Col>
                <Col sm={12}>
                    <Button
                        className="text-center"
                        variant="brand"
                        block
                        type="submit"
                        disabled={this.state.loading}
                    >
                        {this.props.t('page.auth.changePassword')}
                        {
                            this.state.loading &&
                            (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )
                        }
                    </Button>
                </Col>
            </>
        );

        return (
            <Fragment>
                <div className="bg-image">
                    <div className="bg-grey-1" />
                </div>
                <Form method="post" onSubmit={(e) => this.onSubmit(e)} className="form rui-sign-form rui-sign-form-cloud">
                    <Row className="row vertical-gap sm-gap justify-content-center">
                        <Col sm={12}>
                            <h1 className="display-4 mb-10 text-center p-25">
                                <img src={logo} alt="Sundesk" className="img-fluid" />
                            </h1>
                        </Col>
                        {
                            this.state.authError && (
                                <Col sm={12}>
                                    <Alert variant="warning">
                                        {this.state.authError}
                                    </Alert>
                                </Col>
                            )
                        }
                        {steps[this.state.step]}
                        <div className="mt-20">
                            {this.props.t('page.auth.haveAccount')}
                            {` `}
                            <Link to={this.props.buildLink('/auth/login/')}>
                                {this.props.t('page.auth.login')}
                            </Link>
                        </div>
                    </Row>
                </Form>
                <div className="mt-20">
                    <p className="mb-0 text-secondary">
                        <small>
                            <AppVersion />
                        </small>
                    </p>
                </div>
            </Fragment>
        );
    }
}

export default Content
