import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, withRouter } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import API from './api';
import logo from './assets/images/logo.svg';
import './components/animated-route';
import i18n from './components/i18n';
import PageNavbar from './components/page-navbar';
import PageSidebar from './components/page-sidebar';
import PageToasts from './components/page-toasts';
import { appEnv, appEnvCurrent, appVersionCurrent, ignoreErrors, projectTypeRegional } from './constants';
import MaintenancePage from './pages/503';
import Router from './router';
import './style.scss';
import Utils from './utils';

class App extends Component {

    constructor(props) {
        super(props);
        this.api = new API();
        this.utils = new Utils();
        this.langs = i18n.langs;

        this.regions = i18n.regions;
        this.userLang = (window.navigator.userLanguage || window.navigator.language || "").toLowerCase().split('-')[0];
        this.defaultLang = this.langs.includes(this.userLang) ? this.userLang : this.langs[0];

        this.loadingBarRef = React.createRef();
        this.loadingBarFinished = true;

        this.t = this.t.bind(this);
        this.handleApiProgress = this.handleApiProgress.bind(this);
        this.handleApiStatus = this.handleApiStatus.bind(this);
        this.handleApiMaintenance = this.handleApiMaintenance.bind(this);
        this.handleShortcuts = this.handleShortcuts.bind(this);

        this.ping = this.ping.bind(this);
        this.pingTimeout = undefined;

        this.highlight = this.highlight.bind(this);
        this.highlightTimeout = undefined;
        this.highlightActive = false;

        this.state = {
            lang: this.defaultLang,
            region: this.regions[0],
            user: null,
            userUpdated: null,
            loading: this.api.getToken() ? true : false,
            reload: false,
            maintenance: null,
            toast: [],
        }
    }

    componentDidMount() {
        window.addEventListener("API_PROGRESS", this.handleApiProgress, false);
        window.addEventListener("API_STATUS", this.handleApiStatus, false);
        window.addEventListener("API_MAINTENANCE", this.handleApiMaintenance, false);
        window.addEventListener("CTRL_KEY_DOWN", this.handleShortcuts, false);
        this.updateLocaleParams(
            this.props.match.params,
            () => {
                this.maybeCheckAuth();
                this.highlight(true);
                this.ping(true);
            }
        );
    }

    componentDidUpdate(prevProps) {
        this.maybeScrollPageToTop(prevProps);
    }

    componentWillUnmount() {
        window.removeEventListener("API_PROGRESS", this.handleApiProgress, false);
        window.removeEventListener("API_STATUS", this.handleApiStatus, false);
        window.removeEventListener("API_MAINTENANCE", this.handleApiMaintenance, false);
        window.removeEventListener("CTRL_KEY_DOWN", this.handleShortcuts, false);
    }

    handleShortcuts(e) {
        const originalEvent = e.detail;
        if (originalEvent.key?.toLowerCase() === 'b') {
            this.highlightActive = !this.highlightActive;
        }
    }

    updateLocaleParams(newState, callback) {
        if (this.langs.includes(newState.lang)) {
            newState.lang = newState.lang;
        } else {
            newState.lang = this.defaultLang;
        }
        if (this.regions.includes(newState.region)) {
            newState.region = newState.region;
        } else {
            newState.region = this.regions[0];
        }
        this.api.setConfig("url", process.env.REACT_APP_API_URL);
        this.api.setConfig("headers", [
            { name: 'x-lang', value: newState.lang },
            { name: 'x-region', value: newState.region },
            { name: 'x-version', value: appVersionCurrent },
        ])
        i18n.engine.changeLanguage(newState.lang, () => {
            this.setState(newState, () => {
                callback()
            });
        })
    }

    setLang(lang) {
        this.updateLocaleParams(
            { region: this.state.region, lang: lang },
            () => {
                this.openLink(this.getStarterLink());
            }
        );
    }

    getStarterLink(overrideUserData) {
        let starterLink = '/sales';
        const userData = (overrideUserData || this.state.user);
        if (userData?.role.permission.find(itm => itm.ident === 'VIEW_DASHBOARD_TASKS')) {
            starterLink = '/tasks';
        }
        if (userData?.role.permission.find(itm => itm.ident === 'VIEW_DASHBOARD_OPERATIONS')) {
            starterLink = '/operations/';
        }
        return starterLink;
    }

    t(...args) {
        if (
            args.length
            && typeof args[0] === 'string'
            && args[0].startsWith('common.projectType')) {
            let regionIdent = this.getRegionIdent();
            let projectType = args[0].replace('common.projectType.', '');
            if (
                regionIdent
                && projectTypeRegional[regionIdent]
                && projectTypeRegional[regionIdent][projectType]
            ) {
                return projectTypeRegional[regionIdent][projectType];
            }
        }
        return i18n.engine.t(...args);
    }

    handleApiStatus(e) {
        let status = parseInt(e.detail?.status);
        if (status === 401) {
            this.setToken(null, true);
        }
    }

    handleApiMaintenance(e) {
        const currentState = this.state.maintenance;
        const newState = e.detail ? true : false;
        if (currentState !== newState) {
            this.setState({ maintenance: newState }, () => {
                if (currentState !== null) {
                    window.location.reload();
                }
            });
        }
    }

    handleApiProgress(data) {
        let progress = data.detail;
        if (progress === 0 && this.loadingBarFinished) {
            this.loadingBarRef?.current?.continuousStart();
            this.loadingBarFinished = false;
        } else if (progress === 100) {
            setTimeout((self) => {
                self.loadingBarRef?.current?.complete();
                self.loadingBarFinished = true;
            }, 100, this)
        }
    }

    notifyErrorMonitor(data) {
        let ignore = false;
        ignoreErrors.forEach(ignoreError => {
            let str = (JSON.stringify(ignoreError) || '').toUpperCase();
            if (str.includes(ignoreError.toUpperCase)) {
                ignore = true;
            }
        });
        if (ignore) {
            return;
        }
    }

    showToast(data) {
        let ts = new Date();
        let errorObject = null;
        if (data.errorObject) {
            errorObject = this.t('common.toast.errorCode', {
                errorCode: data.errorObject.ident,
                errorDetails: JSON.stringify(data.errorObject.data?.error?.ident)
            });
        }
        let toast = {
            ts: ts,
            title: data.title,
            color: data.color || 'info',
            content: data.content || errorObject,
            duration: data.duration || 16 * 1000,
            closeButton: true
        };

        this.setState(
            {
                toast: this.state.toast.concat([toast])
            },
            () => {
                if (data.holdCaptureException) {
                    return;
                }
                if (data.captureObject) {
                    return this.notifyErrorMonitor(data.captureObject);
                }
                if (errorObject) {
                    return this.notifyErrorMonitor(errorObject);
                }
            }
        );
        setTimeout(() => {
            this.removeToast(ts);
        }, toast.duration);
    }

    removeToast(ts) {
        this.setState({
            toast: this.state.toast.filter(fToast => fToast.ts !== ts)
        });
    }

    maybeCheckAuth() {
        if (this.api.getToken()) {
            this.setToken(this.api.getToken(), true);
        } else {
            if (!this.isAuthPage()) {
                this.goToLoginPage();
            }
        }
    }

    maybeScrollPageToTop(prevProps) {
        const currentLocation = this.props.location.pathname;
        const shortCurrentLocation = currentLocation.split('/').filter(Boolean).pop();
        const previousLocation = prevProps.location.pathname;
        const isNewLocation = currentLocation !== previousLocation;
        if (isNewLocation) {
            window.scrollTo({ top: 0, behavior: 'smooth', });
            this.api.dispatchEvent('NAVBAR_ACTION', { icon: null, hideMobileMenu: true }); // hide all icons
        }
        const bodyClasses = document.body.classList;
        for (var i = 0; i < bodyClasses.length; i++) {
            if (bodyClasses[i].startsWith(`rui-current-page-is`)) {
                document.body.classList.remove(bodyClasses[i]);
            }
        }
        document.body.classList.add(`rui-current-page-is-${shortCurrentLocation}`);
    }

    buildLink(localLink, newlang, newregion) {
        const { lang, region } = this.state;
        if (localLink[0] !== '/') {
            localLink = '/' + localLink;
        }
        return `/${newlang || lang}/${newregion || region}${localLink}`;
    }

    openLink(url, target) {
        const theLink = this.buildLink(url);
        if (target === '_hash') {
            if (theLink.indexOf('#') > 0) {
                const theHash = theLink.substring(theLink.indexOf('#'));
                window.location.hash = theHash;
            } else {
                window.location.hash = '';
            }
        } else if (target === '_replace') {
            this.props.history.replace({ pathname: theLink })
        } else if (target === '_blank') {
            window.open(theLink, '_blank').focus();
        } else if (target === '_href_if_needed') {
            if (!window.location.href.includes(theLink)) {
                window.location.href = theLink;
            }
        } else {
            this.props.history.push(theLink)
        }
    }

    setToken(token, reloadUserData, afterReloadCallback) {
        let self = this;
        self.api.setToken(token);
        if (!token) {
            self.setState(
                {
                    user: null,
                    loading: false
                },
                () => {
                    self.goToLoginPage();
                }
            );
            return;
        }
        if (reloadUserData) {
            self.reloadUserData(afterReloadCallback);
        }
    }

    reloadUserData(afterReloadCallback) {
        let self = this;
        self.api.post(
            `/auth/describe`,
            {},
            (userData) => {
                userData.avatar = userData.files ? userData.files.find(itm => itm.ident === 'avatar') : null;
                self.setState({
                    user: userData,
                    userUpdated: new Date().getTime(),
                    region: userData.region.ident,
                    loading: false,
                }, () => {
                    self.api.registerToken({ "user": { "emailAddress": userData.user.emailAddress }, "token": { "uuid": self.api.getToken() }, "region": { "ident": userData.region.ident } });
                    if (typeof afterReloadCallback === 'function') {
                        afterReloadCallback();
                    }
                });
            },
            () => {
                self.setState(
                    {
                        user: null,
                        userUpdated: new Date().getTime(),
                        loading: false
                    },
                    () => {
                        self.goToLoginPage();
                    }
                );
            }
        )
    }

    highlight(init) {
        if (this.highlightTimeout) {
            clearTimeout(this.highlightTimeout);
        }
        let wrapper = () => {
            let indx = 0;
            let inputs = [
                ...document.getElementsByTagName('input'),
                ...document.getElementsByTagName('select'),
            ];
            for (let i = 0; i < inputs.length; i++) {
                if (this.highlightActive) {
                    if (!inputs[i].parentElement.dataset.indx) {
                        inputs[i].parentElement.dataset.indx = ++indx;
                    }
                } else {
                    delete inputs[i].parentElement.dataset.indx;
                }
            }
            this.highlight(false);
        }
        if (init) {
            this.highlightTimeout = setTimeout(() => { wrapper() }, 100);
        } else {
            this.highlightTimeout = setTimeout(() => { wrapper() }, 500);
        }
    }

    ping(init) {
        if (this.pingTimeout) {
            clearTimeout(this.pingTimeout);
        }
        let wrapper = () => {
            if (this.api.getToken()) {
                this.api.postSilent(`/auth/ping`, {}, (data) => {
                    let expiresInMinutes = parseInt(data.token?.expiresInMinutes);
                    if (expiresInMinutes < 0) {
                        expiresInMinutes = 0;
                    }
                    if (expiresInMinutes < 60) {
                        this.showToast({
                            title: this.t('common.toast.sessionAboutToExpire', { expiresInMinutes: expiresInMinutes }),
                            color: expiresInMinutes < 10 ? 'error' : 'warning',
                            duration: 10 * 60 * 1000
                        });
                    }
                });
            }
            this.ping(false);
        }
        if (init) {
            this.pingTimeout = setTimeout(() => { wrapper() }, 30 * 1000);
        } else {
            this.pingTimeout = setTimeout(() => { wrapper() }, 10 * 60 * 1000);
        }
    }

    isAuthPage() {
        return this.props.location.pathname.includes('/auth/');
    }

    goToLoginPage() {
        this.openLink('/auth/login');
    }

    reload(newUrl) {
        window.location.href = this.buildLink(newUrl || '/');
    }

    hasPermission(requiredPermissionsArray) {
        if (!Array.isArray(requiredPermissionsArray)) {
            requiredPermissionsArray = [requiredPermissionsArray];
        }
        if (requiredPermissionsArray.length === 0) {
            return {
                required: requiredPermissionsArray,
                found: [],
                all: true,
                none: true,
                any: true
            };
        }
        if (!this.state.user) {
            return {
                required: requiredPermissionsArray,
                found: [],
                all: false,
                none: true,
                any: false
            };
        }
        let permissionsFound = [];
        this.state.user.role.permission.forEach(perm => {
            if (permissionsFound.includes(perm.ident)) {
                return;
            }
            if (requiredPermissionsArray.includes(perm.ident)) {
                permissionsFound.push(perm.ident);
            }
        });
        return {
            required: requiredPermissionsArray,
            found: permissionsFound,
            all: requiredPermissionsArray.length === permissionsFound.length,
            none: permissionsFound.length === 0,
            any: permissionsFound.length > 0
        }
    }

    getUserConfig(key, defaultValue) {
        if (!this.state.user) {
            return defaultValue;
        }
        if (!this.state.user.user) {
            return defaultValue;
        }
        if (!this.state.user.user.configuration) {
            return defaultValue;
        }
        if (!(key in this.state.user.user.configuration)) {
            return defaultValue;
        }
        return this.state.user.user.configuration[key] || defaultValue;
    }

    getConfig(key, defaultValue) {
        if (!this.state.user) {
            return defaultValue;
        }
        if (!this.state.user.configuration) {
            return defaultValue;
        }
        if (!(key in this.state.user.configuration)) {
            return defaultValue;
        }
        return this.state.user.configuration[key];
    }

    isRegion(checkRegions) {
        if (!this.state.user) {
            return false;
        }
        for (let i = 0; i < checkRegions.length; i++) {
            let checkRegion = checkRegions[i].toLowerCase();
            if (
                this.state.user.region.countryCode.toLowerCase() === checkRegion ||
                this.state.user.region.ident.toLowerCase() === checkRegion ||
                this.state.user.region.name.toLowerCase() === checkRegion
            ) {
                return true;
            }
        }
        return false;
    }

    getRegionIdent() {
        if (!this.state.user) {
            return null;
        }
        return this.state.user.region.ident.toUpperCase();
    }

    getOrigin() {
        const { lang, region } = this.state;
        return `${window.location.origin}/${lang}/${region}`;
    }

    getUser() {
        return this.state.user;
    }

    render() {

        const isAuthPage = this.isAuthPage();

        const {
            lang,
            region,
            user,
            userUpdated,
            loading,
            toast,
            maintenance
        } = this.state;

        const allProps =
        {
            lang: lang,
            region: region,
            user: user,
            userUpdated: userUpdated,
            api: this.api,
            utils: this.utils,
            t: this.t,
            hasPermission: (requiredPermissionsArray) => this.hasPermission(requiredPermissionsArray),
            getUser: () => this.getUser(),
            getUserConfig: (key, defaultValue) => this.getUserConfig(key, defaultValue),
            isRegion: (checkRegions) => this.isRegion(checkRegions),
            getRegionIdent: () => this.getRegionIdent(),
            setLang: (newLang) => this.setLang(newLang),
            buildLink: (localLink, newlang, newregion) => this.buildLink(localLink, newlang, newregion),
            getOrigin: () => this.getOrigin(),
            openLink: (page, target) => this.openLink(page, target),
            reload: (page) => this.reload(page),
            setToken: (token, reloadUserData, afterReloadCallback) => this.setToken(token, reloadUserData, afterReloadCallback),
            registerToken: (tokenData) => this.api.registerToken(tokenData),
            reloadUserData: (afterReloadCallback) => this.reloadUserData(afterReloadCallback),
            showToast: (data) => this.showToast(data),
            removeToast: (ts) => this.removeToast(ts),
            getConfig: (key, defaultValue) => this.getConfig(key, defaultValue),
            getStarterLink: (overrideUserData) => this.getStarterLink(overrideUserData),
            ...this.props
        };

        if (loading) {
            return (
                <div className="fpl">
                    <img src={logo} alt="Sundesk" className="img-fluid animate-appear" />
                </div>
            )
        }

        const viewToast = (
            <PageToasts
                toasts={toast}
                removeToast={(ts) => this.removeToast(ts)}
            />
        )

        const viewLoadingBar = (
            <LoadingBar
                ref={this.loadingBarRef}
                height={3}
                color='#007bff'
            />
        )

        const viewNonProductionAlert = [appEnv.prod].includes(appEnvCurrent) ? (
            <></>
        ) : (
            <div className="text-center w-100 position-fixed" style={{ zIndex: 9999, fontSize: 16, pointerEvents: 'none', opacity: '0.8', maxWidth: '100vw' }}>
                <pre className='bg-warning px-20 py-10 m-10 text-truncate' style={{ display: 'inline-block', borderRadius: 10, pointerEvents: 'none', overflow: 'hidden', maxWidth: '66vw', margin: '0 auto', overflowX: 'hidden' }}>
                    {this.t('common.tooltip.testEnv')}
                </pre>
            </div>
        )

        const viewRoutes = (
            <TransitionGroup>
                {
                    (!isAuthPage)
                        ?
                        (
                            <Route>
                                <Route
                                    render={
                                        () => (
                                            <PageSidebar {...allProps} />
                                        )
                                    }
                                />
                                <Route
                                    render={
                                        () => (
                                            <PageNavbar {...allProps} />
                                        )
                                    }
                                />
                            </Route>
                        )
                        :
                        (
                            <></>
                        )
                }
                <CSSTransition
                    key={this.props.location.pathname}
                    timeout={isAuthPage ? 0 : 300}
                    classNames="rui-router-transition"
                    unmountOnExit
                >
                    <Router {...allProps} />
                </CSSTransition>
            </TransitionGroup>
        )

        const viewMaintenance = (
            <Fragment>
                <MaintenancePage {...allProps} />
            </Fragment>
        )

        return maintenance
            ? (
                <Fragment>
                    {viewMaintenance}
                </Fragment>
            )
            : (
                <Fragment>
                    {viewToast}
                    {viewLoadingBar}
                    {viewNonProductionAlert}
                    {viewRoutes}
                </Fragment>
            );
    }
}

const AppComponent = withRouter(App);

class AppWrapper extends Component {
    render() {
        return (
            <BrowserRouter>
                <Route
                    path={`/:lang?/:region?`}
                    render={
                        (props) => (
                            <AppComponent {...props} />
                        )
                    }
                />
            </BrowserRouter>
        );
    }
}

export default AppWrapper;

