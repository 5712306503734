import React, { Fragment } from 'react';
import { Row, Col, Card } from "react-bootstrap";
import Icon from '../../components/icon';
import LowerComponent from '../../components/component/lower';
import ModalSignature from '../../components/signature/modal';
import Timeline from '../../components/timeline';

class PageCompanySignature extends LowerComponent {

    constructor(props) {
        super(props);
        this.state = {
            modalSignatureParams: false,
            signingUser: ''
        }
    }

    showModalSignatureParams() {
        if (!this.props.representativeUuid) {
            this.props.showToast({
                title: this.props.t('common.toast.selectRepresentative'),
                color: "danger"
            });
            return;
        }
        this.setState({
            modalSignatureParams: true,
            signingUser: this.props.users.find(user => user.uuid === this.props.representativeUuid)
        })

    }

    render() {

        const {
            isLoading,
            files,
            uuid,
            signatureStatuses,
        } = this.props;

        const {
            signingUser
        } = this.state;

        const timelineItems = signatureStatuses.map((signatureStatus, signatureStatusIndex) => {
            return (
                <Timeline.Item
                    key={signatureStatus.uuid}
                    icon={<Icon name="check-circle" />}
                    date={signatureStatus.created}
                    swap={signatureStatusIndex % 2 === 0}
                >
                    <h3 className="py-0">{this.props.t(`common.signatureStatus.${signatureStatus.ident}`)}</h3>
                    <p>{this.props.t('page.company.component.signature.party') + ' ' + signatureStatus.party}</p>
                    <p dangerouslySetInnerHTML={{ __html: signatureStatus.comment }} />
                </Timeline.Item>
            );
        });

        return (
            <Fragment>

                <ModalSignature
                    t={this.props.t}
                    api={this.props.api}
                    isRegion={this.props.isRegion}
                    isLoading={this.props.isLoading}
                    setUpperState={(newState, callback) => { this.props.setUpperState(newState, callback) }}
                    showToast={this.props.showToast}
                    companyUuid={uuid}
                    show={this.state.modalSignatureParams}
                    onHide={() => { this.setState({ modalSignatureParams: false }) }}
                    header={`${signingUser.firstName} ${signingUser.lastName} - ${signingUser.emailAddress}`}
                    endpoint={`/company`}
                    files={[...files].reverse()}
                    onSigned={(newState, callback) => {
                        this.setState(
                            {
                                modalSignatureParams: false,
                            },
                            () => {
                                this.props.setUpperState(newState, callback);
                                this.props.showToast({
                                    title: this.props.t('common.toast.signatureSent'),
                                    color: "info"
                                });
                            });
                    }}
                />

                <Row>
                    <Col sm={12}>
                        <Card>
                            {
                                (this.props.hasPermission(['VIEW_COMPANY_SIGNATURE_BUTTON']).any) && (
                                    <Card.Header className="px-10 py-10 background-white">
                                        <button
                                            type="button"
                                            className="btn btn-uniform text-success"
                                            onClick={(e) => { this.showModalSignatureParams() }}
                                            disabled={isLoading}
                                        >
                                            <Icon name="pen-tool" />
                                            <span className="pl-10">
                                                {this.props.t('page.company.component.signature.send')}
                                            </span>
                                        </button>
                                    </Card.Header>
                                )
                            }
                            <Card.Body>
                                <Row>
                                    <Col sm={12}>
                                        {
                                            signatureStatuses.length ? (
                                                <Timeline lg="left">
                                                    {timelineItems}
                                                </Timeline>

                                            ) : (
                                                <div className="dropzone py-50 px-20 text-center">
                                                    {this.props.t('page.company.component.signature.await')}
                                                    {` `}
                                                    <Icon name="clock" />
                                                </div>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}
export default PageCompanySignature;