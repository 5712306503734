import React, { Fragment } from 'react';
import { Row, Col, Form, Card, InputGroup, Button, Modal } from "react-bootstrap";
import LowerComponent from '../../components/component/lower';
import Icon from '../../components/icon';
import { domain } from '../../constants';

class Contact extends LowerComponent {

    constructor(props) {
        super(props);
        this.state = {
            showCrmModal: false
        }
    }

    render() {
        const { isLoading, closed, msId } = this.props;
        const { showCrmModal } = this.state;
        const showCrmImport = this.props.hasPermission(['VIEW_CRM_BUTTON_IMPORT']).any && !msId;
        const showMsId = this.props.hasPermission(['VIEW_CRM_BUTTON_IMPORT', 'VIEW_CRM_COLUMN_ID']).any && msId;
        const useCoOwner = this.props.getConfig('useCoOwner') && !this.props.representsCompany;
        const crmUrl = `https://${domain.msCrm}/main.aspx?newWindow=true&pagetype=entityrecord&etn=opportunity&id=${msId}`;

        const crmModal = showCrmModal && (
            <Modal
                show={showCrmModal}
                onHide={() => { this.setState({ showCrmModal: false }) }}
                backdrop="static"
                keyboard={false}
                size="xl"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`CRM #${msId}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='embed-responsive embed-responsive-16by9' style={{ minHeight: '80vh' }}>
                    <iframe src={crmUrl} className="embed-responsive-item" style={{ minHeight: '80vh' }}></iframe>
                </Modal.Body>
            </Modal>
        )

        return (
            <Fragment>
                {crmModal}
                <h2>
                    {this.props.t('page.calculation.contact.title')}
                    {
                        this.props.msId && this.props.statuses?.length > 0 && (
                            <>{` - `}{this.props.statuses[this.props.statuses?.length - 1].ident}</>
                        )
                    }
                    <div className="float-md-right clear-section-container">

                        {
                            this.props.hasPermission(['VIEW_OFFER_CLEAR_SECTION_BUTTON']).any && (
                                <button
                                    type="button"
                                    className="btn text-danger px-0"
                                    disabled={isLoading || closed}
                                    onClick={(e) => { this.props.clearSection(e, ['contact']) }}
                                >
                                    {this.props.t(`common.button.clear`)}
                                </button>
                            )
                        }
                    </div>
                </h2>
                <Row>
                    <Col sm={12}>
                        <Card>
                            {
                                (showMsId || showCrmImport) &&
                                (
                                    <Card.Header className="px-25 py-10 background-white">
                                        {
                                            showMsId && (
                                                <>
                                                    <a
                                                        className="btn btn-uniform text-info"
                                                        style={{ userSelect: 'text' }}
                                                        disabled={isLoading || closed}
                                                        href={crmUrl}
                                                        target="_blank"
                                                        rel="nofollow noopener"
                                                        title={this.props.t(`common.tooltip._blank`)}
                                                    >
                                                        <Icon name="external-link" />
                                                    </a>
                                                    <button
                                                        className="btn btn-uniform text-success d-none d-lg-inline-block"
                                                        style={{ userSelect: 'text' }}
                                                        disabled={isLoading || closed}
                                                        title={this.props.t(`common.tooltip._popup`)}
                                                        onClick={(e) => { e.preventDefault(); this.setState({ showCrmModal: true }) }}
                                                    >
                                                        <Icon name="cast" />
                                                        <span className="pl-10">
                                                            {`CRM #${msId}`}
                                                        </span>
                                                    </button>
                                                </>
                                            )
                                        }
                                        {
                                            showCrmImport && (
                                                <button
                                                    type="button"
                                                    className="btn btn-uniform text-info mr-10"
                                                    disabled={isLoading || closed}
                                                    onClick={(e) => { this.setUpperState({ modalImportFromDynamicsCrm: true }) }}
                                                >
                                                    <Icon name="download" />
                                                    <span className="pl-10">
                                                        {this.props.t(`page.calculation.contact.importFromDynamicsCrm`)}
                                                    </span>
                                                </button>
                                            )
                                        }
                                    </Card.Header>
                                )
                            }
                            <Card.Body>
                                <Row>
                                    <Col md={12}>
                                        <Form.Check
                                            disabled={isLoading || closed}
                                            type="switch"
                                            id="representsCompany"
                                            label={this.props.t('page.calculation.contact.representsCompany')}
                                            checked={this.props.representsCompany || false}
                                            onChange={(e) => { this.setUpperState({ representsCompany: !this.props.representsCompany }) }}
                                            className="mb-10"
                                        />
                                    </Col>
                                </Row>
                                {
                                    this.props.representsCompany &&
                                    (
                                        <Fragment>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group className="required">
                                                        <Form.Label>
                                                            {this.props.t('page.calculation.contact.companyTaxNumber')}
                                                        </Form.Label>
                                                        <InputGroup>
                                                            <Form.Control
                                                                disabled={isLoading || closed}
                                                                type="text"
                                                                placeholder=""
                                                                required={true}
                                                                value={this.props.companyTaxNumber || ""}
                                                                onChange={event =>
                                                                    this.setUpperState({ companyTaxNumber: event.target.value })
                                                                }
                                                            />
                                                            {
                                                                (this.props.isRegion(['POLAND'])) && (
                                                                    <InputGroup.Append>
                                                                        <Button variant="outline-secondary" onClick={(e) => { this.props.findCompany() }} disabled={isLoading || closed}>
                                                                            <Icon name="search" />
                                                                        </Button>
                                                                    </InputGroup.Append>
                                                                )
                                                            }
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                {
                                                    (this.props.isRegion(['POLAND'])) && (
                                                        <Col md={6}>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    {this.props.t('page.calculation.contact.companyIdentNumber')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled={isLoading || closed}
                                                                    type="text"
                                                                    placeholder=""
                                                                    value={this.props.companyIdentNumber || ""}
                                                                    onChange={event =>
                                                                        this.setUpperState({ companyIdentNumber: event.target.value })
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    )
                                                }
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <Form.Group className="required">
                                                        <Form.Label>
                                                            {this.props.t('page.calculation.contact.companyName')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled={isLoading || closed}
                                                            type="text"
                                                            placeholder=""
                                                            required={true}
                                                            value={this.props.companyName || ""}
                                                            onChange={event =>
                                                                this.setUpperState({ companyName: event.target.value })
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    )
                                }
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="required">
                                            <Form.Label>
                                                {this.props.t('page.calculation.contact.firstName')}
                                            </Form.Label>
                                            <Form.Control
                                                disabled={isLoading || closed}
                                                type="text"
                                                placeholder=""
                                                required={true}
                                                value={this.props.firstName}
                                                onChange={event =>
                                                    this.setUpperState({ firstName: event.target.value, fullName: `${event.target.value} ${this.props.lastName}` })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="required">
                                            <Form.Label>
                                                {this.props.t('page.calculation.contact.lastName')}
                                            </Form.Label>
                                            <Form.Control
                                                disabled={isLoading || closed}
                                                type="text"
                                                placeholder=""
                                                required={true}
                                                value={this.props.lastName}
                                                onChange={event =>
                                                    this.setUpperState({ lastName: event.target.value, fullName: `${this.props.lastName} ${event.target.value}` })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className={'required'}>
                                            <Form.Label>
                                                {this.props.t('page.calculation.contact.emailAddress')}
                                            </Form.Label>
                                            <Form.Control
                                                disabled={isLoading || closed}
                                                type="email"
                                                placeholder=""
                                                required={true}
                                                value={this.props.emailAddress}
                                                onChange={event =>
                                                    this.setUpperState({ emailAddress: event.target.value })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="required">
                                            <Form.Label>
                                                {this.props.t('page.calculation.contact.mobilePhone')}
                                            </Form.Label>
                                            <Form.Control
                                                disabled={isLoading || closed}
                                                type="tel"
                                                placeholder=""
                                                required={true}
                                                value={this.props.mobilePhone}
                                                onChange={event =>
                                                    this.setUpperState({ mobilePhone: event.target.value })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {
                                    this.props.getConfig('useClientIdentNumber') && !this.props.representsCompany && (
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className={this.props.getConfig('useClientIdentNumberRequired') ? "required" : ""}>
                                                    <Form.Label>
                                                        {this.props.getConfig('useClientIdentNumberLabel') || this.props.t('page.calculation.contact.identNumber')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        disabled={isLoading || closed}
                                                        type="text"
                                                        placeholder=""
                                                        required={this.props.getConfig('useClientIdentNumberRequired')}
                                                        value={this.props.identNumber || ""}
                                                        onChange={event => {
                                                            this.setUpperState({ identNumber: event.target.value });
                                                            if (
                                                                this.props.utils.isValid(
                                                                    this.props.getConfig('useClientIdentNumberRequired'),
                                                                    event.target.value,
                                                                    this.props.getConfig('useClientIdentNumberValidator')
                                                                )
                                                            ) {
                                                                event.target.setCustomValidity("");
                                                            } else {
                                                                event.target.setCustomValidity(this.props.t('common.tooltip.invalid'));
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    )
                                }
                                {
                                    useCoOwner && (
                                        <>
                                            <Row>
                                                <Col md={12}>
                                                    <Form.Check
                                                        disabled={isLoading || closed}
                                                        type="switch"
                                                        id="hasCoOwner"
                                                        label={this.props.getConfig('useCoOwnerLabel') || this.props.t('page.calculation.contact.hasCoOwner')}
                                                        checked={this.props.hasCoOwner || false}
                                                        onChange={(e) => { this.setUpperState({ hasCoOwner: !this.props.hasCoOwner }) }}
                                                        className="mb-10"
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                }
                                {
                                    this.props.hasCoOwner && !this.props.representsCompany && (
                                        <>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group className="required">
                                                        <Form.Label>
                                                            {this.props.t('page.calculation.contact.firstName')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled={isLoading || closed}
                                                            type="text"
                                                            placeholder=""
                                                            required={useCoOwner}
                                                            value={this.props.coOwnerFirstName}
                                                            onChange={event =>
                                                                this.setUpperState({ coOwnerFirstName: event.target.value })
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="required">
                                                        <Form.Label>
                                                            {this.props.t('page.calculation.contact.lastName')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled={isLoading || closed}
                                                            type="text"
                                                            placeholder=""
                                                            required={useCoOwner}
                                                            value={this.props.coOwnerLastName}
                                                            onChange={event =>
                                                                this.setUpperState({ coOwnerLastName: event.target.value })
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group className="required">
                                                        <Form.Label>
                                                            {this.props.t('page.calculation.contact.emailAddress')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled={isLoading || closed}
                                                            type="email"
                                                            placeholder=""
                                                            required={false}
                                                            value={this.props.coOwnerEmailAddress}
                                                            onChange={event =>
                                                                this.setUpperState({ coOwnerEmailAddress: event.target.value })
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="required">
                                                        <Form.Label>
                                                            {this.props.t('page.calculation.contact.mobilePhone')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled={isLoading || closed}
                                                            type="tel"
                                                            placeholder=""
                                                            required={false}
                                                            value={this.props.coOwnerMobilePhone}
                                                            onChange={event =>
                                                                this.setUpperState({ coOwnerMobilePhone: event.target.value })
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {
                                                this.props.getConfig('useClientIdentNumber') && (
                                                    <Row>
                                                        <Col md={6}>
                                                            <Form.Group className={this.props.getConfig('useClientIdentNumberRequired') && useCoOwner ? "required" : ""}>
                                                                <Form.Label>
                                                                    {this.props.getConfig('useClientIdentNumberLabel') || this.props.t('page.calculation.contact.identNumber')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled={isLoading || closed}
                                                                    type="text"
                                                                    placeholder=""
                                                                    required={this.props.getConfig('useClientIdentNumberRequired') && useCoOwner}
                                                                    value={this.props.coOwnerIdentNumber || ""}
                                                                    onChange={event => {
                                                                        this.setUpperState({ coOwnerIdentNumber: event.target.value });
                                                                        if (
                                                                            this.props.utils.isValid(
                                                                                this.props.getConfig('useClientIdentNumberRequired') && useCoOwner,
                                                                                event.target.value,
                                                                                this.props.getConfig('useClientIdentNumberValidator')
                                                                            )
                                                                        ) {
                                                                            event.target.setCustomValidity("");
                                                                        } else {
                                                                            event.target.setCustomValidity(this.props.t('common.tooltip.invalid'));
                                                                        }
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment >
        );
    }
}
export default Contact;