import { Component } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";

class PvPanelsAction extends Component {

    render() {
        return (
            <DropdownButton
                as={ButtonGroup}
                title={this.props.t('page.calculation.pvDesigner.addPvSelect')}
                variant="link"
                menuAlign={{ lg: 'right' }}
                disabled={this.props.isLoading || this.props.isDisabled}
            >
                <Dropdown.Item as="button" onClick={(e) => this.props.addPolygonPanels('single-vertical', e)}>
                    {this.props.t('page.calculation.pvDesigner.addPvSingleVertical')}
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={(e) => this.props.addPolygonPanels('single-horizontal', e)}>
                    {this.props.t('page.calculation.pvDesigner.addPvSingleHorizontal')}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item as="button" onClick={(e) => this.props.addPolygonPanels('multiple-vertical', e)}>
                    {this.props.t('page.calculation.pvDesigner.addPvFillVertically')}
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={(e) => this.props.addPolygonPanels('multiple-horizontal', e)}>
                    {this.props.t('page.calculation.pvDesigner.addPvFillHorizontally')}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item as="button" onClick={(e) => this.props.removePolygonPanels(e)}>
                    {this.props.t('page.calculation.pvDesigner.clearPv')}
                </Dropdown.Item>
            </DropdownButton>
        )
    }
}

export default PvPanelsAction;