import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import BaseComponent from '../../components/component/base';
import Datatable from "../../components/datatable";
import Icon from '../../components/icon';
import ModalFiles from '../Offer/modal-files';
import { Row, Col, Card, Form } from "react-bootstrap";
import MarginSmile from '../../components/margin-smile';

class Content extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            uuid: null,
            showModal: false,
            modalTitle: "",
            files: [],
            onlyMyArea: this.props.hasPermission(['VIEW_MY_AREA_FILTER_BUTTON']).any
        }

        this.columns = [];

        this.columns.push({
            Header: this.props.t(`page.${this.props.typePlural}.table.ident`),
            accessor: "ident_5",
            width: 140
        });

        if (this.props.typePlural !== 'leads' &&
            this.props.hasPermission(['VIEW_GAMIFY_MARGIN_SMILEYS']).any) {
            this.columns.push({
                Header: this.props.t(`page.${this.props.typePlural}.table.marginType`),
                accessor: "sclr_16",
                width: 120
            });
        }

        if (this.props.getUserConfig('offersTableUseUpdated', false)) {
            this.columns.push({
                Header: this.props.t(`page.${this.props.typePlural}.table.updated`),
                accessor: "updated_13"
            });
        }

        if (this.props.hasPermission(['VIEW_OFFERS_CREATORS']).any ||
            this.props.getUserConfig('offersTableUseSellerEmailAddress', false)) {
            this.columns.push({
                Header: this.props.t(`page.${this.props.typePlural}.table.sellerEmailAddress`),
                accessor: "emailAddress_12"
            });
        }

        this.columns.push({
            Header: this.props.t(`page.${this.props.typePlural}.table.emailAddress`),
            accessor: "emailAddress_1"
        });

        this.columns.push({
            Header: this.props.t(`page.${this.props.typePlural}.table.mobilePhone`),
            accessor: "mobilePhone_8"
        });

        this.columns.push({
            Header: this.props.t(`page.${this.props.typePlural}.table.firstName`),
            accessor: "firstName_2"
        });

        this.columns.push({
            Header: this.props.t(`page.${this.props.typePlural}.table.lastName`),
            accessor: "lastName_3"
        });

        if (this.props.typePlural === 'leads') {
            this.columns.push({
                Header: this.props.t(`page.${this.props.typePlural}.table.status`),
                accessor: "ident_6"
            });
        }

        if (this.props.typePlural !== 'leads') {
            this.columns.push({
                Header: this.props.t(`page.${this.props.typePlural}.table.area`),
                accessor: "ident_10",
                width: 100
            });
        }

        this.columns.push({
            Header: (<>&nbsp;</>),
            accessor: "actions",
            sortable: false,
            filterable: false,
            width: 135
        });
    }

    render() {
        return (
            <Fragment>

                <ModalFiles
                    {...this.props}
                    modalTitle={this.state.modalTitle}
                    showModal={this.state.showModal}
                    onModalHide={() => this.setState({ showModal: false, uuid: null, files: [] })}
                    hasPermission={this.props.hasPermission}
                    getUserConfig={this.props.getUserConfig}
                    uuid={this.state.uuid}
                    files={this.state.files}
                    isReadOnly={true}
                    withOptions={false}
                    paddingLeft={30}
                />

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Header className="px-10 py-10 background-white">
                                <button
                                    type="button"
                                    className="btn btn-uniform text-success"
                                    disabled={false}
                                    onClick={(e) => { this.props.openLink(`/${this.props.typeSingular}/`) }}
                                >
                                    <Icon name="plus-square" />
                                    <span className="pl-10">
                                        {this.props.t(`page.${this.props.typePlural}.newOffer`)}
                                    </span>
                                </button>
                                {
                                    this.props.hasPermission(['VIEW_MY_AREA_FILTER_BUTTON']).any && (
                                        <div className="float-md-right clear-section-container">
                                            <Form.Check
                                                type="switch"
                                                id="onlyMyArea"
                                                label={this.props.t('page.users.onlyMyArea')}
                                                checked={this.state.onlyMyArea || false}
                                                onChange={(e) => { this.setState({ onlyMyArea: !this.state.onlyMyArea }) }}
                                            />
                                        </div>
                                    )
                                }
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Datatable
                                    id={'offers-table'}
                                    endpoint={`offer/table`}
                                    monitorProp={this.state.onlyMyArea}
                                    withData={{
                                        onlyMyArea: this.state.onlyMyArea,
                                        offer: {
                                            type: this.props.typeSingular
                                        }
                                    }}
                                    columns={this.columns}
                                    sort={[
                                        {
                                            id: "ident_5",
                                            desc: true
                                        }
                                    ]}
                                    withCheckbox={false}
                                    onChecked={(data) => { }}
                                    transform={[
                                        {
                                            field: "ident_5",
                                            fn: (data) => {
                                                const theIdent = data.closed_14 === "1" ? `🔒 ${data.ident_5}` : data.ident_5
                                                return <Link to={this.props.buildLink(`/${data.type_4}/${data.uuid_0}/`)}>{theIdent}</Link>
                                            }
                                        },
                                        {
                                            field: "sclr_16",
                                            fn: (data) => {
                                                let val = this.round(data.sclr_16, 2);
                                                return <><MarginSmile val={val} />{` ${val}%`}</>
                                            }
                                        }
                                    ]}
                                    badges={[
                                        {
                                            "field": "representsCompany_7",
                                            "divClass": "text-center",
                                            "variants": {
                                                "0": "secondary",
                                                "1": "primary"
                                            }
                                        }
                                    ]}
                                    actions={[
                                        this.props.typePlural === 'leads' ? null : {
                                            action: "files",
                                            disabled: (data) => !data.files.length,
                                            callback: (data) => {
                                                this.setState({ showModal: true, uuid: data.uuid_0, modalTitle: data.ident_5, files: data.files })
                                            }
                                        },
                                        {
                                            action: "edit",
                                            callback: (data, target) => {
                                                this.props.openLink(`/${data.type_4}/${data.uuid_0}/`, target)
                                            }
                                        },
                                        {
                                            action: 'delete',
                                            callback: (data) => {
                                                this.confirmRemove(() => {
                                                    this.props.api.post(
                                                        `/offer/trash`,
                                                        {
                                                            "offer": {
                                                                "uuids": [data.uuid_0]
                                                            }
                                                        },
                                                        () => {
                                                            this.props.api.dispatchEvent('RELOAD_TABLE');
                                                        }
                                                    )
                                                })
                                            }
                                        }
                                    ].filter(Boolean)}
                                    {...this.props}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }

}

export default Content
