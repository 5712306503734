import React, { Component } from 'react';
import { Table, Form } from "react-bootstrap";
import Icon from '../../components/icon';

class FilesTable extends Component {

    changeFileFinal(theFile) {

        let newFiles = [...this.props.files];
        let changeFilesAsFinal = { [theFile.uuid]: theFile };
        let newState = false;

        newFiles.forEach((mapFile) => {
            if (mapFile.uuid === theFile.uuid) {
                newState = !mapFile.final;
            }
            if (mapFile.parent?.uuid === theFile.uuid) {
                changeFilesAsFinal[mapFile.uuid] = mapFile;
            }
        });

        for (const [uuid, file] of Object.entries(changeFilesAsFinal)) {
            newFiles = newFiles.map((mapFile) => {
                let newFile = { ...mapFile };
                if (newFile.uuid === file.uuid) {
                    newFile.final = newState;
                } else if (file.ident.indexOf("offer") > -1 || file.ident.indexOf("budget") > -1 || file.ident.indexOf("agreement") > -1) {
                    if (newFile.ident === file.ident && newFile.final) {
                        newFile.final = false;
                    }
                }
                return newFile;
            });
        }

        this.props.setUpperState({ files: newFiles });
    }

    render() {

        return (
            <Table size="xs" className="mb-0" responsive>
                <thead>
                    <tr>
                        <th scope="col" style={{ minWidth: 150 }} className={`pl-${this.props.paddingLeft || 20}`}>
                            {this.props.t('page.calculation.files.table.filename')}
                        </th>
                        <th scope="col" style={{ minWidth: 160 }}>
                            {this.props.t('page.calculation.files.table.created')}
                        </th>
                        <th scope="col" style={{ minWidth: 150 }}>
                            {this.props.t('page.calculation.files.table.validUntil')}
                        </th>
                        {
                            this.props.withOptions && (
                                <>
                                    <th scope="col" style={{ minWidth: 60 }} className="text-center">
                                        {this.props.t('page.calculation.files.table.final')}
                                    </th>
                                    <th scope="col" style={{ minWidth: 60 }} className="text-center">
                                        {this.props.t('page.calculation.files.table.options')}
                                    </th>
                                </>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.files.filter(itm => !itm.trash).length ?
                            this.props.files.map((file) => {
                                if (file.trash) {
                                    return null;
                                }
                                if (
                                    file.ident.indexOf("budget") > -1 &&
                                    !this.props.hasPermission(['VIEW_OFFER_BUDGET_BUTTON']).any
                                ) {
                                    return null;
                                }
                                return (
                                    <tr key={file.uuid} className={file.className}>
                                        <td className={`pl-${this.props.paddingLeft || 20}`}>
                                            <a target="_blank" rel="rel noopener noreferrer" href={file.link}>
                                                {file.filename}
                                            </a>
                                        </td>
                                        <td className="align-middle">
                                            {file.created}
                                        </td>
                                        <td className="align-middle pr-10">
                                            {file.validUntil}
                                        </td>
                                        {
                                            this.props.withOptions && (
                                                <>
                                                    <td className="align-middle pr-10 text-center">
                                                        <Form.Check
                                                            type="switch"
                                                            id={`final-${file.uuid}`}
                                                            checked={file.final || false}
                                                            disabled={this.props.isLoading || this.props.closed}
                                                            label={``}
                                                            onChange={e => {
                                                                this.changeFileFinal(file);
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <button
                                                            disabled={this.props.isLoading || this.props.closed}
                                                            type="button"
                                                            className="btn btn-custom-round text-danger"
                                                            data-tip={this.props.t('common.tooltip.remove')}
                                                            onClick={
                                                                (e) => {
                                                                    this.props.confirmRemove(
                                                                        () => {
                                                                            this.props.handleElementTrash(
                                                                                'files',
                                                                                file.uuid
                                                                            );
                                                                        }
                                                                    )
                                                                }
                                                            }
                                                        >
                                                            <Icon name="minus-square" />
                                                        </button>
                                                    </td>
                                                </>
                                            )
                                        }
                                    </tr>
                                );
                            })
                            :
                            (
                                <tr>
                                    <td className={`pl-${this.props.paddingLeft || 20}`}>
                                        {this.props.t('page.calculation.files.table.empty')}
                                    </td>
                                </tr>
                            )
                    }
                </tbody>
            </Table>
        );
    }
}

export default FilesTable;