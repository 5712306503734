import React from 'react'
import { SwatchesPicker } from 'react-color'

class ColorPicker extends React.Component {
    render() {
        return (
            <div style={{ position: 'absolute', zIndex: 3 }}>
                <SwatchesPicker color={this.props.value} onChange={this.props.onChange} disableAlpha={true} />
            </div>
        )
    }
}

export default ColorPicker