import React, { Fragment } from 'react';
import { Row, Col } from "react-bootstrap";
import LowerComponent from '../../components/component/lower';
import Icon from '../../components/icon';
import { Link } from 'react-router-dom';

class Options extends LowerComponent {

    render() {

        const { isLoading, closed } = this.props;

        if (closed) {
            return null;
        }

        return (
            <Fragment>
                <Row className="mt-30">
                    <Col xs={12} sm={6} className="mb-30">
                        <button
                            type="submit"
                            className="btn btn-uniform btn-lg btn-success"
                            disabled={isLoading || closed}
                        >
                            <Icon name="save" />
                            <span className="pl-10">
                                {this.props.t('page.calculation.options.saveData')}
                            </span>
                        </button>
                    </Col>
                    <Col xs={12} sm={6} className="mb-30 text-sm-right">
                        {
                            this.props.type !== "calculation" &&
                                this.props.hasPermission(['ADMIN', 'VIEW_OFFER_CALCULATION']).any
                                ?
                                (
                                    <button
                                        type="button"
                                        className="btn btn-uniform btn-lg btn-outline"
                                        disabled={isLoading || closed}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setUpperState(
                                                {
                                                    type: "calculation"
                                                },
                                                () => {
                                                    this.props.onSubmit(e)
                                                }
                                            )
                                        }}
                                    >
                                        <Icon name="save" />
                                        <span className="pl-10">
                                            {this.props.t('page.calculation.options.saveAsCalculation')}
                                        </span>
                                    </button>
                                )
                                :
                                (
                                    <></>
                                )
                        }
                        {
                            this.props.uuid
                                && this.props.type === "calculation"
                                && this.props.hasPermission(['VIEW_OFFER_CLOSE_BUTTON']).any
                                ? this.props.projectuuid ? (
                                    <Link
                                        disabled={isLoading || closed}
                                        to={this.props.buildLink(`/operations/#/project/${this.props.projectuuid}/details`)}
                                        target="_blank"
                                    >
                                        <Icon name="link" />
                                        <span className="pl-10">
                                            {this.props.t('page.calculation.options.openProject')}
                                            {` `}
                                            {this.props.projectlabel}
                                        </span>
                                    </Link>
                                ) :
                                    (
                                        <button
                                            type="button"
                                            className="btn btn-uniform btn-lg btn-link"
                                            disabled={isLoading || closed}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.converToProject();
                                            }}
                                        >
                                            <Icon name="calendar" />
                                            <span className="pl-10">
                                                {this.props.t('page.calculation.options.convertToProject')}
                                            </span>
                                        </button>
                                    )
                                :
                                (
                                    <></>
                                )
                        }
                    </Col>
                </Row>
            </Fragment>
        );
    }
}
export default Options;