import React, { Fragment } from 'react';
import { Row, Col, Card, Tabs, Tab, ListGroup, Button, Spinner } from "react-bootstrap";
import Icon from '../../components/icon';
import LowerComponent from '../../components/component/lower';
import ModalSignature from '../../components/signature/modal';
import Timeline from '../../components/timeline';

class ProjectSignature extends LowerComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            modalSignatureParams: false,
            hasCoOwner: props.hasCoOwner || false,
            projectUuid: props.projectUuid || '',
            fullName: props.fullName || '',
            emailAddress: props.emailAddress || '',
            files: [],
            signatures: [],
            signatureUuid: '',
            signatureStatuses: [],
            anchorsFetch: props.anchors || {},
        }
    }

    componentDidMount() {
        this.onInit();
    }

    onInit(lastSuccessfulCallback) {
        this.fetchSignatures(() => {
            this.fetchFiles(() => {
                if (this.state.signatures.length > 0) {
                    this.loadSignatureStatuses(this.state.signatures[0].uuid, lastSuccessfulCallback);
                }
            });
        });
    }

    fetchSignatures(callback) {
        this.setState({ isLoading: true }, () => {
            this.props.api.post(
                `/signature/list`,
                {
                    "anchors": this.state.anchorsFetch
                },
                (data) => {
                    this.setState({
                        isLoading: false,
                        signatures: data.signatures,
                    }, callback)
                },
                (errorObject) => {
                    this.props.showToast({
                        errorObject: errorObject,
                        title: this.props.t('common.toast.error'),
                        color: 'danger'
                    });
                }
            );
        })
    }

    fetchFiles(callback) {
        this.setState({ isLoading: true }, () => {
            this.props.api.post(
                `/file/list`,
                {
                    "anchors": this.state.anchorsFetch
                },
                (data) => {
                    const files = [...data.files]
                        .filter(itm => ['pdf'].includes(itm.file.extension));
                    this.setState({
                        isLoading: false,
                        files: files,
                    }, callback)
                },
                (errorObject) => {
                    this.props.showToast({
                        errorObject: errorObject,
                        title: this.props.t('common.toast.error'),
                        color: 'danger'
                    });
                }
            );
        })
    }

    loadSignatureStatuses(uuid, callback) {
        this.setState(
            {
                signatureStatuses: [],
                isLoading: true,
                signatureUuid: uuid,
            },
            () => {
                this.props.api.post(
                    `/signature/describe`,
                    {
                        'signature': {
                            'uuid': uuid
                        }
                    },
                    (data) => {
                        let newSignatures = this.state.signatures.map(
                            oldSignature => {
                                let newSignature = { ...oldSignature };
                                if (newSignature.uuid === data.signature.uuid) {
                                    newSignature = { ...data.signature };
                                }
                                return newSignature;
                            });
                            
                        this.setState({
                            'isLoading': false,
                            'signatures': newSignatures,
                            'signatureUuid': data.signature.uuid || '',
                            'signatureStatuses': data.signatureStatuses || [],
                        }, callback);

                        setTimeout(() => {
                            this.props.api.dispatchEvent('REFETCH_FILES');
                        }, 1000);
                    },
                    (e) => {
                        let ident = e?.ident || `${uuid}-error-status`;
                        let status = e?.data?.error?.ident || null;
                        let message = '';
                        try {
                            status = JSON.parse(e?.data?.error?.ident);
                            message = status.error_message;
                        } catch (ee) {
                            // ...
                        }
                        this.setState({
                            'isLoading': false,
                            'signatureUuid': uuid,
                            'signatureStatuses': [{
                                uuid: ident,
                                created: '',
                                ident: 'problem',
                                party: 'Scrive',
                                comment: message
                            }]
                        })
                    }
                );
            }
        );
    }

    getDocumentMainFile(e, signatureUuid) {
        e.preventDefault();
        const {
            api,
            showToast,
            t
        } = this.props;
        this.setState({ isLoading: true }, () => {
            api.post(
                `/signature/download`,
                {
                    "signature": {
                        "uuid": signatureUuid,
                    }
                },
                (successData) => {
                    this.setState({ isLoading: false }, () => {
                        if (successData.file && successData.file.link) {
                            window.open(successData.file.link);
                        }
                    });

                },
                (errorData) => {
                    this.setState({ isLoading: false }, () => {
                        showToast({
                            errorObject: errorData,
                            title: t('common.toast.error'),
                            color: "danger"
                        });
                    });
                }
            );
        });
    }

    render() {

        const {
            isLoading,
            files,
            projectUuid,
            fullName,
            emailAddress,
            signatures,
            signatureUuid,
            signatureStatuses,
            modalSignatureParams,
            hasCoOwner,
        } = this.state;

        const timelineItems = signatureStatuses.map((signatureStatus, signatureStatusIndex) => {
            return (
                <Timeline.Item
                    key={signatureStatus.uuid}
                    icon={<Icon name="check-circle" />}
                    date={signatureStatus.created}
                    swap={signatureStatusIndex % 2 === 0}
                >
                    <h3 className="py-0">{this.props.t(`common.signatureStatus.${signatureStatus.ident}`)}</h3>
                    <p>{this.props.t('page.calculation.signature.party') + ' ' + signatureStatus.party}</p>
                    <p dangerouslySetInnerHTML={{ __html: signatureStatus.comment }} />
                </Timeline.Item>
            );
        });

        const placeholder = (
            <div className="dropzone py-50 px-20 text-center">
                {this.props.t('page.calculation.signature.await')}
                {` `}
                <Icon name="clock" />
            </div>
        )

        const metrics = (signature) => [
            {
                "label": this.props.t(`page.calculation.signature.metric.documentMainFileName`),
                "value": (
                    <button className='btn-link p-0 text-right' onClick={(e) => { this.getDocumentMainFile(e, signature.uuid) }}>{signature.documentMainFileName || "---"}</button>
                )
            },
            { "label": this.props.t(`page.calculation.signature.metric.documentId`), "value": signature.documentId },
            { "label": this.props.t(`page.calculation.signature.metric.documentStatus`), "value": signature.documentStatus },
            { "label": this.props.t(`page.calculation.signature.metric.created`), "value": signature.created },
            { "label": this.props.t(`page.calculation.signature.metric.agreementType`), "value": this.props.t(`common.agreementType.${signature.agreementType || 'contract'}`) },
            { "label": this.props.t(`page.calculation.signature.metric.comment`), "value": signature.comment || "---" },
        ];

        const {
            t,
            api,
            isRegion,
            hasPermission,
            getUserConfig,
            showToast,
        } = this.props;

        const loadingView = (
            <Row>
                <Col sm={12} className="text-center p-20">
                    <Spinner animation="border" />
                </Col>
            </Row>
        )

        return (
            <Fragment>

                <ModalSignature
                    show={modalSignatureParams}
                    universalValidation={true}
                    showParties={false}
                    onHide={() => { this.setState({ modalSignatureParams: false }) }}
                    header={`${fullName} - ${emailAddress}`}
                    files={[...files].reverse()}
                    t={t}
                    api={api}
                    isLoading={isLoading}
                    setUpperState={(newState, callback) => { this.setState(newState, callback) }}
                    isRegion={isRegion}
                    hasPermission={hasPermission}
                    getUserConfig={getUserConfig}
                    showToast={showToast}
                    projectUuid={projectUuid}
                    endpoint={`/project`}
                    isSalesProcess={false}
                    onSigned={(newState) => {
                        this.setState({
                            modalSignatureParams: false,
                            ...newState
                        }, () => {
                            this.props.showToast({
                                title: this.props.t('common.toast.signatureSent'),
                                color: "info"
                            });
                            this.onInit(() => {
                                setTimeout(() => {
                                    this.props.api.dispatchEvent('REFETCH_FILES');
                                }, 1000);
                            });
                        });
                    }}
                />

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Header className="px-10 py-10 background-white">
                                {
                                    (hasPermission(['VIEW_PROJECT_SIGNATURE_BUTTON']).any) && (
                                        <button
                                            type="button"
                                            className="btn btn-uniform text-success"
                                            onClick={(e) => { this.setState({ modalSignatureParams: true }) }}
                                            disabled={isLoading}
                                        >
                                            <Icon name="pen-tool" />
                                            <span className="pl-10">
                                                {t('page.calculation.signature.send')}
                                            </span>
                                        </button>
                                    )
                                }
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ? loadingView : signatures.length > 0 ? (
                                        <>
                                            <Tabs
                                                className="align-content-between nicebar border-bottom"
                                                id="signatures"
                                                activeKey={signatureUuid}
                                                onSelect={(k) => this.loadSignatureStatuses(k)}
                                            >
                                                {
                                                    signatures.map(signature => (
                                                        <Tab key={signature.uuid} title={`${signature.comment || t(`common.agreementType.${signature.agreementType || 'contract'}`)} (${signature.created})`} tabClassName="mt-10" eventKey={`${signature.uuid}`}>
                                                            <Row>
                                                                <Col sm={12} className="mt-30">
                                                                    {
                                                                        signatureStatuses.length ? (
                                                                            <Timeline lg="left">
                                                                                {timelineItems}
                                                                            </Timeline>

                                                                        ) : placeholder
                                                                    }
                                                                </Col>
                                                                <Col sm={12} className="mt-30">
                                                                    <ListGroup>
                                                                        {
                                                                            metrics(signature).map(metric => (
                                                                                <ListGroup.Item key={`metric-${metric.label}`} className="d-flex justify-content-between align-items-center">
                                                                                    <div>
                                                                                        {metric.label}
                                                                                    </div>
                                                                                    <div>
                                                                                        {metric.value}
                                                                                    </div>
                                                                                </ListGroup.Item>
                                                                            ))
                                                                        }
                                                                    </ListGroup>
                                                                </Col>
                                                            </Row>
                                                        </Tab>
                                                    ))
                                                }
                                            </Tabs>
                                        </>
                                    ) : placeholder
                                }

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}
export default ProjectSignature;