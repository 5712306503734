import React, { Component, Fragment } from 'react';
import { Row, Col, Modal } from "react-bootstrap";
import FilesTable from './files-table';

class ModalFiles extends Component {

    render() {

        return (
            <Fragment>
                <Modal
                    show={this.props.showModal}
                    onHide={() => { this.props.onModalHide() }}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.modalTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-0 pt-0 border-top-0">
                        <Row>
                            <Col sm={12}>
                                <FilesTable
                                    t={this.props.t}
                                    files={this.props.files}
                                    withOptions={this.props.withOptions}
                                    paddingLeft={this.props.paddingLeft || 20}
                                    hasPermission={this.props.hasPermission}
                                    getUserConfig={this.props.getUserConfig}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Fragment>
        )
    }

}

export default ModalFiles
