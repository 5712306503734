import './style.scss';

import React, { Component } from 'react';
import { Row, Col, Card, Form, } from "react-bootstrap";
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';

class ReferralManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props.user.referralManager
        }
    }

    render() {

        const theAvatar = this.state.imageUrl ? (
            <img src={this.state.imageUrl} alt="" key="dz-inputContent" />
        ) : (
            <div className="img" key="dz-inputContent">
                {this.props.utils.getInitials(this.state.firstName, this.state.lastName)}
            </div>
        );

        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={
                        {
                            '#': this.props.t('page.referralManager.shortTitle'),
                        }
                    }
                    meta={{ title: this.props.t(`page.referralManager.longTitle`) }}
                    t={this.props.t}
                >
                    <h1>
                        {this.props.t('page.referralManager.longTitle')}
                    </h1>
                </PageTitle>
                <PageContent>
                    <Form method="post" onSubmit={(e) => { }}>
                        <Card>
                            <Card.Body>
                                <Row className="pt-20">
                                    <Col sm={12} md={2}>
                                        <Row>
                                            <Col sm={12}>
                                                <Form.Group>
                                                    <div className="rui-profile-img">
                                                        {theAvatar}
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12} md={10}>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        {this.props.t('page.referralDetails.firstName')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={(e) => { }}
                                                        value={this.state.firstName}
                                                        type="text"
                                                        autoComplete="off"
                                                        readOnly={true}
                                                        placeholder={this.props.t('common.placeholder.any')}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>
                                                        {this.props.t('page.referralDetails.emailAddress')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={(e) => { }}
                                                        value={this.state.emailAddress}
                                                        type="text"
                                                        autoComplete="off"
                                                        readOnly={true}
                                                        placeholder={this.props.t('common.placeholder.any')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        {this.props.t('page.referralDetails.lastName')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={(e) => { }}
                                                        value={this.state.lastName}
                                                        type="text"
                                                        autoComplete="off"
                                                        readOnly={true}
                                                        placeholder={this.props.t('common.placeholder.any')}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>
                                                        {this.props.t('page.referralDetails.mobilePhone')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        onChange={(e) => { }}
                                                        value={this.state.mobilePhone}
                                                        type="text"
                                                        autoComplete="off"
                                                        readOnly={true}
                                                        placeholder={this.props.t('common.placeholder.any')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Form>
                </PageContent>
            </PageWrap>
        );
    }
}

export default ReferralManager;
