/**
 * https://jpoehnelt.medium.com/wms-layer-on-google-maps-1087a43d7556
 * https://josm.openstreetmap.de/wiki/Maps/Sweden
 * https://api.lantmateriet.se/open/topowebb-ccby/v1/wmts/token/3fd6e526-22dd-3787-ad48-9e8421aebe05/?request=GetCapabilities&version=1.0.0&service=wmts
 * https://stackoverflow.com/questions/13381497/draw-rectangles-in-area-on-google-maps/13465153
 * https://developers.google.com/maps/documentation/javascript/examples/overlay-simple
 */

import React from "react";
import GoogleMapReact from 'google-map-react';
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import Sidebar from "./sidebar";
import BaseComponent from "../component/base";
import rectangle from '../../assets/images/figure/rectangle.png';
import triangle from '../../assets/images/figure/triangle.png';
import trapezoid from '../../assets/images/figure/trapezoid.png';
import trapezoidRectLeft from '../../assets/images/figure/trapezoid-rectangular-left.png';
import trapezoidRectRight from '../../assets/images/figure/trapezoid-rectangular-right.png';
import gable from '../../assets/images/roof/gable_angle.jpg';
import multi from '../../assets/images/roof/multi_angle.jpg';
import { appEnv, appEnvCurrent, orientation } from "../../constants";

class USGSOverlay extends google.maps.OverlayView {

    bounds_;
    image_;
    div_;

    constructor(bounds, image) {
        super();
        this.bounds_ = bounds;
        this.image_ = image;
        this.div_ = null;
    }

    onAdd() {
        this.div_ = document.createElement("div");
        this.div_.style.borderStyle = "none";
        this.div_.style.borderWidth = "0px";
        this.div_.style.position = "absolute";
        const img = document.createElement("img");
        img.src = this.image_;
        img.style.width = "100%";
        img.style.height = "100%";
        img.style.position = "absolute";
        this.div_.appendChild(img);
        const panes = this.getPanes();
        panes.overlayLayer.appendChild(this.div_);
    }

    draw() {
        const overlayProjection = this.getProjection();
        const sw = overlayProjection.fromLatLngToDivPixel(
            this.bounds_.getSouthWest()
        );
        const ne = overlayProjection.fromLatLngToDivPixel(
            this.bounds_.getNorthEast()
        );
        if (this.div_) {
            this.div_.style.left = sw.x + "px";
            this.div_.style.top = ne.y + "px";
            this.div_.style.width = ne.x - sw.x + "px";
            this.div_.style.height = sw.y - ne.y + "px";
        }
    }

    onRemove() {
        if (this.div_) {
            this.div_.parentNode.removeChild(this.div_);
            this.div_ = null;
        }
    }
}

class Designer extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            mapTypeId: 'roadmap',
            selectedElement: null,
            selectedElementDimensions: [],
            selectedEdgeIndex: null,
            selectedEdgeType: 'hip',
            showModalAddWhat: false,
            showModalAddRoof: false,
            showModalAddShape: false,
            updateSelectedElement: false,
            newPolygonOrientation: 0,
            newPolygonShape: 'rectangle',
            newPolygonSizeA: 0,
            newPolygonSizeB: 0,
            newPolygonSizeH: 0,
            newRoofOrientation: 0,
            newRoofShape: 'gable',
            newRoofSizeA: '', // 19.35,
            newRoofSizeB: '', // 16.95,
            newRoofSizeC: '', // 2.4,
            newRoofSizeAlfa: 0, // 25,
            newRoofSizeBeta: 0,
            roofArea: 0,
            roofAreaOver38Deg: 0,
            pvArea: 0,
            passiveArea: 0,
            bipvArea: 0,
            passiveAreaExtra: 0,
            pvAmount: 0,
            offeredPvPower: 0,
            showDimensions: false,
            showRidges: false,
            drawMode: 'slope',
            showModalRoofEdge: false,
            pvWidth: this.props.pvWidth || 99.9,
            pvHeight: this.props.pvHeight || 166.2,
            pvThick: this.props.pvThick || .5,
            pvPower: this.props.pvPower || 360,
            pvDesign: [], // placeholder for serialized elements
            loadedDesign: [], // placeholder for real elements
            mapStyles: [],
            mapRotation: 0,
            mapHideControls: false,
            sidebarHideControls: false,
        }
        this.map = null;
        this.maps = null;
        this.drawingManager = null;
        this.strokeColor = {
            "slopeLabel": "#333",
            "pvPanel": "#2e2d2e",
            "slope": "#ffffff",
            "obstacle": "#2e2d2e"
        };
        this.polygonOptions = {
            slope: {
                fillColor: '#ffffff',
                fillOpacity: .5,
                strokeColor: "#ffffff",
                strokeWeight: 1.5,
                clickable: true,
                editable: false,
                draggable: true,
                zIndex: 1,
                geodesic: false,
            },
            pvPanel: {
                fillColor: '#2e2d2e',
                fillOpacity: .88,
                strokeColor: "#2e2d2e",
                strokeWeight: 1,
                clickable: true,
                editable: false,
                draggable: true,
                zIndex: 2,
                geodesic: false,
            },
            obstacle: {
                fillColor: '#ff3333',
                fillOpacity: .8,
                strokeColor: "#ff3333",
                strokeWeight: 1,
                clickable: true,
                editable: false,
                draggable: true,
                zIndex: 2,
                geodesic: false,
            }
        };
        this.afterDrag = [];
        this.duringDrag = false;
        this.fromPointDrag = null;
        this.controlDiv = null;
        this.controlAddSlope = null;
        this.controlAddSlopeDiv = null;
        this.controlAddSlopeText = null;
        this.controlToggleRuler = null;
        this.controlToggleRulerDiv = null;
        this.controlToggleRulerText = null;
        this.controlToggleShowRidges = null;
        this.controlToggleShowRidgesDiv = null;
        this.controlToggleShowRidgesText = null;
        this.controlChangeMode = null;
        this.controlChangeModeDiv = null;
        this.controlChangeModeText = null;
        this.controlRotateLeftDiv = null;
        this.controlRotateLeftButton = null;
        this.controlRotateRightDiv = null;
        this.controlRotateRightButton = null;
        this.imageOverlay = null;
        this.locationMarker = null;
        this.locationMarkerInfo = null;
        this.shapes = [
            { ident: 'triangle', useSize: ['a', 'h'], src: triangle },
            { ident: 'rectangle', useSize: ['a', 'h'], src: rectangle },
            { ident: 'trapezoid', useSize: ['a', 'b', 'h'], src: trapezoid },
            { ident: 'trapezoidRectLeft', useSize: ['a', 'b', 'h'], src: trapezoidRectLeft },
            { ident: 'trapezoidRectRight', useSize: ['a', 'b', 'h'], src: trapezoidRectRight }
        ];
        this.roofs = [
            { ident: 'gable', label: 'Gable', useSize: ['A', 'B', 'alfa'], src: gable },
            { ident: 'multi', label: 'Hipped', useSize: ['A', 'B', 'C', 'alfa'], src: multi },
            // { ident: 'lshapedhipped', label: 'L-shaped hipped', useSize: ['A', 'B', 'C', 'D', 'E', 'F', 'alfa'], src: multi },
            { ident: 'ltshapedgable', label: 'L/T-shaped gable', useSize: ['A', 'B', 'C', 'D', 'E', 'alfa', 'beta'], src: multi },
        ];
        this.elements = [];

        this.handleElementsUpdate = _.debounce(this.handleElementsUpdate.bind(this), 100);
        this.setInputData = _.debounce(this.setInputData.bind(this), 100);

        this.mapCenter = this.mapCenter.bind(this);
        this.mapShortcuts = this.mapShortcuts.bind(this);
        this.dropFocus = this.dropFocus.bind(this);
        this.handleGoogleMapApi = this.handleGoogleMapApi.bind(this);

        this.boundsFitted = false;
        this.defaultPanelsOrientation = 'horizontal';
        this.componentIdent = 'bp-component-designer';

        if (this.props.isRegion(['GERMANY'])) {
            this.defaultPanelsOrientation = 'vertical';
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.mapShortcuts, false);
        window.addEventListener("MAP_CENTER", this.mapCenter, false);
        window.addEventListener("DROP_FOCUS", this.dropFocus, false);

        fetch("/assets/data/map-styles.json")
            .then((res) => res.json())
            .then((data) => this.setState({ mapStyles: data }));
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.mapShortcuts, false);
        window.removeEventListener('MAP_CENTER', this.mapCenter, false);
        window.removeEventListener("DROP_FOCUS", this.dropFocus, false);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.map
            && nextProps.pvDesign.length
            && nextProps.pvDesign.length !== this.state.loadedDesign.length
        ) {
            nextState.loadedDesign = nextProps.pvDesign;
            this.parseElements(nextProps.pvDesign, false);
            setTimeout(() => {
                this.mapFitBounds();
            }, 3000);
        }
        if (this.map && !_.isEqual(nextProps.markers, this.props.markers)) {
            this.renderLocationMarkers(this.map, this.maps, nextProps.markers);
        }
        return true;
    }

    polygonContainsPoint(latLng, polygon) {
        /*
        let latLngObj = { latitude: latLng.lat(), longitude: latLng.lng() };
        let polygonLatLngArr = [];
        polygon.getPath().getArray().forEach((polyLatLng) => {
            polygonLatLngArr.push([{ latitude: polyLatLng.lat(), longitude: polyLatLng.lng() }]);
        });
        */
        return this.maps.geometry.poly.containsLocation(latLng, polygon)
    }

    dropFocus() {
        this.debug('drop focus');
        if (this.state.loadedDesign.length
            && this.state.selectedElement) {
            this.selectElement(null);
        }
    }

    mapShortcuts(e) {
        if (['INPUT', 'TEXTAREA'].includes(document.activeElement?.tagName?.toUpperCase())) {
            return;
        }

        // do nothing
        if (this.props.isDisabled || !e || !e.code) {
            return;
        }

        // delete selected element
        if (e.code.toUpperCase() === 'DELETE' || e.code.toUpperCase() === 'BACKSPACE') {
            if (!this.state.selectedElement) {
                return;
            } else {
                e.preventDefault();
            }
            this.removeElements(this.state.selectedElement.uuid, this.state.selectedElement.uuid, null, "||", true);
        }

        // copy or cut selected element
        if (e.ctrlKey && (e.key.toUpperCase() === 'C' || e.key.toUpperCase() === 'X')) {
            if (!this.state.selectedElement) {
                return;
            } else {
                e.preventDefault();
            }

            let oldNewUuidMapping = {};
            let elementsToBeCopied = this.elements.filter(element => {
                let isProperType = ['pvPanel', 'slope', 'obstacle'].includes(element.elementCategory);
                let isProperUuid = element.uuid === this.state.selectedElement.uuid || element.parent === this.state.selectedElement.uuid;
                oldNewUuidMapping[element.uuid] = this.props.utils.getUUID();
                return isProperType && isProperUuid;
            });

            let serializedArray = [];
            elementsToBeCopied = elementsToBeCopied.map(element => {
                let serializedSelectedElement = { ...this.serializeElement(element) };
                serializedSelectedElement.uuid = oldNewUuidMapping[serializedSelectedElement.uuid];
                if (element.parent === this.state.selectedElement.uuid) {
                    serializedSelectedElement.parent = oldNewUuidMapping[serializedSelectedElement.parent];
                }
                serializedArray.push(serializedSelectedElement);
                return element;
            });

            const serialized = JSON.stringify({
                ___ident: this.componentIdent,
                ___values: {
                    elements: serializedArray,
                    map: {
                        center: this.map.getCenter()
                    }
                }
            });

            navigator.clipboard.writeText(serialized).then(
                () => {
                    if (e.key.toUpperCase() === 'X') {
                        this.removeElements(this.state.selectedElement.uuid, this.state.selectedElement.uuid, null, "||", true);
                    }
                    this.selectElement(null);
                },
                (err) => {
                    this.props.showToast({
                        errorObject: {
                            ident: 'clipboard',
                            data: {
                                error: {
                                    ident: err.message,
                                }
                            }
                        },
                        title: this.props.t('common.toast.error'),
                        color: 'danger'
                    });
                }
            );
        }

        // paste copied element
        if (e.ctrlKey && e.key.toUpperCase() === 'V') {
            e.preventDefault();
            navigator.clipboard.readText().then(
                (txt) => {
                    let clipboarded = '';
                    try {
                        clipboarded = JSON.parse(txt) || {};
                    } catch (err) {
                        //...
                    }
                    if (clipboarded
                        && clipboarded.hasOwnProperty('___ident')
                        && clipboarded.hasOwnProperty('___values')
                        && clipboarded.___ident === this.componentIdent) {

                        // find out if elements need parent
                        let rawElements = [...clipboarded.___values.elements];
                        let rawElementsUuids = [];
                        let theyNeedParent = true;
                        rawElements = rawElements.map(rawElement => {
                            rawElementsUuids.push(rawElement.uuid);
                            if (rawElement.elementCategory === 'slope') {
                                theyNeedParent = false;
                            }
                            return { ...rawElement, importuuid: rawElement.uuid };
                        })

                        // if you are pasting pvPanels withous slope, 
                        // they might need a parent
                        if (
                            this.state.selectedElement
                            && this.state.selectedElement.elementCategory === 'slope'
                            && theyNeedParent
                        ) {
                            rawElements = rawElements.map(rawElement => {
                                return { ...rawElement, parent: this.state.selectedElement.uuid }
                            });
                        }

                        // load elements
                        this.parseElements(rawElements, true);

                        // calculate offset
                        let thisMapCenter = this.map.getCenter();
                        let pastedMapCenter = clipboarded.___values.map.center;
                        let mapsHeading = this.maps.geometry.spherical.computeHeading(pastedMapCenter, thisMapCenter);
                        let mapsDistance = this.maps.geometry.spherical.computeDistanceBetween(pastedMapCenter, thisMapCenter);

                        // move pasted elements
                        this.elements = this.elements.map(element => {
                            if (element.importuuid && rawElementsUuids.includes(element.importuuid)) {
                                return this.movePolygon(element, mapsDistance, mapsHeading, this.maps);
                            }
                            return element;
                        });
                    }
                },
                (err) => {
                    this.props.showToast({
                        errorObject: {
                            ident: 'clipboard',
                            data: {
                                error: {
                                    ident: err.message,
                                }
                            }
                        },
                        title: this.props.t('common.toast.error'),
                        color: 'danger'
                    });
                }
            );
        }
    }

    mapCenter(event) {
        if (!this.map) {
            return;
        }
        if (event.detail) {
            this.map.setCenter(event.detail);
        } else {
            this.mapFitBounds(true);
        }
    }

    mapFitBounds(force) {
        if (this.boundsFitted && !force) {
            return;
        }
        this.boundsFitted = true;
        setTimeout(() => {
            let bounds = this.getBoundsOfElement();
            if (bounds.hasBounds) {
                this.map.fitBounds(bounds.bounds);
            }
        }, 1000);
    }

    handleElementsUpdate(callback) {
        // this is debounced!
        this.setInputData(callback)
    }

    setElements(elements, callback) {
        if (typeof callback !== "function") {
            callback = () => { }
        }
        let registered = [];
        this.elements = elements.map(element => {
            if (registered.includes(element.uud)) {
                element.setMap(null);
                return null;
            }
            registered.push(element.uuid);
            return element;
        }).filter(Boolean);
        this.handleElementsUpdate(() => {
            this.forceUpdate();
            callback();
        });
    }

    handleElementAdd(elementOrElements, callback) {
        if (typeof callback !== "function") {
            callback = () => { }
        }

        let newElements = [];
        if (Array.isArray(elementOrElements)) {
            newElements = [...this.elements, ...elementOrElements];
        } else {
            newElements = [...this.elements, elementOrElements];
        }

        this.setElements(newElements, callback);
    };

    handleElementChange(newElement, callback) {
        if (typeof callback !== "function") {
            callback = () => { }
        }

        let newElements = this.elements.map((element) => {
            if (element.uuid !== newElement.uuid) {
                return element;
            }
            return newElement;
        });
        this.setElements(newElements, () => {
            this.updatePolygonProps(newElement, this.map, this.maps, () => {
                callback();
            })
        });
    }

    selectElement(element, callback) {
        if (typeof callback !== "function") {
            callback = () => { }
        }
        for (const [property, value] of Object.entries(this.strokeColor)) {
            this.updateElements({ 'strokeColor': value }, property);
        }
        if (element) {
            element.set('strokeColor', '#007bff');
        }
        this.setState({ selectedElement: element }, () => callback());
    }

    updateElement(element, properties) {
        let options = {};
        for (const [property, value] of Object.entries(properties)) {
            if (element.hasOwnProperty(property)) {
                options[property] = value;
            }
        };
        element.setOptions(options);
        return element;
    }

    updateSelectedElement(properties, callback) {
        if (typeof callback !== "function") {
            callback = () => { }
        }
        let selectedElement = this.state.selectedElement;
        if (!selectedElement) {
            return;
        }
        selectedElement = this.updateElement(selectedElement, properties);
        this.setState({
            selectedElement: selectedElement
        }, () => {
            this.handleElementChange(selectedElement, callback);
        })
    }

    updateSelectedElementPath(newPath) {
        let selectedElement = this.state.selectedElement;
        if (!selectedElement) {
            return;
        }
        selectedElement.setPath(newPath);
        this.handleElementChange(selectedElement);
    }

    updateElements(properties, elementCategory, callback) {
        let newElements = this.elements.map((element) => {
            if (
                (
                    Array.isArray(elementCategory) && elementCategory.includes(element.elementCategory)
                ) || element.elementCategory === elementCategory
            ) {
                return this.updateElement(element, properties);
            }
            return element;
        });
        this.setElements(newElements, callback);
    }

    removeElements(itemUuid, parentItemUuid, elementCategory, operator, andRemoveSelected, callback) {

        if (typeof callback !== "function") {
            callback = () => { }
        }

        const operators = {
            '&&': (...args) => { return args.reduce((acc, cur) => { return acc && cur }) },
            '||': (...args) => { return args.reduce((acc, cur) => { return acc || cur }) },
        };

        const op = operator || "&&";

        let newElements = [...this.elements];
        newElements = newElements.map(element => {
            if (itemUuid && parentItemUuid && elementCategory) {
                if (operators[op](itemUuid === element.uuid, parentItemUuid === element.parent, elementCategory === element.elementCategory)) {
                    element.setMap(null);
                    element = null;
                    return null;
                }
            } else if (itemUuid && parentItemUuid) {
                if (operators[op](itemUuid === element.uuid, parentItemUuid === element.parent)) {
                    element.setMap(null);
                    element = null;
                    return null;
                }
            } else if (itemUuid && elementCategory) {
                if (operators[op](itemUuid === element.uuid, parentItemUuid === element.parent)) {
                    element.setMap(null);
                    element = null;
                    return null;
                }
            } else if (parentItemUuid && elementCategory) {
                if (operators[op](parentItemUuid === element.parent, elementCategory === element.elementCategory)) {
                    element.setMap(null);
                    element = null;
                    return null;
                }
            } else if (itemUuid) {
                if (operators[op](itemUuid === element.uuid)) {
                    element.setMap(null);
                    element = null;
                    return null;
                }
            } else if (parentItemUuid) {
                if (operators[op](parentItemUuid === element.parent)) {
                    element.setMap(null);
                    element = null;
                    return null;
                }
            } else if (elementCategory) {
                if (operators[op](elementCategory === element.elementCategory)) {
                    element.setMap(null);
                    element = null;
                    return null;
                }
            }

            return element;
        }).filter(element => element !== null);

        this.setElements(newElements);

        if (andRemoveSelected && this.state.selectedElement) {
            this.state.selectedElement.setMap(null);
            this.state.selectedElement = null;
            this.setState({ selectedElement: null }, () => {
                callback();
            });
        } else {
            callback();
        }
    }

    xyzToBounds(x, y, z) {
        let EXTENT = [-Math.PI * 6378137, Math.PI * 6378137];
        let tileSize = EXTENT[1] * 2 / Math.pow(2, z);
        let minx = EXTENT[0] + x * tileSize;
        let maxx = EXTENT[0] + (x + 1) * tileSize;
        // remember: y origin starts at top
        let miny = EXTENT[1] - (y + 1) * tileSize;
        let maxy = EXTENT[1] - y * tileSize;
        return [minx, miny, maxx, maxy];
    }

    renderLocationMarkers(map, maps, markers) {
        if (!markers || !markers.length || !maps || !map) {
            return;
        }
        if (this.props.enableDesigner) {
            return;
        }
        markers.forEach((markerData) => {
            if (this.locationMarker) {
                try {
                    this.locationMarker.setMap(null);
                } catch (e) {
                    this.locationMarker = null;
                }
            }
            this.locationMarkerInfo = new google.maps.InfoWindow({
                content: `<p style="padding: 10px 5px">${markerData.title}<br />${markerData.lat}, ${markerData.lng}</p>`,
            });
            this.locationMarker = new maps.Marker({
                position: { lat: markerData.lat, lng: markerData.lng },
                map,
                title: markerData.title,
                icon: publicPaths.markers + 'marker_orange.png',
            });
            this.locationMarker.addListener("click", () => {
                this.locationMarkerInfo.open({
                    anchor: this.locationMarker,
                    map,
                    shouldFocus: false,
                });
            });
        });
    }

    getSizeInCm(sizeInPX, pixelRatio) {
        if (!pixelRatio) {
            pixelRatio = window.devicePixelRatio;
        }
        return sizeInPX * 2.54 / (96 * pixelRatio)
    };

    addImageOverlay(map, bounds, srcImage) {

        let widthInCm = this.getSizeInCm(3508);
        let heightInCm = this.getSizeInCm(2479);
        let scale = 1.50;

        bounds = this.calcBoundsOfRectangleByGivenSize(
            this.map.getCenter(),
            new google.maps.Size(widthInCm / scale, heightInCm / scale)
        )

        srcImage = ''; //'http://c.local/rzut.jpg';

        if (this.imageOverlay) {
            this.imageOverlay.setMap(null);
            this.imageOverlay = null;
        }
        this.imageOverlay = new USGSOverlay(bounds, srcImage);
        this.imageOverlay.setMap(map);
    }

    addLayers(map, maps) {
        if (!this.props.layers || !this.props.layers.length) {
            return;
        }
        this.props.layers.forEach((layerIdent) => {
            let layer = null;
            if (layerIdent === "lantmateriet") {
                layer = new maps.ImageMapType({
                    getTileUrl: (coordinates, zoom) => {
                        let width = 256;
                        let height = 256;
                        let srs = "EPSG:3857";
                        let bbox = this.xyzToBounds(coordinates.x, coordinates.y, zoom).join(",");
                        return `https://minkarta.lantmateriet.se/map/ortofoto/?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&LAYERS=Ortofoto_0.5,Ortofoto_0.4,Ortofoto_0.25,Ortofoto_0.16&WIDTH=${width}&HEIGHT=${height}&SRS=${srs}&BBOX=${bbox}`
                    },
                    tileSize: new maps.Size(256, 256),
                    name: layerIdent,
                    maxZoom: 25,
                    alt: layerIdent,
                    opacity: 1.0,
                    isPng: true,
                })
            }
            if (layerIdent === "sketch") {
                layer = new maps.ImageMapType({
                    getTileUrl: (coordinates, zoom) => {
                        return `/assets/tiles/gray.jpg`;
                    },
                    tileSize: new maps.Size(2048, 2048),
                    name: layerIdent,
                    maxZoom: 25,
                    alt: layerIdent
                })
            }
            if (layer) {
                map.mapTypes.set(layerIdent, layer);
                this.setMapTypeId(undefined, layerIdent)
            }
        });
    }

    addDrawingManager(map, maps) {
        this.drawingManager = new maps.drawing.DrawingManager({
            drawingMode: null,
            drawingControl: !this.props.isDisabled,
            drawingControlOptions: {
                position: maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                    // maps.drawing.OverlayType.POLYGON,
                    // maps.drawing.OverlayType.RECTANGLE
                ]
            },
            polygonOptions: this.getCurrentPolygonTypeOptions(),
            rectangleOptions: this.getCurrentPolygonTypeOptions()
        });

        this.drawingManager.setMap(map);

        if (this.props.isDisabled) {
            return;
        }

        // Create custom button - add slope. 
        this.controlAddSlopeDiv = document.createElement("div");
        this.controlAddSlope = document.createElement("button");
        this.controlAddSlope.type = "button";

        // Set CSS for the control border.
        this.controlAddSlope.id = "drawing-manager-custom-button-0";
        this.controlAddSlope.title = "Add an element";
        this.controlAddSlopeDiv.appendChild(this.controlAddSlope);

        // Set CSS for the control interior.
        this.controlAddSlopeText = document.createElement("div");
        this.controlAddSlopeText.innerHTML = "+";
        this.controlAddSlope.appendChild(this.controlAddSlopeText);

        // Append to map
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.controlAddSlopeDiv);

        // Create custom button - toggle ruler.
        this.controlToggleRulerDiv = document.createElement("div");
        this.controlToggleRuler = document.createElement("button");
        this.controlToggleRuler.type = "button";

        // Set CSS for the control border.
        this.controlToggleRuler.id = "drawing-manager-custom-button-1";
        this.controlToggleRuler.title = "Toggle ruler";
        this.controlToggleRulerDiv.appendChild(this.controlToggleRuler);

        // Set CSS for the control interior.
        this.controlToggleRulerText = document.createElement("div");
        this.controlToggleRulerText.innerHTML = "📐";
        this.controlToggleRuler.appendChild(this.controlToggleRulerText);

        // Append to map
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.controlToggleRulerDiv);

        // Create custom button - toggle ruler.
        this.controlToggleRidgesDiv = document.createElement("div");
        this.controlToggleRidges = document.createElement("button");
        this.controlToggleRidges.type = "button";

        // Set CSS for the control border.
        this.controlToggleRidges.id = "drawing-manager-custom-button-2";
        this.controlToggleRidges.title = "Toggle ridges";
        this.controlToggleRidgesDiv.appendChild(this.controlToggleRidges);

        // Set CSS for the control interior.
        this.controlToggleRidgesText = document.createElement("div");
        this.controlToggleRidgesText.innerHTML = "🏠";
        this.controlToggleRidges.appendChild(this.controlToggleRidgesText);

        // Append to map
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.controlToggleRidgesDiv);

        /*
        // Create custom button - change mode.
        this.controlChangeModeDiv = document.createElement("div");
        this.controlChangeMode = document.createElement("button");
        this.controlChangeMode.type = "button";

        // Set CSS for the control border.
        this.controlChangeMode.id = "drawing-manager-custom-button-3";
        this.controlChangeMode.title = "Change mode";
        this.controlChangeModeDiv.appendChild(this.controlChangeMode);

        // Set CSS for the control interior.
        this.controlChangeModeText = document.createElement("div");
        this.controlChangeModeText.innerHTML = this.state.drawMode;
        this.controlChangeMode.appendChild(this.controlChangeModeText);

        // Append to map
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.controlChangeModeDiv);
        */

        /*
        // Rotate left
        this.controlRotateLeftDiv = document.createElement("div");
        this.controlRotateLeftButton = document.createElement("button");
        this.controlRotateLeftButton.classList.add("designer-ui-button");
        this.controlRotateLeftButton.innerText = `lefty`;
        this.controlRotateLeftDiv.appendChild(this.controlRotateLeftButton);

        // Append to map
        map.controls[google.maps.ControlPosition.LEFT_CENTER].push(this.controlRotateLeftDiv);
        */

        /*
        // Rotate left
        this.controlRotateRightDiv = document.createElement("div");
        this.controlRotateRightButton = document.createElement("button");
        this.controlRotateRightButton.classList.add("designer-ui-button");
        this.controlRotateRightButton.innerText = `right`;
        this.controlRotateRightDiv.appendChild(this.controlRotateRightButton);

        // Append to map
        map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(this.controlRotateRightDiv);
        */

        // Bind events
        this.bindDrawingManagerEvents(this.drawingManager, map, maps);

    }

    adjustMap(mode, amount) {
        switch (mode) {
            case "rotate":
                this.setState({ mapRotation: amount, mapHideControls: amount !== 0 }, () => {
                    this.toggleControls();
                    this.toggleDrag();
                    this.map.getDiv().style.transform = 'rotate(' + amount + 'deg)';
                })
                break;
            default:
                break;
        }
    };

    toggleDrag() {
        const draggable = !this.state.mapHideControls;
        this.map.setOptions({ draggable: draggable, zoomControl: draggable, scrollwheel: draggable, disableDoubleClickZoom: !draggable });
        this.updateElements({ draggable: draggable }, ['pvPanel', 'slope', 'obstacle']);
    }

    toggleControls() {
        if (this.state.mapHideControls) {
            this.map.zoomControl = false;
            this.controlAddSlopeDiv.parentElement.style.display = 'none';
        } else {
            this.map.zoomControl = true;
            this.controlAddSlopeDiv.parentElement.style.display = 'block';
        }
    }

    moveLatByMeters(latLng, meters, maps) {
        let earth = 6378.137;  //radius of the earth in kilometers
        let pi = Math.PI;
        let m = (1 / ((2 * pi / 360) * earth)) / 1000;  //1 meter in degree
        return new maps.LatLng(latLng.lat() + (meters * m), latLng.lng());
    }

    moveLngByMeters(latLng, meters, maps) {
        let earth = 6378.137;  //radius of the earth in kilometers
        let pi = Math.PI;
        let m = (1 / ((2 * pi / 360) * earth)) / 1000;  //1 meter in degree
        return new maps.LatLng(latLng.lat(), latLng.lng() + (meters * m) / Math.cos(latLng.lat() * (pi / 180)));
    }

    reDrawPolygonSlopeDimensions(map, maps, callback) {
        if (typeof callback !== "function") {
            callback = () => { }
        }

        if (!this.state.showDimensions) {
            this.removeElements(null, null, 'sideLabel', '&&', false, () => { callback() });
            return;
        }

        let newElements = [];
        this.elements.filter(itm => itm.elementCategory === 'slope').forEach(polygon => {

            let polygonCenter = this.getCenterOfPolygon(polygon);
            for (let i = 0; i < polygon.getPath().getLength(); i++) {
                // for each side in path, compute center and length
                let bound = new maps.LatLngBounds();
                let start = polygon.getPath().getAt(i);
                let end = polygon.getPath().getAt(i < polygon.getPath().getLength() - 1 ? i + 1 : 0);
                let sideLength = maps.geometry.spherical.computeDistanceBetween(start, end);
                bound.extend(start);
                bound.extend(end);
                let sideCenter = bound.getCenter();
                let sideHeading = maps.geometry.spherical.computeHeading(sideCenter, polygonCenter);
                // sideCenter = maps.geometry.spherical.computeOffset(sideCenter, 1, sideHeading);

                let sideLabel = new maps.Marker({
                    map: map,
                    fontSize: 14,
                    align: "center",
                    position: sideCenter,
                    zIndex: 4,
                    label: {
                        text: `L${i} = ` + sideLength.toFixed(2) + "m",
                        color: "#fff"
                    },
                    icon: {
                        path: "M -10 -10 H 45 V 10 H -45 L -45 -10",
                        fillColor: '#333',
                        fillOpacity: .95,
                        strokeWeight: 0,
                        scale: 1
                    }
                });

                sideLabel.uuid = this.props.utils.getUUID();
                sideLabel.parent = polygon.uuid;
                sideLabel.elementCategory = 'sideLabel';
                newElements.push(sideLabel);
            }

        });

        this.removeElements(null, null, 'sideLabel', '&&', false, () => {
            this.handleElementAdd(newElements, () => { callback() });
        });
    }

    estimatePolygonRotation(polygon, map, maps, callback) {
        if (typeof callback !== "function") {
            callback = () => { }
        }

        if (typeof polygon.defaultHeading !== "undefined") {
            return { estimatedHeading: polygon.defaultHeading };
        }

        if (typeof polygon.elementCategory !== "slope") {
            return { estimatedHeading: polygon.defaultHeading };
        }

        if (!polygon.edges) {
            return { estimatedHeading: polygon.defaultHeading };
        }

        let longestLength = 0;
        let longestHeading = 0;

        let ridgesFound = false;
        let eavesFound = false;

        let ridges = new maps.LatLngBounds();
        let eaves = new maps.LatLngBounds();

        for (let i = 0; i < polygon.getPath().getLength(); i++) {

            // for each side in path, compute center and length
            let start = polygon.getPath().getAt(i);
            let end = polygon.getPath().getAt(i < polygon.getPath().getLength() - 1 ? i + 1 : 0);
            let edgeParameters = polygon.edges.find(edge => edge.index === i);

            if (edgeParameters && edgeParameters.type === 'ridge') {
                ridgesFound = true;
                ridges.extend(start);
                ridges.extend(end);
            }

            if (edgeParameters && edgeParameters.type === 'eaves') {
                eavesFound = true;
                eaves.extend(start);
                eaves.extend(end);
            }

            let sideLength = maps.geometry.spherical.computeDistanceBetween(start, end);
            let sideHeading = maps.geometry.spherical.computeHeading(start, end);
            if (sideLength > longestLength) {
                longestLength = sideLength;
                longestHeading = sideHeading;
            }
        }

        if (ridgesFound && eavesFound) {
            let ridgesCenter = ridges.getCenter();
            let eavesCenter = eaves.getCenter();
            let ridgesEavesHeading = this.props.utils.normalizeDegrees(maps.geometry.spherical.computeHeading(eavesCenter, ridgesCenter) + 90);
            return { estimatedHeading: ridgesEavesHeading, longestHeading: longestHeading };
        }

        return { estimatedHeading: longestHeading };
    }

    reDrawPolygonSlopeParametersLabel(polygon, map, maps, callback) {
        if (typeof callback !== "function") {
            callback = () => { }
        }

        callback();

        /*
        let slopeLabel = new maps.Marker({
            map: map,
            fontSize: 20,
            align: "center",
            position: this.getSideOfPolygon(polygon),
            zIndex: 5,
            label: {
                text: `${polygon.slopeDeg}° (${polygon.slopePercent}%)`,
                color: this.strokeColor['slopeLabel']
            },
            icon: {
                path: 'M20 0 40 0 l-0 0 l-10 -5 m10 5 l-10 5',
                rotation: polygon.rotation,
                strokeColor: this.strokeColor['slopeLabel'],
                strokeOpacity: 1.0,
                strokeWeight: 2,
                scale: 1
            }
        });

        slopeLabel.uuid = this.props.utils.getUUID();
        slopeLabel.parent = polygon.uuid;
        slopeLabel.elementCategory = 'slopeLabel';

        this.removeElements(null, polygon.uuid, 'slopeLabel', '&&', false, () => {
            this.handleElementAdd(slopeLabel, () => { callback() });
        });
        */
    }

    slopeEdgeClick(polygon, edgeType, edgeIndex) {
        this.selectElement(polygon, () => {
            this.setState({
                // showModalRoofEdge: true,
                selectedEdgeType: edgeType,
                selectedEdgeIndex: edgeIndex
            })
        });
    }

    setSlopeEdge(e) {
        if (e) {
            e.preventDefault();
        }
        let selectedElement = this.state.selectedElement;
        let selectedEdgeIndex = this.state.selectedEdgeIndex;
        let selectedEdgeType = this.state.selectedEdgeType;

        selectedElement.edges = selectedElement.edges.map(edge => {
            let newEdge = { ...edge };
            if (newEdge.index === selectedEdgeIndex) {
                newEdge.type = selectedEdgeType;
            }
            return newEdge;
        });

        let estimatedRotation = this.estimatePolygonRotation(selectedElement, this.map, this.maps);
        selectedElement.rotation = estimatedRotation.estimatedHeading;

        this.setState({
            showModalRoofEdge: false,
            selectedEdgeIndex: null
        }, () => {
            this.handleElementChange(selectedElement, () => {
                this.reDrawPolygonSlopeRidges(this.map, this.maps);
            });
        })
    }

    reDrawPolygonSlopeRidges(map, maps, callback) {

        if (typeof callback !== "function") {
            callback = () => { }
        }

        if (!this.state.showRidges) {
            this.removeElements(null, null, 'slopeEdge', '&&', false, () => { callback() });
            return;
        }

        let newElements = [];
        this.elements.filter(itm => itm.elementCategory === 'slope').forEach(polygon => {

            for (let i = 0; i < polygon.getPath().getLength(); i++) {
                // get edge parameters
                let edgeParameters = polygon.edges.find(edge => edge.index === i);
                // for each side in path, compute center and length
                let bound = new maps.LatLngBounds();
                let start = polygon.getPath().getAt(i);
                let end = polygon.getPath().getAt(i < polygon.getPath().getLength() - 1 ? i + 1 : 0);
                bound.extend(start);
                bound.extend(end);
                let sideCenter = bound.getCenter();
                // sideCenter = maps.geometry.spherical.computeOffset(sideCenter, -0.5, 0);
                let sideHeading = this.props.utils.normalizeDegrees(maps.geometry.spherical.computeHeading(start, end) + 90);

                let slopeEdge = new maps.Marker({
                    map: map,
                    fontSize: 11,
                    align: "center",
                    position: sideCenter,
                    zIndex: 4,
                    label: {
                        text: edgeParameters.type,
                        rotation: sideHeading,
                        color: '#fff'
                    },
                    icon: {
                        path: "M -20 -20 H 20 V 20 H -20 L -20 -20",
                        rotation: sideHeading,
                        fillColor: '#333',
                        fillOpacity: .95,
                        strokeWeight: 0,
                        scale: 1
                    }
                });

                slopeEdge.uuid = this.props.utils.getUUID();
                slopeEdge.parent = polygon.uuid;
                slopeEdge.elementCategory = 'slopeEdge';
                slopeEdge.addListener("click", () => {
                    this.slopeEdgeClick(polygon, edgeParameters.type, edgeParameters.index);
                });
                newElements.push(slopeEdge);
            }

        });

        this.removeElements(null, null, 'slopeEdge', '&&', false, () => {
            this.handleElementAdd(newElements, () => { callback() });
        });
    }

    reCalcPolygonDimensions(polygon, map, maps, callback) {
        if (typeof callback !== "function") {
            callback = () => { }
        }
        let lengths = [];
        if (polygon) {
            for (let i = 0; i < polygon.getPath().getLength(); i++) {
                let start = polygon.getPath().getAt(i);
                let end = polygon.getPath().getAt(i < polygon.getPath().getLength() - 1 ? i + 1 : 0);
                let distance = maps.geometry.spherical.computeDistanceBetween(start, end);
                lengths.push({
                    index: i,
                    length: this.round(distance, 2)
                });
            }
        }
        this.setState({ selectedElementDimensions: lengths }, callback);
    }

    getMetersPerPixel(map) {
        return 156543.03392 * Math.cos(map.getCenter().lat() * Math.PI / 180) / Math.pow(2, map.getZoom())
    }

    getMetersInPixels(meters, mppValue) {
        return meters / mppValue;
    }

    getCenterOfPolygon(poly) {
        var bounds = new google.maps.LatLngBounds()
        poly.getPath().forEach(function (element, index) { bounds.extend(element) })
        return bounds.getCenter();
    }

    getSideOfPolygon(poly) {
        var bounds = new google.maps.LatLngBounds()
        poly.getPath().forEach(function (element, index) { bounds.extend(element) })
        return google.maps.geometry.spherical.computeOffset(bounds.getNorthEast(), 2.5, 45);
    }

    getBoundsParameters(bounds) {
        let NE = bounds.getNorthEast();
        let SW = bounds.getSouthWest();

        let lat1 = NE.lat();
        let lat2 = SW.lat();
        let lng1 = NE.lng();
        let lng2 = SW.lng();

        let horizontalLatLng1 = new google.maps.LatLng(lat1, lng1);
        let horizontalLatLng2 = new google.maps.LatLng(lat1, lng2);

        let verticalLatLng1 = new google.maps.LatLng(lat2, lng2);
        let verticalLatLng2 = new google.maps.LatLng(lat2, lng1);

        // this.createPolygonFromCoords([horizontalLatLng1, horizontalLatLng2, verticalLatLng1, verticalLatLng2], {}, this.map, this.maps);

        let width = google.maps.geometry.spherical.computeDistanceBetween(horizontalLatLng1, horizontalLatLng2);
        let height = google.maps.geometry.spherical.computeDistanceBetween(verticalLatLng1, verticalLatLng2);

        let center = bounds.getCenter()

        return {
            NE,
            SW,
            lat1,
            lat2,
            lng1,
            lng2,
            horizontalLatLng1,
            horizontalLatLng2,
            verticalLatLng1,
            verticalLatLng2,
            width,
            height,
            center
        }
    }

    getBoundsOfElement(polygon) {
        var bounds = new google.maps.LatLngBounds()
        var hasBounds = false;

        if (!polygon) {
            if (this.elements.length) {
                this.elements.forEach(poly => {
                    if (poly.elementCategory === "slope") {
                        poly.getPath().forEach(function (element, index) { bounds.extend(element) })
                        hasBounds = true;
                    }
                })
            }
        } else {
            polygon.getPath().forEach(function (element, index) { bounds.extend(element) })
            hasBounds = true;
        }

        if (!hasBounds) {
            return {
                hasBounds: hasBounds
            }
        }

        const parameters = this.getBoundsParameters(bounds);

        return {
            width: parameters.width,
            height: parameters.height,
            bounds: bounds,
            hasBounds: hasBounds
        }
    }

    rotatePolygon(polygon, origin, angle, map) {
        let prj = map.getProjection();
        let bounds = new google.maps.LatLngBounds();
        // Get paths from polygon and set event listeners for each path separately
        polygon.getPath().forEach(function (path, index) {
            bounds.extend(path);
        });
        if (!origin) {
            origin = prj.fromLatLngToPoint(bounds.getCenter());
            // origin = prj.fromLatLngToPoint(polygon.getPath().getAt(0)); //rotate around first point
        }
        let coords = polygon.getPath().getArray().map((latLng) => {
            let point = prj.fromLatLngToPoint(latLng);
            let rotatedLatLng = prj.fromPointToLatLng(this.rotatePoint(point, origin, angle));
            return { lat: rotatedLatLng.lat(), lng: rotatedLatLng.lng() };
        });
        polygon.setPath(coords);
        return polygon;
    }

    movePolygon(polygon, distance, heading, maps) {
        let coords = polygon.getPath().getArray().map((latLng) => {
            let newPoint = maps.geometry.spherical.computeOffset(latLng, distance, heading);
            return { lat: newPoint.lat(), lng: newPoint.lng() };
        });
        polygon.setPath(coords);
        return polygon;
    }

    rotatePoint(point, origin, angle) {
        let angleRad = angle * Math.PI / 180.0;
        return {
            x: Math.cos(angleRad) * (point.x - origin.x) - Math.sin(angleRad) * (point.y - origin.y) + origin.x,
            y: Math.sin(angleRad) * (point.x - origin.x) + Math.cos(angleRad) * (point.y - origin.y) + origin.y
        };
    }

    createPolygonFromRectangle(rectangle, map, maps) {

        let coords = [
            { lat: rectangle.getBounds().getNorthEast().lat(), lng: rectangle.getBounds().getNorthEast().lng() },
            { lat: rectangle.getBounds().getNorthEast().lat(), lng: rectangle.getBounds().getSouthWest().lng() },
            { lat: rectangle.getBounds().getSouthWest().lat(), lng: rectangle.getBounds().getSouthWest().lng() },
            { lat: rectangle.getBounds().getSouthWest().lat(), lng: rectangle.getBounds().getNorthEast().lng() }
        ];

        let properties = [
            "uuid",
            "importuuid",
            "originalType",
            "strokeColor",
            "strokeOpacity",
            "strokeWeight",
            "fillColor",
            "fillOpacity",
            "clickable",
            "editable",
            "draggable",
            "zIndex",
            "primitiveShape",
            "fakeSlopeArea",
            "annualProduction",
            "calculateAs",
            "orientation",
            "defaultHeading",
            "sizes",
            "geodesic",
        ];

        //inherit rectangle properties 
        let options = {};
        properties.forEach(function (property) {
            if (rectangle.hasOwnProperty(property)) {
                options[property] = rectangle[property];
            }
        });

        let polygon = this.createPolygonFromCoords(coords, options, map, maps);
        rectangle.setMap(null);
        return polygon;
    }

    createPolygonFromCoords(coords, options, map, maps) {
        // Construct the polygon.
        let rectPoly = new maps.Polygon({
            path: coords,
            geodesic: false,
        });
        rectPoly.setOptions(options);
        rectPoly.setMap(map);
        return rectPoly;
    }

    getPolygonBounds(polygon) {
        let bounds = new this.maps.LatLngBounds();
        polygon.getPath().getArray().map((latLng) => {
            bounds.extend(latLng);
        });
        return bounds;
    }

    getPolygonWithPadding(polygon, padding) {
        let finalPolygonVertices = [];
        const vertices = polygon.getPath().getArray();

        let currentVertex;
        let nextVertex;
        let tempHeading;
        let tempMovedVertex;
        let counterClockwiseHeadings = [];
        let clockwiseHeadings = [];
        let tempMovedVertices = [];

        // Counting angles from the current vertex to the next counterclockwise vertex
        for (let i = 0; i < vertices.length; i++) {
            currentVertex = vertices[i];
            nextVertex = vertices[(i + 1) % vertices.length];
            tempHeading = this.maps.geometry.spherical.computeHeading(currentVertex, nextVertex);
            counterClockwiseHeadings.push(tempHeading);
        }

        // Counting angles from the current vertex to the next one clockwise
        for (let i = 0; i < vertices.length; i++) {
            currentVertex = vertices[(vertices.length - i) % vertices.length];
            nextVertex = vertices[(vertices.length - i - 1) % vertices.length];
            tempHeading = this.maps.geometry.spherical.computeHeading(currentVertex, nextVertex);
            clockwiseHeadings.push(tempHeading);
        }

        // Counting shifted vertices by padding after a counterclockwise angle
        for (let i = 0; i < vertices.length; i++) {
            tempMovedVertex = this.maps.geometry.spherical.computeOffset(vertices[i], padding, counterClockwiseHeadings[i]);
            tempMovedVertices.push(tempMovedVertex);
        }

        // Counting shifted vertices by padding after a clockwise angle
        for (let i = 0; i < vertices.length; i++) {
            tempMovedVertex = this.maps.geometry.spherical.computeOffset(tempMovedVertices[i], padding, clockwiseHeadings[(vertices.length - i) % vertices.length]);
            finalPolygonVertices.push(tempMovedVertex);
        }

        return this.createPolygonFromCoords(finalPolygonVertices, {}, this.map, this.maps);
    }

    polygonAContainsPolygonB(polygonA, polygonB) {
        let fits = true;
        polygonB.getPath().getArray().forEach((latLng) => {
            if (fits) {
                fits = this.polygonContainsPoint(latLng, polygonA);
            }
        });
        return fits;
    }

    reDrawPolygonPanels(polygon, orientation, map, maps) {

        // Revert rotation
        if (polygon.defaultHeading) {
            this.rotatePolygon(polygon, undefined, -polygon.defaultHeading, this.map);
        }

        // Save orientation
        polygon.pvOrientation = orientation;

        // Set paddings
        const paddedPolygon = this.getPolygonWithPadding(polygon, 0.10);
        const paddedPolygonCenter = this.getCenterOfPolygon(paddedPolygon);
        const polygonBounds = this.getPolygonBounds(paddedPolygon);

        let finalPanels = [];
        let pvWidth = this.parseFloatFixed(this.props.pvWidth) / 100;
        let pvHeight = this.parseFloatFixed(this.props.pvHeight) / 100;

        if (orientation.includes('horizontal')) {
            [pvWidth, pvHeight] = [pvHeight, pvWidth];
        }

        const getNewPvPanel = (location, x, y) => {
            let pvPanel = this.addPredefinedRectangle(pvWidth, pvHeight, location, 'pvPanel', true);
            pvPanel.parent = polygon.uuid;
            pvPanel.elementCategory = 'pvPanel';
            pvPanel.pseudoX = x;
            pvPanel.pseudoY = y;
            pvPanel.addListener('click', (e) => {
                this.onPolygonClick(pvPanel);
            });
            pvPanel.addListener('dragstart', () => {
                this.onPolygonDragStart(pvPanel, map, maps);
            });
            pvPanel.addListener('dragend', () => {
                pvPanel.getPaths().forEach((path, pathIndex) => {
                    this.onPolygonDrag(path, pathIndex, pvPanel, map, maps);
                });
                this.onPolygonDragEnd(pvPanel, map, maps);
            });
            return pvPanel;
        }

        if (orientation.includes('single')) {
            finalPanels.push(getNewPvPanel(polygonBounds.getSouthWest(), -1, -1));
        } else {

            const maxX = 125;
            const maxY = 125;
            const failOver = 15;

            // Clear previous data
            this.removeElements(null, polygon.uuid, 'pvPanel');

            // New panels
            let sides = [
                { id: 'sw-1/1', point: polygonBounds.getSouthWest(), divider: 1, count: 0, pvPanels: [] },
                { id: 'sw-2/1', point: polygonBounds.getSouthWest(), divider: 2, count: 0, pvPanels: [] },
                { id: 'sw-3/1', point: polygonBounds.getSouthWest(), divider: 3, count: 0, pvPanels: [] },
            ];
            let counts = {}; // test on hipped 20 15 5 19
            let totalY = 0;

            sides = sides.map(side => {
                let inTheXGrid = 0;
                for (let x = 0; x < maxX; x++) {
                    let inTheYGrid = 0;
                    let offTheYGrid = 0;
                    for (let y = 0; y < maxY; y++) {
                        // create
                        let newPv = getNewPvPanel(side.point, x, y);
                        // adjust to SouthWest |
                        this.movePolygon(newPv, (pvHeight / 2), 0, this.maps);
                        // adjust to SouthWest -
                        if (side.divider) {
                            this.movePolygon(newPv, (pvWidth / side.divider), 90, this.maps);
                        }
                        // move further |
                        this.movePolygon(newPv, (pvHeight * y), 0, this.maps);
                        // move further -
                        this.movePolygon(newPv, (pvWidth * x), 90, this.maps);
                        // break maybe?
                        const newPvCenter = this.getCenterOfPolygon(newPv);
                        if (
                            !this.polygonContainsPoint(newPvCenter, paddedPolygon) // changed from paddedPolygon --> polygon
                            || !this.polygonAContainsPolygonB(paddedPolygon, newPv) // changed from paddedPolygon --> polygon
                        ) {
                            offTheYGrid++;
                            newPv.setMap(null);
                            if (offTheYGrid > failOver) {
                                break;
                            }
                            continue;
                        }
                        inTheYGrid++;
                        side.count++;
                        side.pvPanels.push(newPv);
                        if (typeof counts[side.id] === "undefined") {
                            counts[side.id] = {}
                        }
                        if (typeof counts[side.id][y] === "undefined") {
                            counts[side.id][y] = 0;
                        }
                        counts[side.id][y]++;
                        if (y > totalY) {
                            totalY = y;
                        }
                    }
                    if (inTheYGrid === 0) {
                        inTheXGrid++;
                        if (inTheXGrid > failOver) {
                            break;
                        }
                    }
                }
                return side;
            });

            for (let y = 0; y <= totalY; y++) {
                let winningId = null;
                let winningCount = -1;
                for (let sideId in counts) {
                    if (counts[sideId][y] > winningCount) {
                        winningId = sideId;
                        winningCount = counts[sideId][y];
                    }
                }
                sides = sides.map(side => {
                    if (side.id !== winningId) {
                        side.pvPanels = side.pvPanels.map(pvPanel => {
                            if (pvPanel.pseudoY !== y) {
                                return pvPanel;
                            }
                            pvPanel.setMap(null);
                            return null;
                        }).filter(Boolean);
                    }
                    if (side.pvPanels.length === 0) {
                        return null;
                    }
                    return side;
                }).filter(Boolean);
            }

            sides.forEach(side => {
                side.pvPanels.forEach(pvPanel => {
                    finalPanels.push(pvPanel);
                })
            });
            sides = undefined;

            if (['rectangle'].includes(polygon.primitiveShape)) {
                let boundsOfPvPanels = new maps.LatLngBounds();
                finalPanels.forEach(pvPanel => {
                    pvPanel.getPath().getArray().map((latLng) => {
                        boundsOfPvPanels.extend(latLng);
                    });
                });

                const boundsOfPvPanelsCenter = boundsOfPvPanels.getCenter();
                const localPaddedPolygonCenter = new google.maps.LatLng(paddedPolygonCenter.lat(), paddedPolygonCenter.lng());
                const distance = maps.geometry.spherical.computeDistanceBetween(boundsOfPvPanelsCenter, localPaddedPolygonCenter);
                const heading = maps.geometry.spherical.computeHeading(boundsOfPvPanelsCenter, localPaddedPolygonCenter);

                finalPanels = finalPanels.map(pvPanel => {
                    return this.movePolygon(pvPanel, distance, heading, this.maps);
                });
            } else if (['triangle', 'trapezoid'].includes(polygon.primitiveShape)) {
                for (let y = 0; y <= totalY; y++) {
                    let boundsOfPvPanels = new maps.LatLngBounds();
                    finalPanels.forEach(pvPanel => {
                        if (pvPanel.pseudoY === y) {
                            pvPanel.getPath().getArray().map((latLng) => {
                                boundsOfPvPanels.extend(latLng);
                            });
                        }
                    });

                    const boundsOfPvPanelsCenter = boundsOfPvPanels.getCenter();
                    const localPaddedPolygonCenter = new google.maps.LatLng(boundsOfPvPanelsCenter.lat(), paddedPolygonCenter.lng());
                    const distance = maps.geometry.spherical.computeDistanceBetween(boundsOfPvPanelsCenter, localPaddedPolygonCenter);
                    const heading = maps.geometry.spherical.computeHeading(boundsOfPvPanelsCenter, localPaddedPolygonCenter);

                    finalPanels = finalPanels.map(pvPanel => {
                        if (pvPanel.pseudoY === y) {
                            return this.movePolygon(pvPanel, distance, heading, this.maps);
                        }
                        return pvPanel;
                    });
                };
            } else {
                // ... skip centering
            }

        }

        finalPanels = finalPanels.map(pvPanel => {
            if (polygon.defaultHeading) {
                pvPanel = this.rotatePolygon(pvPanel, this.map.getProjection().fromLatLngToPoint(paddedPolygonCenter), polygon.defaultHeading, this.map);
            }
            this.handleElementAdd(pvPanel);
            return pvPanel;
        });

        // Re-revert rotation
        if (polygon.defaultHeading) {
            this.rotatePolygon(polygon, undefined, polygon.defaultHeading, this.map);
        }

        // Clean-up
        paddedPolygon.setMap(null);

    }

    updatePolygonProps(polygon, map, maps, callback) {
        if (typeof callback !== "function") {
            callback = () => { }
        }
        this.reDrawPolygonSlopeDimensions(map, maps, () => {
            this.reDrawPolygonSlopeRidges(map, maps, () => {
                this.reDrawPolygonSlopeParametersLabel(polygon, map, maps, () => {
                    this.reCalcPolygonDimensions(polygon, map, maps, () => {
                        callback()
                    });
                });
            })
        });
    }

    onPolygonClick(polygon, map, maps) {
        if (this.state.mapHideControls) {
            return;
        }
        this.selectElement(polygon);
        if (polygon.elementCategory === "slope") {
            this.updatePolygonProps(polygon, map, maps);
        }
    }

    onPolygonDragStart(polygon, map, maps) {
        this.duringDrag = true;
        this.fromPointDrag = this.getCenterOfPolygon(polygon);
        this.afterDrag = [];
        this.selectElement(polygon);
    }

    onPolygonDragEnd(polygon, map, maps) {
        this.afterDrag.forEach(dragData => {
            polygon.getPaths().forEach((path, pathIndex) => {
                path.forEach((point, pointIndex) => {
                    let newPoint = maps.geometry.spherical.computeOffset(point, dragData.distance, dragData.heading);
                    path.setAt(pointIndex, newPoint);
                });
            });
        });

        if (polygon.elementCategory === "slope") {
            let toPointDrag = this.getCenterOfPolygon(polygon);
            let distance = maps.geometry.spherical.computeDistanceBetween(this.fromPointDrag, toPointDrag);
            let heading = maps.geometry.spherical.computeHeading(this.fromPointDrag, toPointDrag);
            let elements = this.elements.map(element => {
                if (element.elementCategory === "pvPanel" && element.parent === polygon.uuid) {
                    return this.movePolygon(element, distance, heading, maps);
                }
                return element;
            });
            this.setElements(elements);
            this.updatePolygonProps(polygon, map, maps);
        }

        this.duringDrag = false;
        this.fromPointDrag = null;
        this.afterDrag = [];
    }

    onPolygonDrag(path, pathIndex, polygon, map, maps) {
        let minDistance = 9999;
        this.elements
            .filter(element => element.elementCategory === polygon.elementCategory && element.uuid !== polygon.uuid)
            .forEach(element => {
                element.getPath().forEach((subPoint) => {
                    path.forEach((mainPoint, mainPointIndex) => {
                        let distance = maps.geometry.spherical.computeDistanceBetween(mainPoint, subPoint); // meters
                        if (distance < 2 && distance < minDistance) {
                            let heading = maps.geometry.spherical.computeHeading(mainPoint, subPoint);
                            this.afterDrag = [{
                                pathIndex: pathIndex,
                                pointIndex: mainPointIndex,
                                distance: distance,
                                heading: heading
                            }];
                            minDistance = distance;
                        }
                    });
                });
            });
    }

    polygonCompleteCallback(polygon, map, maps, callback) {
        if (typeof callback !== 'function') {
            callback = () => { }
        }
        polygon.uuid = polygon.uuid || this.props.utils.getUUID();
        polygon.parent = polygon.parent || null;
        polygon.elementCategory = polygon.elementCategory || this.state.drawMode; // slope or obstacle
        polygon.rotation = polygon.rotation || 90;
        polygon.slopeDeg = polygon.slopeDeg || 0;
        polygon.slopePercent = polygon.slopePercent || 0;
        polygon.elevation = polygon.elevation || 2000;
        polygon.calculateAs = polygon.calculateAs || null;
        polygon.orientation = polygon.orientation || 0;
        polygon.defaultHeading = polygon.defaultHeading || 0;
        polygon.pvOrientation = polygon.pvOrientation || '';
        polygon.fakeSlopeArea = polygon.fakeSlopeArea || 0;
        polygon.annualProduction = polygon.annualProduction || 0;
        polygon.sizes = polygon.sizes || {};

        if (polygon.elementCategory === 'slope') {
            let orgEdges = Array.isArray(polygon.edges) ? [...polygon.edges] : [];
            polygon.edges = [];
            for (let e = 0; e < polygon.getPath().getLength(); e++) {
                let start = polygon.getPath().getAt(e);
                let end = polygon.getPath().getAt(e < polygon.getPath().getLength() - 1 ? e + 1 : 0);
                let sideLength = maps.geometry.spherical.computeDistanceBetween(start, end);
                let newEdgeType = e === 0 ? 'ridge' : e === polygon.getPath().getLength() - 2 ? 'eaves' : 'hip';
                orgEdges.forEach(orgEdge => {
                    if (orgEdge.index === e) {
                        newEdgeType = orgEdge.type || newEdgeType;
                    }
                })
                polygon.edges.push({
                    index: e,
                    type: newEdgeType,
                    path: polygon.getPath().getAt(e),
                    sideLength: sideLength
                });
            }
        }

        let estimatedRotation = this.estimatePolygonRotation(polygon, map, maps);
        polygon.rotation = estimatedRotation.estimatedHeading;

        maps.event.addListener(polygon, 'click', () => {
            this.onPolygonClick(polygon, map, maps);
        });

        maps.event.addListener(polygon, 'dragstart', () => {
            this.onPolygonDragStart(polygon, map, maps);
        });

        maps.event.addListener(polygon, 'dragend', () => {
            polygon.getPaths().forEach((path, pathIndex) => {
                this.onPolygonDrag(path, pathIndex, polygon, map, maps);
            });
            this.onPolygonDragEnd(polygon, map, maps);
        });

        polygon.getPaths().forEach((path, pathIndex) => {
            maps.event.addListener(path, 'insert_at', () => {
                this.updatePolygonProps(polygon, map, maps);
            });

            maps.event.addListener(path, 'remove_at', () => {
                this.updatePolygonProps(polygon, map, maps);
            });
        });

        this.handleElementAdd(polygon, () => {
            callback();
        });

    }

    toggleDrawMode() {
        this.setState(
            { drawMode: this.state.drawMode === 'slope' ? 'obstacle' : 'slope' },
            () => {
                if (this.controlChangeMode) {
                    this.controlChangeModeText.innerHTML = this.state.drawMode;
                }
                this.drawingManager.setOptions({
                    polygonOptions: this.getCurrentPolygonTypeOptions(),
                    rectangleOptions: this.getCurrentPolygonTypeOptions()
                });
            }
        );
    }

    bindDrawingManagerEvents(drawingManager, map, maps) {

        // Setup the click event listeners for the custom button
        if (this.controlAddSlope) {
            this.controlAddSlope.addEventListener("click", (e) => {
                e.preventDefault();
                this.setState({ showModalAddWhat: true });
            });
        }

        // Setup the click event listeners for the custom button
        if (this.controlToggleRuler) {
            this.controlToggleRuler.addEventListener("click", (e) => {
                e.preventDefault();
                this.setState(
                    { showDimensions: !this.state.showDimensions },
                    () => {
                        this.reDrawPolygonSlopeDimensions(map, maps);
                    }
                );
            });
        }

        // Setup the click event listeners for the custom button
        if (this.controlToggleRidges) {
            this.controlToggleRidges.addEventListener("click", (e) => {
                e.preventDefault();
                this.setState(
                    { showRidges: !this.state.showRidges },
                    () => {
                        this.reDrawPolygonSlopeRidges(map, maps);
                    }
                );
            });
        }

        // Setup the click event listeners for the custom button
        if (this.controlChangeMode) {
            this.controlChangeMode.addEventListener("click", (e) => {
                e.preventDefault();
                this.toggleDrawMode();
            });
        }


        // Setup the click event listerers for the custom button
        if (this.controlRotateLeftButton) {
            this.controlRotateLeftButton.addEventListener("click", (e) => {
                e.preventDefault();
                this.adjustMap('rotate', 20);
            });
        }

        if (this.controlRotateRightButton) {
            this.controlRotateRightButton.addEventListener("click", (e) => {
                e.preventDefault();
                this.adjustMap('rotate', -20);
            });
        }

        // Setup events for standard google maps behaviour
        maps.event.addListener(drawingManager, 'markercomplete', (marker) => {
            marker.setMap(null);
        });

        maps.event.addListener(map, 'click', (event) => {
            this.selectElement(null);
        });

        maps.event.addListener(drawingManager, 'rectanglecomplete', (rectangle) => {
            let polygon = this.createPolygonFromRectangle(rectangle, map, maps);
            polygon.originalType = "rectangle";
            this.polygonCompleteCallback(polygon, map, maps);
        });

        maps.event.addListener(drawingManager, 'polygoncomplete', (polygon) => {
            polygon.originalType = "polygon";
            this.polygonCompleteCallback(polygon, map, maps);
        });
    }

    addPolygonPanels(orientation, e) {
        if (e) {
            e.preventDefault();
        }
        this.reDrawPolygonPanels(this.state.selectedElement, orientation, this.map, this.maps);
    }

    getCurrentPolygonTypeOptions(overrideCurrentDrawMode) {
        return this.polygonOptions[overrideCurrentDrawMode || this.state.drawMode];
    }

    calcCoordsOfTriangleByGivenSize(center, size) {
        let n = this.maps.geometry.spherical.computeOffset(center, size.height / 2, 0),
            s = this.maps.geometry.spherical.computeOffset(center, size.height / 2, 180),
            e = this.maps.geometry.spherical.computeOffset(center, size.width / 2, 90),
            w = this.maps.geometry.spherical.computeOffset(center, size.width / 2, 270);
        return [
            { lat: n.lat(), lng: n.lng() },
            { lat: s.lat(), lng: w.lng() },
            { lat: s.lat(), lng: e.lng() }
        ];
    }

    addPredefinedTriangle(newPolygonSizeA, newPolygonSizeH) {
        let width = this.parseFloatFixed(newPolygonSizeA || this.state.newPolygonSizeA);
        let height = this.parseFloatFixed(newPolygonSizeH || this.state.newPolygonSizeH); // (width * Math.sqrt(3)) / 2; 
        let coords = this.calcCoordsOfTriangleByGivenSize(this.map.getCenter(), new google.maps.Size(width, height));
        let polygon = this.createPolygonFromCoords(coords, { originalType: "polygon", primitiveShape: "triangle", uuid: this.props.utils.getUUID(), ...this.getCurrentPolygonTypeOptions() }, this.map, this.maps);
        polygon.edges = [
            {
                index: 0,
                type: 'hip'
            },
            {
                index: 1,
                type: 'eaves'
            },
            {
                index: 2,
                type: 'hip'
            }
        ];
        polygon.sizes = {
            a: width,
            h: height
        };
        return polygon;
    }

    calcCoordsOfTrapezoidByGivenSize(center, size, newPolygonShape) {
        switch (newPolygonShape) {
            case "trapezoidRectLeft": {
                let n = this.maps.geometry.spherical.computeOffset(center, size.height / 2, 0),
                    s = this.maps.geometry.spherical.computeOffset(center, size.height / 2, 180),
                    ec = this.maps.geometry.spherical.computeOffset(center, size.ceil, 90),
                    wc = this.maps.geometry.spherical.computeOffset(center, 0, 270),
                    ef = this.maps.geometry.spherical.computeOffset(center, size.floor, 90),
                    wf = this.maps.geometry.spherical.computeOffset(center, 0, 270);
                return [
                    { lat: n.lat(), lng: ec.lng() },
                    { lat: n.lat(), lng: wc.lng() },
                    { lat: s.lat(), lng: wf.lng() },
                    { lat: s.lat(), lng: ef.lng() },
                ];
            }
            case "trapezoidRectRight": {
                let n = this.maps.geometry.spherical.computeOffset(center, size.height / 2, 0),
                    s = this.maps.geometry.spherical.computeOffset(center, size.height / 2, 180),
                    ec = this.maps.geometry.spherical.computeOffset(center, 0, 90),
                    wc = this.maps.geometry.spherical.computeOffset(center, size.ceil, 270),
                    ef = this.maps.geometry.spherical.computeOffset(center, 0, 90),
                    wf = this.maps.geometry.spherical.computeOffset(center, size.floor, 270);
                return [
                    { lat: n.lat(), lng: ec.lng() },
                    { lat: n.lat(), lng: wc.lng() },
                    { lat: s.lat(), lng: wf.lng() },
                    { lat: s.lat(), lng: ef.lng() },
                ];
            }
            case "trapezoid":
            default: {
                let n = this.maps.geometry.spherical.computeOffset(center, size.height / 2, 0),
                    s = this.maps.geometry.spherical.computeOffset(center, size.height / 2, 180),
                    ec = this.maps.geometry.spherical.computeOffset(center, size.ceil / 2, 90),
                    wc = this.maps.geometry.spherical.computeOffset(center, size.ceil / 2, 270),
                    ef = this.maps.geometry.spherical.computeOffset(center, size.floor / 2, 90),
                    wf = this.maps.geometry.spherical.computeOffset(center, size.floor / 2, 270);
                return [
                    { lat: n.lat(), lng: ec.lng() },
                    { lat: n.lat(), lng: wc.lng() },
                    { lat: s.lat(), lng: wf.lng() },
                    { lat: s.lat(), lng: ef.lng() },
                ];
            }
        }
    }

    addPredefinedTrapezoid(newPolygonSizeA, newPolygonSizeB, newPolygonSizeH, newPolygonShape, newPolygonEdges) {
        let ceil = this.parseFloatFixed(newPolygonSizeA || this.state.newPolygonSizeA);
        let floor = this.parseFloatFixed(newPolygonSizeB || this.state.newPolygonSizeB);
        let height = this.parseFloatFixed(newPolygonSizeH || this.state.newPolygonSizeH);
        let shape = newPolygonShape || this.state.newPolygonShape;
        let coords = this.calcCoordsOfTrapezoidByGivenSize(this.map.getCenter(), { ceil: ceil, floor: floor, height: height }, shape);
        let polygon = this.createPolygonFromCoords(coords, { originalType: "polygon", primitiveShape: newPolygonShape || "trapezoid", uuid: this.props.utils.getUUID(), ...this.getCurrentPolygonTypeOptions() }, this.map, this.maps);
        let edges = [
            {
                index: 0,
                type: 'ridge'
            },
            {
                index: 1,
                type: 'hip'
            },
            {
                index: 2,
                type: 'eaves'
            },
            {
                index: 3,
                type: 'hip'
            }
        ];
        if (typeof newPolygonEdges === "undefined") {
            polygon.edges = edges;
        } else {
            polygon.edges = newPolygonEdges;
        }
        polygon.sizes = {
            a: ceil,
            b: floor,
            h: height,
        };
        return polygon;
    }

    calcBoundsOfRectangleByGivenSize(center, size) {
        let n = this.maps.geometry.spherical.computeOffset(center, size.height / 2, 0).lat(),
            s = this.maps.geometry.spherical.computeOffset(center, size.height / 2, 180).lat(),
            e = this.maps.geometry.spherical.computeOffset(center, size.width / 2, 90).lng(),
            w = this.maps.geometry.spherical.computeOffset(center, size.width / 2, 270).lng();
        return new this.maps.LatLngBounds(new this.maps.LatLng(s, w), new this.maps.LatLng(n, e));
    }

    addPredefinedRectangle(newPolygonSizeA, newPolygonSizeH, newPolygonLocation, newPolygonType, doNotAddEdges) {
        let polygonSizeA = this.parseFloatFixed(newPolygonSizeA || this.state.newPolygonSizeA);
        let polygonSizeH = this.parseFloatFixed(newPolygonSizeH || this.state.newPolygonSizeH);
        let rectangle = new this.maps.Rectangle(
            {
                bounds: this.calcBoundsOfRectangleByGivenSize(
                    newPolygonLocation || this.map.getCenter(),
                    new google.maps.Size(
                        polygonSizeA,
                        polygonSizeH,
                    )
                ),
                map: this.map,
                ...this.getCurrentPolygonTypeOptions(newPolygonType),
                originalType: "polygon",
                primitiveShape: "rectangle",
                uuid: this.props.utils.getUUID(),
            }
        );
        let polygon = this.createPolygonFromRectangle(rectangle, this.map, this.maps);
        if (doNotAddEdges) {
            // ... 
        } else {
            polygon.edges = [
                {
                    index: 0,
                    type: 'eaves'
                },
                {
                    index: 1,
                    type: 'verge'
                },
                {
                    index: 2,
                    type: 'ridge'
                },
                {
                    index: 3,
                    type: 'verge'
                }
            ];
        }
        polygon.sizes = {
            a: polygonSizeA,
            h: polygonSizeH
        }
        return polygon;
    }

    addPredefinedPolygonRoutine(polygon, callback) {
        if (typeof callback !== 'function') {
            callback = () => { }
        }
        let polygonCenter = this.getCenterOfPolygon(polygon);
        this.rotatePolygon(polygon, this.map.getProjection().fromLatLngToPoint(polygonCenter), this.state.newPolygonOrientation, this.map);
        this.polygonCompleteCallback(polygon, this.map, this.maps, callback);
        return polygon;
    }

    addPredefinedPolygon(e) {
        if (e) {
            e.preventDefault();
        }

        const {
            updateSelectedElement,
            selectedElement
        } = this.state;

        let polygon;
        let boundsOfAllElements = this.getBoundsOfElement();

        if (this.state.newPolygonShape === 'rectangle') {
            polygon = this.addPredefinedRectangle();
        }

        if (this.state.newPolygonShape === 'triangle') {
            polygon = this.addPredefinedTriangle();
        }

        if (this.state.newPolygonShape === 'trapezoid') {
            polygon = this.addPredefinedTrapezoid(undefined, undefined, undefined, 'trapezoid');
        }

        if (this.state.newPolygonShape === 'trapezoidRectLeft') {
            let edges = [
                {
                    index: 0,
                    type: 'eaves'
                },
                {
                    index: 1,
                    type: 'verge'
                },
                {
                    index: 2,
                    type: 'ridge'
                },
                {
                    index: 3,
                    type: 'valley'
                }
            ]
            polygon = this.addPredefinedTrapezoid(undefined, undefined, undefined, 'trapezoidRectLeft', edges);
        }

        if (this.state.newPolygonShape === 'trapezoidRectRight') {
            let edges = [
                {
                    index: 0,
                    type: 'eaves'
                },
                {
                    index: 1,
                    type: 'valley'
                },
                {
                    index: 2,
                    type: 'ridge'
                },
                {
                    index: 3,
                    type: 'verge'
                }
            ]
            polygon = this.addPredefinedTrapezoid(undefined, undefined, undefined, 'trapezoidRectRight', edges);
        }

        polygon.defaultHeading = this.state.newPolygonOrientation;
        polygon = this.addPredefinedPolygonRoutine(polygon);

        if (updateSelectedElement && selectedElement) {
            const uuid = selectedElement.uuid;
            const properCenter = this.getCenterOfPolygon(selectedElement);
            const wrongCenter = this.getCenterOfPolygon(polygon);
            this.removeElements(uuid, null, null, '&&', false, () => {
                polygon = this.updateElement(polygon, { uuid: uuid });
                this.handleElementChange(polygon, () => {
                    const distance = this.maps.geometry.spherical.computeDistanceBetween(wrongCenter, properCenter);
                    const heading = this.maps.geometry.spherical.computeHeading(wrongCenter, properCenter);
                    polygon = this.movePolygon(polygon, distance, heading, this.maps)
                });
            });
        } else {
            let boundsOfPolygon = this.getBoundsOfElement(polygon);
            if (boundsOfAllElements.hasBounds && boundsOfPolygon.hasBounds) {
                polygon = this.movePolygon(polygon, (boundsOfAllElements.width * 0.5) + (boundsOfPolygon.width) + 1, 90, this.maps);
            }
        }

        this.boundsFitted = false;
        this.mapFitBounds();

        this.setState({
            newPolygonSizeA: 0,
            newPolygonSizeB: 0,
            newPolygonSizeH: 0,
            newPolygonOrientation: 0,
            newPolygonShape: 'rectangle',
            showModalAddWhat: false,
            showModalAddShape: false,
            updateSelectedElement: false,
            showModalAddRoof: false
        })
    }

    addPredefinedRoof(e) {
        if (e) {
            e.preventDefault();
        }

        let newElements = [];

        const {
            newRoofShape,
            newRoofSizeAlfa,
        } = this.state;

        const newRoofSizeA = this.parseFloatFixed(this.state.newRoofSizeA);
        const newRoofSizeB = this.parseFloatFixed(this.state.newRoofSizeB);
        const newRoofSizeC = this.parseFloatFixed(this.state.newRoofSizeC);

        if (newRoofShape === 'gable') {
            const newRoofSizeC = (0.5 * newRoofSizeB) / Math.cos(this.props.utils.degreesToRadians(newRoofSizeAlfa));

            let polygonA = this.addPredefinedRectangle(newRoofSizeA, newRoofSizeC);
            polygonA.defaultHeading = 90;
            polygonA.orientation = 90;
            polygonA.slopeDeg = newRoofSizeAlfa;
            polygonA.slopePercent = this.props.utils.percentFromDegrees(newRoofSizeAlfa);
            this.addPredefinedPolygonRoutine(polygonA);
            this.rotatePolygon(polygonA, undefined, polygonA.defaultHeading, this.map);
            this.movePolygon(polygonA, newRoofSizeC / 2, polygonA.defaultHeading, this.maps);

            let polygonB = this.addPredefinedRectangle(newRoofSizeA, newRoofSizeC);
            polygonB.defaultHeading = 270;
            polygonB.orientation = 270;
            polygonB.slopeDeg = newRoofSizeAlfa;
            polygonB.slopePercent = this.props.utils.percentFromDegrees(newRoofSizeAlfa);
            this.addPredefinedPolygonRoutine(polygonB);
            this.rotatePolygon(polygonB, undefined, polygonB.defaultHeading, this.map);
            this.movePolygon(polygonB, newRoofSizeC / 2, polygonB.defaultHeading, this.maps);

            newElements = [polygonA, polygonB];
        }

        if (newRoofShape === 'ltshapedgable') {
            const {
                newRoofSizeA,
                newRoofSizeB,
                newRoofSizeC,
                newRoofSizeD,
                newRoofSizeE,
                newRoofSizeAlfa,
                newRoofSizeBeta,
            } = this.state;

            const newRoofSizePolyB = (0.5 * newRoofSizeB) / Math.cos(this.props.utils.degreesToRadians(newRoofSizeAlfa));
            const newRoofSizePolyE = (0.5 * newRoofSizeE) / Math.cos(this.props.utils.degreesToRadians(newRoofSizeBeta));

            let polygonA = this.addPredefinedRectangle(newRoofSizeA, newRoofSizePolyB);
            polygonA.defaultHeading = 0;
            polygonA.orientation = 0;
            polygonA.slopeDeg = newRoofSizeAlfa;
            polygonA.slopePercent = this.props.utils.percentFromDegrees(newRoofSizeAlfa);
            this.addPredefinedPolygonRoutine(polygonA);
            this.rotatePolygon(polygonA, undefined, polygonA.defaultHeading, this.map);
            this.movePolygon(polygonA, newRoofSizePolyB / 2, polygonA.defaultHeading, this.maps);

            let polygonB = this.addPredefinedRectangle(newRoofSizeA, newRoofSizePolyB);
            polygonB.defaultHeading = 180;
            polygonB.orientation = 180;
            polygonB.slopeDeg = newRoofSizeAlfa;
            polygonB.slopePercent = this.props.utils.percentFromDegrees(newRoofSizeAlfa);
            this.addPredefinedPolygonRoutine(polygonB);
            this.rotatePolygon(polygonB, undefined, polygonB.defaultHeading, this.map);
            this.movePolygon(polygonB, newRoofSizePolyB / 2, polygonB.defaultHeading, this.maps);

            let polygonC = this.addPredefinedTrapezoid(newRoofSizeC, newRoofSizeD, newRoofSizePolyE, 'trapezoidRectRight');
            polygonC.defaultHeading = 90;
            polygonC.orientation = 90;
            polygonC.slopeDeg = newRoofSizeBeta;
            polygonC.slopePercent = this.props.utils.percentFromDegrees(newRoofSizeBeta);
            this.addPredefinedPolygonRoutine(polygonC);
            this.rotatePolygon(polygonC, undefined, polygonC.defaultHeading, this.map);
            this.movePolygon(polygonC, -newRoofSizePolyB, 0, this.maps);
            // this.movePolygon(polygonC, -(newRoofSizeC - newRoofSizeD), 0, this.maps);

            // let polygonD = this.addPredefinedTrapezoid(newRoofSizeC, newRoofSizeD, newRoofSizePolyE, 'trapezoidRectLeft');
            // polygonD.defaultHeading = 0;
            // polygonD.orientation = 0;
            // polygonD.slopeDeg = newRoofSizeBeta;
            // polygonD.slopePercent = this.props.utils.percentFromDegrees(newRoofSizeBeta);
            // this.addPredefinedPolygonRoutine(polygonD);
            // this.rotatePolygon(polygonD, undefined, polygonD.defaultHeading, this.map);
            // this.movePolygon(polygonD, newRoofSizePolyB / 2, polygonA.defaultHeading, this.maps);
            // this.movePolygon(polygonD, (newRoofSizeC - newRoofSizeD), polygonD.defaultHeading, this.maps);

            // newElements = [polygonA, polygonB, polygonC, polygonD];

            newElements = [polygonA, polygonB, polygonC];
        }

        if (newRoofShape === 'multi') {
            const {
                newRoofSizeA,
                newRoofSizeB,
                newRoofSizeC,
                newRoofSizeAlfa,
            } = this.state;
            const newRoofSizeD = (0.5 * newRoofSizeB) / Math.cos(this.props.utils.degreesToRadians(newRoofSizeAlfa));
            const newRoofSizeE = Math.sqrt(Math.pow(0.5 * newRoofSizeB * Math.tan(this.props.utils.degreesToRadians(newRoofSizeAlfa)), 2) + Math.pow((newRoofSizeA - newRoofSizeC) * 0.5, 2));
            const newRoofSizeBeta = Math.round(this.props.utils.radiansToDegrees(Math.acos((newRoofSizeA - newRoofSizeC) / (2 * newRoofSizeE))));

            let polygonA = this.addPredefinedTrapezoid(newRoofSizeC, newRoofSizeA, newRoofSizeD);
            polygonA.defaultHeading = 90;
            polygonA.orientation = 270;
            polygonA.slopeDeg = newRoofSizeAlfa;
            polygonA.slopePercent = this.props.utils.percentFromDegrees(newRoofSizeAlfa);
            this.addPredefinedPolygonRoutine(polygonA);
            this.rotatePolygon(polygonA, undefined, polygonA.defaultHeading, this.map);
            this.movePolygon(polygonA, newRoofSizeD / 2, -polygonA.defaultHeading, this.maps);

            let polygonB = this.addPredefinedTrapezoid(newRoofSizeC, newRoofSizeA, newRoofSizeD);
            polygonB.defaultHeading = 270;
            polygonB.orientation = 90;
            polygonB.slopeDeg = newRoofSizeAlfa;
            polygonB.slopePercent = this.props.utils.percentFromDegrees(newRoofSizeAlfa);
            this.addPredefinedPolygonRoutine(polygonB);
            this.rotatePolygon(polygonB, undefined, polygonB.defaultHeading, this.map);
            this.movePolygon(polygonB, newRoofSizeD / 2, -polygonB.defaultHeading, this.maps);

            let polygonC = this.addPredefinedTriangle(newRoofSizeB, newRoofSizeE);
            polygonC.defaultHeading = 180;
            polygonC.orientation = 0;
            polygonC.slopeDeg = newRoofSizeBeta;
            polygonC.slopePercent = this.props.utils.percentFromDegrees(newRoofSizeBeta);
            this.addPredefinedPolygonRoutine(polygonC);
            this.rotatePolygon(polygonC, undefined, polygonC.defaultHeading, this.map);
            this.movePolygon(polygonC, (newRoofSizeE / 2) + (newRoofSizeC / 2), 0, this.maps);

            let polygonD = this.addPredefinedTriangle(newRoofSizeB, newRoofSizeE);
            polygonD.defaultHeading = 0;
            polygonD.orientation = 180;
            polygonD.slopeDeg = newRoofSizeBeta;
            polygonD.slopePercent = this.props.utils.percentFromDegrees(newRoofSizeBeta);
            this.addPredefinedPolygonRoutine(polygonD);
            this.rotatePolygon(polygonD, undefined, polygonD.defaultHeading, this.map);
            this.movePolygon(polygonD, (newRoofSizeE / 2) + (newRoofSizeC / 2), 180, this.maps);

            newElements = [polygonA, polygonB, polygonC, polygonD];
        }

        newElements.forEach(polygon => {
            this.reDrawPolygonPanels(polygon, this.defaultPanelsOrientation, this.map, this.maps);
        });

        this.setState({
            roofType: newRoofShape,
            newRoofSizeA: '',
            newRoofSizeB: '',
            newRoofSizeC: '',
            newRoofSizeAlfa: 0,
            newRoofSizeBeta: 0,
            showModalAddWhat: false,
            showModalAddShape: false,
            updateSelectedElement: false,
            showModalAddRoof: false
        });

    }

    removePolygonPanels(e) {
        if (e) {
            e.preventDefault();
        }
        if (this.state.selectedElement) {
            this.removeElements(null, this.state.selectedElement.uuid, 'pvPanel');
        }
    }

    handleGoogleMapApi({ map, maps }) {
        this.map = map;
        this.maps = maps;

        if (this.props.enableDesigner) {
            this.addLayers(map, maps);
            if ([appEnv.local].includes(appEnvCurrent)) {
                // this.addImageOverlay(map);
            }
            this.addDrawingManager(map, maps);
        } else {
            this.renderLocationMarkers(map, maps, this.props.markers);
        }

        this.map.addListener('tilesloaded', () => {
            this.props.api.dispatchEvent('MAP_TILES_LOADED', {});
        });
    }

    setMapTypeId(e, mapTypeId) {
        if (e) {
            e.preventDefault();
        }
        this.map.setMapTypeId(mapTypeId);
        this.setState({ mapTypeId: mapTypeId });
    }

    getSerializableProperties() {
        return [
            "uuid",
            "importuuid",
            "edges",
            "elementCategory",
            "elevation",
            "originalType",
            "parent",
            "primitiveShape",
            "fakeSlopeArea",
            "annualProduction",
            "rotation",
            "slopeDeg",
            "slopePercent",
            "uuid",
            "calculateAs",
            "orientation",
            "defaultHeading",
            "sizes",
            "pvOrientation",
        ];
    }

    serializeElement(element) {
        const properties = this.getSerializableProperties();
        let latLngs = [];
        element.getPath().getArray().forEach((polyLatLng) => {
            latLngs.push({ lat: polyLatLng.lat(), lng: polyLatLng.lng() });
        });
        let serialized = { paths: latLngs };
        properties.forEach((property) => {
            if (element.hasOwnProperty(property)) {
                serialized[property] = element[property];
            }
        });
        return serialized;
    }

    serializeElements() {
        let serializedArray = [];
        this.elements
            .filter(element => ['pvPanel', 'slope'].includes(element.elementCategory))
            .forEach(element => {
                const serialized = this.serializeElement(element);
                serializedArray.push(serialized);
            });
        return serializedArray;
    }

    consumeRawElement(element) {
        let properties = this.getSerializableProperties();
        let elementProps = { ...this.polygonOptions[element.elementCategory] };
        properties.forEach((property) => {
            if (element.hasOwnProperty(property)) {
                elementProps[property] = element[property];
            }
        });
        let polygon = this.createPolygonFromCoords(element.paths, elementProps, this.map, this.maps);
        this.polygonCompleteCallback(polygon, this.map, this.maps, () => { this.setInputData(); });
    }

    parseElements(rawElements, keepOld) {
        if (!keepOld) {
            this.elements = this.elements.map(element => {
                element.setMap(null);
                return null;
            });
            this.elements = [];
        }
        [...rawElements].forEach(element => {
            this.elements.push(this.consumeRawElement(element));
        });
        this.setState({ loadedDesign: [...rawElements] });
    }

    setInputData(callback) {

        if (typeof callback !== "function") {
            callback = () => { }
        }

        let roofType = this.state.roofType;
        let roofArea = 0;
        let roofAreaOver38Deg = 0;
        let pvArea = 0;
        let pvAmount = 0;
        let ridgeLength = 0;
        let eavesLength = 0;
        let vergeLength = 0;
        let valleyLength = 0;
        let offeredPvPower = 0;
        let passiveArea = 0;
        let bipvArea = 0;
        let passiveAreaExtra = 0;
        let passiveAreaAbsolute = 0;
        let pvDesign;
        let holdAutoCalculation = true;
        let assumedPvPower = 0;
        let offeredPvPowerError = false;
        let neededPvPower = 0;
        let hipLength = 0;
        let roofPitch = 0;
        let pvHorizontal = false;
        let pvVertical = false;

        this.elements.forEach(element => {
            switch (element.elementCategory) {
                case 'slope':
                    let realSlopeArea = this.maps.geometry.spherical.computeArea(element.getPath()) + this.parseFloatFixed(element.fakeSlopeArea);

                    roofArea += realSlopeArea;
                    roofPitch = Math.max(roofPitch, element.slopeDeg);
                    if (element.calculateAs !== 'passiveAreaExtra') {
                        pvHorizontal = pvHorizontal || element.pvOrientation.includes('horizontal');
                        pvVertical = pvVertical || element.pvOrientation.includes('vertical');
                    }

                    if (element.calculateAs === 'passiveAreaExtra') {
                        passiveAreaExtra += realSlopeArea;
                    }

                    if (this.parseFloatFixed(element.slopeDeg) > 38) {
                        roofAreaOver38Deg += realSlopeArea;
                    }

                    element.edges.forEach(edge => {
                        switch (edge.type) {
                            case 'ridge':
                                ridgeLength += edge.sideLength;
                                break;

                            case 'eaves':
                                eavesLength += edge.sideLength;
                                break;

                            case 'verge':
                                vergeLength += edge.sideLength;
                                break;

                            case 'valley':
                                valleyLength += edge.sideLength;
                                break;

                            case 'hip':
                                hipLength += edge.sideLength;
                                break;

                            default:
                                break;
                        }
                    });
                    break;

                case 'pvPanel':
                    pvArea += this.maps.geometry.spherical.computeArea(element.getPath());
                    pvAmount += 1;
                    break;

                default:
                    break;
            }
        });

        offeredPvPower = (pvAmount * this.props.pvPower) / 1000;
        assumedPvPower = offeredPvPower;
        passiveArea = roofArea - pvArea;
        bipvArea = roofArea - passiveAreaExtra;
        passiveAreaAbsolute = roofArea - pvArea - passiveAreaExtra;
        pvDesign = this.serializeElements();
        holdAutoCalculation = this.elements.length > 0;
        neededPvPower = offeredPvPower * 833;

        const newUpperState = {
            roofArea,
            roofAreaOver38Deg,
            pvArea,
            pvAmount,
            bipvArea,
            passiveAreaAbsolute,
            passiveAreaExtra,
            ridgeLength,
            eavesLength,
            vergeLength,
            valleyLength,
            offeredPvPower,
            assumedPvPower,
            offeredPvPowerError,
            passiveArea,
            pvDesign,
            holdAutoCalculation,
            neededPvPower,
            hipLength,
            pvHorizontal,
            pvVertical,
            roofPitch,
        };

        const newLocalState = {
            roofType,
            ...newUpperState
        };

        this.setState(newLocalState, () => {
            this.props.setUpperState(newUpperState, () => {
                callback();
            });
        });
    }

    reCalcFinancing() {
        this.props.reCalcFinancing(true, (data) => {
            let newElements = [...this.elements]
            if (data && data.financing && data.financing.gisData) {
                data.financing.gisData.forEach(gisData => {
                    newElements = newElements.map((element) => {
                        if (element.uuid === gisData.elementUuid) {
                            return this.updateElement(element, {
                                annualProduction: gisData.annualProduction
                            });
                        }
                        return element;
                    });
                })
                this.setElements(newElements);
            }
            this.props.setUpperState({ isLoading: false })
        });
    }

    reFetchTokens() {
        this.props.reFetchTokens();
    }

    editSelectedElement() {
        const { selectedElement } = this.state;
        this.setState({
            showModalAddShape: true,
            updateSelectedElement: true,
            newPolygonShape: selectedElement.primitiveShape,
            newPolygonOrientation: selectedElement.defaultHeading,
            newPolygonSizeA: selectedElement.sizes.a,
            newPolygonSizeB: selectedElement.sizes.b,
            newPolygonSizeH: selectedElement.sizes.h,
        });
    }

    render() {

        let sidebar = (
            <div className="side-with-shadow" style={{ position: 'absolute', background: '#ffffff', zIndex: 2, padding: 15, top: 25, left: 25, opacity: this.state.sidebarHideControls ? 0.1 : 1.0 }}>
                <Sidebar
                    t={this.props.t}
                    map={this.map}
                    maps={this.maps}
                    api={this.props.api}
                    utils={this.props.utils}
                    layers={this.props.layers}
                    mapTypeId={this.state.mapTypeId}
                    isDisabled={this.props.isDisabled}
                    adjustMap={(mode, value) => this.adjustMap(mode, value)}
                    mapRotation={this.state.mapRotation}
                    setMapTypeId={(e, id) => this.setMapTypeId(e, id)}
                    defaultCenter={this.props.defaultCenter}
                    fullAddress={this.props.fullAddress}
                    roofArea={this.state.roofArea}
                    pvArea={this.state.pvArea}
                    passiveArea={this.state.passiveArea}
                    passiveAreaExtra={this.state.passiveAreaExtra}
                    bipvArea={this.state.bipvArea}
                    pvPower={this.props.pvPower}
                    pvAmount={this.state.pvAmount}
                    roofType={this.props.roofType}
                    roofTypes={this.props.roofTypes}
                    offeredPvPower={this.state.offeredPvPower}
                    selectedElement={this.state.selectedElement}
                    selectedElementDimensions={this.state.selectedElementDimensions}
                    realAnnualAssumedEnergyProductionKwH={this.props.realAnnualAssumedEnergyProductionKwH}
                    setUpperState={this.props.setUpperState}
                    reCalcFinancing={() => this.reCalcFinancing()}
                    reFetchTokens={() => this.reFetchTokens()}
                    tokenRot={this.props.tokenRot}
                    tokenGronTeknik={this.props.tokenGronTeknik}
                    updateSelectedElement={(newProps, callback) => { this.updateSelectedElement(newProps, callback) }}
                    updateSelectedElementPath={(newPath, callback) => { this.updateSelectedElementPath(newPath, callback) }}
                    addPolygonPanels={(mode, e) => { this.addPolygonPanels(mode, e) }}
                    removePolygonPanels={(e) => this.removePolygonPanels(e)}
                    mapFitBounds={(e) => this.mapFitBounds(true)}
                    numberOfElements={this.elements.filter(itm => ['pvPanel', 'slope', 'obstacle'].includes(itm.elementCategory)).length}
                    editSelectedElement={() => { this.editSelectedElement() }}
                    pvWidth={this.props.pvWidth}
                    pvHeight={this.props.pvHeight}
                    pvThick={this.props.pvThick}
                    isRegion={this.props.isRegion}
                    setSidebarVisible={(val) => this.setState({ sidebarHideControls: val })}
                />
            </div>
        );

        let maps = this.state.mapStyles.length ? (
            <GoogleMapReact
                bootstrapURLKeys={{ id: 'script-maps-googleapis' }}
                defaultCenter={this.props.defaultCenter}
                defaultZoom={this.props.defaultZoom || 20}
                maxZoom={25}
                options={maps => ({
                    tilt: 0,
                    heading: 0,
                    mapTypeControl: false,
                    disableDefaultUI: true,
                    rotateControl: true,
                    zoomControl: true,
                    scaleControl: true,
                    streetViewControl: false,
                    fullscreenControl: false,
                    styles: this.state.mapStyles,
                })}

                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={this.handleGoogleMapApi}
            />
        ) : (<></>);

        let modalAddWhat = (
            <Modal
                show={this.state.showModalAddWhat}
                onHide={() => { this.setState({ showModalAddWhat: false }) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
                className="modal-shadow"
            >
                <Form method="post" onSubmit={(e) => { this.addPredefinedPolygon(e) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.calculation.pvDesigner.add')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={6} md={6} className="text-center">
                                <Button size="lg" variant="light" style={{ margin: '0 auto', background: "#f5f5f5", display: 'block' }} onClick={(e) => {
                                    this.setState({
                                        showModalAddWhat: false,
                                        showModalAddRoof: true
                                    })
                                }}>
                                    <img src={gable} alt="" style={{ height: 64 }} />
                                    <br />{this.props.t('page.calculation.pvDesigner.addWholeRoof')}
                                </Button>
                            </Col>
                            <Col sm={6} md={6} className="text-center">
                                <Button size="lg" variant="light" style={{ margin: '0 auto', background: "#f5f5f5", display: 'block' }} onClick={(e) => {
                                    this.setState({
                                        showModalAddWhat: false,
                                        showModalAddShape: true
                                    })
                                }}>
                                    <img src={rectangle} alt="" style={{ height: 64 }} />
                                    <br />{this.props.t('page.calculation.pvDesigner.addRoofSide')}
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Form>
            </Modal>
        );

        let selectedRoofProps = this.roofs.find(roof => roof.ident === this.state.newRoofShape);

        let modalAddRoof = (
            <Modal
                show={this.state.showModalAddRoof}
                onHide={() => { this.setState({ showModalAddRoof: false }) }}
                backdrop="static"
                keyboard={false}
                size="md"
                centered
                className="modal-shadow"
            >
                <Form method="post" onSubmit={(e) => { this.addPredefinedRoof(e) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.calculation.pvDesigner.addRoof')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={12} md={6}>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group as={Row} className="required">
                                            <Form.Label column sm={4}>
                                                {this.props.t('page.calculation.pvDesigner.roof')}
                                            </Form.Label>
                                            <Col sm={8}>
                                                <Form.Control
                                                    as="select"
                                                    custom
                                                    value={this.state.newRoofShape}
                                                    onChange={(e) => { this.setState({ newRoofShape: this.val(e) }) }}
                                                >
                                                    {
                                                        this.roofs.map(roof => (
                                                            <option key={`roof-${roof.ident}`} value={roof.ident}>
                                                                {roof.label}
                                                            </option>
                                                        ))
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group as={Row} className="required">
                                            <Form.Label column sm={4}>
                                                {this.props.t('page.calculation.pvDesigner.panels')}
                                            </Form.Label>
                                            <Col sm={8}>
                                                <Form.Control
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    required={true}
                                                    disabled={this.props.isLoading}
                                                    as="select"
                                                    custom
                                                    value={this.props.pvPower ? this.props.pvPower.toString() : ''}
                                                    data-value={this.props.pvPower ? this.props.pvPower.toString() : ''}
                                                    onChange={
                                                        (evt) => {
                                                            this.props.setUpperState(
                                                                { pvPower: this.parseFloatFixed(this.val(evt)) }
                                                            )
                                                        }
                                                    }
                                                >
                                                    <option disabled></option>
                                                    {
                                                        this.props.pvPowers && Array.isArray(this.props.pvPowers) && this.props.pvPowers.map(pwr => (
                                                            <option key={`pwr-${pwr}`} value={pwr.toString()}>
                                                                {pwr}
                                                            </option>
                                                        ))
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>

                                    {
                                        selectedRoofProps.useSize.includes('A') && (
                                            <Col sm={12}>
                                                <Form.Group as={Row} className="required">
                                                    <Form.Label column sm={4}>
                                                        A&nbsp;(m):
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            size="lg"
                                                            required={true}
                                                            step="0.1"
                                                            min="0.1"
                                                            max="500"
                                                            type="number"
                                                            value={this.state.newRoofSizeA || ''}
                                                            onChange={
                                                                (evt) => {
                                                                    this.setState({
                                                                        newRoofSizeA: this.valFloat(evt)
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        )
                                    }

                                    {
                                        selectedRoofProps.useSize.includes('B') && (
                                            <Col sm={12}>
                                                <Form.Group as={Row} className="required">
                                                    <Form.Label column sm={4}>
                                                        B&nbsp;(m):
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            size="lg"
                                                            required={true}
                                                            step="0.1"
                                                            min="0.1"
                                                            max="500"
                                                            type="number"
                                                            value={this.state.newRoofSizeB || ''}
                                                            onChange={
                                                                (evt) => {
                                                                    this.setState({
                                                                        newRoofSizeB: this.valFloat(evt)
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        )
                                    }

                                    {
                                        selectedRoofProps.useSize.includes('C') && (
                                            <Col sm={12}>
                                                <Form.Group as={Row} className="required">
                                                    <Form.Label column sm={4}>
                                                        C&nbsp;(m):
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            size="lg"
                                                            required={true}
                                                            step="0.1"
                                                            min="0"
                                                            max="500"
                                                            type="number"
                                                            value={this.state.newRoofSizeC}
                                                            onChange={
                                                                (evt) => {
                                                                    this.setState({
                                                                        newRoofSizeC: this.val(evt)
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        )
                                    }

                                    {
                                        selectedRoofProps.useSize.includes('D') && (
                                            <Col sm={12}>
                                                <Form.Group as={Row} className="required">
                                                    <Form.Label column sm={4}>
                                                        D&nbsp;(m):
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            size="lg"
                                                            required={true}
                                                            step="0.1"
                                                            min="0"
                                                            max="500"
                                                            type="number"
                                                            value={this.state.newRoofSizeC}
                                                            onChange={
                                                                (evt) => {
                                                                    this.setState({
                                                                        newRoofSizeC: this.val(evt)
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        )
                                    }

                                    {
                                        selectedRoofProps.useSize.includes('alfa') && (
                                            <Col sm={12}>
                                                <Form.Group as={Row}>
                                                    <Form.Label column sm={4}>
                                                        alfa&nbsp;(&deg;):
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            size="lg"
                                                            required={true}
                                                            step="0.1"
                                                            min="0"
                                                            max="90"
                                                            type="number"
                                                            value={this.state.newRoofSizeAlfa || ""}
                                                            onChange={
                                                                (evt) => {
                                                                    this.setState({
                                                                        newRoofSizeAlfa: this.valFloat(evt)
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        )
                                    }

                                    {
                                        selectedRoofProps.useSize.includes('beta') && (
                                            <Col sm={12}>
                                                <Form.Group as={Row} className="required">
                                                    <Form.Label column sm={4}>
                                                        beta&nbsp;(&deg;):
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            size="lg"
                                                            required={true}
                                                            step="0.1"
                                                            min="0"
                                                            max="90"
                                                            type="number"
                                                            value={this.state.newRoofSizeBeta || ""}
                                                            onChange={
                                                                (evt) => {
                                                                    this.setState({
                                                                        newRoofSizeBeta: this.valFloat(evt)
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        )
                                    }

                                </Row>
                            </Col>
                            <Col sm={12} md={6} className="overflow-hidden">
                                <img
                                    className="d-block"
                                    style={{ maxHeight: 240, maxWidth: '100%', margin: '0 auto', transition: 'all 0.2s ease', transform: `rotate(${this.state.newRoofOrientation}deg)` }}
                                    src={selectedRoofProps.src}
                                    alt=""
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="p-20 border-top">
                        <Button disabled={this.props.isLoading} variant="success" type="submit" onClick={(e) => { this.addPredefinedRoof(e) }}>
                            {this.props.t('page.calculation.pvDesigner.addRoofButton')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )

        let selectedShapeProps = this.shapes.find(shape => shape.ident === this.state.newPolygonShape);

        let modalAddShape = (
            <Modal
                show={this.state.showModalAddShape}
                onHide={() => { this.setState({ showModalAddShape: false, updateSelectedElement: false }) }}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
                className="modal-shadow"
            >
                <Form method="post" onSubmit={(e) => { this.addPredefinedPolygon(e) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.calculation.pvDesigner.addShape')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={12} md={6}>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group as={Row} className="required">
                                            <Form.Label column sm={4}>
                                                {this.props.t('page.calculation.pvDesigner.shape')}
                                            </Form.Label>
                                            <Col sm={8}>
                                                <Form.Control
                                                    as="select"
                                                    custom
                                                    value={this.state.newPolygonShape}
                                                    onChange={(e) => { this.setState({ newPolygonShape: this.val(e) }) }}
                                                >
                                                    {
                                                        this.shapes.map(shape => (
                                                            <option key={`shape-${shape.ident}`} value={shape.ident}>
                                                                {shape.ident}
                                                            </option>
                                                        ))
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>

                                    <Col sm={12}>
                                        <Form.Group as={Row} className="required">
                                            <Form.Label column sm={4}>
                                                {this.props.t('page.calculation.pvDesigner.orientation')}
                                            </Form.Label>
                                            <Col sm={8}>
                                                <Form.Control
                                                    as="select"
                                                    custom
                                                    value={this.state.newPolygonOrientation}
                                                    onChange={(e) => { this.setState({ newPolygonOrientation: this.val(e) }) }}
                                                >
                                                    {
                                                        Object.keys(orientation).map(o => {
                                                            return (
                                                                <option key={o} value={orientation[o]}>
                                                                    {this.props.t(`page.calculation.input.roofDirection.${o}`)}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>

                                    {
                                        selectedShapeProps.useSize.includes('a') && (
                                            <Col sm={12}>
                                                <Form.Group as={Row} className="required">
                                                    <Form.Label column sm={4}>
                                                        a&nbsp;(m):
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            size="lg"
                                                            required={true}
                                                            step="0.1"
                                                            min="0.1"
                                                            max="100"
                                                            type="number"
                                                            value={this.state.newPolygonSizeA || ""}
                                                            onChange={
                                                                (evt) => {
                                                                    this.setState({
                                                                        newPolygonSizeA: this.valFloat(evt)
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        )
                                    }

                                    {
                                        selectedShapeProps.useSize.includes('b') && (
                                            <Col sm={12}>
                                                <Form.Group as={Row} className="required">
                                                    <Form.Label column sm={4}>
                                                        b&nbsp;(m):
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            size="lg"
                                                            required={true}
                                                            step="0.1"
                                                            min="0.1"
                                                            max="100"
                                                            type="number"
                                                            value={this.state.newPolygonSizeB || ""}
                                                            onChange={
                                                                (evt) => {
                                                                    this.setState({
                                                                        newPolygonSizeB: this.valFloat(evt)
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        )
                                    }

                                    {
                                        selectedShapeProps.useSize.includes('h') && (
                                            <Col sm={12}>
                                                <Form.Group as={Row} className="required">
                                                    <Form.Label column sm={4}>
                                                        h&nbsp;(m):
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            size="lg"
                                                            required={true}
                                                            step="0.1"
                                                            min="0.1"
                                                            max="100"
                                                            type="number"
                                                            value={this.state.newPolygonSizeH || ""}
                                                            onChange={
                                                                (evt) => {
                                                                    this.setState({
                                                                        newPolygonSizeH: this.valFloat(evt)
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        )
                                    }

                                </Row>
                            </Col>
                            <Col sm={12} md={6} className="overflow-hidden">
                                <img
                                    className="d-block"
                                    style={{ maxHeight: 240, maxWidth: '100%', margin: '0 auto', transition: 'all 0.2s ease', transform: `rotate(${this.state.newPolygonOrientation}deg)` }}
                                    src={selectedShapeProps.src}
                                    alt=""
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="p-20 border-top">
                        <Button disabled={this.props.isLoading} variant="success" type="submit" onClick={(e) => { this.addPredefinedPolygon(e) }}>
                            {
                                this.state.updateSelectedElement ?
                                    this.props.t('common.button.save') :
                                    this.props.t('page.calculation.pvDesigner.addShapeButton')
                            }
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )

        let modalEavesRidgeVerge = (
            <Modal
                show={this.state.showModalRoofEdge}
                onHide={() => { this.setState({ showModalRoofEdge: false }) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
            >
                <Form method="post" onSubmit={(e) => { this.setSlopeEdge(e) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.calculation.pvDesigner.edit')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={12}>
                                <Form.Group className="required">
                                    <Form.Label>
                                        {this.props.t('page.calculation.pvDesigner.edgeType')}
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={this.state.selectedEdgeType}
                                        onChange={(e) => { this.setState({ selectedEdgeType: e.target.value }) }}
                                    >
                                        <option value="hip">Hip</option>
                                        <option value="eaves">Eaves</option>
                                        <option value="ridge">Ridge</option>
                                        <option value="verge">Verge</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="p-20 border-top">
                        <Button variant="success" type="submit" onClick={(e) => { this.setSlopeEdge(e) }}>
                            {this.props.t('page.calculation.pvDesigner.update')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )


        let addonStyle = '';
        if (this.state.mapHideControls) {
            addonStyle = (
                <style>
                    {
                        `
                        .gm-style-cc {
                            display: none !important;
                        }

                        .gm-style a[href^="https://maps.google.com/maps"]{
                            display: none !important;
                        }
                        `
                    }
                </style>
            );
        }

        if (this.props.enableDesigner) {
            return (
                <div style={{ position: 'relative', overflow: 'hidden', background: `#c6c4c7` }}>
                    {addonStyle}
                    {modalAddWhat}
                    {modalAddRoof}
                    {modalAddShape}
                    {modalEavesRidgeVerge}
                    {sidebar}
                    <div style={{ left: 0, top: 0, bottom: 0, right: 0, height: '82vh', width: '100%', position: 'relative' }}>
                        {maps}
                    </div>
                </div>
            );
        }

        return (
            <Row>
                <Col style={{ height: '50vh', width: '100%' }} className="pv-designer">
                    {maps}
                </Col>
            </Row>
        )

    }
}

export default Designer;