
import React from 'react';
import styled from '@emotion/styled';
import { borderRadius, grid } from './constants';
import colors from './colors';
import AutoBadge, { getBadgeColor } from '../auto-badge';

const getBackgroundColor = (
    isDragging,
    isGroupedOver,
    statusColors,
) => {
    if (isDragging) {
        return statusColors.soft;
    }

    if (isGroupedOver) {
        return colors.N30;
    }

    return colors.N0;
};

const getBorderColor = (isDragging, statusColors) =>
    isDragging ? statusColors.hard : 'transparent';

const imageSize = 40;

const CloneBadge = styled.div`
  background: ${colors.G100};
  bottom: ${grid / 2}px;
  border: 2px solid ${colors.G200};
  border-radius: 50%;
  box-sizing: border-box;
  font-size: 10px;
  position: absolute;
  right: -${imageSize / 3}px;
  top: -${imageSize / 3}px;
  transform: rotate(40deg);

  height: ${imageSize}px;
  width: ${imageSize}px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.a`
  text-align: left;
  overflow-y: scroll;
  border-radius: ${borderRadius}px;
  border: 2px solid transparent;
  border-color: ${(props) => getBorderColor(props.isDragging, props.colors)};
  background-color: ${(props) =>
        getBackgroundColor(props.isDragging, props.isGroupedOver, props.colors)};
  box-shadow: ${({ isDragging }) =>
        isDragging ? `2px 2px 1px ${colors.N70}` : 'none'};
  box-sizing: border-box;
  padding: 8px;
  min-height: ${imageSize}px;
  margin-bottom: ${grid}px;
  user-select: none;

  /* anchor overrides */
  color: ${colors.N900};

  &:hover,
  &:active {
    color: ${colors.N900};
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.colors.hard};
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`;

const Avatar = styled.img`
  width: ${imageSize}px;
  height: ${imageSize}px;
  border-radius: 50%;
  margin-right: ${grid}px;
  flex-shrink: 0;
  flex-grow: 0;
`;

const AvatarTxt = styled.span`
  width: ${imageSize}px;
  height: ${imageSize}px;
  line-height: ${imageSize - 2}px;
  border-radius: 50%;
  font-size: 16px;
  background-color: #fbfcfc;
  border: 1px solid #ced4da;
  display: block;
  margin-right: ${grid}px;
  flex-shrink: 0;
  flex-grow: 0;
  text-align:center;
`;

const Content = styled.div`
  /* flex child */
  flex-grow: 1;

  /*
    Needed to wrap text in ie11
    https://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
  */
  flex-basis: 100%;

  /* flex parent */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 3px 5px;
`;

const BlockQuote = styled.div`
  font-weight: bold;
  font-size: 13px;
`;

const MinHeightBlock = styled.span`
  display: block;
  min-height: 62px;
`;

const Footer = styled.div`
  display: flex;
  /* margin-top: ${grid}px; */
  margin-top: 5px;
  align-items: center;
`;

const Status = styled.small`
  display: inline-block;
  color: ${(props) => props.colors.hard};
  flex-grow: 0;
  margin: 0;
  background-color: ${(props) => props.colors.soft};
  border-radius: ${borderRadius}px;
  font-weight: normal;
  padding: 0px 5px;
  font-size: 11px;
`;

const AuthorId = styled.small`
  display: inline-block;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  font-weight: normal;
  text-overflow: ellipsis;
  text-align: center;
`;

const QuoteId = styled.small`
  display: inline-block;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  font-weight: normal;
  text-overflow: ellipsis;
  text-align: right;
  font-size: 11px;
  padding-top:2px;
`;

function getStyle(provided, style) {
    if (!style) {
        return provided.draggableProps.style;
    }

    return {
        ...provided.draggableProps.style,
        ...style,
    };
}

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
function QuoteItem(props) {
    const {
        quote,
        isDragging,
        isGroupedOver,
        provided,
        style,
        isClone,
        index,
    } = props;

    const [textColor, bgColor, bgLightColor, textLightColor] = getBadgeColor(quote.hashtags || '#blank');

    return (
        <Container
            isDragging={isDragging}
            isGroupedOver={isGroupedOver}
            isClone={isClone}
            colors={quote.author.colors}
            ref={provided.innerRef}
            onClick={(e) => { e.preventDefault(); window.dispatchEvent(new CustomEvent('QUOTE_ITEM_CLICK', { 'detail': JSON.stringify(quote) }), true); }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle(provided, style)}
            data-is-dragging={isDragging}
            data-testid={quote.id}
            data-index={index}
        >
            {
                /*
                quote.author.avatarTxt ? (
                    <AvatarTxt>{quote.author.avatarTxt}</AvatarTxt>
                ) : (
                    <Avatar src={quote.author.avatarUrl} alt={quote.author.name} />
                )
                */
            }
            {isClone ? <CloneBadge>Clone</CloneBadge> : null}
            <Content style={{ background: `${bgLightColor}`, color: `${textLightColor}` }}>
                <BlockQuote>
                    <AuthorId>{quote.author.name}</AuthorId>
                    {quote.hashtags && <AutoBadge text={quote.hashtags} divClassName={'d-inline float-right'} inline={true} />}
                    <MinHeightBlock>{quote.content}</MinHeightBlock>
                </BlockQuote>
                <Footer>
                    <Status colors={quote.status.colors}>{quote.status.label}</Status>
                    <QuoteId className='float-right'>{quote.ident}☀</QuoteId>
                </Footer>
            </Content>
        </Container>
    );
}

export default React.memo(QuoteItem);