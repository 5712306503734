import { Card, Col, Form, Row } from "react-bootstrap";
import BaseComponent from "../../components/component/base";
import FinancingBasic from "./financing-basic";

class FinancingDE extends BaseComponent {

    render() {

        return (
            <>
                <Row>
                    <Col sm={12}>
                        <Card className="offer-financing">
                            <Card.Body>
                                <Row>

                                    <FinancingBasic {...this.props} showSwitch={true} />

                                    <Col sm={12} md={6} lg={6} className="align-self-end">
                                        <Form.Group className="optional">
                                            <Form.Label>
                                                {this.props.t('page.calculation.financing.estSelfConsumption')}
                                            </Form.Label>
                                            <Form.Control
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={false}
                                                min={0}
                                                max={9999999}
                                                disabled={this.props.isLoading || this.props.closed}
                                                readOnly={false}
                                                placeholder="..."
                                                type="number"
                                                step="0.01"
                                                value={this.props.estSelfConsumption || "0"}
                                                onChange={
                                                    (evt) => {
                                                        this.props.setUpperState(
                                                            {
                                                                estSelfConsumption: this.valFloat(evt),
                                                            }
                                                        )
                                                    }
                                                }
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col sm={12} md={6} lg={6} className="align-self-end">
                                        <Form.Group className="optional">
                                            <Form.Label>
                                                {this.props.t('page.calculation.financing.estSelfSufficiency')}
                                            </Form.Label>
                                            <Form.Control
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={false}
                                                min={0}
                                                max={9999999}
                                                disabled={this.props.isLoading || this.props.closed}
                                                readOnly={false}
                                                placeholder="..."
                                                type="number"
                                                step="0.01"
                                                value={this.props.estSelfSufficiency || "0"}
                                                onChange={
                                                    (evt) => {
                                                        this.props.setUpperState(
                                                            {
                                                                estSelfSufficiency: this.valFloat(evt),
                                                            }
                                                        )
                                                    }
                                                }
                                            />
                                        </Form.Group>
                                    </Col>

                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default FinancingDE;