import './style.scss';
import React, { Component } from 'react';
import Content from './content';

class AuthLoginPage extends Component {
    render() {
        return (
            <div className="rui-main">
                <div className="rui-sign align-items-center justify-content-center">
                    <Content {...this.props} />
                </div>
            </div>
        );
    }
}

export default AuthLoginPage;
