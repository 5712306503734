import React, { Component } from 'react';
import TextEditor from '../../components/text-editor';

class Comment extends Component {

    render() {

        const { isLoading, comment } = this.props.upperState;
        const { onChange } = this.props;

        return (
            <TextEditor
                required={false}
                disabled={isLoading}
                rows={3}
                value={comment || ""}
                onChange={(e) => { onChange(e) }}
            />
        );
    }
}
export default Comment;