import React, { Fragment } from 'react';
import { Button, Card, Col, Form, ListGroup, Modal, Row, Tab, Tabs } from "react-bootstrap";
import BaseComponent from '../../components/component/base';
import Dnd from '../../components/dnd';
import Icon from '../../components/icon';
import { IsLoading } from '../../components/spinner';
import getProto from '../../proto';

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            showItemModal: false,
            selectedItem: null,
            activeTab1: "milestones",
            checklist: [],
            peopleOfProject: [
                'client',
                'projectpm', 'projectsm', 'projectbipvdesigner', 'projectcostcontroller',
                'projectelectricaldesigner', 'projectconstructionengineer', 'projectpmomanager',
                'projectlogisticsmanager', 'projectdesignmanager'
            ],
        };
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.setState({ isLoading: true }, () => {
            this.props.api.post(
                `/admin/describe-operations`,
                {},
                (dataObject) => {
                    this.afterFetchDataSuccess(dataObject);
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        })
    }

    updateChecklist() {
        this.setState({ isLoading: true }, () => {
            this.props.api.post(
                `/admin/update-partial-operations`,
                {
                    fields: ['checklist'],
                    checklist: this.state.checklist,
                },
                (dataObject) => {
                    this.afterFetchDataSuccess(dataObject);
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        })
    }

    afterFetchDataSuccess(dataObject, callback) {
        if (typeof callback !== "function") {
            callback = () => { }
        }
        this.setState({
            isLoading: false,
            checklist: dataObject.checklist
        }, callback);
    }

    afterFetchError(errorObject) {
        this.setState({ isLoading: false }, () => {
            this.props.showToast({
                errorObject: errorObject,
                title: this.props.t('common.toast.error'),
                color: 'danger'
            });
        })
    }

    addNewItem(e) {
        const parentuuid = this.state.checklist.find(itm => itm.parentuuid === null)?.uuid;
        if (parentuuid) {
            const newItem = getProto('checklistItem', { parentuuid: parentuuid, notify: [], position: this.state.checklist.length + 1 });
            const newChecklist = [...this.state.checklist, { ...newItem }];
            this.setState({
                checklist: newChecklist,
                selectedItem: null,
            })
        }
    }

    changeSelectedItem(e) {
        const itmId = e.target.id;
        const val = this.val(e);
        const isChecked = e.target.checked;
        let newSelectedItem = { ...this.state.selectedItem };
        if (itmId.startsWith('ms-title')) {
            newSelectedItem.title = val;
        }
        if (itmId.startsWith('ms-tip')) {
            newSelectedItem.tip = val;
        }
        if (itmId.startsWith('ms-link')) {
            newSelectedItem.link = val;
        }
        if (itmId.startsWith('ms-notify')) {
            if (!Array.isArray(newSelectedItem.notify)) {
                newSelectedItem.notify = [];
            }
            const contains = newSelectedItem.notify.find(itm => itm === val);
            if (contains) {
                newSelectedItem.notify = newSelectedItem.notify.filter(itm => itm !== val);
            } else {
                newSelectedItem.notify.push(val);
            }
        }
        if (itmId.startsWith('ms-owners')) {
            if (!Array.isArray(newSelectedItem.owners)) {
                newSelectedItem.owners = [];
            }
            const contains = newSelectedItem.owners.find(itm => itm === val);
            if (contains) {
                newSelectedItem.owners = newSelectedItem.owners.filter(itm => itm !== val);
            } else {
                newSelectedItem.owners.push(val);
            }
        }
        if (itmId.startsWith('ms-for-client')) {
            newSelectedItem.client = isChecked;
        }
        if (itmId.startsWith('ms-trash')) {
            newSelectedItem.trash = isChecked;
        }
        this.setState({ selectedItem: newSelectedItem }, () => {
            const newChecklist = this.state.checklist.map(itm => {
                return itm.uuid === newSelectedItem.uuid ? { ...newSelectedItem } : { ...itm };
            });
            if (itmId.startsWith('ms-submit')) {
                this.setState({
                    checklist: newChecklist,
                    showItemModal: false,
                    selectedItem: null
                })
            } else {
                this.setState({
                    checklist: newChecklist,
                })
            }
        });
    }

    render() {
        const {
            isLoading,
            activeTab1,
            checklist,
            peopleOfProject,
            showItemModal,
            selectedItem,
        } = this.state;

        const loadingView = (
            <IsLoading />
        );

        const checklistItemModal = showItemModal && selectedItem && (
            <Modal
                show={showItemModal}
                onHide={() => { this.setState({ showItemModal: false, selectedItem: null }) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedItem.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={6}>
                            <Form.Label>
                                {this.props.t('page.admin.operations.milestoneTitle')}
                            </Form.Label>
                            <Form.Control
                                disabled={isLoading}
                                required
                                type="text"
                                placeholder={this.props.t('common.placeholder.any')}
                                value={selectedItem.title || ''}
                                id="ms-title"
                                onChange={(e) => { this.changeSelectedItem(e) }}
                            />
                        </Col>
                        <Col xs={6}>
                            <Form.Label>
                                {this.props.t('page.admin.operations.milestoneTip')}
                            </Form.Label>
                            <Form.Control
                                disabled={isLoading}
                                required
                                type="text"
                                placeholder={this.props.t('common.placeholder.any')}
                                value={selectedItem.tip || ''}
                                id="ms-tip"
                                onChange={(e) => { this.changeSelectedItem(e) }}
                            />
                        </Col>
                    </Row>

                    <Row className='mt-5'>
                        <Col xs={12}>
                            <Form.Label>
                                {this.props.t('page.admin.operations.milestoneLink')}
                            </Form.Label>
                            <Form.Control
                                disabled={isLoading}
                                type="text"
                                placeholder={this.props.t('common.placeholder.any')}
                                value={selectedItem.link || ''}
                                id="ms-link"
                                onChange={(e) => { this.changeSelectedItem(e) }}
                            />
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col sm={12}>
                            <Form.Label>
                                {this.props.t('page.admin.operations.milestoneFor')}
                            </Form.Label>
                        </Col>
                        {
                            peopleOfProject.filter(person => person !== 'client').map(person => (
                                <Col key={`ms-owners-${person}`} sm={4}>
                                    <Form.Check
                                        id={`ms-owners-${person}`}
                                        type="checkbox"
                                        disabled={isLoading}
                                        checked={(selectedItem.owners || []).includes(person)}
                                        value={person}
                                        onChange={(e) => { this.changeSelectedItem(e) }}
                                        label={this.props.t(`page.scheduler.modal.projectDetails.tabs.details.${person}`).replace(':', '')}
                                    />
                                </Col>
                            ))
                        }
                    </Row>

                    <hr />

                    <Row>
                        <Col sm={12}>
                            <Form.Label>
                                {this.props.t('page.admin.operations.milestoneNotify')}
                            </Form.Label>
                        </Col>
                        {
                            peopleOfProject.map(person => (
                                <Col key={`ms-notify-${person}`} sm={4}>
                                    <Form.Check
                                        id={`ms-notify-${person}`}
                                        type="checkbox"
                                        disabled={isLoading}
                                        checked={(selectedItem.notify || []).includes(person)}
                                        value={person}
                                        onChange={(e) => { this.changeSelectedItem(e) }}
                                        label={this.props.t(`page.scheduler.modal.projectDetails.tabs.details.${person}`).replace(':', '')}
                                    />
                                </Col>
                            ))
                        }
                    </Row>

                    <hr />

                    <Row>
                        <Col sm={12}>
                            <Form.Label>
                                {this.props.t('page.admin.operations.milestoneAddons')}
                            </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Check
                                id={`ms-for-client`}
                                type="checkbox"
                                disabled={isLoading}
                                checked={selectedItem.client || false}
                                value="1"
                                onChange={(e) => { this.changeSelectedItem(e) }}
                                label={this.props.t('page.admin.operations.milestoneForClient')}
                            />
                        </Col>
                        <Col sm={4}>
                            <Form.Check
                                id={`ms-trash`}
                                type="checkbox"
                                disabled={isLoading}
                                checked={selectedItem.trash || false}
                                value="1"
                                onChange={(e) => { this.changeSelectedItem(e) }}
                                label={this.props.t('page.admin.operations.milestoneTrash')}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="p-20 border-top">
                    <Button id="ms-submit" variant="info" onClick={(e) => { e.preventDefault(); this.changeSelectedItem(e) }}>
                        {this.props.t('common.button.set')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        const dataView = (
            <Fragment>
                {checklistItemModal}
                <Card>
                    <Card.Body>
                        <Tabs
                            activeKey={activeTab1}
                            onSelect={(k) => this.setState({ activeTab1: k })}
                            className="border-bottom nicebar"
                        >
                            <Tab eventKey="milestones" title={this.props.t('page.admin.operations.tabMilestones')}>
                                <Row>
                                    <Col md={12} className='p-0'>
                                        <ListGroup variant="flush">
                                            <Dnd
                                                items={checklist.filter(itm => itm.parentuuid).sort((a, b) => a.position - b.position)}
                                                onUpdate={(items) => {
                                                    this.setState({ checklist: items });
                                                }}
                                                droppableId={`droppable-checklist`}
                                                renderItem={(itm, index, itmDragProps) => {
                                                    return (
                                                        <ListGroup.Item key={itm.uuid} className="px-0 py-5 m-0" style={{ whiteSpace: "nowrap", padding: 0 }}>
                                                            <Row>
                                                                <Col xs={12} style={{ display: 'flex' }}>
                                                                    <span
                                                                        style={{ cursor: "move", marginRight: 15 }}
                                                                        {...itmDragProps}
                                                                    >
                                                                        <Icon name="move" style={{ marginBottom: 2 }} />
                                                                    </span>
                                                                    <span>
                                                                        {itm.title}
                                                                    </span>
                                                                    <button
                                                                        className='btn-fake-link btn-icon-link px-5 mx-5'
                                                                        style={{
                                                                            fontSize: 12,
                                                                            backgroundColor: '#ffe9a2',
                                                                            color: '#333',
                                                                            borderRadius: 5,
                                                                            fontWeight: '600'
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.setState({ selectedItem: itm, showItemModal: true });
                                                                        }}
                                                                    >
                                                                        <Icon name="edit" size={12} />
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                        </ListGroup.Item>
                                                    )
                                                }}
                                            />
                                        </ListGroup>
                                    </Col>
                                    <Col sm={12}>
                                        <button
                                            className='btn-fake-link btn-icon-link px-5 py-4 mx-5'
                                            style={{
                                                fontSize: 14,
                                                backgroundColor: '#ffe9a2',
                                                color: '#333',
                                                borderRadius: 5,
                                                fontWeight: '600'
                                            }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.addNewItem(e);
                                            }}
                                        >
                                            <Icon name="plus" size={14} />
                                        </button>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Card.Body>
                    <Card.Footer className="p-20 border-top sticky-bottom">
                        <Button disabled={isLoading} variant="warning" onClick={(e) => { e.preventDefault(); this.updateChecklist(); }}>
                            {this.props.t('common.button.save')}
                        </Button>
                    </Card.Footer>
                </Card>
            </Fragment>
        );

        return isLoading ? loadingView : dataView;
    }
}

export default Content
