import React, { Component } from 'react';
import classnames from 'classnames/dedupe';
import { Container, Breadcrumb } from 'react-bootstrap';
import Dropdown from '../bs-dropdown';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class PageTitle extends Component {

    constructor(props) {
        super(props);
        this.prepareCrumbs = this.prepareCrumbs.bind(this);
    }

    prepareCrumbs(crumbs) {
        return Object.keys(crumbs).map((url) => {
            return (
                <li className="breadcrumb-item" key={url + crumbs[url]}>
                    <Link to={url || '#'}>
                        {crumbs[url]}
                    </Link>
                </li>
            );
        });
    }

    render() {
        const {
            className,
            children,
            breadcrumbs,
            meta,
            t,
            style,
            metaOnly
        } = this.props;

        return metaOnly ? (
            <Helmet>
                <title>{t(`meta.title`, { title: metaOnly?.title ? metaOnly.title + ' - ' : '' })}</title>
            </Helmet>
        ) : (
            <div className={classnames('rui-page-title', className)} style={{ ...style || {} }}>

                <Helmet>
                    <title>{t(`meta.title`, { title: meta?.title ? meta.title + ' - ' : '' })}</title>
                </Helmet>

                <Container fluid>
                    {breadcrumbs && Object.keys(breadcrumbs).length ? (
                        <ol className="breadcrumb">
                            {this.prepareCrumbs(breadcrumbs)}
                        </ol>
                    ) : ''}
                    {children}
                </Container>
            </div>
        );
    }
}

export default PageTitle
