import React from 'react';
import Icon from '../../components/icon';
import { Row, Col, Spinner, Dropdown, Form } from 'react-bootstrap';
import BaseComponent from '../../components/component/base';
import TextEditor from '../../components/text-editor';
import { appEnv, appEnvCurrent } from '../../constants';
import { Paperclip } from 'react-feather';

class Discussion extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSaving: false,
            isEditorVisible: false,
            commentuuid: '',
            commentcontent: '',
            comments: [],
            user: props.getUser(),
            anchors: props.anchors,
            mainpage: props.mainpage,
            subpage: props.subpage,
            filter: props.filter,
        }
        this.commentsSectionRef = React.createRef();
        this.editorSectionRef = React.createRef();
        this.pingTimeout = null;
        this.ping = this.ping.bind(this);
        this.fetchComments = this.fetchComments.bind(this);
        this.scrollComments = this.scrollComments.bind(this);
    }

    componentDidMount() {
        const commentuuid = this.props.utils.getLastHashElement();
        this.fetchComments(() => { commentuuid && this.scrollComments(commentuuid, true) }, { alwaysScrollToBottom: true });
        this.ping();
    }

    componentWillUnmount() {
        if (this.pingTimeout) {
            clearTimeout(this.pingTimeout);
        }
    }

    ping() {
        if (this.pingTimeout) {
            clearTimeout(this.pingTimeout);
        }
        let wrapper = () => {
            this.fetchComments(() => { }, true);
            this.ping();
        }
        this.pingTimeout = setTimeout(() => { wrapper() }, 15 * 1000); // every X seconds
    }

    fetchComments(callback, params) {
        this.setState({ isLoading: false }, () => {
            this.props.api.postSilent(
                `/comment/list`,
                {
                    "anchors": this.state.anchors
                },
                (data) => {
                    const { filter } = this.state;
                    let comments = data.comments || [];
                    let newCallback = () => { };
                    const lastCount = this.state.comments.length;
                    const newCount = comments.length;
                    if (filter) {
                        comments = comments.filter(filter)
                    }
                    if (lastCount !== newCount || params.alwaysScrollToBottom) {
                        newCallback = () => {
                            if (comments.length > 0) {
                                const lastuuid = comments[comments.length - 1].comment?.uuid;
                                this.scrollComments(lastuuid, true);
                            }
                            callback();
                        }
                    }
                    this.setState({ isLoading: false, isSaving: false, comments: comments }, newCallback)
                }
            );
        })
    }

    scrollComments(uuid, highlight) {
        const { comments } = this.state;
        const uuidpassed = uuid ? true : false;
        const lastcommentuuid = comments[comments.length - 1]?.comment?.uuid;
        const theuuid = uuid || (this.props.stopAutoScroll ? null : lastcommentuuid);
        const theelement = document.getElementById(theuuid);
        if (!theelement) {
            return;
        }
        if (uuidpassed && highlight) {
            theelement.classList.add('highlight');
        }
        theelement.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }

    saveComment() {
        const { commentuuid, commentcontent } = this.state;
        const linkprefix = this.getLinkPrefix();
        const hascommentuuid = commentuuid ? true : false;
        this.setState({ isSaving: true }, () => {
            this.props.api.post(
                hascommentuuid ? `/comment/update` : `/comment/create`,
                {
                    "comment": {
                        "uuid": commentuuid,
                        "content": commentcontent,
                    },
                    "notify": {
                        "url": linkprefix,
                    },
                    "anchors": this.state.anchors
                },
                (data) => {
                    this.setState({ isSaving: false, commentuuid: '', commentcontent: '', isEditorVisible: false }, () => {
                        this.props.api.dispatchEvent('UPDATE_EDITOR', { editorId: "discussion-editor", content: '' });
                        this.fetchComments(() => { this.scrollComments(commentuuid) });
                        if (!hascommentuuid) {
                            if ([appEnv.prod].includes(appEnvCurrent)) {
                                // only for test flight, skip.
                            } else {
                                // DISABLED PERMANENTLY
                                // this.props.api.postSilent(`/comment/task-risk-scoring`, { comment: { uuid: data.comment?.uuid } });
                            }
                        }
                    })
                },
                (errorObject) => {
                    this.props.showToast({
                        errorObject: errorObject,
                        title: this.props.t('common.toast.error'),
                        color: 'danger'
                    });
                }
            );
        })
    }

    trashComment(commentuuid) {
        this.setState({ isSaving: true }, () => {
            this.props.api.post(
                `/comment/trash`,
                {
                    "comment": {
                        "uuids": [commentuuid],
                    },
                    "anchors": this.state.anchors
                },
                (data) => {
                    this.fetchComments();
                },
                (errorObject) => {
                    this.props.showToast({
                        errorObject: errorObject,
                        title: this.props.t('common.toast.error'),
                        color: 'danger'
                    });
                }
            );
        })
    }

    getLinkPrefix() {
        const origin = window.origin;
        const { anchors, mainpage, subpage } = this.state;
        let theLinkPrefix = '';
        if (mainpage) {
            if (anchors.task) {
                theLinkPrefix = `/${mainpage}/${anchors.task.uuid}/#/discussion/`
            } else if (anchors.project) {
                theLinkPrefix = `/${mainpage}/${anchors.project.uuid}/#/discussion/`
            } else if (anchors.offer) {
                theLinkPrefix = `/${mainpage}/${anchors.offer.uuid}/#/tab-comments/`
            }
        } else if (subpage) {
            if (anchors.task) {
                theLinkPrefix = `/${subpage}/#/task/${anchors.task.uuid}/discussion/`
            } else if (anchors.project) {
                theLinkPrefix = `/${subpage}/#/project/${anchors.project.uuid}/discussion/`
            }
        }
        return origin + this.props.buildLink(theLinkPrefix);
    }

    showLinkPopup(commentuuid) {
        const theLink = this.getLinkPrefix() + commentuuid;
        window.prompt(this.props.t('common.toast.shareLink'), theLink);
    }

    getCommentHeader(commentObj) {
        const files = commentObj.files?.filter(Boolean) || [];
        const avatarFile = files ? files.find(itm => itm.ident === 'avatar') : null;
        const attachedFiles = files ? files.filter(itm => itm.ident === 'comment') : [];
        const theAvatar = avatarFile ? (
            <img src={avatarFile.link} alt="" />
        ) : (
            <span>
                {this.props.utils.getInitials(commentObj.user?.firstName, commentObj.user?.lastName)}
            </span>
        );
        const theLink = commentObj.comment?.uuid ? <button className='btn btn-link p-0' onClick={(e) => { e.preventDefault(); this.showLinkPopup(commentObj.comment?.uuid) }}>&#10697;</button> : null;
        return (
            <div className="media media-show">
                <div className="media-link">
                    <span className="media-img">
                        {theAvatar}
                    </span>
                    <span className="media-body">
                        <span className="media-title">
                            {`${commentObj.user?.firstName} ${commentObj.user?.lastName}`}
                            {
                                commentObj.user?.hasClientRole ? (
                                    <span className="badge badge-success">CLIENT</span>
                                ) : (
                                    <span className="badge badge-warning">SUNROOF</span>
                                )
                            }
                        </span>
                        <small className="media-subtitle">
                            {theLink}
                            {` `}
                            {commentObj.comment.headline}
                            {commentObj.comment.created ? this.props.t(`component.discussion.created`) + ` ` + commentObj.comment.created + ` ` : ''}
                            {commentObj.comment.updated ? this.props.t(`component.discussion.updated`) + ` ` + commentObj.comment.updated + ` ` : ''}
                            {attachedFiles && (
                                <ul className='list-unstyled'>
                                    {attachedFiles.map(file => (
                                        <li key={file.uuid}>
                                            <a href={file.link} target="_blank" rel="noopener noreferrer">
                                                <Paperclip size={12} /> {file.filename}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </small>
                    </span>
                </div>
                {
                    commentObj.comment.editable && !this.state.readOnly && !this.props.disableEdit && (
                        <>
                            <Dropdown tag="div" className="media-icon">
                                <Dropdown.Toggle className="btn dropdown-item media-icon text-center p-10">
                                    <Icon name="more-vertical" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu tag="ul" className="dropdown-menu dropdown-menu-right" align="left">
                                    <li>
                                        <button
                                            className="btn btn-block"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    commentuuid: commentObj.comment.uuid,
                                                    commentcontent: commentObj.comment.content,
                                                    isEditorVisible: true,
                                                }, () => {
                                                    this.props.api.dispatchEvent('UPDATE_EDITOR', { editorId: "discussion-editor", content: commentObj.comment.content });
                                                });
                                            }}
                                        >
                                            {this.props.t(`common.button.edit`)}
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className="btn btn-block text-danger"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.trashComment(commentObj.comment.uuid);
                                            }}
                                        >
                                            {this.props.t(`common.button.remove`)}
                                        </button>
                                    </li>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    )
                }
            </div>
        )
    }

    getComment(commentObj) {
        return (
            <div className="rui-task-comment" id={commentObj.comment.uuid} key={commentObj.comment.uuid}>
                {this.getCommentHeader(commentObj)}
                <div className="rui-task-comment-text">
                    <div dangerouslySetInnerHTML={{ __html: commentObj.comment.content }} />
                </div>
            </div>
        )
    }

    render() {
        const {
            comments,
            commentcontent,
            commentuuid,
            isLoading,
            isSaving,
            isEditorVisible,
            user,
        } = this.state;

        const {
            readOnly
        } = this.props;

        const commentcontentIsEmpty = !this.props.utils.getTextFromHtml(commentcontent);

        const loadingView = (
            <Row>
                <Col sm={12} className="text-center p-20">
                    <Spinner animation="border" />
                </Col>
            </Row>
        )

        const commentsEditor = !readOnly && (
            <>
                <div className="rui-task-comment bg-light mt-20" ref={this.editorSectionRef}>
                    {this.getCommentHeader({
                        comment: {
                            headline: commentuuid ?
                                this.props.t(`component.discussion.edit`) :
                                isEditorVisible ?
                                    this.props.t(`component.discussion.new`) :
                                    <button className='btn text-success p-0' onClick={() => this.setState({ isEditorVisible: true })}>+ {this.props.t(`component.discussion.new`)}</button>,
                        },
                        user: user.user,
                        files: [user.avatar]
                    })}
                    {
                        isEditorVisible && (
                            <>
                                <div className="rui-task-comment-text">
                                    <TextEditor
                                        api={this.props.api}
                                        mentions={true}
                                        editorId="discussion-editor"
                                        disabled={isLoading || isSaving}
                                        value={commentcontent || ''}
                                        simpleToolbar={true}
                                        config={{ autoGrow_minHeight: 100 }}
                                        onChange={
                                            (html) => {
                                                this.setState(
                                                    { commentcontent: html }
                                                )
                                            }
                                        }
                                    />
                                </div>
                                <Row>
                                    <Col xs={6}>
                                        <button
                                            type="button"
                                            className={"btn pr-0 pl-0 my-10 mx-0 text-success"}
                                            disabled={isLoading || isSaving || commentcontentIsEmpty}
                                            onClick={(e) => { e.preventDefault(); this.saveComment(); }}
                                        >
                                            <Icon name="send" className="mr-5" />
                                            {this.props.t('common.button.send')}
                                        </button>
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        <button
                                            type="button"
                                            className={"btn pr-0 pl-0 my-5 mx-0"}
                                            disabled={isLoading || isSaving}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    commentuuid: '',
                                                    commentcontent: '',
                                                    isEditorVisible: false,
                                                }, () => {
                                                    this.props.api.dispatchEvent('UPDATE_EDITOR', { editorId: "discussion-editor", content: '' });
                                                    this.scrollComments();
                                                });
                                            }}
                                        >
                                            <Icon name="x" />
                                        </button>
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                </div>
            </>
        )

        const commentsEmpty = (
            <>
                <div className="text-center p-25" style={{ border: `1px solid #dee2e6` }}>
                    <Icon name="book-open" style={{ width: 64, height: 64, color: "#e6ecf0" }} />
                </div>
            </>
        )

        const commentsView = (
            <>
                {
                    comments.length > 0 ? (
                        <div style={this.props.divStyle || { maxHeight: '60vh', overflowY: 'scroll' }} className="pb-10" ref={this.commentsSectionRef}>
                            {comments.map(comment => this.getComment(comment))}
                        </div>
                    ) : commentsEmpty
                }

                {commentsEditor}
            </>
        )

        return isLoading ? loadingView : commentsView;
    }

}

export default Discussion;