import React, { Component } from 'react';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import Content from './content';

class MaintenancePage extends Component {
    render() {
        return (
            <PageWrap>
                <PageTitle
                    meta={{ title: "" }}
                    t={this.props.t}
                >
                    <h1>503</h1>
                </PageTitle>
                <PageContent>
                    <Content {...this.props} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default MaintenancePage;
