import React, { Fragment } from 'react';
import BaseComponent from '../../components/component/base';
import { Row, Col, Modal } from 'react-bootstrap';
import FilterProjects, { restoreFilters } from '../Scheduler/filter-projects';
import WidgetTableOfProjects from './widget-table-of-projects';
import ModalAnnounce from '../../components/modal-announce/sundesk';
import ModalAnnounceGoodJob from '../../components/modal-announce/goodjob';

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            filters: {},
            modalFilters: false,
        };

        this.fetchData = this.fetchData.bind(this);
        this.showFilters = this.showFilters.bind(this);
    }

    componentDidMount() {
        window.addEventListener("FETCH_OPERATIONS", this.fetchData, false);
        window.addEventListener("ADJUST_OPERATIONS", this.showFilters, false);
        this.fetchFilters();
    }

    componentWillUnmount() {
        window.removeEventListener('FETCH_OPERATIONS', this.fetchData, false);
        window.removeEventListener('ADJUST_OPERATIONS', this.showFilters, false);
    }

    fetchFilters(callback) {
        const restored = restoreFilters();
        this.setState({ filters: restored, isLoading: false }, callback)
    }

    showFilters() {
        this.setState({ modalFilters: true })
    }

    fetchData() {
        this.fetchFilters(() => {
            this.setState({ isLoading: true }, () => {
                this.setState({ isLoading: false })
            });
        });
    }

    render() {

        const {
            isLoading,
            filters,
        } = this.state;

        const filtersView = (
            <FilterProjects
                t={this.props.t}
                api={this.props.api}
                showToast={this.props.showToast}
                tab={'basic'}
                onRestore={(filters) => { this.setState({ filters: filters }, () => this.fetchData()) }}
                onSubmit={(filters) => { this.setState({ filters: filters, modalFilters: false }, () => this.fetchData()) }}
            />
        )

        const viewFilters = (
            <Modal
                show={this.state.modalFilters}
                onHide={() => { this.setState({ modalFilters: false }) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.t('common.string.adjust')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {filtersView}
                </Modal.Body>
            </Modal>
        )

        const allProps = {
            isLoading: isLoading,
            filters: filters,
            fetchData: () => { this.fetchData() },
            ...this.props,
        };

        return (
            <Fragment>
                {viewFilters}

                {/*
                    <ModalAnnounce
                        api={this.props.api}
                        what="sundesk"
                        size="sm"
                        till="2023-02-13"
                        times={3}
                    />

                <h2>{this.props.t('page.operations.welcome')}</h2>

                */}

                <ModalAnnounceGoodJob
                    {...this.props}
                    api={this.props.api}
                    what="goodjob"
                    size="sm"
                />

                <Row>
                    <Col sm={12}>
                        <WidgetTableOfProjects {...allProps} />
                    </Col>
                </Row>
            </Fragment >
        );
    }
}

export default Content
