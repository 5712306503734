import React, { Component } from 'react';
import { appVersionCurrent } from '../../constants';

class AppVersion extends Component {
    render() {
        return (
            <>
                ⚡ powered by <a target="_blank" rel="rel noopener noreferrer" href="https://sunroof.se">SunRoof</a> // version {appVersionCurrent}
            </>
        )
    }
}
export default AppVersion;