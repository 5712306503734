import BaseComponent from './base';

class LowerComponent extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {}
    }

    setUpperState(newState, callback) {
        if (typeof callback !== "function") {
            callback = () => { }
        }
        this.props.setUpperState(newState, callback);
    }

    onChangeSetUpperState(key, e, explicit) {
        this.props.setUpperState({ [key]: explicit ? e : this.val(e) });
    }

}

export default LowerComponent;