/**
 * External Dependencies
 */
import React from 'react';
import * as FeatherIcons from 'react-feather';
import * as changeCase from 'change-case';

/**
 * Component
 *
 * @param {Object} props component props.
 * @return {Object} component.
 */
const Icon = (props) => {
    const {
        vendor = 'feather',
    } = props;

    let {
        name,
    } = props;

    let result = '';

    const opts = {
        ...props,
    };
    delete opts.vendor;


    if (opts.ccc) {
        opts.className = `${(opts.className || '')}`;
    } else {
        opts.className = `${(opts.className || '')} rui-icon`;
    }

    // Feather.
    if ('feather' === vendor) {
        name = changeCase.pascalCase(name);

        if (!FeatherIcons[name]) {
            name = 'AlertTriangle';
        }

        const RenderIcon = FeatherIcons[name];

        result = <RenderIcon {...opts} strokeWidth="1.5" />;
    }

    return result;
};

export default Icon;
