import React from 'react';
import { Row, Col, Form, Alert } from "react-bootstrap";
import LowerComponent from '../../components/component/lower';

class CustomFields extends LowerComponent {

    render() {

        const {
            isLoading,
            closed,
            fields,
            setUpperState,
            validator
        } = this.props;

        return (
            <Row>
                {
                    fields.map(field => (
                        <Col key={`field-${field.stateKey}`} sm={12} md={6} lg={6}>
                            <Form.Group className={field.required ? `required` : ``}>
                                <Form.Label>
                                    {field.label}
                                </Form.Label>
                                {
                                    field.inputType === 'select' ? (
                                        <Form.Control
                                            disabled={isLoading || closed}
                                            placeholder=""
                                            required={field.required}
                                            value={field.value}
                                            as="select"
                                            custom
                                            onChange={event => {
                                                if (field.useRawValue) {
                                                    setUpperState({ [field.stateKey]: event.target.value });
                                                } else {
                                                    setUpperState({ [field.stateKey]: [{ ident: event.target.value }] });
                                                }
                                                if (
                                                    validator(
                                                        field.required,
                                                        event.target.value,
                                                        field.validator
                                                    )
                                                ) {
                                                    event.target.setCustomValidity("");
                                                } else {
                                                    event.target.setCustomValidity(this.props.t('common.tooltip.invalid'));
                                                }
                                            }}
                                        >
                                            <option value=""></option>
                                            {
                                                field.inputType === 'select' && field.values.map(itm => {
                                                    return (
                                                        <option key={itm.value} value={itm.value}>{itm.label}</option>
                                                    )
                                                })
                                            }
                                        </Form.Control>
                                    ) : (
                                        <Form.Control
                                            disabled={isLoading || closed}
                                            type={field.inputType}
                                            placeholder=""
                                            required={field.required}
                                            value={field.value}
                                            onChange={event => {
                                                setUpperState({ [field.stateKey]: event.target.value });
                                                if (
                                                    validator(
                                                        field.required,
                                                        event.target.value,
                                                        field.validator
                                                    )
                                                ) {
                                                    event.target.setCustomValidity("");
                                                } else {
                                                    event.target.setCustomValidity(this.props.t('common.tooltip.invalid'));
                                                }
                                            }}
                                            {...field.options || {}}
                                        />
                                    )
                                }
                                {
                                    field.stateKey === 'fuse' && this.parseFloatFixed(field.value?.split('|')[0]) > 25 && (
                                        <Alert variant='danger'>
                                            {this.props.t('common.tooltip.checkWithOperations')}
                                        </Alert>
                                    )
                                }
                            </Form.Group>
                        </Col>
                    ))
                }
            </Row>
        );
    }
}
export default CustomFields;