import React, { Fragment } from 'react';
import { Row, Col, Card, Form } from "react-bootstrap";
import LowerElementsComponent from '../../components/component/lower-elements';
import OfferBasic from './offer-basic';
import OfferSummary from './offer-summary';
import OfferSupplier from './offer-supplier';

class Offer extends LowerElementsComponent {

    constructor(props) {
        super(props);
        this.state = {
            offerSupplierHidden: this.props.hasPermission(['VIEW_OFFER_MY_COMPANY_MARGIN_HIDE_BUTTON']).any
        };
    }

    render() {

        const {
            isLoading,
            closed
        } = this.props;

        return (
            <Fragment>

                <Fragment>
                    <h2>
                        {this.props.t('page.calculation.offer.title')}

                        <div className="float-md-right clear-section-container">

                            {
                                this.props.hasPermission(['VIEW_OFFER_REFRESH_PRODUCTS_BUTTON']).any && (
                                    <button
                                        type="button"
                                        className="btn text-danger mr-10 px-0"
                                        disabled={isLoading || closed}
                                        onClick={(e) => { this.props.clearSection(e, ['offer', 'offer-elements', 'offer-elements-reload'], { word: 'confirm' }) }}
                                    >
                                        {this.props.t(`page.calculation.offer.reloadOfferElements`)}
                                    </button>
                                )
                            }

                            {
                                this.props.hasPermission(['VIEW_OFFER_CLEAR_SECTION_BUTTON']).any && (
                                    <button
                                        type="button"
                                        className="btn text-danger px-0"
                                        disabled={isLoading || closed}
                                        onClick={(e) => { this.props.clearSection(e, ['offer', 'offer-elements']) }}
                                    >
                                        {this.props.t(`common.button.clear`)}
                                    </button>
                                )
                            }
                        </div>

                    </h2>

                    <Row className="mb-30">
                        <Col sm={12}>
                            <OfferBasic {...this.props} />
                        </Col>
                    </Row>
                </Fragment>

                <Fragment>
                    <h2>
                        {this.props.t('page.calculation.offer.summary.title')}
                        {
                            this.props.hasPermission(['VIEW_OFFER_MY_COMPANY_MARGIN_HIDE_BUTTON']).any && (
                                <div className="float-right pt-5">
                                    <Form.Check
                                        type="switch"
                                        id="offerSupplierHidden"
                                        label={``}
                                        checked={!this.state.offerSupplierHidden || false}
                                        onChange={(e) => { this.setState({ offerSupplierHidden: !this.state.offerSupplierHidden }) }}
                                    />
                                </div>
                            )
                        }
                    </h2>
                    <Row className="mb-30">
                        <Col sm={12}>
                            <Card style={{ borderTop: 0 }}>
                                <Card.Body className="p-0">
                                    <OfferSummary {...this.props} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Fragment>

                {
                    this.props.hasPermission(['VIEW_OFFER_MY_COMPANY_MARGIN']).any && !this.state.offerSupplierHidden &&
                    (
                        <Fragment>
                            <h2>
                                {this.props.t('page.calculation.offer.supplier.title')}
                            </h2>
                            <Row className="mb-30">
                                <Col sm={12}>
                                    <Card style={{ borderTop: 0 }}>
                                        <Card.Body className="p-0">
                                            <OfferSupplier {...this.props} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Fragment>
                    )
                }

            </Fragment>

        );
    }
}
export default Offer;