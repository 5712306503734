import "./style.scss";
import React, { Component } from "react";
import Select from 'react-select';

class SelectSync extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.value || "",
            options: []
        };
    }

    render() {

        const {
            t,
            value,
            placeholder,
            disabled,
            isSearchable,
            isClearable,
            isMulti,
            cache,
            options,
            onChange
        } = this.props;

        const {
            inputValue
        } = this.state;

        let resolvedValue = null;
        let stringValue = String(value || '').toLowerCase();

        if (options) {
            if (typeof value === "object") {
                resolvedValue = value;
            } else {
                if (Array.isArray(options) && options[0]?.options) {
                    options.forEach(
                        itm => {
                            if (!resolvedValue) {
                                resolvedValue = itm.options?.find(child => String(child.value || '').toLowerCase() === stringValue)
                            }
                        });
                } else {
                    if (Array.isArray(options)) {
                        resolvedValue = options.find(itm => String(itm.value || '').toLowerCase() === stringValue)
                    }
                }
            }
        }

        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        };
        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        };

        const formatGroupLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );

        return (
            <>
                <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder={placeholder || "..."}
                    noOptionsMessage={() => inputValue ? t('common.select.emptyResults') : t('common.select.emptyPhrase')}
                    loadingMessage={() => t('common.select.loading')}
                    isDisabled={disabled}
                    isSearchable={isSearchable || true}
                    isClearable={isClearable || false}
                    isMulti={isMulti || false}
                    cacheOptions={cache || false}
                    options={options || []}
                    value={resolvedValue}
                    formatGroupLabel={formatGroupLabel}
                    onInputChange={(val) => { this.setState({ inputValue: val }); return val; }}
                    onChange={(value) => { onChange(value); }}
                />
            </>
        );
    }
}

export default SelectSync;