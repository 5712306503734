import React from 'react';
import BaseComponent from '../../components/component/base';
import SimpleMap from '../../components/maps/simple';
import { kanbanTerminal, mapCenter, mapperMarker } from '../../constants';
import FilterProjects from '../Scheduler/filter-projects';
import { Modal } from 'react-bootstrap';
import ModalProject from '../Scheduler/modal-project';
import { restoreFilters } from '../Scheduler/filter-projects';

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            markers: [],
            modalProject: false,
            filters: {},
            modalFilters: false,
            modalData: {}
        };

        this.showFilters = this.showFilters.bind(this);
        this.hashChange = this.hashChange.bind(this);
    }

    componentDidMount() {
        this.fetchFilters(() => {
            this.fetchProjectsLocations();
            this.parsePathAndOpenModal();
        });
        window.addEventListener("ADJUST_MAPPER", this.showFilters, false);
        window.addEventListener('hashchange', this.hashChange, false);
    }

    componentWillUnmount() {
        window.removeEventListener('ADJUST_MAPPER', this.showFilters, false);
        window.addEventListener('hashchange', this.hashChange, false);
    }

    hashChange() {
        if (!this.state.modalProject) {
            this.parsePathAndOpenModal();
        }
    }

    fetchFilters(callback) {
        const restored = restoreFilters();
        this.setState({ filters: restored }, callback)
    }

    showFilters() {
        this.setState({ modalFilters: true })
    }

    parsePathAndOpenModal() {
        const particles = this.props.location.hash.replace('#', '').split('/').filter(Boolean);
        if (particles.length < 3) {
            return;
        }
        const path = particles[0];
        const uuid = particles[1];
        const view = particles[2];
        const subuuid = particles[3] || '';
        switch (path) {
            case 'project':
                this.showModal(null, 'modalProject', { projectuuid: uuid, uri: `/mapper/#/project/${uuid}/${view}/${subuuid}`, activeTab: view })
                break;

            default:
                break;
        }
    }

    showModal(e, modal, modalData) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (modalData.uri) {
            this.props.openLink(modalData.uri, '_hash');
        }
        this.setState({ [modal]: true, modalData: modalData })
    }

    hideModal(modal, reloadData) {
        this.props.openLink(`/mapper/`, '_hash');
        this.setState({
            [modal]: false,
            modalData: {}
        }, () => {
            if (reloadData) {
                this.fetchProjectsLocations();
            }
        });
    }

    getMarker(status) {
        return mapperMarker[status || 'pending'];
    }

    fetchProjectsLocations() {
        const { filters } = this.state;
        if (_.isEmpty(filters)) {
            return;
        }
        this.props.api.post(
            `/project/list-location`,
            {
                filter: filters,
                page: 0,
                pageSize: 2048
            },
            (data) => {
                let markers = [];
                let info = '';
                data.projects.forEach(item => {
                    if ((!item.address.geoLat && !item.address.geoLng)
                        || (parseInt(item.address.geoLat, 10) === 0 && parseInt(item.address.geoLng, 10) === 0)
                    ) {
                        item.address.geoLat = 55.3205708 + Math.min(0.1, Math.random());
                        item.address.geoLng = 17.3403783 + Math.min(0.1, Math.random());
                        info = '[empty lat long!]'
                    } else {
                        info = '';
                    }
                    const link = this.props.buildLink(`/mapper/#/project/${item.project.uuid}/details`);
                    markers.push({
                        lat: this.parseFloatFixed(item.address.geoLat),
                        lng: this.parseFloatFixed(item.address.geoLng),
                        title: `<a href="${link}" class='fs-16'>${item.project.label} (${item.address.addressName})</a></a>`,
                        content: `<div><div class="my-5"><strong>${item.project.mapMarker || 'pending'}${` `}${info}</strong></div><div class="bg-light border p-10 mt-10 p-infobox">${(item.project.description || '').replace('<p></p>', '') || '---'}</div></div>`,
                        icon: this.getMarker(item.project.mapMarker)
                    })
                });
                this.setState({ markers: markers, isLoading: false })
            },
            (errorObject) => {
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: 'danger'
                });
            }
        );
    }

    render() {
        const {
            isLoading,
            modalProject,
            modalData
        } = this.state;

        const filtersView = (
            <FilterProjects
                t={this.props.t}
                api={this.props.api}
                showToast={this.props.showToast}
                tab={'basic'}
                onRestore={(filters) => { this.setState({ filters: filters, }, () => this.fetchProjectsLocations()) }}
                onSubmit={(filters) => { this.setState({ filters: filters, modalFilters: false }, () => this.fetchProjectsLocations()) }}
            />
        )

        const viewFilters = (
            <Modal
                show={this.state.modalFilters}
                onHide={() => { this.setState({ modalFilters: false }) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.t('common.string.adjust')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {filtersView}
                </Modal.Body>
            </Modal>
        )

        const viewMap = !isLoading && (
            <SimpleMap
                defaultZoom={5}
                fullAddress={``}
                defaultCenter={mapCenter[this.props.getRegionIdent()]}
                markers={this.state.markers}
                height={`calc(100vh - 70px)`}
            />
        )

        const viewModal = !isLoading && modalProject && (
            <ModalProject
                t={this.props.t}
                api={this.props.api}
                utils={this.props.utils}
                openLink={this.props.openLink}
                getUser={this.props.getUser}
                location={this.props.location}
                showToast={this.props.showToast}
                getRegionIdent={this.props.getRegionIdent}
                isRegion={this.props.isRegion}
                showModal={modalProject}
                modalTitle={this.props.t(`page.scheduler.modal.projectDetails.title`)}
                onModalHide={(reloadData) => this.hideModal('modalProject', reloadData)}
                onAfterSubmit={(reloadData, hideModal) => hideModal && this.hideModal('modalProject', true)}
                data={modalData}
                activeTab={modalData.activeTab}
                buildLink={this.props.buildLink}
                hasPermission={this.props.hasPermission}
            />
        )

        return (
            <>
                {viewFilters}
                {viewModal}
                {viewMap}
            </>
        )
    }
}

export default Content
