import React from 'react';
import { DateTime } from "luxon";
import { Form, Button, Col, Tabs, Tab, Table, Spinner } from 'react-bootstrap';
import BaseComponent from '../../components/component/base';
import SelectAsync from '../../components/select/async';
import { defaultCols } from '../Operations/widget-table-of-projects';

export const defaultFilters = {
    calSince: DateTime.now().startOf('month').toISODate(),
    calTill: DateTime.now().endOf('month').toISODate(),
    since: null,
    till: null,
    contractSignedSince: null,
    contractSignedTill: null,
    types: null,
    statuses: null,
    milestoneStatus: '',
    pms: null,
    teams: null,
    view: 'days',
    mode: 'simple',
    default: true,
    dataCols: [],
};

export function restoreFilters() {
    let restoredObj = JSON.parse(window.localStorage.getItem("bp_scheduler_filter")) || {};
    let newFilters = { ...defaultFilters, ...restoredObj };
    delete newFilters.dataCols;
    // console.log({ ...restoredObj })
    return newFilters;
};

export function storeFilters(toStringify) {
    // console.log({ ...toStringify })
    window.localStorage.setItem("bp_scheduler_filter", JSON.stringify(toStringify));
}

class FilterProjects extends BaseComponent {

    constructor(props) {
        super(props)
        this.state = {
            ...defaultFilters,
            isLoading: false,
            activeTab1: this.props.tab || 'basic',
        }
    }

    componentDidMount() {
        this.localRestoreFilters(() => {
            this.reFetchColumns(() => { });
        });
    }

    reFetchColumns(callback) {
        this.setState({
            isLoading: true
        }, () => {
            this.props.api.get(
                `/project/table-columns`,
                (data) => {
                    let dataCols = defaultCols(this.props.t);
                    data.columns.filter(Boolean).forEach(columnName => {
                        if (columnName.startsWith('checklist_')) {
                            let accessor = columnName; // this.props.api.hashCode(columnName.normalize('NFC'), 'cl_');
                            let checklistColumnName = columnName.replace('checklist___', '').replace('checklist_', '');
                            if (checklistColumnName.includes('#date')) {
                                checklistColumnName = `📅 ${checklistColumnName.replace('(#date)', '')}`;
                            } else {
                                checklistColumnName = `☑️ ${checklistColumnName}`;
                            }
                            dataCols.push({
                                Header: checklistColumnName,
                                accessor: accessor
                            })
                        }
                    })
                    this.setState({ dataCols, isLoading: false }, callback);
                }
            );
        })

    }

    localStoreFilters(callback, keepDefault, removeHidden) {
        const oldFilters = { ...this.state };
        let newFilters = {
            ...this.state,
            since: oldFilters.since ? DateTime.fromISO(oldFilters.since) : null,
            till: oldFilters.till ? DateTime.fromISO(oldFilters.till) : null,
            calSince: oldFilters.calSince ? DateTime.fromISO(oldFilters.calSince) : null,
            calTill: oldFilters.calTill ? DateTime.fromISO(oldFilters.calTill) : null,
        };
        if (!newFilters.calSince || !newFilters.calTill) {
            return this.props.showToast({
                title: this.props.t('page.scheduler.filters.checkDates'),
                color: 'danger'
            });
        }
        if (newFilters.since && newFilters.till && (newFilters.since > newFilters.till)) {
            return this.props.showToast({
                title: this.props.t('page.scheduler.filters.checkDates'),
                color: 'danger'
            });
        }
        this.setState({
            since: newFilters.since?.toISODate(),
            till: newFilters.till?.toISODate(),
            calSince: newFilters.calSince?.toISODate(),
            calTill: newFilters.calTill?.toISODate(),
            types: newFilters.types,
            statuses: newFilters.statuses,
            milestoneStatus: newFilters.milestoneStatus,
            pms: newFilters.pms,
            teams: newFilters.teams,
            view: newFilters.view,
            mode: newFilters.mode,
            default: keepDefault ? true : false,
        }, () => {
            let toStringify = { ...this.state };
            delete toStringify.dataCols;
            delete toStringify.isLoading;
            delete toStringify.activeTab1;
            if (removeHidden) {
                for (let k in toStringify) {
                    if (k.endsWith('_hidden')) {
                        delete toStringify[k];
                    }
                }
            }
            storeFilters(toStringify);
            this.props.api.dispatchEvent('FILTERS_CHANGED', {});
            callback(newFilters);
        })
    }

    localRestoreFilters(callback) {
        const newFilters = restoreFilters();
        this.setState(newFilters, callback);
    }

    resetFilters() {
        this.setState({ ...defaultFilters }, () => {
            let toStringify = { ...this.state };
            delete toStringify.dataCols;
            delete toStringify.isLoading;
            delete toStringify.activeTab1;
            for (let k in toStringify) {
                if (k.endsWith('_hidden')) {
                    delete toStringify[k];
                }
            }
            storeFilters(toStringify);
            this.props.api.dispatchEvent('FILTERS_CHANGED', {});
            this.props.onSubmit({ ...toStringify });
        });
    }

    onSubmit(e, keepDefault) {
        if (e) {
            e.preventDefault();
        }
        this.localStoreFilters((newFilters) => {
            let newFiltersAdjusted = { ...newFilters };
            this.props.onSubmit(newFiltersAdjusted);
        }, keepDefault)
    }

    render() {

        return (
            <Form onSubmit={(e) => { this.onSubmit(e) }}>
                <Tabs
                    className="m-0 nicebar"
                    id="filtertabs"
                    defaultActiveKey={`basic`}
                    activeKey={this.state.activeTab1}
                    onSelect={(k) => { this.setState({ activeTab1: k }) }}
                >
                    <Tab eventKey="basic" title={this.props.t('page.scheduler.filters.tab.basic')}>
                        <Form.Row className="align-items-start">
                            <Form.Group as={Col} sm={12} lg={6}>
                                <Form.Label>{this.props.t('page.scheduler.filters.since')}</Form.Label>
                                <Form.Control
                                    disabled={this.props.isLoading}
                                    type="date"
                                    value={this.state.since || ''}
                                    onChange={(e) => { this.onChangeSetState('since', e) }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={6}>
                                <Form.Label>{this.props.t('page.scheduler.filters.till')}</Form.Label>
                                <Form.Control
                                    disabled={this.props.isLoading}
                                    type="date"
                                    value={this.state.till || ''}
                                    onChange={(e) => { this.onChangeSetState('till', e) }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={6}>
                                <Form.Label>{this.props.t('page.scheduler.filters.contractSignedSince')}</Form.Label>
                                <Form.Control
                                    disabled={this.props.isLoading}
                                    type="date"
                                    value={this.state.contractSignedSince || ''}
                                    onChange={(e) => { this.onChangeSetState('contractSignedSince', e) }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={6}>
                                <Form.Label>{this.props.t('page.scheduler.filters.contractSignedTill')}</Form.Label>
                                <Form.Control
                                    disabled={this.props.isLoading}
                                    type="date"
                                    value={this.state.contractSignedTill || ''}
                                    onChange={(e) => { this.onChangeSetState('contractSignedTill', e) }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={6}>
                                <Form.Label>{this.props.t('page.scheduler.filters.type')}</Form.Label>
                                <SelectAsync
                                    t={this.props.t}
                                    api={this.props.api}
                                    isClearable={true}
                                    isMulti={true}
                                    cache={true}
                                    preload={true}
                                    disabled={this.props.isLoading}
                                    endpoint={`/project/list-type`}
                                    value={this.state.types || []}
                                    params={[]}
                                    appendData={[{ value: '<empty/>', label: this.props.t('common.text.emptyValue') }]}
                                    transformData={(data, callback) => {
                                        let newData = data.types.map(itm => {
                                            return { ...itm, value: itm.type, label: itm.type }
                                        });
                                        callback(newData)
                                    }}
                                    onChange={(data) => {
                                        this.setState({ types: data });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={6}>
                                <Form.Label>{this.props.t('page.scheduler.filters.pm')}</Form.Label>
                                <SelectAsync
                                    t={this.props.t}
                                    api={this.props.api}
                                    isClearable={true}
                                    isMulti={true}
                                    cache={true}
                                    preload={true}
                                    disabled={this.props.isLoading}
                                    endpoint={`/user/list-partial-operations`}
                                    value={this.state.pms}
                                    params={{ include: this.state.pms ? [this.state.pms.uuid] : [] }}
                                    transformData={(data, callback) => {
                                        let newData = data.users.map(itm => { return { ...itm, value: itm.uuid } });
                                        newData.unshift({ value: '<empty/>', uuid: '<empty/>', label: this.props.t('common.text.emptyValue') });
                                        callback(newData)
                                    }}
                                    onChange={(data) => {
                                        this.setState({ pms: data });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={6}>
                                <Form.Label>{this.props.t('page.scheduler.filters.team')}</Form.Label>
                                <SelectAsync
                                    t={this.props.t}
                                    api={this.props.api}
                                    isClearable={true}
                                    isMulti={true}
                                    cache={true}
                                    preload={true}
                                    disabled={this.props.isLoading}
                                    endpoint={`/team/list`}
                                    value={this.state.teams}
                                    params={{ include: this.state.teams ? [this.state.teams.uuid] : [] }}
                                    appendData={[{ value: '<empty/>', label: this.props.t('common.text.emptyValue') }]}
                                    transformData={(data, callback) => {
                                        let newData = data.teams.map(itm => { return { ...itm, value: itm.uuid } });
                                        callback(newData)
                                    }}
                                    onChange={(data) => {
                                        this.setState({ teams: data });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={6}>
                                <Form.Label>{this.props.t('page.scheduler.filters.milestone')}</Form.Label>
                                <SelectAsync
                                    t={this.props.t}
                                    api={this.props.api}
                                    isClearable={true}
                                    isMulti={true}
                                    cache={true}
                                    preload={true}
                                    disabled={this.props.isLoading}
                                    endpoint={`/project/list-status`}
                                    value={this.state.statuses || []}
                                    params={[]}
                                    appendData={[{ value: '<empty/>', label: this.props.t('common.text.emptyValue') }]}
                                    transformData={(data, callback) => {
                                        let newData = data.statuses.map(itm => {
                                            let lbl = itm.label || itm.status;
                                            if (['todo', 'doing', 'done'].includes(lbl)) {
                                                lbl = this.props.t(`common.kanban.${lbl}`)
                                            }
                                            return { ...itm, value: itm.status, label: lbl }
                                        });
                                        callback(newData)
                                    }}
                                    onChange={(data) => {
                                        this.setState({ statuses: data });
                                    }}
                                />
                            </Form.Group>
                            {
                                this.state.statuses?.length > 0 && (
                                    <Form.Group as={Col} sm={12} lg={6}>
                                        <Form.Label>{this.props.t('page.scheduler.filters.milestoneStatus')}</Form.Label>
                                        <Form.Control
                                            disabled={this.props.isLoading}
                                            as="select"
                                            custom
                                            value={this.state.milestoneStatus || ''}
                                            onChange={(e) => { this.onChangeSetState('milestoneStatus', e) }}
                                        >
                                            <option value=""></option>
                                            {['na', 'todo', 'doing', 'done'].map(milestoneStatus => (
                                                <option key={this.props.t(`common.milestoneStatus.${milestoneStatus}`)} value={this.props.t(`common.milestoneStatus.${milestoneStatus}`)}>{this.props.t(`common.milestoneStatus.${milestoneStatus}`)}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                )
                            }
                        </Form.Row>
                    </Tab>

                    <Tab eventKey="scheduler" title={this.props.t('page.scheduler.filters.tab.scheduler')}>
                        <Form.Row className="align-items-start">
                            <Form.Group as={Col} sm={12} lg={6}>
                                <Form.Label>{this.props.t('page.scheduler.filters.calSince')}</Form.Label>
                                <Form.Control
                                    disabled={this.props.isLoading}
                                    type="date"
                                    value={this.state.calSince || ''}
                                    onChange={(e) => { this.onChangeSetState('calSince', e) }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={6}>
                                <Form.Label>{this.props.t('page.scheduler.filters.calTill')}</Form.Label>
                                <Form.Control
                                    disabled={this.props.isLoading}
                                    type="date"
                                    value={this.state.calTill || ''}
                                    onChange={(e) => { this.onChangeSetState('calTill', e) }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={6}>
                                <Form.Label>{this.props.t('page.scheduler.filters.view')}</Form.Label>
                                <Form.Control
                                    disabled={this.props.isLoading}
                                    as="select"
                                    custom
                                    value={this.state.view || ''}
                                    onChange={(e) => { this.onChangeSetState('view', e) }}
                                >
                                    {['days', 'weeks', 'months'].map(view => (
                                        <option key={view} value={view}>{this.props.t(`common.view.${view}`)}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={6}>
                                <Form.Label>{this.props.t('page.scheduler.filters.mode')}</Form.Label>
                                <Form.Control
                                    disabled={this.props.isLoading}
                                    as="select"
                                    custom
                                    value={this.state.mode || ''}
                                    onChange={(e) => { this.onChangeSetState('mode', e) }}
                                >
                                    {['simple', 'advanced'].map(mode => (
                                        <option key={mode} value={mode}>{this.props.t(`common.mode.${mode}`)}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                    </Tab>

                    <Tab eventKey="dashboard" title={this.props.t('page.scheduler.filters.tab.dashboard')}>
                        <Table striped bordered hover responsive="md" size="sm" className="sticky-thead align-middle" style={{ zIndex: 999 }}>
                            <thead>
                                <tr className='align-middle'>
                                    <th className='align-middle'>{this.props.t('page.scheduler.filters.dashboard.label')}</th>
                                    <th className='align-middle' style={{ width: 60, zIndex: 99 }}>{this.props.t('page.scheduler.filters.dashboard.hidden')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.isLoading ? (
                                        <tr>
                                            <td colSpan={3}>
                                                <div className="text-center p-30">
                                                    <Spinner animation="border" />
                                                </div>
                                            </td>
                                        </tr>
                                    ) : this.state.dataCols.map(itm => {
                                        const hiddenKey = `dashboard_${itm.accessor}_hidden`;
                                        const widthKey = `dashboard_${itm.accessor}_width`;
                                        return (
                                            <tr key={itm.accessor}>
                                                <td className='align-middle'>
                                                    {itm.Header}
                                                </td>
                                                <td className='align-middle'>
                                                    <Form.Check
                                                        type="switch"
                                                        id={hiddenKey}
                                                        checked={this.getConf(this.state, hiddenKey, false)}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                [hiddenKey]: !this.getConf(this.state, hiddenKey, false),
                                                            })
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Tab>
                </Tabs>

                <Form.Row className="align-items-center border-top px-25 py-25 mt-20 bg-white" style={{ marginLeft: -30, marginRight: -30, position: 'sticky', bottom: 0 }}>
                    <Form.Group as={Col} xs={6} sm={6} className="mb-0">
                        <Button variant="primary" type="submit">
                            {this.props.t('page.scheduler.filters.apply')}
                        </Button>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} sm={6} className="mb-0 text-right">
                        <Button variant="link" type="button" style={{ padding: 0 }} onClick={(e) => { e.preventDefault(); this.resetFilters() }}>
                            {this.props.t('page.scheduler.filters.reset')}
                        </Button>
                    </Form.Group>
                </Form.Row>

            </Form>
        )
    }

}

export default FilterProjects;