import React, { Component, Fragment } from 'react';
import classnames from 'classnames/dedupe';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import logo from '../../assets/images/logo.svg';
import AppVersion from '../../components/app-version';

class Content extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emailAddress: '',
            password: '',
            otp: '',
            authError: '',
            loading: false,
            otpRequired: false
        };
    }

    maybeSubmit(e) {
        e.preventDefault();
        if (this.state.loading) {
            return;
        }
        const data = {
            user: {
                emailAddress: this.state.emailAddress,
                password: this.state.password
            },
            token: {
                otp: this.state.otp
            }
        };
        if (this.state.otpRequired) {
            return this.checkCredentials(data);
        }
        return this.checkOtpRequired(data);
    }

    getAuthErrorString(errorObject) {
        let authErrorString = '';
        switch (errorObject.data?.error?.ident) {
            case 'error_user_not_found':
                authErrorString = this.props.t('page.auth.userNotFound');
                break;
            case 'error_user_wrong_password':
                authErrorString = this.props.t('page.auth.wrongPassword');
                break;
            case 'error_user_not_activated':
                authErrorString = this.props.t('page.auth.userNotActivated');
                break;
            case 'error_wrong_otp':
                authErrorString = this.props.t('page.auth.wrongCode');
                break;
            default:
                authErrorString = JSON.stringify(errorObject.data);
                break;
        }
        return authErrorString;
    }

    checkOtpRequired(data) {
        this.setState({ loading: true, authError: '' }, () => {
            this.props.api.post(
                `/auth/pre-login`,
                data,
                (loginData) => {
                    const otpRequired = loginData.user.otpRequired;
                    this.setState({ otpRequired, loading: false }, () => {
                        if (!otpRequired) {
                            this.checkCredentials(data)
                        }
                    })
                },
                (errorObject) => {
                    this.setState({
                        loading: false,
                        authError: this.getAuthErrorString(errorObject)
                    })
                }
            )
        });
    }

    checkCredentials(data) {
        this.setState({ loading: true, authError: '' }, () => {
            this.props.api.post(
                `/auth/login`,
                data,
                (tokenData) => {
                    this.props.setToken(tokenData.token.uuid, false);
                    window.location.href = this.props.buildLink(this.props.getStarterLink(tokenData));
                },
                (errorObject) => {
                    this.setState({
                        loading: false,
                        authError: this.getAuthErrorString(errorObject)
                    })
                }
            )
        });
    }

    render() {
        return (
            <Fragment>
                <div className="bg-image">
                    <div className="bg-grey-1" />
                </div>
                <form method="post" onSubmit={(e) => this.maybeSubmit(e)} className="form rui-sign-form rui-sign-form-cloud">
                    <div className="row vertical-gap sm-gap justify-content-center">
                        <div className="col-12">
                            <h1 className="display-4 mb-10 text-center p-25">
                                <img src={logo} alt="Sundesk" className="img-fluid" />
                            </h1>
                        </div>
                        {
                            this.state.otpRequired && (
                                <div className="col-12">
                                    <div className="alert alert-info" role="alert">
                                        {this.props.t('page.auth.authCodeSent')}
                                    </div>
                                </div>
                            )
                        }
                        {
                            this.state.authError && (
                                <div className="col-12">
                                    <div className="alert alert-warning" role="alert">
                                        {this.state.authError}
                                    </div>
                                </div>
                            )
                        }
                        <div className="col-12">
                            <input
                                type="email"
                                required
                                className={classnames('form-control')}
                                placeholder={this.props.t('page.auth.email')}
                                value={this.state.emailAddress}
                                onChange={event =>
                                    this.setState({ emailAddress: event.target.value })
                                }
                                disabled={this.state.loading || this.state.otpRequired}
                                autoComplete="username"
                            />
                        </div>
                        <div className="col-12">
                            <input
                                type="password"
                                required
                                className={classnames('form-control')}
                                placeholder={this.props.t('page.auth.password')}
                                value={this.state.password}
                                onChange={event =>
                                    this.setState({ password: event.target.value })
                                }
                                disabled={this.state.loading || this.state.otpRequired}
                                autoComplete="current-password"
                            />
                        </div>
                        {
                            this.state.otpRequired && (
                                <>
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            required
                                            className={classnames('form-control')}
                                            placeholder={this.props.t('page.auth.authCode')}
                                            value={this.state.otp}
                                            onChange={event =>
                                                this.setState({ otp: event.target.value })
                                            }
                                            disabled={this.state.loading}
                                            autoComplete="one-time-code"
                                        />
                                    </div>
                                </>
                            )
                        }
                        <div className="col-12">
                            <button
                                className="btn btn-brand btn-block text-center"
                                disabled={this.state.loading}
                                type="submit"
                            >
                                {this.props.t('page.auth.login')}
                                {
                                    this.state.loading &&
                                    (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )
                                }
                            </button>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <Link to={this.props.buildLink('/auth/reset/')} className="fs-13">
                                    {this.props.t('page.auth.forget')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="mt-20">
                    <p className="mb-0 text-secondary">
                        <small>
                            <AppVersion />
                        </small>
                    </p>
                </div>
            </Fragment>
        );
    }
}

export default Content
