import React, { Fragment } from 'react';
import { Row, Col, Card, Modal, Form, Button, Alert } from "react-bootstrap";
import DropzoneWrapper from '../../components/dropzone';
import Icon from '../../components/icon';
import LowerElementsComponent from '../../components/component/lower-elements';
import ReactTooltip from "react-tooltip";
import FilesTable from './files-table';
import ModalFillForm from './modal-fill-form';
import { acceptFileType, appEnv, appEnvCurrent, offerSigningConstrutorNamePoland } from '../../constants';

class Files extends LowerElementsComponent {

    constructor(props) {
        super(props);
        this.state = {
            modalOfferParams: false,
            modalBudgetParams: false,
            modalAgreementParams: false,
            modalFillFormParams: false,
            budgetDisclaimer: false,
            agreementArticleUuid: "",
            agreementArticles: [],
            agreementOfferUuid: "",
            representative: "",
            secretPersonalId: "",
            secretPersonalLocation: "",
            secretCompanyLocation: "",
            offerAttachment0Uuid: "",
            offerAttachment0Link: "",
            offerNotes: "",
            hidePrices: false,
            hideDiscounts: false,
            hideDescriptions: false,
        }

        if (this.props.isRegion(['POLAND'])) {
            this.state = {
                ...this.state,
                secretPersonalId: "PESEL: ___________."
            }
        }

        this.fetchFiles = this.fetchFiles.bind(this);
        this.getArticles = this.getArticles.bind(this);
    }

    componentDidMount() {
        window.addEventListener("REFETCH_FILES", this.fetchFiles, false);
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        window.removeEventListener("REFETCH_FILES", this.fetchFiles, false);
    }

    fetchFiles(callback) {
        this.props.api.post(
            `/file/list`,
            {
                "anchors": { "offer": { "uuid": this.props.uuid } }
            },
            (data) => {
                this.setUpperState({
                    files: data.files.map(itm => itm.file),
                }, callback)
            },
            (errorObject) => {
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: 'danger'
                });
            }
        );
    }

    getArticles(stateField, category, callback) {
        if (!this.props.hasPermission(['DB_MY_REGION_ARTICLES']).any) {
            return;
        }
        this.props.api.post(
            `/article/list`,
            {
                "article": {
                    "category": category
                }
            },
            (data) => {
                this.setState({ [stateField]: data.articles }, callback);
            },
            (errorUpdateValidityData) => {
                this.props.showToast({
                    errorObject: errorUpdateValidityData,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        );
    }

    addFile(data) {
        this.handleElementAdd(
            'files',
            { ...data, className: 'highlight' },
            null,
            true
        );
    }

    checkIfFileCanBeGeneratedAndThen(ident, callback, skipFinancing) {

        if (this.props.isRegion(['POLAND'])) {
            ident = ident + '-pl';
        }

        if (this.props.isRegion(['SWEDEN'])) {
            ident = ident + '-se';
        }

        if (this.props.isRegion(['GERMANY'])) {
            ident = ident + '-de';
        }

        if (this.props.isRegion(['USA'])) {
            ident = ident + '-us';
        }

        if (!this.props.uuid) {
            this.props.showToast({
                title: this.props.t('common.toast.saveOfferFirst'),
                color: "warning"
            });
            return;
        }

        let preparedData = this.props.getPreparedData();

        if (preparedData.offer.marginType === "zero") {
            this.props.showToast({
                title: this.props.t('common.toast.couldNotSaveWithZeroMargin'),
                color: "danger"
            });
            return;
        }

        this.props.reCalcFinancing(skipFinancing ? false : this.props.financingPresent, () => {
            this.setUpperState({ isLoading: true }, () => {
                callback(ident)
            });
        })

    }

    preprareChartSlices(dataset) {
        let slices = [];
        for (let i = 0; i < dataset.data.length; i++) {
            slices.push(
                {
                    name: `${dataset.labels[i]}: ${dataset.data[i]}%`,
                    value: dataset.data[i],
                    color: dataset.backgroundColor[i],
                }
            )
        }
        return slices;
    }

    generateOfferAndMaybeUpdateStuff(ident) {
        let preparedData = this.props.getPreparedData();
        let extraData = {
            extra: {
                hidePrices: this.state.hidePrices,
                offerNotes: this.state.offerNotes,
                hideDescriptions: this.state.hideDescriptions
            }
        };

        let callback = () => {
            this.props.updateInDynamics(); // post silent, no state change
            extraData = {
                ...extraData,
                attachments: [
                    {
                        ident: 'house',
                        uuid: this.state.offerAttachment0Uuid,
                        link: this.state.offerAttachment0Link
                    }
                ],
            }
            this.generateFile(ident, extraData);
        }

        if (!preparedData.offer.validUntil) {
            this.props.api.post(
                `/offer/update-validity`,
                {
                    "offer": {
                        "uuid": preparedData.offer.uuid,
                        "validity": preparedData.offer.validity,
                    }
                },
                (successUpdateValidityData) => {
                    this.setUpperState(successUpdateValidityData.offer, callback);
                },
                (errorUpdateValidityData) => {
                    this.setUpperState({ isLoading: false });
                    this.props.showToast({
                        errorObject: errorUpdateValidityData,
                        title: this.props.t('common.toast.error'),
                        color: "danger"
                    });
                }
            );
        } else {
            callback();
        }
    }

    generateFile(ident, withData, level) {
        if (!ident) {
            this.props.showToast({
                title: this.props.t('common.toast.errorFatalReload'),
                color: "danger"
            });
            this.setUpperState({ isLoading: false });
            return;
        }

        let preparedData = this.props.getPreparedData();
        this.setUpperState({ isLoading: true }, () => {
            this.props.api.post(
                `/file/create/offer`,
                {
                    ...preparedData,
                    ...withData,
                    "file": {
                        "ident": ident,
                    }
                },
                (successFileCreateData) => {
                    this.hideModals(() => {
                        this.setUpperState({ isLoading: false }, () => {
                            this.addFile(successFileCreateData.file);
                            this.props.showToast({
                                title: this.props.t('common.toast.fileCreated'),
                                color: "info"
                            });
                            if (level > 2) {
                                return;
                            }
                            if (ident.indexOf("offer") > -1) {
                                this.checkIfFileCanBeGeneratedAndThen(
                                    'budget',
                                    (ident) => this.generateFile(
                                        ident,
                                        {
                                            parent: { uuid: successFileCreateData.file.uuid }
                                        },
                                        level ? level + 1 : 1
                                    ),
                                    true
                                );
                            }
                        });
                    });
                },
                (errorFileCreateData) => {
                    this.setUpperState({ isLoading: false }, () => {
                        this.props.showToast({
                            errorObject: errorFileCreateData,
                            title: this.props.t('common.toast.error'),
                            color: "danger"
                        });
                    });
                }
            );
        });

    }

    fillForm(articleUuid, fileIdent, formTokens) {

        let preparedData = this.props.getPreparedData();

        if (!preparedData.offer.uuid) {
            this.props.showToast({
                title: this.props.t('common.toast.saveOfferFirst'),
                color: "warning"
            });
            return;
        }

        if (!fileIdent) {
            this.props.showToast({
                title: this.props.t('common.toast.errorFatalReload'),
                color: "danger"
            });
            this.setUpperState({ isLoading: false });
            return;
        }

        this.setUpperState({ isLoading: true });

        this.props.api.post(
            `/file/fill-form`,
            {
                "offer": {
                    "uuid": preparedData.offer.uuid
                },
                "article": {
                    "uuid": articleUuid
                },
                "file": {
                    "ident": fileIdent
                },
                "tokens": formTokens
            },
            (successFileCreateData) => {
                this.hideModals();
                this.setUpperState({ isLoading: false });
                this.addFile(successFileCreateData.file);
                this.props.showToast({
                    title: this.props.t('common.toast.fileCreated'),
                    color: "info"
                });
            },
            (errorFileCreateData) => {
                this.setUpperState({ isLoading: false });
                this.props.showToast({
                    errorObject: errorFileCreateData,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        );
    }

    hideModals(callback) {
        if (typeof callback !== 'function') {
            callback = () => { }
        }
        this.setState({
            modalOfferParams: false,
            modalBudgetParams: false,
            modalAgreementParams: false,
            modalFillFormParams: false,
            agreementOfferUuid: "",
            offerAttachment0Uuid: "",
            offerAttachment0Link: "",
        }, () => callback());
    }

    canGenerateAgreement() {
        if (this.state.isLoading) {
            return false;
        }
        if (!this.state.agreementArticleUuid || !this.state.agreementOfferUuid) {
            return false;
        }
        if (this.props.representsCompany && !this.state.secretCompanyLocation) {
            return false;
        }
        if (!this.props.representsCompany && (!this.state.secretPersonalId || !this.state.secretPersonalLocation)) {
            return false;
        }
        return true;
    }

    render() {
        const {
            isLoading,
            closed,
            files,
            uuid,
            validity,
            validityOptions,
            validUntil,
            costTotalGross,
            costTotal
        } = this.props;

        const disabledBecauseOverMillion =
            (
                this.props.hasPermission(['VIEW_OFFER_DISABLE_OVER_1_0_MILLION']).any &&
                (
                    this.parseFloatFixed(costTotal) > 1_000_000
                )
            ) ||
            (
                this.props.hasPermission(['VIEW_OFFER_DISABLE_OVER_1_5_MILLION']).any &&
                (
                    this.parseFloatFixed(costTotal) > 1_500_000
                )
            );

        return (
            <Fragment>

                <ReactTooltip />

                <h2>
                    {this.props.t('page.calculation.files.title')}

                    <div className="float-md-right clear-section-container">

                        {
                            this.props.hasPermission(['VIEW_OFFER_CLEAR_SECTION_BUTTON']).any && (
                                <button
                                    type="button"
                                    className="btn text-danger px-0"
                                    disabled={isLoading || closed}
                                    onClick={(e) => { this.props.clearSection(e, ['files']) }}
                                >
                                    {this.props.t(`common.button.clear`)}
                                </button>
                            )
                        }

                    </div>
                </h2>

                <ModalFillForm
                    t={this.props.t}
                    api={this.props.api}
                    isLoading={isLoading || closed}
                    isRegion={this.props.isRegion}
                    show={this.state.modalFillFormParams}
                    showToast={this.props.showToast}
                    fetchFormArticles={true}
                    onHide={() => { this.setState({ modalFillFormParams: false }) }}
                    onSubmit={(articleUuid, fileIdent, tokens) => { this.fillForm(articleUuid, fileIdent, tokens) }}
                    upperState={this.props.upperState}
                />

                <Modal
                    show={this.state.modalOfferParams}
                    onHide={() => { this.setState({ modalOfferParams: false }) }}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.calculation.files.modal.offerParams.title')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Group className="required">
                                    <Form.Label>
                                        {this.props.t('page.calculation.files.modal.offerParams.validityTitle')}
                                    </Form.Label>
                                    {
                                        !validUntil ? (
                                            <Form.Control
                                                required={true}
                                                disabled={isLoading || closed}
                                                size="lg"
                                                as="select"
                                                custom
                                                value={validity}
                                                onChange={
                                                    (evt) => {
                                                        this.setUpperState({
                                                            validity: this.val(evt)
                                                        })
                                                    }
                                                }
                                            >
                                                {
                                                    validityOptions.map(days => (
                                                        <option key={`days-${days}`} value={days}>
                                                            {this.props.t('page.calculation.files.modal.offerParams.validityValue', { days: days })}
                                                        </option>
                                                    ))
                                                }
                                            </Form.Control>
                                        ) : (
                                            <Form.Control
                                                disabled={true}
                                                size="lg"
                                                type="date"
                                                value={validUntil}
                                                onChange={
                                                    (evt) => {
                                                        this.setUpperState({
                                                            validUntil: this.val(evt)
                                                        })
                                                    }
                                                }
                                            />
                                        )
                                    }
                                </Form.Group>
                            </Col>
                            {
                                this.props.isRegion(['POLAND']) && (
                                    <Col sm={12}>
                                        <Form.Group>
                                            <Form.Check
                                                type="switch"
                                                id="hidePrices"
                                                checked={this.state.hidePrices}
                                                onChange={event => {
                                                    this.setState({ hidePrices: !this.state.hidePrices })
                                                }}
                                                label={this.props.t(`page.calculation.files.modal.offerParams.hidePrices`)}
                                            />
                                        </Form.Group>
                                    </Col>
                                )
                            }
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>{this.props.t(`page.calculation.files.modal.offerParams.attachment0Title`)}</Form.Label>
                                    <Form.Control
                                        required={false}
                                        disabled={isLoading || closed}
                                        as="select"
                                        custom
                                        value={this.state.offerAttachment0Uuid}
                                        onChange={(e) => {
                                            this.setState({
                                                offerAttachment0Uuid: e.target.value,
                                                offerAttachment0Link: this.props.files.find(itm => itm.uuid === e.target.value)?.link
                                            })
                                        }}
                                    >
                                        <option value="">...</option>
                                        {
                                            this.props.files
                                                .filter(itm => !itm.trash && itm.ident.indexOf("addon") > -1)
                                                .sort((a, b) => (a.final > b.final) ? -1 : ((b.final > a.final) ? 1 : 0))
                                                .map((file) => {
                                                    return (
                                                        <option key={`offerAttachment0Uuid-${file.uuid}`} value={file.uuid}>
                                                            {`${file.filename} ${file.final ? '🔵' : ''}`}
                                                        </option>
                                                    )
                                                })
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            {
                                this.props.isRegion(['POLAND']) && (
                                    <Col sm={12}>
                                        <Form.Group>
                                            <Form.Label>{this.props.t('page.calculation.files.modal.offerParams.comment')}</Form.Label>
                                            <Form.Control
                                                required={true}
                                                disabled={this.props.isLoading || this.props.closed}
                                                as="textarea"
                                                style={{ height: 100 }}
                                                rows="2"
                                                value={this.state.offerNotes}
                                                onChange={(e) => { this.setState({ offerNotes: this.val(e) }) }}
                                            />
                                        </Form.Group>
                                    </Col>
                                )
                            }
                            {
                                this.props.isRegion(['SWEDEN', 'GERMANY']) && (
                                    <>
                                        <Col sm={12}>
                                            <Form.Group>
                                                <Form.Check
                                                    type="switch"
                                                    id="hidePrices"
                                                    checked={this.state.hidePrices}
                                                    onChange={event => {
                                                        this.setState({ hidePrices: !this.state.hidePrices })
                                                    }}
                                                    label={this.props.t(`page.calculation.files.modal.offerParams.hidePrices`)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </>
                                )
                            }
                            {
                                this.props.isRegion(['GERMANY']) && (
                                    <>
                                        <Col sm={12}>
                                            <Form.Group>
                                                <Form.Check
                                                    type="switch"
                                                    id="hideDescriptions"
                                                    checked={this.state.hideDescriptions}
                                                    onChange={event => {
                                                        this.setState({ hideDescriptions: !this.state.hideDescriptions })
                                                    }}
                                                    label={this.props.t(`page.calculation.files.modal.offerParams.hideDescriptions`)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </>
                                )
                            }
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="p-20 border-top">
                        <Button
                            variant="success"
                            disabled={isLoading || closed}
                            onClick={() => {
                                this.checkIfFileCanBeGeneratedAndThen('offer', (ident) => this.generateOfferAndMaybeUpdateStuff(ident))
                            }}>
                            {this.props.t('page.calculation.files.modal.offerParams.generate')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalBudgetParams}
                    onHide={() => { this.setState({ modalBudgetParams: false, budgetDisclaimer: false }) }}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.calculation.files.modal.budgetParams.title')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Check
                                        type="switch"
                                        id="disclaimer"
                                        checked={this.state.budgetDisclaimer}
                                        onChange={event => {
                                            this.setState({ budgetDisclaimer: !this.state.budgetDisclaimer })
                                        }}
                                        label={this.props.t('page.calculation.files.modal.budgetParams.disclaimer')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="p-20 border-top">
                        <Button
                            variant="success"
                            disabled={isLoading || !this.state.budgetDisclaimer}
                            onClick={() => {
                                this.checkIfFileCanBeGeneratedAndThen('budget', (ident) => this.generateFile(ident, {}))
                            }}>
                            {this.props.t('page.calculation.files.modal.budgetParams.generate')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalAgreementParams}
                    onHide={() => { this.setState({ modalAgreementParams: false }) }}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.calculation.files.modal.agreementParams.title')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {
                                this.props.representsCompany ? (
                                    <Col sm={12} md={6}>
                                        <Form.Group className="required">
                                            <Form.Label>{this.props.t('page.calculation.files.modal.agreementParams.secretCompanyLocation')}</Form.Label>
                                            <Form.Control
                                                required={true}
                                                disabled={this.props.isLoading || this.props.closed}
                                                as="textarea"
                                                style={{ height: 100 }}
                                                rows="2"
                                                value={this.state.secretCompanyLocation}
                                                onChange={(e) => { this.setState({ secretCompanyLocation: this.val(e) }) }}
                                            />
                                        </Form.Group>
                                    </Col>
                                ) : (
                                    <Col sm={12} md={6}>
                                        <Form.Group className="required">
                                            <Form.Label>{this.props.t('page.calculation.files.modal.agreementParams.secretPersonalId')}</Form.Label>
                                            <Form.Control
                                                required={true}
                                                disabled={this.props.isLoading || this.props.closed}
                                                as="textarea"
                                                style={{ height: 100 }}
                                                rows="2"
                                                value={this.state.secretPersonalId}
                                                onChange={(e) => { this.setState({ secretPersonalId: this.val(e) }) }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="required">
                                            <Form.Label>{this.props.t('page.calculation.files.modal.agreementParams.secretPersonalLocation')}</Form.Label>
                                            <Form.Control
                                                required={true}
                                                disabled={this.props.isLoading || this.props.closed}
                                                as="textarea"
                                                style={{ height: 100 }}
                                                rows="2"
                                                value={this.state.secretPersonalLocation}
                                                onChange={(e) => { this.setState({ secretPersonalLocation: this.val(e) }) }}
                                            />
                                        </Form.Group>
                                    </Col>
                                )
                            }
                            <Col sm={12} md={6}>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group className="required">
                                            <Form.Label>{this.props.t('page.calculation.files.modal.agreementParams.representative')}</Form.Label>
                                            <Form.Control
                                                required={true}
                                                disabled={this.props.isLoading || this.props.closed}
                                                value={this.state.representative}
                                                onChange={(e) => { this.setState({ representative: this.val(e) }) }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="required">
                                            <Form.Label>{this.props.t(`page.calculation.files.modal.agreementParams.article`)}</Form.Label>
                                            <Form.Control
                                                required={true}
                                                disabled={isLoading || closed}
                                                as="select"
                                                custom
                                                value={this.state.agreementArticleUuid}
                                                onChange={(e) => { this.setState({ agreementArticleUuid: e.target.value }) }}
                                            >
                                                <option value="">...</option>
                                                {
                                                    this.state.agreementArticles.filter(itm => !itm.schema).map(articleCategory => (
                                                        <option key={articleCategory.uuid} value={articleCategory.uuid}>
                                                            {articleCategory.title}
                                                        </option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="required">
                                            <Form.Label>{this.props.t(`page.calculation.files.modal.agreementParams.offer`)}</Form.Label>
                                            <Form.Control
                                                required={true}
                                                disabled={isLoading || closed}
                                                as="select"
                                                custom
                                                value={this.state.agreementOfferUuid}
                                                onChange={(e) => { this.setState({ agreementOfferUuid: e.target.value }) }}
                                            >
                                                <option value="">...</option>
                                                {
                                                    this.props.files
                                                        .filter(itm => !itm.trash && itm.ident.indexOf("offer") > -1)
                                                        .sort((a, b) => (a.final > b.final) ? -1 : ((b.final > a.final) ? 1 : 0))
                                                        .map((file) => {
                                                            return (
                                                                <option key={`agreementOffer-${file.uuid}`} value={file.uuid}>
                                                                    {`${file.filename} ${file.final ? '🔵' : ''}`}
                                                                </option>
                                                            )
                                                        })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="p-20 border-top">
                        <Button
                            variant="success"
                            disabled={
                                !this.canGenerateAgreement()
                                || isLoading
                            }
                            onClick={() => {
                                this.checkIfFileCanBeGeneratedAndThen(
                                    'agreement',
                                    (ident) => this.generateFile(ident, {
                                        parent: { uuid: this.state.agreementOfferUuid },
                                        article: { uuid: this.state.agreementArticleUuid },
                                        secret: { personalId: this.state.secretPersonalId, personalLocation: this.state.secretPersonalLocation, companyLocation: this.state.secretCompanyLocation },
                                        addons: { representative: this.state.representative }
                                    }))
                            }}>
                            {this.props.t('page.calculation.files.modal.agreementParams.generate')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Row className="mb-30">
                    <Col sm={12}>
                        <Card>
                            {
                                this.props.hidden.includes('generateCalculation') ? (
                                    (this.props.hasPermission(['VIEW_OFFER_FILL_FORM_BUTTON']).any && this.props.hidden.includes('keepFillInTheForm')) && (
                                        <Card.Header className="px-10 py-10 background-white">
                                            <button
                                                type="button"
                                                className="btn btn-uniform text-dark"
                                                onClick={(e) => {
                                                    this.getArticles('agreementArticles', 'sales', () => {
                                                        this.setState({ modalFillFormParams: true });
                                                    });
                                                }}
                                                disabled={isLoading || closed}
                                            >
                                                <Icon name="edit" />
                                                <span className="pl-10">
                                                    {this.props.t('page.calculation.files.fillForm')}
                                                </span>
                                            </button>
                                        </Card.Header>
                                    )
                                ) : disabledBecauseOverMillion ? (
                                    <Alert variant='warning'>
                                        {this.props.t('common.tooltip.offerOverMillion')}
                                    </Alert>
                                ) : (
                                    <Card.Header className="px-10 py-10 background-white">
                                        <button
                                            type="button"
                                            className="btn btn-uniform text-success"
                                            onClick={(e) => { this.setState({ modalOfferParams: true }) }}
                                            disabled={isLoading || closed}
                                        >
                                            <Icon name="plus-square" />
                                            <span className="pl-10">
                                                {this.props.t('page.calculation.files.generateCalculation')}
                                            </span>
                                        </button>
                                        {
                                            (this.props.hasPermission(['VIEW_OFFER_BUDGET_BUTTON']).any) && (
                                                <button
                                                    type="button"
                                                    className="btn btn-uniform text-info"
                                                    onClick={(e) => { this.setState({ modalBudgetParams: true }) }}
                                                    disabled={isLoading || closed}
                                                >
                                                    <Icon name="plus-square" />
                                                    <span className="pl-10">
                                                        {this.props.t('page.calculation.files.generateBudget')}
                                                    </span>
                                                </button>
                                            )
                                        }
                                        {
                                            (this.props.hasPermission(['VIEW_OFFER_AGREEMENT_BUTTON']).any) && (
                                                <button
                                                    type="button"
                                                    className="btn btn-uniform text-default"
                                                    onClick={(e) => {
                                                        this.getArticles('agreementArticles', 'sales', () => {
                                                            this.setState({ modalAgreementParams: true });
                                                        });
                                                    }}
                                                    disabled={isLoading || closed}
                                                >
                                                    <Icon name="plus-square" />
                                                    <span className="pl-10">
                                                        {this.props.t('page.calculation.files.generateAgreement')}
                                                    </span>
                                                </button>
                                            )
                                        }
                                        {
                                            (this.props.hasPermission(['VIEW_OFFER_FILL_FORM_BUTTON']).any) && (
                                                <button
                                                    type="button"
                                                    className="btn btn-uniform text-dark"
                                                    onClick={(e) => {
                                                        this.getArticles('agreementArticles', 'sales', () => {
                                                            this.setState({ modalFillFormParams: true });
                                                        });
                                                    }}
                                                    disabled={isLoading || closed}
                                                >
                                                    <Icon name="edit" />
                                                    <span className="pl-10">
                                                        {this.props.t('page.calculation.files.fillForm')}
                                                    </span>
                                                </button>
                                            )
                                        }
                                    </Card.Header>
                                )
                            }
                            <Card.Body className="border-top-1 overflow-auto">
                                <DropzoneWrapper
                                    inputContent={!uuid ? this.props.t('common.toast.saveOfferFirst') : this.props.t('page.calculation.files.dropzone')}
                                    disabled={isLoading || !uuid || closed}
                                    api={this.props.api}
                                    bearerToken={this.props.api.getToken()}
                                    params={{ "offer": { "uuid": this.props.uuid }, "file": { "ident": "addon" } }}
                                    accept={acceptFileType.join(', ')}
                                    className="dropzone py-50 px-20 text-center"
                                    progressEventName="API_PROGRESS"
                                    onUploaded={(file) => { this.addFile(file); }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <Card className="border-top-0">
                            <Card.Body className="p-0">
                                <FilesTable
                                    t={this.props.t}
                                    files={files}
                                    withOptions={true}
                                    isLoading={isLoading || closed}
                                    confirmRemove={(callback) => { this.confirmRemove(callback) }}
                                    setUpperState={(newState, callback) => { this.setUpperState(newState, callback) }}
                                    handleElementTrash={(elementsKey, uuid, where) => { this.handleElementTrash(elementsKey, uuid, where) }}
                                    handleElementChange={(elementsKey, uuid, field, value, where) => { this.handleElementChange(elementsKey, uuid, field, value, where) }}
                                    hasPermission={this.props.hasPermission}
                                    getUserConfig={this.props.getUserConfig}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}
export default Files;