import React, { Fragment } from 'react';
import Datatable from "../../components/datatable";
import BaseComponent from '../../components/component/base';
import Icon from '../../components/icon';
import { Row, Col, Card } from "react-bootstrap";

class Content extends BaseComponent {

    render() {
        return (
            <Fragment>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Header className="px-10 py-10 background-white">
                                <button
                                    type="button"
                                    className="btn btn-uniform text-success"
                                    disabled={false}
                                    onClick={(e) => { this.props.openLink('/asset/') }}
                                >
                                    <Icon name="plus-square" />
                                    <span className="pl-10">
                                        {this.props.t('page.assets.newAsset')}
                                    </span>
                                </button>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Datatable
                                    id={'asset-table'}
                                    endpoint={`asset/table`}
                                    withData={{
                                        asset: {
                                            category: 'asset'
                                        }
                                    }}
                                    columns={[
                                        {
                                            Header: this.props.t('page.assets.table.ident'),
                                            accessor: "ident_1",
                                            width: 120,
                                        },
                                        {
                                            Header: this.props.t('page.assets.table.assetType'),
                                            accessor: "assetType_3",
                                            width: 120,
                                        },
                                        {
                                            Header: this.props.t('page.assets.table.assetStatus'),
                                            accessor: "ident_7",
                                            width: 120,
                                        },
                                        {
                                            Header: this.props.t('page.assets.table.companyName'),
                                            accessor: "nameOfOwningCompany_4"
                                        },
                                        {
                                            Header: this.props.t('page.assets.table.name'),
                                            accessor: "name_2"
                                        },
                                        {
                                            Header: this.props.t('page.assets.table.firstName'),
                                            accessor: "firstNameOfUser_5",
                                            width: 200
                                        },
                                        {
                                            Header: this.props.t('page.assets.table.lastName'),
                                            accessor: "lastNameOfUser_6",
                                            width: 200
                                        },
                                        {
                                            Header: (<>&nbsp;</>),
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                            width: 150
                                        }
                                    ]}
                                    sort={[
                                        {
                                            id: "ident_1",
                                            desc: true
                                        }
                                    ]}
                                    badges={[{
                                        "field": "assetType_3",
                                        "badgify": true,
                                        "divClass": "text-left"
                                    }, {
                                        "field": "ident_7",
                                        "badgify": true,
                                        "divClass": "text-left"
                                    }]}
                                    transform={[
                                        {
                                            'field': 'assetType_3',
                                            'fn': (row) => {
                                                return row.assetType_3.split('_').slice(-1)[0]
                                            }
                                        }
                                    ]}
                                    withCheckbox={false}
                                    actions={[
                                        {
                                            action: 'edit',
                                            callback: (data) => {
                                                this.props.openLink(`/asset/${data.uuid_0}/`)
                                            }
                                        },
                                        {
                                            action: 'delete',
                                            callback: (data) => {
                                                this.confirmRemove(() => {
                                                    this.props.api.post(
                                                        `/asset/trash`,
                                                        {
                                                            "asset": {
                                                                "uuids": [data.uuid_0]
                                                            }
                                                        },
                                                        () => {
                                                            this.props.api.dispatchEvent('RELOAD_TABLE');
                                                        }
                                                    )
                                                })
                                            }
                                        }
                                    ]}
                                    {...this.props}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default Content
