import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        keySeparator: ".",
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: '/assets/language/{{lng}}.json'
        }
    });

const langs = [
    'en',
    'sv',
    'pl',
    'de',
    'us'
];

const regions = [
    'region',
    'poland',
    'sweden',
    'germany',
    'usa'
]

const i18nBundle = {
    engine: i18n,
    langs: langs,
    regions: regions
};

export default i18nBundle;