import './style.scss';
import React, { Fragment } from 'react';
import _ from 'lodash';
import classnames from 'classnames/dedupe';
import { FormGroup, Table, Form, Tabs, Tab, Spinner, Row, Col, Modal, Button } from 'react-bootstrap';
import BaseComponent from '../../components/component/base';
import Icon from '../../components/icon';
import TextEditor from '../../components/text-editor';
import getProto from '../../proto';
import DebounceInput from 'react-debounce-input';
import { HelpCircle } from "react-feather";
import ReactTooltip from "react-tooltip";
import { Tree } from './tree';

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            activeTab1: 'basic',
            tree: [],
            variables: {},
            selectedObj: null,
            selectedLvl: null,
            loading: false,
            downloading: false,
            saving: false,
            modal: false,
            lastChange: null,
            modalSync: false,
            modalSyncLoading: false,
            syncPreview: [],
            selectedPath: [],
            definedVariables: {},
            productTypes: [],
            syncAll: false,
            syncItems: [],
        };
        this.treeUpdateStatus = this.treeUpdateStatus.bind(this);
        this.treeUpdateStatusInterval = undefined;
    }

    componentDidMount() {
        this.fetchConfig(() => {
            this.getAll();
        });
        this.treeUpdateStatusInterval = setInterval(() => {
            this.treeUpdateStatus()
        }, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.treeUpdateStatusInterval);
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild();
    }

    getAll() {
        this.setState({ loading: true, downloading: true }, () => {
            this.getTheTree(() => {
                this.setState({ loading: false, downloading: false });
            });
        });
    }

    fetchConfig(callback) {
        this.props.api.post(
            `/project/data`,
            {
                "file": {
                    "names": ["product_types"]
                }
            }, (data) => {
                this.setState({ productTypes: (data.product_types || []) }, callback);
            }
        );
    }

    getTheTree(callback) {
        this.props.api.get(
            `/product/tree-structure`,
            (data) => {
                this.setState({
                    tree: data.tree,
                    definedVariables: data.variables,
                    lastChange: 'structure',
                    selectedObj: null
                }, () => { callback() })
            }
        );
    }

    setTheTree(newState) {
        this.setState(
            newState,
            () => {
                let newItem = document.getElementsByClassName(`highlight`);
                if (newItem && newItem.length) {
                    // this.maybeScrollPageToElement(newItem[0]);
                }
            }
        )
    }

    maybeScrollPageToBottom() {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        });
    }

    maybeScrollPageToElement(element) {
        let headerOffset = 100;
        let elementPosition = element.getBoundingClientRect().top;
        let offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    }

    treeUpdateStatus() {
        if (this.state.saving) {
            this.props.showToast({
                title: this.props.t('common.toast.dataBeingSaved'),
                color: "info",
                duration: 2500,
            });
        }
    }

    setTreeInOrder(tree) {
        return [...tree].map((lvl0, lvl0i) => {
            return {
                ...lvl0,
                position: lvl0i,
                children: [...lvl0.children].map((lvl1, lvl1i) => {
                    return {
                        ...lvl1,
                        position: lvl1i,
                        children: [...(lvl1.children || [])].map((lvl2, lvl2i) => {
                            return {
                                ...lvl2,
                                position: lvl2i,
                            }
                        })
                    }
                })
            }
        });
    }

    maybeSave(e) {
        if (e) {
            e.preventDefault();
        }
        let selectedObj = { ...this.state.selectedObj || {} };
        let selectedLvl = this.state.selectedLvl || 1;
        let selectedPath = [...this.state.selectedPath];
        this.setState({
            downloading: true,
            saving: true,
        }, () => {
            this.selectItem(null, null, () => {
                this.props.api.post(
                    `/product/tree-update`,
                    {
                        tree: this.setTreeInOrder(this.state.tree)
                    },
                    (data) => {
                        this.state.tree.forEach(obj => {
                            if (obj.uuid === selectedObj.uuid) {
                                selectedObj = { ...obj }
                            }
                        });
                        this.selectItem(selectedObj, selectedLvl, () => {
                            this.setState(
                                {
                                    tree: data.tree,
                                    downloading: false,
                                    saving: false,
                                    lastChange: "structure",
                                    selectedPath: selectedPath,
                                },
                                () => {
                                    this.props.showToast({
                                        title: this.props.t('common.toast.dataSaved'),
                                        color: "success"
                                    });
                                })
                        });
                    },
                    (errorObject) => {
                        this.setState(
                            {
                                downloading: false,
                                saving: false
                            },
                            () => {
                                this.props.showToast({
                                    errorObject: errorObject,
                                    title: this.props.t('common.toast.error'),
                                    color: 'danger'
                                });
                            }
                        );
                    }
                );
            });
        });
    }

    onInvalid(e) {
        if (e) {
            e.preventDefault();
        }
        let errorObject = {
            "ident": "",
            "data": {
                "error": {
                    "ident": ""
                }
            }
        };
        if (
            e.target
            && e.target?.previousElementSibling
            && e.target.previousElementSibling.tagName === 'LABEL'
        ) {
            errorObject.ident = this.props.t(`common.tooltip.invalid`);
            errorObject.data.error.ident = e.target.previousElementSibling.innerText;
        }
        this.props.showToast({
            title: this.props.t('common.toast.checkInputData'),
            color: 'danger',
            errorObject: errorObject.ident ? errorObject : null
        });
    }

    export() {
        let self = this;
        self.setState({ loading: true });
        self.props.showToast({
            title: self.props.t('common.toast.exportStarter'),
            color: "info"
        });
        self.props.api.post(
            `/product/tree-export`,
            {},
            (data) => {
                self.setState({ loading: false });
                if (data.file && data.file.link) {
                    window.open(data.file.link);
                }
            },
            (errorObject) => {
                this.setState({ loading: false });
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        );
    }

    selectItem(obj, lvl, callback) {
        let selectedPath = [obj?.parent];
        let lastParent = obj?.parent;
        for (let p = 0; p < 3; p++) {
            if (!lastParent) {
                break;
            }
            this.loopThrough((itm) => {
                if (itm.uuid === lastParent) {
                    selectedPath.push(lastParent);
                    lastParent = itm.parent;
                }
                return itm;
            });
        }
        selectedPath = selectedPath.filter(Boolean);
        this.setState({
            activeTab1: 'basic',
            lastChange: 'item',
            selectedObj: obj,
            selectedLvl: lvl,
            selectedPath: selectedPath,
        }, () => {
            this.props.api.dispatchEvent('UPDATE_EDITOR', { editorId: "product-description-editor", content: obj?.descriptionIfIncluded || '' });
            this.dndIsSlowSoSelectElementManually(obj?.uuid);
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    dndIsSlowSoSelectElementManually(elementUuid) {
        let items = document.getElementsByClassName(`tree-item`);
        let item = document.getElementsByClassName(`tree-item-${elementUuid}`);
        for (let i = 0; i < items.length; i++) {
            items[i].classList.remove('current');
        }
        if (item && item.length) {
            item[0].classList.add('current');
        }
    }

    dndIsSlowSoToggleSubItemsManually(elementUuid) {
        let items = document.getElementsByClassName(`tree-group-with-parent-${elementUuid}`);
        for (let i = 0; i < items.length; i++) {
            items[i].classList.toggle('hidden');
        }
    }

    loopThrough(fn) {
        // let t0 = performance.now();
        let preparedTree = this.state.tree;
        preparedTree = preparedTree.map(rootItm => {
            return this.diveIn(rootItm, fn, 0, null);
        });
        // let t1 = performance.now();
        // console.log("Call to loopThrough took " + (t1 - t0) + " milliseconds.")
        return preparedTree;
    }

    diveIn(obj, fn, level, parent) {
        obj = fn(obj, level, parent);
        if (obj.children && obj.children.length) {
            obj.children.map(child => {
                return this.diveIn(child, fn, level++, obj);
            });
        }
        return obj;
    }

    addItem(parentUuid, level, newItem) {
        let label = "";
        switch (level) {
            case 0:
                label = this.props.t('page.products.defaultLabelCategory');
                break

            case 1:
                label = this.props.t('page.products.defaultLabelGroup');
                break;

            case 2:
                label = this.props.t('page.products.defaultLabelProduct');
                break;
        }
        newItem = newItem || getProto('product', { label: label, className: 'highlight' });
        if (parentUuid) {
            let newTree = this.loopThrough((obj) => {
                if (obj.uuid === parentUuid) {
                    obj.children = obj.children.concat([newItem])
                        .sort((a, b) => (a.position > b.position) ? 1 : (a.position < b.position) ? -1 : 0);
                }
                return obj;
            });
            this.setTheTree({
                lastChange: "structure",
                tree: newTree,
            });
        } else {
            this.setTheTree({
                lastChange: "structure",
                tree: this.state.tree.concat([newItem])
                    .sort((a, b) => (a.position > b.position) ? 1 : (a.position < b.position) ? -1 : 0),
            });
        }
    }

    copyItem(item) {
        let newItem = {
            ...item,
            uuid: getProto('uuid'),
            label: `${item.label} (copy)`,
            className: 'highlight'
        };
        if (newItem.children) {
            newItem.children = newItem.children.map(lvl0Real => {
                let lvl0 = { ...lvl0Real };
                lvl0.uuid = getProto('uuid');
                lvl0.parent = newItem.uuid;
                lvl0.label = `${lvl0.label} (copy)`;
                lvl0.className = 'highlight';
                if (lvl0.children) {
                    lvl0.children = lvl0.children.map(lvl1Real => {
                        let lvl1 = { ...lvl1Real };
                        lvl1.uuid = getProto('uuid');
                        lvl1.parent = lvl0.uuid;
                        lvl1.label = `${lvl1.label} (copy)`;
                        lvl1.className = 'highlight';
                        if (lvl1.children) {
                            lvl1.children = lvl1.children.map(lvl2Real => {
                                let lvl2 = { ...lvl2Real };
                                lvl2.uuid = getProto('uuid');
                                lvl2.parent = lvl1.uuid;
                                lvl2.label = `${lvl2.label} (copy)`;
                                lvl2.className = 'highlight';
                                if (lvl2.children) {
                                    lvl2.children = lvl2.children.map(lvl3Real => {
                                        let lvl3 = { ...lvl3Real };
                                        lvl3.uuid = getProto('uuid');
                                        lvl3.parent = lvl2.uuid;
                                        lvl3.label = `${lvl3.label} (copy)`;
                                        lvl3.className = 'highlight';
                                        return lvl3;
                                    })
                                }
                                return lvl2;
                            })
                        }
                        return lvl1;
                    })
                }
                return lvl0;
            })
        }
        this.addItem(
            newItem.parent,
            undefined,
            newItem
        );
    }

    addItemSubItem(field, proto) {
        const {
            selectedObj
        } = this.state;
        let newSelectedObj = { ...selectedObj };
        let newItem = getProto(proto);
        let preparedTree = this.loopThrough((obj) => {
            if (obj.uuid === selectedObj.uuid) {
                obj[field] = obj[field].concat([newItem]);
                newSelectedObj = { ...obj };
            }
            return obj;
        })
        this.setState({ tree: preparedTree, selectedObj: newSelectedObj, lastChange: "item" });
    }

    editItem(value, field) {
        const {
            selectedObj
        } = this.state;
        let newSelectedObj = { ...selectedObj };
        let preparedTree = this.loopThrough((obj) => {
            if (obj.uuid === selectedObj?.uuid) {
                obj[field] = value;
                newSelectedObj = { ...obj };
            }
            return obj;
        })
        this.setState({ tree: preparedTree, selectedObj: newSelectedObj, lastChange: "item" });
    }

    editItemSubItem(value, findUuid, field, inSection, inKey) {
        const {
            selectedObj
        } = this.state;
        let newSelectedObj = { ...selectedObj };
        let preparedTree = this.loopThrough((obj) => {
            if (obj.uuid === selectedObj.uuid) {
                obj[field] = obj[field].map((nestedObj) => {
                    if (nestedObj.uuid === findUuid) {
                        if (inSection && inKey) {
                            let updatedObj = { ...nestedObj[inSection] };
                            updatedObj[inKey] = value;
                            nestedObj[inSection] = updatedObj;
                        } else {
                            nestedObj[inSection] = value;
                        }
                    }
                    return nestedObj;
                });
                newSelectedObj = { ...obj };
            }
            return obj;
        })
        this.setState({ tree: preparedTree, selectedObj: newSelectedObj, lastChange: "item" });
    }

    removeItem(shouldBeInTrash, uuid) {
        let preparedTree = this.loopThrough((obj) => {
            if (obj.uuid === uuid) {
                obj.trash = shouldBeInTrash;
                return obj;
            }
            return obj;
        });
        this.setTheTree({ tree: preparedTree, lastChange: "structure" });
    }

    getLabel(obj, level, dragProps) {

        const {
            selectedObj
        } = this.state;

        if (obj.createdSecondsAgo < 10) {
            obj.className = 'highlight';
        }

        const objLabel = (
            <>
                {
                    obj.trash
                        ?
                        (
                            <del className="text-danger mr-5">{obj.label}</del>
                        )
                        :
                        (
                            <span className={classnames("mr-5", "tree-item", `tree-item-${obj.uuid}`, obj.className, selectedObj && obj.uuid === selectedObj.uuid ? "current" : "")}>{obj.label}</span>
                        )
                }
            </>
        );

        const copyButton = (
            <>
                <button
                    type="button"
                    data-tip={this.props.t('common.tooltip.copy')}
                    className="btn btn-custom-round text-secondary ml-5"
                    onClick={(e) => { this.copyItem(obj) }}
                >
                    <Icon name="copy" />
                </button>

            </>
        );

        const editButton = (
            <>
                <button
                    type="button"
                    className="btn btn-link"
                    data-tip={this.props.t('common.tooltip.edit')}
                    style={{ textTransform: 'none' }}
                    onClick={(e) => { this.selectItem(obj, level) }}
                >
                    {objLabel}
                </button>
            </>
        )

        const addButton = (
            level > 2
                ?
                <></>
                :
                <>
                    <button
                        type="button"
                        className="btn btn-custom-round text-info ml-5"
                        data-tip={this.props.t('common.tooltip.add')}
                        onClick={(e) => { this.addItem(obj.uuid, level) }}
                    >
                        <Icon name="plus-square" />
                    </button>

                </>
        );

        const showSubItemsButton = (
            level > 2
                ?
                <></>
                :
                <>
                    <button
                        type="button"
                        className="btn btn-custom-round ml-5"
                        data-tip={this.props.t('common.tooltip.show')}
                        onClick={(e) => { this.dndIsSlowSoToggleSubItemsManually(obj.uuid) }}
                    >
                        <Icon name="chevrons-down" />
                    </button>

                </>
        );

        const removeButton = (
            <>
                <button
                    type="button"
                    className="btn btn-custom-round ml-5 text-danger"
                    data-tip={this.props.t('common.tooltip.remove')}
                    onClick={
                        (e) => {
                            this.confirmRemove(
                                () => {
                                    this.removeItem(true, obj.uuid)
                                }
                            )
                        }
                    }
                >
                    <Icon name="minus-square" />
                </button>
            </>
        )

        const moveButton = (
            <span
                style={{ cursor: "move" }}
                {...dragProps}
            >
                <Icon name="menu" />
            </span>
        )

        return (
            <>
                {moveButton}
                {showSubItemsButton}
                {editButton}
                {addButton}
                {copyButton}
                {removeButton}
            </>
        );
    }

    getSyncPreview() {
        this.setState({
            modalSyncLoading: true,
            syncPreview: []
        }, () => {
            this.props.api.get(
                `/product/sync-business-central-preview`,
                (data) => {
                    this.setState({
                        syncPreview: data,
                        modalSyncLoading: false
                    })
                },
                (errorObject) => {
                    this.setState({ modalSyncLoading: false });
                    errorObject.data.error.details[0].forEach(errorItem => {
                        this.props.showToast({
                            errorObject: errorObject,
                            title: this.props.t('common.toast.error') + `: ${errorItem.bcId} (${errorItem.label})`,
                            color: 'danger'
                        });
                    })
                }
            );
        });
    }

    showSyncModal() {
        this.setState({ modalSync: true, }, () => this.getSyncPreview());
    }

    syncMaybeExecute(andClose) {
        const callback = () => {
            this.setState({
                modalSync: true,
                modalSyncLoading: true,
                syncPreview: []
            }, () => {
                this.props.api.post(
                    `/product/sync-business-central-execute`,
                    {
                        "product": {
                            "uuids": this.state.syncItems
                        }
                    },
                    (data) => {
                        this.setState({
                            syncPreview: data,
                            modalSyncLoading: false
                        }, () => {
                            if (andClose) {
                                this.setState({
                                    modalSync: false,
                                }, () => {
                                    this.props.showToast({
                                        title: this.props.t('common.toast.dataSaved'),
                                        color: "info",
                                        duration: 2500,
                                    });
                                    this.getAll();
                                });
                            }
                        })
                    },
                    (errorObject) => {
                        this.setState({ modalSyncLoading: false });
                        this.props.showToast({
                            errorObject: errorObject,
                            title: this.props.t('common.toast.error'),
                            color: "danger"
                        });
                    }
                );
            });
        }

        this.confirm({
            title: this.props.t('page.products.basicData.sync.confirm.title'),
            text: this.props.t('page.products.basicData.sync.confirm.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('page.products.basicData.sync.confirm.confirm'),
            confirmButtonColor: '#fdbf21',
            cancelButtonColor: '#aaa',
            cancelButtonText: this.props.t('page.products.basicData.sync.confirm.cancel')
        }, callback);
    }

    toggleSync(itmuuid) {
        const wasSyncAll = this.state.syncAll;
        const oldItems = [...this.state.syncItems];
        const allItems = [...this.state.syncPreview].filter(itm => itm.sync).filter(itm => !itm.error).map(itm => itm.uuid);
        let bcId = "";

        if (itmuuid === 'all') {
            this.setState({ syncAll: !wasSyncAll, syncItems: wasSyncAll ? [] : allItems })
        } else if (allItems.includes(itmuuid)) {

            let validItems = [];
            this.state.syncPreview.filter(itm => !itm.error).forEach(itm => {
                if (itm.uuid === itmuuid) {
                    bcId = itm.bcId;
                }
                if (bcId && bcId === itm.bcId) {
                    validItems.push(itm.uuid);
                }
            });

            if (oldItems.includes(itmuuid)) {
                const syncItems = oldItems.filter(itm => !validItems.includes(itm));
                this.setState({ syncAll: false, syncItems });
            } else {
                oldItems.push(...validItems);
                this.setState({ syncAll: false, syncItems: oldItems });
            }
        }
    }

    render() {

        const {
            selectedObj,
            selectedLvl,
            tree,
            activeTab1,
            loading,
            downloading,
            lastChange,
            selectedPath,
            definedVariables,
            syncAll,
            syncItems
        } = this.state;

        const objEditor = !_.isEmpty(selectedObj) && (
            <div className={classnames('p-30', _.isEmpty(selectedObj) ? 'd-none' : 'col-sm-12 col-md-8')}>
                <div className="sticky-top" style={{ top: 100 }}>
                    <Form method='post' onSubmit={(e) => { this.maybeSave(e) }} onInvalid={(e) => { this.onInvalid(e) }}>
                        <Tabs defaultActiveKey={`basic`} activeKey={activeTab1} onSelect={(k) => this.setState({ activeTab1: k })} className="nicebar">
                            <Tab eventKey="basic" title={this.props.t('page.products.basicData.title')}>
                                <div style={{ maxHeight: `calc(100vh - 260px)`, overflowY: 'auto', overflowX: 'hidden' }}>
                                    <FormGroup>
                                        <Form.Label>
                                            {this.props.t('page.products.basicData.name')}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={this.props.t('page.products.basicData.placeholder.name')}
                                            value={selectedObj?.label || ""}
                                            onChange={(e) => { this.editItem(this.val(e), 'label') }}
                                        />
                                    </FormGroup>

                                    {(selectedLvl === 1) && (
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Form.Label>
                                                        {this.props.t('page.products.basicData.category')}
                                                    </Form.Label>
                                                    <Form.Control as="select" value={selectedObj?.category || ""} onChange={(e) => { this.editItem(this.val(e), 'category') }} >
                                                        <option value="">{this.props.t('page.products.basicData.empty')}</option>
                                                        <option value="active">{this.props.t('page.products.basicData.active')}</option>
                                                        <option value="passive">{this.props.t('page.products.basicData.passive')}</option>
                                                        {
                                                            selectedObj?.category === 'service' && (
                                                                <option value="service">{this.props.t('page.products.basicData.service')}</option>
                                                            )
                                                        }
                                                        <option value="additional">{this.props.t('page.products.basicData.additional')}</option>
                                                        <option value="other">{this.props.t('page.products.basicData.other')}</option>
                                                    </Form.Control>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}

                                    {(selectedLvl === 1) && (
                                        <Row>
                                            <Col md={12}>
                                                <Form.Check
                                                    type="switch"
                                                    id="required"
                                                    label={this.props.t('page.products.basicData.required')}
                                                    checked={selectedObj?.required || false}
                                                    onChange={(e) => { this.editItem(!selectedObj?.required, 'required') }}
                                                    className="mb-10"
                                                />
                                            </Col>
                                        </Row>
                                    )}

                                    {(selectedLvl === 1) && (
                                        <Row>
                                            <Col md={12}>
                                                <Form.Check
                                                    type="switch"
                                                    id="allowCopy"
                                                    label={this.props.t('page.products.basicData.allowCopy')}
                                                    checked={selectedObj?.allowCopy || false}
                                                    onChange={(e) => { this.editItem(!selectedObj?.allowCopy, 'allowCopy') }}
                                                    className="mb-10"
                                                />
                                            </Col>
                                        </Row>
                                    )}

                                    <Row>
                                        <Col md={12}>
                                            <Form.Check
                                                type="switch"
                                                id="hidden"
                                                label={this.props.t('page.products.basicData.hidden')}
                                                checked={selectedObj?.hidden || false}
                                                onChange={(e) => { this.editItem(!selectedObj?.hidden, 'hidden') }}
                                                className="mb-10"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Check
                                                type="switch"
                                                id="visibleForWholesaler"
                                                label={this.props.t('page.products.basicData.visibleForWholesaler')}
                                                checked={selectedObj?.visibleForWholesaler || false}
                                                onChange={(e) => { this.editItem(!selectedObj?.visibleForWholesaler, 'visibleForWholesaler') }}
                                                className="mb-10"
                                            />
                                        </Col>
                                    </Row>

                                    {
                                        selectedLvl === 2
                                            ?
                                            (
                                                <>
                                                    {
                                                        (this.props.hasPermission(['VIEW_PRODUCTS_SET_VAT_ZERO']).any) && (
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id="vatZero"
                                                                        label={this.props.t('page.products.basicData.vatZero')}
                                                                        checked={selectedObj?.vatZero || false}
                                                                        onChange={(e) => { this.editItem(!selectedObj?.vatZero, 'vatZero') }}
                                                                        className="mb-10"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }
                                                    <Row>
                                                        <Col md={12}>
                                                            <Form.Check
                                                                type="switch"
                                                                id="allowCustom"
                                                                label={this.props.t('page.products.basicData.allowCustom')}
                                                                checked={selectedObj?.allowCustom || false}
                                                                onChange={(e) => { this.editItem(!selectedObj?.allowCustom, 'allowCustom') }}
                                                                className="mb-10"
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Form.Check
                                                                    type="switch"
                                                                    id="pvPowerSetter"
                                                                    label={this.props.t('page.products.basicData.pvPowerSetter')}
                                                                    checked={selectedObj?.pvPowerSetter || false}
                                                                    onChange={(e) => { this.editItem(!selectedObj?.pvPowerSetter, 'pvPowerSetter') }}
                                                                    className="mb-10"
                                                                />
                                                            </FormGroup>
                                                            {
                                                                selectedObj?.pvPowerSetter && (
                                                                    <Row>
                                                                        <Col sm={12} md={6} xl={3}>
                                                                            <FormGroup>
                                                                                <Form.Label>
                                                                                    {this.props.t('page.products.basicData.pvPower')}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder={this.props.t('page.products.basicData.placeholder.pvPower')}
                                                                                    value={selectedObj?.pvPower || ""} onChange={(e) => { this.editItem(this.val(e), 'pvPower') }}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col sm={12} md={6} xl={3}>
                                                                            <FormGroup>
                                                                                <Form.Label>
                                                                                    {this.props.t('page.products.basicData.pvWidth')}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder={this.props.t('page.products.basicData.placeholder.pvWidth')}
                                                                                    value={selectedObj?.pvWidth || ""} onChange={(e) => { this.editItem(this.val(e), 'pvWidth') }}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col sm={12} md={6} xl={3}>
                                                                            <FormGroup>
                                                                                <Form.Label>
                                                                                    {this.props.t('page.products.basicData.pvHeight')}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder={this.props.t('page.products.basicData.placeholder.pvHeight')}
                                                                                    value={selectedObj?.pvHeight || ""} onChange={(e) => { this.editItem(this.val(e), 'pvHeight') }}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col sm={12} md={6} xl={3}>
                                                                            <FormGroup>
                                                                                <Form.Label>
                                                                                    {this.props.t('page.products.basicData.pvThick')}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder={this.props.t('page.products.basicData.placeholder.pvThick')}
                                                                                    value={selectedObj?.pvThick || ""} onChange={(e) => { this.editItem(this.val(e), 'pvThick') }}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.unit')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.unit')}
                                                                    value={selectedObj?.unit || ""} onChange={(e) => { this.editItem(this.val(e), 'unit') }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.variable')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.variable')}
                                                                    value={selectedObj?.variable || ""} onChange={(e) => { this.editItem(this.val(e), 'variable') }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.amountAuto')}
                                                                    <Button variant="link" className="px-5" onClick={(e) => { e.preventDefault(); this.setState({ modal: true }) }}>
                                                                        <HelpCircle size={12} />
                                                                    </Button>
                                                                </Form.Label>
                                                                <DebounceInput
                                                                    className={
                                                                        selectedObj.amountAuto
                                                                            &&
                                                                            this.props.utils.calculate(
                                                                                { ...definedVariables },
                                                                                selectedObj.amountAuto,
                                                                                true
                                                                            ) === this.props.utils.calculationGoneWrongValue
                                                                            ? 'form-control is-invalid' : 'form-control is-valid'
                                                                    }
                                                                    element="textarea"
                                                                    minLength={0}
                                                                    debounceTimeout={500}
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.amountAuto')}
                                                                    value={selectedObj?.amountAuto || ""}
                                                                    disabled={this.props.isLoading}
                                                                    onChange={(e) => { this.editItem(this.val(e), 'amountAuto') }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                            :
                                            <></>
                                    }

                                    {
                                        selectedLvl === 3
                                            ?
                                            (
                                                <>

                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.bcId')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.bcId')}
                                                                    value={selectedObj?.bcId || ""} onChange={(e) => { this.editItem(this.val(e), 'bcId') }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.type')}
                                                                </Form.Label>
                                                                <Form.Control as="select" value={selectedObj?.type || ""} onChange={(e) => { this.editItem(this.val(e), 'type') }} >
                                                                    <option value="">{this.props.t('page.products.basicData.productType.empty')}</option>
                                                                    {
                                                                        (this.state.productTypes || []).map(productType => {
                                                                            return (
                                                                                <option key={productType} value={productType}>{productType}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Control>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.unit')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.unit')}
                                                                    value={selectedObj?.unit || ""} onChange={(e) => { this.editItem(this.val(e), 'unit') }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.costPerUnit')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.costPerUnit')}
                                                                    value={selectedObj?.costPerUnit || ""} onChange={(e) => { this.editItem(this.val(e), 'costPerUnit') }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.minMargin')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    min="0"
                                                                    max="99.9999"
                                                                    step="0.01"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.minMargin')}
                                                                    value={selectedObj?.minMargin || ""} onChange={(e) => { this.editItem(this.val(e), 'minMargin') }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.defaultMargin')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    min="0"
                                                                    max="99.9999"
                                                                    step="0.01"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.defaultMargin')}
                                                                    value={selectedObj?.defaultMargin || ""} onChange={(e) => { this.editItem(this.val(e), 'defaultMargin') }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.maxMargin')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    min="0"
                                                                    max="99.9999"
                                                                    step="0.01"
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.maxMargin')}
                                                                    value={selectedObj?.maxMargin || ""} onChange={(e) => { this.editItem(this.val(e), 'maxMargin') }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Form.Label>
                                                                    {this.props.t('page.products.basicData.amountAuto')}
                                                                    <Button variant="link" className="px-5" onClick={(e) => { e.preventDefault(); this.setState({ modal: true }) }}>
                                                                        <HelpCircle size={12} />
                                                                    </Button>
                                                                </Form.Label>
                                                                <DebounceInput
                                                                    className={
                                                                        selectedObj.amountAuto
                                                                            &&
                                                                            this.props.utils.calculate(
                                                                                { ...definedVariables },
                                                                                selectedObj.amountAuto,
                                                                                true
                                                                            ) === this.props.utils.calculationGoneWrongValue
                                                                            ? 'form-control is-invalid' : 'form-control is-valid'
                                                                    }
                                                                    element="textarea"
                                                                    minLength={0}
                                                                    debounceTimeout={500}
                                                                    placeholder={this.props.t('page.products.basicData.placeholder.amountAuto')}
                                                                    value={selectedObj?.amountAuto || ""}
                                                                    disabled={this.props.isLoading}
                                                                    onChange={(e) => { this.editItem(this.val(e), 'amountAuto') }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                            :
                                            <></>
                                    }
                                </div>
                            </Tab>

                            <Tab eventKey="description" title={this.props.t('page.products.description.title')} disabled={selectedLvl !== 2}>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group>
                                            <TextEditor
                                                required={false}
                                                rows={3}
                                                editorId="product-description-editor"
                                                value={selectedObj?.descriptionIfIncluded || ""}
                                                onChange={
                                                    (html) => {
                                                        this.editItem(html, 'descriptionIfIncluded')
                                                    }
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Tab>

                        </Tabs>
                        <div className="border-top mt-20 pt-20">
                            <button
                                type="submit"
                                className="btn btn-uniform btn-success"
                                disabled={loading}
                            >
                                <Icon name="save" />
                                <span className="pl-10">
                                    {this.props.t('page.products.saveData')}
                                </span>
                            </button>

                            <button
                                type="button"
                                className="btn btn-uniform btn-default float-right"
                                disabled={loading}
                                onClick={(e) => { this.selectItem(null, null) }}
                            >
                                <Icon name="x" />
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        )

        return (
            <Fragment>

                <ReactTooltip />

                <Modal
                    show={this.state.modalSync}
                    onHide={() => { this.setState({ modalSync: false, syncAll: false, syncItems: [] }) }}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.products.basicData.sync.title')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table striped bordered hover responsive size="sm" className="sticky-thead">
                            <thead>
                                <tr>
                                    <th className='px-5 py-2' style={{ zIndex: 2, background: '#fff', minWidth: 90 }}>ID</th>
                                    <th className='px-5 py-2' style={{ zIndex: 2, background: '#fff' }}>{this.props.t('page.products.basicData.sync.product')}</th>
                                    <th className='px-5 py-2' style={{ zIndex: 2, background: '#fff', minWidth: 120 }}>{this.props.t('page.products.basicData.sync.oldPrice')}</th>
                                    <th className='px-5 py-2' style={{ zIndex: 2, background: '#fff', minWidth: 120 }}>{this.props.t('page.products.basicData.sync.newPrice')}</th>
                                    <th className='px-5 py-2' style={{ zIndex: 2, background: '#fff', minWidth: 100 }}>{this.props.t('page.products.basicData.sync.baseUnitOfMeasure')}</th>
                                    <th className='px-5 py-2' style={{ zIndex: 2, background: '#fff', minWidth: 100 }}>{this.props.t('page.products.basicData.sync.difference')}</th>
                                    <th className='px-5 py-2 text-center' style={{ zIndex: 2, background: '#fff' }}>
                                        <Form.Check
                                            id={`sync-all`}
                                            type="checkbox"
                                            custom={true}
                                            checked={syncAll}
                                            onChange={(e) => { this.toggleSync('all') }}
                                            label='SYNC'
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.syncPreview.map(itm => (
                                    <tr key={`sync-preview-${itm.uuid}`}
                                        style={{
                                            background: Math.abs(this.parseFloatFixed(itm.costPerUnitDifference)) > 0 && !itm.error ? "#dfeeee" : 'transparent'
                                        }}>
                                        <td className='px-5 py-2'>{itm.sync ? `✔ ` : `✗ `}{itm.bcId}</td>
                                        <td className='px-5 py-2'>{itm.label.split('|').map((itmLabel, itmLabelIndex) => {
                                            if (!itmLabel) {
                                                return null;
                                            }
                                            if (itmLabelIndex < 2) {
                                                return <small key={itmLabel + '-' + itmLabelIndex}>{itmLabel}{' - '}</small>
                                            }
                                            return <small key={itmLabel + '-' + itmLabelIndex}><strong>{itmLabel}</strong></small>
                                        })}</td>
                                        <td className='px-5 py-2'>{itm.costPerUnit}</td>
                                        {
                                            itm.error ? (
                                                <td className='px-5 py-2' colSpan="4">{itm.error}</td>
                                            ) : (
                                                <>
                                                    <td className='px-5 py-2'>{itm.newCostPerUnit}</td>
                                                    <td className='px-5 py-2'>{itm.baseUnitOfMeasure}</td>
                                                    <td className='px-5 py-2 strong'>{itm.costPerUnitDifference}</td>
                                                    <td className='px-5 py-2 text-center'>
                                                        {
                                                            itm.sync ? (
                                                                <Form.Check
                                                                    id={`sync-itm-${itm.uuid}`}
                                                                    type="checkbox"
                                                                    checked={syncItems.includes(itm.uuid)}
                                                                    onChange={(e) => { this.toggleSync(itm.uuid) }}
                                                                />
                                                            ) : null
                                                        }
                                                    </td>
                                                </>
                                            )
                                        }
                                    </tr>
                                ))}
                                {this.state.modalSyncLoading && (
                                    <tr>
                                        <td colSpan={7} className="text-center">
                                            <Spinner animation="border" />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer className="p-20 border-top bg-white" style={{ position: 'sticky', bottom: 0 }}>
                        <Button
                            variant="info"
                            disabled={this.state.modalSyncLoading}
                            className="mr-10"
                            onClick={() => {
                                this.getSyncPreview()
                            }}>
                            {this.props.t('common.operation.refresh')}
                        </Button>
                        <Button
                            variant="warning"
                            disabled={this.state.modalSyncLoading || !syncItems.length}
                            onClick={() => {
                                this.syncMaybeExecute(true)
                            }}>
                            {this.props.t('common.operation.sync')}
                            {` (${syncItems.length})`}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modal}
                    onHide={() => { this.setState({ modal: false }) }}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.products.basicData.amountAutoHint.title')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mb-10">
                            {this.props.t('page.products.basicData.amountAutoHint.line0')}
                        </p>
                        <p className="mb-10">
                            <samp>
                                {this.props.t('page.products.basicData.amountAutoHint.line1')}
                            </samp>
                        </p>
                        <p className="mb-10">
                            {this.props.t('page.products.basicData.amountAutoHint.line2')}
                        </p>
                        <ul className="mb-15 pre code" style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', padding: 10, overflowX: 'scroll', listStyle: 'none' }}>
                            {
                                Object.keys({ ...definedVariables }).map(defvar =>
                                    <li className='pr-20' key={`hint-${defvar}`}>{this.props.t(`page.products.basicData.amountAutoHint.${defvar}`, defvar)}</li>
                                )
                            }
                        </ul>
                        <p className="mb-10">
                            {this.props.t('page.products.basicData.amountAutoHint.line3')}
                        </p>
                        <p className="mb-10">
                            {this.props.t('page.products.basicData.amountAutoHint.line4')}
                        </p>
                        <p className="mb-10">
                            {this.props.t('page.products.basicData.amountAutoHint.line5')}
                        </p>
                        <p className="mb-10">
                            <samp>
                                {this.props.t('page.products.basicData.amountAutoHint.line6')}
                            </samp>
                        </p>
                        <p className="mb-10">
                            {this.props.t('page.products.basicData.amountAutoHint.line7')}
                        </p>
                        <p className="mb-10">
                            <samp>
                                {this.props.t('page.products.basicData.amountAutoHint.line8')}
                            </samp>
                        </p>
                        <p className="mb-0">
                            {this.props.t('page.products.basicData.amountAutoHint.line9')}
                        </p>
                    </Modal.Body>
                </Modal>

                <div className="row border py-10">
                    <div className="col-sm-12 justify-content-between">
                        <div className="row justify-content-between">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center">
                                    <div className="input-group-prepend">
                                        <button
                                            type="button"
                                            disabled={loading}
                                            className="btn btn-uniform text-success mr-10"
                                            onClick={(e) => {
                                                this.maybeSave(e)
                                            }}
                                        >
                                            <Icon name="save" />
                                            <span className="pl-10">
                                                {this.props.t('common.operation.save')}
                                            </span>
                                        </button>
                                    </div>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <button
                                                type="button"
                                                disabled={loading}
                                                className="btn btn-uniform text-info mr-10"
                                                onClick={(e) => {
                                                    this.addItem(null, 0);
                                                    this.maybeScrollPageToBottom()
                                                }}
                                            >
                                                <Icon name="plus-square" />
                                                <span className="pl-10">
                                                    {this.props.t('page.products.newCategory')}
                                                </span>
                                            </button>
                                        </div>
                                        <div className="input-group-prepend">
                                            <button
                                                type="button"
                                                disabled={loading}
                                                className="btn btn-uniform text-secondary mr-10"
                                                onClick={(e) => {
                                                    this.export()
                                                }}
                                            >
                                                <Icon name="download" />
                                                <span className="pl-10">
                                                    {this.props.t('common.operation.export')}
                                                </span>
                                            </button>
                                        </div>
                                        <div className="input-group-prepend" style={{ marginLeft: "auto" }}>
                                            <button
                                                type="button"
                                                disabled={loading}
                                                className="btn btn-uniform text-warning mr-10"
                                                onClick={(e) => {
                                                    this.showSyncModal()
                                                }}
                                            >
                                                <Icon name="refresh-cw" />
                                                <span className="pl-10">
                                                    {this.props.t('common.operation.sync')}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row border-left border-bottom border-right">

                    <div className={classnames('col-sm-12 overflow-auto p-0', _.isEmpty(selectedObj) ? 'col-md-12' : 'col-md-4 border-right')}>
                        {
                            downloading
                                ?
                                (
                                    <div className="text-center p-30">
                                        <Spinner animation="border" />
                                    </div>
                                )
                                :
                                (
                                    <Tree
                                        tree={tree}
                                        lastChange={lastChange}
                                        setTheTree={(data) => this.setTheTree(data)}
                                        getLabel={(obj, level, dragProps) => this.getLabel(obj, level, dragProps)}
                                        loopThrough={(fn) => this.loopThrough(fn)}
                                        selectedPath={selectedPath}
                                    />
                                )
                        }
                    </div>

                    {objEditor}

                </div>
            </Fragment >
        );
    }
}

export default Content
