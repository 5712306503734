import React, { Fragment } from "react";
import GoogleMapReact from 'google-map-react';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Col, Row } from "react-bootstrap";
import BaseComponent from "../component/base";
import { publicPaths } from "../../constants";

class SimpleMap extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            mapStyles: [],
            inited: false,
        }
        this.map = null;
        this.maps = null;
        this.mapCenter = props.defaultCenter;
        this.mapZoom = props.defaultZoom || 20;
        this.renderedMarkers = [];
        this.polygons = [];
        this.renderedPolygons = [];
        this.renderedPolygonsLabels = [];
        this.locationMarkerInfoBox = null;
        this.markerClusterer = null;
    }

    componentDidMount() {
        fetch("/assets/data/map-styles.json")
            .then((res) => res.json())
            .then((data) => this.setState({ mapStyles: data }));
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.map) {
            if (!this.state.inited || !_.isEqual(nextProps.markers, this.props.markers)) {
                this.renderLocationMarkers(this.map, this.maps, nextProps.markers);
            }
            if (!this.state.inited || !_.isEqual(nextProps.polygons, this.props.polygons)) {
                this.renderPolygons(this.map, this.maps, nextProps.polygons);
            }
            if (!this.state.inited || !_.isEqual(nextProps.defaultCenter, this.props.defaultCenter)) {
                this.mapCenter = nextProps.defaultCenter;
                this.map.setCenter(this.mapCenter)
            }
            if (!this.state.inited || !_.isEqual(nextProps.defaultZoom, this.props.defaultZoom)) {
                this.mapZoom = nextProps.defaultZoom;
                this.map.setZoom(this.mapZoom)
            }
        }
        return true;
    }

    handleGoogleMapApi({ map, maps }) {
        this.map = map;
        this.maps = maps;
        this.map.setCenter(this.mapCenter);
        this.map.setZoom(this.mapZoom);

        this.renderLocationMarkers(map, maps, this.props.markers);
        this.renderPolygons(this.map, this.maps, this.props.polygons);
        this.setState({ inited: true });
    }

    removeMapAndObject(obj) {
        if (obj) {
            obj.setMap(null);
        }
        obj = null;
        return null;
    }

    createInfoBoxData(marker, data) {
        this.locationMarkerInfoBox = this.removeMapAndObject(this.locationMarkerInfoBox);
        this.locationMarkerInfoBox = new this.maps.InfoWindow({
            content: `<div style="padding: 10px; min-width: 200px;"><p style="margin: 10px 0"><strong>${marker.title}</strong></p><p style="margin: 10px 0">${data.content}</p></div>`,
        });
        this.locationMarkerInfoBox.open({
            anchor: marker,
            map: this.map,
            shouldFocus: true,
        });
    }

    renderLocationMarkers(map, maps, markers) {
        this.renderedMarkers = this.renderedMarkers.map((markerData) => {
            return this.removeMapAndObject(markerData);
        }).filter(Boolean);
        if (!markers || !markers.length || !maps || !map) {
            return;
        }
        markers.forEach((markerData) => {
            let newMarker = new maps.Marker({
                position: { lat: markerData.lat, lng: markerData.lng },
                map,
                title: markerData.title,
                content: markerData.content || '',
                icon: publicPaths.markers + 'marker_orange.png',
            });
            if (markerData.icon) {
                newMarker.setIcon(markerData.icon);
            }
            newMarker.addListener("click", () => {
                this.createInfoBoxData(newMarker, markerData);
            });
            this.renderedMarkers.push(newMarker)
        });
    }

    renderPolygons(map, maps, polygons) {
        this.renderedPolygons = this.renderedPolygons.map((polygonData) => {
            return this.removeMapAndObject(polygonData);
        }).filter(Boolean);
        this.renderedPolygonsLabels = this.renderedPolygonsLabels.map((markerData) => {
            return this.removeMapAndObject(markerData);
        }).filter(Boolean);
        if (!polygons || !polygons.length || !maps || !map) {
            return;
        }
        polygons.forEach((polygonData) => {
            let newPolygon = new maps.Polygon({
                path: polygonData.path,
                zIndex: 3,
                clickable: false,
                map,
                fillColor: '#333',
                fillOpacity: .5,
                strokeColor: "#333",
                strokeWeight: 1.5,
                ...polygonData.props || {},
            });
            this.renderedPolygons.push(newPolygon);

            var bounds = new google.maps.LatLngBounds();
            for (var i = 0; i < polygonData.path.length; i++) {
                bounds.extend(polygonData.path[i]);
            }
            var iconSize = polygonData.ident.length * 4;
            var mapLabel = new maps.Marker({
                map: map,
                fontSize: 14,
                align: "center",
                position: bounds.getCenter(),
                zIndex: 4,
                clickable: true,
                label: {
                    text: polygonData.ident,
                    color: "#fff",
                    ...polygonData.markerprops?.label || {},
                },
                icon: {
                    path: `M -10 -10 H ${iconSize} V 10 H -${iconSize} L -${iconSize} -10`,
                    fillColor: '#333',
                    fillOpacity: .95,
                    strokeWeight: 0,
                    scale: 1,
                    ...polygonData.markerprops?.icon || {},
                },
            });
            if (typeof polygonData.onClick !== 'function') {
                polygonData.onClick = () => { }
            }
            mapLabel.addListener("click", polygonData.onClick);
            this.renderedPolygonsLabels.push(mapLabel);
        });

        if (this.renderedPolygonsLabels.length > 1) {
            if (!this.markerClusterer) {
                this.markerClusterer = new MarkerClusterer({ map: this.map });
            }
            this.markerClusterer.clearMarkers();
            this.markerClusterer.addMarkers(this.renderedPolygonsLabels);
        }
    }

    render() {
        let maps = this.state.mapStyles.length ? (
            <GoogleMapReact
                bootstrapURLKeys={{ id: 'script-maps-googleapis' }}
                defaultCenter={this.mapCenter}
                defaultZoom={this.mapZoom}
                maxZoom={25}
                options={maps => ({
                    tilt: 0,
                    mapTypeControl: false,
                    disableDefaultUI: true,
                    rotateControl: false,
                    zoomControl: true,
                    scaleControl: true,
                    streetViewControl: false,
                    fullscreenControl: false,
                    styles: this.state.mapStyles
                })}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={this.handleGoogleMapApi.bind(this)}
            />
        ) : (<></>);

        return (
            <Row>
                <Col style={{ height: this.props.height || '50vh', width: '100%' }}>
                    {maps}
                </Col>
            </Row>
        )
    }

}

export default SimpleMap;