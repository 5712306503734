import './style.scss';
import _ from 'lodash';
import React, { Component } from 'react';

class Avatar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            avatar: this.props.user?.avatar,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextProps.user?.avatar, this.props.user?.avatar);
    }

    render() {
        return this.props.user?.avatar ? (
            <span>
                <img src={this.props.user?.avatar?.link} alt="" />
            </span>
        ) : (
            <span className="img">
                {this.props.utils.getInitials(this.props.user?.user?.firstName, this.props.user?.user?.lastName)}
            </span>
        );
    }
}

export default Avatar;
