import { DateTime, Interval } from "luxon";
import React, { Fragment } from 'react';
import { Alert, Badge, Button, Col, Form, ListGroup, Modal, Nav, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import BaseComponent from '../../components/component/base';
import Discussion from '../../components/discussion';
import Files from '../../components/files';
import Icon from '../../components/icon';
import MarginSmile from "../../components/margin-smile";
import SelectAsync from '../../components/select/async';
import TextEditor from '../../components/text-editor';
import Timeline from '../../components/timeline';
import getProto from '../../proto';
import Checklist from './checklist';
import ProjectSignature from "./project-signature";
import { Info } from "react-feather";
import ReactTooltip from "react-tooltip";

class ModalProject extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            __modified: false,

            isLoading: false,
            activeTab1: props.activeTab || "timeline",
            activeTab2: props.activeTab2 || "t2Primary",
            activeTab1Seen: [props.activeTab].filter(Boolean),
            reloadParent: false,
            isEditorVisible: false,
            showModalEvent: false,
            isEventSaving: false,
            checklistDefaults: [],

            projectuuid: props.data.projectuuid,
            projectbcid: '',
            projectlabel: '',
            projectsince: '',
            projectsincecontracted: '',
            projectcreated: '',

            projectpvblueprintaccepted: '',
            projectpvtype: '',
            projectpvamount: '',

            projectnps: '',
            projectnpsreasons: '',

            projectenergyplatformident: '',
            projectsolaredgeid: '',
            projectsolaredgekey: '',
            projectgridxid: '',

            projectpm: null,
            projectpmfullname: '',
            projectsm: null,
            projectbipvdesigner: null,
            projectelectricaldesigner: null,
            projectconstructionengineer: null,

            projectchecklist: [],
            projectdescription: '',
            projecttype: '',
            projectmapmarker: '',

            projectsubtype: '',
            projectpassivematerial: '',
            projectbackupinterface: '',
            projectbatteries: '',
            projectenergized: '',
            projecttill: '',

            projectstatus: '',
            contractors: [],
            contractorsEditable: false,
            tasks: [],

            addressaddressname: '',
            addressemailaddress: '',
            addressprovincename: '',
            addresspostalcode: '',
            addresscityname: '',
            addressstreetaddress: '',
            addressstreetnumber: '',
            addressapartmentnumber: '',
            addressmobilephone: '',
            addressgeolat: '',
            addressgeolng: '',
            addressfull: '',

            projectcontractsigned: '',
            projectpassivearea: '',
            projectpassiveareastacbond: '',
            projectpvorientation: '',
            projectassemblycompleted: '',
            projectevcharger: '',
            projectarea: '',
            projectsubarea: '',
            projectcontracttype: '',
            projecttimeconsumeddesign: '',
            projectgridregistered: '',
            projectconsumersuccessmanager: null,
            projectpmomanager: null,
            projectelectricconsumercare: null,
            projectcostcontroller: null,
            projectlogisticsmanager: null,
            projectdesignmanager: null,
            projectresigned: false,
            projectresignedreasons: '',
            projectclientinfopermit: false,
            projectdelayed: false,
            projectdelayedblocked: false,
            projectdelayedreasons: '',
            projectplannedassemblycompleted: '',
            projectreadytoaccomodate: '',

            offermarginpercent: 0,

            timeline: [],
            timelineActive: [],
            timelineActiveTop: 0,
            timelineActiveHeight: 0,
            timelineShowEmptyEvents: false,
            optionsets: {},

            eventuuid: null,
            eventident: '',
            eventcomment: '',
            eventdated: '',
            events: [],

            projectlownps: false,
            projectemptynps: false,
            projectdelaynoexplain: false,
            projectdelayunknown: false,

            projectmilestonesdone: false,

        }

        this.setFullAddress = _.debounce(this.setFullAddress.bind(this), 500);
        this.setTabEvent = this.setTabEvent.bind(this);
    }

    addNewEvent() {
        this.setState({
            showModalEvent: true,
            eventuuid: null,
            eventident: '',
            eventcomment: '',
            eventdated: DateTime.now().toISODate(),
        })
    }

    saveEvent() {
        const { eventuuid, eventident, eventcomment, eventdated, projectuuid } = this.state;
        this.setState({ isEventSaving: true }, () => {
            this.props.api.post(
                eventuuid ? `/event/update` : `/event/create`,
                {
                    "event": {
                        "uuid": eventuuid,
                        "ident": eventident,
                        "description": eventcomment,
                        "dated": eventdated,
                    },
                    "anchors": { "project": { "uuid": projectuuid } }
                },
                (data) => {
                    this.setState({ isEventSaving: false, eventuuid: '', eventident: '', eventcomment: '', eventdated: '', showModalEvent: false, }, () => {
                        this.fetchProjectEvents(() => {
                            this.setTimeline();
                        });
                    })
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        })
    }

    fetchProjectEvents(callback) {
        const { projectuuid } = this.state;
        this.props.api.post(
            `/event/list`,
            {
                "anchors": { "project": { "uuid": projectuuid } }
            },
            (data) => {
                this.setState({ events: data.events }, callback)
            },
            (errorObject) => {
                this.afterFetchError(errorObject);
            }
        );
    }

    componentDidMount() {
        window.addEventListener("SET_TAB", this.setTabEvent, false);
        this.setState({ isLoading: true }, () => {
            this.fetchConfig(() => {
                this.fetchProject(() => {
                    this.fetchProjectEvents(() => {
                        this.setTimeline(() => {
                            this.setTimelineActive();
                        });
                    });
                });
            })
        });
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        window.removeEventListener('SET_TAB', this.setTabEvent, false);
    }

    setTabEvent(e) {
        this.setTab(e.detail.tab, 'activeTab1', () => { });
    }

    fetchConfig(callback) {
        this.props.api.post(
            `/project/data`,
            {
                "file": {
                    "names": ["checklist", "optionsets"]
                }
            },
            (data) => {
                this.setState({ isLoading: false, optionsets: data.optionsets.project, checklistDefaults: data.checklist }, callback);
            },
            (errorObject) => {
                this.afterFetchError(errorObject);
            }
        );
    }

    fetchProject(callback) {
        const {
            projectuuid
        } = this.state;
        this.setState({ isLoading: true }, () => {
            this.props.api.post(
                `/project/describe`,
                {
                    project: {
                        uuid: projectuuid
                    }
                },
                (projectObject) => {
                    this.props.api.post(
                        `/task/list`,
                        {
                            filter: {
                                projectuuid: projectuuid
                            }
                        },
                        (tasksObject) => {
                            this.setState({ tasks: tasksObject.tasks }, () => {
                                this.afterFetchSuccess(projectObject, callback);
                            });
                        },
                        (errorObject) => {
                            this.afterFetchError(errorObject);
                        }
                    );
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        })
    }

    afterFetchSuccess(dataObject, callback) {

        const {
            checklistDefaults
        } = this.state;

        let newState = {
            __modified: false,
            __validated: false,
            isLoading: false,
            isEditorVisible: false,
            contractorsEditable: dataObject.contractors.length === 0,
            projectbcid: dataObject.project.bcId,
            projectcreated: dataObject.project.created,
            projectlabel: dataObject.project.label,
            projectsince: dataObject.project.since,
            projectsincecontracted: dataObject.project.sinceContracted,

            projectpm: dataObject.pm,
            projectpmfullname: dataObject.pm?.label,
            projectsm: dataObject.sm,
            projectbipvdesigner: dataObject.bipvDesigner,
            projectelectricaldesigner: dataObject.electricalDesigner,
            projectconstructionengineer: dataObject.constructionEngineer,

            projectchecklist: (dataObject.project.checklist || []),
            projectdescription: dataObject.project.description,
            projecttype: dataObject.project.type,
            projectmapmarker: dataObject.project.mapMarker,

            projectsubtype: dataObject.project.subType,
            projectpassivematerial: dataObject.project.passiveMaterial,
            projectbackupinterface: dataObject.project.backupInterface,
            projectbatteries: dataObject.project.batteries,
            projectenergized: dataObject.project.energized,
            projecttill: dataObject.project.till,

            projectstatus: dataObject.project.status,
            projectpvblueprintaccepted: dataObject.project.pvBlueprintAccepted,
            projectpvtype: dataObject.project.pvType,
            projectpvamount: dataObject.project.pvAmount,
            projectnps: dataObject.project.nps,
            projectnpsreasons: dataObject.project.npsReasons,
            projectenergyplatformident: dataObject.project.energyPlatformIdent,
            projectsolaredgeid: dataObject.project.solarEdgeId,
            projectsolaredgekey: dataObject.project.solarEdgeKey,
            projectgridxid: dataObject.project.gridXId,
            contractors: dataObject.contractors,

            addressfull: `${dataObject.address.postalCode || ''} ${dataObject.address.cityName || ''}, ${dataObject.address.streetAddress || ''} ${dataObject.address.streetNumber || ''}`,
            addressaddressname: dataObject.address.addressName,
            addressemailaddress: dataObject.address.emailAddress,
            addressprovincename: dataObject.address.provinceName,
            addresspostalcode: dataObject.address.postalCode,
            addresscityname: dataObject.address.cityName,
            addressstreetaddress: dataObject.address.streetAddress,
            addressstreetnumber: dataObject.address.streetNumber,
            addressapartmentnumber: dataObject.address.apartmentNumber,
            addressmobilephone: dataObject.address.mobilePhone,
            addressgeolat: dataObject.address.geoLat,
            addressgeolng: dataObject.address.geoLng,

            projectcontractsigned: dataObject.project.contractSigned,
            projectpassivearea: dataObject.project.passiveArea,
            projectpassiveareastacbond: dataObject.project.passiveAreaStacbond,
            projectpvorientation: dataObject.project.pvOrientation,
            projectassemblycompleted: dataObject.project.assemblyCompleted,
            projectevcharger: dataObject.project.evCharger,
            projectarea: dataObject.project.area,
            projectsubarea: dataObject.project.subArea,
            projectcontracttype: dataObject.project.contractType,
            projecttimeconsumeddesign: dataObject.project.timeConsumedDesign,
            projectgridregistered: dataObject.project.gridRegistered,
            projectconsumersuccessmanager: dataObject.consumerSuccessManager,
            projectpmomanager: dataObject.pmoManager,
            projectelectricconsumercare: dataObject.electricConsumerCare,
            projectcostcontroller: dataObject.costController,
            projectlogisticsmanager: dataObject.logisticsManager,
            projectdesignmanager: dataObject.designManager,
            projectresigned: dataObject.project.resigned || false,
            projectresignedreasons: dataObject.project.resignedReasons || '',
            projectdelayed: dataObject.project.delayed || false,
            projectdelayedreasons: dataObject.project.delayedReasons || '',
            projectclientinfopermit: dataObject.project.clientInfoPermit || false,
            projectplannedassemblycompleted: dataObject.project.plannedAssemblyCompleted,
            projectreadytoaccomodate: dataObject.project.readyToAccomodate,

            offermarginpercent: dataObject.offer?.marginPercent || 0,

            projectmilestonesdone: dataObject.project.milestonesDone || false,
        };


        const cd = (checklistDefaults['_'] || {});
        const useFlatStatus = true;
        newState.projectchecklist = newState.projectchecklist.map(itm => {
            const userUuid = this.props.getUser()?.user?.uuid;
            let allowedFor = (itm.owners || []);
            let isDisabled = false;
            let newTip = itm.tip;

            for (let defaultChecklistItmTitle in cd) {
                if (defaultChecklistItmTitle === itm.title || cd[defaultChecklistItmTitle]._uuid === itm._uuid) {
                    newTip = cd[defaultChecklistItmTitle].tip || newTip;
                    let allowedForLocal = (cd[defaultChecklistItmTitle].owners || []);
                    allowedFor = [...allowedForLocal];
                }
            }

            allowedFor.forEach(pp => {
                if (pp in newState) {
                    if (newState[pp]?.uuid === userUuid) {
                        isDisabled = false;
                    }
                }
            });

            if (this.props.hasPermission(['VIEW_PROJECT_MILESTONES_EDIT']).any) {
                isDisabled = false;
            }

            return { ...itm, disabled: isDisabled, tip: newTip };
        });

        this.setState(
            newState,
            () => {
                this.setBlockers(callback);
            }
        );
    }

    afterFetchError(errorObject) {
        this.setState({ isLoading: false }, () => {
            this.props.showToast({
                errorObject: errorObject,
                title: this.props.t('common.toast.error'),
                color: 'danger'
            });
        })
    }

    getTimelineSorted(timeline) {
        return [...timeline]
            .map(itm => DateTime.fromISO(itm))
            .sort((a, b) => a - b)
            .map(itm => itm.toISODate());
    }

    setTimelineActive(callback) {
        let newTimelineActive = [...this.state.timelineActive];
        if (newTimelineActive.length > 1) {
            let minDate = DateTime.fromISO(newTimelineActive[0]);
            let maxDate = DateTime.fromISO(newTimelineActive[0]);

            newTimelineActive.forEach(date => {
                let iso = DateTime.fromISO(date);
                if (iso < minDate) {
                    minDate = iso;
                }
                if (iso > maxDate) {
                    maxDate = iso;
                }
            });

            let minElement = document.getElementsByClassName(`tl-itm-${minDate.toISODate()}`);
            let maxElement = document.getElementsByClassName(`tl-itm-${maxDate.toISODate()}`);

            if (minElement && minElement.length > 0 && maxElement && maxElement.length > 0) {
                this.setState({
                    timelineActiveTop: minElement[0].offsetTop + 30,
                    timelineActiveHeight: maxElement[0].offsetTop - minElement[0].offsetTop,
                }, callback)
            }
        } else {
            this.setState({
                timelineActiveTop: 0,
                timelineActiveHeight: 0
            }, callback)
        }
    }

    toggleActiveTimelineItem(e, date, callback) {
        if (e) {
            e.preventDefault();
        }

        let newTimelineActive = [...this.state.timelineActive];
        if (date) {
            if (newTimelineActive.includes(date)) {
                newTimelineActive = newTimelineActive.filter(itm => itm !== date);
            } else {
                newTimelineActive.push(date);
            }
        }

        this.setState({ timelineActive: this.getTimelineSorted(newTimelineActive) }, () => {
            this.setTimelineActive(callback);
        });
    }

    setTimeline(callback) {
        let autodates = [
            {
                'date': this.state.projectcreated ? DateTime.fromISO(this.state.projectcreated) : null,
                'header': this.props.t('page.scheduler.modal.projectDetails.tabs.details.created').replace(':', '')
            },
            {
                'date': this.state.projectcontractsigned ? DateTime.fromISO(this.state.projectcontractsigned) : null,
                'header': this.props.t('page.scheduler.modal.projectDetails.tabs.details.contractSigned').replace(':', '')
            },
            {
                'date': this.state.projectsincecontracted ? DateTime.fromISO(this.state.projectsincecontracted) : null,
                'header': this.props.t('page.scheduler.modal.projectDetails.tabs.details.sinceContracted').replace(':', '')
            },
            {
                'date': this.state.projectsince ? DateTime.fromISO(this.state.projectsince) : null,
                'header': this.props.t('page.scheduler.modal.projectDetails.tabs.details.since').replace(':', '')
            },
            {
                'date': this.state.projectassemblycompleted ? DateTime.fromISO(this.state.projectassemblycompleted) : null,
                'header': this.props.t('page.scheduler.modal.projectDetails.tabs.details.assemblyCompleted').replace(':', '')
            },
            {
                'date': this.state.projectpvblueprintaccepted ? DateTime.fromISO(this.state.projectpvblueprintaccepted) : null,
                'header': this.props.t('page.scheduler.modal.projectDetails.tabs.details.pvBlueprintAccepted').replace(':', '')
            },
            {
                'date': this.state.projectenergized ? DateTime.fromISO(this.state.projectenergized) : null,
                'header': this.props.t('page.scheduler.modal.projectDetails.tabs.details.energized').replace(':', '')
            },
            {
                'date': this.state.projectgridregistered ? DateTime.fromISO(this.state.projectgridregistered) : null,
                'header': this.props.t('page.scheduler.modal.projectDetails.tabs.details.gridRegistered').replace(':', '')
            },
            {
                'date': this.state.projectplannedassemblycompleted ? DateTime.fromISO(this.state.projectplannedassemblycompleted) : null,
                'header': this.props.t('page.scheduler.modal.projectDetails.tabs.details.plannedAssemblyCompleted').replace(':', '')
            },
            {
                'date': this.state.projectreadytoaccomodate ? DateTime.fromISO(this.state.projectreadytoaccomodate) : null,
                'header': this.props.t('page.scheduler.modal.projectDetails.tabs.details.readyToAccomodate').replace(':', '')
            },
            {
                'date': this.state.projecttill ? DateTime.fromISO(this.state.projecttill) : null,
                'header': this.props.t('page.scheduler.modal.projectDetails.tabs.details.till').replace(':', '')
            },
            {
                'date': DateTime.local(),
                'header': this.props.t('common.text.today').replace(':', '')
            }
        ];

        this.state.events.forEach(eventItem => {
            const evtEvt = eventItem.event;
            const evtUsr = eventItem.user;
            if (evtEvt.dated && evtEvt.ident) {
                autodates.push({
                    'date': DateTime.fromISO(evtEvt.dated),
                    'header': <span className="bg-danger text-white">{evtEvt.ident}</span>,
                    'comment': <span>{[evtUsr?.label || 'SYSTEM', evtEvt.description].filter(Boolean).join(': ')}</span>,
                });
            }
        });

        this.state.projectchecklist.forEach(checklistItem => {
            if (checklistItem.done) {
                autodates.push({
                    'date': DateTime.fromISO(checklistItem.date),
                    'header': [checklistItem.title, checklistItem.status].filter(Boolean).join(': '),
                });
            }
        });

        let minDate = DateTime.local();
        let maxDate = DateTime.local();

        autodates = autodates.filter(itm => itm.date !== null);
        autodates.forEach(itm => {
            if (itm.date < minDate) {
                minDate = itm.date;
            }
            if (itm.date > maxDate) {
                maxDate = itm.date;
            }
        })

        const dates =
            Interval.fromDateTimes(
                minDate.minus({ days: 1 }).startOf('day'),
                maxDate.plus({ days: 1 }).endOf('day')
            )
                .splitBy({ day: 1 })
                .map(d => d.start);

        let timeline = {};
        let timelineActive = [];
        dates.forEach(date => {
            timeline[date.toISODate()] = {
                date: date,
                events: [],
            };
        });

        autodates.filter(itm => itm.date).forEach(itm => {
            timelineActive.push(itm.date.toISODate());
            if (!timeline[itm.date.toISODate()]) {
                timeline[itm.date.toISODate()] = {
                    date: itm.date,
                    events: [],
                };
            }
            timeline[itm.date.toISODate()].events.push({
                header: itm.header,
                content: itm.date.toLocaleString({ weekday: 'long', month: 'long', day: '2-digit' }),
                comment: itm.comment || '',
            });
        })

        this.setState({
            timeline: Object.values(timeline),
            timelineActive: this.getTimelineSorted([...new Set(timelineActive)]),
        }, callback);
    }

    setFullAddress(callback) {
        const {
            addresspostalcode,
            addresscityname,
            addressstreetaddress,
            addressstreetnumber,
            addressfull
        } = this.state;

        if (typeof callback === 'undefined') {
            callback = () => { }
        }

        if (!addresspostalcode && !addressstreetaddress) {
            callback();
            return;
        }

        let newaddressfull = `${addresspostalcode} ${addresscityname}, ${addressstreetaddress} ${addressstreetnumber}`;

        if (addressfull === newaddressfull) {
            callback();
            return;
        }

        this.setState(
            {
                addressfull: newaddressfull
            },
            () => {
                geocodeByAddress(newaddressfull)
                    .then((results) => getLatLng(results[0]))
                    .then((latLng) => {
                        this.setState(
                            {
                                addressgeolat: latLng.lat,
                                addressgeolng: latLng.lng,
                            },
                            () => {
                                callback();
                            }
                        )
                    })
                    .catch((error) => {
                        this.props.showToast({
                            title: this.props.t('common.toast.mapError'),
                            color: 'danger'
                        });
                    });
            });
    }

    saveProject(closePopup) {
        const {
            projectuuid,
            projectlabel,
            projectsince,
            projectsincecontracted,
            projectpm,
            projectsm,
            projectbipvdesigner,
            projectelectricaldesigner,
            projectconstructionengineer,
            projectchecklist,
            projectdescription,
            projecttype,
            projectmapmarker,
            projectsubtype,
            projectpassivematerial,
            projectbackupinterface,
            projectbatteries,
            projectenergized,
            projecttill,
            contractors,
            projectstatus,
            projectpvblueprintaccepted,
            projectpvtype,
            projectpvamount,
            projectnps,
            projectnpsreasons,
            projectenergyplatformident,
            projectsolaredgeid,
            projectsolaredgekey,
            projectgridxid,
            addressaddressname,
            addressemailaddress,
            addressprovincename,
            addresspostalcode,
            addresscityname,
            addressstreetaddress,
            addressstreetnumber,
            addressapartmentnumber,
            addressmobilephone,
            addressgeolat,
            addressgeolng,

            projectcontractsigned,
            projectpassivearea,
            projectpassiveareastacbond,
            projectpvorientation,
            projectassemblycompleted,
            projectevcharger,
            projectarea,
            projectsubarea,
            projectcontracttype,
            projecttimeconsumeddesign,
            projectgridregistered,

            projectconsumersuccessmanager,
            projectpmomanager,
            projectelectricconsumercare,
            projectcostcontroller,
            projectlogisticsmanager,
            projectdesignmanager,
            projectresigned,
            projectresignedreasons,
            projectdelayed,
            projectdelayedreasons,
            projectclientinfopermit,
            projectplannedassemblycompleted,
            projectreadytoaccomodate,
            projectmilestonesdone,
        } = this.state;
        this.setState({ isLoading: true, reloadParent: true }, () => {
            this.props.api.post(
                `/project/update`,
                {
                    project: {
                        uuid: projectuuid,
                        label: projectlabel,
                        description: projectdescription,
                        checklist: projectchecklist,
                        status: projectstatus,
                        type: projecttype,
                        mapMarker: projectmapmarker,
                        since: projectsince,
                        sinceContracted: projectsincecontracted,
                        pvBlueprintAccepted: projectpvblueprintaccepted,
                        pvType: projectpvtype,
                        pvAmount: projectpvamount,
                        nps: projectnps,
                        npsReasons: projectnpsreasons,
                        energyPlatformIdent: projectenergyplatformident,
                        solarEdgeId: projectsolaredgeid,
                        solarEdgeKey: projectsolaredgekey,
                        gridXId: projectgridxid,
                        subType: projectsubtype,
                        passiveMaterial: projectpassivematerial,
                        backupInterface: projectbackupinterface,
                        batteries: projectbatteries,
                        energized: projectenergized,
                        till: projecttill,

                        contractSigned: projectcontractsigned,
                        passiveArea: projectpassivearea,
                        passiveAreaStacbond: projectpassiveareastacbond,
                        pvOrientation: projectpvorientation,
                        assemblyCompleted: projectassemblycompleted,
                        evCharger: projectevcharger,
                        area: projectarea,
                        subArea: projectsubarea,
                        contractType: projectcontracttype,
                        timeConsumedDesign: projecttimeconsumeddesign,
                        gridRegistered: projectgridregistered,
                        resigned: projectresigned,
                        resignedReasons: projectresignedreasons,
                        delayed: projectdelayed,
                        delayedReasons: projectdelayedreasons,
                        clientInfoPermit: projectclientinfopermit,

                        plannedAssemblyCompleted: projectplannedassemblycompleted,
                        readyToAccomodate: projectreadytoaccomodate,

                        milestonesDone: projectmilestonesdone,
                    },
                    address: {
                        addressName: addressaddressname,
                        emailAddress: addressemailaddress,
                        provinceName: addressprovincename,
                        postalCode: addresspostalcode,
                        cityName: addresscityname,
                        streetAddress: addressstreetaddress,
                        streetNumber: addressstreetnumber,
                        apartmentNumber: addressapartmentnumber,
                        mobilePhone: addressmobilephone,
                        geoLat: addressgeolat,
                        geoLng: addressgeolng
                    },
                    pm: {
                        uuid: projectpm?.uuid
                    },
                    sm: {
                        uuid: projectsm?.uuid
                    },
                    bipvDesigner: {
                        uuid: projectbipvdesigner?.uuid
                    },
                    electricalDesigner: {
                        uuid: projectelectricaldesigner?.uuid
                    },
                    constructionEngineer: {
                        uuid: projectconstructionengineer?.uuid
                    },
                    contractors: contractors,

                    consumerSuccessManager: {
                        uuid: projectconsumersuccessmanager?.uuid
                    },
                    pmoManager: {
                        uuid: projectpmomanager?.uuid
                    },
                    electricConsumerCare: {
                        uuid: projectelectricconsumercare?.uuid
                    },
                    costController: {
                        uuid: projectcostcontroller?.uuid
                    },
                    logisticsManager: {
                        uuid: projectlogisticsmanager?.uuid
                    },
                    designManager: {
                        uuid: projectdesignmanager?.uuid
                    },
                    notify: {
                        url: window.location.href,
                    }
                },
                (dataObject) => {
                    this.afterFetchSuccess(dataObject);
                    if (typeof this.props.onAfterSubmit === 'function') {
                        this.props.onAfterSubmit(dataObject, closePopup);
                    }
                },
                (errorObject) => {
                    this.afterFetchError(errorObject);
                }
            );
        })
    }

    createContractor() {
        const { contractors } = this.state;
        let newContractors = [...contractors];
        let newContractor = getProto('contractor');
        newContractors.push(newContractor);
        this.setState({
            contractors: newContractors,
        });
    }

    assignTeamToContractor(contractoruuid, newteam) {
        const { contractors } = this.state;
        const exists = contractors.find(itm => itm.team.uuid === newteam.uuid);
        const newContractors = contractors.map(contractorObj => {
            let newContractorObj = { ...contractorObj };
            if (newContractorObj.contractor.uuid === contractoruuid) {
                newContractorObj.contractor.label = newteam.label;
                newContractorObj.contractor.category = newteam.category;
                newContractorObj.contractor.color = newteam.color;
                newContractorObj.team = newteam;
                newContractorObj.contractor.trash = exists ? true : false;
            }
            return newContractorObj;
        });
        this.setState({
            contractors: newContractors,
        });
    }

    setContractor(contractoruuid, field, value, explicit) {
        const { contractors } = this.state;
        const newContractors = contractors.map(contractorObj => {
            let newContractorObj = { ...contractorObj };
            if (newContractorObj.contractor.uuid === contractoruuid) {
                newContractorObj.contractor[field] = explicit ? value : this.val(value);
            }
            return newContractorObj;
        });
        this.setState({
            contractors: newContractors,
        });
    }

    removeContractor(contractoruuid) {
        this.setContractor(contractoruuid, 'trash', true, true);
    }

    setTab(tabName, sectionName, callback) {
        const { projectuuid } = this.state;
        if (sectionName === 'activeTab1') {
            this.props.openLink(`/scheduler/#/project/${projectuuid}/${tabName}/`, '_hash');
        }
        this.setState({ [sectionName]: tabName, activeTab1Seen: [...this.state.activeTab1Seen, tabName] }, callback);
    }

    confirmHide() {
        if (this.state.__modified) {
            this.confirm(
                {
                    title: this.props.t(`common.confirm.quitNotSaving.title`),
                    text: this.props.t(`common.confirm.quitNotSaving.text`),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ffc107',
                    confirmButtonText: this.props.t(`common.confirm.quitNotSaving.confirmButtonText`),
                    cancelButtonText: this.props.t(`common.confirm.quitNotSaving.cancelButtonText`)
                },
                () => {
                    this.props.onModalHide(this.state.reloadParent);
                }
            )
        } else {
            this.props.onModalHide(this.state.reloadParent);
        }
    }

    setBlockers(callback) {
        const assemblyCompleted = this.state.projectassemblycompleted ? new Date(`${this.state.projectassemblycompleted} 00:00:00 GMT`) : null;
        const plannedAssemblyCompleted = this.state.projectplannedassemblycompleted ? new Date(`${this.state.projectplannedassemblycompleted} 23:59:59 GMT`) : null;
        const projectdelayunknown = !assemblyCompleted || !plannedAssemblyCompleted;
        const overdue = assemblyCompleted > plannedAssemblyCompleted;
        const projectdelayed = this.state.projectdelayed; //overdue; //temp
        const projectdelayedblocked = this.state.projectdelayedblocked; //overdue; //temp
        const projectemptynps = this.state.projectnps ? false : true;
        const projectlownps = (this.state.projectnps && this.parseFloatFixed(this.state.projectnps) < 9) ? true : false;
        const projectdelaynoexplain = overdue && !this.state.projectdelayedreasons;
        this.setState({
            projectdelayed,
            projectdelayedblocked,
            projectlownps,
            projectemptynps,
            projectdelaynoexplain,
            projectdelayunknown
        }, callback);
    }

    saveProjectRoutine(e) {
        e.preventDefault();
        e.stopPropagation();
        let closePopup = true;
        if (e.target.dataset.stay) {
            closePopup = false;
        }
        this.validateForms(
            () => {
                this.saveProject(closePopup);
            },
            (invalidTab, invalidForms, invalidInputs, invalidTab2) => {
                const maybeSetTab2 = () => {
                    if (invalidTab2) {
                        this.setTab(invalidTab2, 'activeTab2')
                    }
                };
                if (invalidTab) {
                    this.setTab(invalidTab, 'activeTab1', maybeSetTab2)
                } else {
                    maybeSetTab2();
                }
                for (let i = 0; i < invalidInputs.length; i++) {
                    const lblTxt = this.props.utils.getClosestLabelText(invalidInputs[i]);
                    this.props.showToast({
                        title: this.props.t('common.toast.checkRequiredFields'),
                        color: 'danger',
                        errorObject: {
                            ident: this.props.t(`common.tooltip.invalid`),
                            data: {
                                error: {
                                    ident: lblTxt
                                }
                            }
                        }
                    });
                }
            }
        );
    }

    render() {
        const {
            __modified,
            __validated,

            isLoading,
            isEditorVisible,
            activeTab1,
            activeTab2,
            activeTab1Seen,
            checklistDefaults,

            projectuuid,
            projectbcid,
            projectlabel,
            projectdescription,
            projectsince,
            projectsincecontracted,

            projectsubtype,
            projectpassivematerial,
            projectbackupinterface,
            projectbatteries,
            projectenergized,
            projecttill,

            contractors,
            contractorsEditable,
            tasks,
            projectchecklist,
            projectstatus,
            projecttype,
            projectmapmarker,
            projectpvblueprintaccepted,
            projectpvtype,
            projectpvamount,
            projectnps,
            projectnpsreasons,
            projectenergyplatformident,
            projectsolaredgeid,
            projectsolaredgekey,
            projectgridxid,
            addressaddressname,
            addressemailaddress,
            addressprovincename,
            addresspostalcode,
            addresscityname,
            addressstreetaddress,
            addressstreetnumber,
            addressapartmentnumber,
            addressmobilephone,
            addressgeolat,
            addressgeolng,

            projectcontractsigned,
            projectpassivearea,
            projectpassiveareastacbond,
            projectpvorientation,
            projectassemblycompleted,
            projectevcharger,
            projectarea,
            projectsubarea,
            projectcontracttype,
            projecttimeconsumeddesign,
            projectgridregistered,
            projectresigned,
            projectresignedreasons,
            projectdelayedreasons,
            projectclientinfopermit,

            projectplannedassemblycompleted,
            projectreadytoaccomodate,

            offermarginpercent,

            eventident,
            eventcomment,
            eventdated,
            isEventSaving,

            projectdelayed,
            projectdelayedblocked,
            projectlownps,
            projectemptynps,
            projectdelaynoexplain,
            projectdelayunknown,

            projectmilestonesdone,

        } = this.state;

        const todayIso = DateTime.now().toISODate();

        const contractorsAreEmpty = contractors.filter(contractorObj => !contractorObj.contractor.trash).length === 0;

        const useFlatStatus = false;

        const blockEditSince = false; // useFlatStatus && projectchecklist.find(itm => itm.parentuuid !== null && itm.title?.startsWith('6. ') && itm.done) ? true : false;

        const forcedReadOnly = !this.props.hasPermission(['VIEW_PROJECT_EDIT']).any;

        const requireFields = false;// !this.props.isRegion(['SWEDEN']);

        const loadingView = (
            <Row>
                <Col sm={12} className="text-center p-20">
                    <Spinner animation="border" />
                </Col>
            </Row>
        )

        const tabHeader = __modified && (
            <></>
        )

        const tabFooter = (
            <div className="py-20 border-top bg-white" style={{ position: 'sticky', bottom: 0 }}>
                <Row>
                    <Col md={12}>
                        <Button variant="success" disabled={isLoading} type="submit">
                            {this.props.t('common.button.save')}
                        </Button>
                        <Button variant="link" data-stay='true' disabled={isLoading} onClick={(e) => { this.saveProjectRoutine(e); }}>
                            {this.props.t('common.button.saveAndStay')}
                        </Button>
                    </Col>
                </Row>
            </div>
        )

        const tab2Primary = (
            <Tab.Pane eventKey="t2Primary" title={this.props.t('page.scheduler.modal.projectDetails.tabs.details.primary')}>
                <Row>
                    <Col sm={12} md={4}>
                        <Form.Group className='required'>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.label')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Primary"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={true}
                                required
                                type="text"
                                value={projectlabel || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectlabel', e) }}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={4}>
                        <Form.Group className={requireFields ? 'required' : ''}>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.type')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Primary"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                required={requireFields}
                                value={projecttype || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projecttype', e) }}
                                list="optionset-type"
                            />
                            <datalist id="optionset-type">
                                {(this.state.optionsets.type || []).map(itm => {
                                    return (
                                        <option key={`type-${itm}`} value={itm}>{itm}</option>
                                    )
                                })}
                            </datalist>
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={4}>
                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.subType')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Primary"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                value={projectsubtype || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectsubtype', e) }}
                                list="optionset-subType"
                            />
                            <datalist id="optionset-subType">
                                {(this.state.optionsets.subType || []).map(itm => {
                                    return (
                                        <option key={`subType-${itm}`} value={itm}>{itm}</option>
                                    )
                                })}
                            </datalist>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>
                                {this.props.t('page.scheduler.modal.projectDetails.tabs.details.description')}
                                {` `}
                                (
                                <button disabled={isLoading || forcedReadOnly} className="btn btn-link p-0" onClick={(e) => { e.preventDefault(); this.setState({ isEditorVisible: !isEditorVisible }) }}>{this.props.t('common.button.edit')}</button>
                                )
                            </Form.Label>
                            {
                                isEditorVisible ? (
                                    <TextEditor
                                        data-tab2="t2Primary"
                                        required={false}
                                        disabled={isLoading || forcedReadOnly}
                                        value={projectdescription || ''}
                                        placeholder={this.props.t('common.placeholder.any')}
                                        simpleToolbar={true}
                                        onChange={
                                            (html) => {
                                                this.setState(
                                                    { projectdescription: html, __modified: projectdescription !== html, validated: false }
                                                )
                                            }
                                        }
                                    />
                                ) : (
                                    <>
                                        <div className="div-as-input p-infobox" dangerouslySetInnerHTML={{ __html: projectdescription || '...' }} />
                                    </>
                                )
                            }

                        </Form.Group>
                    </Col>
                </Row>
                {
                    (this.props.isRegion(['SWEDEN'])) && (
                        <Row>
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Check
                                        data-tab2="t2Primary"
                                        id="clientInfoPermit"
                                        type="checkbox"
                                        custom
                                        label={this.props.t('common.info.clientInfoPermit')}
                                        disabled={isLoading || forcedReadOnly}
                                        checked={projectclientinfopermit || false}
                                        onChange={(e) => { this.setState({ projectclientinfopermit: !projectclientinfopermit }) }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )
                }
                <Row>
                    <Col md={12} lg={6} className="pb-20">
                        <Form.Group className="mb-0">
                            <Form.Check
                                data-tab2="t2Primary"
                                id="delayed"
                                type="checkbox"
                                custom
                                label={this.props.t('page.scheduler.modal.projectDetails.tabs.details.delayed')}
                                disabled={isLoading || forcedReadOnly || projectdelayedblocked}
                                checked={projectdelayed || false}
                                onChange={(e) => { this.setState({ projectdelayed: !projectdelayed }) }}
                            />
                        </Form.Group>
                        {
                            projectdelayed && (
                                <Form.Group className={'required'}>
                                    <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.delayedReasons')}</Form.Label>
                                    <Form.Control
                                        data-tab2="t2Primary"
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        disabled={isLoading || forcedReadOnly}
                                        required={true}
                                        value={projectdelayedreasons || ''}
                                        placeholder={this.props.t('common.placeholder.any')}
                                        onChange={(e) => { this.onChangeSetState('projectdelayedreasons', e) }}
                                        as="select"
                                        custom
                                    >
                                        <option value='' disabled></option>
                                        {(this.state.optionsets.delayedReason || []).map(itm => {
                                            return (
                                                <option key={`delayedReason-${itm}`} value={itm}>{itm}</option>
                                            )
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            )
                        }
                    </Col>
                    <Col md={12} lg={6} className="pb-20">
                        <Form.Group className="mb-0">
                            <Form.Check
                                data-tab2="t2Primary"
                                id="resigned"
                                type="checkbox"
                                custom
                                label={this.props.t('page.scheduler.modal.projectDetails.tabs.details.resigned')}
                                disabled={isLoading || forcedReadOnly}
                                checked={projectresigned || false}
                                onChange={(e) => { this.setState({ projectresigned: !projectresigned }) }}
                            />
                        </Form.Group>
                        {
                            projectresigned && (
                                <Form.Group className='required'>
                                    <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.resignedReasons')}</Form.Label>
                                    <Form.Control
                                        data-tab2="t2Primary"
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        disabled={isLoading || forcedReadOnly}
                                        required={true}
                                        type="text"
                                        value={projectresignedreasons || ''}
                                        placeholder={this.props.t('common.placeholder.any')}
                                        onChange={(e) => { this.onChangeSetState('projectresignedreasons', e) }}
                                    />
                                </Form.Group>
                            )
                        }
                    </Col>
                </Row>
            </Tab.Pane>
        )

        const tab2Hardware = (
            <Tab.Pane eventKey="t2Hardware" title={this.props.t('page.scheduler.modal.projectDetails.tabs.details.hardware')}>
                <Row>
                    <Col sm={12} md={6} xl={4}>
                        <Form.Group className={requireFields ? 'required' : ''}>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.pvType')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Hardware"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                required={requireFields}
                                type="text"
                                value={projectpvtype || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectpvtype', e) }}
                            />
                        </Form.Group>

                        <Form.Group className={requireFields ? 'required' : ''}>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.pvAmount')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Hardware"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                required={requireFields}
                                type="number"
                                step="1"
                                min="0"
                                value={projectpvamount || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectpvamount', e) }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.pvOrientation')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Hardware"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                type="text"
                                value={projectpvorientation || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectpvorientation', e) }}
                            />
                        </Form.Group>

                    </Col>

                    <Col sm={12} md={6} xl={4}>
                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.passiveArea')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Hardware"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                type="number"
                                step="0.1"
                                min="0"
                                value={projectpassivearea || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectpassivearea', e) }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.passiveAreaStacbond')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Hardware"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                type="number"
                                step="0.1"
                                min="0"
                                value={projectpassiveareastacbond || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectpassiveareastacbond', e) }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.passiveMaterial')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Hardware"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                value={projectpassivematerial || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectpassivematerial', e) }}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={12} md={6} xl={4}>
                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.batteries')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Hardware"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                value={projectbatteries || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectbatteries', e) }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.backupInterface')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Hardware"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                value={projectbackupinterface || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectbackupinterface', e) }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.evCharger')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Hardware"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                type="text"
                                value={projectevcharger || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectevcharger', e) }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Tab.Pane>
        );

        const tab2Dates = (
            <Tab.Pane eventKey="t2Dates" title={this.props.t('page.scheduler.modal.projectDetails.tabs.details.dates')}>
                <Row>
                    <Col sm={12} md={6} style={{ display: 'grid', alignContent: 'end' }}>
                        <Form.Group className={requireFields ? 'required' : ''}>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.contractSigned')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Dates"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                type="date"
                                required={requireFields}
                                value={projectcontractsigned || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectcontractsigned', e) }}
                            />
                        </Form.Group>

                        <Form.Group className={requireFields ? 'required' : ''}>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.sinceContracted')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Dates"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                required={requireFields}
                                type="date"
                                value={projectsincecontracted || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectsincecontracted', e) }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                {this.props.t('page.scheduler.modal.projectDetails.tabs.details.since')}
                                {` `}
                                <i data-tip={this.props.t(`common.tooltip.projectsince`)}>
                                    <Info size={14} />
                                </i>
                            </Form.Label>
                            <Form.Control
                                data-tab2="t2Dates"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                min={blockEditSince ? `${todayIso}` : ''}
                                type="date"
                                value={projectsince || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectsince', e) }}
                            />

                        </Form.Group>

                        {/*
                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.plannedAssemblyCompleted')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Dates"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                type="date"
                                value={projectplannedassemblycompleted || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectplannedassemblycompleted', e, false, () => { this.setBlockers() }) }}
                            />
                        </Form.Group>
                        */}

                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.assemblyCompleted')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Dates"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                type="date"
                                value={projectassemblycompleted || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectassemblycompleted', e, false, () => { this.setBlockers() }) }}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={12} md={6} style={{ display: 'grid', alignContent: 'end' }}>
                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.pvBlueprintAccepted')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Dates"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                type="date"
                                value={projectpvblueprintaccepted || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectpvblueprintaccepted', e) }}
                            />
                        </Form.Group>

                        {/*
                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.readyToAccomodate')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Dates"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                type="date"
                                value={projectreadytoaccomodate || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectreadytoaccomodate', e) }}
                            />
                        </Form.Group>
                        */}

                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.energized')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Dates"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                type="date"
                                value={projectenergized || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectenergized', e) }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.gridRegistered')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Dates"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                type="date"
                                value={projectgridregistered || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectgridregistered', e) }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.till')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Dates"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                type="date"
                                value={projecttill || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projecttill', e) }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Tab.Pane>
        )

        const tab2Addons = (
            <Tab.Pane eventKey="t2Addons" title={this.props.t('page.scheduler.modal.projectDetails.tabs.details.addons')}>
                <Row>
                    <Col sm={12} md={6} lg={6}>
                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.area')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Addons"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                value={projectarea || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectarea', e) }}
                                list="optionset-area"
                            />
                            <datalist id="optionset-area">
                                {(this.state.optionsets.area || []).map(itm => {
                                    return (
                                        <option key={`area-${itm}`} value={itm}>{itm}</option>
                                    )
                                })}
                            </datalist>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.contractType')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Addons"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                value={projectcontracttype || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectcontracttype', e) }}
                                list="optionset-contractType"
                            />
                            <datalist id="optionset-contractType">
                                {(this.state.optionsets.contractType || []).map(itm => {
                                    return (
                                        <option key={`contractType-${itm}`} value={itm}>{itm}</option>
                                    )
                                })}
                            </datalist>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.nps')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Addons"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                type="number"
                                step="1"
                                min="0"
                                max="10"
                                value={projectnps || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectnps', e, false, () => { this.setBlockers() }) }}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.subArea')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Addons"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                value={projectsubarea || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectsubarea', e) }}
                                list="optionset-subArea"
                            />
                            <datalist id="optionset-subArea">
                                {(this.state.optionsets.subArea || []).map(itm => {
                                    return (
                                        <option key={`subArea-${itm}`} value={itm}>{itm}</option>
                                    )
                                })}
                            </datalist>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.timeConsumedDesign')}</Form.Label>
                            <Form.Control
                                data-tab2="t2Addons"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                type="number"
                                step="1"
                                value={projecttimeconsumeddesign || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projecttimeconsumeddesign', e) }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.mapMarker')}</Form.Label>
                            <Form.Control
                                data-tab2="t2VPP"
                                disabled={isLoading || forcedReadOnly}
                                as="select"
                                value={projectmapmarker || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectmapmarker', e) }}
                            >
                                <option value="">{this.props.t('common.placeholder.any')}</option>
                                <option value='projectDone'>{this.props.t('common.map.projectDone')}</option>
                                <option value='bipvDone'>{this.props.t('common.map.bipvDone')}</option>
                                <option value='bipvOngoing'>{this.props.t('common.map.bipvOngoing')}</option>
                                <option value='pending'>{this.props.t('common.map.pending')}</option>
                                <option value='cancelled'>{this.props.t('common.map.cancelled')}</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                {
                    projectlownps && (
                        <Row>
                            <Col sm={12}>
                                <Form.Group className='required'>
                                    <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.npsReasons')}</Form.Label>
                                    <Form.Control
                                        data-tab2="t2Addons"
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        disabled={isLoading || forcedReadOnly}
                                        required={true}
                                        type="text"
                                        value={projectnpsreasons || ''}
                                        placeholder={this.props.t('common.placeholder.any')}
                                        onChange={(e) => { this.onChangeSetState('projectnpsreasons', e, false, () => { this.setBlockers() }) }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )
                }
            </Tab.Pane>
        )

        const tab2VPP = (
            <Tab.Pane eventKey="t2VPP" title={this.props.t('page.scheduler.modal.projectDetails.tabs.details.appVpp')}>
                <Row>
                    <Col md={12} lg={6} className="pb-20">
                        <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.appSettings')}</Form.Label>
                        <Form.Group className="mb-0">
                            <Form.Check
                                data-tab2="t2Primary"
                                id="milestonesDone"
                                type="checkbox"
                                custom
                                label={this.props.t('page.scheduler.modal.projectDetails.tabs.details.milestonesDone')}
                                disabled={isLoading || forcedReadOnly}
                                checked={projectmilestonesdone || false}
                                onChange={(e) => { this.setState({ projectmilestonesdone: !projectmilestonesdone }) }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6} lg={6}>
                        <Form.Group>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.energyPlatformIdent')}</Form.Label>
                            <Form.Control
                                data-tab2="t2VPP"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={isLoading || forcedReadOnly}
                                as="select"
                                value={projectenergyplatformident || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('projectenergyplatformident', e, false, () => { this.setBlockers() }) }}
                            >
                                <option value="">{this.props.t('common.placeholder.any')}</option>
                                <option value="gridx">GridX</option>
                                <option value="solaredge">SolarEdge</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                {
                    projectenergyplatformident === 'solaredge' && (
                        <Row>
                            <Col sm={12} md={6} lg={6}>
                                <Form.Group>
                                    <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.solarEdgeId')}</Form.Label>
                                    <Form.Control
                                        data-tab2="t2VPP"
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        disabled={isLoading || forcedReadOnly}
                                        type="text"
                                        value={projectsolaredgeid || ''}
                                        placeholder={this.props.t('common.placeholder.any')}
                                        onChange={(e) => { this.onChangeSetState('projectsolaredgeid', e, false, () => { this.setBlockers() }) }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                                <Form.Group>
                                    <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.solarEdgeKey')}</Form.Label>
                                    <Form.Control
                                        data-tab2="t2VPP"
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        disabled={isLoading || forcedReadOnly}
                                        type="text"
                                        value={projectsolaredgekey || ''}
                                        placeholder={this.props.t('common.placeholder.any')}
                                        onChange={(e) => { this.onChangeSetState('projectsolaredgekey', e, false, () => { this.setBlockers() }) }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )
                }

                {
                    projectenergyplatformident === 'gridx' && (
                        <Row>
                            <Col sm={12} md={6} lg={6}>
                                <Form.Group>
                                    <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.gridXID')}</Form.Label>
                                    <Form.Control
                                        data-tab2="t2VPP"
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        disabled={isLoading || forcedReadOnly}
                                        type="text"
                                        value={projectgridxid || ''}
                                        placeholder={this.props.t('common.placeholder.any')}
                                        onChange={(e) => { this.onChangeSetState('projectgridxid', e, false, () => { this.setBlockers() }) }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )
                }
            </Tab.Pane>
        )

        const tabDetails = (
            <Tab eventKey="details" title={this.props.t('page.scheduler.modal.projectDetails.tabs.details.title')}>
                <Form noValidate validated={__validated} method="post" onSubmit={(e) => { this.saveProjectRoutine(e); }} data-tab="details">
                    {tabHeader}
                    <Row>
                        <Col sm={12}>
                            {(
                                <Alert variant="success" className="mb-15 font-weight-bold">
                                    <span>
                                        {this.props.t('page.scheduler.modal.projectDetails.tabs.details.useBcId', { bcId: projectbcid || projectlabel })}
                                    </span>
                                    {
                                        this.parseFloatFixed(offermarginpercent) > 0 && (
                                            <span>
                                                {`. `}
                                                {this.props.t('page.scheduler.modal.projectDetails.tabs.details.offerMargin')}
                                                {` `}
                                                <u>{offermarginpercent}%</u>
                                                <MarginSmile val={offermarginpercent} size={14} style={{ marginTop: -5 }} />
                                            </span>
                                        )}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mx-0 my-5 align-content-between">
                            <Tab.Container
                                activeKey={activeTab2}
                                defaultActiveKey={activeTab2}
                                onSelect={(k) => this.setTab(k, 'activeTab2')}
                            >
                                <Row>
                                    <Col xs={12} sm={4} md={3}>
                                        <Nav className="flex-column mb-30 nav-right-side">
                                            <Nav.Item>
                                                <Nav.Link eventKey="t2Primary" active={activeTab2 === 't2Primary'}>
                                                    <Icon name="award" />
                                                    <span>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.primary')}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="t2Dates" active={activeTab2 === 't2Dates'}>
                                                    <Icon name="calendar" />
                                                    <span>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.dates')}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="t2Hardware" active={activeTab2 === 't2Hardware'}>
                                                    <Icon name="battery-charging" />
                                                    <span>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.hardware')}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="t2Addons" active={activeTab2 === 't2Addons'}>
                                                    <Icon name="info" />
                                                    <span>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.addons')}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="t2VPP" active={activeTab2 === 't2VPP'}>
                                                    <Icon name="git-merge" />
                                                    <span>{this.props.t('page.scheduler.modal.projectDetails.tabs.details.appVpp')}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col xs={12} sm={8} md={9}>
                                        <Tab.Content>
                                            {tab2Primary}
                                            {tab2Dates}
                                            {tab2Hardware}
                                            {tab2Addons}
                                            {tab2VPP}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Col>
                    </Row>

                    {tabFooter}

                </Form>
            </Tab >
        )

        const contractorsLockedView = (
            <>
                <ListGroup>
                    {
                        contractors.filter(contractorObj => !contractorObj.contractor.trash).map((contractorObj, index) => {
                            const contractor = contractorObj.contractor;
                            return (
                                <ListGroup.Item key={`project-edit-contractor-${contractor.uuid || index}`} style={{ borderLeft: `5px solid ${contractor.color}` }}>
                                    <Row className="justify-content-center align-items-center">
                                        <Col xs={4}>
                                            <small>
                                                {contractor.category || '---'}
                                            </small>
                                            <br />
                                            <span>
                                                {contractor.label || '---'}
                                            </span>
                                        </Col>
                                        <Col xs={3}>
                                            <small>
                                                {this.props.t(`page.scheduler.modal.projectDetails.tabs.contractors.status`)}
                                            </small>
                                            <br />
                                            <span>
                                                {this.props.t(`common.contractorStatus.${contractor.status || 'empty'}`) || this.props.t('common.placeholder.any')}
                                            </span>
                                        </Col>
                                        <Col xs={3}>
                                            <small>
                                                {this.props.t(`page.scheduler.modal.projectDetails.tabs.contractors.comment`)}
                                            </small>
                                            <br />
                                            <span>
                                                {contractor.comment || this.props.t('common.placeholder.any')}
                                            </span>
                                        </Col>
                                        <Col sm={2} className="text-right">
                                            &nbsp;
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            )
                        })
                    }
                </ListGroup>
                <Row>
                    <Col sm={12}>
                        <button
                            type="button"
                            className={"btn pr-0 pl-0 my-10"}
                            disabled={isLoading || forcedReadOnly}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({ contractorsEditable: true })
                            }}
                        >
                            <Icon name="edit2" className="mr-5" />
                            {this.props.t('common.button.edit')}
                        </button>
                    </Col>
                </Row>
            </>
        )

        const contractorsEditView = (
            <>
                <ListGroup>
                    {
                        contractors.filter(contractorObj => !contractorObj.contractor.trash).map((contractorObj, index) => {
                            const contractor = contractorObj.contractor;
                            return (
                                <ListGroup.Item key={`project-edit-contractor-${contractor.uuid || index}`} style={{ borderLeft: `5px solid ${contractor.color}` }}>
                                    <Row className="justify-content-center align-items-center">
                                        <Col sm={4}>
                                            {
                                                contractorObj.team.uuid ? (
                                                    <>
                                                        <small>
                                                            {contractor.category}
                                                        </small>
                                                        <br />
                                                        <span>
                                                            {contractor.label}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <small>
                                                            {this.props.t(`common.button.set`)}
                                                        </small>
                                                        <br />
                                                        <SelectAsync
                                                            api={this.props.api}
                                                            t={this.props.t}
                                                            isClearable={false}
                                                            cache={true}
                                                            preload={true}
                                                            disabled={isLoading || forcedReadOnly}
                                                            endpoint={`/team/list`}
                                                            value={null}
                                                            placeholder={this.props.t('common.placeholder.any')}
                                                            params={{ include: [] }}
                                                            transformData={(data, callback) => {
                                                                let newData = data.teams.map(itm => { return { ...itm, value: itm.uuid } });
                                                                callback(newData)
                                                            }}
                                                            onChange={(data) => {
                                                                this.assignTeamToContractor(contractor.uuid, data);
                                                            }}
                                                        />
                                                    </>
                                                )
                                            }
                                        </Col>
                                        <Col sm={3}>
                                            <small>
                                                {this.props.t(`page.scheduler.modal.projectDetails.tabs.contractors.status`)}
                                            </small>
                                            <br />
                                            <Form.Control
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                as="select"
                                                custom
                                                value={contractor.status || ''}
                                                placeholder={this.props.t('common.placeholder.any')}
                                                onChange={(e) => { this.setContractor(contractor.uuid, 'status', e); }}
                                            >
                                                <option value="">{this.props.t('page.scheduler.modal.projectDetails.tabs.contractors.placeholder.status')}</option>
                                                <option value="contractSent">{this.props.t(`common.contractorStatus.contractSent`)}</option>
                                                <option value="contractAccepted">{this.props.t(`common.contractorStatus.contractAccepted`)}</option>
                                            </Form.Control>
                                        </Col>
                                        <Col sm={3}>
                                            <small>
                                                {this.props.t(`page.scheduler.modal.projectDetails.tabs.contractors.comment`)}
                                            </small>
                                            <br />
                                            <Form.Control
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                type="text"
                                                placeholder={this.props.t('page.scheduler.modal.projectDetails.tabs.contractors.placeholder.comment')}
                                                value={contractor.comment || ''}
                                                onChange={(e) => { this.setContractor(contractor.uuid, 'comment', e); }}
                                            />
                                        </Col>
                                        <Col sm={2} className="text-right">
                                            <button
                                                type="button"
                                                className={"btn pr-0 pl-0"}
                                                onClick={(e) => { e.preventDefault(); this.removeContractor(contractor.uuid); }}
                                            >
                                                <small>&nbsp;</small>
                                                <br />{this.props.t('common.button.remove')}
                                            </button>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            )
                        })
                    }
                </ListGroup>
                {
                    contractorsAreEmpty ? (
                        <Row>
                            <Col sm={12}>
                                <button
                                    type="button"
                                    className={"btn pr-0 pl-0 my-10"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ contractorsEditable: true }, () => this.createContractor())
                                    }}
                                >
                                    <Icon name="plus" className="ml-5" />
                                    {this.props.t('common.button.add')}
                                </button>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col sm={12} md={6} lg={4}>
                                <button
                                    type="button"
                                    className={"btn pr-0 pl-0 my-10"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.createContractor();
                                    }}
                                >
                                    <Icon name="plus" className="mr-5" />
                                    {this.props.t('common.button.add')}
                                </button>
                            </Col>
                            <Col sm={12} md={6} lg={8} className="text-right">
                                <button
                                    type="button"
                                    className={"btn pr-0 pl-0 my-10"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ contractorsEditable: false })
                                    }}
                                >
                                    {this.props.t('common.button.close')}
                                    <Icon name="x" className="ml-5" />
                                </button>

                            </Col>
                        </Row>
                    )
                }

            </>
        )

        const contractorsEmptyView = contractorsAreEmpty && (
            <div className="text-center p-25" style={{ border: `1px solid #dee2e6` }}>
                <Icon name="users" style={{ width: 64, height: 64, color: "#e6ecf0" }} />
            </div>
        )

        const tabParticipantsContent = activeTab1Seen.includes('participants') && (
            <Row>
                <Col sm={12} md={6} lg={6}>
                    {

                        ['projectpm', 'projectsm', 'projectbipvdesigner', 'projectcostcontroller'].map(person => (
                            <Form.Group key={person}>
                                <Form.Label className="text-capitalize">
                                    {this.props.t(`page.scheduler.modal.projectDetails.tabs.details.${person}`)}
                                    {
                                        ['projectcostcontroller'].includes(person) && (
                                            <i data-tip={this.props.t(`page.scheduler.modal.projectDetails.tabs.details.${person}_tip`)}>
                                                <Info size={14} />
                                            </i>
                                        )
                                    }
                                </Form.Label>
                                <SelectAsync
                                    api={this.props.api}
                                    t={this.props.t}
                                    isClearable={true}
                                    cache={true}
                                    preload={true}
                                    disabled={isLoading || forcedReadOnly}
                                    endpoint={`/user/list`}
                                    value={this.state[person] || null}
                                    placeholder={this.props.t('common.placeholder.any')}
                                    params={{ include: this.state[person] ? [this.state[person].uuid] : [] }}
                                    transformData={(data, callback) => {
                                        let newData = data.users.map(itm => { return { ...itm, value: itm.uuid } });
                                        callback(newData)
                                    }}
                                    onChange={(data) => {
                                        this.setState({ [person]: data, __modified: true, __validated: false });
                                    }}
                                />
                            </Form.Group>
                        ))
                    }
                </Col>
                <Col sm={12} md={6} lg={6}>
                    {
                        ['projectelectricaldesigner', 'projectconstructionengineer', 'projectpmomanager', 'projectlogisticsmanager', 'projectdesignmanager'].map(person => (
                            <Form.Group key={person}>
                                <Form.Label className="text-capitalize">{this.props.t(`page.scheduler.modal.projectDetails.tabs.details.${person}`)}</Form.Label>
                                <SelectAsync
                                    api={this.props.api}
                                    t={this.props.t}
                                    isClearable={true}
                                    cache={true}
                                    preload={true}
                                    disabled={isLoading || forcedReadOnly}
                                    endpoint={`/user/list`}
                                    value={this.state[person] || null}
                                    placeholder={this.props.t('common.placeholder.any')}
                                    params={{ include: this.state[person] ? [this.state[person].uuid] : [] }}
                                    transformData={(data, callback) => {
                                        let newData = data.users.map(itm => { return { ...itm, value: itm.uuid } });
                                        callback(newData)
                                    }}
                                    onChange={(data) => {
                                        this.setState({ [person]: data, __modified: true });
                                    }}
                                />
                            </Form.Group>
                        ))
                    }
                </Col>
            </Row>
        )

        const tabParticipants = (
            <Tab eventKey="participants" title={this.props.t('page.scheduler.modal.projectDetails.tabs.participants.title')}>
                <Form noValidate validated={__validated} method="post" onSubmit={(e) => { this.saveProjectRoutine(e); }} data-tab="participants">
                    {tabHeader}
                    {tabParticipantsContent}
                    {tabFooter}
                </Form>
            </Tab>
        )

        const tabContractors = (
            <Tab eventKey="contractors" title={this.props.t('page.scheduler.modal.projectDetails.tabs.contractors.title')}>
                <Form noValidate validated={__validated} method="post" onSubmit={(e) => { this.saveProjectRoutine(e); }} data-tab="contractors">
                    {tabHeader}
                    <Row>
                        <Col sm={12}>
                            {contractorsEmptyView}
                            {contractorsEditable ? contractorsEditView : contractorsLockedView}
                        </Col>
                    </Row>
                    {tabFooter}
                </Form>
            </Tab>
        )

        const tabChecklist = (
            <Tab eventKey="checklist" title={this.props.t('page.scheduler.modal.projectDetails.tabs.checklist.title')}>
                <Form noValidate validated={__validated} method="post" onSubmit={(e) => { this.saveProjectRoutine(e); }} data-tab="checklist">
                    {tabHeader}
                    <Checklist
                        t={this.props.t}
                        api={this.props.api}
                        utils={this.props.utils}
                        getUser={this.props.getUser}
                        showToast={this.props.showToast}
                        getRegionIdent={this.props.getRegionIdent}
                        withTasks={false}
                        withStatuses={true}
                        withHistory={true}
                        tasks={tasks}
                        checklist={projectchecklist}
                        checklistDefaults={checklistDefaults}
                        onChange={(newChecklist, callback) => {
                            this.setState({
                                projectchecklist: newChecklist,
                                __modified: true,
                                __validated: false
                            }, () => {
                                callback(newChecklist)
                            })
                        }}
                        setMasterStatus={(setNew, newStatus, callback) => {
                            this.setState({
                                projectstatus: setNew ? newStatus : this.state.projectstatus,
                                __modified: setNew,
                                __validated: !setNew
                            }, () => {
                                callback(newStatus)
                            })
                        }}
                        canEdit={useFlatStatus ? this.props.hasPermission(['VIEW_PROJECT_MILESTONES_EDIT']).any : this.props.hasPermission(['VIEW_PROJECT_CHECKLIST_EDIT']).any}
                        canEditDate={this.props.hasPermission(['VIEW_PROJECT_CHECKLIST_ITEM_STATUS_EDIT']).any}
                        canEditNotes={this.props.hasPermission(['VIEW_PROJECT_CHECKLIST_ITEM_NOTES_EDIT']).any}
                        noteDateRequired={true}
                        upperBlockers={{
                            projectdelayed,
                            projectdelayedblocked,
                            projectlownps,
                            projectemptynps,
                            projectdelaynoexplain,
                            projectdelayunknown,
                        }}
                    />
                    {tabFooter}
                </Form>
            </Tab>
        )

        const tabLocation = (
            <Tab eventKey="location" title={this.props.t('page.scheduler.modal.projectDetails.tabs.location.title')}>
                <Form noValidate validated={__validated} method="post" onSubmit={(e) => { this.saveProjectRoutine(e); }} data-tab="location">
                    {tabHeader}
                    <Form.Row>
                        <Form.Group as={Col} sm={12} md={4} className='required'>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.location.addressName')}</Form.Label>
                            <Form.Control
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                type="text"
                                disabled={isLoading || forcedReadOnly}
                                required
                                value={addressaddressname || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('addressaddressname', e) }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={4}>
                            <Form.Label>
                                {this.props.t('page.scheduler.modal.projectDetails.tabs.location.mobilePhone')}
                            </Form.Label>
                            <Form.Control
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                type="text"
                                disabled={isLoading || forcedReadOnly}
                                value={addressmobilephone || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('addressmobilephone', e) }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={4}>
                            <Form.Label>
                                {this.props.t('page.scheduler.modal.projectDetails.tabs.location.emailAddress')}
                            </Form.Label>
                            <Form.Control
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                type="text"
                                disabled={isLoading || forcedReadOnly}
                                value={addressemailaddress || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('addressemailaddress', e) }}
                            />
                        </Form.Group>
                    </Form.Row>
                    <hr />
                    <Form.Row>
                        <Form.Group as={Col} sm={12} md={4}>
                            <Form.Label>{this.props.t('page.scheduler.modal.projectDetails.tabs.location.provinceName')}</Form.Label>
                            <Form.Control
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                type="text"
                                disabled={isLoading || forcedReadOnly}
                                value={addressprovincename || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('addressprovincename', e, false, this.setFullAddress) }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={4} className='required'>
                            <Form.Label>
                                {this.props.t('page.scheduler.modal.projectDetails.tabs.location.postalCode')}
                            </Form.Label>
                            <Form.Control
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                type="text"
                                disabled={isLoading || forcedReadOnly}
                                required
                                value={addresspostalcode || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('addresspostalcode', e, false, this.setFullAddress) }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={4}>
                            <Form.Label>
                                {this.props.t('page.scheduler.modal.projectDetails.tabs.location.cityName')}
                            </Form.Label>
                            <Form.Control
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                type="text"
                                disabled={isLoading || forcedReadOnly}
                                value={addresscityname || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('addresscityname', e, false, this.setFullAddress) }}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={12} md={4}>
                            <Form.Label>
                                {this.props.t('page.scheduler.modal.projectDetails.tabs.location.streetAddress')}
                            </Form.Label>
                            <Form.Control
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                type="text"
                                disabled={isLoading || forcedReadOnly}
                                value={addressstreetaddress || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('addressstreetaddress', e, false, this.setFullAddress) }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={4}>
                            <Form.Label>
                                {this.props.t('page.scheduler.modal.projectDetails.tabs.location.streetNumber')}
                            </Form.Label>
                            <Form.Control
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                type="text"
                                disabled={isLoading || forcedReadOnly}
                                value={addressstreetnumber || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('addressstreetnumber', e, false, this.setFullAddress) }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={4}>
                            <Form.Label>
                                {this.props.t('page.scheduler.modal.projectDetails.tabs.location.apartmentNumber')}
                            </Form.Label>
                            <Form.Control
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                type="text"
                                disabled={isLoading || forcedReadOnly}
                                value={addressapartmentnumber || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('addressapartmentnumber', e, false, this.setFullAddress) }}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={12} md={4}>
                            <Form.Label>
                                {this.props.t('page.scheduler.modal.projectDetails.tabs.location.geoLat')}
                            </Form.Label>
                            <Form.Control
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                type="text"
                                disabled={isLoading || forcedReadOnly}
                                value={addressgeolat || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('addressgeolat', e) }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={4}>
                            <Form.Label>
                                {this.props.t('page.scheduler.modal.projectDetails.tabs.location.geoLng')}
                            </Form.Label>
                            <Form.Control
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                type="text"
                                disabled={isLoading || forcedReadOnly}
                                value={addressgeolng || ''}
                                placeholder={this.props.t('common.placeholder.any')}
                                onChange={(e) => { this.onChangeSetState('addressgeolng', e) }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} />
                    </Form.Row>
                    {tabFooter}
                </Form>
            </Tab>
        )

        const tabDiscussion = this.props.hasPermission(['VIEW_PROJECT_COMMENTS_VIEW']).any && (
            <Tab eventKey="discussion" title={this.props.t('page.scheduler.modal.projectDetails.tabs.discussion.title')}>
                <Discussion
                    t={this.props.t}
                    api={this.props.api}
                    utils={this.props.utils}
                    getUser={this.props.getUser}
                    location={this.props.location}
                    showToast={this.props.showToast}
                    hasPermission={this.props.hasPermission}
                    anchors={{ project: { uuid: projectuuid } }}
                    filter={(comment) => comment.task.uuid === null}
                    subpage={`scheduler`}
                    buildLink={this.props.buildLink}
                    readOnly={!this.props.hasPermission(['VIEW_PROJECT_COMMENTS_EDIT']).any}
                />
            </Tab>
        )

        const tabFiles = this.props.hasPermission(['VIEW_PROJECT_FILES_VIEW']).any && (
            <Tab eventKey="files" title={this.props.t('page.scheduler.modal.projectDetails.tabs.files.title')}>
                <Files
                    t={this.props.t}
                    api={this.props.api}
                    utils={this.props.utils}
                    getUser={this.props.getUser}
                    showToast={this.props.showToast}
                    hasPermission={this.props.hasPermission}
                    anchors={{ project: { uuid: projectuuid } }}
                    ident={`project`}
                    buildLink={this.props.buildLink}
                    openLink={this.props.openLink}
                    fillFormWithTokens={this.state}
                    isRegion={this.props.isRegion}
                    fetchFormArticles={{ "article": { "category": "operations" } }}
                />
            </Tab>
        )

        const tabSignature = this.props.hasPermission(['VIEW_PROJECT_SIGNATURE_VIEW']).any && (
            <Tab eventKey="signature" title={this.props.t('page.scheduler.modal.projectDetails.tabs.signature.title')}>
                <ProjectSignature
                    t={this.props.t}
                    api={this.props.api}
                    isRegion={this.props.isRegion}
                    hasPermission={this.props.hasPermission}
                    showToast={this.props.showToast}

                    projectUuid={projectuuid}
                    fullName={addressaddressname}
                    emailAddress={addressemailaddress}
                    anchors={{ project: { uuid: projectuuid } }}
                />
            </Tab>
        )

        const powerBiLink = `https://app.powerbi.com/reportEmbed?reportId=ea4b4e75-f1db-45f4-ae0f-12ddc7744202&autoAuth=true&ctid=e8c7ba02-6176-4b5e-af6c-1af3adabeadf&$filter=BC_total_job_card/No eq '${projectbcid}'`;
        const tabPowerBi = (this.props.hasPermission(['VIEW_PROJECT_REPORTS_VIEW']).any) && projectbcid && (
            <Tab eventKey="powerbi" title={this.props.t('page.scheduler.modal.projectDetails.tabs.powerbi.title')}>
                {
                    activeTab1Seen.includes('powerbi') && (
                        <iframe style={{ width: '100%', height: '66vh' }} src={powerBiLink} frameBorder={0} allowFullScreen={true}></iframe>
                    )
                }
            </Tab>
        )

        const modalEvent = (
            <Modal
                show={this.state.showModalEvent}
                onHide={() => { this.setState({ showModalEvent: false }) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.t('page.scheduler.modal.event.title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12} lg={8}>
                            <Form.Group className={'required'}>
                                <Form.Label>
                                    {this.props.t('page.scheduler.modal.event.ident')}:
                                </Form.Label>
                                <Form.Control
                                    required={true}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                    disabled={isLoading || isEventSaving || forcedReadOnly}
                                    as="select"
                                    custom
                                    value={eventident || ''}
                                    placeholder={this.props.t('common.placeholder.any')}
                                    onChange={(e) => { this.setState({ eventident: this.val(e) }) }}
                                >
                                    <option value='' disabled>{this.props.t('common.placeholder.any')}</option>
                                    {(this.state.optionsets.event || []).map(itm => {
                                        return (
                                            <option key={`event-${itm}`} value={itm}>{itm}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={4}>
                            <Form.Group className={'required'}>
                                <Form.Label>
                                    {this.props.t('page.scheduler.modal.event.date')}:
                                </Form.Label>
                                <Form.Control
                                    disabled={isLoading || isEventSaving || forcedReadOnly}
                                    required={true}
                                    type="date"
                                    value={eventdated}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                    onChange={(e) => { this.setState({ eventdated: this.val(e) }) }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>
                                    {this.props.t('page.scheduler.modal.event.comment')}:
                                </Form.Label>
                                <Form.Control
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                    disabled={isLoading || isEventSaving || forcedReadOnly}
                                    required={false}
                                    type="text"
                                    value={eventcomment || ''}
                                    placeholder={this.props.t('common.placeholder.any')}
                                    onChange={(e) => { this.setState({ eventcomment: this.val(e) }) }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="p-20 border-top">
                    <Button disabled={isLoading || isEventSaving || forcedReadOnly || !eventident || !eventdated} variant="warning" onClick={(e) => { e.preventDefault(); this.saveEvent(); }}>
                        {this.props.t('common.button.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )

        const timelineItems = this.state.timeline.map((item, itemIndex) => {
            const isoDate = item.date.toISODate();
            const isActive = this.state.timelineActive.includes(isoDate);
            const iconClass = isActive ? 'tl-active' : '';

            let badge;
            if (isActive) {
                let elIndex = this.state.timelineActive.indexOf(isoDate);
                let prevDate = elIndex > 0 ? DateTime.fromISO(this.state.timelineActive[elIndex - 1]) : null;
                if (prevDate) {
                    const deltaDays = item.date.diff(prevDate, 'days').toObject()['days'];
                    badge = (
                        <Badge variant="warning" className="ml-5">{deltaDays}D</Badge>
                    )
                }
            }

            if (item.events.length === 0 && !this.state.timelineShowEmptyEvents) {
                return null;
            }

            return (
                <Timeline.Item
                    key={`timeline-${itemIndex}`}
                    icon={<Icon name="calendar" />}
                    iconClick={(e) => { this.toggleActiveTimelineItem(e, isoDate) }}
                    iconClassName={iconClass}
                    className={`tl-operations-project animate-fade-append tl-itm-${isoDate}`}
                    date={(
                        <>
                            <div className="d-block d-lg-none text-left">
                                <small>{item.date.toLocaleString({ weekday: 'short', month: 'short', day: '2-digit' })}</small>
                            </div>
                            <div className="d-none d-lg-block">
                                <span>{isoDate}{badge}</span>
                                <br />
                                <small>{item.date.toLocaleString({ weekday: 'long', month: 'long', day: '2-digit' })}</small>
                            </div>
                        </>
                    )}
                    swap={itemIndex % 2 === 0}
                >
                    {
                        item.events.map((event, eventIndex) => {
                            return (
                                <div key={`timeline-${itemIndex}-${eventIndex}`}>
                                    {event.header && (<strong className="py-0 mb-5">{event.header}</strong>)}
                                    {event.comment && (<p className="fs-13 py-0 mb-5" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '100%' }}>{event.comment}</p>)}
                                    {event.content && (<p className="fs-12 py-0 my-0">{event.content}</p>)}
                                </div>
                            )
                        })
                    }
                </Timeline.Item>
            );
        });

        const tabTimeline = (
            <Tab eventKey="timeline" title={this.props.t('page.scheduler.modal.projectDetails.tabs.timeline.title')}>
                <Row>
                    <Col md={4} className="d-none d-lg-block position-absolute" style={{ zIndex: 2 }}>
                        <Form.Group>
                            <Button variant="danger" disabled={isLoading} onClick={() => { this.addNewEvent() }}>
                                {this.props.t('common.button.createEvent')}
                            </Button>
                        </Form.Group>
                        <Form.Group>
                            <Form.Check
                                type="switch"
                                id="timelineShowEmptyEvents"
                                checked={this.state.timelineShowEmptyEvents || false}
                                label={this.props.t('common.button.showEmptyDates')}
                                onChange={(e) => { this.setState({ timelineShowEmptyEvents: !this.state.timelineShowEmptyEvents }, () => { this.setTimelineActive() }) }}
                                className="mb-10"
                            />
                        </Form.Group>
                        <p className="d-none d-md-block">
                            <Badge variant="warning">D</Badge> <small>{this.props.t('page.scheduler.modal.projectDetails.tabs.timeline.legend.days')}</small>
                        </p>
                    </Col>
                    <Col md={12} className="pb-30">
                        <Timeline right={true}>
                            {
                                this.state.timelineActive.length > 1 && (
                                    <div
                                        className="rui-timeline-line rui-timeline-line-blue"
                                        style={{
                                            top: this.state.timelineActiveTop,
                                            height: this.state.timelineActiveHeight
                                        }}
                                    />
                                )
                            }
                            {timelineItems}
                        </Timeline>
                    </Col>
                </Row>
            </Tab>
        )

        const formView = (
            <Tabs
                activeKey={activeTab1}
                onSelect={(k) => this.setTab(k, 'activeTab1')}
                className="m-0 align-content-between nicebar"
            >
                {tabDetails}
                {tabLocation}
                {tabChecklist}
                {tabParticipants}
                {tabContractors}
                {tabDiscussion}
                {tabFiles}
                {tabSignature}
                {tabPowerBi}
                {tabTimeline}
            </Tabs>
        )

        const modalMain = (
            <Modal
                show={this.props.showModal}
                onHide={() => { this.confirmHide() }}
                backdrop="static"
                keyboard={false}
                size="xl"
                centered
                autoFocus={false}
                enforceFocus={false}

            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            __modified ? (
                                <Icon name="save" className="bg-warning p-3 mr-5 mt-n1" style={{ width: 20, height: 20, borderRadius: '50%' }} />
                            ) : (
                                <Icon name="save" className="bg-success text-white p-3 mr-5 mt-n1" style={{ width: 20, height: 20, borderRadius: '50%' }} />
                            )
                        }
                        {
                            [
                                projectlabel,
                                addressaddressname,
                                this.props.modalTitle,
                            ].filter(Boolean).join(' - ')
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReactTooltip />
                    {isLoading ? loadingView : formView}
                </Modal.Body>
            </Modal>
        )

        return (
            <Fragment>
                {modalMain}
                {modalEvent}
            </Fragment>
        )
    }
}

export default ModalProject
