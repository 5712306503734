import React, { Fragment } from 'react';
import BaseComponent from "../../components/component/base";
import PageArticleEditor from "./editor";

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            uuid: "",
            category: "",
            title: "",
            html: "",
            header: "",
            footer: "",
            schema: ""
        }
    }

    componentDidMount() {
        let uuid = this.props.match.params.uuid || this.props.uuid;
        if (uuid) {
            this.loadData(uuid);
        } else {
            this.setState({ isLoading: false })
        }
    }

    loadData(uuid) {
        this.setState({ isLoading: true });
        this.props.api.post(
            `/article/describe`,
            {
                "article": {
                    "uuid": uuid,
                }
            },
            (data) => {
                this.setData(data);
            }
        );
    }

    saveData() {
        this.setState({ isLoading: true });
        this.props.api.post(
            this.state.uuid ? `/article/update` : `/article/create`,
            {
                "article": {
                    "uuid": this.state.uuid,
                    "category": this.state.category,
                    "title": this.state.title,
                    "html": this.state.html,
                    "header": this.state.header,
                    "footer": this.state.footer,
                    "schema": this.state.schema
                }
            },
            (data) => {
                this.setState({ isLoading: false });
                this.props.showToast({
                    title: this.props.t('common.toast.dataSaved'),
                    color: "info"
                });
                this.setData(data);
                this.props.openLink(`/article/${data.article.uuid}/`);
            },
            (errorObject) => {
                this.setState({ isLoading: false });
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        );
    }

    setData(data) {
        this.setState({
            ...data.article,
            isLoading: false
        });
    }

    render() {
        return (
            <Fragment>
                <PageArticleEditor
                    {...this.props}
                    upperState={this.state}
                    setUpperState={(data) => this.setState(data)}
                    saveData={(data) => this.saveData(data)}
                />
            </Fragment>
        );
    }
}

export default Content
