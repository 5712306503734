import BaseComponent from "../../component/base";
import { Col, Row, ListGroup, Form, Button } from "react-bootstrap";
import Icon from "../../icon";
import { appEnv, appEnvCurrent } from "../../../constants";

class Toolbox extends BaseComponent {

    render() {
        return (
            <>
                <ListGroup>
                    <ListGroup.Item className="px-15 py-5">
                        <Form.Group as={Row} className="mb-0">
                            <Form.Label column sm={12} md={6}>
                                {this.props.t('page.calculation.pvDesigner.numberOfElements')}
                                &nbsp;
                            </Form.Label>
                            <Col sm={12} md={6}>
                                <div className="float-right">
                                    <span className="px-5">
                                        {this.props.numberOfElements || "0"}
                                    </span>
                                    <Button
                                        variant="link"
                                        disabled={this.props.isLoading}
                                        style={{ padding: 0 }}
                                        onClick={
                                            (evt) => {
                                                this.props.mapFitBounds()
                                            }
                                        }>
                                        <Icon name="zoom-in" height={12} />
                                    </Button>
                                </div>
                            </Col>
                        </Form.Group>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-15 py-5">
                        <Form.Group as={Row} className="mb-0">
                            <Form.Label column sm={12} md={6}>
                                {this.props.t('page.calculation.pvDesigner.rotation')}
                                &nbsp;
                            </Form.Label>
                            <Col sm={12} md={6}>
                                <Form.Control
                                    disabled={this.props.isLoading || this.props.isDisabled}
                                    type="number"
                                    step="1"
                                    value={this.props.mapRotation || 0}
                                    onChange={(e) => { this.props.adjustMap('rotate', this.valFloat(e)) }}
                                />
                            </Col>
                        </Form.Group>
                    </ListGroup.Item>
                </ListGroup>
            </>
        )
    }
}

export default Toolbox;