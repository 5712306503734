import './style.scss';

import React, { Component } from 'react';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import Content from './content';
import { Alert, Button, Row, Col } from 'react-bootstrap';
import Icon from '../../components/icon';
import IconFilters from '../../components/icon-filters';
import ReactTooltip from 'react-tooltip';
import SelectAsync from '../../components/select/async';

class Scheduler extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dismissed: this.props.api.getStored('bp_dismiss', 'scheduler_info', false),
            projects: [],
            filters: this.getFilters(),
            isLoading: false,
        }
        this.onFetchScheduler = this.onFetchScheduler.bind(this);
    }

    componentDidMount() {
        window.addEventListener("FETCH_SCHEDULER", this.onFetchScheduler, false);
        window.addEventListener("FILTERS_CHANGED", this.onFetchScheduler, false);
    }

    componentWillUnmount() {
        window.removeEventListener('FETCH_SCHEDULER', this.onFetchScheduler, false);
        window.removeEventListener('FILTERS_CHANGED', this.onFetchScheduler, false);
    }

    dismiss() {
        this.props.api.setStored('bp_dismiss', 'scheduler_info', true);
        this.setState({ dismissed: true }, () => {
            this.props.api.dispatchEvent('FETCH_SCHEDULER', {})
        });
    }

    onFetchScheduler() {
        this.setState({
            isLoading: true,
            filters: this.getFilters()
        }, () => {
            this.setState({ isLoading: false })
        });
    }

    getFilters() {
        return JSON.parse(window.localStorage.getItem("bp_scheduler_filter")) || {};
    }

    render() {

        const {
            filters,
            isLoading
        } = this.state;

        return (
            <PageWrap className="no-scroll">
                <ReactTooltip className="tooltip-multiline" multiline={true} />
                <PageTitle
                    breadcrumbs={
                        {
                            '#': this.props.t('page.scheduler.shortTitle'),
                        }
                    }
                    meta={{ title: this.props.t('page.scheduler.longTitle', { firstName: this.props.user?.user?.firstName }) }}
                    t={this.props.t}
                >
                    <Row>
                        <Col sm={12} md={6}>
                            <h1>
                                {this.props.t('page.scheduler.longTitle', { firstName: this.props.user?.user?.firstName })}
                                <Button variant='link' onClick={() => { this.props.api.dispatchEvent('FETCH_SCHEDULER', {}) }}>
                                    <Icon name="refresh-cw" />
                                </Button>
                                <Button variant='link' onClick={() => { window.print() }}>
                                    <Icon name="printer" />
                                </Button>
                                <Button variant='link' onClick={() => { this.props.api.dispatchEvent('ADJUST_SCHEDULER', {}) }}>
                                    <IconFilters />
                                </Button>
                            </h1>
                        </Col>
                        <Col sm={12} md={6} className="sm-mt-10">
                            {
                                !isLoading && (
                                    <SelectAsync
                                        t={this.props.t}
                                        api={this.props.api}
                                        isClearable={true}
                                        isMulti={true}
                                        cache={false}
                                        preload={true}
                                        disabled={this.props.isLoading}
                                        endpoint={`/search/project`}
                                        value={this.state.projects || []}
                                        params={{
                                            filter: filters
                                        }}
                                        transformData={(data, callback) => {
                                            let newData = data.projects.map(itm => { return { ...itm, value: itm.uuid, label: `${itm.label} (${itm.addressName})` } });
                                            callback(newData)
                                        }}
                                        onChange={(data) => {
                                            this.setState({ projects: data }, () => {
                                                this.props.api.dispatchEvent(
                                                    'FETCH_SCHEDULER',
                                                    {
                                                        hasprojectsuuids: true,
                                                        projectsuuids: (this.state.projects || []).map(itm => itm.uuid).filter(Boolean)
                                                    }
                                                )
                                            });
                                        }}
                                    />
                                )
                            }
                        </Col>
                    </Row>
                    {
                        !this.state.dismissed && (
                            <Alert variant="info" className="mt-10" onClose={() => this.dismiss()} dismissible>
                                {this.props.t('page.scheduler.masterTip')}
                            </Alert>
                        )
                    }
                </PageTitle>
                <PageContent hideFooter={true} className="no-min-height py-10">
                    <Content {...this.props} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default Scheduler;
