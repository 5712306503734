import React, { Component } from 'react';
import { Table } from "react-bootstrap";
import Icon from '../../components/icon';

class FilesTable extends Component {

    render() {

        const { t, paddingLeft, confirmRemove, handleElementTrash, files } = this.props;

        return (
            <Table size="xs" className="mb-0 comments border-left border-right border-bottom" responsive>
                <thead>
                    <tr>
                        <th scope="col" style={{ minWidth: 150 }} className={`pl-${paddingLeft || 20}`}>
                            {t('page.asset.element.filesTable.filename')}
                        </th>
                        <th scope="col" style={{ width: 160 }}>
                            {t('page.asset.element.filesTable.created')}
                        </th>
                        <th scope="col" style={{ width: 160 }} className="text-center">
                            {t('page.asset.element.filesTable.options')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        files.filter(itm => !itm.trash).length ?
                            files.map((file) => {
                                if (file.trash) {
                                    return null;
                                }
                                return (
                                    <tr key={file.uuid} className={file.className}>
                                        <td className={`pl-${paddingLeft || 20}`}>
                                            <a target="_blank" rel="rel noopener noreferrer" href={file.link}>
                                                {file.filename}
                                            </a>
                                        </td>
                                        <td className="align-middle">
                                            {file.created}
                                        </td>
                                        <td className="align-middle text-center">
                                            <button
                                                type="button"
                                                className="btn btn-custom-round text-danger"
                                                data-tip={t('common.tooltip.remove')}
                                                onClick={
                                                    (e) => {
                                                        confirmRemove(
                                                            () => {
                                                                handleElementTrash(
                                                                    'files',
                                                                    file.uuid
                                                                );
                                                            }
                                                        )
                                                    }
                                                }
                                            >
                                                <Icon name="minus-square" />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })
                            :
                            (
                                <tr>
                                    <td className={`pl-${paddingLeft || 20}`}>
                                        {t('page.asset.element.filesTable.empty')}
                                    </td>
                                </tr>
                            )
                    }
                </tbody>
            </Table>
        );
    }
}

export default FilesTable;