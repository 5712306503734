import React, { Fragment } from 'react';
import BaseComponent from "../../components/component/base";
import Basic from "../User/basic";

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            newPassword: '',
            newPasswordRepeat: '',
            newPasswordComplexity: 0,
            oldPassword: '',
            roles: [],
            companies: [],
            consents: [],
            preferredCooperationModels: [],
            preferredCooperationTypes: [],
            configuration: {},
            ...props.user.user,
            avatar: props.user.files ? props.user.files.find(itm => itm.ident === 'avatar') : null
        }
    }

    saveData() {
        if (this.state.newPassword) {
            if (this.state.newPassword !== this.state.newPasswordRepeat) {
                this.props.showToast({
                    title: this.props.t('common.toast.mismatchPassword'),
                    color: "danger"
                });
                return;
            }
            if (this.state.newPasswordComplexity < this.props.utils.minimumPasswordComplexity()) {
                this.props.showToast({
                    title: this.props.t('common.toast.tooWeakPassword'),
                    color: "danger"
                });
                return;
            }
        }
        this.setState({ isLoading: true });
        this.props.api.post(
            `/user/update-self`,
            {
                "user": {
                    "firstName": this.state.firstName,
                    "secondName": this.state.secondName,
                    "lastName": this.state.lastName,
                    "emailAddress": this.state.emailAddress,
                    "mobilePhone": this.state.mobilePhone,
                    "password": this.state.newPassword,
                    "oldPassword": this.state.oldPassword,
                    "mentions": this.state.mentions,
                    "otpRequired": this.state.otpRequired,
                    "configuration": this.state.configuration,
                }
            },
            () => {
                this.setState({ isLoading: false });
                this.props.showToast({
                    title: this.props.t('common.toast.dataSavedRefresh'),
                    color: "info"
                });
            },
            (errorObject) => {
                this.setState({ isLoading: false });
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        );
    }

    render() {

        return (
            <Fragment>
                <Basic
                    {...this.props}
                    showAvatarUpload={true}
                    showAdminTab={false}
                    showOldPassword={true}
                    upperState={this.state}
                    setUpperState={(data) => this.setState(data)}
                    saveData={() => this.saveData()}
                />
            </Fragment>
        );
    }
}

export default Content
