import './style.scss';

import React, { Component } from 'react';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import ContentUsers from './content-users';
import ContentCustomers from './content-customers';

class Users extends Component {
    render() {
        if (this.props.customerContext) {
            return (
                <PageWrap>
                    <PageTitle
                        breadcrumbs={
                            {
                                '#': this.props.t('page.customer.shortTitle'),
                            }
                        }
                        meta={{ title: this.props.t(`page.customer.longTitle`) }}
                        t={this.props.t}
                    >
                        <h1>
                            {this.props.t('page.customer.longTitle')}
                        </h1>
                    </PageTitle>
                    <PageContent>
                        <ContentCustomers {...this.props} />
                    </PageContent>
                </PageWrap>
            );
        }

        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={
                        {
                            '#': this.props.t('page.users.shortTitle'),
                        }
                    }
                    meta={{ title: this.props.t(`page.users.longTitle`) }}
                    t={this.props.t}
                >
                    <h1>
                        {this.props.t('page.users.longTitle')}
                    </h1>
                </PageTitle>
                <PageContent>
                    <ContentUsers {...this.props} />
                </PageContent>
            </PageWrap>
        );
    }
    
}

export default Users;
