import React, { Component, Fragment } from 'react';
import { Row, Col, Table, Card, Button } from "react-bootstrap";

class Status extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wrapped: true
        }
    }

    render() {

        const { wrapped } = this.state;
        const { statuses } = this.props;
        const filteredStatuses = statuses.filter(itm => !itm.trash);

        return (
            <Fragment>
                <h2>
                    {this.props.t('page.calculation.status.title')}
                </h2>
                <Row className="mb-30">
                    <Col sm={12}>
                        <Card style={{ borderTop: 0 }}>
                            <Card.Body className="p-0">
                                <Table size="xs" className="mb-0" responsive>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="pl-20">
                                                {this.props.t('page.calculation.status.table.ident')}
                                            </th>
                                            <th scope="col" style={{ width: 250 }} className="pr-20 text-right">
                                                {this.props.t('page.calculation.status.table.created')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            filteredStatuses.length === 0 &&
                                            (
                                                <tr>
                                                    <td colSpan="2" className="pl-20">
                                                        {this.props.t('page.calculation.status.table.empty')}
                                                    </td>
                                                </tr>
                                            )
                                        }

                                        {
                                            filteredStatuses.length > 0 && wrapped &&
                                            (
                                                <>
                                                    <tr>
                                                        <td className="align-middle pl-20">{filteredStatuses[0].ident}</td>
                                                        <td className="align-middle pr-20 text-right">{filteredStatuses[0].created}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2" className="pl-20">
                                                            <Button
                                                                variant="link"
                                                                style={{ padding: 0 }}
                                                                onClick={(e) => this.setState({ wrapped: false })}
                                                            >
                                                                ...
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-middle pl-20">{filteredStatuses[filteredStatuses.length - 1].ident}</td>
                                                        <td className="align-middle pr-20 text-right">{filteredStatuses[filteredStatuses.length - 1].created}</td>
                                                    </tr>
                                                </>
                                            )
                                        }
                                        {
                                            filteredStatuses.length > 0 && !wrapped && filteredStatuses.map((status) => {
                                                return (
                                                    <tr key={status.uuid}>
                                                        <td className="align-middle pl-20">{status.ident}</td>
                                                        <td className="align-middle pr-20 text-right">{status.created}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment >
        );
    }
}
export default Status;