import React, { Fragment } from 'react';
import DebounceInput from 'react-debounce-input';
import { Row, Col, Form, Card } from "react-bootstrap";
import LowerComponent from '../../components/component/lower';
import SimpleMap from '../../components/maps/simple';

class Location extends LowerComponent {

    render() {

        const {
            isLoading,
            closed
        } = this.props;

        return (
            <Fragment>
                <h2>
                    {this.props.t('page.calculation.location.title')}
                    <div className="float-md-right clear-section-container">

                        {
                            this.props.hasPermission(['VIEW_OFFER_CLEAR_SECTION_BUTTON']).any && (
                                <button
                                    type="button"
                                    className="btn text-danger px-0"
                                    disabled={isLoading || closed}
                                    onClick={(e) => { this.props.clearSection(e, ['location', 'map']) }}
                                >
                                    {this.props.t(`common.button.clear`)}
                                </button>
                            )
                        }

                    </div>
                </h2>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <Card>
                            <Card.Body>
                                {
                                    this.props.isRegion(['GERMANY']) ? (
                                        <></>
                                    ) : (
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Group controlId="provinceName">
                                                        <Form.Label>{this.props.t('page.calculation.location.provinceName')}</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            disabled={isLoading || closed}
                                                            value={this.props.provinceName || ""}
                                                            onChange={event =>
                                                                this.props.setAddress({ provinceName: event.target.value })
                                                            }
                                                        >
                                                            <option value="">...</option>
                                                            {
                                                                this.props.utils.provincesOf(this.props.region).map(
                                                                    province => {
                                                                        return <option key={province} value={province.toLowerCase()}>{province}</option>
                                                                    }
                                                                )
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    )
                                }
                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="required">
                                            <Form.Label>
                                                {this.props.t('page.calculation.location.postalCode')}
                                            </Form.Label>
                                            <DebounceInput
                                                required={true}
                                                className="form-control"
                                                minLength={2}
                                                debounceTimeout={10000}
                                                type="text"
                                                value={this.props.postalCode || ""}
                                                disabled={isLoading || closed}
                                                onChange={event =>
                                                    this.props.setAddress({ postalCode: event.target.value })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Group className={this.props.getConfig('useCityNameRequired') ? "required" : ""}>
                                            <Form.Label>
                                                {this.props.t('page.calculation.location.cityName')}
                                            </Form.Label>
                                            <DebounceInput
                                                required={this.props.getConfig('useCityNameRequired') ? true : false}
                                                className="form-control"
                                                minLength={2}
                                                debounceTimeout={10000}
                                                type="text"
                                                value={this.props.cityName || ""}
                                                disabled={isLoading || closed}
                                                onChange={event =>
                                                    this.props.setAddress({ cityName: event.target.value })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group className={this.props.getConfig('useStreetAddressRequired') ? "required" : ""}>
                                            <Form.Label>
                                                {this.props.t('page.calculation.location.streetAddress')}
                                            </Form.Label>
                                            <DebounceInput
                                                required={this.props.getConfig('useStreetAddressRequired') ? true : false}
                                                className="form-control"
                                                minLength={2}
                                                debounceTimeout={10000}
                                                type="text"
                                                value={this.props.streetAddress || ""}
                                                disabled={isLoading || closed}
                                                onChange={event =>
                                                    this.props.setAddress({ streetAddress: event.target.value })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <Form.Group className={this.props.getConfig('useStreetNumberRequired') ? "required" : ""}>
                                            <Form.Label>
                                                {this.props.t('page.calculation.location.streetNumber')}
                                            </Form.Label>
                                            <DebounceInput
                                                required={this.props.getConfig('useStreetNumberRequired') ? true : false}
                                                className="form-control"
                                                minLength={1}
                                                debounceTimeout={10000}
                                                type="text"
                                                value={this.props.streetNumber || ""}
                                                disabled={isLoading || closed}
                                                onChange={event =>
                                                    this.props.setAddress({ streetNumber: event.target.value })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>
                                                {this.props.t('page.calculation.location.apartmentNumber')}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={this.props.t('page.calculation.location.option')}
                                                value={this.props.apartmentNumber || ""}
                                                disabled={isLoading || closed}
                                                onChange={event =>
                                                    this.props.setAddress({ apartmentNumber: event.target.value })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    {this.props.fullAddress && (
                        <Col sm={12} md={12} lg={12}>
                            <Card className="border-top-0">
                                <Card.Body>
                                    <SimpleMap
                                        defaultZoom={this.props.fullAddressWithDetails ? 17 : 14}
                                        fullAddress={this.props.fullAddress}
                                        defaultCenter={this.props.mapCenter}
                                        markers={this.props.mapMarkers}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Row>
            </Fragment>
        );
    }
}
export default Location;