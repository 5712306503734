import React, { Fragment } from 'react';
import Datatable from "../../components/datatable";
import BaseComponent from '../../components/component/base';
import Icon from '../../components/icon';
import { Row, Col, Card, Form } from "react-bootstrap";

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            onlyMyArea: this.props.hasPermission(['VIEW_MY_AREA_FILTER_BUTTON']).any
        };
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Header className="px-10 py-10 background-white">
                                <button
                                    type="button"
                                    className="btn btn-uniform text-success"
                                    disabled={false}
                                    onClick={(e) => { this.props.openLink('/company/') }}
                                >
                                    <Icon name="plus-square" />
                                    <span className="pl-10">
                                        {this.props.t('page.companies.newCompany')}
                                    </span>
                                </button>
                                {
                                    this.props.hasPermission(['VIEW_MY_AREA_FILTER_BUTTON']).any && (
                                        <div className="float-md-right clear-section-container">
                                            <Form.Check
                                                type="switch"
                                                id="onlyMyArea"
                                                label={this.props.t('page.companies.onlyMyArea')}
                                                checked={this.state.onlyMyArea || false}
                                                onChange={(e) => { this.setState({ onlyMyArea: !this.state.onlyMyArea }) }}
                                            />
                                        </div>
                                    )
                                }
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Datatable
                                    id={'company-table'}
                                    endpoint={`company/table`}
                                    monitorProp={this.state.onlyMyArea}
                                    withData={{
                                        "onlyMyArea": this.state.onlyMyArea
                                    }}
                                    columns={[
                                        {
                                            Header: this.props.t('page.companies.table.name'),
                                            accessor: "name_2"
                                        },
                                        {
                                            Header: this.props.t('page.companies.table.type'),
                                            accessor: "type_4",
                                            width: 120
                                        },
                                        {
                                            Header: this.props.t(`page.companies.table.area`),
                                            accessor: "ident_3",
                                            width: 100
                                        },
                                        {
                                            Header: (<>&nbsp;</>),
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                            width: 100
                                        }
                                    ]}
                                    sort={[
                                        {
                                            id: "name_2",
                                            desc: false
                                        }
                                    ]}
                                    withCheckbox={false}
                                    onChecked={(data) => { }}
                                    badges={[
                                        {
                                            "field": "type_4",
                                            "badgify": true
                                        },
                                        {
                                            "field": "ident_3",
                                            "badgify": true
                                        }
                                    ]}
                                    actions={[
                                        {
                                            action: 'edit',
                                            callback: (data) => {
                                                this.props.openLink(`/company/${data.uuid_0}/`)
                                            }
                                        },
                                        {
                                            action: 'delete',
                                            callback: (data) => {
                                                this.confirmRemove(() => {
                                                    this.props.api.post(
                                                        `/company/trash`,
                                                        {
                                                            "company": {
                                                                "uuids": [data.uuid_0]
                                                            }
                                                        },
                                                        () => {
                                                            this.props.api.dispatchEvent('RELOAD_TABLE');
                                                        }
                                                    )
                                                })
                                            }
                                        }
                                    ]}
                                    {...this.props}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }

}

export default Content
