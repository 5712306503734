import React, { Fragment } from 'react';
import { Row, Col, Card, Tabs, Tab, Form, Button, ListGroup } from "react-bootstrap";
import ColorPicker from '../../components/color-picker';
import LowerComponent from "../../components/component/lower-elements";
import Icon from '../../components/icon';
import SelectAsync from '../../components/select/async';
import { IsLoading } from '../../components/spinner';
import { teamsCategories } from '../../constants';

class PageTeamTabs extends LowerComponent {

    constructor(props) {
        super(props);
        this.state = {
            showColorPicker: false
        }
    }

    render() {

        const loadingView = (
            <IsLoading />
        );

        const formView = (
            <Fragment>
                <Form method="post" onSubmit={(e) => { e.preventDefault(); this.props.saveData(this.props.upperState) }} onClick={(e) => { if (e.target?.tagName !== 'INPUT') { this.setState({ showColorPicker: false }) } }}>
                    <Card>
                        <Card.Body>
                            <Tabs defaultActiveKey="metric" className="border-bottom nicebar">
                                <Tab eventKey="metric" title={this.props.t('page.team.component.basicData.title')}>
                                    <Row className="pt-20">
                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>{this.props.t('page.team.component.basicData.label')}</Form.Label>
                                                <Form.Control
                                                    disabled={this.props.upperState.isLoading}
                                                    required
                                                    type="text"
                                                    placeholder={this.props.t('page.team.component.basicData.placeholder.label')}
                                                    value={this.props.upperState.label || ""}
                                                    onChange={(e) => { this.onChangeSetUpperState('label', e) }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>{this.props.t('page.team.component.basicData.category')}</Form.Label>
                                                <Form.Control
                                                    required={true}
                                                    disabled={this.props.upperState.isLoading}
                                                    as="select"
                                                    custom
                                                    placeholder={this.props.t('page.team.component.basicData.placeholder.category')}
                                                    value={this.props.upperState.category || ""}
                                                    onChange={(e) => { this.onChangeSetUpperState('category', e) }}
                                                >
                                                    <option value="">{this.props.t('page.team.component.basicData.placeholder.category')}</option>
                                                    {
                                                        teamsCategories.map(rt => (
                                                            <option key={`rt-${rt}`} value={this.props.t(`common.teamsCategory.${rt}`)}>
                                                                {this.props.t(`common.teamsCategory.${rt}`)}
                                                            </option>
                                                        ))
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>{this.props.t('page.team.component.basicData.company')}</Form.Label>
                                                <SelectAsync
                                                    api={this.props.api}
                                                    t={this.props.t}
                                                    isClearable={false}
                                                    cache={true}
                                                    preload={true}
                                                    disabled={this.props.upperState.isLoading}
                                                    endpoint={`/company/list`}
                                                    value={this.props.upperState.company}
                                                    params={{ include: this.props.upperState.company ? [this.props.upperState.company.uuid] : [] }}
                                                    transformData={(data, callback) => {
                                                        let newData = data.companies.map(itm => { return { ...itm, value: itm.uuid } });
                                                        callback(newData)
                                                    }}
                                                    onChange={(data) => {
                                                        this.setUpperState({ company: data });
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>{this.props.t('page.team.component.basicData.color')}</Form.Label>
                                                <Form.Control
                                                    disabled={this.props.upperState.isLoading}
                                                    required
                                                    type="text"
                                                    placeholder={this.props.t('page.team.component.basicData.placeholder.color')}
                                                    value={this.props.upperState.color || ""}
                                                    onClick={() => this.setState({ showColorPicker: !this.state.showColorPicker })}
                                                    style={{ backgroundColor: `${this.props.upperState.color}`, color: `${this.props.utils.contrastColor(this.props.upperState.color)}` }}
                                                    onChange={(e) => { this.onChangeSetUpperState('color', e) }}
                                                    pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                                                />
                                                {
                                                    this.state.showColorPicker && (
                                                        <ColorPicker
                                                            value={this.props.upperState.color}
                                                            onChange={(data) => {
                                                                this.setUpperState({ color: data.hex });
                                                            }}
                                                        />
                                                    )
                                                }
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>
                                                    <span>
                                                        {this.props.t('page.team.component.basicData.users')}
                                                    </span>
                                                    {` `}
                                                    <button
                                                        type="button"
                                                        className={"btn pr-0 pl-0"}
                                                        onClick={(e) => { this.props.setUpperState({ users: [...this.props.upperState.users].concat([{ "uuid": null }]) }) }}
                                                    >
                                                        <Icon name="plus" />
                                                        {this.props.t('common.button.add')}
                                                    </button>

                                                </Form.Label>
                                                <ListGroup>
                                                    {
                                                        this.props.upperState.users.map((user, index) => {
                                                            return (
                                                                <ListGroup.Item key={`user-${user.uuid}-${index}`} style={{ padding: 0, border: 0, marginBottom: 15 }}>
                                                                    <Form.Group as={Row} className="mb-0">
                                                                        <Col xs={10}>
                                                                            <SelectAsync
                                                                                api={this.props.api}
                                                                                t={this.props.t}
                                                                                isClearable={false}
                                                                                cache={true}
                                                                                preload={true}
                                                                                disabled={this.props.upperState.isLoading}
                                                                                endpoint={`/user/list`}
                                                                                value={user}
                                                                                params={{ include: [user.uuid] }}
                                                                                transformData={(data, callback) => {
                                                                                    let newData = data.users.map(itm => { return { ...itm, value: itm.uuid } });
                                                                                    callback(newData)
                                                                                }}
                                                                                onChange={(data) => {
                                                                                    let newUsers = [...this.props.upperState.users];
                                                                                    newUsers[index] = data;
                                                                                    this.setUpperState({ users: newUsers });
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={2} className="text-right">
                                                                            <button
                                                                                type="button"
                                                                                className={"btn pr-0 pl-0 ml-10"}
                                                                                onClick={(e) => { this.props.setUpperState({ users: this.spliceNoMutate(this.props.upperState.users, index) }) }}
                                                                            >
                                                                                <Icon name="minus" />
                                                                            </button>
                                                                        </Col>
                                                                    </Form.Group>
                                                                </ListGroup.Item>
                                                            )
                                                        })
                                                    }
                                                </ListGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                    <Row className="mb-30">
                        <Col sm={12}>
                            <div className="pt-30">
                                <Button
                                    variant="success"
                                    size="lg"
                                    disabled={this.props.upperState.isLoading}
                                    onClick={(e) => { this.props.saveData() }}
                                >
                                    <Icon name="save" />
                                    <span className="pl-10">
                                        {this.props.t('page.team.component.options.saveData')}
                                    </span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        );

        return this.props.upperState.isLoading ? loadingView : formView;
    }
}

export default PageTeamTabs
