import './style.scss';
import "../../assets/css/fa/css/all.css";
import _ from 'lodash';
import React from "react";
import BaseComponent from "../component/base";
import ReactTable from "react-table-6";
import ReactTooltip from "react-tooltip";
import { Button, Form, Spinner } from "react-bootstrap";
import AutoBadge from '../auto-badge';
import { CSVLink } from "react-csv";
import Icon from "../icon";

export function restoreSettings(key) {
    let restoredObj = JSON.parse(window.localStorage.getItem(key)) || {};
    return restoredObj;
};

export function storeSettings(key, data) {
    window.localStorage.setItem(key, JSON.stringify(data));
}

class Datatable extends BaseComponent {

    constructor(props) {
        super(props);

        this.tableKey = `bp_table_${this.props.id}`;
        const restoredObj = restoreSettings(this.tableKey);

        this.state = {
            isLoading: false,
            isTableReloading: false,
            data: [],
            pages: -1,
            page: 0,
            pageSize: 10,
            defaultPageSize: 10,
            checkAll: false,
            checked: [],
            ...restoredObj,
        };
        this.reloadTableBounce = _.debounce(this.reloadTableBounce.bind(this), 500);
        this.handleFilterChangeBounce = _.debounce(this.handleFilterChangeBounce.bind(this), 500);
        this.setPageSize = _.debounce(this.setPageSize.bind(this), 100);
    }

    componentDidMount() {
        window.addEventListener("ON_PAGESIZE_TABLE", this.setPageSize, false);
        window.addEventListener("ON_PAGE_TABLE", this.setPageSize, false);
        window.addEventListener("RELOAD_TABLE", this.reloadTableBounce, false);
    }

    componentWillUnmount() {
        window.removeEventListener('ON_PAGESIZE_TABLE', this.setPageSize, false);
        window.removeEventListener('ON_PAGE_TABLE', this.setPageSize, false);
        window.removeEventListener('RELOAD_TABLE', this.reloadTableBounce, false);
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.endpoint !== nextProps.endpoint || this.props.monitorProp !== nextProps.monitorProp) {
            this.reloadTableBounce();
            return false;
        }
        return true;
    }

    setPageSize(e) {
        this.setState(e.detail.data, () => {
            this.storeLocalSettings();
        });
    }

    storeLocalSettings() {
        storeSettings(this.tableKey, {
            page: this.state.page,
            pageSize: this.state.pageSize
        });
    }

    reloadTableBounce() {
        this.setState(
            { isTableReloading: true },
            () => {
                this.setState({ isTableReloading: false })
            }
        );
    }

    getButton = (rowData, name, disabled, callback, newStyle) => {
        if (typeof disabled === "function") {
            disabled = disabled(rowData);
        }
        switch (name) {
            case 'details':
                return (
                    <Button
                        style={{ width: 40, ...newStyle }}
                        key={`action-${name}`}
                        className="px-15 capture-middle-click"
                        variant="default"
                        disabled={disabled}
                        data-tip={this.props.t('component.datatable.details')}
                        onClick={(e) => { callback(rowData) }}
                        onAuxClick={(e) => { if (event.button === 1) { callback(rowData, '_blank') } }}
                    >
                        <i className="fa fa-search" />
                    </Button>
                )
            case 'files':
                return (
                    <Button
                        style={{ width: 40, ...newStyle }}
                        key={`action-${name}`}
                        className="px-15 capture-middle-click"
                        variant="default"
                        disabled={disabled}
                        data-tip={this.props.t('component.datatable.files')}
                        onClick={(e) => { callback(rowData) }}
                        onAuxClick={(e) => { if (e.button === 1) { callback(rowData, '_blank') } }}
                    >
                        <i className="fa fa-file-pdf" />
                    </Button>
                )
            case 'edit':
                return (
                    <Button
                        style={{ width: 40, ...newStyle }}
                        key={`action-${name}`}
                        className="px-15 capture-middle-click"
                        variant="default"
                        disabled={disabled}
                        data-tip={this.props.t('component.datatable.edit')}
                        onClick={(e) => { callback(rowData) }}
                        onAuxClick={(e) => { if (e.button === 1) { callback(rowData, '_blank') } }}
                    >
                        <i className="fa fa-edit" />
                    </Button>
                )
            case 'print':
                return (
                    <Button
                        style={{ width: 40, ...newStyle }}
                        key={`action-${name}`}
                        className="px-15"
                        variant="default"
                        disabled={disabled}
                        data-tip={this.props.t('component.datatable.print')}
                        onClick={() => { callback(rowData) }}
                    >
                        <i className="fa fa-print" />
                    </Button >
                )
            case 'delete':
                return (
                    <Button
                        style={{ width: 40, ...newStyle }}
                        key={`action-${name}`}
                        className="text-danger px-15"
                        variant="default"
                        disabled={disabled}
                        data-tip={this.props.t('component.datatable.delete')}
                        onClick={() => { callback(rowData) }}
                    >
                        <i className="fa fa-times" />
                    </Button>
                )
            default:
                return (
                    <></>
                )
        }
    }

    handleFilterChangeBounce(value, onChange) {
        onChange(value);
    }

    customFilter = ({ filter, onChange }) => {
        return (
            <Form.Control
                placeholder={this.props.t('component.datatable.search')}
                onChange={event => {
                    this.handleFilterChangeBounce(event.target.value, onChange)
                }}
                onKeyPress={event => {
                    if (event.keyCode === 13 || event.which === 13) {
                        this.handleFilterChangeBounce(event.target.value, onChange)
                    }
                }}
            />
        )
    }

    reMapRows(data) {
        let self = this;
        return data.map((prop) => {

            let rawData = { ...prop };

            if (self.props.transform) {
                self.props.transform.forEach((transformDefinition) => {
                    prop[transformDefinition.field] = transformDefinition.fn(rawData);
                })
            }

            if (self.props.withCheckbox) {
                prop.checkbox = (
                    <div className="text-center">
                        <Form.Check
                            checked={self.state.checked.some(e => e.uuid === prop.uuid)}
                            onChange={event => {
                                self.checkOne(prop.uuid, event.target.checked);
                            }}
                        />
                    </div>
                );
            }

            if (self.props.badges) {
                self.props.badges.forEach((badgeDefinition) => {
                    let localProps = {
                        "pill": true,
                        "divClassName": badgeDefinition.divClass || `text-center`
                    }
                    if (badgeDefinition.variants) {
                        localProps.variant = badgeDefinition.variants[prop[badgeDefinition.field]]
                    }
                    if (badgeDefinition.separator) {
                        prop[badgeDefinition.field] = prop[badgeDefinition.field]?.split(badgeDefinition.separator).map((needle) => {
                            return (
                                <AutoBadge
                                    {...localProps}
                                    inline={true}
                                    text={needle || ""}
                                    utils={self.props.utils}
                                />
                            )
                        });
                    } else {
                        prop[badgeDefinition.field] = (
                            <AutoBadge
                                {...localProps}
                                text={prop[badgeDefinition.field]}
                                utils={self.props.utils}
                            />
                        );
                    }
                })
            }

            prop.actions = (
                <div className={self.props.actionsClassName || "actions-right"}>
                    {
                        self.props.actions?.map((item) => {
                            return self.getButton(rawData, item.action, item.disabled || false, item.callback, item.style || {});
                        })
                    }
                </div>
            );
            return prop;
        });
    }

    checkSelected(checkedItems, all) {
        this.setState(
            {
                checked: checkedItems,
                checkAll: all
            },
            () => {
                this.setState({ data: this.reMapRows(this.state.data) });
                if (typeof this.props.onChecked === "function") {
                    this.props.onChecked(checkedItems);
                }
            }
        );
    }

    checkAll(checkedValue) {
        if (checkedValue) {
            let checked = [];
            this.state.data.forEach(
                (element) => {
                    checked.push({ uuid: element.uuid });
                }
            );
            this.checkSelected(checked, true);
        } else {
            this.checkSelected([], false);
        }
    }

    checkOne(uuid, checkedValue) {
        let checked = this.state.checked;
        if (checkedValue) {
            checked = this.state.checked.concat([{ uuid: uuid }])
        } else {
            checked = this.state.checked.filter((item) => { return item.uuid !== uuid })
        }
        this.checkSelected(checked, false);
    }

    getCleanData() {
        let data = [...this.state.data];
        let colsMap = {};
        let cols = this.getCols();
        cols.forEach(col => {
            colsMap[col.accessor] = col.Header
        });
        data = data.map(itm => {
            let newItm = {};
            cols.forEach(col => {
                let k = col.accessor;
                let key = colsMap[k] || k;
                let value = itm[k];
                if (col.accessor === 'actions') {
                    return;
                }
                if (typeof itm[k] === 'object') {
                    value = this.props.utils.getInnerText(itm[k]);
                }
                newItm[key] = value;
            })
            return newItm;
        });
        return data;
    }

    getCols() {
        return this.props.columns.slice().filter((itm) => !itm.hidden).map((col) => {
            col.Filter = this.customFilter;
            return col;
        });
    }

    getCsvName() {
        return 'sd_export_' + (window.location.pathname + '_' + new Date().toLocaleString()).replace(/\s+/g, '').replaceAll('/', '_') + '.csv';
    }

    render() {
        let cols = this.getCols();

        if (this.props.withCheckbox) {
            cols.unshift({
                Header: (
                    <Form.Check
                        type="checkbox"
                        checked={this.state.checkAll}
                        onChange={event => {
                            this.checkAll(event.target.checked);
                        }}
                    />
                ),
                accessor: "checkbox",
                sortable: false,
                filterable: false,
                width: 80,
            });
        }

        return (
            <>
                <ReactTooltip />
                {
                    this.state.isTableReloading
                        ?
                        (
                            <div className="text-center p-30">
                                <Spinner animation="border" />
                            </div>
                        )
                        :
                        (
                            <>
                                <ReactTable
                                    manual
                                    filterable
                                    pageSize={this.state.pageSize}
                                    pageIndex={this.state.page}
                                    defaultPageSize={this.state.defaultPageSize}
                                    pageSizeOptions={[10, 25, 50, 100, 150, 200]}
                                    showPaginationBottom
                                    className="-striped -highlight"
                                    previousText={this.props.t('component.datatable.previousText')}
                                    nextText={this.props.t('component.datatable.nextText')}
                                    noDataText={this.props.t('component.datatable.noDataText')}
                                    loadingText={<Spinner animation="border" />}
                                    pageText={this.props.t('component.datatable.pageText')}
                                    ofText={this.props.t('component.datatable.ofText')}
                                    rowsText={this.props.t('component.datatable.rowsText')}
                                    data={this.state.data}
                                    pages={this.state.pages}
                                    loading={this.state.isLoading}
                                    columns={cols}
                                    defaultSorted={this.props.sort}

                                    // Callbacks
                                    onPageChange={(pageIndex) => { this.props.api.dispatchEvent('ON_PAGE_TABLE', { data: { pageIndex }, table: this.props.id }); }} // Called when the page index is changed by the user
                                    onPageSizeChange={(pageSize, pageIndex) => { this.props.api.dispatchEvent('ON_PAGESIZE_TABLE', { data: { pageSize, pageIndex }, table: this.props.id }); }} // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
                                    onSortedChange={(newSorted, column, shiftKey) => { this.props.api.dispatchEvent('ON_SORTED_TABLE', { data: { newSorted, column }, table: this.props.id }); }} // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
                                    onExpandedChange={(newExpanded, index, event) => { this.props.api.dispatchEvent('ON_EXPANDED_TABLE', { data: { newExpanded, index }, table: this.props.id }); }} // Called when an expander is clicked. Use this to manage `expanded`
                                    onFilteredChange={(filtered, column) => { this.props.api.dispatchEvent('ON_FILTERED_TABLE', { data: { filtered, column }, table: this.props.id }); }} // Called when a user enters a value into a filter input field or the value passed to the onFiltersChange handler by the Filter option.
                                    onResizedChange={(newResized, event) => { this.props.api.dispatchEvent('ON_RESIZED_TABLE', { data: { newResized, event }, table: this.props.id }); }} // Called when a user clicks on a resizing component (the right edge of a column header)

                                    // Ajax
                                    onFetchData={(state) => {
                                        let self = this;
                                        self.setState({ isLoading: true }, () => {
                                            self.props.api.post(
                                                self.props.endpoint,
                                                Object.assign(
                                                    {},
                                                    {
                                                        page: state.page,
                                                        pageSize: state.pageSize,
                                                        sorted: state.sorted,
                                                        filtered: state.filtered
                                                    },
                                                    this.props.withData || {}
                                                ),
                                                (res) => {
                                                    const callback = (res) => {
                                                        let mappedRows = self.reMapRows(res.rows);
                                                        if (typeof this.props.reMapRows === 'function') {
                                                            mappedRows = this.props.reMapRows(res.rows);
                                                        }
                                                        self.setState(
                                                            {
                                                                data: mappedRows,
                                                                page: res.page,
                                                                pages: res.pages,
                                                                pageSize: res.pageSize,
                                                                isLoading: false,
                                                            },
                                                            () => {
                                                                ReactTooltip.rebuild();
                                                                this.storeLocalSettings();
                                                            }
                                                        );
                                                    }
                                                    if (typeof this.props.afterFetch === 'function') {
                                                        this.props.afterFetch(res, () => {
                                                            callback(res)
                                                        });
                                                    } else {
                                                        callback(res);
                                                    }

                                                },
                                                () => {
                                                    self.setState({ isLoading: false });
                                                }
                                            );
                                        });
                                    }}
                                />
                                {
                                    this.props.showCsvLink && (
                                        <CSVLink
                                            filename={this.getCsvName()}
                                            data={this.getCleanData()}
                                            className="badge badge-secondary px-10 py-8"
                                            style={{ marginTop: 10, fontSize: 14 }}
                                            separator={";"}
                                        >
                                            <Icon name='download' style={{ marginRight: 5, width: 14, minWidth: 14, height: 14 }} />
                                            <span>CSV</span>
                                        </CSVLink>
                                    )
                                }
                            </>
                        )

                }
            </>
        );
    }
}

export default Datatable;