import React, { Fragment } from 'react';
import Basic from "./basic";
import BaseComponent from "../../components/component/base";
import { assetStatuses } from '../../constants';

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isReadOnly: props.isReadOnly || false,

            uuid: "",
            assetType: "",
            category: "asset",
            ident: "",
            serialNumber: "",
            name: "",
            nameOfOwningCompany: "",
            product: null,

            countryCodeOfUser: this.props.user.region.countryCode,
            firstNameOfUser: this.props.user.user.firstName,
            lastNameOfUser: this.props.user.user.lastName,
            emailAddressOfUser: this.props.user.user.emailAddress,
            mobilePhoneOfUser: this.props.user.user.mobileNumber,

            positionOfUser: "",
            firstNameOfSupervisor: "",
            lastNameOfSupervisor: "",
            emailAddressOfSupervisor: "",
            mobilePhoneOfSupervisor: "",
            contractType: "",
            currencyCode: "",
            totalValue: "",
            entryFee: "",
            monthlyFee: "",
            redemptionValue: "",
            purchaseDate: "",
            expirationDate: "",
            grantName: "",
            pinOfDevice: "",
            pukOfDevice: "",

            comment: "",
            comments: [],

            files: [],

            status: "",
            statusComment: "",
            statuses: [],

            view: 'asset',
            fields: this.props.fields
        };
    }

    componentDidMount() {
        let uuid = this.props.match.params.uuid || this.props.uuid;
        if (uuid) {
            this.loadData(uuid);
        } else {
            this.setState({ isLoading: false })
        }
    }

    loadData(uuid) {
        this.setState({ isLoading: true });
        this.props.api.post(
            `/asset/describe`,
            {
                "asset": {
                    "uuid": uuid,
                }
            },
            (data) => {
                this.setData(data);
            }
        );
    }

    saveData() {
        this.setState({ isLoading: true });
        this.props.api.post(
            this.state.uuid ? `/asset/update` : `/asset/create`,
            {
                "asset": {
                    "uuid": this.state.uuid,
                    "assetType": this.state.assetType,
                    "ident": this.state.ident,
                    "category": this.state.category,
                    "serialNumber": this.state.serialNumber,
                    "name": this.state.name,
                    "nameOfOwningCompany": this.state.nameOfOwningCompany,
                    "countryCodeOfUser": this.state.countryCodeOfUser,
                    "firstNameOfUser": this.state.firstNameOfUser,
                    "lastNameOfUser": this.state.lastNameOfUser,
                    "emailAddressOfUser": this.state.emailAddressOfUser,
                    "mobilePhoneOfUser": this.state.mobilePhoneOfUser,
                    "positionOfUser": this.state.positionOfUser,
                    "firstNameOfSupervisor": this.state.firstNameOfSupervisor,
                    "lastNameOfSupervisor": this.state.lastNameOfSupervisor,
                    "emailAddressOfSupervisor": this.state.emailAddressOfSupervisor,
                    "contractType": this.state.contractType,
                    "currencyCode": this.state.currencyCode,
                    "totalValue": this.state.totalValue,
                    "entryFee": this.state.entryFee,
                    "monthlyFee": this.state.monthlyFee,
                    "redemptionValue": this.state.redemptionValue,
                    "purchaseDate": this.state.purchaseDate,
                    "expirationDate": this.state.expirationDate,
                    "grantName": this.state.grantName,
                    "pinOfDevice": this.state.pinOfDevice,
                    "pukOfDevice": this.state.pukOfDevice,
                },
                "return": {
                    "url": `${this.props.getOrigin()}/${this.state.category}/{{uuid}}`
                },
                "assetStatus": {
                    "ident": this.state.uuid ? this.state.status : assetStatuses[0],
                    "comment": this.state.statusComment,
                },
                "product": {
                    "uuid": this.state.product
                },
                "comment": {
                    "content": this.state.comment
                },
                "files": this.state.files
            },
            (data) => {
                this.setState({ isLoading: false });
                this.props.showToast({
                    title: this.props.t('common.toast.dataSaved'),
                    color: "info"
                });
                this.setData(data);
                this.props.openLink(`/${this.state.view}/${data.asset.uuid}/`);
            },
            (errorObject) => {
                this.setState({ isLoading: false });
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: "danger"
                });
            }
        );
    }

    setData(data) {
        this.setState({
            ...data.asset,
            statuses: data.assetStatuses,
            comments: data.comments,
            files: data.files,
            comment: "",
            isLoading: false
        });
    }

    render() {
        return (
            <Fragment>
                <Basic
                    {...this.props}
                    upperState={this.state}
                    setUpperState={(data) => this.setState(data)}
                    saveData={(data) => this.saveData(data)}
                />
            </Fragment>
        );
    }
}

export default Content
