import React, { Fragment } from 'react';
import { DateTime, Interval } from "luxon";
import ModalTask from './modal-task';
import ModalProject from './modal-project';
import Icon from '../../components/icon';
import { IsLoading } from '../../components/spinner';
import { Badge, Modal } from 'react-bootstrap';
import BaseComponent from '../../components/component/base';
import FilterProjects from './filter-projects';
import _ from 'lodash';
import { restoreFilters } from '../Scheduler/filter-projects';

class Content extends BaseComponent {

    constructor(props) {
        super(props);

        this.default = {
            pageSize: 25
        };
        this.state = {
            df: "dd.MM",

            projectsuuids: [],
            projects: [],
            dates: [],
            tasks: [],
            shifts: {},
            colors: {},

            cellMeasured: false,
            cellWidth: 100,
            cellHeight: 100,
            cellPaddingX: 0,
            cellPaddingY: 0,

            taskHeight: 26,
            taskMargin: 4,

            draggable: true,
            dragStopped: 0,

            modalTask: false,
            modalProject: false,

            isLoading: true,
            isAdding: false,
            modalData: {},

            filters: {},
            filtersInitiated: false,
            modalFilters: false,

            wrapperWidth: 100,
            wrapperHeight: 100,

            page: 0,
            pageSize: this.default.pageSize,
            hasMore: true,
            scrollTop: 0,
            scrollLeft: 0,
        };

        this.dragged = null;
        this.today = DateTime.local();
        this.todayIso = this.today.toISODate();

        this.onDrag = this.onDrag.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onResize = this.onResize.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.fetchFreshData = this.fetchFreshData.bind(this);
        this.fetchFreshDataListener = this.fetchFreshDataListener.bind(this);
        this.showFilters = this.showFilters.bind(this);
        this.renderProject = this.renderProject.bind(this);
        this.onSchedulerWrapperScroll = this.onSchedulerWrapperScroll.bind(this);

        this.schedulerRef = React.createRef();
        this.isFetching = false;
    }

    componentDidMount() {
        document.addEventListener("drag", this.onDrag, false);
        document.addEventListener("dragstart", this.onDragStart, false);
        document.addEventListener("dragend", this.onDragEnd, false);
        document.addEventListener("dragover", this.onDragOver, false);
        document.addEventListener("dragenter", this.onDragEnter, false);
        document.addEventListener("dragleave", this.onDragLeave, false);
        document.addEventListener("drop", this.onDrop, false);
        window.addEventListener("resize", this.onResize, false);
        window.addEventListener("FETCH_SCHEDULER", this.fetchFreshDataListener, false);
        window.addEventListener("ADJUST_SCHEDULER", this.showFilters, false);
        this.fetchFilters(() => {
            this.parsePathAndOpenModal();
            this.fetchData();
        })
    }

    componentWillUnmount() {
        document.removeEventListener("drag", this.onDrag, false);
        document.removeEventListener("dragstart", this.onDragStart, false);
        document.removeEventListener("dragend", this.onDragEnd, false);
        document.removeEventListener("dragover", this.onDragOver, false);
        document.removeEventListener("dragenter", this.onDragEnter, false);
        document.removeEventListener("dragleave", this.onDragLeave, false);
        document.removeEventListener("drop", this.onDrop, false);
        window.removeEventListener("resize", this.onResize, false);
        window.removeEventListener('FETCH_SCHEDULER', this.fetchFreshDataListener, false);
        window.removeEventListener('ADJUST_SCHEDULER', this.showFilters, false);
    }

    fetchFilters(callback) {
        const restored = restoreFilters();
        this.setState({ filters: restored }, callback)
    }

    showFilters() {
        this.setState({ modalFilters: true })
    }

    fetchFreshDataListener(e) {
        if (e.detail?.hasprojectsuuids) {
            this.setState({ projectsuuids: e.detail?.projectsuuids || [] }, () => {
                this.fetchFreshData({})
            });
        } else {
            this.fetchFreshData({})
        }
    }

    fetchFreshData(newState) {
        const theState = {
            projects: [],
            page: 0,
            pageSize: (this.state.page + 1) * this.state.pageSize,
            isLoading: true,
            hasMore: true,
            ...newState
        };
        this.setState(theState, () => {
            this.fetchData(!theState.isLoading, () => {
                this.setState({ pageSize: this.default.pageSize })
            });
        })
    }

    fetchData(addMoreData, callback) {
        const allFinished = () => {
            this.setState({ isLoading: false, isAdding: false }, () => {
                this.isFetching = false;
                this.onResize();
                if (typeof callback === 'function') {
                    callback();
                }
            });
        }

        if (this.isFetching) {
            return;
        }

        this.isFetching = true;
        const { filters } = this.state;

        this.setState({ isLoading: !addMoreData, isAdding: true }, () => {
            this.fetchProjects(filters, (projects) => {
                this.fetchTasks(filters, (tasks) => {
                    this.setDates(() =>
                        this.setProjectsAndContractors(projects, () =>
                            this.setTasks(tasks, () => {
                                return allFinished();
                            })
                        )
                    );
                });
            });
        });
    }

    onResize() {
        this.setResizeables();
        this.setCellWidth();
        this.scrollToToday();
        this.setSchedulerWrapper();
    }

    onSchedulerWrapperScroll() {
        if (this.schedulerRef.current) {
            const { scrollTop, scrollLeft, scrollHeight, clientHeight } = this.schedulerRef.current;
            if (scrollTop + clientHeight >= (scrollHeight - 5)) {
                this.setState({ scrollTop, scrollLeft }, () => {
                    if (this.state.hasMore) {
                        this.fetchData(true);
                    }
                })
            }
        }
    }

    setSchedulerWrapper() {
        const jwrapper = window.jQuery('#scheduler-wrapper');
        if (!jwrapper || !jwrapper.offset()) {
            return;
        }
        const jwindow = window.jQuery(window);
        const windowHeight = jwindow.height();
        const offsetTop = jwrapper.offset().top;
        jwrapper.height(windowHeight - offsetTop);
        jwrapper.scrollTop(this.state.scrollTop);
        jwrapper.scrollLeft(this.state.scrollLeft);
        this.setState({ wrapperWidth: jwrapper.width(), wrapperHeight: jwrapper.height() });
    }

    parsePathAndOpenModal() {
        const particles = this.props.location.hash.replace('#', '').split('/').filter(Boolean);
        if (particles.length < 3) {
            return;
        }
        const path = particles[0];
        const uuid = particles[1];
        const view = particles[2];
        const subuuid = particles[3] || '';
        switch (path) {
            case 'project':
                this.showModal(null, 'modalProject', { projectuuid: uuid, uri: `/scheduler/#/project/${uuid}/${view}/${subuuid}`, activeTab: view })
                break;

            case 'task':
                this.showModal(null, 'modalTask', { taskuuid: uuid, uri: `/scheduler/#/task/${uuid}/${view}/${subuuid}`, activeTab: view })
                break;

            default:
                break;
        }
    }

    fetchProjects(criteria, callback) {
        if (_.isEmpty(criteria)) {
            return callback([]);
        }
        if (!this.state.hasMore) {
            return callback([]);
        }
        let payload = {
            filter: {
                ...criteria,
                projectsuuids: [...this.state.projectsuuids]
            },
            page: this.state.page,
            pageSize: this.state.pageSize
        }

        this.props.api.post(
            `/project/list`,
            payload,
            (data) => {
                this.setState({
                    page: this.state.page + 1,
                    hasMore: data.projects.length === this.state.pageSize
                }, () => {
                    const newProjects = [...this.state.projects].concat(data.projects || []).filter(Boolean);
                    callback(newProjects);
                })
            },
            (errorObject) => {
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: 'danger'
                });
            }
        );
    }

    fetchTasks(criteria, callback) {
        if (_.isEmpty(criteria)) {
            return callback([]);
        }
        let payload = {
            filter: {
                ...criteria,
                projectsuuids: [...this.state.projectsuuids]
            },
            page: this.state.page,
            pageSize: this.state.pageSize
        }

        this.props.api.post(
            `/task/list`,
            payload,
            (data) => {
                callback(data.tasks)
            },
            (errorObject) => {
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: 'danger'
                });
            }
        );
    }

    onDrag(event) {
        // console.log(event)
    }

    onDragStart(event) {
        this.dragged = event.target;
        event.target.style.opacity = .5;
        this.setEventsOnTasks(event.target, false);
    }

    onDragEnd(event) {
        event.target.style.opacity = "";
    }

    onDragOver(event) {
        event.preventDefault();
    }

    onDragEnter(event) {
        if (event.target.classList.contains("droptask")) {
            event.target.classList.add("drop-it-like-its-hot");
        }
    }

    onDragLeave(event) {
        if (event.target.classList.contains("droptask")) {
            event.target.classList.remove("drop-it-like-its-hot");
        }
    }

    onDrop(event) {
        event.preventDefault();
        if (event.target.classList.contains("droptask")) {
            event.target.classList.remove("drop-it-like-its-hot");
            const oldTask = this.getTask(this.dragged.dataset.taskuuid).task;
            const newSince = DateTime.fromISO(event.target.dataset.day);
            const newContractorUuid = event.target.dataset.contractoruuid;
            const deltaDays = newSince.diff(oldTask.since, 'days').toObject()['days'];
            if (this.dragged.dataset.projectuuid !== event.target.dataset.projectuuid) {
                alert('You cannot move tasks between projects');
                return;
            }
            this.setTask(
                oldTask.uuid,
                {
                    contractor: { uuid: newContractorUuid },
                    task: {
                        since: oldTask.since.plus({ days: deltaDays }),
                        till: oldTask.till.plus({ days: deltaDays })
                    }
                },
                () => { }
            );
        }
        this.setEventsOnTasks(null, true);
    }

    setEventsOnTasks(despiteElement, onOff) {
        const collection = document.getElementsByClassName("task");
        for (let i = 0; i < collection.length; i++) {
            if (collection[i] === despiteElement) {
                continue;
            }
            if (onOff) {
                collection[i].classList.remove("no-events");
            } else {
                collection[i].classList.add("no-events");
            }
        }
    }

    getProjectsContainerDimensions() {
        scheduler - table
    }

    setCellWidth() {
        // set sticky columns
        const theFirstColumns = document.getElementsByClassName('first-col');
        const theSecondColumns = document.getElementsByClassName('second-col');
        const paddingLeft = 5;

        if (theFirstColumns && theFirstColumns.length && theSecondColumns && theSecondColumns.length) {
            let maxFirstWidth = 0;
            let maxSecondWidth = 0;

            for (let i = 0; i < theFirstColumns.length; i++) {
                const subElements = theFirstColumns[i].getElementsByClassName('measure');
                for (let j = 0; j < subElements.length; j++) {
                    const measure = this.props.utils.getApproxTextWidth(subElements[j].innerText, 16);
                    if (measure > maxFirstWidth) {
                        maxFirstWidth = measure;
                    }
                }
            }

            for (let i = 0; i < theSecondColumns.length; i++) {
                const subElements = theSecondColumns[i].getElementsByClassName('measure');
                for (let j = 0; j < subElements.length; j++) {
                    const measure = this.props.utils.getApproxTextWidth(subElements[j].innerText, 16);
                    if (measure > maxSecondWidth) {
                        maxSecondWidth = measure;
                    }
                }
            }

            maxFirstWidth = Math.round(maxFirstWidth) - paddingLeft;
            maxSecondWidth = Math.round(maxSecondWidth);

            for (let i = 0; i < theFirstColumns.length; i++) {
                theFirstColumns[i].style.width = `${maxFirstWidth}px`;
            }

            for (let i = 0; i < theSecondColumns.length; i++) {
                theSecondColumns[i].style.width = `${maxSecondWidth}px`;
                theSecondColumns[i].style.left = `${maxFirstWidth}px`;
            }
        }
        // measure cells
        const theCell = document.getElementsByClassName("cell-date");
        if (theCell && theCell.length) {
            var computedStyle = getComputedStyle(theCell[0]);
            let cellWidth = theCell[0].clientWidth;
            let cellHeight = theCell[0].clientHeight;
            let cellPaddingY = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
            let cellPaddingX = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
            let cellMeasured = true;
            this.setState({ cellWidth, cellHeight, cellPaddingX, cellPaddingY, cellMeasured });
        }
    }

    scrollToToday() {
        const todayElement = document.getElementsByClassName('thtoday');
        if (todayElement.length) {
            if (this.state.scrollLeft === 0) {
                todayElement[0].scrollIntoView({ block: 'nearest', inline: 'center' });
            }
        }
    }

    setProjectsAndContractors(projects, callback) {
        let colors = {};
        projects.forEach(projectObj => {
            projectObj.contractors.forEach(contractorObj => {
                const contractor = contractorObj.contractor;
                colors[contractor.uuid] = contractor['color'];
            })
        })
        this.setState({ projects, colors }, callback);
    }

    setDates(callback) {
        const splitBy = this.state.filters.view || 'days';
        const startOf = { 'days': 'day', 'weeks': 'week', 'months': 'month' }[splitBy];
        // https://moment.github.io/luxon/docs/manual/formatting.html#macro-tokens
        const dateFormat = { 'days': "dd.MM", 'weeks': "WW/kk (MMM)", 'months': "LL/yy (MMM)" }[splitBy];
        const since = DateTime.fromISO(this.state.filters.calSince);
        const till = DateTime.fromISO(this.state.filters.calTill);
        const dates = Interval.fromDateTimes(since.startOf(startOf), till.endOf(startOf)).splitBy({ [splitBy]: 1 }).map(d => d.start);
        this.setState({ dates: dates, df: dateFormat }, callback);
    }

    setTasks(tasks, callback) {
        let newTasks = [];
        let shifts = {};

        const filterSince = DateTime.fromISO(this.state.filters.calSince);
        const filterTill = DateTime.fromISO(this.state.filters.calTill);
        const splitBy = this.state.filters.view || 'days';
        const startOf = { 'days': 'day', 'weeks': 'week', 'months': 'month' }[splitBy];

        let getShift = function (projectuuid, contractoruuid, interval) {
            const key = `${projectuuid}-${contractoruuid}`;
            if (!shifts[key]) {
                shifts[key] = {};
            }
            const datesArray = interval.splitBy({ [splitBy]: 1 }).map(d => d.start);
            let maxShift = 0;
            datesArray.forEach((isoDate) => {
                if (splitBy === 'weeks') {
                    isoDate = isoDate.startOf('week');
                } else if (splitBy === 'months') {
                    isoDate = isoDate.startOf('month');
                }

                isoDate = isoDate.toISODate();

                if (!shifts[key][isoDate]) {
                    shifts[key][isoDate] = 0;
                }

                shifts[key][isoDate]++;
                maxShift = Math.max(maxShift, shifts[key][isoDate]);
            });
            datesArray.forEach((isoDate) => {
                shifts[key][isoDate] = maxShift;
            })
            return maxShift;
        };

        for (let i = 0; i < tasks.length; i++) {
            const taskObj = tasks[i]
            const task = taskObj.task;
            const project = taskObj.project;
            const contractor = taskObj.contractor;

            let allowDragOps = splitBy === 'days';

            let since = typeof task.since === "string" ? DateTime.fromISO(task.since) : task.since;
            let till = typeof task.till === "string" ? DateTime.fromISO(task.till) : task.till;

            let sinceWeek = since.startOf('week');
            let tillWeek = till.endOf('week');

            let sinceMonth = since.startOf('month');
            let tillMonth = till.endOf('month');

            if (since > filterTill) {
                continue;
            }

            if (till < filterSince) {
                continue;
            }

            if (since < filterSince) {
                since = filterSince;
                allowDragOps = false;
            }

            if (till > filterTill) {
                till = filterTill;
                allowDragOps = false;
            }

            if (sinceWeek < filterSince.startOf('week')) {
                sinceWeek = filterSince.startOf('week');
                allowDragOps = false;
            }

            if (tillWeek > filterTill.endOf('week')) {
                tillWeek = filterTill.endOf('week');
                allowDragOps = false;
            }

            if (sinceMonth < filterSince.startOf('month')) {
                sinceMonth = filterSince.startOf('month');
                allowDragOps = false;
            }

            if (tillMonth > filterTill.endOf('month')) {
                tillMonth = filterTill.endOf('month');
                allowDragOps = false;
            }

            const interval = Interval.fromDateTimes(since.startOf(startOf), till.endOf(startOf));
            const timeSpanInDays = Math.ceil(till.diff(since, 'days').toObject()['days']);
            const timeSpanInWeeks = Math.ceil(tillWeek.diff(sinceWeek, 'weeks').toObject()['weeks']);
            const timeSpanInMonths = Math.ceil(tillMonth.diff(sinceMonth, 'months').toObject()['months']);

            const keyAnchor = splitBy === 'days' ? since : splitBy === 'weeks' ? sinceWeek : sinceMonth;
            const key = `${project.uuid}-${contractor.uuid}-${keyAnchor.toISODate()}`;
            const shift = getShift(project.uuid, contractor.uuid, interval);
            const newTask = {
                project: project,
                contractor: contractor,
                task: {
                    ...task,
                    key,
                    shift,
                    since,
                    till,
                    sinceWeek,
                    tillWeek,
                    sinceMonth,
                    tillMonth,
                    allowDragOps,
                    interval,
                    timeSpanInDays,
                    timeSpanInWeeks,
                    timeSpanInMonths,
                }
            };
            newTasks.push(newTask);
        };

        Object.keys(shifts).forEach(key => {
            let maxLocalShift = 0;
            Object.keys(shifts[key]).forEach(isoDate => {
                maxLocalShift = Math.max(maxLocalShift, shifts[key][isoDate]);
            })
            shifts[key].max = maxLocalShift;
        });

        this.setState({ tasks: newTasks, shifts }, () => { this.setResizeables(); callback(); });
    }

    getMaxShift(projectuuid, contractoruuid) {
        const key = `${projectuuid}-${contractoruuid}`;
        if (!this.state.shifts[key]) {
            return 0;
        }
        return this.state.shifts[key].max || 0;
    }

    getRowHeight(projectuuid, contractoruuid) {
        const {
            taskHeight,
            taskMargin,
            cellPaddingY,
        } = this.state;
        const shift = this.getMaxShift(projectuuid, contractoruuid);
        return (shift * (taskHeight + taskMargin)) + cellPaddingY;
    }

    getTask(taskuuid) {
        return this.state.tasks.find(itm => itm.task.uuid === taskuuid);
    }

    getTasks(project, contractor, date) {
        const splitBy = this.state.filters.view || 'days';
        return this.state.tasks.filter(itm => itm.task.key === `${project.uuid}-${contractor.uuid}-${date.toISODate()}`);
    }

    setTask(taskUuid, newData, callback) {
        let sendUpdatedTask = null;
        const newTasks = this.state.tasks.map((taskObj) => {
            let newTask = { ...taskObj };
            if (taskObj.task.uuid === taskUuid) {
                newTask = {
                    task: { ...taskObj.task, ...newData.task },
                    project: { ...taskObj.project, ...newData.project },
                    contractor: { ...taskObj.contractor, ...newData.contractor },
                }
                sendUpdatedTask = { ...newTask };
                sendUpdatedTask.task = {
                    ...newTask.task,
                    since: newTask.task.since.toISODate(),
                    till: newTask.task.till.toISODate(),
                }
            }
            return newTask;
        });
        if (sendUpdatedTask) {
            this.props.api.postSilent(`/task/update-partial-timeline`, sendUpdatedTask);
        }
        this.setTasks(newTasks, callback);
    }

    setResizeables() {
        const resizers = document.querySelectorAll('.resizer')
        const minimumSize = this.state.cellWidth - this.state.cellPaddingX;
        let originalWidth = 0;
        let originalHeight = 0;
        let originalX = 0;
        let originalY = 0;
        let originalMouseX = 0;
        let originalMouseY = 0;
        let delta = 0;

        for (let i = 0; i < resizers.length; i++) {
            const currentResizer = resizers[i];
            const element = currentResizer.parentElement;

            const resize = (e) => {
                if (currentResizer.classList.contains('right')) {
                    delta = (e.pageX - originalMouseX);
                    let newWidth = originalWidth + delta;
                    if (newWidth > minimumSize) {
                        element.style.width = newWidth + 'px'
                    }
                }
            }

            const stopResize = (e) => {
                window.removeEventListener('mousemove', resize);
                window.removeEventListener('mouseup', stopResize);
                const currentTaskUuid = (currentResizer.parentElement || currentResizer.parentNode).dataset.taskuuid;
                const localDelta = delta;
                const deltaDays = Math.round(localDelta / this.state.cellWidth);
                delta = 0;
                if (deltaDays === 0) {
                    element.style.width = originalWidth + 'px';
                }
                if (!currentTaskUuid) {
                    return;
                }
                const theTask = this.getTask(currentTaskUuid).task;

                this.setState({ draggable: true, dragStopped: Date.now() }, () => {
                    const execute = () => {
                        this.setTask(currentTaskUuid, { task: { till: DateTime.fromISO(theTask.till).plus({ days: deltaDays }) } }, () => {
                        });
                    }
                    const restore = () => {
                        element.style.width = originalWidth + 'px';
                    };
                    this.confirm({
                        title: this.props.t('page.scheduler.modal.taskDateTillChange.title'),
                        text: this.props.t('page.scheduler.modal.taskDateTillChange.text'),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: this.props.t('common.button.confirm'),
                        confirmButtonColor: '#fdbf21',
                        cancelButtonColor: '#aaa',
                        cancelButtonText: this.props.t('common.button.cancel')
                    },
                        () => execute(),
                        () => restore());
                });
            }

            const mousedown = (e) => {
                e.preventDefault();
                e.stopPropagation();
                originalWidth = parseFloat(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
                originalHeight = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
                originalX = element.getBoundingClientRect().left;
                originalY = element.getBoundingClientRect().top;
                originalMouseX = e.pageX;
                originalMouseY = e.pageY;
                window.addEventListener('mousemove', resize)
                window.addEventListener('mouseup', stopResize)
                this.setState({ draggable: false, dragStopped: 0 })
            }

            if (!currentResizer.dataset.hasmousedown) {
                currentResizer.dataset.hasmousedown = true;
                currentResizer.addEventListener('mousedown', mousedown)
            }
        }
    }

    getContractorColor(contractoruuid) {
        return this.state.colors[contractoruuid] || "";
    }

    getContractorContrastColor(contractoruuid) {
        return this.props.utils.contrastColor(this.getContractorColor(contractoruuid));
    }

    getCellClassName(date, classNames, prefix) {
        const splitBy = this.state.filters.view || 'days';

        prefix = prefix || '';
        classNames += ` month-${date?.month}`;
        if (date?.weekday === 6) {
            classNames += ` sat`;
            if (prefix) {
                classNames += ` ${prefix}sat`;
            }
        }
        if (date?.weekday === 7) {
            classNames += ` sun`;
            if (prefix) {
                classNames += ` ${prefix}sun`;
            }
        }

        let isToday = false;
        if (splitBy === 'days') {
            if (date?.toISODate() === this.todayIso) {
                isToday = true;
            }
        } else if (splitBy === 'weeks') {
            if (date?.toISODate() === this.today.startOf('week').toISODate()) {
                isToday = true;
            }
        } else if (splitBy === 'months') {
            if (date?.toISODate() === this.today.startOf('month').toISODate()) {
                isToday = true;
            }
        }

        if (isToday) {
            classNames += ` today`;
            if (prefix) {
                classNames += ` ${prefix}today`;
            }
        }

        return classNames;
    }

    showModal(e, modal, modalData) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (modalData.uri) {
            this.props.openLink(modalData.uri, '_hash');
        }
        this.setState({ [modal]: true, modalData: modalData })
    }

    hideModal(modal, reloadData) {
        this.props.openLink(`/scheduler/`, '_hash');
        this.setState({ [modal]: false, modalData: {} }, () => {
            if (reloadData) {
                this.fetchFreshData();
            }
        });
    }

    checkSlotForContractor(params, callback) {
        this.props.api.post(
            `/task/slot-check`,
            {
                task: {
                    since: params.since
                },
                contractor: {
                    uuid: params.contractoruuid
                }
            },
            (data) => {
                if (data.tasks && data.tasks.length) {
                    this.confirm({
                        title: this.props.t('page.scheduler.modal.slotTaken.title'),
                        text: this.props.t('page.scheduler.modal.slotTaken.text'),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: this.props.t('common.button.confirm'),
                        confirmButtonColor: '#fdbf21',
                        cancelButtonColor: '#aaa',
                        cancelButtonText: this.props.t('common.button.cancel')
                    }, () => callback());
                } else {
                    callback();
                }
            },
            (errorObject) => {
                this.props.showToast({
                    errorObject: errorObject,
                    title: this.props.t('common.toast.error'),
                    color: 'danger'
                });
            }
        );
    }

    renderProject({ index, style }) {
        const {
            draggable,
            dragStopped,
            df,
            projects,
            dates,
            cellWidth,
            taskHeight,
            taskMargin,
            cellPaddingX,
            filters
        } = this.state;

        const splitBy = this.state.filters.view || 'days';
        const forcedReadOnly = !this.props.hasPermission(['VIEW_PROJECT_SCHEDULER_EDIT']).any;
        const renderTimestamp = Date.now();
        const projectObj = projects[index];

        if (!projectObj) {
            return <></>;
        }

        return (
            <Fragment key={`details-${projectObj.project.uuid}-${index}`}>
                {index === 0 && (
                    <thead className='sticky-thead'>
                        <tr className='sticky-row'>
                            <th className="sticky-col first-col cut" style={{ lineHeight: '14px', height: '14px' }}>
                                <span>{this.props.t(`page.scheduler.component.project`).slice(0, 32)}</span>
                            </th>
                            <th className={`sticky-col second-col cut mode-${filters.mode}`} style={{ lineHeight: '14px', height: '14px' }}>
                                <span>{this.props.t(`page.scheduler.component.crew`).slice(0, 32)}</span>
                            </th>
                            {
                                dates.map(date => (
                                    <Fragment key={`header-${date.toISODate()}`} style={{ lineHeight: '14px', height: '14px' }}>
                                        <th className={this.getCellClassName(date, "cell-date monospace", "th")}>{date.toFormat(df)}</th>
                                    </Fragment>
                                ))
                            }
                        </tr>
                    </thead>
                )
                }
                <tbody>
                    <tr>
                        <td className={`sticky-col first-col mode-${filters.mode}`} rowSpan={projectObj.contractors.length + 1}>
                            <button
                                className="measure text-left strong"
                                onClick={(e) => this.showModal(e, 'modalProject', { projectuuid: projectObj.project.uuid, uri: `/scheduler/#/project/${projectObj.project.uuid}/details`, activeTab: "details" })}
                            >
                                {(projectObj.project.label || '---')}
                            </button>
                            {` `}
                            <button
                                className="measure text-left"
                                onClick={(e) => this.showModal(e, 'modalProject', { projectuuid: projectObj.project.uuid, uri: `/scheduler/#/project/${projectObj.project.uuid}/details`, activeTab: "details" })}
                            >
                                {(projectObj.address.addressName || '---')}
                            </button>
                            {
                                filters.mode === 'advanced' && (
                                    <>
                                        <br /><span className="measure">PM: {projectObj.pm ? projectObj.pm.label : <Badge pill variant='danger'>&nbsp;?&nbsp;</Badge>}</span>
                                        <br /><span className="measure">SM: {projectObj.sm ? projectObj.sm.label : <Badge pill variant='danger'>&nbsp;?&nbsp;</Badge>}</span>
                                    </>
                                )
                            }
                        </td>
                        {
                            projectObj.contractors.length === 0 && (
                                <Fragment>
                                    <td className={`sticky-col second-col mode-${filters.mode}`}>&nbsp;</td>
                                    {
                                        dates.map(date => (
                                            <Fragment key={`cell-${projectObj.project.uuid}-${date.toISODate()}`}>
                                                <td className={this.getCellClassName(date, "cell-date border-left")}>&nbsp;</td>
                                            </Fragment>
                                        ))
                                    }
                                </Fragment>
                            )
                        }
                    </tr>
                    {
                        projectObj.contractors.map(contractorObj => {
                            const contractor = contractorObj.contractor;
                            return (
                                <Fragment key={`details-${projectObj.project.uuid}-contractor-${contractor.uuid}`}>
                                    <tr style={{ height: this.getRowHeight(projectObj.project.uuid, contractor.uuid) }}>
                                        <td className={`sticky-col second-col mode-${filters.mode}`} style={{ borderLeft: `2px solid ${contractor.color}` }}>
                                            <span className="measure">{contractor.label}</span>
                                            {
                                                filters.mode === 'advanced' && (
                                                    <>
                                                        <br />
                                                        <small className="measure">{contractor.category}</small>
                                                    </>
                                                )
                                            }
                                        </td>
                                        {
                                            dates.map(date => {
                                                const tasksForProjectContractorDate = this.getTasks(projectObj.project, contractor, date);
                                                return (
                                                    <td
                                                        key={`cell-${projectObj.project.uuid}-${contractor.uuid}-${date.toISODate()}`}
                                                        className={this.getCellClassName(date, "cell-date placeholder droptask border-left")}
                                                        data-day={date.toISODate()}
                                                        data-projectuuid={projectObj.project.uuid}
                                                        data-contractoruuid={contractor.uuid}
                                                        onClick={(e) => {
                                                            if (forcedReadOnly) {
                                                                return;
                                                            }
                                                            if (renderTimestamp - dragStopped > 250) {
                                                                this.checkSlotForContractor({ contractoruuid: contractor.uuid, since: date.toISODate() }, () => {
                                                                    this.showModal(e, 'modalTask', { since: date.toISODate(), projectuuid: projectObj.project.uuid, contractoruuid: contractor.uuid, contractorlabel: contractor.label })
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            tasksForProjectContractorDate.map((taskObj) => {
                                                                const task = taskObj.task;
                                                                const contractor = taskObj.contractor;
                                                                const project = taskObj.project;
                                                                const ccol = this.getContractorColor(contractor.uuid);
                                                                const darkCcol = this.props.utils.shadeColor(ccol, -33);
                                                                const timeSpan = splitBy === 'days' ? task.timeSpanInDays : splitBy === 'weeks' ? task.timeSpanInWeeks : task.timeSpanInMonths;
                                                                const timeSpanForStyle = timeSpan + (['days'].includes(splitBy) ? 1 : 0);
                                                                return (
                                                                    <div
                                                                        key={`task-${task.uuid}`}
                                                                        className="task"
                                                                        data-taskuuid={task.uuid}
                                                                        data-projectuuid={project.uuid}
                                                                        data-contractoruuid={contractor.uuid}
                                                                        data-timespan={timeSpan}
                                                                        draggable={task.allowDragOps && draggable && !forcedReadOnly}
                                                                        onDragStart={(event) => event.dataTransfer.setData('text/plain', null)}
                                                                        style={{
                                                                            width: (cellWidth * (timeSpanForStyle)) - cellPaddingX,
                                                                            marginTop: (task.shift - 1) * (taskHeight + taskMargin),
                                                                            background: `linear-gradient(90deg, ${darkCcol} 0%, ${darkCcol} ${task.progress}%, ${ccol} ${task.progress}%, ${ccol} 100%)`
                                                                        }}
                                                                    >
                                                                        <button
                                                                            onClick={(e) => this.showModal(e, 'modalTask', { taskuuid: task.uuid, uri: `/scheduler/#/task/${task.uuid}/details` })}
                                                                            style={{
                                                                                color: this.getContractorContrastColor(contractor.uuid),
                                                                                width: '100%',
                                                                                textAlign: 'left'
                                                                            }}
                                                                        >
                                                                            {task.confirmed ? `` : `😴 `}
                                                                            {task.done ? `✔ - ` : `${task.progress}% - `}
                                                                            {task.label}
                                                                        </button>
                                                                        {
                                                                            task.allowDragOps && (
                                                                                <div className='br right resizer' data-taskuuid={task.uuid}>
                                                                                    &nbsp;
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                </Fragment>
                            )
                        })
                    }
                </tbody>
            </Fragment>
        )
    }

    render() {

        const {
            projects,
            isLoading,
            isAdding,
            modalTask,
            modalProject,
            modalData,
            modalFilters,
        } = this.state;

        const splitBy = this.state.filters.view || 'days';

        const loadingView = (
            <IsLoading
                style={{ position: 'absolute' }}
            />
        )

        const filtersView = (
            <FilterProjects
                t={this.props.t}
                api={this.props.api}
                showToast={this.props.showToast}
                tab={'scheduler'}
                onRestore={(filters) => { this.setState({ filters: filters }, () => this.fetchFreshData({ scrollTop: 0, scrollLeft: 0, page: 0, pageSize: this.default.pageSize })) }}
                onSubmit={(filters) => { this.setState({ filters: filters, modalFilters: false }, () => this.fetchFreshData({ scrollTop: 0, scrollLeft: 0, page: 0, pageSize: this.default.pageSize })) }}
            />
        )

        const viewFilters = (
            <Modal
                show={modalFilters}
                onHide={() => { this.setState({ modalFilters: false }) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.t('common.string.adjust')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {filtersView}
                </Modal.Body>
            </Modal>
        )

        const emptyProjectsView = (
            <Fragment>
                {viewFilters}
                <div className="text-center p-25">
                    <Icon name="coffee" style={{ width: 128, height: 128, color: "#e6ecf0" }} />
                    <h3 className='py-15' style={{ color: "#444" }}>{this.props.t(`page.scheduler.component.emptyProjectsList`)}</h3>
                </div>
            </Fragment>
        )

        const hasProjectsView = (
            <Fragment>
                {
                    modalTask && (
                        <ModalTask
                            t={this.props.t}
                            api={this.props.api}
                            utils={this.props.utils}
                            openLink={this.props.openLink}
                            getUser={this.props.getUser}
                            location={this.props.location}
                            showToast={this.props.showToast}
                            getRegionIdent={this.props.getRegionIdent}
                            isRegion={this.props.isRegion}
                            showModal={modalTask}
                            modalTitle={this.props.t(`page.scheduler.modal.taskDetails.title`)}
                            onModalHide={(reloadData) => this.hideModal('modalTask', reloadData)}
                            data={modalData}
                            activeTab={modalData.activeTab}
                            buildLink={this.props.buildLink}
                            hasPermission={this.props.hasPermission}
                            onAfterRemove={() => this.hideModal('modalTask', true)}
                        />
                    )
                }

                {
                    modalProject && (
                        <ModalProject
                            t={this.props.t}
                            api={this.props.api}
                            utils={this.props.utils}
                            openLink={this.props.openLink}
                            getUser={this.props.getUser}
                            location={this.props.location}
                            showToast={this.props.showToast}
                            getRegionIdent={this.props.getRegionIdent}
                            isRegion={this.props.isRegion}
                            showModal={modalProject}
                            modalTitle={this.props.t(`page.scheduler.modal.projectDetails.title`)}
                            onModalHide={(reloadData) => this.hideModal('modalProject', reloadData)}
                            onAfterSubmit={(reloadData, hideModal) => hideModal && this.hideModal('modalProject', true)}
                            data={modalData}
                            activeTab={modalData.activeTab}
                            buildLink={this.props.buildLink}
                            hasPermission={this.props.hasPermission}
                        />
                    )
                }

                {viewFilters}

                <div id="scheduler" className="scheduler">
                    <div id="scheduler-wrapper" className="wrapper nicebar" onScroll={this.onSchedulerWrapperScroll} ref={this.schedulerRef}>
                        <table id="scheduler-table" className={`table schedule-view-${splitBy}`}>
                            {
                                projects.map((project, index) => {
                                    return this.renderProject({ index })
                                })
                            }
                        </table>
                        {
                            isAdding && (
                                <IsLoading
                                    style={{ position: 'absolute', color: '#007bff', background: 'transparent' }}
                                    withMarginLeft={true}
                                />
                            )
                        }
                    </div>
                </div>
            </Fragment>
        );

        const withProjects = projects.length > 0 ? hasProjectsView : emptyProjectsView
        return isLoading ? loadingView : withProjects;
    }
}

export default Content
